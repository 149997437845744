import { IonButton, IonRouterLink } from '@ionic/react';
import styled, { css } from 'styled-components';

import { isNotTouchable } from 'styles/responsive';

import { ReactComponent as CaretDown } from '../../assets/icons/caret-down.svg';
import type { ButtonProps, LinkProps } from './types';

const buttonVariants = css<{
  $size: ButtonProps['$size'];
  $variant: ButtonProps['$variant'];
  $color: ButtonProps['$color'];
}>`
  min-width: 20.875rem;
  min-height: 3.1875rem;
  border-radius: 12px;
  overflow: hidden;
  font-size: 1rem;
  line-height: 1.1875rem;
  text-transform: none;
  font-weight: ${({ theme }) => theme.weights.regular};
  cursor: pointer;
  --background: ${({ theme }) => theme.colors.primary};
  transition: all 200ms ease;
  :disabled {
    opacity: 0.5;
  }

  /* Main colors */

  ${({ $color }) =>
    ($color === 'primary' &&
      css`
        ${isNotTouchable} {
          :hover:not(:disabled) {
            --background: ${({ theme }) => theme.colors.primary75};
            --background-hover: ${({ theme }) => theme.colors.primary75};
          }
        }
      `) ||
    ($color === 'primary-dark' &&
      css`
        --background: ${({ theme }) => theme.colors.dark0};
        ${isNotTouchable} {
          :hover:not(:disabled) {
            --background: ${({ theme }) => theme.colors.dark3};
          }
        }
      `) ||
    ($color === 'secondary' &&
      css`
        --background: ${({ theme }) => theme.colors.white10};
        ${isNotTouchable} {
          :hover:not(:disabled) {
            --background: ${({ theme }) => theme.colors.white25};
          }
        }
      `)}

  /* Small buttons */

    ${({ $size, $color, $variant }) =>
    $size === 'small' &&
    css`
      min-width: inherit;
      min-height: 2.5625rem;
      --padding-end: 0.75rem;
      --padding-start: 0.75rem;
      font-size: 0.875rem;
      line-height: 1.0625rem;
      color: ${({ theme }) =>
        $color === 'secondary' && $variant === 'label' && theme.colors.primary};
      --background: ${({ theme }) =>
        $color === 'secondary' &&
        $variant === 'label' &&
        theme.colors.primary15};
      ${isNotTouchable} {
        :hover:not(:disabled) {
          --background: ${({ theme }) =>
            $color === 'secondary' &&
            $variant === 'label' &&
            theme.colors.primary25};
        }
      }
    `}
    
    /* Only icon buttons */

    ${({ $variant, $size, $color }) =>
    ($variant === 'only-icon' &&
      css`
        min-width: inherit;
        min-height: inherit;
        width: ${$size === 'small' ? '2.25rem' : '3rem'};
        height: ${$size === 'small' ? '2.25rem' : '3rem'};
        --background: ${({ theme }) =>
          $color === 'primary-dark' && theme.colors.dark2};
        border: ${({ theme }) =>
          $color === 'primary-dark' &&
          `0.0625rem solid ${theme.colors.white10}`};
      `) ||
    ($variant === 'no-border' &&
      css`
        min-width: inherit;
        min-height: inherit;
        width: 2.25rem;
        height: 2.25rem;
        --background: ${({ theme }) =>
          $color === 'primary' ? theme.colors.primary : theme.colors.dark2};
      `)}
`;

export const CaretDownIcon = styled(CaretDown)`
  width: 0.625rem;
  height: 0.375rem;
  margin-left: 0.4375rem;
  flex-shrink: 0;
`;

export const IconContainer = styled.div<{
  $variant: ButtonProps['$variant'];
  $size: ButtonProps['$size'];
}>`
  max-width: 1.25rem;
  flex-shrink: 0;
  margin-right: 0.625rem;
  svg {
    width: 100%;
  }
  ${({ $variant, $size }) =>
    ($variant === 'no-border' || $variant === 'only-icon') &&
    css`
      margin-right: 0;
      max-width: ${$size === 'small' ? '0.875rem' : '1.375rem'};
      max-height: ${$size === 'small' ? '0.875rem' : '1.375rem'};
    `}
`;

export const DefaultButton = styled(IonButton)`
  ${buttonVariants};
`;

export const DefaultLink = styled(IonRouterLink)<{
  $disabled: LinkProps['$disabled'];
}>`
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.875rem;
  line-height: 1.0625rem;
  cursor: pointer;
  transition: color 200ms ease;
  ${isNotTouchable} {
    :hover:not(:disabled) {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;
