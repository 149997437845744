import { useQuery } from 'react-query';

import useGetMinimumTransactionDateQuery, {
  GET_MINIMUM_TRANSACTION_DATE_QUERY_KEY,
} from 'api/queries/transactions/getMinimumTransactionDate';

export default function useGetMinimumTransactionDate(
  portfolio: string | undefined,
) {
  const { data } = useQuery(
    GET_MINIMUM_TRANSACTION_DATE_QUERY_KEY(portfolio ?? ''),
    {
      enabled: !!portfolio,
      queryFn: useGetMinimumTransactionDateQuery(),
    },
  );

  return {
    data: data ?? undefined,
  };
}
