import { nanoid } from 'nanoid/non-secure';
import type { FC } from 'react';

import { Bar } from './styles';
import type { Props } from './types';

const GridColumns: FC<Props> = ({ scale, bands, height, top }) => (
  <g>
    {bands.map((band, i) => {
      if (i % 2 === 0) return null;
      const key = nanoid(2);
      return (
        <Bar
          key={key}
          y={top}
          x={scale(band)}
          width={scale.bandwidth()}
          height={height}
        />
      );
    })}
  </g>
);

export default GridColumns;
