import { memo } from 'react';
import type { FC } from 'react';

import { BarRounded } from './styles';
import type { Props } from './types';

const BarChart: FC<Props> = ({
  data,
  xScale,
  yScale,
  bottomHeight,
  handleTooltipClosed,
  handleTooltipUpdated,
  selectDate,
}) => (
  <g>
    {data.map((datum) => (
      <BarRounded
        top
        key={`bar-${datum.date}`}
        radius={4}
        x={xScale(datum.date) ?? 0}
        width={xScale.bandwidth()}
        y={yScale(datum.allocation)}
        height={bottomHeight - yScale(datum.allocation)}
        onMouseLeave={handleTooltipClosed}
        onMouseOver={() => handleTooltipUpdated({ datum })}
        onClick={() => selectDate(datum.date.toString())}
      />
    ))}
  </g>
);

export default memo(BarChart);
