import type { FC } from 'react';

import useConnect from './connect';
import {
  Container,
  Title,
  YieldHistoryChart,
  YieldSpreadChart,
} from './styles';

const YieldHistory: FC = () => {
  const { data } = useConnect();

  return (
    <Container>
      <Title>Yield Historical</Title>
      <YieldHistoryChart data={data} />
      <Title>Yield Spread (Over Adjusted Treasury Yield)</Title>
      <YieldSpreadChart data={data} />
    </Container>
  );
};

export default YieldHistory;
