import useYieldHistory from 'api/hooks/allocations/useYieldHistory';
import useSelectedClient from 'api/hooks/clients/useSelectedClient';

export default function useConnect() {
  const { selectedPortfolio } = useSelectedClient();

  const { data = [] } = useYieldHistory(selectedPortfolio?.id);

  return { data };
}
