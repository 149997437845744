import { useCallback, useMemo } from 'react';
import { useRouteMatch } from 'react-router';

import useSelectedClient from 'api/hooks/clients/useSelectedClient';
import { formatMonth } from 'utils/dates';
import type { KeySortOfColumn } from 'utils/sort/types';
import useModal from 'utils/useModal';

import { useCategory, useNavigation, useReport, useSorting } from './logic';
import type { SortFormModel } from './types';

export default function useConnect() {
  const {
    params: { categorySlug, reportSlug, timeFrame },
    url,
  } = useRouteMatch<{
    categorySlug: string;
    reportSlug: string;
    timeFrame: string;
  }>();

  const { selectedClient, selectedPortfolio } = useSelectedClient();

  const report = useReport({
    client: selectedClient?.id,
    portfolioName: selectedPortfolio?.name,
    slug: reportSlug,
  });

  const { compare, currentSorting, onChangeSorting, sortOptions } =
    useSorting();

  const category = useCategory({
    categorySlug,
    compare,
    portfolio: selectedPortfolio?.id,
    reportSlug,
    timeFrame,
  });

  const headerColumns: KeySortOfColumn[] =
    reportSlug === 'since-inception'
      ? [
          {
            titleColumn: `${category?.assets.length} Assets`,
            keyOrderAsc: 'alphabeticalR',
            keyOrderDesc: 'alphabetical',
          },
          {
            titleColumn: 'Total ($)',
            keyOrderAsc: 'totalASC',
            keyOrderDesc: 'default',
          },
        ]
      : [
          {
            titleColumn: `${category?.assets.length} Assets`,
            keyOrderAsc: 'alphabeticalR',
            keyOrderDesc: 'alphabetical',
          },
          {
            titleColumn: 'Total ($)',
            keyOrderAsc: 'totalASC',
            keyOrderDesc: 'default',
          },
          {
            titleColumn: 'Total (%)',
            keyOrderAsc: 'totalPorcASC',
            keyOrderDesc: 'totalPorcDESC',
          },
        ];

  const headerTimeFrameText = useMemo(() => {
    switch (reportSlug) {
      case 'month-to-date':
        return !timeFrame
          ? ''
          : ` For ${formatMonth(new Date(timeFrame.split('-').join('/')))} `;
      case 'year-to-date':
        return !timeFrame ? '' : ` For ${timeFrame} `;
      case 'daily-estimate':
      case 'since-inception':
      default:
        return undefined;
    }
  }, [timeFrame, reportSlug]);

  const { closeModal, modalOpen, openModal } = useModal();

  const initialValues: SortFormModel = useMemo(
    () => ({
      value: currentSorting,
    }),
    [currentSorting],
  );

  const { breadcrumbs, navLinks } = useNavigation({
    categoryName: category?.name,
    categorySlug,
    reportSlug,
    url,
  });

  const onSubmitSortForm = useCallback(
    ({ value }: { value?: string }) => {
      onChangeSorting(value ?? '');
      closeModal();
    },
    [closeModal, onChangeSorting],
  );

  return {
    breadcrumbs,
    category,
    currentSorting,
    handle: {
      changeSorting: onChangeSorting,
      closeModal,
      openModal,
      submitSortForm: onSubmitSortForm,
    },
    initialValues,
    modalOpen,
    navLinks,
    report,
    headerTimeFrameText,
    sortOptions,
    headerColumns,
    url,
  };
}
