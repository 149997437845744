import { useMemo } from 'react';
import { useLocation, useRouteMatch } from 'react-router';

import useAllocationAsset from 'api/hooks/allocations/useAllocationAsset';
import useAllocationGroups from 'api/hooks/allocations/useAllocationGroups';
import useSelectedClient from 'api/hooks/clients/useSelectedClient';
import up from 'utils/paths';

import {
  useCurrentGrouping,
  useNavigationLinks,
} from '../CategoryDetail/logic';

export default function useConnect() {
  const {
    params: { categorySlug, assetSlug },
    url,
  } = useRouteMatch<{ categorySlug: string; assetSlug: string }>();

  const { search } = useLocation();

  const parentPath = useMemo(() => up(url, 2), [url]);

  const grouping = useCurrentGrouping({ search });

  const { selectedPortfolio } = useSelectedClient();

  const asset = useAllocationAsset({
    assetSlug,
    categorySlug,
    grouping,
    portfolio: selectedPortfolio?.id,
  });

  const { getNameBySlug } = useAllocationGroups({
    groupBy: 'asset-breakdown',
    subselection: undefined,
    portfolio: selectedPortfolio?.id,
  });

  const { backUrl } = useNavigationLinks({
    categorySlug,
    getNameBySlug,
    grouping,
    search,
    url,
  });

  const pageTitle = getNameBySlug('asset-breakdown', categorySlug);

  return {
    asset,
    backUrl,
    dividendsReceived: asset?.dividendsReceived ?? 0,
    marketPrice: asset?.marketPrice ?? 0,
    marketValue: asset?.marketValue ?? 0,
    cost: asset?.cost ?? 0,
    parentPath,
    pageTitle,
    shares: asset?.shares ?? 0,
    title: asset?.fullName ?? '',
    totalProfitLoss: asset?.totalProfitAndLoss ?? 0,
    url,
  };
}
