import { createContext, FC, useContext, useMemo, useState } from 'react';

import type { RefreshTokenContextModel } from './types';

const Context = createContext<RefreshTokenContextModel | undefined>(undefined);

const RefreshTokenProvider: FC = ({ children }) => {
  const [isLoadingRequestToken, setLoadingRequestToken] =
    useState<boolean>(true);
  const [isRefreshingToken, setRefreshingToken] = useState<boolean>(false);
  const [couldResfreshToken, setCouldResfreshToken] = useState<boolean>(true);

  const value = useMemo(
    () => ({
      isRefreshingToken,
      setRefreshingToken,
      couldResfreshToken,
      setCouldResfreshToken,
      isLoadingRequestToken,
      setLoadingRequestToken,
    }),
    [isRefreshingToken, couldResfreshToken, isLoadingRequestToken],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export function useRefreshToken() {
  return useContext(Context);
}

export default RefreshTokenProvider;
