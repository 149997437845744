export default function searchWithoutSort(search: string | undefined) {
  if (!search) {
    return '';
  }

  let searchAux = search
    .replace('?', '')
    .split('&')
    .filter((e) => !e.includes('sortBy'))
    .join('&');

  if (searchAux) {
    searchAux = `?${searchAux}`;
  }

  return searchAux;
}
