import { FC, memo } from 'react';
import { useQueryClient } from 'react-query';

import {
  ACCESS_TOKEN_QUERY_KEY,
  AccessTokenQuery,
} from 'api/queries/auth/accessToken';

import {
  Button,
  Container,
  MessageRefreshToken,
  Spinner,
  SubText,
  Text,
  TextContainer,
} from './style';
import type { Props } from './types';

const RefreshTokenOverlay: FC<Props> = ({ isMobile, couldRefreshToken }) => {
  const queryClient = useQueryClient();

  const goToLogin = () => {
    queryClient.setQueryData<AccessTokenQuery>(ACCESS_TOKEN_QUERY_KEY, {
      accessToken: undefined,
      email: undefined,
      passwordResetData: undefined,
      refreshToken: undefined,
      termsAgreed: false,
    });

    setTimeout(() => window.location.reload(), 100);
  };

  return (
    <Container $isMobile={isMobile}>
      <MessageRefreshToken $isMobile={isMobile}>
        <TextContainer>
          <Text>Session Expired</Text>
          {couldRefreshToken ? (
            <SubText $small={false}>We are trying to reconnect you</SubText>
          ) : (
            <SubText $small={false}>
              For your security you have been logged out due to inactivity.
            </SubText>
          )}
          {couldRefreshToken ? (
            <Spinner />
          ) : (
            <Button onClick={goToLogin}>Login</Button>
          )}
          {couldRefreshToken && (
            <SubText $small>It may take a few seconds</SubText>
          )}
        </TextContainer>
      </MessageRefreshToken>
    </Container>
  );
};

export default memo(RefreshTokenOverlay);
