import { useCallback } from 'react';
import type { MutationFunction } from 'react-query';

import useRequest from 'api/request';
import withoutNulls from 'utils/withoutNulls';

import type {
  ResetPasswordRequest,
  ResetPasswordResponse,
  ResetPasswordVariables,
} from './types';

export default function useResetPasswordMutation(): MutationFunction<
  void,
  ResetPasswordVariables
> {
  const request = useRequest<ResetPasswordRequest, ResetPasswordResponse>();

  return useCallback(
    async ({ email, password, token }: ResetPasswordVariables) => {
      const response = await request('/api/Authentication/PasswordReset', {
        body: {
          confirmPassword: password,
          email,
          password,
          token,
        },
        method: 'put',
      });

      const data = withoutNulls(await response.json());

      if (!data.isSuccess) {
        throw new Error(data.message);
      }
    },
    [request],
  );
}
