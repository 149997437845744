export function calculateDifference(
  startValue: number,
  endValue: number,
): number {
  if (startValue === 0 && endValue === 0) {
    return 0;
  }

  const increment = endValue - startValue;
  return (increment / Math.abs(startValue)) * 100;
}
