import type { PickByValue } from 'utility-types';

import type { NotionalValues } from 'model/NotionalValues';

const getNotionalSummary = (values: readonly NotionalValues[]) => {
  const sum = (key: keyof PickByValue<NotionalValues, number>) =>
    values.reduce((total, data) => data[key] + total, 0);
  return {
    balance: sum('balance'),
    balancePct: sum('balancePct'),
    notional: sum('notional'),
    notionalPct: sum('notionalPct'),
    totalNotional: sum('balance') + sum('notional'),
    totalNotionalPct: sum('balancePct') + sum('notionalPct'),
  };
};

export default getNotionalSummary;
