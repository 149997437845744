import { FC, memo, useCallback, useEffect, useState } from 'react';

import getClientJoinPortfolio from 'utils/getClientJoinPortfolio';

import {
  Container,
  ContainerFixed,
  Content,
  ContentInfo,
  InfoText,
  ItemContainer,
  ItemLink,
  ItemText,
  NoItemsMessage,
  RadioGroup,
  RadioItem,
  SearchInput,
  SubTitle,
  Title,
} from './styles';
import type { Props, SearchItem } from './types';

const SearchBar: FC<Props> = ({
  className,
  debounce,
  disabled,
  id,
  onChange,
  onChangeAccount,
  onChangeDefaultAccount,
  placeholder,
  value = '',
  searchItemsList,
  variant = 'list',
  selectedAccount = '',
  title,
  subtitle,
}) => {
  const [filteredSearch, setFilteredSearch] = useState<SearchItem[]>([
    {
      id: '',
      value: '',
      label: '',
      subPortfolios: [],
    },
  ]);

  useEffect(() => {
    const tempSearchResult = searchItemsList.filter((item) =>
      item.label.toLowerCase().includes(value.toLowerCase()),
    );
    setFilteredSearch([...tempSearchResult]);
  }, [searchItemsList, value]);

  const handleChangeAccount = useCallback(
    (profileId) => {
      onChangeAccount(profileId);
    },
    [onChangeAccount],
  );

  return (
    <Container className={className}>
      {title ? (
        <ContainerFixed>
          <Title>{title}</Title>
          <SubTitle>{subtitle}</SubTitle>
          <SearchInput
            placeholder={placeholder}
            value={value}
            onIonChange={onChange}
            disabled={disabled}
            id={id}
            debounce={debounce}
          />
        </ContainerFixed>
      ) : (
        <SearchInput
          placeholder={placeholder}
          value={value}
          onIonChange={onChange}
          disabled={disabled}
          id={id}
          debounce={debounce}
        />
      )}
      <Content $isRadioVariant={variant === 'radio'}>
        {variant === 'list' && (
          <ContentInfo>
            <InfoText>
              {filteredSearch.length}{' '}
              {filteredSearch.length === 1 ? 'Result' : 'Results'}
            </InfoText>
            <InfoText>A - Z</InfoText>
          </ContentInfo>
        )}
        {filteredSearch.length === 0 && (
          <NoItemsMessage $isRadioVariant={variant === 'radio'}>
            No items matching your search criteria were found.
          </NoItemsMessage>
        )}
        {variant === 'list' && filteredSearch.length !== 0 ? (
          filteredSearch.map((item) => (
            <ItemLink
              key={item.value}
              onClick={() => handleChangeAccount(item.id)}
              variant="link"
            >
              <ItemContainer>
                <ItemText>{item.label}</ItemText>
              </ItemContainer>
            </ItemLink>
          ))
        ) : (
          <RadioGroup
            value={selectedAccount}
            onIonChange={onChangeDefaultAccount}
          >
            {filteredSearch.map((item) =>
              item.subPortfolios.map((portfolio, index) => (
                <RadioItem
                  key={getClientJoinPortfolio(item.value, portfolio.value)}
                  optionType="radiobutton"
                  id={`settings-search-option-${getClientJoinPortfolio(
                    item.value,
                    portfolio.value,
                  )}`}
                  value={getClientJoinPortfolio(item.value, portfolio.value)}
                  label={getClientJoinPortfolio(
                    item.value,
                    portfolio.value,
                    ' ',
                  )}
                  lastGroupItem={item.subPortfolios.length === index + 1}
                />
              )),
            )}
          </RadioGroup>
        )}
      </Content>
    </Container>
  );
};

export default memo(SearchBar);
