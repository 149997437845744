import { FC, useEffect, useState } from 'react';

import PageDetailLayoutHeader from 'components/PageDetailLayoutHeader';
import { useScreenOrientation } from 'containers/Services/ScreenOrientationService';
import from from 'styles/responsive';
import { getFullPortfolioNameReadable } from 'utils/getFullPortfolioName';
import useMedia from 'utils/useMedia';

import useConnect from './connect';
import {
  DesktopContainer,
  DisclosuresLink,
  MobileContainer,
  MobileContent,
} from './styles';
import type { Props } from './types';

const PageDetailLayout: FC<Props> = ({
  children,
  className,
  parentUrl,
  title,
  secondaryTitle,
  withSubtitle = true,
  showLayoutHeader = true,
  subtitle,
  modalOpen = false,
  fullScreen = false,
}) => {
  const [isTop, setIsTop] = useState(showLayoutHeader);

  const { selectedClient, selectedPortfolio } = useConnect();
  const screenOrientation = useScreenOrientation();
  const isMobile = !useMedia(from.tablet.query);

  const handleScroll = (event: CustomEvent) => {
    if (showLayoutHeader && screenOrientation?.isLandscape) {
      const posY = event.detail.currentY;
      setIsTop(posY <= 0);

      const tabBarElement = document.getElementsByTagName('ion-tab-bar')[0];
      if (tabBarElement) {
        tabBarElement.style.visibility = posY <= 0 ? 'initial' : 'hidden';
        tabBarElement.style.height = posY <= 0 ? '4.8125rem' : '0';
      }
    }
  };

  useEffect(() => {
    if (showLayoutHeader) {
      const tabBarElement = document.getElementsByTagName('ion-tab-bar')[0];
      if (tabBarElement) {
        tabBarElement.style.visibility =
          (!fullScreen && isTop) || !screenOrientation?.isLandscape
            ? 'initial'
            : 'hidden';
        tabBarElement.style.height =
          (!fullScreen && isTop) || !screenOrientation?.isLandscape
            ? '4.8125rem'
            : '0';
      }
    }
  }, [fullScreen, isTop, screenOrientation, showLayoutHeader]);

  const showHeader = () => {
    if (screenOrientation?.isLandscape) {
      if (isTop) {
        return !fullScreen;
      }
      return false;
    }
    return true;
  };

  if (!selectedClient || !selectedPortfolio) {
    return null;
  }

  if (isMobile) {
    return (
      <MobileContainer className={className}>
        {showLayoutHeader && showHeader() && (
          <PageDetailLayoutHeader
            name={
              withSubtitle || !!subtitle
                ? subtitle ??
                  getFullPortfolioNameReadable({
                    clientName: selectedClient.name,
                    portfolioName: selectedPortfolio.name,
                  })
                : undefined
            }
            goBackPathname={parentUrl}
            title={title}
            secondaryTitle={secondaryTitle}
            modalOpen={modalOpen}
          />
        )}
        <MobileContent scrollEvents onIonScroll={handleScroll}>
          {children}
          <DisclosuresLink href="/terms-of-use-and-disclosures" variant="link">
            Terms, Conditions, and Important Disclosures
          </DisclosuresLink>
        </MobileContent>
      </MobileContainer>
    );
  }

  return (
    <DesktopContainer className={className}>
      {children}
      <DisclosuresLink href="/terms-of-use-and-disclosures" variant="link">
        Terms, Conditions, and Important Disclosures
      </DisclosuresLink>
    </DesktopContainer>
  );
};

export default PageDetailLayout;
