import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import useDefaultAccount from 'api/hooks/clients/account/useDefaultAccount';
import useSetDefaultAccount from 'api/hooks/clients/account/useSetDefaultAccount';
import useDefaultPortfolio from 'api/hooks/clients/portfolio/useDefaultPortfolio';
import useSetDefaultPortfolio from 'api/hooks/clients/portfolio/useSetDefaultPortfolio';
import useClients from 'api/hooks/clients/useClients';
import type { MenuController } from 'components/Menu';

import { useLogic } from './logic';

export default function useConnect() {
  const menuRef = useRef<MenuController>(null);
  const { data: profiles } = useClients();
  const { account: defaultAccountName } = useDefaultAccount();
  const { portfolio: defaultPortfolioName } = useDefaultPortfolio();
  const setDefaultAccount = useSetDefaultAccount();
  const setDefaultPortfolio = useSetDefaultPortfolio();

  const defaultAccount = useMemo(
    () => profiles?.find((profile) => profile.name === defaultAccountName),
    [profiles, defaultAccountName],
  );

  const [selectedDefaultAccount, setSelectedDefaultAccount] = useState<
    string | undefined
  >(() => defaultAccount?.name);

  const [selectedDefaultPortfolio, setSelectedDefaultPortfolio] = useState<
    string | undefined
  >(defaultPortfolioName);

  useEffect(() => {
    setSelectedDefaultPortfolio(defaultPortfolioName);
  }, [defaultPortfolioName, setSelectedDefaultPortfolio]);

  useEffect(() => {
    setSelectedDefaultAccount(defaultAccountName);
  }, [defaultAccountName, setSelectedDefaultAccount]);

  const clientList = useMemo(
    () =>
      profiles?.map(({ id, name, subPortfolios }) => ({
        id,
        value: name,
        label: name,
        subPortfolios: subPortfolios?.map(
          ({ id: subPortfolioId, name: subPortfolioName }) => ({
            id: subPortfolioId,
            value: subPortfolioName,
            label: subPortfolioName,
          }),
        ),
      })),
    [profiles],
  );

  const portfolios = useMemo(
    () =>
      defaultAccount?.subPortfolios.map(({ id, name }) => ({
        id,
        value: name,
        label: name,
      })),
    [defaultAccount],
  );

  const handleAccountRadioGroupChange = useCallback(
    (e) => {
      const value = e.detail.value as string;
      const accountName = value.split('-');
      if (accountName[0]) {
        void setDefaultAccount({
          newDefaultAccount: accountName[0],
        });
        setSelectedDefaultAccount(accountName[0]);
      }
      if (accountName[1]) {
        void setDefaultPortfolio({
          portfolioName: accountName[1],
        });
        setSelectedDefaultPortfolio(accountName[1]);
      }
    },
    [
      setSelectedDefaultAccount,
      setDefaultAccount,
      setDefaultPortfolio,
      setSelectedDefaultPortfolio,
    ],
  );

  const handlePortfolioRadioGroupChange = useCallback(
    (e) => {
      const portfolioId = e.detail.value;
      void setDefaultPortfolio({
        portfolioName: portfolioId,
      });
      setSelectedDefaultPortfolio(portfolioId);
    },
    [setDefaultPortfolio, setSelectedDefaultPortfolio],
  );

  const {
    handleDisplaySearch,
    handleDisplaySettings,
    handleSearchChange,
    onChangeSelectedClient,
    onChangeSelectedPortfolio,
    searchValue,
    selectedClient,
    selectedPortfolio,
    openSearch,
    openSettings,
  } = useLogic({
    clients: profiles,
    menuRef,
  });

  return {
    clientList,
    clients: profiles ?? [],
    defaultAccount,
    handle: {
      changeSelectedClient: onChangeSelectedClient,
      changeSelectedPortfolio: onChangeSelectedPortfolio,
    },
    handleAccountRadioGroupChange,
    handleDisplaySearch,
    handleDisplaySettings,
    handlePortfolioRadioGroupChange,
    handleSearchChange,
    menuRef,
    openSearch,
    openSettings,
    portfolios,
    searchValue,
    selectedClient,
    selectedDefaultAccount,
    selectedDefaultPortfolio,
    selectedPortfolio,
    setSelectedDefaultAccount,
    setSelectedDefaultPortfolio,
  };
}
