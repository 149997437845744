import { FC, useEffect } from 'react';
import { useHistory } from 'react-router';

import ProfileMenu from 'components/ProfileMenu';
import { useNews } from 'containers/Services/NewsService';

import useConnect from '../connect';
import {
  pathnameConditions,
  pathnameLogOut,
  pathnameNotifications,
  pathnamePassword,
} from '../constants';
import { Container, Grid, Header } from './styles';
import type { Props } from './types';

const ProfileRoot: FC<Props> = ({ path }) => {
  const { replace } = useHistory();
  const newsServices = useNews();
  const { biometricsEnabled, biometricType, email, onToggleBiometrics } =
    useConnect();

  useEffect(() => {
    if (newsServices?.notificationToNews) {
      replace('/news');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsServices?.notificationToNews]);

  return (
    <Container title="Profile" parentUrl="/home" withSubtitle={false}>
      <Grid>
        <Header email={email} />
        <ProfileMenu
          biometricsEnabled={biometricsEnabled}
          biometricType={biometricType}
          onToggleBiometrics={onToggleBiometrics}
          pathnameConditions={`${path}${pathnameConditions}`}
          pathnameLogOut={`${path}${pathnameLogOut}`}
          pathnameNotifications={`${path}${pathnameNotifications}`}
          pathnamePassword={`${path}${pathnamePassword}`}
        />
      </Grid>
    </Container>
  );
};

export default ProfileRoot;
