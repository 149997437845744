import { useEffect } from 'react';
import { useHistory } from 'react-router';

import useEmail from 'api/hooks/auth/useEmail';
import useLoginStatus from 'api/hooks/auth/useLoginStatus';
import { useGoogleAnalytics } from 'containers/Services/GoogleAnalyticsService';

export function useTrackAppOpened() {
  const googleAnalytics = useGoogleAnalytics();

  useEffect(() => {
    googleAnalytics?.trackEvent('navigation', 'app-opened');
  }, [googleAnalytics]);
}

export function useTrackLocationChange() {
  const googleAnalytics = useGoogleAnalytics();
  const { listen } = useHistory();

  useEffect(() => {
    if (!googleAnalytics) {
      return () => {};
    }

    const unsubscribe = listen((location) => {
      void googleAnalytics.trackPageview(
        `${location.pathname}${location.search}`,
      );
    });

    return () => {
      unsubscribe();
    };
  }, [googleAnalytics, listen]);
}

export function useTrackUserID() {
  const googleAnalytics = useGoogleAnalytics();
  const loginStatus = useLoginStatus();
  const { data: email } = useEmail();

  useEffect(() => {
    if (loginStatus.type === 'logged-in' && email && googleAnalytics) {
      void googleAnalytics.setUserId(email);
    }
  }, [email, googleAnalytics, loginStatus]);
}
