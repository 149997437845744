import useGetNewsForEmail, {
  GET_NEWS_FOR_EMAIL_QUERY_KEY,
} from 'api/queries/clients/news/index';
import { useQuery } from 'api/storage';

export default function useNewsForEmail(email: string) {
  const { data, refetch } = useQuery(GET_NEWS_FOR_EMAIL_QUERY_KEY({ email }), {
    enabled: !!email,
    queryFn: useGetNewsForEmail(),
    refetchInterval: 60 * 60 * 1000,
    staleTime: 0,
  });

  return {
    data,
    refetch,
  };
}
