import type { GetNewsForAnEmailResponse } from 'api/queries/clients/news/types';
import type { WithoutNulls } from 'utils/withoutNulls';

type Input = NonNullable<GetNewsForAnEmailResponse['data']>[number];

export const normalizeNewsForAnEmail = (input: WithoutNulls<Input>) =>
  ({
    id: input.messageID,
    message: input.message,
    source: input.source,
    url: input.url,
    image: input.image,
    messageRead: input.messageRead,
    dateCreated: input.dateCreated,
    dateRead: input.readDate,
  } as const);

export type NewsForAnEmail = ReturnType<typeof normalizeNewsForAnEmail>;
