import useGetDailyVolatilityChartDataQuery, {
  GET_DAILY_VOLATILITY_CHART_DATA_QUERY_KEY,
} from 'api/queries/riskAnalysis/getDailyVolatilityChartData';
import { useQuery } from 'api/storage';

export default function useDailyVolatilityChartData(
  portfolio: string | undefined,
) {
  const { data } = useQuery(
    GET_DAILY_VOLATILITY_CHART_DATA_QUERY_KEY(portfolio ?? ''),
    {
      enabled: !!portfolio,
      queryFn: useGetDailyVolatilityChartDataQuery(),
    },
  );

  return {
    data,
  };
}
