import type { GetNotificationSettingsResponse } from 'api/queries/clients/notifications/types';
import type { WithoutNulls } from 'utils/withoutNulls';

type Input = NonNullable<GetNotificationSettingsResponse['data']>[number];

export const normalizeNotificationSetting = (input: WithoutNulls<Input>) =>
  ({
    emailID: input.emailID,
    platform: input.platform,
    token: input.token,
    deviceID: input.deviceID,
    dailyClose: input.dailyClose,
    newsUpdate: input.newsUpdate,
  } as const);

export type NotificationSetting = ReturnType<
  typeof normalizeNotificationSetting
>;
