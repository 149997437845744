import { FC, memo } from 'react';

import { Container, CVLogo, CVLogoWithOneFamily } from './styles';
import type { Props } from './types';

const Brand: FC<Props> = ({ className, withOneFamily }) => (
  <Container className={className}>
    {withOneFamily ? <CVLogoWithOneFamily /> : <CVLogo />}
  </Container>
);

export default memo(Brand);
