import type { FC } from 'react';
import { memo } from 'react';

import { formatAmount, formatAmountWithDecimals } from 'utils/amounts';

import { Container, Signs, Value } from './styles';
import type { Props } from './types';

const Amount: FC<Props> = ({
  as,
  className,
  coloured = false,
  value,
  withDecimals = false,
}) => {
  const formattedChars = Array.from(
    withDecimals ? formatAmountWithDecimals(value) : formatAmount(value),
  );
  const signs = formattedChars.splice(0, value >= 0 ? 1 : 2);
  const lastSign = value < 0 && formattedChars.splice(-1);

  return (
    <Container
      $coloured={coloured}
      $isNegative={value < 0}
      as={as}
      className={className}
    >
      <Signs $coloured={coloured}>{signs}</Signs>
      <Value>{formattedChars.join('')}</Value>
      <Signs $coloured={coloured}>{lastSign}</Signs>
    </Container>
  );
};

export default memo(Amount);
