/* eslint-disable import/no-cycle */
import type { FormikHelpers } from 'formik';
import { useCallback } from 'react';

import useLogin from 'api/hooks/auth/useLogin';
import type { LoginFormModel } from 'components/LoginForm';

export const useSubmitHandler = () => {
  const { loading, login } = useLogin();

  const onSubmit = useCallback(
    async (
      values: LoginFormModel,
      { setFieldError }: FormikHelpers<LoginFormModel>,
    ): Promise<boolean> => {
      try {
        const response = await login({
          ...values,
        });

        if (!response.accessToken) {
          setFieldError('password', 'Username or password invalid');
          return false;
        }

        return true;
      } catch (e) {
        const messageError = (e as Error).message;
        if (messageError === 'Account Locked') {
          setFieldError('password', messageError);
        } else {
          setFieldError(
            'password',
            'An unexpected error occurred, please try again later',
          );
        }

        return false;
      }
    },
    [login],
  );

  return { loading, onSubmit };
};
