import { useRouteMatch } from 'react-router-dom';

import usePortfolioHistoryChartData from 'api/hooks/charts/usePortfolioHistoryChartData';
import useProfitLossChartData from 'api/hooks/charts/useProfitLossChartData';
import useSelectedClient from 'api/hooks/clients/useSelectedClient';
import up from 'utils/paths';

export default function useConnect() {
  const { url } = useRouteMatch();
  const { selectedClient, selectedPortfolio } = useSelectedClient();

  const { data: profitLossChartData } = useProfitLossChartData({
    client: selectedClient?.id,
    portfolioName: selectedPortfolio?.name,
  });
  const { data: portfolioHistoryData } = usePortfolioHistoryChartData({
    client: selectedClient?.id,
    portfolioName: selectedPortfolio?.name,
  });

  const parentUrl = up(url);

  return {
    portfolioHistoryData,
    profitLossChartData,
    parentUrl,
  };
}
