import { curveMonotoneX } from '@visx/curve';
import { Group } from '@visx/group';
import { Bar } from '@visx/shape';
import { memo } from 'react';
import type { FC } from 'react';

import { useAccessors } from '../logic';
import { AreaClosed, Path } from './styles';
import type { Props } from './types';

const Chart: FC<Props> = ({
  bottom,
  data,
  handleTooltipClosed,
  handleTooltipUpdated,
  left,
  right,
  top,
  yScale,
  xScale,
  chartKey,
  chartId,
}) => {
  const { x, y } = useAccessors({ yScale, xScale, chartKey });
  return (
    <Group left={left}>
      <AreaClosed
        curve={curveMonotoneX}
        data={data}
        x={x}
        y={y}
        yScale={yScale}
        $chartKey={chartKey}
        $chartId={chartId}
      />
      <Path
        $chartKey={chartKey}
        curve={curveMonotoneX}
        data={data}
        x={x}
        y={y}
      />
      <Bar
        fill="transparent"
        height={bottom - top}
        width={Math.max(right - left, 0)}
        x={0}
        y={top}
        onMouseLeave={handleTooltipClosed}
        onMouseMove={handleTooltipUpdated}
        onTouchMove={handleTooltipUpdated}
        onTouchStart={handleTooltipUpdated}
      />
    </Group>
  );
};

export default memo(Chart);
