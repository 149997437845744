import styled, { css } from 'styled-components';

import DefaultAmount from 'components/Amount';
import {
  Signs as AmountSigns,
  Value as AmountValue,
} from 'components/Amount/styles';

export const Container = styled.div`
  color: ${({ theme }) => theme.colors.white};
  flex-direction: column;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1.5rem;
`;

export const Amount = styled(DefaultAmount)`
  display: block;
  font-weight: ${({ theme }) => theme.weights.bold};
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.white75};
  font-size: 0.875rem;
`;

export const Card = styled.div<{ $disabled?: boolean }>`
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.dark1};
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  font-size: 1.75rem;
  padding: 1.25rem;

  > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      ${AmountSigns}, ${AmountValue}, ${Label} {
        color: ${({ theme }) => theme.colors.white25};
      }
    `};
`;
