import { Line as DefaultLine } from '@visx/shape';
import styled from 'styled-components';

export const Group = styled.g`
  color: ${({ theme }) => theme.colors.primary};
`;

export const Line = styled(DefaultLine).attrs({
  stroke: 'currentColor',
})`
  stroke-width: 2px;
`;

export const Circle = styled.circle.attrs({
  fill: 'currentColor',
  stroke: 'currentColor',
})`
  stroke-width: 4px;
`;
