import type { FC } from 'react';
import { memo } from 'react';

import { formatBarKey, getBarKeyColor } from '../logic';
import { Box, Container, Item, Label } from './styles';
import type { Props } from './types';

const Legend: FC<Props> = ({ keys }) => (
  <Container>
    {keys.map((key) => (
      <Item key={key}>
        <Box $color={getBarKeyColor(keys.indexOf(key))} />
        <Label>{formatBarKey(key)}</Label>
      </Item>
    ))}
  </Container>
);

export default memo(Legend);
