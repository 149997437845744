import { useCallback } from 'react';

import useRequest from 'api/request';
import withoutNulls from 'utils/withoutNulls';

import type {
  SetUserDefaultAccountMutationVariables,
  SetUserDefaultAccountRequest,
  SetUserDefaultAccountResponse,
} from './types';

export default function useSetUserDefaultAccount() {
  const request = useRequest<
    SetUserDefaultAccountRequest,
    SetUserDefaultAccountResponse
  >();

  return useCallback(
    async ({ newDefaultAccount }: SetUserDefaultAccountMutationVariables) => {
      const response = await request('/api/ClientData/setUserDefaultAccount', {
        method: 'POST',
        body: newDefaultAccount,
      });

      const data = await response.json();

      if (!data.isSuccess) {
        throw new Error(data?.message ?? '');
      }

      return withoutNulls(data.data);
    },
    [request],
  );
}
