import {
  IonContent as DefaultIonContent,
  IonPage as DefaultIonPage,
} from '@ionic/react';
import styled from 'styled-components';

import DefaultTermsAndConditionsContent from 'components/TermsAndConditionsContent';
import from from 'styles/responsive';

export const IonPage = styled(DefaultIonPage)`
  --ion-background-color: ${({ theme }) => theme.colors.dark2};

  ${from.tablet`
    height: 100vh;
  `}
`;

export const IonContent = styled(DefaultIonContent).attrs({
  forceOverscroll: false,
})`
  display: flex;
`;

export const TermsAndConditionsContent = styled(
  DefaultTermsAndConditionsContent,
)`
  margin: 0 auto;
  max-width: 85rem;
  padding: 3.125rem 1.25rem 3.125rem;
  ${from.tablet`
    padding: 6.25rem 2.5rem 7.5rem;
  `}
`;
