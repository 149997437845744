import styled from 'styled-components';

import hexToRgb from 'utils/hexToRgb';

import type { $BarProps } from './types';

export const Bar = styled.rect.attrs<$BarProps>(
  ({ $isInstrumentTypeHover, $color, height, width, theme }) => ({
    fill: $isInstrumentTypeHover
      ? `rgba(${hexToRgb(
          theme.colors[$color as keyof typeof theme.colors],
        )}, 0.5)`
      : theme.colors[$color as keyof typeof theme.colors],
    stroke: $isInstrumentTypeHover
      ? theme.colors[$color as keyof typeof theme.colors]
      : 'none',
    strokeWidth: $isInstrumentTypeHover ? 4 : 0,
    strokeDasharray: `${width} ${height}`,
  }),
)<$BarProps>`
  cursor: pointer;
`;
