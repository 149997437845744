import type { FC } from 'react';

import NewsCard from 'components/NewsCard';
import { formatDate } from 'utils/dates';

import useConnect from './connect';
import {
  BaseListNews,
  BlueTickIcon,
  Container,
  ContainerEmpty,
  ContainerHeader,
  ContainerNews,
  Content,
  LoaderContainer,
  MarkAllAsReadButton,
  Spinner,
  SubtitleEmpty,
  Title,
} from './styles';

const News: FC = () => {
  const { news, newsRead, handleUpdateNewsReadStatus } = useConnect();

  const isNewsEmpty = () => news && news.length === 0;

  const getImageUrl = (path: string) =>
    `${path}?v=${new Date(new Date().setHours(0, 0, 0, 0)).getTime()}`;

  return (
    <>
      {!news && (
        <LoaderContainer>
          <Spinner />
        </LoaderContainer>
      )}
      <Container $loading={!news}>
        <Content $empty={isNewsEmpty() ?? false}>
          <Title>News</Title>
          {!isNewsEmpty() ? (
            <ContainerNews>
              <ContainerHeader>
                <MarkAllAsReadButton
                  className="mark-as-read-button"
                  onClick={() => handleUpdateNewsReadStatus(0)}
                >
                  <BlueTickIcon />
                  Mark all as read
                </MarkAllAsReadButton>
              </ContainerHeader>
              <BaseListNews>
                {news &&
                  news.map((n) => (
                    <NewsCard
                      key={n.id}
                      messageID={n.id ?? 0}
                      url={n.url}
                      message={n.message}
                      date={formatDate(new Date(n.dateCreated ?? 0))}
                      source={n.source ?? ''}
                      image={n.image ? getImageUrl(n.image) : undefined}
                      isRead={newsRead?.includes(n.id ?? 0) ?? false}
                      onUpdateNewsReadStatus={handleUpdateNewsReadStatus}
                    />
                  ))}
              </BaseListNews>
            </ContainerNews>
          ) : (
            <ContainerEmpty>
              <SubtitleEmpty>
                There have been no news messages received within the past thirty
                days. Please revisit at your convenience, and if you have not
                done so already, please make certain to Enable News
                notifications in the CV App settings.
              </SubtitleEmpty>
            </ContainerEmpty>
          )}
        </Content>
      </Container>
    </>
  );
};

export default News;
