import type { PickByValue } from 'utility-types';

import type { Liquidity } from 'model/Liquidity';

export default function getCashSummary(accounts: readonly Liquidity[]) {
  function sum(key: keyof PickByValue<Liquidity, number>) {
    return accounts.reduce((total, account) => account[key] + total, 0);
  }

  return {
    cashUSD: sum('cashUSD'),
    cashOther: sum('cashOther'),
    cashEquivalent: sum('cashEquivalent'),
    totalCashAvailable: sum('totalCashAvailable'),
    totalLiquidityAvailable: sum('totalLiquidityAvailable'),
    treasuries: sum('treasuries'),
  };
}
