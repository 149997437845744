import useGetIncomeProfileR12Query, {
  GET_INCOME_PROFILE_R12_QUERY_KEY,
} from 'api/queries/allocations/getIncomeProfileR12';
import { useQuery } from 'api/storage';

export default function useIncomeProfileR12(portfolio: string | undefined) {
  const { data } = useQuery(GET_INCOME_PROFILE_R12_QUERY_KEY(portfolio ?? ''), {
    enabled: !!portfolio,
    queryFn: useGetIncomeProfileR12Query(),
  });

  return {
    data,
  };
}
