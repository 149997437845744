import styled, { css } from 'styled-components';

import { ReactComponent as DefaultVectorDownIcon } from 'assets/icons/vector-down.svg';
import { ReactComponent as DefaultVectorUpIcon } from 'assets/icons/vector-up.svg';
import DefaultBaseListButton from 'components/BaseListButton';
import { RowBase } from 'components/BaseListButton/styles';
import from from 'styles/responsive';

export const FirstCol = styled.div`
  text-align: left;
`;

export const Table = styled.div`
  display: grid;
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.875rem;
  line-height: 1.0625rem;
`;

export const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 0.2fr;
  padding: 1rem 2rem;
  column-gap: 0.5rem;
`;

export const Header = styled.div<{ $isSinceInception: boolean }>`
  display: grid;
  grid-template-columns: 1.5fr repeat(2, 0.6fr);
  column-gap: 0.5rem;
  color: ${({ theme }) => theme.colors.white75};
  text-transform: capitalize;
  text-align: right;
  > :first-child {
    text-align: left;
  }
  ${from.desktop`
    grid-template-columns: 1.5fr repeat(2, 1fr);
  `}
  ${({ $isSinceInception }) =>
    $isSinceInception &&
    css`
      grid-template-columns: 1.5fr 0.6fr;

      ${from.desktop`
        grid-template-columns: 1.5fr 1fr;
      `}
    `};
`;

export const BaseListButton = styled(DefaultBaseListButton)`
  ${RowBase} {
    padding: 1rem 1.25rem;
  }
  ${from.tablet`
    ${RowBase} {
      padding: 1rem 2rem;
    `}
`;

export const Content = styled.div`
  border-radius: 1rem;
  overflow: hidden;
  > :not(:last-child) > div {
    margin-bottom: 0.125rem;
  }
`;

export const HeaderCell = styled.div``;

export const HeaderTitle = styled.span`
  cursor: pointer;
  display: inline-flex;
`;

export const Row = styled.div<{ $isSinceInception: boolean }>`
  display: grid;
  grid-template-columns: 1.5fr 0.8fr;
  grid-template-rows: 1fr 1fr;
  justify-items: flex-start;
  grid-row-gap: 4px;
  font-size: 1rem;
  line-height: 1.1875rem;
  > :first-child {
    grid-row: 1;
    grid-column: 1;
  }
  > :nth-child(2) {
    grid-row: 2;
    grid-column: 1;
  }
  > :last-child {
    grid-row: 2;
    grid-column: 2;
    justify-self: right;
  }
  ${from.tablet`
    grid-template-columns: 1.5fr repeat(2, 0.6fr);
    grid-template-rows: 1fr;
    > :first-child {
      grid-row: auto;
      grid-column: auto;
    }
    > :nth-child(2) {
      grid-row: auto;
      grid-column: auto;
      justify-self: right;
    }
    > :last-child {
      grid-row: auto;
      grid-column: auto;
    }
  `}
  ${from.desktop`
    grid-template-columns: 1.5fr repeat(2, 1fr);
  `}
  ${({ $isSinceInception }) =>
    $isSinceInception &&
    css`
      > :nth-child(2) {
        grid-row: 2;
        grid-column: 1;
        justify-self: left;
      }
      ${from.tablet`
        grid-template-columns: 1.5fr 0.6fr;
        > :nth-child(2) {
          grid-row: auto;
          grid-column: auto;
          justify-self: right;
        }
      `}
    `};
`;

export const Col = styled.div`
  text-align: right;
`;

export const VectorDownIcon = styled(DefaultVectorDownIcon)``;

export const VectorUpIcon = styled(DefaultVectorUpIcon)``;
