import styled, { css } from 'styled-components';

import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';

import type { CellProps } from './types';

export const Container = styled.div<{
  $isNegative?: CellProps['$isNegative'];
}>`
  color: ${({ theme }) => theme.colors.statusIncrease};
  ${({ $isNegative }) =>
    $isNegative &&
    css`
      color: ${({ theme }) => theme.colors.statusDecrease};
    `}
`;

export const Arrow = styled(ArrowUp)<{
  $isNegative?: CellProps['$isNegative'];
}>`
  margin-left: 0.375rem;
  ${({ $isNegative }) =>
    $isNegative &&
    css`
      transform: rotate(180deg);
    `}
`;
