import { FC, memo, useEffect, useState } from 'react';

import from from 'styles/responsive';
import type { KeySortOfColumn, OrderOrientation } from 'utils/sort/types';
import useMedia from 'utils/useMedia';

import DesktopAndTabletVersion from './DesktopAndTabletVersion';
import MobileVersion from './MobileVersion';
import type { Props } from './types';

const MarketOverviewTable: FC<Props> = ({
  data,
  onPin,
  onUnpin,
  selectedData = 'Daily Change',
  headerColumns,
  currentSorting,
  onChangeSorting,
}) => {
  const [sortColumn, setSortColumn] = useState<string>('Daily Change');
  const [sortOrientation, setSortOrientation] = useState<string>('DESC');

  const isDesktop = useMedia(from.tablet.query);

  useEffect(() => {
    if (headerColumns) {
      const currentSortingAux = currentSorting ?? 'default';

      const headerColumn = headerColumns.find(
        (col) =>
          col.keyOrderAsc === currentSortingAux ||
          col.keyOrderDesc === currentSortingAux,
      );

      if (headerColumn) {
        setSortColumn(headerColumn.titleColumn);
        setSortOrientation(
          headerColumn.keyOrderAsc === currentSortingAux ? 'ASC' : 'DESC',
        );
      }
    }
  }, [headerColumns, currentSorting]);

  const handleSortByColumn = (sortByColumnSelected: KeySortOfColumn) => {
    if (onChangeSorting) {
      if (sortByColumnSelected.titleColumn === sortColumn) {
        onChangeSorting(
          sortOrientation === 'DESC'
            ? sortByColumnSelected.keyOrderAsc
            : sortByColumnSelected.keyOrderDesc,
        );
      } else if (sortByColumnSelected.titleColumn !== sortColumn) {
        onChangeSorting(sortByColumnSelected.keyOrderDesc);
      }
    }
  };

  if (isDesktop && headerColumns) {
    return (
      <DesktopAndTabletVersion
        data={data}
        onPin={onPin}
        onUnpin={onUnpin}
        handleSortByColumn={handleSortByColumn}
        sortApplied={{
          titleColumn: sortColumn,
          orderOrientation: sortOrientation as OrderOrientation,
        }}
        headerColumns={headerColumns}
      />
    );
  }

  return (
    <MobileVersion
      data={data}
      onPin={onPin}
      onUnpin={onUnpin}
      selectedData={selectedData}
    />
  );
};

export default memo(MarketOverviewTable);
