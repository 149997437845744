import styled from 'styled-components';

import DefaultBrand from 'components/Brand';
import DefaultButton from 'components/Button';

export const Container = styled.div``;

export const Brand = styled(DefaultBrand)`
  max-width: 12.8125rem;
  margin-bottom: 2.5rem;
`;

export const RichText = styled.div`
  font-size: 1.2rem;
  margin: 0;

  * {
    font-family: ${({ theme }) => theme.fonts.GTAmerica} !important;
  }

  a {
    color: ${({ theme }) => theme.colors.primary};

    :visited {
      color: ${({ theme }) => theme.colors.dataBlue};
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  margin-top: 3.125rem;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;

export const Button = styled(DefaultButton)`
  min-width: 12.5rem;
  text-transform: uppercase;
`;

export const GoBackButton = styled(Button)`
  min-width: auto;
  margin-right: 10px;
`;
