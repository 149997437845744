import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import useUpdateNewsReadStatusForEmail from 'api/mutations/clients/news';
import { GET_NEWS_FOR_EMAIL_QUERY_KEY } from 'api/queries/clients/news';

export default function useUpdateNewsReadStatus() {
  const client = useQueryClient();
  const { mutateAsync } = useMutation(useUpdateNewsReadStatusForEmail(), {
    onSuccess: () => {
      void client.invalidateQueries([GET_NEWS_FOR_EMAIL_QUERY_KEY]);
    },
  });

  return useCallback(
    async ({ email, messageID }: { email: string; messageID: number }) =>
      mutateAsync({ email, messageID }),
    [mutateAsync],
  );
}
