import styled, { css } from 'styled-components';

import DefaultAssetAllocationHistoryChart from 'components/AssetAllocationHistoryChart';
import DefaultClientMonthlyAllocationBySubtypeChart from 'components/ClientMonthlyAllocationBySubtypeChart';
import Select from 'components/Select';
import DefaultSpinner from 'components/Spinner';
import PageDetailLayout from 'containers/Common/PageDetailLayout';
import from from 'styles/responsive';

export const LoaderContainer = styled.div`
  position: sticky;
  top: 50%;
  padding-left: 50%;
  z-index: 1000;
`;

export const Container = styled(PageDetailLayout)<{
  $loading: boolean;
  $mobile: boolean;
}>`
  ${({ $loading }) =>
    $loading &&
    css`
      pointer-events: none;
      filter: blur(1px);
    `};

  ${({ $loading, $mobile }) =>
    $loading &&
    !$mobile &&
    css`
      margin-top: -4.5rem;
    `};
`;

export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  ${from.tablet`
    margin-bottom: 1.5rem;
    flex-wrap: nowrap;
  `}
`;

export const Title = styled.h2`
  font-size: 1.375rem;
  line-height: 1.4;
  font-weight: ${({ theme }) => theme.weights.regular};
`;

export const FilterButton = styled(Select)<{
  $isTablet: boolean;
}>`
  width: fit-content;
  margin-left: 0.75rem;
  margin: ${({ $isTablet }) =>
    $isTablet ? '0 0 0 0.75rem' : '1.5rem 0 0 3.5rem'};
`;

export const AssetAllocationHistoryChart = styled(
  DefaultAssetAllocationHistoryChart,
)`
  margin-bottom: 2rem;
`;

export const ClientMonthlyAllocationBySubtypeChart = styled(
  DefaultClientMonthlyAllocationBySubtypeChart,
)`
  margin-bottom: 2rem;
`;

export const Spinner = styled(DefaultSpinner)``;
