import kebabCaseCustom from 'utils/kebabCaseCustom';

import { Datum, instrumentTypeOrdered } from './types';

export function getDataOrdered(data: readonly Datum[]) {
  const result: Datum[] = [];

  instrumentTypeOrdered.forEach((instrumentType: string) => {
    const individualData = data.find(
      (d: Datum) =>
        kebabCaseCustom(d.instrumentType) === kebabCaseCustom(instrumentType),
    );

    if (individualData) {
      result.push(individualData);
    }
  });

  return result;
}
