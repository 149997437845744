import { nanoid } from 'nanoid';

import type { GetAssetAllocationTargetAndRangesChartDataResponse } from 'api/queries/charts/getAssetAllocationTargetAndRangesChartData/types';
import type { WithoutNulls } from 'utils/withoutNulls';

type Input = NonNullable<
  GetAssetAllocationTargetAndRangesChartDataResponse['data']
>[number];

export const normalizeAssetAllocationTargetAndRangesDatum = (
  input: WithoutNulls<Input>,
) =>
  ({
    id: nanoid(),
    instrumentType: input.instrumentType ?? '',
    date: input.date ? new Date(input.date).getTime() : 0,
    current: input.percentage ? input.percentage / 100 : 0,
    target: input.target ? input.target / 100 : 0,
    range: {
      lowerBound: input.lowerBound ? input.lowerBound / 100 : 0,
      upperBound: input.upperBound ? input.upperBound / 100 : 0,
    },
    balance: input.balance ?? 0,
  } as const);

export type AssetAllocationTargetAndRangesDatum = ReturnType<
  typeof normalizeAssetAllocationTargetAndRangesDatum
>;
