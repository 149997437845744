import { ErrorMessage, useField } from 'formik';
import type { FC } from 'react';

import { Checkbox, Container, Label } from './styles';
import type { Props } from './types';

const FormikIonCheckbox: FC<Props> = ({ name, label }) => {
  const [{ value }, , { setValue }] = useField<boolean | undefined>(name);

  const onClick = () => {
    setValue(!value);
  };

  return (
    <>
      <Container onClick={onClick}>
        <Checkbox id="1" checked={value} value={name} />
        <Label>{label ?? ''}</Label>
      </Container>
      <ErrorMessage name={name} />
    </>
  );
};

export default FormikIonCheckbox;
