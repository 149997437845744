import styled from 'styled-components';

import BaseAssetDetailTable from 'components/BaseAssetDetailTable';
import PageDetailLayout from 'containers/Common/PageDetailLayout';
import from from 'styles/responsive';

export const Container = styled(PageDetailLayout)``;

export const FilterLabelContainer = styled.div`
  font-size: 0.75rem;
  line-height: 0.875rem;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1.25rem 0;
`;

export const Content = styled.div`
  padding: 2rem 1.25rem;
  ${from.tablet`
    padding: 0;
  `}
`;

export const Table = styled(BaseAssetDetailTable)`
  > :last-child {
    border-bottom: none;
  }
`;
