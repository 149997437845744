import groupBy from 'lodash/groupBy';
import { nanoid } from 'nanoid';
import { useCallback } from 'react';
import type { QueryFunction } from 'react-query';

import useRequest from 'api/request';
import {
  AssetAllocationHistoryDatum,
  normalizeAssetAllocationHistoryDatum,
} from 'model/AssetAllocationHistoryDatum';
import getPortfolioId from 'utils/getPorfolioId';
import withoutNulls from 'utils/withoutNulls';

import type {
  GetAssetAllocationHistoryChartDataRequest,
  GetAssetAllocationHistoryChartDataResponse,
} from './types';

export type Datum = {
  id: string;
  date: number;
  alternatives: number;
  cash: number;
  commodity: number;
  equityIndividual: number;
  equityMarket: number;
  fixedIncome: number;
  privateInvestments: number;
};

export const GET_ASSET_ALLOCATION_HISTORY_CHART_DATA_QUERY_KEY = (
  portfolio: string,
) => ['charts/getAllocationsHistoryChartData', portfolio];

const getPercByInstrument = (
  instrumentType: string,
  array: AssetAllocationHistoryDatum[],
) => array.find((d) => d.instrumentType === instrumentType)?.percentage;

export default function useGetAssetAllocationHistoryChartDataQuery(): QueryFunction<
  Datum[]
> {
  const request = useRequest<
    GetAssetAllocationHistoryChartDataRequest,
    GetAssetAllocationHistoryChartDataResponse
  >();

  return useCallback(
    async ({ queryKey }) => {
      const [, portfolio] = queryKey as [string, string];

      const response = await request(
        '/api/CVData/getHistoricalAllocationsAndTargetsByInstrument/{portfolio}',
        {
          urlParams: {
            portfolio: getPortfolioId(portfolio) ?? '',
          },
        },
      );

      const data = await response.json();

      if (!data.isSuccess) {
        throw new Error(data.message ?? '');
      }

      const allItems = withoutNulls(data.data ?? []).map(
        normalizeAssetAllocationHistoryDatum,
      );

      const groupedByMonthAndYear = groupBy(allItems, (item) => item.date);

      const result: Datum[] = [];

      // eslint-disable-next-line guard-for-in
      for (const key in groupedByMonthAndYear) {
        const array = groupedByMonthAndYear[
          key
        ] as AssetAllocationHistoryDatum[];

        result.push({
          id: nanoid(),
          date: array[0]?.date ?? 0,
          alternatives: getPercByInstrument('Alternatives', array) ?? 0,
          cash: getPercByInstrument('Cash', array) ?? 0,
          commodity: getPercByInstrument('Commodity', array) ?? 0,
          equityIndividual:
            getPercByInstrument('Equity-Individual', array) ?? 0,
          equityMarket: getPercByInstrument('Equity-Market', array) ?? 0,
          fixedIncome: getPercByInstrument('Fixed Income', array) ?? 0,
          privateInvestments:
            getPercByInstrument('Private Investments', array) ?? 0,
        });
      }

      return withoutNulls(result ?? []);
    },
    [request],
  );
}
