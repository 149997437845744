import styled, { css } from 'styled-components';

import { ReactComponent as DefaultBlueTick } from 'assets/icons/blue-tick.svg';
import DefaultSpinner from 'components/Spinner';
import PageLayout from 'containers/Common/PageLayout';
import from from 'styles/responsive';

export const LoaderContainer = styled.div`
  position: sticky;
  top: 50%;
  padding-left: 50%;
  z-index: 1000;
`;

export const Container = styled(PageLayout)<{
  $loading: boolean;
}>`
  ${({ $loading }) =>
    $loading &&
    css`
      pointer-events: none;
      filter: blur(1px);
    `};
`;

export const Content = styled.div<{ $empty: boolean }>`
  max-width: 68.75rem;

  ${({ $empty }) =>
    $empty &&
    css`
      height: 92%;
    `}
`;

export const Title = styled.h2`
  display: none;

  ${from.tablet`
    display: flex;
    color: ${({ theme }) => theme.colors.white};
    margin: 0 0 1rem 0;
    font-size: 1.75rem;
    line-height: 1.4;
    margin-bottom: 1rem;
  `}
`;

export const ContainerNews = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 1.25rem;

  ${from.tablet`
    margin: 0;
  `}
`;

export const ContainerHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1.5rem 0;
`;

export const BaseListNews = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MarkAllAsReadButton = styled.div`
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  display flex;
  align-items: center;

  ${from.laptop`
    &:hover {
      color: ${({ theme }) => theme.colors.primary};
  
      path {
        fill: ${({ theme }) => theme.colors.primary};
      }
    }
  `}
`;

export const BlueTickIcon = styled(DefaultBlueTick)`
  margin-right: 0.5rem;
  path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

export const ContainerEmpty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8rem 3rem;
`;

export const TitleEmpty = styled.div`
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  margin: 2rem;
`;

export const SubtitleEmpty = styled.div`
  text-align: center;
`;

export const Spinner = styled(DefaultSpinner)``;
