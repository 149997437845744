import styled from 'styled-components';

import { ReactComponent as Close } from 'assets/icons/close.svg';
import Button from 'components/Button';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.dark2};
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0px;
  overflow: hidden;
`;

export const Header = styled.div`
  padding: 2.0938rem 1rem 0.1875rem 1.25rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.white10};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CloseIcon = styled(Close)`
  color: ${({ theme }) => theme.colors.white};
`;
export const ApplyButton = styled(Button)`
  color: ${({ theme }) => theme.colors.primary};
  padding: 0;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: 1.4;
  padding-inline-start: 0px;
  padding-inline-start: 0px;
  > .button-native {
    min-width: 45px;
    --padding-inline-start: 0px;
    --padding-inline-start: 0px;
    --padding: 0;
  }
  min-width: auto;
  --background: transparent;
`;

export const Content = styled.div`
  padding: 2rem 1.25rem 4rem;
  color: ${({ theme }) => theme.colors.white};
  overflow: auto;
  max-height: 80vh;
`;
