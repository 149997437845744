import { AreaClosed as DefaultAreaClosed, LinePath } from '@visx/shape';
import type { AreaClosedProps } from '@visx/shape/lib/shapes/AreaClosed';
import type { LinePathProps } from '@visx/shape/lib/shapes/LinePath';
import styled from 'styled-components';

import { areaGradientId } from '../styles';
import type { Datum } from '../types';

function DatumAreaClosed(props: AreaClosedProps<Datum>) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <DefaultAreaClosed<Datum> {...props} />;
}

function DatumLinePath(props: LinePathProps<Datum>) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <LinePath<Datum> {...props} />;
}

export const AreaClosed = styled(DatumAreaClosed).attrs(() => ({
  fill: `url(#${areaGradientId})`,
}))``;

export const Path = styled(DatumLinePath).attrs({ stroke: 'currentColor' })`
  color: ${({ theme }) => theme.colors.primary};
  stroke-width: 4px;
`;
