import useGetNotificationSettings, {
  GET_NOTIFICATION_SETTINGS_QUERY_KEY,
} from 'api/queries/clients/notifications';
import type { GetNotificationSettingsRequestParams } from 'api/queries/clients/notifications/types';
import { useQuery } from 'api/storage';

export default function useNotificationSettings({
  deviceID,
}: GetNotificationSettingsRequestParams) {
  const { isLoading, data } = useQuery(
    GET_NOTIFICATION_SETTINGS_QUERY_KEY({ deviceID }),
    {
      enabled: !!deviceID,
      queryFn: useGetNotificationSettings(),
    },
  );
  return { isLoading, data };
}
