import { QueryClient } from 'react-query';

// This function will be async in the future where we will persist and rehydrate
// the cache.
// eslint-disable-next-line @typescript-eslint/require-await
export default async function createClient(): Promise<QueryClient> {
  return new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: Infinity,
        /*
         * By default we only want to revalidate on component mount. If we ever
         * need more precise revalidation we will explicitly enable it.
         */
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    },
  });
}
