import styled from 'styled-components';

import SectionNavigation from 'components/SectionNavigation';
import Tabs from 'components/Tabs';
import { TabButton } from 'components/Tabs/styles';

export const Container = styled.div`
  display: grid;
  grid-template-rows: repeat(4, auto);
`;

export const MainTabs = styled(Tabs)``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 1.25rem;
`;

export const TableTabs = styled(Tabs)`
  margin-bottom: 1.75rem;
  ${TabButton} {
    min-width: 4.5rem;
  }
` as typeof Tabs;

export const Navigation = styled(SectionNavigation)`
  padding: 2rem 1.25rem;
`;
