import { FC, memo, useMemo } from 'react';

import from from 'styles/responsive';
import useMedia from 'utils/useMedia';

import Chart from './Chart';
import {
  getTicksX,
  useEdgeValues,
  useHeight,
  useMargins,
  useScales,
  useTooltip,
} from './logic';
import {
  AxisBottom,
  AxisBottomTitleWrapper,
  AxisLeft,
  BottomTitle,
  ChartContainer,
  GraphVector,
  Grid,
  LeftTitle,
} from './styles';
import Tooltip from './Tooltip';
import type { Props } from './types';

const DailyVolatilityChart: FC<Props> = ({
  className,
  data: readonlyData,
  width,
}) => {
  // visx wants mutable arrays even though it will never mutate them
  const data = useMemo(() => [...readonlyData], [readonlyData]);

  const isTablet = useMedia(from.tablet.query);

  const height = useHeight({ isTablet });

  const {
    bottomAxisHeight,
    bottomAxisMargin,
    leftAxisMargin,
    leftAxisWidth,
    rightMargin,
    topMargin,
  } = useMargins({ isTablet });
  const { maxValue, minValue } = useEdgeValues(data);
  const { xScale, yScale } = useScales({
    data,
    bottomAxisHeight,
    bottomAxisMargin,
    height,
    leftAxisMargin,
    leftAxisWidth,
    maxValue,
    minValue,
    rightMargin,
    topMargin,
    width,
  });

  const right = width - leftAxisWidth - leftAxisMargin - rightMargin;
  const barWidth = Math.max(right / data.length / 2, 0);

  const {
    handleTooltipUpdated,
    handleTooltipClosed,
    tooltipData,
    tooltipLeft,
    tooltipTop,
  } = useTooltip({
    data,
  });

  return (
    <ChartContainer className={className}>
      <GraphVector
        width="100%"
        viewBox={`0 0 ${isTablet ? width : width - 15} ${height + 70}`}
      >
        <Grid
          scale={yScale}
          width={
            width + data.length - leftAxisWidth - leftAxisMargin - rightMargin
          }
          left={leftAxisWidth + leftAxisMargin - data.length / 2}
        />
        <AxisLeft $isTablet={isTablet} left={leftAxisWidth} scale={yScale} />
        <AxisBottom
          $isTablet={isTablet}
          left={leftAxisWidth + leftAxisMargin}
          tickValues={isTablet ? getTicksX(data) : undefined}
          numTicks={isTablet ? undefined : 5}
          scale={xScale}
          top={height - bottomAxisHeight}
        />
        <LeftTitle
          $x={isTablet ? 34 : 10}
          $y={height / 2}
          dominantBaseline="central"
          textAnchor="middle"
        >
          Number of Days
        </LeftTitle>
        <Chart
          barWidth={barWidth}
          handleTooltipUpdated={handleTooltipUpdated}
          handleTooltipClosed={handleTooltipClosed}
          bottom={height - bottomAxisHeight - bottomAxisMargin}
          data={data}
          left={leftAxisWidth + leftAxisMargin}
          yScale={yScale}
          xScale={xScale}
        />
      </GraphVector>
      <AxisBottomTitleWrapper>
        <BottomTitle>Daily Returns</BottomTitle>
      </AxisBottomTitleWrapper>
      {typeof tooltipData !== 'undefined' &&
        typeof tooltipLeft === 'number' &&
        typeof tooltipTop === 'number' && (
          <Tooltip
            data={tooltipData}
            tooltipLeft={tooltipLeft}
            tooltipTop={tooltipTop}
          />
        )}
    </ChartContainer>
  );
};

export default memo(DailyVolatilityChart);
