import styled from 'styled-components';

export const Table = styled.div`
  display: grid;
  color: ${({ theme }) => theme.colors.white};
  font-size: 1rem;
  line-height: 1.1875rem;
  color: ${({ theme }) => theme.colors.white};
  > :nth-child(even) {
    background: ${({ theme }) => theme.colors.dark1};
  }
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 0.5rem;
  padding: 1rem 1.5rem;
  color: ${({ theme }) => theme.colors.white75};
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  text-align: right;
  > :first-child {
    text-align: left;
  }
`;

export const HeaderCell = styled.div`
  font-size: 0.875rem;
  line-height: 1.0625rem;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 0.5rem;
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.white10};
  padding: 1rem 1.5rem;
`;

export const FirstCol = styled.div`
  text-align: left;
  padding: 0 0.25rem;
  font-size: 1rem;
  line-height: 1.0625rem;
`;

export const LastRow = styled(Row)`
  font-weight: ${({ theme }) => theme.weights.bold};
`;

export const LastFirstCol = styled(FirstCol)`
  font-size: 0.875rem;
`;

export const Col = styled.div`
  text-align: right;
`;
