import { localPoint } from '@visx/event';
import { scaleLinear, scaleTime } from '@visx/scale';
import { useTooltip as useVisxTooltip } from '@visx/tooltip';
import type { UseTooltipParams } from '@visx/tooltip/lib/hooks/useTooltip';
import { bisector } from 'd3-array';
import { MouseEvent, TouchEvent, useCallback, useMemo, useState } from 'react';

import keysOf from 'utils/keysOf';

import type { ChartKey, Datum } from './types';

const bisectDate = bisector<Datum, Date>((d) => new Date(d.date)).left;

const getDate = (d: Datum) => d.date;

export function useAccessors({
  xScale,
  yScale,
  chartKey,
}: ReturnType<typeof useScales> & { chartKey: ChartKey }) {
  const x = useCallback((d: Datum) => xScale(getDate(d)) ?? 0, [xScale]);
  const y = useCallback(
    (d: Datum) => yScale(d[chartKey]) ?? 0,
    [yScale, chartKey],
  );

  return { x, y };
}

export function useHeight({
  isLaptop,
  isTablet,
}: {
  isLaptop: boolean;
  isTablet: boolean;
}): number {
  if (isLaptop) {
    return 488;
  }

  if (isTablet) {
    return 428;
  }

  return 182;
}

export function useEdgeValues(data: readonly Datum[], chartKeys: ChartKey[]) {
  const flattenedData = useMemo(
    () => data.flatMap((datum) => chartKeys.map((chartKey) => datum[chartKey])),
    [data, chartKeys],
  );

  const start = useMemo(
    () =>
      data.reduce(
        (lowestTime, datum) => Math.min(lowestTime, datum.date),
        Infinity,
      ),
    [data],
  );

  const end = useMemo(
    () =>
      data.reduce((highestTime, datum) => Math.max(highestTime, datum.date), 0),
    [data],
  );

  const minValue = useMemo(() => Math.min(...flattenedData), [flattenedData]);
  const maxValue = useMemo(() => Math.max(...flattenedData), [flattenedData]);

  return {
    end,
    maxValue: maxValue + 0.03,
    minValue: Math.max(0, minValue),
    start,
  };
}

export function useMargins({ isTablet }: { isTablet: boolean }) {
  const topMargin = 16;

  const rightMargin = 32;

  const bottomAxisHeight = 56;
  const bottomAxisMargin = 12;

  const leftAxisWidth = isTablet ? 70 : 40;
  const leftAxisMargin = 16;

  return {
    bottomAxisHeight,
    bottomAxisMargin,
    leftAxisMargin,
    leftAxisWidth,
    rightMargin,
    topMargin,
  };
}

export function useScales(props: {
  bottomAxisHeight: number;
  bottomAxisMargin: number;
  end: number;
  height: number;
  leftAxisMargin: number;
  leftAxisWidth: number;
  maxValue: number;
  minValue: number;
  rightMargin: number;
  start: number;
  topMargin: number;
  width: number;
}) {
  // Destructured here rather than on the "props" parameter itself so that the
  // parameter hint in IntelliJ based IDEs isn't gigantic.
  const {
    bottomAxisHeight,
    bottomAxisMargin,
    end,
    height,
    leftAxisMargin,
    leftAxisWidth,
    maxValue,
    minValue,
    rightMargin,
    start,
    topMargin,
    width,
  } = props;

  const xScale = useMemo(
    () =>
      scaleTime({
        range: [0, width - leftAxisWidth - leftAxisMargin - rightMargin],
        domain: [start, end],
      }),
    [end, leftAxisMargin, leftAxisWidth, start, rightMargin, width],
  );

  const yScale = useMemo(
    () =>
      scaleLinear({
        range: [height - bottomAxisHeight - bottomAxisMargin, topMargin],
        domain: [minValue, maxValue],
        clamp: true,
      }),
    [bottomAxisHeight, bottomAxisMargin, height, maxValue, minValue, topMargin],
  );

  return { xScale, yScale };
}

export function useTooltip({
  data,
  leftAxisMargin,
  leftAxisWidth,
  xScale,
  yScale,
  chartKeys,
}: {
  data: readonly Datum[];
  leftAxisMargin: number;
  leftAxisWidth: number;
  xScale: ReturnType<typeof useScales>['xScale'];
  yScale: ReturnType<typeof useScales>['yScale'];
  chartKeys: ChartKey[];
}) {
  const { hideTooltip, showTooltip, tooltipData, tooltipLeft, tooltipTop } =
    useVisxTooltip<Datum>();

  const [chartKeysY, setChartKeysY] = useState<{ [Key in ChartKey]: number }>();

  const createTooltipEventHandler = useCallback(
    (show: UseTooltipParams<Datum>['showTooltip']) =>
      (event: MouseEvent<SVGRectElement> | TouchEvent<SVGRectElement>) => {
        const { x } = localPoint(event) ?? { x: 0 };

        // The x from the event includes the left margin
        const adjustedX = x - leftAxisMargin - leftAxisWidth;

        const selectedDate = xScale.invert(adjustedX);
        const index = bisectDate(data, selectedDate, 1);

        const datumLeft = data[index - 1];
        const datumRight = data[index];

        const closestDatum = (() => {
          if (!datumLeft && !datumRight) {
            return undefined;
          }

          if (!datumLeft) {
            return datumRight;
          }

          if (!datumRight) {
            return datumLeft;
          }

          const differenceToLeft = selectedDate.getTime() - getDate(datumLeft);
          const differenceToRight =
            getDate(datumRight) - selectedDate.getTime();

          if (differenceToLeft > differenceToRight) {
            return datumRight;
          }

          return datumLeft;
        })();

        if (closestDatum) {
          const keysYPosition = chartKeys.reduce(
            (acc, key) => ({ ...acc, [key]: yScale(closestDatum[key]) }),
            {} as { [Key in ChartKey]: number },
          );

          const highestKeyPosition = keysOf(keysYPosition)
            .map((key) => [key, keysYPosition[key]] as const)
            .sort((a, b) => a[1] - b[1])
            .shift()?.[0] as ChartKey;

          setChartKeysY(keysYPosition);
          show({
            tooltipData: closestDatum,
            tooltipLeft: x,
            tooltipTop: keysYPosition[highestKeyPosition],
          });
        }
      },
    [data, leftAxisMargin, leftAxisWidth, xScale, yScale, chartKeys],
  );

  const handleTooltipUpdated = useMemo(
    () => createTooltipEventHandler(showTooltip),
    [createTooltipEventHandler, showTooltip],
  );

  return {
    handleTooltipClosed: hideTooltip,
    handleTooltipUpdated,
    tooltipData,
    tooltipLeft,
    tooltipTop,
    chartKeysY,
  };
}
