import type { FC } from 'react';

import from from 'styles/responsive';
import useMedia from 'utils/useMedia';

import { Container, DesktopContainer } from './styles';
import type { Props } from './types';

const MainContentGrid: FC<Props> = ({ children, className }) => {
  const isMobile = !useMedia(from.tablet.query);

  if (isMobile) {
    return <Container className={className}>{children}</Container>;
  }

  return <DesktopContainer className={className}>{children}</DesktopContainer>;
};

export default MainContentGrid;
