import uniq from 'lodash/uniq';
import { useMemo } from 'react';

import useAllocationGroups from 'api/hooks/allocations/useAllocationGroups';
import { formatAmount } from 'utils/amounts';
import { getFixedIncomeCharacteristics } from 'utils/fixedIncome';
import kebabCaseCustom from 'utils/kebabCaseCustom';
import { formatPercentage } from 'utils/percentages';

import { groupingLabels } from '../AllocationsRoot/logic';
import {
  useAssets,
  useCurrentGrouping,
  useCurrentSubtypes,
} from '../CategoryDetail/logic';

const decimals = new Intl.NumberFormat('en-Us', {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export function useRows({
  portfolio,
  search,
  url,
  enabledAfterTax = false,
}: {
  portfolio: string | undefined;
  search: string;
  url: string;
  enabledAfterTax?: boolean;
}) {
  const grouping = useCurrentGrouping({ search });

  const currentSubtypes = useCurrentSubtypes({ search });

  const { getNameBySlug } = useAllocationGroups({
    groupBy: 'asset-breakdown',
    subselection: undefined,
    portfolio,
  });

  const filterText = useMemo(() => {
    if (!grouping) {
      return undefined;
    }

    return `By ${groupingLabels[grouping.groupBy]} > ${getNameBySlug(
      grouping.groupBy,
      grouping.subselection,
    )}`;
  }, [getNameBySlug, grouping]);

  const { assets } = useAssets({
    categorySlug: 'fixed-income',
    compare: undefined,
    filter: undefined,
    grouping,
    portfolio,
    url,
  });

  const filtered = useMemo(
    () =>
      currentSubtypes &&
      currentSubtypes?.length > 0 &&
      !currentSubtypes.includes('All')
        ? [...assets].filter((d) =>
            currentSubtypes.includes(kebabCaseCustom(d.subtype)),
          )
        : assets,
    [currentSubtypes, assets],
  );

  const subtypes = useMemo(
    () => uniq(filtered.map((item) => item.subtype)),
    [filtered],
  );

  const balance = useMemo(
    () => filtered.reduce((acc, asset) => acc + asset.value, 0),
    [filtered],
  );

  const allocation = useMemo(
    () => filtered.reduce((acc, asset) => acc + asset.allocation, 0),
    [filtered],
  );

  const {
    annualIncome,
    annualIncomeAfterTax,
    coupon,
    duration,
    rating,
    timeToMaturity,
    yieldToWorst,
  } = useMemo(() => getFixedIncomeCharacteristics(filtered), [filtered]);

  const rows = useMemo(
    () => [
      {
        id: 1,
        key: 'Yield to Worst',
        value: formatPercentage(yieldToWorst),
      },
      {
        id: 2,
        key: 'Time to Maturity',
        value: decimals.format(timeToMaturity),
      },
      {
        id: 3,
        key: 'Duration',
        value: decimals.format(duration),
      },
      {
        id: 4,
        key: 'Rating',
        value: rating,
      },
      {
        id: 5,
        key: 'Coupon',
        value: formatPercentage(coupon),
      },
      {
        id: 6,
        key: 'Expected Annual Income',
        value: enabledAfterTax
          ? formatAmount(annualIncomeAfterTax)
          : formatAmount(annualIncome),
      },
      {
        id: 7,
        key: 'Percentage of Total Portfolio',
        value: formatPercentage(allocation),
      },
      {
        id: 8,
        key: 'Total Fixed Income',
        value: formatAmount(balance),
      },
    ],
    [
      allocation,
      annualIncome,
      annualIncomeAfterTax,
      balance,
      coupon,
      duration,
      enabledAfterTax,
      rating,
      timeToMaturity,
      yieldToWorst,
    ],
  );

  return { filterText, rows, subtypes };
}
