import uniq from 'lodash/uniq';
import { useMemo } from 'react';

import type { CVBondSummary } from 'model/CvBondSummary';
import { formatAmount } from 'utils/amounts';
import getBondPerformanceAnalysis from 'utils/fixedIncome/getBondPerformanceAnalysis';
import kebabCaseCustom from 'utils/kebabCaseCustom';

import { useCurrentSubtypes } from '../CategoryDetail/logic';

const decimals = new Intl.NumberFormat('en-Us', {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export function useRows({
  cvBondPerformanceSummaries,
  search,
}: {
  cvBondPerformanceSummaries: CVBondSummary[];
  search: string;
}) {
  const currentSubtypes = useCurrentSubtypes({ search });

  const filtered = useMemo(
    () =>
      currentSubtypes &&
      currentSubtypes?.length > 0 &&
      !currentSubtypes.includes('All')
        ? [...cvBondPerformanceSummaries].filter((d) =>
            currentSubtypes.includes(kebabCaseCustom(d.subtype)),
          )
        : cvBondPerformanceSummaries,
    [currentSubtypes, cvBondPerformanceSummaries],
  );

  const subtypes = useMemo(
    () => uniq(filtered.map((item) => item.subtype)),
    [filtered],
  );

  const {
    totalCost,
    couponPL,
    bondPL,
    couponsToMaturity,
    recoveryToPar,
    totalFinalPL,
    weightedAverage,
    currBondValue,
  } = useMemo(() => getBondPerformanceAnalysis(filtered), [filtered]);

  const rows = useMemo(
    () => [
      {
        id: 1,
        key: 'Total Cost of Bond Book',
        value: formatAmount(totalCost),
      },
      {
        id: 2,
        key: 'Bond P&L',
        value: formatAmount(bondPL),
      },
      {
        id: 3,
        key: 'Coupons Received',
        value: formatAmount(couponPL),
      },
      {
        id: 4,
        key: 'Current Bond Portfolio Value',
        value: formatAmount(currBondValue),
      },
      {
        id: 5,
        key: 'Avg. Bond Price',
        value: decimals.format(weightedAverage),
      },
      {
        id: 6,
        key: 'Recovery to Par',
        value: formatAmount(recoveryToPar),
      },
      {
        id: 7,
        key: 'Coupons to Maturity',
        value: formatAmount(couponsToMaturity),
      },
      {
        id: 8,
        key: 'Total P&L to Maturity',
        value: formatAmount(totalFinalPL),
      },
    ],
    [
      bondPL,
      couponPL,
      couponsToMaturity,
      currBondValue,
      recoveryToPar,
      totalCost,
      totalFinalPL,
      weightedAverage,
    ],
  );

  return { rows, subtypes };
}
