/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { localPoint } from '@visx/event';
import { scaleBand, scaleLinear } from '@visx/scale';
import { useTooltip as useVisxTooltip } from '@visx/tooltip';
import { useCallback, useMemo } from 'react';
import type { MouseEvent, TouchEvent } from 'react';

import { formatPercentageWithDecimalsIfNecessary } from 'utils/percentages';
import unreachable from 'utils/unreachable';

import type { Datum } from './types';

export function getTicksX(datum: Datum[]): string[] {
  return datum.map((d: Datum) => getFormatTicksX(d));
}

export function getFormatTicksX(data: Datum): string {
  let result = '';
  if (data.change === -1.1) {
    result = `< ${formatPercentageWithDecimalsIfNecessary(-1 / 100)}`;
  } else if (data.change === 1) {
    result = `> ${formatPercentageWithDecimalsIfNecessary(1 / 100)}`;
  } else {
    result = `${formatPercentageWithDecimalsIfNecessary(
      data.change / 100,
    )} to ${formatPercentageWithDecimalsIfNecessary(
      (data.change + 0.1) / 100,
    )}`;
  }
  return result;
}

export const getChange = (d: Datum) => d.change;
export const getDays = (d: Datum) => d.days;

export function useHeight({ isTablet }: { isTablet: boolean }): number {
  if (isTablet) {
    return 327;
  }

  return 220;
}

export function useEdgeValues(data: readonly Datum[]) {
  const minValue = 0;

  const maxValue = useMemo(
    () =>
      data.reduce(
        (highestDays, datum) => Math.max(highestDays, datum.days + 10),
        0,
      ),
    [data],
  );

  return {
    maxValue,
    minValue,
  };
}

export function useMargins({ isTablet }: { isTablet: boolean }) {
  // This builds on top of the padding of the Container element. Instead of
  // putting all on the padding, we put 1rem here so the chart line, which has
  // a thick stroke, doesn't get cut. We also put the rest on the Container
  // element so it is easier for other developers to change the styles.
  const topMargin = 16;

  // This can't simply be padding on the Container element because it wouldn't
  // be noticed by the resize observer.
  const rightMargin = 32;

  const bottomAxisHeight = 18;
  const bottomAxisMargin = 12;

  const leftAxisWidth = isTablet ? 86 : 50;
  const leftAxisMargin = isTablet ? 24 : 20;

  return {
    bottomAxisHeight,
    bottomAxisMargin,
    leftAxisMargin,
    leftAxisWidth,
    rightMargin,
    topMargin,
  };
}

export function useScales(props: {
  data: Datum[];
  bottomAxisHeight: number;
  bottomAxisMargin: number;
  height: number;
  leftAxisMargin: number;
  leftAxisWidth: number;
  maxValue: number;
  minValue: number;
  rightMargin: number;
  topMargin: number;
  width: number;
}) {
  // Destructured here rather than on the "props" parameter itself so that the
  // parameter hint in IntelliJ based IDEs isn't gigantic.
  const {
    data,
    bottomAxisHeight,
    bottomAxisMargin,
    height,
    leftAxisMargin,
    leftAxisWidth,
    maxValue,
    minValue,
    rightMargin,
    topMargin,
    width,
  } = props;

  const xScale = useMemo(
    () =>
      scaleBand({
        domain: getTicksX(data),
        range: [0, width - leftAxisWidth - leftAxisMargin - rightMargin],
      }),
    [data, width, leftAxisWidth, leftAxisMargin, rightMargin],
  );

  const yScale = useMemo(
    () =>
      scaleLinear({
        domain: [minValue, maxValue * 1.1],
        range: [height - bottomAxisHeight - bottomAxisMargin, topMargin],
      }),
    [bottomAxisHeight, bottomAxisMargin, height, maxValue, minValue, topMargin],
  );

  return { xScale, yScale };
}

export function useTooltip({ data }: { data: readonly Datum[] }) {
  const { hideTooltip, showTooltip, tooltipData, tooltipLeft, tooltipTop } =
    useVisxTooltip<{
      id: string;
      rangeChange: string;
      days: number;
    }>();

  const handleTooltipUpdated = useCallback(
    (event: MouseEvent<SVGRectElement> | TouchEvent<SVGRectElement>) => {
      if (event.target instanceof SVGRectElement) {
        const { x: xBar, y: yBar } = localPoint(event) ?? { x: 0, y: 0 };

        const datumId = event.target.id;
        const datumBar = data.find((d) => `bar-${d.id}` === datumId);

        if (!datumBar) {
          return unreachable(undefined);
        }

        showTooltip({
          tooltipData: {
            id: datumBar.id,
            rangeChange: getFormatTicksX(datumBar),
            days: datumBar.days,
          },
          tooltipLeft: xBar,
          tooltipTop: yBar,
        });
      }

      return undefined;
    },
    [data, showTooltip],
  );

  return {
    handleTooltipUpdated,
    handleTooltipClosed: hideTooltip,
    tooltipData,
    tooltipLeft,
    tooltipTop,
  };
}
