import { FC, useEffect } from 'react';
import { useHistory } from 'react-router';

import { useNews } from 'containers/Services/NewsService';
import { formatDate } from 'utils/dates';
import exportToExcel from 'utils/exportToExcel';
import usePlatform from 'utils/usePlatform/usePlatform';

import useConnect from './connect';
import ControlTabFilterDate from './ControlTabFilterDate';
import ControlTabType from './ControlTabType';
import CustomDate from './CustomDate';
import {
  Container,
  ContainerControls,
  ContainerControlsFilter,
  ContainerControlsLeft,
  ContainerControlsTab,
  ContainerCustomDate,
  ContainerDateControls,
  ContainerSearchBar,
  ContainerTitle,
  Content,
  ExportToExcelButton,
  FilterButton,
  LoaderContainer,
  ResetLink,
  SearchInput,
  Spinner,
  TextAmount,
  Title,
  TotalAmount,
  Value,
} from './styles';
import TransactionsTable from './TransactionsTable';

const Transactions: FC = () => {
  const { replace } = useHistory();
  const newsServices = useNews();
  const { isWeb } = usePlatform();

  const {
    transactionsDataFiltered,
    searchValue,
    filterEntity,
    filterAccount,
    filterAssetClass,
    filterCategory,
    typeTransactions,
    filterDate,
    rangeDate,
    minTransactionDate,
    entitiesOptions,
    accountsOptions,
    assetsClassOptions,
    categoryOptions,
    handle,
    headerColumns,
    currentSorting,
    loading,
  } = useConnect();

  useEffect(() => {
    if (newsServices?.notificationToNews) {
      replace('/news');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsServices?.notificationToNews]);

  const onResetFilterLink = () => {
    handle.onChangeSearch('');
    handle.onChangeTypeTransactions('all');
    handle.onChangeFilteringEntity(undefined);
    handle.onChangeFilteringAccount(undefined);
    handle.onChangeFilteringAssetClass(undefined);
    handle.onChangeFilteringCategory(undefined);
    handle.onChangeFilterDate('current-month');
  };

  const handleExportToExcel = () => {
    const data = transactionsDataFiltered.map((item) => ({
      Ticker: item.ticker ? item.ticker : 'N/A',
      Date: formatDate(new Date(item.date ?? 0)),
      Asset: item.asset,
      Asset_Class: item.assetClass,
      Entity: item.entity,
      Account: item.account,
      Description: item.description,
      Amount: item.amount,
      Price: item.price,
      Shares: item.shares,
      Type: item.type,
    }));

    void exportToExcel(data, 'Transactions List', 'Tlist', [8, 9], [], [10]);
  };

  return (
    <>
      {false && (
        <LoaderContainer>
          <Spinner />
        </LoaderContainer>
      )}
      <Container $loading={false}>
        <Content>
          <ContainerTitle>
            <Title>Activity and Transactions History</Title>
            <TotalAmount>
              <Value
                value={
                  transactionsDataFiltered.reduce(
                    (total, current) => total + current.amount,
                    0,
                  ) ?? 0
                }
              />
              <TextAmount>Net Total Amount</TextAmount>
            </TotalAmount>
          </ContainerTitle>
          <ContainerControlsLeft>
            <ContainerControlsTab>
              <ControlTabType
                onChange={handle.onChangeTypeTransactions}
                selectedTypeTransactions={typeTransactions}
              />
            </ContainerControlsTab>
            <ContainerSearchBar>
              <SearchInput
                id="search-bar-input"
                placeholder="Search"
                value={searchValue}
                onIonChange={(e) => handle.onChangeSearch(e.detail.value)}
                debounce={100}
                showClearButton="always"
              />
            </ContainerSearchBar>
          </ContainerControlsLeft>
          <ContainerControlsLeft>
            <ContainerDateControls>
              <ControlTabFilterDate
                onChange={handle.onChangeFilterDate}
                selectedFilterDate={filterDate}
              />

              <ContainerCustomDate>
                <CustomDate
                  selectedDate={rangeDate}
                  minTransactionDate={minTransactionDate ?? new Date(0)}
                  onChange={handle.onChangeDateRange}
                  onClear={handle.onClearDateRange}
                  onFocus={() => handle.onChangeFilterDate('custom-date')}
                />
              </ContainerCustomDate>
            </ContainerDateControls>
          </ContainerControlsLeft>
          <ContainerControls>
            <ContainerControlsFilter>
              <FilterButton
                onChangeMultiple={handle.onChangeFilteringEntity}
                options={
                  entitiesOptions.filter((opt) => opt.value !== 'default') ?? []
                }
                value={filterEntity?.join()}
                placeholder="Entity"
                multiple
                showLeft
                textSmall
              />
              <FilterButton
                onChangeMultiple={handle.onChangeFilteringAccount}
                options={
                  accountsOptions.filter((opt) => opt.value !== 'default') ?? []
                }
                value={filterAccount?.join()}
                placeholder="Account"
                multiple
                showLeft
                textSmall
              />
              <FilterButton
                onChangeMultiple={handle.onChangeFilteringAssetClass}
                options={
                  assetsClassOptions.filter((opt) => opt.value !== 'default') ??
                  []
                }
                value={filterAssetClass?.join()}
                placeholder="Asset Class"
                multiple
                showLeft
                textSmall
              />
              <FilterButton
                onChangeMultiple={handle.onChangeFilteringCategory}
                options={
                  categoryOptions.filter((opt) => opt.value !== 'default') ?? []
                }
                value={filterCategory?.join()}
                placeholder="Category"
                multiple
                showLeft
                textSmall
              />
              <ResetLink onClick={onResetFilterLink}>
                Reset All Filters
              </ResetLink>
            </ContainerControlsFilter>
            {isWeb &&
              transactionsDataFiltered &&
              transactionsDataFiltered.length > 0 && (
                <ExportToExcelButton
                  size="small"
                  color="secondary"
                  onClick={handleExportToExcel}
                >
                  Export to Excel
                </ExportToExcelButton>
              )}
          </ContainerControls>
          <TransactionsTable
            data={transactionsDataFiltered}
            loading={loading}
            headerColumns={headerColumns}
            currentSorting={currentSorting}
            onChangeSorting={handle.onChangeSorting}
          />
        </Content>
      </Container>
    </>
  );
};

export default Transactions;
