import styled, { css } from 'styled-components';

import { ReactComponent as DefaultBlueTick } from 'assets/icons/blue-tick.svg';
import { MarkAllAsReadButton } from 'containers/Protected/News/styles';

export const Container = styled.div<{ $isRead: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 1rem;
  margin-bottom: 1rem;
  height: 10.625rem;
  background: ${({ theme }) => theme.colors.white5};
  padding: 1.25rem;

  ${({ $isRead }) =>
    $isRead
      ? css`
          opacity: 0.6;
        `
      : css`
          box-shadow: 0.5rem 0.5rem 0.5rem 0px rgba(0, 0, 0, 0.2);
        `};
`;

export const ContainerMain = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DescriptionItem = styled.div`
  font-size: 1rem;
  line-height: 1.4rem;
  color: ${({ theme }) => theme.colors.white};
  margin-right: 0.625rem;
`;

export const ContainerFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DateSourceItem = styled.div`
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: ${({ theme }) => theme.colors.white75};
  display: flex;
  flex-direction: column;
`;

export const DateItem = styled.div``;
export const SourceItem = styled.div``;

export const ImagenContainer = styled.div<{ $url: string }>`
  min-width: 5.5rem;
  min-height: 5.5rem;
  border-radius: 5px;
  background-image: url(${({ $url }) => $url});
  background-size: cover;
  background-position: center;
`;

export const MarkAsReadButton = styled(MarkAllAsReadButton)`
  height: fit-content;
  display: flex;
  align-items: center;
`;

export const MarkAsUnreadButton = styled(MarkAsReadButton)``;

export const BlueTickIcon = styled(DefaultBlueTick)`
  margin-right: 0.5rem;
  path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;
