import type { PickByValue } from 'utility-types';

import type { CVBondSummary } from 'model/CvBondSummary';
import { weightedAvg } from 'utils/math';

type Input = Pick<
  CVBondSummary,
  | 'totalCost'
  | 'couponPL'
  | 'bondPL'
  | 'couponsToMaturity'
  | 'recoveryToPar'
  | 'totalFinalPL'
  | 'weightedAverage'
  | 'currBondValue'
>;

export default function getBondPerformanceAnalysis(
  cvBondSummaries: readonly Input[],
) {
  if (!Array.isArray(cvBondSummaries) || cvBondSummaries.length <= 0) {
    return {
      totalCost: 0,
      couponPL: 0,
      bondPL: 0,
      couponsToMaturity: 0,
      recoveryToPar: 0,
      totalFinalPL: 0,
      weightedAverage: 0,
      currBondValue: 0,
    };
  }

  function sum(key: keyof PickByValue<Input, number>) {
    return cvBondSummaries.reduce(
      (total, cvBondSummary) => cvBondSummary[key] + total,
      0,
    );
  }

  function avg(key: keyof PickByValue<Input, number>) {
    return weightedAvg<Input>(
      (cvBondSummary) => cvBondSummary[key],
      (cvBondSummary) => cvBondSummary.currBondValue,
    )(cvBondSummaries);
  }

  return {
    totalCost: sum('totalCost'),
    couponPL: sum('couponPL'),
    bondPL: sum('bondPL'),
    couponsToMaturity: sum('couponsToMaturity'),
    recoveryToPar: sum('recoveryToPar'),
    totalFinalPL: sum('totalFinalPL'),
    weightedAverage: avg('weightedAverage'),
    currBondValue: sum('currBondValue'),
  };
}
