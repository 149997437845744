import { useCallback } from 'react';

import useRequest from 'api/request';
import { normalizeNotificationSetting } from 'model/NotificationSetting';
import withoutNulls from 'utils/withoutNulls';

import type {
  GetNotificationSettingsRequest,
  GetNotificationSettingsRequestParams,
  GetNotificationSettingsResponse,
} from './types';

export const GET_NOTIFICATION_SETTINGS_QUERY_KEY = ({
  deviceID,
}: GetNotificationSettingsRequestParams) => [
  'clients/getNotificationSettings',
  deviceID,
];

export default function useGetNotificationSettings() {
  const request = useRequest<
    GetNotificationSettingsRequest,
    GetNotificationSettingsResponse
  >();

  return useCallback(
    async ({ queryKey }) => {
      const [, deviceID] = queryKey as [string, string];

      const response = await request(
        '/api/SNSData/GetSnsTokenList/{deviceID}',
        {
          urlParams: {
            deviceID,
          },
        },
      );
      const data = await response.json();
      if (!data.isSuccess) {
        throw new Error(data?.message ?? '');
      }
      const raw = data.data && withoutNulls(data.data);
      return raw?.map(normalizeNotificationSetting) ?? [];
    },
    [request],
  );
}
