import type { FC } from 'react';

import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';

import Profile from './Profile';
import {
  Container,
  Content,
  ProfileList,
  Profiles,
  SettingsButton,
  SettingsContainer,
} from './styles';
import type { Props } from './types';

const ProfileSwitcherLayout: FC<Props> = ({
  activeProfileId,
  children,
  onChangeSelectedProfile,
  onClickSettings,
  onClickSearch,
  profiles,
  searchActive = false,
  settingsActive = false,
}) => (
  <Container>
    <Profiles>
      <ProfileList>
        {profiles.map((profile) => (
          <Profile
            id={profile.id}
            initials={profile.initials}
            isActive={activeProfileId === profile.id}
            key={profile.id}
            name={profile.name}
            onChangeSelectedProfile={onChangeSelectedProfile}
          />
        ))}
      </ProfileList>
      <SettingsContainer>
        <SettingsButton
          $isActive={searchActive}
          withIcon={<SearchIcon />}
          onClick={onClickSearch}
        />
        <SettingsButton
          $isActive={settingsActive}
          withIcon={<SettingsIcon />}
          onClick={onClickSettings}
        />
      </SettingsContainer>
    </Profiles>
    <Content>{children}</Content>
  </Container>
);

export default ProfileSwitcherLayout;
