import type { useRollingAllocationTargets } from 'containers/Protected/RiskAnalysis/logic';

export type Datum = Readonly<{
  id: string;
  instrumentType: string;
  target: number;
  current: number;
  range: { lowerBound: number; upperBound: number };
}>;

export type Props = {
  className?: string;
  data: readonly Datum[];
  handleInstrumentTypeChange: ReturnType<
    typeof useRollingAllocationTargets
  >['handleInstrumentTypeChange'];
  width: number;
};

export type $AxisProps = {
  $isTablet: boolean;
};

export const instrumentTypeOrdered: string[] = [
  'Equity-Individual',
  'Equity-Market',
  'Fixed Income',
  'Alternatives',
  'Commodity',
  'Private Investments',
  'Cash',
];

export type ChartKey = 'range' | 'target' | 'current';

export type TooltipDatum = Readonly<{
  instrumentType: string;
  target: number;
  current: number;
  range: { lowerBound: number; upperBound: number };
}>;
