import useGetAssetAllocationTargetAndRangesChartDataQuery, {
  GET_ASSET_ALLOCATION_TARGET_AND_RANGES_CHART_DATA_QUERY_KEY,
} from 'api/queries/charts/getAssetAllocationTargetAndRangesChartData';
import { useQuery } from 'api/storage';

export default function useAssetAllocationTargetAndRangesChartData(
  portfolio: string | undefined,
) {
  const { data } = useQuery(
    GET_ASSET_ALLOCATION_TARGET_AND_RANGES_CHART_DATA_QUERY_KEY(
      portfolio ?? '',
    ),
    {
      enabled: !!portfolio,
      queryFn: useGetAssetAllocationTargetAndRangesChartDataQuery(),
    },
  );

  return {
    data,
  };
}
