import { FC, memo } from 'react';

import Amount from 'components/Amount';
import { formatNumberDecimals } from 'utils/amounts';
import { formatPercentage } from 'utils/percentages';

import { Col } from '../styles';
import type { Props } from './types';

const InfoColumn: FC<Props> = ({ row, selectedData }) => {
  switch (selectedData) {
    default:
    case 'Total':
      return (
        <Col>
          <Amount value={row.value} />
          <span>{formatPercentage(row.allocation)}</span>
        </Col>
      );
    case 'YTW':
      return <Col>{formatPercentage(row.ytw)}</Col>;
    case 'TE YTW':
      return <Col>{formatPercentage(row.tey)}</Col>;
    case 'TTC':
      return <Col>{formatNumberDecimals(row.ttc)}</Col>;
    case 'Duration':
      return <Col>{formatNumberDecimals(row.duration)}</Col>;
    case 'Rating':
      return <Col>{row.rating}</Col>;
  }
};

export default memo(InfoColumn);
