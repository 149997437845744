import { useCallback, useEffect, useState } from 'react';

import useEmail from 'api/hooks/auth/useEmail';
import useUpdateNewsReadStatus from 'api/hooks/clients/news/useUpdateNewsReadStatus';
import { useNews } from 'containers/Services/NewsService';
import type { NewsForAnEmail } from 'model/NewsForEmailDatum';

export default function useConnect() {
  const [news, setNews] = useState<NewsForAnEmail[]>();

  const newsServices = useNews();

  const { data: email } = useEmail();
  const updateNewsReadStatus = useUpdateNewsReadStatus();

  useEffect(() => {
    if (newsServices?.newsList) {
      setNews(newsServices.newsList);
    }
  }, [newsServices?.newsList]);

  const handleUpdateNewsReadStatus = useCallback(
    (messageID: number) => {
      void updateNewsReadStatus({
        email,
        messageID,
      });

      newsServices?.updateUnreadAndReadList(messageID);
    },
    [email, newsServices, updateNewsReadStatus],
  );

  return {
    newsUnread: newsServices?.newsUnread,
    newsRead: newsServices?.newsRead,
    news,
    handleUpdateNewsReadStatus,
  };
}
