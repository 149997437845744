import { Group } from '@visx/group';
import { memo } from 'react';
import type { FC } from 'react';

import { getDays, getFormatTicksX } from '../logic';
import { Bar } from './styles';
import type { Props } from './types';

const Chart: FC<Props> = ({
  barWidth,
  bottom,
  data,
  left,
  yScale,
  xScale,
  handleTooltipUpdated,
  handleTooltipClosed,
}) => (
  <Group left={left}>
    {data.map((d) => {
      const days = getDays(d);
      const barHeight = bottom - yScale(days);
      const barX = xScale(getFormatTicksX(d)) ?? 0;
      const barY = bottom - barHeight;
      return (
        <Bar
          key={`bar-${d.id}`}
          id={`bar-${d.id}`}
          x={barX + barWidth / 2}
          y={barY}
          rx={5}
          width={barWidth}
          height={barHeight}
          onMouseDown={handleTooltipUpdated}
          onMouseLeave={handleTooltipClosed}
          onMouseMove={handleTooltipUpdated}
          onMouseUp={handleTooltipUpdated}
          onTouchMove={handleTooltipUpdated}
          onTouchStart={handleTooltipUpdated}
        />
      );
    })}
  </Group>
);
export default memo(Chart);
