import type { FC } from 'react';

import SelectedPortfolio from 'components/SelectedPortfolio';
import from from 'styles/responsive';
import { getFullPortfolioNameReadable } from 'utils/getFullPortfolioName';
import useMedia from 'utils/useMedia';

import useConnect from './connect';
import {
  DesktopContainer,
  DisclosuresLink,
  MobileContainer,
  MobileContent,
} from './styles';
import type { Props } from './types';

const PageLayout: FC<Props> = ({
  children,
  className,
  showSelectedPortfolio = true,
}) => {
  const { handle, selectedClient, selectedPortfolio } = useConnect();
  const isMobile = !useMedia(from.tablet.query);

  if (!selectedClient || !selectedPortfolio) {
    return null;
  }

  if (isMobile) {
    return (
      <MobileContainer className={className}>
        {showSelectedPortfolio && (
          <SelectedPortfolio
            initials={selectedClient.initials}
            lastUpdated={selectedPortfolio.lastUpdated}
            name={getFullPortfolioNameReadable({
              clientName: selectedClient.name,
              portfolioName: selectedPortfolio.name,
            })}
            onAvatarClick={handle.openPortfolioSwitcher}
            onProfileClick={handle.profileClick}
          />
        )}
        <MobileContent>
          {children}
          <DisclosuresLink href="/terms-of-use-and-disclosures" variant="link">
            Terms, Conditions, and Important Disclosures
          </DisclosuresLink>
        </MobileContent>
      </MobileContainer>
    );
  }

  return (
    <DesktopContainer className={className}>
      {children}
      <DisclosuresLink href="/terms-of-use-and-disclosures" variant="link">
        Terms, Conditions, and Important Disclosures
      </DisclosuresLink>
    </DesktopContainer>
  );
};

export default PageLayout;
