import { Formik } from 'formik';
import type { FC } from 'react';
import * as Yup from 'yup';

import FormikIonInput from 'components/FormikIonInput';
import ProfileMenu from 'components/ProfileMenu';
import from from 'styles/responsive';
import useMedia from 'utils/useMedia';

import {
  pathnameConditions,
  pathnameLogOut,
  pathnameNotifications,
  pathnamePassword,
  pathnameRoot,
} from '../constants';
import useConnect from './connect';
import {
  Button,
  Container,
  Content,
  Description,
  Form,
  Grid,
  Header,
  Inputs,
  Title,
} from './styles';
import type { ChangePasswordFormModel, Props } from './types';

const initialValues: ChangePasswordFormModel = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
};

export const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required('This field is required'),
  confirmPassword: Yup.string()
    .required('This field is required')
    .oneOf([Yup.ref('newPassword')], "Passwords don't match"),
  newPassword: Yup.string()
    .required('This field is required')
    .min(6, 'Must have at least 6 characters'),
});

const ProfileChangePassword: FC<Props> = ({ path }) => {
  const {
    biometricsEnabled,
    biometricType,
    email,
    handle,
    loading,
    onToggleBiometrics,
    success,
  } = useConnect();
  const isTablet = useMedia(from.tablet.query);

  return (
    <Container
      title="Change Password"
      parentUrl={`${path}${pathnameRoot}`}
      withSubtitle={false}
    >
      <Grid>
        {isTablet && (
          <>
            <Header email={email} />
            <ProfileMenu
              biometricsEnabled={biometricsEnabled}
              biometricType={biometricType}
              onToggleBiometrics={onToggleBiometrics}
              currentPathname={`${path}${pathnamePassword}`}
              pathnameConditions={`${path}${pathnameConditions}`}
              pathnameLogOut={`${path}${pathnameLogOut}`}
              pathnameNotifications={`${path}${pathnameNotifications}`}
              pathnamePassword={`${path}${pathnamePassword}`}
            />
          </>
        )}
        <Content>
          {success ? (
            <p>Done! Your password has been changed successfully.</p>
          ) : (
            <>
              <Title>Change Password</Title>
              <Description>
                Your new password must be a minimum of 6 characters.
              </Description>
              <Formik initialValues={initialValues} onSubmit={handle.submit}>
                <Form>
                  <Inputs>
                    <FormikIonInput
                      placeholder="Current Password"
                      name="currentPassword"
                      type="password"
                    />
                    <FormikIonInput
                      placeholder="New Password"
                      name="newPassword"
                      type="password"
                    />
                    <FormikIonInput
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      type="password"
                    />
                  </Inputs>
                  <Button disabled={loading} type="submit">
                    Submit
                  </Button>
                </Form>
              </Formik>
            </>
          )}
        </Content>
      </Grid>
    </Container>
  );
};

export default ProfileChangePassword;
