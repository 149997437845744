import { parse, stringify } from 'query-string';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useLocation, useRouteMatch } from 'react-router-dom';

import useAllocationGroups from 'api/hooks/allocations/useAllocationGroups';
import useSelectedClient from 'api/hooks/clients/useSelectedClient';
import { formatNumberDecimals } from 'utils/amounts';
import type { KeySortOfColumn } from 'utils/sort/types';
import useModal from 'utils/useModal';

import {
  useCurrentGrouping,
  useNavigationLinks,
  useSorting,
} from '../CategoryDetail/logic';
import {
  useAssets,
  useFilteringSponsors,
  useFilteringSubtypes,
} from '../CategoryDetailFixedIncome/logic';
import { useSummary } from './logic';
import type { SortAndFilterFormModel } from './types';

export default function useConnect() {
  const categorySlug = 'private-investments';
  const pageTitle = 'Private Investments';

  const { url } = useRouteMatch();
  const { replace } = useHistory();
  const { hash, pathname, search, state } = useLocation();

  const { selectedPortfolio } = useSelectedClient();

  const { getNameBySlug } = useAllocationGroups({
    groupBy: 'asset-breakdown',
    subselection: undefined,
    portfolio: selectedPortfolio?.id,
  });
  const grouping = useCurrentGrouping({ search });
  const { backUrl, breadcrumbs, navigationLinks, selectedUrl } =
    useNavigationLinks({
      categorySlug,
      getNameBySlug,
      grouping,
      search,
      url,
    });

  const { compare, currentSorting, onChangeSorting, sortOptions } =
    useSorting();

  const {
    currentFiltering: currentFilteringSubtypes,
    multipleOptions: multipleSubtypesOptions,
    filterOptions: filterSubtypesOptions,
    onChangeFiltering: onChangeFilteringSubtypes,
  } = useFilteringSubtypes({
    categorySlug,
    portfolio: selectedPortfolio?.id,
  });

  const {
    currentFiltering: currentFilteringSponsors,
    multipleOptions: multipleSponsorsOptions,
    filterOptions: filterSponsorsOptions,
    onChangeFiltering: onChangeFilteringSponsors,
  } = useFilteringSponsors({
    categorySlug,
    portfolio: selectedPortfolio?.id,
  });

  const { allocation, allocationChartData, assets, balance } = useAssets({
    categorySlug,
    compare,
    multipleSubtypesOptions,
    multipleSponsorsOptions,
    grouping,
    portfolio: selectedPortfolio?.id,
    url,
  });

  const assetsWithPO = useMemo(
    () => assets.filter((a) => !a.closedPosition),
    [assets],
  );

  const summary = useSummary({
    assets: assetsWithPO,
  });

  const headerColumns: KeySortOfColumn[] = [
    {
      titleColumn: `${assetsWithPO.length} Assets`,
      keyOrderAsc: 'alphabeticalR',
      keyOrderDesc: 'alphabetical',
    },
    {
      titleColumn: 'Total ($)',
      keyOrderAsc: 'totalASC',
      keyOrderDesc: 'default',
    },
    {
      titleColumn: 'Total (%)',
      keyOrderAsc: 'totalPorcASC',
      keyOrderDesc: 'totalPorcDESC',
    },
  ];

  const MOI = useMemo(() => {
    const calculatedMOI =
      (summary.value + summary.distributions) / summary.grossCapitalCall;
    if (isFinite(calculatedMOI)) {
      return formatNumberDecimals(calculatedMOI);
    }
    return '-';
  }, [summary.value, summary.distributions, summary.grossCapitalCall]);

  const { closeModal, modalOpen, openModal } = useModal();

  const initialValues: SortAndFilterFormModel = useMemo(
    () => ({
      filterSubtypesValue: currentFilteringSubtypes,
      filterSponsorsValue: currentFilteringSponsors,
      sortValue: currentSorting,
    }),
    [currentFilteringSponsors, currentFilteringSubtypes, currentSorting],
  );

  const onSubmitForm = useCallback(
    ({
      filterSponsorsValue,
      filterSubtypesValue,
      sortValue,
    }: SortAndFilterFormModel) => {
      replace({
        hash,
        pathname,
        search: stringify({
          ...parse(search),
          sponsors:
            filterSponsorsValue === 'default'
              ? undefined
              : filterSponsorsValue ?? undefined,
          subtypes:
            filterSubtypesValue === 'default'
              ? undefined
              : filterSubtypesValue ?? undefined,
          sortBy: sortValue === 'default' ? undefined : sortValue ?? undefined,
        }),
        state,
      });
      closeModal();
    },
    [closeModal, hash, pathname, replace, search, state],
  );

  return {
    allocation,
    allocationChartData,
    assets: assetsWithPO,
    backUrl,
    balance,
    breadcrumbs,
    currentFilteringSubtypes,
    currentFilteringSponsors,
    currentSorting,
    filterSubtypesOptions,
    filterSponsorsOptions,
    handle: {
      changeFilteringSubtypes: onChangeFilteringSubtypes,
      changeFilteringSponsors: onChangeFilteringSponsors,
      changeSorting: onChangeSorting,
      closeModal,
      openModal,
      submitForm: onSubmitForm,
    },
    initialValues,
    modalOpen,
    MOI,
    navigationLinks,
    pageTitle,
    selectedUrl,
    sortOptions,
    headerColumns,
    summary,
    url,
    search,
  };
}
