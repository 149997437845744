import type { GetCoverageTeamResponse } from 'api/queries/clients/getCoverageTeam/types';
import type { WithoutNulls } from 'utils/withoutNulls';

type Input = NonNullable<GetCoverageTeamResponse['data']>[number];

export const normalizeCoverageTeam = (input: WithoutNulls<Input>) =>
  ({
    email: input.email,
    fullName: input.fullName,
    phoneNumber: input.phoneNumber,
    roleName: input.roleName,
    teamEmail: input.teamEmail,
  } as const);

export type CoverageTeam = ReturnType<typeof normalizeCoverageTeam>;
