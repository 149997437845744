import dark from './dark';
import light from './light';

const untypedThemes = {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  dark,
  light,
};

export type Theme = typeof dark;
export type ThemeKey = keyof typeof untypedThemes;

const themes: { [k in ThemeKey]: Theme } = untypedThemes;

export default themes;
