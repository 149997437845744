import type { FC } from 'react';
import { memo } from 'react';

import { formatPercentage } from 'utils/percentages';

import { durationToYears } from '../logic';
import { AssetName, Rating, Row, TooltipWithBounds, Value } from './styles';
import type { Props } from './types';

const Tooltip: FC<Props> = ({
  data,
  tooltipLeft,
  tooltipTop,
  enabledTaxEquvalentYield,
}) => (
  <TooltipWithBounds top={tooltipTop} left={tooltipLeft}>
    <AssetName>
      {'isAverage' in data ? 'Portfolio Average' : data.name}
    </AssetName>
    <Row>
      <Rating>YTW</Rating>
      <Value>
        {formatPercentage(
          !('isAverage' in data) && enabledTaxEquvalentYield
            ? data.tey
            : data.ytw,
        )}
      </Value>
    </Row>
    <Row>
      <Rating>Duration</Rating>
      <Value>{durationToYears(data.duration)}</Value>
    </Row>
  </TooltipWithBounds>
);

export default memo(Tooltip);
