import { useMemo, useState } from 'react';

export function useMainTabs() {
  const mainTabs = useMemo(
    () => [
      { value: 'asset-breakdown', label: 'Asset Breakdown' },
      { value: 'browse', label: 'Browse' },
    ],
    [],
  );

  const [activeMainTab, setActiveMainTab] = useState('asset-breakdown');

  return {
    activeMainTab,
    mainTabs,
    setActiveMainTab,
  };
}
