import { Formik } from 'formik';
import { FC, memo } from 'react';
import * as Yup from 'yup';

import FormikIonInput from 'components/FormikIonInput';

import {
  BackButton,
  Chevron,
  Container,
  Content,
  Form,
  Inputs,
  Paragraph,
  SubmitButton,
  Title,
} from './styles';
import type { PasswordResetFormModel, Props } from './types';

const initialValues: PasswordResetFormModel = {
  confirmPassword: '',
  newPassword: '',
};

export const validationSchema = Yup.object().shape({
  confirmPassword: Yup.string()
    .required('This field is required')
    .oneOf([Yup.ref('newPassword')], "Passwords don't match"),
  newPassword: Yup.string()
    .required('This field is required')
    .min(6, 'Must have at least 6 characters'),
});

const PasswordResetRequestForm: FC<Props> = ({
  firstTime = false,
  loading = false,
  onSubmit,
}) => (
  <Container>
    <Content>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <Form>
          <BackButton href="/" withIcon={<Chevron />} />
          <Title>{firstTime ? 'Set Password' : 'Reset Password'}</Title>
          {firstTime && (
            <Paragraph>
              Welcome to CV Advisors! Before accessing the app, you need to
              create a new password.
            </Paragraph>
          )}
          <Paragraph>
            Your new password must be a minimum of 6 characters.
          </Paragraph>
          <Inputs>
            <FormikIonInput
              autoComplete="new-password"
              placeholder="New Password"
              name="newPassword"
              type="password"
            />
            <FormikIonInput
              autoComplete="new-password"
              placeholder="Confirm Password"
              name="confirmPassword"
              type="password"
            />
          </Inputs>
          <SubmitButton disabled={loading} type="submit">
            Submit
          </SubmitButton>
        </Form>
      </Formik>
    </Content>
  </Container>
);

export default memo(PasswordResetRequestForm);

export type { PasswordResetFormModel };
