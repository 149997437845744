import type { GetNotionalValuesResponse } from 'api/queries/allocations/getNotionalValues/types';
import type { WithoutNulls } from 'utils/withoutNulls';

type Input = NonNullable<GetNotionalValuesResponse['data']>[number];

export const normalizeNotionalValues = (input: WithoutNulls<Input>) =>
  ({
    instrumentType: input.instrumentType ?? '',
    entity: input.entity ?? '',
    account: input.account,
    custodian: input.custodian,
    sector: input.sector,
    country: input.country,
    balance: input.subType !== 'Options' ? input.balance ?? 0 : 0,
    balancePct: input.subType !== 'Options' ? input.balancePct ?? 0 : 0,
    notional: input.notional ?? 0,
    notionalPct: input.notionalPct ?? 0,
    totalNotional: input.totalNotional ?? 0,
    totalNotionalPct: input.totalNotionalPct ?? 0,
    subType: input.subType ?? '',
  } as const);

export type NotionalValues = ReturnType<typeof normalizeNotionalValues>;

export type NotionalValuesSummary = Pick<
  NotionalValues,
  | 'balance'
  | 'balancePct'
  | 'notional'
  | 'notionalPct'
  | 'totalNotional'
  | 'totalNotionalPct'
>;
