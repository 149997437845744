import { useCallback } from 'react';
import type { QueryFunction } from 'react-query';

import useRequest from 'api/request';
import { normalizeProfitLossDatum } from 'model/ProfitLossDatum';
import type { ProfitLossDatum } from 'model/ProfitLossDatum';
import getPortfolioId from 'utils/getPorfolioId';
import withoutNulls from 'utils/withoutNulls';

import type {
  GetProfitLossChartDataRequest,
  GetProfitLossChartDataResponse,
} from './types';

export const GET_PROFIT_LOSS_CHART_DATA_QUERY_KEY = (portfolio: string) => [
  'charts/getProfitLossChartData',
  portfolio,
];

export default function useGetProfitLossChartDataQuery(): QueryFunction<
  Record<string, ProfitLossDatum[]>
> {
  const request = useRequest<
    GetProfitLossChartDataRequest,
    GetProfitLossChartDataResponse
  >();

  return useCallback(
    async ({ queryKey }) => {
      const [, portfolio] = queryKey as [string, string];

      const response = await request(
        '/api/CVData/getPnLChartDataComplete/{portfolio}',
        {
          urlParams: {
            portfolio: getPortfolioId(portfolio) ?? '',
          },
        },
      );

      const data = await response.json();

      if (!data.isSuccess) {
        throw new Error(data.message ?? '');
      }

      const raw = withoutNulls(data.data ?? []);

      return (
        raw[0]?.subPnlCharts?.reduce(
          (acc, { clientName: portfolioName = '', pnlChartData = [] }) => ({
            ...acc,
            [portfolioName]: pnlChartData.map(normalizeProfitLossDatum),
          }),
          {} as Record<string, ProfitLossDatum[]>,
        ) ?? {}
      );
    },
    [request],
  );
}
