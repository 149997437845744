import styled from 'styled-components';

export const Container = styled.div`
  padding: 8px 12px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 8px;
`;

export const Title = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.white};
  opacity: 0.75;
  font-size: 12px;
  margin-bottom: 4px;
  line-height: 14.46px;
`;

export const Value = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  line-height: 16.87px;
  font-weight: ${({ theme }) => theme.weights.medium};
`;
