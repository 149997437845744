import last from 'lodash/last';
import { useMemo, useState } from 'react';

import useMaturityProfile from 'api/hooks/allocations/useMaturityProfile';
import type { MaturityProfileYear } from 'model/MaturityProfileYear';
import { formatYear } from 'utils/dates';

export function useMaturityData({
  portfolio,
}: {
  clientName: string | undefined;
  portfolio: string | undefined;
}) {
  const { data } = useMaturityProfile(portfolio);

  return useMemo(
    () =>
      data?.reduce(
        (acc, year) => [
          ...acc,
          {
            ...year,
            cumulativeAllocation: Math.min(
              (last(acc)?.cumulativeAllocation ?? 0) + year.allocation,
              1,
            ),
          },
        ],
        [] as ReadonlyArray<
          MaturityProfileYear & { cumulativeAllocation: number }
        >,
      ) ?? [],
    [data],
  );
}

type Option = {
  label: string;
  value: string;
};

export function useDataSelection({
  maturityData,
}: {
  maturityData: ReturnType<typeof useMaturityData>;
}) {
  const dataOptions = useMemo(
    (): readonly [Option, ...Option[]] => [
      {
        label: 'Summary',
        value: 'summary',
      },
      ...maturityData.map(({ date }) => ({
        value: `${date}`,
        label: `${formatYear(date)}`,
      })),
    ],
    [maturityData],
  );

  const [selectedData, setSelectedData] = useState(dataOptions[0].value);

  const maturityDataDetail = useMemo(
    () =>
      selectedData === 'summary'
        ? undefined
        : maturityData.find((year) => `${year.date}` === selectedData)?.assets,
    [maturityData, selectedData],
  );

  return { dataOptions, maturityDataDetail, selectedData, setSelectedData };
}
