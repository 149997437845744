import type { FC } from 'react';
import { memo, useMemo } from 'react';

import { formatMonth } from 'utils/dates';
import { formatPercentage } from 'utils/percentages';

import { calculateDifference } from './logic';
import { ColorPercentage, Date, Percentage, TooltipWithBounds } from './styles';
import type { Props } from './types';

const Tooltip: FC<Props> = ({ anchorData, data, tooltipLeft, tooltipTop }) => {
  const difference = useMemo(() => {
    if (!anchorData) {
      return undefined;
    }

    if (anchorData.date > data.date) {
      return calculateDifference(data.yieldSpread, anchorData.yieldSpread);
    }

    return calculateDifference(anchorData.yieldSpread, data.yieldSpread);
  }, [anchorData, data]);

  const formattedDate = useMemo(() => {
    if (!anchorData) {
      return formatMonth(data.date);
    }

    if (anchorData.date > data.date) {
      return `${formatMonth(data.date)} to ${formatMonth(anchorData.date)}`;
    }

    return `${formatMonth(anchorData.date)} to ${formatMonth(data.date)}`;
  }, [anchorData, data]);

  return (
    <TooltipWithBounds top={tooltipTop} left={tooltipLeft}>
      <Date>{formattedDate}</Date>
      {typeof difference === 'undefined' ? (
        <Percentage>{formatPercentage(data.yieldSpread)}</Percentage>
      ) : (
        <ColorPercentage $positive={difference >= 0}>
          {(difference === Infinity || difference === -Infinity) &&
          anchorData &&
          data
            ? formatPercentage(
                anchorData.yieldSpread > data.yieldSpread
                  ? anchorData.yieldSpread - data.yieldSpread
                  : data.yieldSpread - anchorData.yieldSpread,
              )
            : formatPercentage(difference / 100)}
        </ColorPercentage>
      )}
    </TooltipWithBounds>
  );
};

export default memo(Tooltip);
