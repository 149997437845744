/* eslint-disable import/no-cycle */
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import type { Vault } from '@ionic-enterprise/identity-vault';
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
} from 'react';

import useLocalDataCleanup from 'api/hooks/auth/useLocalDataCleanup';
import Guest from 'containers/Guest/Login';

import EnableBiometrics from './EnableBiometrics';
import {
  decryptText,
  encryptText,
  generateKey,
  importKeyFromVault,
  unlockVault,
} from './utils';

export function waitForAppActive() {
  return new Promise<void>((resolve) => {
    const checkAppActive = () => {
      void App.getState().then((state) => {
        if (state.isActive) {
          resolve();
        } else {
          setTimeout(checkAppActive, 150);
        }
      });
    };

    checkAppActive();
  });
}

export function useInitialize({
  isCreated,
  isLocked,
  setIsCreated,
  setIsLocked,
  setPrompt,
  vault,
}: {
  isCreated: boolean;
  isLocked: boolean;
  setIsCreated: Dispatch<SetStateAction<boolean>>;
  setIsLocked: Dispatch<SetStateAction<boolean>>;
  setPrompt: Dispatch<SetStateAction<ReactElement | undefined>>;
  vault: Vault | undefined;
}) {
  const cleanup = useLocalDataCleanup();
  const clearVault = useClearVault({ setIsCreated, vault });

  useEffect(() => {
    if (!vault) {
      return;
    }

    vault.onLock(() => {
      setIsLocked(true);
    });

    vault.onUnlock(() => {
      setIsLocked(false);
    });
  }, [setIsLocked, vault]);

  useEffect(() => {
    async function getInitialState() {
      if (!vault) {
        // If there is no vault, we are in desktop. Pretend that there is one.
        // The encrypt and decrypt methods will do nothing.
        setIsCreated(true);
        setIsLocked(false);
        return;
      }

      const [currentIsLocked, currentIsEmpty] = await Promise.all([
        vault.isLocked(),
        vault.isEmpty(),
      ]);

      setIsCreated(!currentIsEmpty);
      setIsLocked(currentIsLocked);
    }

    void getInitialState();
  }, [setIsCreated, setIsLocked, vault]);

  useEffect(() => {
    async function initialize() {
      await waitForAppActive();

      if (localStorage.getItem('unlock_automatic_enabled') === null) {
        cleanup();
        await clearVault();
        localStorage.setItem('biometrics_prompted', 'false');
      }

      if (!isCreated || !isLocked || !vault) {
        return;
      }

      const prompt = new Promise<'unlocked' | 'discarded'>((resolve) => {
        setPrompt(
          <Guest
            onSelectBiometrics={async () => {
              if (await unlockVault(vault)) {
                localStorage.setItem('unlock_automatic_enabled', 'true');
                resolve('unlocked');
              }
            }}
            onSelectPassword={() => {
              resolve('discarded');
            }}
          />,
        );
      });

      const unlockAutomaticEnabled =
        localStorage.getItem('unlock_automatic_enabled') === 'true';

      // First make the user unlock the vault without any interaction.
      let unlockedOnFirstTry = false;
      if (unlockAutomaticEnabled) {
        unlockedOnFirstTry = await unlockVault(vault);
      }

      // If they cancelled the unlock flow or the OS denied them, the OS UI
      // will be dismissed and reveal the prompt. Wait for them to decide
      // whether to try again or to give up.
      if (!unlockedOnFirstTry || !unlockAutomaticEnabled) {
        const promptResult = await prompt;

        if (promptResult === 'discarded') {
          // If the user discarded, clear its contents and pretend it never
          // existed so the user can log in again (decryption will fail and the
          // refresh token won't be recovered).
          await clearVault();
        }
      }

      setPrompt(undefined);
    }

    void initialize();
  }, [
    cleanup,
    clearVault,
    isCreated,
    isLocked,
    setIsCreated,
    setPrompt,
    vault,
  ]);
}

export function useDecryptText({ vault }: { vault: Vault | undefined }) {
  return useCallback(
    async (input: ['AESCBC', string, string]): Promise<string | undefined> => {
      if (typeof vault === 'undefined') {
        return input[1];
      }

      if (await vault.isLocked()) {
        return undefined;
      }

      const key = await importKeyFromVault(vault);

      if (!key) {
        return undefined;
      }

      return decryptText(key, input);
    },
    [vault],
  );
}

export function useEncryptText({ vault }: { vault: Vault | undefined }) {
  return useCallback(
    async (input: string): Promise<string | undefined> => {
      if (typeof vault === 'undefined') {
        return JSON.stringify(['plaintext', input]);
      }

      if (await vault.isLocked()) {
        return undefined;
      }

      const key = await importKeyFromVault(vault);

      if (!key) {
        return undefined;
      }

      return encryptText(key, input);
    },
    [vault],
  );
}

export function useSetupVault({
  setIsCreated,
  setPrompt,
  vault,
}: {
  setIsCreated: Dispatch<SetStateAction<boolean>>;
  setPrompt: Dispatch<SetStateAction<ReactElement | undefined>>;
  vault: Vault | undefined;
}) {
  return useCallback(async () => {
    if (typeof vault === 'undefined') {
      return false;
    }

    const shouldCreate = await new Promise<boolean>((resolve) => {
      setPrompt(
        <EnableBiometrics
          onEnable={async () => {
            const vaultEmpty = await vault.isEmpty();
            const isIOS = Capacitor.getPlatform() === 'ios';
            if (vaultEmpty && isIOS) {
              await unlockVault(vault);
              await vault.lock();
            }
            if (await unlockVault(vault)) {
              resolve(true);
            }
          }}
          onReject={() => {
            resolve(false);
          }}
        />,
      );
    });

    setPrompt(undefined);

    if (!shouldCreate) {
      return false;
    }

    await vault.setValue('key', await generateKey());

    setIsCreated(true);
    return true;
  }, [setIsCreated, setPrompt, vault]);
}

export function useClearVault({
  setIsCreated,
  vault,
}: {
  setIsCreated: Dispatch<SetStateAction<boolean>>;
  vault: Vault | undefined;
}) {
  return useCallback(async () => {
    if (typeof vault === 'undefined') {
      return;
    }

    setIsCreated(false);
    await vault.clear();
  }, [setIsCreated, vault]);
}

export function useLockedVault({
  setIsLocked,
  vault,
}: {
  setIsLocked: Dispatch<SetStateAction<boolean>>;
  vault: Vault | undefined;
}) {
  return useCallback(async () => {
    if (typeof vault === 'undefined') {
      return;
    }
    setIsLocked(true);

    await vault.lock();
  }, [setIsLocked, vault]);
}
