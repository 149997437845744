import { FC, memo } from 'react';

import { formatAmount } from 'utils/amounts';
import { formatPercentage5Dig } from 'utils/percentages';

import {
  Amount,
  Arrow,
  CardLink,
  ChartContainer,
  Chevron,
  Grid,
  InfoContainer,
  Percentage,
  PercentageItem,
  PercentagesContainer,
  PercentageTitle,
  ProfitAndLoss,
  ProfitAndLossText,
  ProfitLossChartSmall,
  Title,
} from './styles';
import type { Props } from './types';

const PerformanceBreakdownCard: FC<Props> = ({
  id,
  name,
  slug,
  pathname,
  profitAndLoss,
  profitLossChartData,
  roc,
  roe,
}) => (
  <CardLink key={id} href={pathname}>
    <Grid id={`btn-${slug}-report`} $isSinceInception={!!profitLossChartData}>
      <InfoContainer>
        <Title>{name}</Title>
        <Chevron />
        <ProfitAndLoss>
          <Amount>{formatAmount(profitAndLoss)}</Amount>
          <ProfitAndLossText>Profit & Loss</ProfitAndLossText>
        </ProfitAndLoss>
        <PercentagesContainer>
          <PercentageItem>
            <PercentageTitle>ROC</PercentageTitle>
            <Percentage $isNegative={roc < 0}>
              {formatPercentage5Dig(roc)}
              <Arrow $isNegative={roc < 0} />
            </Percentage>
          </PercentageItem>
          <PercentageItem>
            <PercentageTitle>ROE</PercentageTitle>
            <Percentage $isNegative={roe < 0}>
              {formatPercentage5Dig(roe)}
              <Arrow $isNegative={roe < 0} />
            </Percentage>
          </PercentageItem>
        </PercentagesContainer>
      </InfoContainer>
      {profitLossChartData && (
        <ChartContainer>
          <ProfitLossChartSmall
            data={profitLossChartData}
            isInteractive={false}
          />
        </ChartContainer>
      )}
    </Grid>
  </CardLink>
);

export default memo(PerformanceBreakdownCard);
