import useEmail from 'api/hooks/auth/useEmail';

import { useBiometricsControls } from './logic';

export default function useConnect() {
  const { data: email } = useEmail();

  const {
    enabled: biometricsEnabled,
    onToggle: onToggleBiometrics,
    type: biometricType,
  } = useBiometricsControls();

  return {
    biometricsEnabled,
    biometricType,
    email,
    onToggleBiometrics,
  };
}
