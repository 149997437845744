import styled, { css } from 'styled-components';

import DefaultAmount from 'components/Amount';
import DefaultChangePercentage from 'components/ChangePercentage';
import from from 'styles/responsive';

export const FirstCol = styled.div`
  text-align: left;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  ${from.laptop`
    font-size: 1rem;
  `}
`;

export const Table = styled.div`
  display: grid;
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.875rem;
  line-height: 1.1875rem;
  color: ${({ theme }) => theme.colors.white};
  > :nth-child(even) {
    background: ${({ theme }) => theme.colors.dark1};
  }
  > :nth-child(3n + 8) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  }
  > :nth-child(2n + 9) {
    > ${FirstCol} {
      font-weight: ${({ theme }) => theme.weights.bold};
      color: ${({ theme }) => theme.colors.white};
    }
  }
  > :not(:nth-child(2n + 9)) {
    > ${FirstCol} {
      color: ${({ theme }) => theme.colors.white75};
    }
  }
  ${from.laptop`
    font-size: 1rem;
  `}
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: 16.4% 13% 13% 8% 12.2% 9.5% 13% 7.7%;
  column-gap: 0.5rem;
  padding: 1rem 1.5rem;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.weights.bold};
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  text-align: right;
  > :first-child {
    text-align: left;
  }
  ${from.desktop`
    grid-template-columns: 1.3fr repeat(6, 1fr) 0.7fr;
  `}
`;

export const HeaderCell = styled.div`
  font-size: 0.75rem;
  line-height: 1.0625rem;
  ${from.laptop`
    font-size: 0.875rem;
  `}
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 16.4% 13% 13% 8% 12.2% 9.5% 13% 7.7%;
  column-gap: 0.5rem;
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.white10};
  padding: 1rem 1.5rem;
  ${from.desktop`
    grid-template-columns: 1.3fr repeat(6, 1fr) 0.7fr;
  `}
`;

const columnStyles = css`
  text-align: right;
`;

export const Col = styled.div`
  ${columnStyles};
`;

export const ChangePercentage = styled(DefaultChangePercentage)`
  ${columnStyles};
`;

export const ColouredAmount = styled(DefaultAmount).attrs({ coloured: true })`
  ${columnStyles};
`;
