import { format, parse } from 'date-fns/fp';

/*
 * Guide for the application date formats.
 * For reference see https://date-fns.org/docs/format
 */

const BACKEND_DATE_FORMAT = 'M/d/yyyy'; // 8/16/2018
const BACKEND_DATE_TIME_FORMAT = 'M/d/yyyy hh:mm:ss a'; // 8/16/2018  12:00:00 AM
const DATE_FORMAT = 'MMM dd, yyyy'; // Jun 01, 2018
const MONTH_FORMAT = 'MMM, yyyy'; // Jun, 2018
const MONTH_SHORT_YEAR_FORMAT = 'MMM yy'; // Jun 18
const MONTH_YEAR_FORMAT = 'MMM yyyy'; // Jun 2018
const YEAR_FORMAT = 'yyyy'; // 2018

export const formatDate = format(DATE_FORMAT);
export const formatMonth = format(MONTH_FORMAT);
export const formatMonthShortYear = format(MONTH_SHORT_YEAR_FORMAT);
export const formatMonthYear = format(MONTH_YEAR_FORMAT);
export const formatYear = format(YEAR_FORMAT);

export const parseBackendDate = parse(new Date(0), BACKEND_DATE_FORMAT);
export const parseBackendDateTime = parse(
  new Date(0),
  BACKEND_DATE_TIME_FORMAT,
);

// Pase a date string in the format 'mm-dd-yyyy' to a Date object
export function parseRequestDate(date: Date) {
  date.setMonth(date.getMonth() + 1, 0);
  return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
    .getDate()
    .toString()
    .padStart(2, '0')}-${date.getFullYear().toString()}`;
}
