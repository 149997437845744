import { TooltipWithBounds as DefaultTooltipWithBounds } from '@visx/tooltip';
import styled from 'styled-components';

import { tooltipStyles } from 'components/Tooltip/styles';

export const TooltipWithBounds = styled(DefaultTooltipWithBounds).attrs({
  offsetLeft: 32,
  offsetTop: -62,
  // We disable the default @visx/tooltip styles as they are applied at the
  // element level and can't be overridden.
  unstyled: true,
})<{ $isCumulative?: boolean }>`
  // We rescue some styles defined by @visx/tooltip, which we disabled above.
  pointer-events: none;
  position: absolute;
  // Custom styles
  ${tooltipStyles}
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 0.5rem 0.375rem;
  padding: 0.75rem;
  padding-block-start: 0.5rem;
  box-shadow: 0 0 1.375rem -0.5rem ${({ theme }) => `${theme.colors.dark3}FA`};
  ${({ $isCumulative }) => !$isCumulative && `min-width: 12.5rem`};
`;

export const Date = styled.span`
  grid-column: 1 / -1;
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  line-height: 1.0625rem;
  margin-block-end: 0.25rem;
`;

export const Label = styled.span`
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  line-height: 0.9038rem;
`;

export const Value = styled(Label)`
  font-weight: ${({ theme }) => theme.weights.bold};
  justify-self: end;
`;
