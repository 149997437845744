import { memo } from 'react';
import type { FC } from 'react';

import { BarRounded } from './styles';
import type { Props } from './types';

const BarChart: FC<Props> = ({
  data,
  xScale,
  yScale,
  bottomHeight,
  handleTooltipClosed,
  handleTooltipUpdated,
  selectData,
}) => (
  <g>
    {data.map((datum) => (
      <BarRounded
        top
        key={`bar-${datum.rating}`}
        radius={4}
        x={xScale(datum.rating) ?? 0}
        width={xScale.bandwidth()}
        y={yScale(datum.allocation)}
        height={bottomHeight - yScale(datum.allocation)}
        onMouseLeave={handleTooltipClosed}
        onMouseMove={handleTooltipUpdated(datum)}
        onClick={() => selectData(datum.rating)}
      />
    ))}
  </g>
);

export default memo(BarChart);
