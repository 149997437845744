import { IonHeader } from '@ionic/react';
import styled, { css } from 'styled-components';

import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
import DefaultButton from 'components/Button';

import type { ContainerProps } from './types';

export const Container = styled(IonHeader)<{
  $withSubtitle: ContainerProps['$withSubtitle'];
  $modalOpen: ContainerProps['$modalOpen'];
}>`
  background-color: ${({ theme }) => theme.colors.dark2};
  border-bottom: 1px solid ${({ theme }) => theme.colors.white10};
  color: ${({ theme }) => theme.colors.white};
  padding: 3.5rem 4rem 1.5625rem;
  display: ${({ $modalOpen }) => ($modalOpen ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  text-align: center;
  ${({ $withSubtitle }) =>
    $withSubtitle &&
    css`
      padding: 3rem 1.25rem 1rem;
    `}
`;

export const Info = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const Category = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  font-weight: ${({ theme }) => theme.weights.medium};
  text-transform: capitalize;
`;

export const Subtitle = styled.span`
  font-size: 0.675rem;
  color: ${({ theme }) => theme.colors.white75};
  font-weight: ${({ theme }) => theme.weights.regular};
`;

export const Name = styled.span`
  margin-top: 0.125rem;
  color: ${({ theme }) => theme.colors.white75};
  font-size: 0.875rem;
`;

export const Button = styled(DefaultButton)`
  position: absolute;
  left: 1.25rem;
  top: 2.75rem;
`;

export const Chevron = styled(ChevronLeft)`
  margin-top: 0.125rem;
`;
