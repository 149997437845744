import type { FC } from 'react';
import { memo } from 'react';

import { formatChartKey } from '../logic';
import { Box, Container, Item, Label } from './styles';
import type { Props } from './types';

const Legend: FC<Props> = ({ keys }) => (
  <Container>
    {keys.map((key) => (
      <Item key={key}>
        <Box $key={key} />
        <Label>{formatChartKey(key)}</Label>
      </Item>
    ))}
  </Container>
);

export default memo(Legend);
