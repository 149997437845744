import styled, { css } from 'styled-components';

import type { Theme } from 'styles/themes';

import BarItem from './BarItem';
import type { BarChartProps, LegendProps } from './types';

export const Chart = styled.div``;

export const LegendTitle = styled.p`
  color: ${({ theme }) => theme.colors.white75};
  font-size: 0.75rem;
  line-height: 0.9038rem;
  white-space: nowrap;
  overflow: hidden;
`;

export const LegendChart = styled.div<{
  $moreThanSevenItems: LegendProps['$moreThanSevenItems'];
}>`
  display: flex;
  justify-content: flex-end;
  > :not(:last-child) {
    margin-right: 1rem;
  }
  ${({ $moreThanSevenItems }) =>
    $moreThanSevenItems &&
    css`
      > :not(:nth-child(-n + 7)) {
        display: none;
      }
      > :last-child {
        display: flex;
      }
      ${LegendTitle} {
        max-width: 70px;
      }
    `}
`;

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
`;

export const LegendColor = styled.div<{
  $color: keyof Theme['colors'];
}>`
  width: 0.25rem;
  height: 0.625rem;
  margin-right: 0.3125rem;
  flex-shrink: 0;
  border-radius: 0.125rem;
  ${({ theme, $color }) =>
    $color &&
    css`
      background-color: ${theme.colors[$color]};
    `}
`;

export const Bar = styled(BarItem)``;

export const BarChart = styled.div<{
  $isOnlyOneItem: BarChartProps['$isOnlyOneItem'];
}>`
  display: flex;
  width: 100%;
  ${({ $isOnlyOneItem }) =>
    $isOnlyOneItem &&
    css`
      ${Bar} {
        &:last-child {
          border-radius: 0.25rem;
        }
      }
    `}
`;
