import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import type { FC } from 'react';
import { ThemeProvider } from 'styled-components';

import ApiProvider from 'api';
import BreakpointDebugger from 'components/BreakpointDebugger';
import Main from 'containers/Main';
import GoogleAnalyticsService from 'containers/Services/GoogleAnalyticsService';
import GlobalStyle from 'styles/GlobalStyle';
import themes from 'styles/themes';

const Root: FC = () => (
  <ThemeProvider theme={themes.dark}>
    <GoogleAnalyticsService>
      <GlobalStyle />
      <IonApp>
        <IonReactRouter>
          <ApiProvider>
            <Main />
          </ApiProvider>
        </IonReactRouter>
      </IonApp>
      <BreakpointDebugger />
    </GoogleAnalyticsService>
  </ThemeProvider>
);

export default Root;
