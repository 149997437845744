import type { FC } from 'react';

import CreditRiskFixedIncomeTable from 'components/CreditRiskFixedIncomeTable';
import CreditRiskProfileDetailTable from 'components/CreditRiskProfileDetailTable';

import useConnect from './connect';
import {
  ActionZone,
  Container,
  CreditRiskProfileChart,
  DataDisplayButton,
  Title,
  Toolbar,
} from './styles';
import type { Props } from './types';

const CreditRiskProfile: FC<Props> = ({ assets }) => {
  const { dataOptions, ratings, ratingDetail, selectedData, setSelectedData } =
    useConnect({ assets });

  return (
    <Container>
      <CreditRiskProfileChart data={ratings} selectData={setSelectedData} />
      <Toolbar>
        <Title>Credit Risk</Title>
        <ActionZone>
          <DataDisplayButton
            options={dataOptions}
            value={selectedData}
            onChange={setSelectedData}
          />
        </ActionZone>
      </Toolbar>
      {ratingDetail ? (
        <CreditRiskProfileDetailTable data={ratingDetail} />
      ) : (
        <CreditRiskFixedIncomeTable data={ratings} />
      )}
    </Container>
  );
};

export default CreditRiskProfile;
