import type { FC } from 'react';

import DetailFixedIncomeProfileTable from 'components/DetailFixedIncomeProfileTable';
import SummaryFixedIncomeProfileTable from 'components/SummaryFixedIncomeProfileTable';

import useConnect from './connect';
import {
  ActionZone,
  Container,
  DataDisplayButton,
  FilterButton,
  IncomeProfileR12Chart,
  Title,
  Toolbar,
} from './styles';
import type { Props } from './types';

const IncomeProfileR12: FC<Props> = ({ enabledAfterTax = false }) => {
  const {
    dateOptions,
    handle,
    incomeProfileR12,
    incomeProfileR12Detail,
    incomeProfileR12DetailAfterTax,
    selectedDate,
    entitiesOptions,
    accountsOptions,
    filterEntity,
    filterAccount,
  } = useConnect();

  return (
    <Container>
      <IncomeProfileR12Chart
        data={incomeProfileR12.map((d) => ({
          date: d.date,
          income: enabledAfterTax ? d.incomeAfterTax : d.income,
        }))}
        withAverage
        selectDate={handle.changeSelectedDate}
      />
      <Toolbar>
        <Title>Monthly Fixed Income Profile</Title>
        <ActionZone>
          <FilterButton
            onChangeMultiple={handle.onChangeFilteringEntity}
            options={
              entitiesOptions.filter((opt) => opt.value !== 'default') ?? []
            }
            value={filterEntity?.join()}
            placeholder="Entity"
            multiple
            showLeft
            textSmall
          />
          <FilterButton
            onChangeMultiple={handle.onChangeFilteringAccount}
            options={
              accountsOptions.filter((opt) => opt.value !== 'default') ?? []
            }
            value={filterAccount?.join()}
            placeholder="Account"
            multiple
            showLeft
            textSmall
          />
          <DataDisplayButton
            options={dateOptions}
            value={selectedDate}
            onChange={handle.changeSelectedDate}
            placeholder="Summary"
          />
        </ActionZone>
      </Toolbar>
      {incomeProfileR12Detail && incomeProfileR12DetailAfterTax ? (
        <DetailFixedIncomeProfileTable
          data={
            enabledAfterTax
              ? incomeProfileR12DetailAfterTax
              : incomeProfileR12Detail
          }
        />
      ) : (
        <SummaryFixedIncomeProfileTable
          data={incomeProfileR12.map((d) => ({
            id: d.id,
            date: d.date,
            income: enabledAfterTax ? d.incomeAfterTax : d.income,
          }))}
        />
      )}
    </Container>
  );
};
export default IncomeProfileR12;
