import { useCallback } from 'react';
import type { QueryFunction } from 'react-query';

import useRequest from 'api/request';
import { CVBondSummary, normalizeCVBondSummary } from 'model/CvBondSummary';
import getPortfolioId from 'utils/getPorfolioId';
import withoutNulls from 'utils/withoutNulls';

import type {
  GetCVBondSummaryRequest,
  GetCVBondSummaryResponse,
} from './types';

export const GET_CV_BOND_SUMMARY_QUERY_KEY = (portfolio: string) => [
  'clients/getCVBondSummary',
  portfolio,
];

export default function useGetCVBondSummary(): QueryFunction<CVBondSummary[]> {
  const request = useRequest<
    GetCVBondSummaryRequest,
    GetCVBondSummaryResponse
  >();

  return useCallback(
    async ({ queryKey }) => {
      const [, portfolio] = queryKey as [string, string];

      const response = await request(
        '/api/CVData/getCVBondSummary/{portfolio}',
        {
          urlParams: {
            portfolio: getPortfolioId(portfolio) ?? '',
          },
        },
      );

      const data = await response.json();

      if (!data.isSuccess) {
        throw new Error(data.message ?? '');
      }

      return withoutNulls(data.data ?? []).map(normalizeCVBondSummary);
    },
    [request],
  );
}
