import styled from 'styled-components';

import from from 'styles/responsive';

export const FirstCol = styled.div`
  text-align: left;
`;

export const Table = styled.div`
  display: grid;
  color: ${({ theme }) => theme.colors.white75};
  font-size: 0.875rem;
  line-height: 1.0544rem;
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: 1.7fr repeat(2, 1fr);
  column-gap: 0.5rem;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  text-align: right;
  > :first-child {
    text-align: left;
  }
  ${from.tablet`
    grid-template-columns: 1.8fr repeat(2, 0.55fr);
    padding: 0 2rem 0 0;
  `}
`;

export const HeaderCell = styled.p`
  margin: 0;
  font-size: 1.125rem;
  line-height: 1.575rem;
`;

export const ColTitle = styled.p`
  margin: 0;
  font-size: 0.75rem;
  line-height: 0.9038rem;
`;

export const Content = styled.div`
  margin-top: 0.25rem;
  ${from.tablet`
    padding: 3rem 2rem 1.875rem;
    margin-top: 1.5rem;
    background-color: ${({ theme }) => theme.colors.dark1};
    border-radius: 1rem;
    overflow: hidden;
    div:first-child {
      padding-top: 0;
    };
  `}
  ${from.laptop`
    padding: 2rem 2rem 1.25rem;
  `}
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1.5fr repeat(2, 1fr);
  align-items: center;
  padding: 1rem 0 0.8125rem;
  text-align: right;
  border-bottom: 1px solid ${({ theme }) => theme.colors.white10};
  ${from.tablet`
    grid-template-columns: 1.6fr repeat(2, 0.6fr);
    :last-child {
      border-bottom:none;
    }
  `}
`;
