import produce from 'immer';
import uniq from 'lodash/uniq';
import { nanoid } from 'nanoid/non-secure';
import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import useSetMarketWatchlistMutation from 'api/mutations/markets/setMarketWatchlist';
import type { SetMarketWatchlistMutationVariables } from 'api/mutations/markets/setMarketWatchlist/types';
import { GET_MARKET_WATCHLIST_QUERY_KEY } from 'api/queries/markets/getMarketWatchlist';
import type { MarketWatchlistAsset } from 'model/MarketWatchlistAsset';

export default function useMarketWatchlistAction() {
  const client = useQueryClient();

  const { mutate: setWatchlist } = useMutation<
    void,
    unknown,
    SetMarketWatchlistMutationVariables,
    { previousWatchlist?: MarketWatchlistAsset[] }
  >(useSetMarketWatchlistMutation(), {
    onMutate: ({ assets }) => {
      const previousWatchlist = client.getQueryData<MarketWatchlistAsset[]>(
        GET_MARKET_WATCHLIST_QUERY_KEY(),
      );

      client.setQueryData<MarketWatchlistAsset[]>(
        GET_MARKET_WATCHLIST_QUERY_KEY(),
        (current) =>
          produce(current ?? [], (next) => {
            const newAssets = [...assets];

            next.forEach((nextAsset) => {
              const i = newAssets.indexOf(nextAsset.name);

              if (i >= 0) {
                // The asset was previously on the watchlist and still is. This
                // means that this asset was not added in this mutation call.
                // Remove it from the new assets array.
                newAssets.splice(i, 1);
                // The asset might have been previously unpinned, so restore its
                // pinned status.
                nextAsset.pinned = true;
              } else {
                // The asset was previously on the watchlist and now is not.
                // It will be deleted from the watchlist. Mark it as unpinned.
                // On the next query call the back will not send it and it will
                // be removed from the cache.
                nextAsset.pinned = false;
              }
            });

            // At this point, the only assets remaining in this array are any
            // assets being inserted into the watchlist.
            newAssets.forEach((assetName) => {
              // Insert a temporary asset into the watchlist. This will make the
              // market overview tables show that this asset is pinned, because
              // an asset with the same name exists in the watchlist.
              // If the user navigates to the watchlist, it will be re-fetched
              // and the back will send the correct data.
              next.push({
                dailyPercentChange: 0,
                dailyProfitAndLoss: 0,
                id: `temp_${nanoid()}`,
                mtdPercentChange: 0,
                mtdProfitAndLoss: 0,
                name: assetName,
                pinned: true,
                price: 0,
                ytdPercentChange: 0,
                ytdProfitAndLoss: 0,
              });
            });
          }),
      );

      return {
        previousWatchlist,
      };
    },
    onError: (_, __, context) => {
      if (context?.previousWatchlist) {
        client.setQueryData<MarketWatchlistAsset[]>(
          GET_MARKET_WATCHLIST_QUERY_KEY(),
          context.previousWatchlist,
        );
      }
    },
  });

  const readWatchlist = useCallback(
    () =>
      client
        .getQueryData<MarketWatchlistAsset[]>(GET_MARKET_WATCHLIST_QUERY_KEY())
        ?.filter((asset) => asset.pinned)
        ?.map((asset) => asset.name) ?? [],
    [client],
  );

  const addToWatchlist = useCallback(
    (newAsset: string) => {
      setWatchlist({
        assets: uniq([...readWatchlist(), newAsset]),
      });
    },
    [readWatchlist, setWatchlist],
  );

  const removeFromWatchlist = useCallback(
    (oldAsset: string) => {
      setWatchlist({
        assets: uniq(readWatchlist().filter((asset) => asset !== oldAsset)),
      });
    },
    [readWatchlist, setWatchlist],
  );

  return {
    addToWatchlist,
    removeFromWatchlist,
  };
}
