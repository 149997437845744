/* eslint-disable import/no-cycle */
import type { FC } from 'react';

import LogInForm from 'components/LoginForm';
import UpdateAppOverlay from 'components/UpdateAppOverlay';
import { useUpdateApp } from 'containers/Services/UpdateAppService';

import useConnect from './connect';
import { IonContent, IonPage } from './styles';
import type { Props } from './types';

const Guest: FC<Props> = ({ onSelectBiometrics, onSelectPassword }) => {
  const updateApp = useUpdateApp();
  const { loading, handle } = useConnect();

  return (
    <IonPage>
      <IonContent>
        {updateApp?.isUpdateAppAvailable && <UpdateAppOverlay />}
        <LogInForm
          loading={loading}
          biometricType="generic"
          onSubmit={handle.submit}
          onSelectBiometrics={onSelectBiometrics}
          onSelectPassword={onSelectPassword}
        />
      </IonContent>
    </IonPage>
  );
};

export default Guest;
