import styled from 'styled-components';

import SectionNavigation from 'components/SectionNavigation';
import { Link } from 'components/SectionNavigation/styles';

export default styled(SectionNavigation)`
  ${Link} {
    order: 0;
  }
`;
