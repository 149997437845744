import { FC, memo, useEffect, useState } from 'react';

import {
  NavigationLink,
  PageItem,
  PaginationContainer,
  PointsPage,
} from './styles';
import type { Props } from './types';

const Pagination: FC<Props> = ({
  numRowsPerPage,
  dataSet,
  handlePageActive,
}) => {
  const [pageActive, setPageActive] = useState<number>(0);
  const [firstPage, setFirstPage] = useState<number>(0);
  const [lastPage, setLastPage] = useState<number>(0);
  const [centerPage, setCenterPage] = useState<number>(0);

  useEffect(() => {
    setPageActive(0);
    setFirstPage(0);
    setLastPage(Math.ceil(dataSet.length / numRowsPerPage) - 1);
    handlePageActive(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSet]);

  useEffect(() => {
    if (lastPage > 2) {
      setCenterPage(0);
    }
    if (
      (pageActive === firstPage || pageActive === lastPage) &&
      lastPage - firstPage < 5
    ) {
      setCenterPage(Math.floor((lastPage - firstPage) / 2));
    } else if (pageActive === firstPage) {
      setCenterPage(pageActive + 2);
    } else if (pageActive === lastPage) {
      setCenterPage(pageActive - 2);
    } else if (pageActive === firstPage + 1 && firstPage + 2 !== lastPage) {
      setCenterPage(pageActive + 1);
    } else if (pageActive === lastPage - 1 && lastPage - 2 !== firstPage) {
      setCenterPage(pageActive - 1);
    } else {
      setCenterPage(pageActive);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstPage, lastPage, pageActive]);

  const handleSelectPage = (page: number) => {
    setPageActive(page);
    handlePageActive(page);
  };

  return (
    <PaginationContainer>
      <NavigationLink
        onClick={() => handleSelectPage(pageActive - 1)}
        $disabled={pageActive === firstPage}
      >
        Previous
      </NavigationLink>
      <PageItem
        onClick={() => handleSelectPage(firstPage)}
        key="first-page"
        $active={pageActive === firstPage}
      >
        {firstPage + 1}
      </PageItem>
      {centerPage - 1 - firstPage > 1 && <PointsPage>···</PointsPage>}
      {lastPage - firstPage > 2 && centerPage - 1 !== firstPage && (
        <PageItem
          onClick={() => handleSelectPage(centerPage - 1)}
          key="previous-page"
          $active={pageActive === centerPage - 1}
        >
          {centerPage}
        </PageItem>
      )}
      {lastPage - firstPage > 1 && (
        <PageItem
          onClick={() => handleSelectPage(centerPage)}
          key="center-page"
          $active={pageActive === centerPage}
        >
          {centerPage + 1}
        </PageItem>
      )}
      {lastPage - firstPage > 2 && centerPage + 1 !== lastPage && (
        <PageItem
          onClick={() => handleSelectPage(centerPage + 1)}
          key="next-page"
          $active={pageActive === centerPage + 1}
        >
          {centerPage + 2}
        </PageItem>
      )}
      {lastPage - (centerPage + 1) > 1 && <PointsPage>···</PointsPage>}
      {firstPage !== lastPage && (
        <PageItem
          onClick={() => handleSelectPage(lastPage)}
          key="last-page"
          $active={pageActive === lastPage}
        >
          {lastPage + 1}
        </PageItem>
      )}
      <NavigationLink
        onClick={() => handleSelectPage(pageActive + 1)}
        $disabled={pageActive === lastPage}
      >
        Next
      </NavigationLink>
    </PaginationContainer>
  );
};

export default memo(Pagination);
