import type { TickFormatter } from '@visx/axis';
import { FC, memo, useMemo } from 'react';

import from from 'styles/responsive';
import { formatPercentageWithDecimalsIfNecessary } from 'utils/percentages';
import useMedia from 'utils/useMedia';

import BarChart from './BarChart';
import Legend from './Legend';
import LineChart from './LineChart';
import {
  getDataOrdered,
  useEdgeValues,
  useHeight,
  useInstrumentArrow,
  useMargins,
  useScales,
  useTooltip,
} from './logic';
import PointChart from './PointChart';
import { AxisBottom, AxisLeft, Container, GraphVector, Grid } from './styles';
import Tooltip from './Tooltip';
import type { ChartKey, Props } from './types';

const chartKeys: readonly ChartKey[] = ['current', 'target', 'range'];

const AssetAllocationTargetAndRangesChart: FC<Props> = ({
  className,
  data: readonlyData,
  handleInstrumentTypeChange,
  width,
}) => {
  // visx wants mutable arrays even though it will never mutate them
  const data = getDataOrdered(useMemo(() => [...readonlyData], [readonlyData]));

  const {
    showInstrumentArrow,
    handleArrowInstrumentClosed,
    handleArrowInstrumentUpdated,
  } = useInstrumentArrow(data);

  const isTablet = useMedia(from.tablet.query);
  const isLaptop = useMedia(from.laptop.query);
  const isMobile = !useMedia(from.tablet.query);

  const height = useHeight({ isTablet });

  const {
    bottomAxisHeight,
    bottomAxisMargin,
    leftAxisMargin,
    leftAxisPadding,
    leftAxisWidth,
    rightMargin,
    topMargin,
  } = useMargins({ isTablet });

  const { end, maxValue, minValue, start } = useEdgeValues(data);

  const { xScale, yScale } = useScales({
    bottomAxisHeight,
    bottomAxisMargin,
    data,
    end,
    height,
    leftAxisMargin,
    leftAxisPadding,
    leftAxisWidth,
    maxValue,
    minValue,
    rightMargin,
    start,
    topMargin,
    width,
    isMobile,
  });

  const {
    handleTooltipClosed,
    handleTooltipUpdated,
    tooltipData,
    tooltipLeft,
    tooltipTop,
  } = useTooltip({
    xScale,
    yScale,
    height: topMargin + bottomAxisHeight,
    width,
    leftAxisMargin,
    leftAxisWidth,
    data,
  });

  return (
    <Container className={className}>
      <GraphVector
        width="100%"
        viewBox={`0 0 ${width} ${!isMobile ? height + 24 : height + 100}`}
      >
        <Grid
          scale={yScale}
          width={width - leftAxisWidth - leftAxisMargin - rightMargin}
          left={leftAxisWidth + leftAxisMargin}
        />
        <AxisLeft
          $isTablet={isTablet}
          left={leftAxisWidth}
          scale={yScale}
          tickFormat={
            formatPercentageWithDecimalsIfNecessary as TickFormatter<unknown>
          }
          numTicks={!isMobile ? undefined : 5}
        />
        <AxisBottom
          $isTablet={isTablet}
          left={0}
          tickValues={data.map((datum) => datum.instrumentType)}
          scale={xScale}
          top={isTablet ? height - bottomAxisHeight : height}
        />
        <BarChart
          data={data}
          isTablet={isTablet}
          xScale={xScale}
          yScale={yScale}
          bottomHeight={height - bottomAxisHeight - bottomAxisMargin}
          handleTooltipClosed={handleTooltipClosed}
          handleTooltipUpdated={handleTooltipUpdated}
          handleInstrumentArrowClosed={handleArrowInstrumentClosed}
          handleInstrumentArrowUpdated={handleArrowInstrumentUpdated}
          handleInstrumentTypeChange={handleInstrumentTypeChange}
        />
        <LineChart
          data={data}
          isTablet={isTablet}
          isLaptop={isLaptop}
          xScale={xScale}
          yScale={yScale}
          widthToCenterBand={xScale.bandwidth() / 2}
          showInstrumentArrow={showInstrumentArrow}
          handleTooltipClosed={handleTooltipClosed}
          handleTooltipUpdated={handleTooltipUpdated}
          handleInstrumentArrowClosed={handleArrowInstrumentClosed}
          handleInstrumentArrowUpdated={handleArrowInstrumentUpdated}
          handleInstrumentTypeChange={handleInstrumentTypeChange}
        />
        <PointChart
          data={data}
          isTablet={isTablet}
          isLaptop={isLaptop}
          xScale={xScale}
          yScale={yScale}
          widthToCenterBand={xScale.bandwidth() / 2}
          showInstrumentArrow={showInstrumentArrow}
        />
      </GraphVector>
      <Legend keys={chartKeys} />
      {typeof tooltipData !== 'undefined' &&
        typeof tooltipLeft === 'number' &&
        typeof tooltipTop === 'number' &&
        !(isTablet && !isLaptop) && (
          <Tooltip
            data={tooltipData}
            tooltipLeft={tooltipLeft}
            tooltipTop={tooltipTop}
          />
        )}
    </Container>
  );
};

export default memo(AssetAllocationTargetAndRangesChart);
