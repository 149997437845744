import styled from 'styled-components';

import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
import DefaultButton from 'components/Button';
import from from 'styles/responsive';

export const Container = styled.div`
  padding: 32px 20px;
  ${from.tablet`
    padding: 0;
  `}
`;

export const Header = styled.div``;

export const Variant = styled.h2`
  margin: 0;
  font-size: 1.375rem;
  line-height: 1.925rem;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.weights.regular};
  ${from.laptop`
    margin-bottom: 0.25rem;
  `}
`;

export const Title = styled.h1`
  margin: 0;
  margin-bottom: 2.8125rem;
  font-size: 1.75rem;
  line-height: 2.45rem;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.weights.bold};
`;

export const Content = styled.div``;

export const GoBackContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

export const Button = styled(DefaultButton)`
  margin-right: 16px;
`;

export const Chevron = styled(ChevronLeft)`
  margin-top: 2px;
`;
