import { IonRadioGroup, IonSearchbar } from '@ionic/react';
import styled, { css } from 'styled-components';

import Button from 'components/Button';
import OptionItem from 'components/OptionItem';
import { prettyScrollbar } from 'styles/mixins';

import type { ContentProps } from './types';

export const Container = styled.div``;

export const ContainerFixed = styled.div`
  position: sticky;
  top: 0;
  z-index: 100000;
  background-color: ${({ theme }) => theme.colors.dark2};
  padding: 4rem 0 1rem 0;
`;

export const Title = styled.h2`
  margin: 0 0 0.5rem;
  font-size: 1.375rem;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.weights.regular};
`;

export const SubTitle = styled.h2`
  margin: 0 0 1rem;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.white75};
  font-weight: ${({ theme }) => theme.weights.regular};
`;

export const SearchInput = styled(IonSearchbar)`
  --color: ${({ theme }) => theme.colors.white50};
  --background: ${({ theme }) => theme.colors.white5};
  --ion-background-color: ${({ theme }) => theme.colors.white5};
  padding-inline-start: 0 !important;
  padding-inline-end: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-bottom: 1.625rem;

  & .searchbar-input {
    min-height: 3.5rem;
    padding-inline-start: 1rem !important;
  }

  &.sc-ion-searchbar-ios-h {
    height: 3.5rem;
  }

  & .searchbar-input-container {
    --box-shadow: none;
    --border-radius: 0.5rem;
    height: 100% !important;
  }

  & .searchbar-search-icon {
    --icon-color: ${({ theme }) => theme.colors.white10};
    left: inherit !important;
    right: 1.125rem;
    top: 0 !important;
    height: 3.5rem !important;
  }

  & .searchbar-clear-button {
    display: none !important;
  }
`;

export const Content = styled.div<{
  $isRadioVariant: ContentProps['$isRadioVariant'];
}>`
  overflow: auto;
  height: 100%;
  border-radius: 0.5rem;
  ${prettyScrollbar({
    vertical: true,
  })};
  ${({ $isRadioVariant }) =>
    $isRadioVariant &&
    css`
      background-color: ${({ theme }) => theme.colors.dark1};
    `}
`;

export const ContentInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0 0.5rem;
  color: ${({ theme }) => theme.colors.white75};
  font-size: 0.75rem;
  line-height: 0.9063rem;
`;

export const InfoText = styled.span``;

export const ItemLink = styled(Button)``;

export const ItemContainer = styled.div`
  padding: 1.25rem 0;
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.white10};
`;

export const ItemText = styled.p`
  margin: 0;
  font-size: 1rem;
  line-height: 1.1875rem;
`;

export const NoItemsMessage = styled(ItemText)<{ $isRadioVariant: boolean }>`
  padding: 1.5rem 0;
  ${({ $isRadioVariant }) =>
    $isRadioVariant &&
    css`
      padding: 1rem;
    `}
`;

export const RadioGroup = styled(IonRadioGroup)`
  > * {
    --background: ${({ theme }) => theme.colors.dark2};
    --background-hover: ${({ theme }) => theme.colors.dark2};
    --padding-start: 1rem;
    --padding-end: 1rem;

    --min-height: 3.25rem;
  }
`;

export const RadioGroupContainer = styled.div`
  border-radius: 8px;
  overflow: hidden;
`;

export const RadioItem = styled(OptionItem)<{ lastGroupItem: boolean }>`
  ${({ lastGroupItem }) =>
    lastGroupItem &&
    css`
      border-bottom: 1px solid rgba(255, 255, 255, 0.1); ;
    `}
`;
