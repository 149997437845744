import type { FC } from 'react';

import MaturityProfileDetailTable from 'components/MaturityProfileDetailTable';
import MaturityProfileSummaryTable from 'components/MaturityProfileSummaryTable';

import useConnect from './connect';
import {
  ActionZone,
  Container,
  DataDisplayButton,
  MaturityProfileChart,
  Title,
  Toolbar,
} from './styles';

const MaturityProfile: FC = () => {
  const {
    dataOptions,
    handle,
    maturityData,
    maturityDataDetail,
    selectedData,
  } = useConnect();

  return (
    <Container>
      <MaturityProfileChart
        data={maturityData}
        selectDate={handle.changeSelectedData}
      />
      <Toolbar>
        <Title>Total Allocation</Title>
        <ActionZone>
          <DataDisplayButton
            options={dataOptions}
            value={selectedData}
            onChange={handle.changeSelectedData}
          />
        </ActionZone>
      </Toolbar>
      {maturityDataDetail ? (
        <MaturityProfileDetailTable data={maturityDataDetail} />
      ) : (
        <MaturityProfileSummaryTable data={maturityData} />
      )}
    </Container>
  );
};

export default MaturityProfile;
