/* eslint-disable import/no-cycle */
import { Capacitor } from '@capacitor/core';
import {
  DeviceSecurityType,
  IdentityVaultConfig,
  Vault,
  VaultType,
} from '@ionic-enterprise/identity-vault';
import { getPlatforms } from '@ionic/react';
import {
  createContext,
  FC,
  ReactElement,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useMutation } from 'react-query';

import useAppError from 'api/mutations/error';

import {
  useClearVault,
  useDecryptText,
  useEncryptText,
  useInitialize,
  useLockedVault,
  useSetupVault,
} from './logic';

function createVault() {
  if (Capacitor.getPlatform() === 'web') {
    return undefined;
  }
  const vaultConfig: IdentityVaultConfig = {
    key: 'com.cv-advisors.app.vault',
    type: VaultType.DeviceSecurity,
    deviceSecurityType: DeviceSecurityType.Both,
    lockAfterBackgrounded: 4000,
    shouldClearVaultAfterTooManyFailedAttempts: true,
    customPasscodeInvalidUnlockAttempts: 2,
    unlockVaultOnLoad: false,
  };

  return new Vault(vaultConfig);
}

const Context = createContext<{
  clearVault: ReturnType<typeof useClearVault>;
  lockedVault: ReturnType<typeof useLockedVault>;
  decryptText: ReturnType<typeof useDecryptText>;
  isCreated: boolean;
  isSupported: boolean;
  encryptText: ReturnType<typeof useEncryptText>;
  setupVault: ReturnType<typeof useSetupVault>;
}>({
  clearVault: () => Promise.resolve(),
  lockedVault: () => Promise.resolve(),
  decryptText: () => Promise.resolve(undefined),
  isCreated: false,
  isSupported: false,
  encryptText: () => Promise.resolve(undefined),
  setupVault: () => Promise.resolve(false),
});

const VaultGate: FC = ({ children }) => {
  const vault = useMemo(createVault, []);
  const { mutate } = useMutation(useAppError());

  vault?.onError((err) => {
    const body = {
      clientName: '',
      functionName: 'vault.onError',
      errorCode: err.code.toString(),
      errorMessage: err.message,
      platformData: getPlatforms().join(','),
    };
    mutate(body);
  });

  const [isCreated, setIsCreated] = useState(false);
  const [isLocked, setIsLocked] = useState(true);
  const [prompt, setPrompt] = useState<ReactElement | undefined>(undefined);

  useInitialize({
    isCreated,
    isLocked,
    setIsCreated,
    setIsLocked,
    setPrompt,
    vault,
  });

  const isSupported = !!vault;

  const doDecryptText = useDecryptText({ vault });
  const doEncryptText = useEncryptText({ vault });
  const setupVault = useSetupVault({
    setIsCreated,
    setPrompt,
    vault,
  });
  const doClearVault = useClearVault({ setIsCreated, vault });
  const doLockedVault = useLockedVault({ setIsLocked, vault });

  const value = useMemo(
    () => ({
      clearVault: doClearVault,
      lockedVault: doLockedVault,
      decryptText: doDecryptText,
      isCreated,
      isSupported,
      encryptText: doEncryptText,
      setupVault,
    }),
    [
      doClearVault,
      doLockedVault,
      doDecryptText,
      doEncryptText,
      isCreated,
      isSupported,
      setupVault,
    ],
  );

  if (prompt) {
    return prompt;
  }

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default VaultGate;

export function useVault() {
  return useContext(Context);
}
