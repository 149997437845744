import { useCallback } from 'react';
import { useHistory } from 'react-router';

import useSelectedClient from 'api/hooks/clients/useSelectedClient';

import { usePortfolioSwitcher } from '../../Services/PortfolioSwitcherContext';

export default function useConnect() {
  const { push } = useHistory();
  const { selectedClient, selectedPortfolio } = useSelectedClient();

  const onProfileClick = useCallback(() => {
    push('/profile');
  }, [push]);

  const portfolioSwitcherContext = usePortfolioSwitcher();

  return {
    handle: {
      openPortfolioSwitcher: portfolioSwitcherContext.onOpenPortfolioSwitcher,
      profileClick: onProfileClick,
    },
    selectedClient,
    selectedPortfolio,
  };
}
