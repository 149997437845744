import { Formik, FormikHelpers } from 'formik';
import { FC, memo, useMemo, useState } from 'react';
import * as Yup from 'yup';

import FormikIonCheckbox from 'components/FormikIonCheckbox';
import FormikIonInput from 'components/FormikIonInput';
import usePlatform from 'utils/usePlatform/usePlatform';

import {
  AlertBiometrics,
  AlertBiometricsIcon,
  AlertBiometricsLabel,
  BiometricsButton,
  Brand,
  Button,
  Container,
  Content,
  FaceIDIcon,
  Form,
  GenericBiometricIcon,
  GenericBiometricWhiteIcon,
  HelpItem,
  HelpLabel,
  HelpLink,
  HelpSection,
  HorizontalGroup,
  Inputs,
  TouchIDIcon,
} from './styles';
import type { LoginFormModel, Props } from './types';

export const validationSchema = Yup.object().shape({
  email: Yup.string().required('The email is required'),
  password: Yup.string().required('The password is required'),
});

const LoginForm: FC<Props> = ({
  loading,
  biometricType,
  onSelectBiometrics,
  onSelectPassword,
  onSubmit,
}) => {
  const { isWeb } = usePlatform();
  const [showAlertBiometrics, setShowAlertBiometrics] =
    useState<boolean>(false);
  const storeLoginData = localStorage.getItem('login_remember_username');
  const loginData = storeLoginData ? JSON.parse(storeLoginData) : undefined;
  const initialValues = {
    email: loginData && loginData.rememberUsername ? loginData.email : '',
    password: loginData && loginData.rememberUsername ? loginData.password : '',
    rememberUsername:
      loginData && loginData.rememberUsername
        ? loginData.rememberUsername
        : false,
  };

  const biometricIcon = useMemo(() => {
    switch (biometricType) {
      case 'face-id':
        return <FaceIDIcon />;
      case 'touch-id':
        return <TouchIDIcon />;
      case 'generic':
      default:
        return <GenericBiometricIcon />;
    }
  }, [biometricType]);

  const handleLoginButtom = async (
    values: LoginFormModel,
    formikHelpers: FormikHelpers<LoginFormModel>,
  ) => {
    const result = await onSubmit(values, formikHelpers);

    if (result && onSelectPassword && !isWeb) {
      onSelectPassword();
    }
  };

  const handleBiometricsButtom = () => {
    if (onSelectBiometrics) {
      onSelectBiometrics();
    } else {
      localStorage.setItem('biometrics_prompted', 'false');

      setShowAlertBiometrics(true);

      setTimeout(() => {
        setShowAlertBiometrics(false);
      }, 3000);
    }
  };

  return (
    <Container>
      {showAlertBiometrics && !isWeb && (
        <AlertBiometrics>
          <AlertBiometricsIcon>
            <GenericBiometricWhiteIcon />
          </AlertBiometricsIcon>
          <AlertBiometricsLabel>
            Please login to enable biometrics
          </AlertBiometricsLabel>
        </AlertBiometrics>
      )}
      <Content>
        <Brand />
        <Formik
          initialValues={initialValues}
          onSubmit={(values, formikHelpers) =>
            handleLoginButtom(values, formikHelpers)
          }
          validationSchema={validationSchema}
        >
          <Form>
            <Inputs>
              <FormikIonInput
                placeholder="Email"
                name="email"
                inputMode="email"
              />
              <FormikIonInput
                clearOnEdit={false}
                placeholder="Password"
                name="password"
                type="password"
              />
              <HorizontalGroup>
                <FormikIonCheckbox
                  name="rememberUsername"
                  label="Remember username"
                />
                {!isWeb && (
                  <BiometricsButton onClick={handleBiometricsButtom}>
                    {biometricIcon}
                  </BiometricsButton>
                )}
              </HorizontalGroup>
            </Inputs>
            <Button disabled={loading} type="submit">
              Log In
            </Button>
          </Form>
        </Formik>
        <HelpSection>
          <HelpItem>
            <HelpLabel>Need help?</HelpLabel>
            <HelpLink href="/reset-password">Reset password</HelpLink>
          </HelpItem>
        </HelpSection>
      </Content>
    </Container>
  );
};

export default memo(LoginForm);

export type { LoginFormModel };
