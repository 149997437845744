import { FC, memo } from 'react';

import Amount from 'components/Amount';
import ChangePercentage from 'components/ChangePercentage';

import { ColouredAmount, FirstCol, Row, Table, Title } from './styles';
import type { Props } from './types';

const WinnersLosersTable: FC<Props> = ({ data }) => (
  <Table>
    {data.map((row) => (
      <Row key={row.id}>
        <FirstCol>
          <Title>{row.name}</Title>
          <Amount value={row.value} />
        </FirstCol>
        <ColouredAmount value={row.profitAndLoss} />
        <ChangePercentage value={row.percentChange} />
      </Row>
    ))}
  </Table>
);

export default memo(WinnersLosersTable);
