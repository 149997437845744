import styled from 'styled-components';

export const Table = styled.div`
  display: grid;
  color: ${({ theme }) => theme.colors.white};
  font-size: 1rem;
  line-height: 1.1875rem;
  color: ${({ theme }) => theme.colors.white};
  > :nth-child(even) {
    background: ${({ theme }) => theme.colors.dark1};
  }
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 0.6fr;
  column-gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  color: ${({ theme }) => theme.colors.white75};
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  text-align: right;
  > :first-child {
    text-align: left;
  }
`;

export const HeaderCell = styled.div`
  font-size: 0.875rem;
  line-height: 1.0625rem;
`;

export const Row = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1.5fr 0.6fr;
  column-gap: 0.5rem;
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.white10};
  padding: 0.75rem 1.5rem;
`;

export const ColTitle = styled.h2`
  margin: 0;
  font-size: 1rem;
  line-height: 1.1875rem;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.weights.regular};
`;

export const ColValue = styled.span`
  text-align: right;
`;
