import { useMemo } from 'react';

import getPrivateInvestmentsSummary from 'utils/privateInvestments/getPrivateInvestmentsSummary';

import type { useAssets } from '../CategoryDetail/logic';

type Asset = ReturnType<typeof useAssets>['assets'][number];

export function useSummary({
  assets,
}: {
  assets: readonly Asset[] | undefined;
}) {
  const fullAssets = useMemo(
    () =>
      assets?.map(
        (asset) =>
          ({
            ...asset,
            date: Date.now(),
          } as const),
      ) ?? [],
    [assets],
  );

  return useMemo(() => getPrivateInvestmentsSummary(fullAssets), [fullAssets]);
}
