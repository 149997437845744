import { FC, useEffect, useRef } from 'react';

import { formatAmount } from 'utils/amounts';
import { formatPercentage } from 'utils/percentages';

import updateTooltipPosition from './logic';
import {
  BarTooltip,
  Col,
  ColTitle,
  Container,
  Row,
  Table,
  ToolTipAssets,
  ToolTipTitle,
} from './styles';
import type { Props } from './types';

const BarItem: FC<Props> = ({
  className,
  width,
  color,
  name,
  totalAssets,
  totalValue,
  totalAllocation,
}) => {
  const barItemRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    updateTooltipPosition({ barItemRef, tooltipRef });
  }, [barItemRef, tooltipRef]);
  return (
    <Container
      // The width of the bar item is not always its allocation, but the
      // weight of this item within their peers. This might be equal the
      // portfolio allocation if we're representing the whole portfolio,
      // but not if we're representing a subselection of the portfolio.
      $width={width}
      $color={color}
      className={className}
      ref={barItemRef}
    >
      <BarTooltip ref={tooltipRef}>
        <ToolTipTitle>{name}</ToolTipTitle>
        {typeof totalAssets !== 'undefined' && (
          <ToolTipAssets>{totalAssets} assets</ToolTipAssets>
        )}
        <Table>
          <Row>
            <ColTitle>Total ($)</ColTitle>
            <Col>{formatAmount(totalValue)}</Col>
          </Row>
          <Row>
            <ColTitle>Total (%)</ColTitle>
            <Col>{formatPercentage(totalAllocation)}</Col>
          </Row>
        </Table>
      </BarTooltip>
    </Container>
  );
};

export default BarItem;
