import styled from 'styled-components';

import SectionNavigation from 'components/SectionNavigation';
import { Link } from 'components/SectionNavigation/styles';
import from from 'styles/responsive';

export const Container = styled.div`
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: 10.4375rem 1fr;
  grid-column-gap: 1.5rem;
`;

export const Title = styled.h2`
  display: none;
  ${from.tablet`
    display: block;
    grid-column: span 2;
    color: ${({ theme }) => theme.colors.white};
    margin: 0 0 2.25rem 0;
    font-size: 1.75rem;
    line-height: 1.4;
  `}
  ${from.laptop`
    margin-bottom: 74px;
  `}
`;

export const Navigation = styled(SectionNavigation)`
  padding-top: 1rem;
  ${Link} {
    order: 0;
  }
`;

export const EmptyState = styled.div``;
