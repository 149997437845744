import type { FC } from 'react';
import { memo } from 'react';

import { formatAmount } from 'utils/amounts';
import { formatYear } from 'utils/dates';

import { Date, Estimate, TooltipWithBounds, Value } from './styles';
import type { Props } from './types';

const Tooltip: FC<Props> = ({ data, tooltipLeft, tooltipTop }) => {
  if ('income' in data) {
    return (
      <TooltipWithBounds top={tooltipTop} left={tooltipLeft}>
        <Date>{formatYear(data.date)}</Date>
        <Estimate>Yearly Estimate</Estimate>
        <Value>{formatAmount(data.income)}</Value>
      </TooltipWithBounds>
    );
  }

  return (
    <TooltipWithBounds top={tooltipTop} left={tooltipLeft}>
      <Date>Average Yearly</Date>
      <Value>{formatAmount(data.value)}</Value>
    </TooltipWithBounds>
  );
};

export default memo(Tooltip);
