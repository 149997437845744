import { FC, useMemo } from 'react';

import type { Theme } from 'styles/themes';
import { formatPercentage } from 'utils/percentages';

import {
  Bar,
  BarChart,
  Chart,
  LegendChart,
  LegendColor,
  LegendItem,
  LegendTitle,
} from './styles';
import type { Props } from './types';

const colorList = [
  'chartLightBlue',
  'chartLightGreen',
  'chartLightYellow',
  'chartLightOrange',
  'chartLightPink',
  'chartLightPurple',
  'chartLightMint',
  'chartBlue',
  'chartGreen',
  'chartYellow',
  'chartOrange',
  'chartPink',
  'chartPurple',
  'chartMint',
  'white35',
] as (keyof Theme['colors'])[];

const DistributionChart: FC<Props> = ({ data, className }) => {
  const getColor = (index: number) => colorList[index] as keyof Theme['colors'];

  const sumOfAllocations = useMemo(
    () => data.reduce((acc, item) => acc + item.totalAllocation, 0),
    [data],
  );

  return (
    <Chart className={className}>
      <LegendChart $moreThanSevenItems={data.length > 7}>
        {data.map((chart, index) => (
          <LegendItem key={chart.id}>
            <LegendColor $color={getColor(index)} />
            <LegendTitle>{chart.name}</LegendTitle>
          </LegendItem>
        ))}
      </LegendChart>
      <BarChart $isOnlyOneItem={data.length === 1}>
        {data.map((chart, index) => (
          <Bar
            key={chart.id}
            // The width of the bar item is not always its allocation, but the
            // weight of this item within their peers. This might be equal the
            // portfolio allocation if we're representing the whole portfolio,
            // but not if we're representing a subselection of the portfolio.
            width={formatPercentage(chart.totalAllocation / sumOfAllocations)}
            color={getColor(index)}
            name={chart.name}
            totalAssets={chart.totalAssets}
            totalValue={chart.totalValue}
            totalAllocation={chart.totalAllocation}
          />
        ))}
      </BarChart>
    </Chart>
  );
};

export default DistributionChart;
