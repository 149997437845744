import { AreaClosed as DefaultAreaClosed, LinePath } from '@visx/shape';
import type { AreaClosedProps } from '@visx/shape/lib/shapes/AreaClosed';
import type { LinePathProps } from '@visx/shape/lib/shapes/LinePath';
import styled from 'styled-components';

import { getChartKeyColor, getChartKeyGradientId } from '../styles';
import type { Datum } from '../types';
import type { $AreaClosed, $Path } from './types';

function DatumAreaClosed(props: AreaClosedProps<Datum>) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <DefaultAreaClosed<Datum> {...props} />;
}

function DatumLinePath(props: LinePathProps<Datum>) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <LinePath<Datum> {...props} />;
}

export const AreaClosed = styled(DatumAreaClosed).attrs<$AreaClosed>(
  ({ $chartKey, $chartId }) => ({
    fill: `url(#${getChartKeyGradientId($chartId, $chartKey)})`,
  }),
)<$AreaClosed>``;

export const Path = styled(DatumLinePath).attrs({
  stroke: 'currentColor',
})<$Path>`
  color: ${({ theme, $chartKey }) => getChartKeyColor($chartKey, theme)};
  stroke-width: 4px;
`;
