import styled, { css } from 'styled-components';

import blueTick from 'assets/icons/blue-tick.svg';
import Button from 'components/Button';
import { isNotTouchable } from 'styles/responsive';

import type { OptionProps } from './types';

export const Container = styled.div`
  > :not(:last-child) {
    border-bottom: 0.5px solid ${({ theme }) => theme.colors.white10};
  }
`;

export const Item = styled(Button)<{
  $checked: OptionProps['$checked'];
}>`
  display: block;
  position: relative;
  padding: 1.3125rem 0;
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  ${isNotTouchable} {
    :hover:not(:disabled) {
      color: ${({ theme }) => theme.colors.white};
    }
  }
  ${({ $checked }) =>
    $checked &&
    css`
      font-weight: ${({ theme }) => theme.weights.bold};
      :after {
        content: '';
        width: 0.875rem;
        height: 0.625rem;
        top: 50%;
        left: 94%;
        transform: translate(-50%, -50%);
        position: absolute;
        background-image: url(${blueTick});
        background-repeat: no-repeat;
      }
    `}
`;
