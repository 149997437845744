import { menuController } from '@ionic/core';
import { useCallback, useMemo } from 'react';

export function useController(menuId: string) {
  const close = useCallback(async () => menuController.close(menuId), [menuId]);

  const open = useCallback(async () => menuController.open(menuId), [menuId]);

  return useMemo(
    () => ({
      close,
      open,
    }),
    [close, open],
  );
}
