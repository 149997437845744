import styled, { css } from 'styled-components';

import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import Button from 'components/Button';
import ProfitLossChart from 'components/ProfitLossChart';

import type { CellProps } from './types';

export const CardLink = styled(Button).attrs({ variant: 'link' })`
  display: block;
`;

export const InfoContainer = styled.div`
  display: grid;
  grid-column: span 2;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.75rem;
  margin: 0 1.25rem;
  padding: 1.25rem 0;
`;

export const Grid = styled.div<{ $isSinceInception?: boolean }>`
  background-color: ${({ theme }) => theme.colors.white5};
  border-radius: 1rem;
  ${({ $isSinceInception }) =>
    $isSinceInception &&
    css`
      padding: 0;
      ${InfoContainer} {
        border-bottom: 1px solid ${({ theme }) => theme.colors.white10};
      }
    `}
`;

export const Title = styled.h2`
  margin: 0;
  font-weight: ${({ theme }) => theme.weights.regular};
  font-size: 1rem;
  line-height: 1.1875rem;
`;

export const Chevron = styled(ChevronRight)`
  margin-left: auto;
`;

export const ProfitAndLoss = styled.div``;

export const Amount = styled.p`
  margin: 0;
  font-weight: ${({ theme }) => theme.weights.medium};
  font-size: 1.25rem;
  line-height: 1.75rem;
`;

export const ProfitAndLossText = styled.h3`
  margin: 0.125rem 0 0 0;
  font-weight: ${({ theme }) => theme.weights.regular};
  font-size: 0.875rem;
  line-height: 1.0625rem;
  opacity: 0.75;
`;

export const PercentagesContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 0.25rem;
  margin-left: auto;
`;

export const PercentageItem = styled.div`
  display: grid;
  grid-template-columns: 1.875rem 5.6rem;
  grid-gap: 0.5rem;
  align-items: center;
  justify-items: end;
`;

export const PercentageTitle = styled.h3`
  margin: 0;
  font-weight: ${({ theme }) => theme.weights.regular};
  font-size: 0.875rem;
  line-height: 1.0625rem;
  opacity: 0.75;
`;

export const Percentage = styled.div<{
  $isNegative?: CellProps['$isNegative'];
}>`
  font-weight: ${({ theme }) => theme.weights.medium};
  font-size: 1rem;
  line-height: 1.1875rem;
  color: ${({ theme }) => theme.colors.statusIncrease};
  ${({ $isNegative }) =>
    $isNegative &&
    css`
      color: ${({ theme }) => theme.colors.statusDecrease};
    `}
`;

export const Arrow = styled(ArrowUp)<{
  $isNegative?: CellProps['$isNegative'];
}>`
  margin-left: 0.375rem;
  ${({ $isNegative }) =>
    $isNegative &&
    css`
      transform: rotate(180deg);
    `}
`;

export const ChartContainer = styled.div`
  position: relative;
  grid-column: span 2;
  padding: 0 0 1.25rem 0.5rem;
  margin-right: -0.5rem;
`;

export const ProfitLossChartSmall = styled(ProfitLossChart)``;
