import styled, { css } from 'styled-components';

import { getChartKeyColor } from './logic';

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const Item = styled.div`
  :not(:last-child) {
    margin-right: 2rem;
  }
`;

export const Box = styled.span<{ $index: number; $length: number }>`
  border-radius: 0.125rem;
  display: inline-block;
  height: 0.625rem;
  margin-right: 0.375rem;
  width: 0.625rem;
  ${({ $index, $length }) => css`
    background-color: ${getChartKeyColor($index, $length)};
  `};
`;

export const Label = styled.span`
  font-size: 0.8125rem;
  color: ${({ theme }) => theme.colors.white50};
`;
