import type { FC } from 'react';
import { useCallback } from 'react';

import { Container, LaptopTabs } from './styles';
import type { Position, Props } from './types';

const laptopOptions = [
  {
    label: 'Open',
    value: 'open' as const,
  },
  {
    label: 'Closed',
    value: 'closed' as const,
  },
  {
    label: 'All',
    value: 'all' as const,
  },
];

const Controls: FC<Props> = ({ onChange, selectedPosition }) => {
  const handlePeriodChange = useCallback(
    (position: Position) => {
      onChange(position);
    },
    [onChange],
  );

  return (
    <Container>
      <LaptopTabs<Position>
        onChange={handlePeriodChange}
        value={selectedPosition}
        tabs={laptopOptions}
      />
    </Container>
  );
};

export default Controls;
