import type { FC, SetStateAction } from 'react';

import AssetClassBreakdownTable from 'components/AssetClassBreakdownTable';

import useConnect from './connect';
import Controls from './Controls';
import type { Data, Period } from './Controls/types';
import CustomDate from './CustomDate';
import {
  Container,
  ContainerControls,
  ContainerCustomDate,
  Header,
  Title,
} from './styles';
import type { Props } from './types';

const AssetClassBreakdown: FC<Props> = ({
  changeRangeDate,
  changeControls,
}) => {
  const { assetClassBreakdown, controls, handle, rangeDate, isDefaultPeriod } =
    useConnect();

  const handleOnChangeRangeDate = (
    value: [Date | undefined, Date | undefined],
  ) => {
    handle.changeRangeDate(value);
    changeRangeDate(value);
  };

  const handleOnClearRangeDate = () => {
    handle.changeRangeDate([undefined, undefined]);
    changeRangeDate([undefined, undefined]);
  };

  const handleOnChangeControls = (
    value: SetStateAction<{ data: Data; period: Period }>,
  ): void => {
    handle.changeControls(value);
    changeControls(value);
    handleOnClearRangeDate();
  };

  return (
    <Container>
      <Header>
        <Title>Asset Class Breakdown</Title>
        <ContainerControls>
          <ContainerCustomDate>
            <CustomDate
              selectedDate={rangeDate}
              selectedPeriod={controls.period}
              onChange={handleOnChangeRangeDate}
              onClear={handleOnClearRangeDate}
            />
          </ContainerCustomDate>
          <Controls
            onChange={handleOnChangeControls}
            selectedData={controls.data}
            selectedPeriod={controls.period}
          />
        </ContainerControls>
      </Header>
      {assetClassBreakdown && (
        <AssetClassBreakdownTable
          data={assetClassBreakdown}
          selectedData={controls.data}
          selectedPeriod={controls.period}
          isDefaultPeriod={isDefaultPeriod}
        />
      )}
    </Container>
  );
};

export default AssetClassBreakdown;
