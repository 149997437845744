import { useCallback } from 'react';
import type { QueryFunction } from 'react-query';

import useRequest from 'api/request';
import { normalizePerformanceBreakdownReport } from 'model/PerformanceBreakdownReport';
import type { PerformanceBreakdownReport } from 'model/PerformanceBreakdownReport';
import getPortfolioId from 'utils/getPorfolioId';
import withoutNulls from 'utils/withoutNulls';

import type {
  GetPerformanceBreakdownRequest,
  GetPerformanceBreakdownResponse,
} from './types';

export const GET_PERFORMANCE_BREAKDOWN_QUERY_KEY = (portfolio: string) => [
  'home/getPerformanceBreakdown',
  portfolio,
];

export default function useGetPerformanceBreakdownQuery(): QueryFunction<
  Record<string, PerformanceBreakdownReport[]>
> {
  const request = useRequest<
    GetPerformanceBreakdownRequest,
    GetPerformanceBreakdownResponse
  >();

  return useCallback(
    async ({ queryKey }) => {
      const [, portfolio] = queryKey as [string, string];

      const response = await request(
        '/api/CVData/getPerformanceBreakDownComplete/{portfolio}',
        {
          urlParams: {
            portfolio: getPortfolioId(portfolio) ?? '',
          },
        },
      );

      const data = await response.json();

      if (!data.isSuccess) {
        throw new Error(data.message ?? '');
      }

      const raw = withoutNulls(data.data ?? []);

      return (
        raw[0]?.subPortfolios?.reduce(
          (
            acc,
            { clientName: portfolioName = '', performanceBreakDown = [] },
          ) => ({
            ...acc,
            [portfolioName]: performanceBreakDown.map(
              normalizePerformanceBreakdownReport,
            ),
          }),
          {} as Record<string, PerformanceBreakdownReport[]>,
        ) ?? {}
      );
    },
    [request],
  );
}
