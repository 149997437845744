import { useEffect } from 'react';
import { useHistory } from 'react-router';

import useConnect from './connect';

const ProfileLogout = () => {
  const { logout } = useConnect();
  const { replace, goBack } = useHistory();
  useEffect(() => {
    logout()
      .then(() => replace('/'))
      .catch(() => goBack());
  }, [logout, replace, goBack]);
  return null;
};

export default ProfileLogout;
