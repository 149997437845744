import styled from 'styled-components';

import DefaultAmount from 'components/Amount';
import { Container } from 'components/ChangePercentage/styles';
import from from 'styles/responsive';

export const FirstCol = styled.div`
  display: grid;
  grid-row: 1/3;
  text-align: left;
  font-size: 1rem;
  line-height: 1.205rem;
  > :last-child {
    font-size: 0.875rem;
    line-height: 1.0544rem;
  }
  ${from.tablet`
    grid-row: 1;
  `}
`;

export const Title = styled.span`
  margin-bottom: 0.25rem;
`;

export const Table = styled.div`
  display: grid;
  color: ${({ theme }) => theme.colors.white};
  font-size: 1rem;
  line-height: 1.1875rem;
  ${from.tablet`
    > :nth-child(odd) {
      background: ${({ theme }) => theme.colors.dark1};
    }
  `}
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 0.6fr 0.4fr;
  grid-template-rows: repeat(2, 1fr);
  align-items: center;
  column-gap: 0.5rem;
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.white10};
  padding: 1rem 0;
  ${Container} {
    text-align: end;
    grid-row: 1;
  }
  ${from.tablet`
    padding: 1rem 1.5rem;
    grid-template-columns: 0.9fr repeat(2, 0.3fr);
    grid-template-rows: 1fr;
  `}
  ${from.laptop`
    grid-template-columns: 1.5fr repeat(2, 0.2fr);
  `}
`;

export const ColouredAmount = styled(DefaultAmount).attrs({ coloured: true })`
  text-align: end;
  grid-row: 2;
  font-size: 0.875rem;
  line-height: 1.0544rem;
  ${from.tablet`
    grid-row: 1;
    font-size: 1rem;
    line-height: 1.1875rem;
  `}
`;
