import { useCallback, useMemo, useState } from 'react';

import useAssetClassBreakdownSummary from 'api/hooks/home/useAssetClassBreakdownSummary';
import useWinnersLosers from 'api/hooks/home/useWinnersLosers';
import type { AssetClassBreakdownItem } from 'components/AssetClassBreakdownList/types';
import { formatDate, formatMonth } from 'utils/dates';
import last12Month from 'utils/last12Month';
import up from 'utils/paths';
import useEntitySorting, {
  SortOption,
} from 'utils/useDataManipulation/useEntitySorting';

type AssetClassBreakdownSortOption = SortOption<AssetClassBreakdownItem>;

export function useSummary({
  portfolio,
  lastUpdate,
  slug,
  compare,
  url,
}: {
  portfolio: string | undefined;
  lastUpdate: number | undefined;
  slug: string;
  compare: AssetClassBreakdownSortOption['compare'];
  url: string;
}) {
  const [timeFrame, setTimeFrame] = useState('');

  const selectTimeFrame = useCallback(
    (value: string) => {
      setTimeFrame(value);
    },
    [setTimeFrame],
  );

  const { data } = useAssetClassBreakdownSummary({
    portfolio,
    reportSlug: slug,
    timeFrame,
  });

  const sorted = useMemo(
    () => (compare && data ? [...data].sort(compare) : data),
    [compare, data],
  );

  const hasTimeFrame = url.split('/').length === 5;
  const baseUrl = up(url, hasTimeFrame ? 1 : 0);

  const summary = useMemo(
    () =>
      sorted
        // The Portfolio Balance is already displayed in the report so there's
        // no point on showing it again.
        ?.filter((it) => it.name !== 'Portfolio Balance')
        ?.map((category) => ({
          id: category.id,
          name: category.name,
          profitAndLoss: category.profitAndLoss,
          percentChange: category.percentChange,
          pathname: `${baseUrl}/detail/${category.slug}`,
          numberOfAssets: category.numberOfAssets,
        })) ?? [],
    [sorted, baseUrl],
  );

  const balance = useMemo(
    () => sorted?.find((it) => it.name === 'Portfolio Balance'),
    [sorted],
  );

  const formattedDate = useMemo(() => {
    if (!timeFrame && slug !== 'daily-estimate') {
      return undefined;
    }

    switch (slug) {
      case 'daily-estimate': {
        const date = lastUpdate ? new Date(lastUpdate) : new Date();
        return formatDate(date);
      }
      case 'month-to-date':
        return formatMonth(new Date(timeFrame.split('-').join('/')));
      case 'year-to-date':
        return timeFrame;
      case 'since-inception':
      default:
        return undefined;
    }
  }, [slug, timeFrame, lastUpdate]);

  const formattedName = useMemo(() => {
    switch (slug) {
      case 'daily-estimate':
        return 'Daily Estimate';
      case 'month-to-date':
        return 'Monthly Estimate';
      case 'year-to-date':
        return 'Yearly Estimate';
      case 'since-inception':
        return 'Since Inception';
      default:
        return undefined;
    }
  }, [slug]);

  return {
    summary,
    balance,
    selectTimeFrame,
    formattedDate,
    formattedName,
  };
}

export function useWinnersContent({
  clientName,
  portfolioName,
  slug,
  portfolio,
}: {
  clientName: string | undefined;
  portfolioName: string | undefined;
  slug: string;
  portfolio: string | undefined;
}) {
  const { data: { losers, winners } = { losers: [], winners: [] } } =
    useWinnersLosers({
      clientName,
      enabled: slug === 'daily-estimate',
      portfolioName,
      portfolio,
    });

  const [isWinnersContent, setIsWinnersContent] = useState(true);

  const toggleWinnersContent = useCallback(() => {
    setTimeout(() => setIsWinnersContent((value) => !value), 100);
  }, [setIsWinnersContent]);

  return {
    isWinnersContent,
    toggleWinnersContent,
    winnersLosers: isWinnersContent ? winners : losers,
  };
}

export function optionsForSelect(
  period: string,
  inceptionDate: number,
  lastUpdate: number,
) {
  switch (period) {
    case 'month-to-date': {
      const month = last12Month(lastUpdate).filter(
        (date) =>
          new Date(date.split('-').join('/')).getTime() >
          new Date(inceptionDate).getTime(),
      );

      return month.map((date) => ({
        value: date.toString(),
        label: formatMonth(new Date(date.split('-').join('/'))),
      }));
    }
    case 'year-to-date': {
      const yearLastUpdate = new Date(lastUpdate).getFullYear();
      const yearInceptionDate = new Date(inceptionDate).getFullYear();
      const years = [];
      for (let i = 0; i <= yearLastUpdate - yearInceptionDate; i += 1) {
        years.push({
          value: (yearLastUpdate - i).toString(),
          label: (yearLastUpdate - i).toString(),
        });
      }
      return years;
    }
    default:
      return undefined;
  }
}

// noinspection DuplicatedCode - This is abstract enough
export function useSorting() {
  const sortOptions: readonly [
    AssetClassBreakdownSortOption,
    ...AssetClassBreakdownSortOption[]
  ] = useMemo(
    () => [
      {
        compare: (a, b) => b.profitAndLoss - a.profitAndLoss,
        label: 'Total: High to Low',
        value: 'default',
      },
      {
        compare: (a, b) => a.profitAndLoss - b.profitAndLoss,
        label: 'Total: Low to High',
        value: 'totalASC',
      },
      {
        compare: (a, b) => b.percentChange - a.percentChange,
        label: 'Total %: High to Low',
        value: 'totalPorcDESC',
      },
      {
        compare: (a, b) => a.percentChange - b.percentChange,
        label: 'Total %: Low to High',
        value: 'totalPorcASC',
      },
      {
        compare: (a, b) => a.name.localeCompare(b.name),
        label: 'Alphabetical: A to Z',
        value: 'alphabetical',
      },
      {
        compare: (a, b) => b.name.localeCompare(a.name),
        label: 'Alphabetical: Z to A',
        value: 'alphabeticalR',
      },
    ],
    [],
  );

  return useEntitySorting({ sortOptions });
}
