import styled from 'styled-components';

import Select from 'components/Select';
import Tabs from 'components/Tabs';

export const Container = styled.div``;

export const LaptopTabs = styled(Tabs).attrs({
  variant: 'bg-tabs',
})`` as typeof Tabs;

export const TabletSelect = styled(Select)``;
