import { localPoint } from '@visx/event';
import { scaleBand, scaleLinear } from '@visx/scale';
import { useTooltip as useVisxTooltip } from '@visx/tooltip';
import { mean, range } from 'lodash';
import { useCallback, useMemo } from 'react';

import { formatNumberDecimals, roundNumberInteger } from 'utils/amounts';

import type { Event } from './Point/types';
import type { Average, Datum } from './types';

export const getTotalValue = (d: Datum) => d.value;
export const getTotalDuration = (d: Datum) => d.duration;

export function useHeight(): number {
  return 424;
}

export function durationToYears(duration: number) {
  return `${formatNumberDecimals(Math.abs(duration))} year${
    duration >= 2 ? 's' : ''
  }`;
}
export function durationToYearsForAxisBottom(duration: number) {
  return `${roundNumberInteger(Math.abs(duration))}`;
}

export function useEdgeValues(
  data: readonly Datum[],
  enabledTaxEquvalentYield: boolean,
) {
  const start = useMemo(
    () =>
      data.reduce(
        (lowestPercentage, datum) => Math.min(lowestPercentage, datum.duration),
        Infinity,
      ),
    [data],
  );

  const end = useMemo(
    () =>
      data.reduce(
        (highestPercentage, datum) =>
          Math.max(highestPercentage, datum.duration),
        0,
      ),
    [data],
  );

  const getTotalAndExtent = useCallback(
    (accessor: (datum: Datum) => number) => {
      const total = data.map(accessor);
      const min = Math.min(...total);
      const max = Math.max(...total);
      return {
        total,
        min,
        max,
      };
    },
    [data],
  );

  const yieldToWorstEdges = getTotalAndExtent((d: Datum) =>
    enabledTaxEquvalentYield ? d.tey : d.ytw,
  );

  const valueEdges = getTotalAndExtent(getTotalValue);

  return {
    end,
    start,
    yieldToWorstEdges,
    valueEdges,
  };
}

export function useMedians({
  valueTotalValues,
  yScale,
  xScale,
  radiusScale,
  durationAverage,
  yieldToWorstAverage,
}: {
  valueTotalValues: number[];
  yScale: ReturnType<typeof useScales>['yScale'];
  xScale: ReturnType<typeof useScales>['xScale'];
  radiusScale: ReturnType<typeof useScales>['radiusScale'];
  durationAverage?: number;
  yieldToWorstAverage?: number;
}) {
  const meanYieldToWorstScaled = useMemo(
    () => yScale(yieldToWorstAverage ?? 0),
    [yScale, yieldToWorstAverage],
  );
  const meanValueScaled = useMemo(
    () => radiusScale(mean(valueTotalValues)),
    [valueTotalValues, radiusScale],
  );
  const meanDurationScaled = useMemo(
    () => xScale(durationAverage ?? 0),
    [xScale, durationAverage],
  );
  return {
    averageRadius: meanValueScaled,
    averageY: meanYieldToWorstScaled,
    averageX: meanDurationScaled,
  };
}

export function useMargins() {
  // This builds on top of the padding of the Container element. Instead of
  // putting all on the padding, we put 1rem here so the chart line, which has
  // a thick stroke, doesn't get cut. We also put the rest on the Container
  // element so it is easier for other developers to change the styles.
  const topMargin = 32;

  // This can't simply be padding on the Container element because it wouldn't
  // be noticed by the resize observer.
  const rightMargin = 32;

  const bottomAxisHeight = 36;
  const bottomAxisMargin = 16;

  const leftAxisWidth = 84;
  const leftAxisMargin = 16;

  return {
    bottomAxisHeight,
    bottomAxisMargin,
    leftAxisMargin,
    leftAxisWidth,
    rightMargin,
    topMargin,
  };
}

export function useScales(props: {
  data: Datum[];
  start: number;
  end: number;
  yieldToWorstMaxValue: number;
  valueMinValue: number;
  valueMaxValue: number;
  width: number;
  height: number;
  topMargin: number;
  rightMargin: number;
  bottomAxisHeight: number;
  bottomAxisMargin: number;
  leftAxisMargin: number;
  leftAxisWidth: number;
}) {
  // Destructured here rather than on the "props" parameter itself so that the
  // parameter hint in IntelliJ based IDEs isn't gigantic.
  const {
    data,
    end,
    yieldToWorstMaxValue,
    valueMaxValue,
    valueMinValue,
    width,
    height,
    topMargin,
    rightMargin,
    bottomAxisHeight,
    bottomAxisMargin,
    leftAxisMargin,
    leftAxisWidth,
  } = props;

  const xRange = useMemo(
    () =>
      [leftAxisWidth + leftAxisMargin, width - rightMargin] as [number, number],
    [leftAxisMargin, leftAxisWidth, rightMargin, width],
  );

  const xScale = useMemo(
    () =>
      scaleLinear({
        domain: [0, Math.ceil(end)],
        range: xRange,
      }),
    [xRange, end],
  );

  const xScaleAxis = useMemo(() => {
    const years = data.map((datum) => Math.floor(datum.duration));
    const maxYear = years.length ? Math.max(...years) + 1 : 0;
    const domain = range(maxYear);
    return scaleBand({
      domain,
      range: xRange,
    });
  }, [xRange, data]);

  const yScale = useMemo(
    () =>
      scaleLinear({
        domain: [0, yieldToWorstMaxValue],
        range: [height - bottomAxisHeight - bottomAxisMargin, topMargin],
        nice: true,
        clamp: true,
      }),
    [
      bottomAxisHeight,
      bottomAxisMargin,
      height,
      yieldToWorstMaxValue,
      topMargin,
    ],
  );

  const radiusScale = useMemo(
    () =>
      scaleLinear({
        domain: [valueMinValue, valueMaxValue],
        range: [3, 16],
        clamp: true,
        zero: true,
      }),
    [valueMinValue, valueMaxValue],
  );

  return { xScale, xScaleAxis, yScale, radiusScale };
}

export function useTooltip({
  height,
}: {
  xScale: ReturnType<typeof useScales>['xScale'];
  yScale: ReturnType<typeof useScales>['yScale'];
  height: number;
  leftAxisMargin: number;
  leftAxisWidth: number;
}) {
  const { hideTooltip, showTooltip, tooltipData, tooltipLeft, tooltipTop } =
    useVisxTooltip<Datum | Average>();

  const handleTooltipUpdated = useCallback(
    (event: Event, payload: Datum | Average) => {
      const coords = localPoint(event);
      const top = Math.max(coords?.y ?? 0, height);
      showTooltip({
        tooltipData: payload,
        tooltipTop: top,
        tooltipLeft: coords?.x,
      });
    },
    [showTooltip, height],
  );

  return {
    handleTooltipUpdated,
    handleTooltipClosed: hideTooltip,
    tooltipData,
    tooltipLeft,
    tooltipTop,
  };
}
