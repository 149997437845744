import type { TickFormatter } from '@visx/axis';
import { FC, memo, useMemo } from 'react';

import from from 'styles/responsive';
import { formatMonth } from 'utils/dates';
import { formatPercentageWithDecimalsIfNecessary } from 'utils/percentages';
import useMedia from 'utils/useMedia';

import BarChart from './BarChart';
import CursorBar from './CursorBar';
import Legend from './Legend';
import LineChart from './LineChart';
import {
  getDate,
  useEdgeValues,
  useHeight,
  useMargins,
  useScales,
  useTooltip,
} from './logic';
import {
  AxisBottom,
  AxisLeft,
  BackLink,
  Chevron,
  Container,
  GraphVector,
  Grid,
} from './styles';
import Tooltip from './Tooltip';
import type { ChartKey, Props } from './types';

const RollingAllocationTargetsChart: FC<Props> = ({
  className,
  data: readonlyData,
  handleBackToTargetAndRanges,
  width,
}) => {
  // visx wants mutable arrays even though it will never mutate them
  const data = useMemo(() => [...readonlyData], [readonlyData]);

  const isTablet = useMedia(from.tablet.query);

  const height = useHeight({ isTablet });

  const {
    bottomAxisHeight,
    bottomAxisMargin,
    leftAxisMargin,
    leftAxisPadding,
    leftAxisWidth,
    rightMargin,
    topMargin,
  } = useMargins({ isTablet });

  const { end, maxValue, minValue, start } = useEdgeValues(data);

  const { xScale, yScale } = useScales({
    bottomAxisHeight,
    bottomAxisMargin,
    data,
    end,
    height,
    leftAxisMargin,
    leftAxisPadding,
    leftAxisWidth,
    maxValue,
    minValue,
    rightMargin,
    start,
    topMargin,
    width,
  });

  const {
    handleTooltipClosed,
    handleTooltipUpdated,
    tooltipData,
    tooltipLeft,
    tooltipTop,
  } = useTooltip({
    xScale,
    yScale,
    height: topMargin + bottomAxisHeight,
    width,
    leftAxisMargin,
    leftAxisWidth,
    data,
  });

  const xAxisTickValues = useMemo(
    () => data.map(getDate).filter((_, i) => i % 3 === 0),
    [data],
  );

  const handleBackLink = () => {
    handleBackToTargetAndRanges();
  };

  const chartKeys: readonly ChartKey[] = ['current', 'target', 'range'];

  return (
    <Container className={className}>
      <BackLink onClick={handleBackLink}>
        <Chevron />
        Back to Target & Ranges
      </BackLink>
      <GraphVector width="100%" viewBox={`0 0 ${width} ${height + 22}`}>
        <Grid
          scale={yScale}
          width={width - leftAxisWidth - leftAxisMargin - rightMargin}
          left={leftAxisWidth + leftAxisMargin}
        />
        <AxisLeft
          $isTablet={isTablet}
          left={leftAxisWidth}
          scale={yScale}
          tickFormat={
            formatPercentageWithDecimalsIfNecessary as TickFormatter<unknown>
          }
        />
        <AxisBottom
          $isTablet={isTablet}
          left={0}
          tickValues={xAxisTickValues}
          scale={xScale}
          top={height - bottomAxisHeight}
          tickFormat={formatMonth}
        />
        <LineChart
          data={data}
          xScale={xScale}
          yScale={yScale}
          widthToCenterBand={xScale.bandwidth() / 2}
        />
        <BarChart
          data={data}
          height={height - bottomAxisHeight - bottomAxisMargin}
          width={width - leftAxisWidth - leftAxisPadding - leftAxisMargin}
          left={leftAxisWidth + leftAxisMargin}
          top={topMargin}
          xScale={xScale}
          yScale={yScale}
          handleTooltipClosed={handleTooltipClosed}
          handleTooltipUpdated={handleTooltipUpdated}
        />
        {typeof tooltipLeft === 'number' && typeof tooltipTop === 'number' && (
          <CursorBar
            bottom={height - bottomAxisHeight - bottomAxisMargin}
            tooltipLeft={tooltipLeft}
            tooltipTop={tooltipTop}
            top={topMargin}
          />
        )}
      </GraphVector>
      <Legend keys={chartKeys} />
      {typeof tooltipData !== 'undefined' &&
        typeof tooltipLeft === 'number' &&
        typeof tooltipTop === 'number' &&
        isTablet && (
          <Tooltip
            data={tooltipData}
            tooltipLeft={tooltipLeft}
            tooltipTop={tooltipTop}
          />
        )}
    </Container>
  );
};

export default memo(RollingAllocationTargetsChart);
