import useSelectedClient from 'api/hooks/clients/useSelectedClient';

export default function useConnect() {
  const { selectedClient, selectedPortfolio } = useSelectedClient();

  return {
    selectedClient,
    selectedPortfolio,
  };
}
