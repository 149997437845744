import useGetCoverageTeam, {
  GET_COVERAGE_TEAM_QUERY_KEY,
} from 'api/queries/clients/getCoverageTeam';
import { useQuery } from 'api/storage';

export default function useCoverageTeam(portfolio: string | undefined) {
  const { data } = useQuery(GET_COVERAGE_TEAM_QUERY_KEY(portfolio ?? ''), {
    enabled: !!portfolio,
    queryFn: useGetCoverageTeam(),
    keepPreviousData: true,
  });

  return {
    data,
  };
}
