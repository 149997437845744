import { useMemo } from 'react';

import kebabCaseCustom from 'utils/kebabCaseCustom';

import { useDataManipulation } from '.';

export type FilterOption<T> = {
  filter?: (input: T) => boolean;
  label: string;
  value: string;
};

export default function useEntitySorting<T>({
  filterKey = 'filter',
  filterOptions,
}: {
  filterKey?: string;
  filterOptions: readonly [FilterOption<T>, ...FilterOption<T>[]];
}) {
  const dataManipulation = useDataManipulation({
    key: filterKey,
    options: useMemo(() => {
      const [first, ...rest] = filterOptions;

      return [
        {
          ...first,
          fn: first.filter,
          value: kebabCaseCustom(first.value),
        },
        ...rest.map((option) => ({
          ...option,
          fn: option.filter,
          value: kebabCaseCustom(option.value),
        })),
      ];
    }, [filterOptions]),
  });

  return {
    currentFiltering: dataManipulation.currentManipulation,
    filter: dataManipulation.fn,
    multipleOptions: dataManipulation.currentOptions,
    filterOptions: dataManipulation.options,
    onChangeFiltering: dataManipulation.onChangeManipulation,
  };
}
