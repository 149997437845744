import useGetLiquidityQuery, {
  GET_LIQUIDITY_QUERY_KEY,
} from 'api/queries/allocations/getLiquidity';
import { useQuery } from 'api/storage';

export default function useLiquidity(portfolio: string | undefined) {
  const { data } = useQuery(GET_LIQUIDITY_QUERY_KEY(portfolio ?? ''), {
    enabled: !!portfolio,
    queryFn: useGetLiquidityQuery(),
  });

  return {
    data: data || [],
  };
}
