import { useCallback, useMemo } from 'react';
import { useRouteMatch } from 'react-router';

import { useMainTabs } from './logic';
import type { Props, SortFormModel } from './types';

export default function useConnect({
  closeModal,
  currentSorting,
  groupLinks,
  onChangeSorting,
}: {
  closeModal: Props['closeModal'];
  currentSorting: Props['currentSorting'];
  groupLinks: Props['groupLinks'];
  onChangeSorting: Props['onChangeSorting'];
}) {
  const { url } = useRouteMatch();

  const { activeMainTab, mainTabs, setActiveMainTab } = useMainTabs();

  const initialValues: SortFormModel = useMemo(
    () => ({
      value: currentSorting,
    }),
    [currentSorting],
  );

  const onSubmitSortForm = useCallback(
    ({ value }: { value?: string }) => {
      onChangeSorting(value ?? '');
      closeModal();
    },
    [closeModal, onChangeSorting],
  );

  const mobileGroupLinks = groupLinks.filter((link) => link.value !== url);

  return {
    activeMainTab,
    handle: {
      changeMainTab: setActiveMainTab,
      submitSortForm: onSubmitSortForm,
    },
    initialValues,
    mainTabs,
    mobileGroupLinks,
    url,
  };
}
