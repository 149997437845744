import { groupBy } from 'lodash';
import { useMemo } from 'react';
import type { PickByValue } from 'utility-types';

import type { AssetGrouping } from 'model/AssetGrouping';
import type { Liquidity } from 'model/Liquidity';
import kebabCaseCustom from 'utils/kebabCaseCustom';

import useLiquidity from './useLiquidity';

function getKeyByGrouping(groupByKey: AssetGrouping) {
  switch (groupByKey) {
    case 'custodian':
      return 'custodian';
    case 'account':
      return 'account';
    case 'entity':
      return 'entity';
    case 'sector':
      return 'sector';
    case 'country':
      return 'country';
    default:
      return 'account';
  }
}

function reduceAssets(assets: readonly [Liquidity, ...Liquidity[]]) {
  function sum(key: keyof PickByValue<Liquidity, number>) {
    return assets.reduce((total, asset) => asset[key] + total, 0);
  }

  const first = assets[0];

  return {
    client: first.client,
    entity: first.entity,
    account: first.account,
    custodian: first.custodian,
    sector: first.sector,
    country: first.country,
    ranking: first.ranking,
    groupID: first.groupID,
    cashUSD: sum('cashUSD'),
    cashOther: sum('cashOther'),
    cashEquivalent: sum('cashEquivalent'),
    totalCashAvailable: sum('totalCashAvailable'),
    totalLiquidityAvailable: sum('totalLiquidityAvailable'),
    treasuries: sum('treasuries'),
  } as const;
}

export default function useLiquidityAssets({
  grouping,
  portfolio,
}: {
  grouping: { groupBy: AssetGrouping; subselection: string } | undefined;
  portfolio: string | undefined;
}) {
  const { data } = useLiquidity(portfolio);

  const filtered = useMemo(() => {
    let dataFiltered = data;
    if (grouping?.groupBy) {
      const selectingKey = getKeyByGrouping(grouping.groupBy);
      dataFiltered =
        data?.filter(
          (asset) =>
            kebabCaseCustom(asset[selectingKey]) === grouping?.subselection,
        ) ?? [];
    }
    return dataFiltered;
  }, [data, grouping]);

  const reduced = useMemo(
    () =>
      Object.values(groupBy(filtered, (asset) => asset.ranking)).flatMap(
        (assets) => {
          const [first, ...rest] = assets;

          if (!first) {
            return [];
          }

          return [reduceAssets([first, ...rest])];
        },
      ),
    [filtered],
  );

  return {
    data: reduced,
  };
}
