import {
  createInstance,
  defineDriver,
  INDEXEDDB,
  LOCALSTORAGE,
} from 'localforage';
import CordovaSQLiteDriver, {
  _driver as CORDOVA_SQLITE_DRIVER,
} from 'localforage-cordovasqlitedriver';
import memoize from 'lodash/memoize';
import type { FC } from 'react';
import { createContext, useCallback, useContext, useMemo } from 'react';
import {
  hashQueryKey,
  QueryKey,
  useQueryClient,
  useQuery as useQueryParent,
} from 'react-query';
import type { DehydratedState } from 'react-query/hydration';
import { dehydrate } from 'react-query/hydration';
import type {
  UseQueryOptions,
  UseQueryResult,
} from 'react-query/types/react/types';

import { useVault } from 'containers/Services/VaultService';
import { uint32ArrayToString } from 'utils/typedArray';
import usePromise from 'utils/usePromise';

const STATE_VERSION = 4;

type DehydratedQuery = DehydratedState['queries'][number];
type Store = ReturnType<typeof createInstance>;

async function createStore(): Promise<Store> {
  await defineDriver(CordovaSQLiteDriver);

  return createInstance({
    driver: [CORDOVA_SQLITE_DRIVER, INDEXEDDB, LOCALSTORAGE],
    name: `com.cv_advisors.app_v${STATE_VERSION}`,
  });
}

async function hashKey(key: string): Promise<string> {
  const digest = await crypto.subtle.digest(
    'SHA-256',
    new TextEncoder().encode(key),
  );
  return btoa(uint32ArrayToString(new Uint32Array(digest)));
}

async function loadQuery({
  decryptText,
  queryHash,
  store,
}: {
  decryptText: ReturnType<typeof useVault>['decryptText'];
  queryHash: string;
  store: Store;
}) {
  const hash = await hashKey(queryHash);
  const serializedQuery = await store.getItem<string>(hash);

  if (!serializedQuery) {
    return undefined;
  }

  const parsed = JSON.parse(serializedQuery) as
    | DehydratedQuery
    | ['AESCBC', string, string];

  if (Array.isArray(parsed)) {
    const decrypted = await decryptText(parsed);

    if (!decrypted) {
      return undefined;
    }

    return JSON.parse(decrypted) as DehydratedQuery;
  }

  return JSON.parse(serializedQuery) as DehydratedQuery;
}

async function saveQuery({
  encryptText,
  query,
  store,
}: {
  encryptText: ReturnType<typeof useVault>['encryptText'];
  query: DehydratedQuery;
  store: Store;
}): Promise<void> {
  const [keyHash, ciphertext] = await Promise.all([
    // We hash the query hash, which is just the JSON.stringify-ed queryKey as
    // it may contain sensitive information.
    hashKey(query.queryHash),
    encryptText(JSON.stringify(query)),
  ]);

  await store.setItem(keyHash, ciphertext);
}

const Context = createContext<{
  cacheQuery: (queryKey: QueryKey) => void;
  clearStorage: () => Promise<void>;
  loadCachedQuery: (queryKey: QueryKey) => Promise<boolean>;
}>({
  cacheQuery: () => {},
  clearStorage: () => Promise.resolve(),
  loadCachedQuery: () => Promise.resolve(true),
});

/*
 * We expose this function in a context instead of exporting is as a hook so we
 * can memoize it and every usage calls the same memoized implementation.
 */
function useContextValue({ store }: { store: Store }) {
  const client = useQueryClient();
  const { decryptText, encryptText } = useVault();

  const cacheQuery = useCallback(
    (queryKey: QueryKey) => {
      void (async () => {
        const dehydrated = dehydrate(client, {
          dehydrateQueries: true,
          dehydrateMutations: false,
          shouldDehydrateQuery: (query) =>
            query.queryHash === hashQueryKey(queryKey),
        });

        if (dehydrated.queries?.[0]) {
          await saveQuery({
            encryptText,
            query: dehydrated.queries[0],
            store,
          });
        }
      })();
    },
    [client, encryptText, store],
  );

  const clearStorage = useCallback(async () => {
    await store.clear();
  }, [store]);

  const loadCachedQuery = useCallback(
    async (queryKey: QueryKey): Promise<boolean> => {
      try {
        const query = await loadQuery({
          decryptText,
          queryHash: hashQueryKey(queryKey),
          store,
        });

        if (!query) {
          return false;
        }

        client.setQueryData(queryKey, query.state.data, {
          updatedAt: query.state.dataUpdatedAt,
        });

        return true;
      } catch (e) {
        return false;
      }
    },
    [client, decryptText, store],
  );

  const memoizedLoadCachedQuery = useMemo(
    () => memoize(loadCachedQuery, hashQueryKey),
    [loadCachedQuery],
  );

  return useMemo(
    () => ({
      cacheQuery,
      clearStorage,
      loadCachedQuery: memoizedLoadCachedQuery,
    }),
    [cacheQuery, clearStorage, memoizedLoadCachedQuery],
  );
}

/**
 * A wrapper around useQuery that before requesting anything, loads the query
 * from the local storage to see whether it exists.
 */
export function useQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  options?: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
): UseQueryResult<TData, TError> {
  const { cacheQuery, loadCachedQuery } = useContext(Context);

  const queryCacheLoaded = usePromise(
    useCallback(() => loadCachedQuery(queryKey), [loadCachedQuery, queryKey]),
  );
  const queryCacheLoading = typeof queryCacheLoaded === 'undefined';

  const result = useQueryParent<TQueryFnData, TError, TData, TQueryKey>(
    queryKey,
    {
      ...options,
      enabled: (options?.enabled ?? true) && !queryCacheLoading,
      onSuccess: (data) => {
        options?.onSuccess?.(data);
        cacheQuery(queryKey);
      },
    },
  );

  return {
    ...result,
    isLoading: result.isLoading && !queryCacheLoading,
  } as UseQueryResult<TData, TError>;
}

const StorageService: FC<{ store: Store }> = ({ children, store }) => (
  <Context.Provider value={useContextValue({ store })}>
    {children}
  </Context.Provider>
);

const StorageGate: FC = ({ children }) => {
  const store = usePromise(createStore);

  return (
    <>{!!store && <StorageService store={store}>{children}</StorageService>}</>
  );
};

export default StorageGate;

export function useClearStorage() {
  const { clearStorage } = useContext(Context);
  return clearStorage;
}
