import styled from 'styled-components';

import hexToRgb from 'utils/hexToRgb';

export const Point = styled.circle.attrs(() => ({
  r: 4,
}))`
  color: ${({ theme }) => theme.colors.statusDecrease};
  fill: currentColor;
  pointer-events: none;
`;

export const BasePoint = styled.circle.attrs(() => ({
  r: 8,
}))<{
  $showInstrumentArrow: boolean;
}>`
  color: ${({ theme, $showInstrumentArrow }) =>
    $showInstrumentArrow
      ? `rgba(${hexToRgb(theme.colors.statusDecrease)}, 0.3)`
      : 'transparent'};
  fill: currentColor;
  pointer-events: none;
`;
