import type { FC } from 'react';
import { memo } from 'react';

import { Change, Days, TooltipWithBounds } from './styles';
import type { Props } from './types';

const Tooltip: FC<Props> = ({ data, tooltipLeft, tooltipTop }) => (
  <TooltipWithBounds top={tooltipTop} left={tooltipLeft}>
    <Days>{data.days} days</Days>
    <Change>{data.rangeChange}</Change>
  </TooltipWithBounds>
);

export default memo(Tooltip);
