import styled from 'styled-components';

import DefaultAmount from 'components/Amount';
import DefaultDistributionChart from 'components/DistributionChart';
import from from 'styles/responsive';

export const Container = styled.div<{ $modalOpen?: boolean }>`
  display: ${({ $modalOpen }) => ($modalOpen ? 'none' : 'flex')};
  align-items: center;
  flex-direction: column-reverse;
  grid-column: 1/3;
  margin-bottom: 2rem;
  padding: 2rem 0;
  background-color: ${({ theme }) => theme.colors.dark1};
  border-bottom: 1px solid ${({ theme }) => theme.colors.white10};
  ${from.tablet`
    align-items: start;
    flex-direction: column;
    margin-bottom: 3rem;
    background-color: transparent;
    border-bottom: none;
    padding: 0;
  `}
  ${from.laptop`
    align-items: center;
    margin-bottom: 2rem;
    display: grid;
    grid-template-columns: minmax(180px, auto) 1fr;
    grid-column-gap: 0.625rem;
  `}
`;

export const TitleContainer = styled.div`
  display: contents;
  ${from.tablet`
    display: block;
  `}
  svg {
    margin-right: 0.375rem;
  }
  svg ~ span {
    font-size: 1rem;
    line-height: 1.1875rem;
  }
`;

export const Title = styled.h2`
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  color: ${({ theme }) => theme.colors.white75};
  ${from.tablet`
    color: ${({ theme }) => theme.colors.white};
    margin: 0 0 0.25rem 0;
    font-size: 1.375rem;
    line-height: 1.4;
  `}
`;

export const Amount = styled(DefaultAmount)`
  margin: 0 0 0.5rem;
  font-size: 1.75rem;
  line-height: 2.4375rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  ${from.tablet`
    margin: 0 0 0.25rem;
  `}
`;

export const DistributionChart = styled(DefaultDistributionChart)`
  width: 100%;
  margin-top: 16px;
  ${from.laptop`
    margin: 0;
  `}
`;
