import { curveLinear } from '@visx/curve';
import { Group } from '@visx/group';
import { Fragment, memo } from 'react';
import type { FC } from 'react';

import { BasePoint, Path, Point } from './styles';
import type { Props } from './types';

const LineChart: FC<Props> = ({
  data,
  xScale,
  yScale,
  handleTooltipUpdated,
  handleTooltipClosed,
}) => (
  <Group>
    {data.map((datum) => (
      <Fragment key={`point_${datum.date}_${datum.cumulativeAllocation}`}>
        <BasePoint
          cx={xScale(datum.date)}
          cy={yScale(datum.cumulativeAllocation)}
          onMouseEnter={() =>
            handleTooltipUpdated({
              datum,
              isCumulative: true,
            })
          }
          onMouseLeave={handleTooltipClosed}
        />
        <Point
          cx={xScale(datum.date)}
          cy={yScale(datum.cumulativeAllocation)}
        />
      </Fragment>
    ))}
    <Path
      curve={curveLinear}
      data={data}
      x={(d) => xScale(d.date) ?? 0}
      y={(d) => yScale(d.cumulativeAllocation || 0) ?? 0}
    />
  </Group>
);

export default memo(LineChart);
