import { memo, useCallback } from 'react';
import type { FC } from 'react';

import { BarRounded } from './styles';
import type { Props } from './types';

const Bar: FC<Props> = ({
  datum,
  isLastOne,
  x,
  y,
  width,
  height,
  handleTooltipClosed,
  handleTooltipUpdated,
}) => {
  const handleTooltip = useCallback(
    () => handleTooltipUpdated(datum, isLastOne),
    [datum, isLastOne, handleTooltipUpdated],
  );
  return (
    <BarRounded
      top
      radius={4}
      x={x}
      width={Math.max(width, 0)}
      y={y}
      height={height}
      onMouseLeave={handleTooltipClosed}
      onMouseOver={handleTooltip}
    />
  );
};

export default memo(Bar);
