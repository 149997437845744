import type { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Login from './Login';
import ResetPassword from './ResetPassword';

const Guest: FC = () => (
  <Switch>
    <Route key="login" exact path="/">
      <Login />
    </Route>
    <Route key="reset-password" exact path="/reset-password">
      <ResetPassword />
    </Route>
    <Redirect to="/" />
  </Switch>
);

export default Guest;
