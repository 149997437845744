import { FC, memo, useMemo } from 'react';

import { Bar } from './styles';
import type { Props } from './types';

const MedianBar: FC<Props> = ({
  median,
  x,
  y,
  width,
  handleTooltipUpdated,
  handleTooltipClosed,
}) => {
  const handleTooltip = useMemo(
    () => handleTooltipUpdated({ average: 'Average yearly', value: median }),
    [median, handleTooltipUpdated],
  );
  return (
    <Bar
      key="median"
      x={x}
      y={y}
      width={width}
      height={3}
      onMouseLeave={handleTooltipClosed}
      onMouseMove={handleTooltip}
    />
  );
};

export default memo(MedianBar);
