import { parse, stringify } from 'query-string';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useLocation, useRouteMatch } from 'react-router-dom';

import useAllocationGroups from 'api/hooks/allocations/useAllocationGroups';
import useNotionalAssets from 'api/hooks/allocations/useNotionalAssets';
import useSelectedClient from 'api/hooks/clients/useSelectedClient';
import type { KeySortOfColumn } from 'utils/sort/types';
import useModal from 'utils/useModal';

import {
  useAssets,
  useFilteringSubtypes,
} from '../CategoryDetailFixedIncome/logic';
import type { SortAndFilterFormModel } from '../CategoryDetailFixedIncome/types';
import { useCurrentGrouping, useNavigationLinks, useSorting } from './logic';

export default function useConnect() {
  const {
    params: { categorySlug },
    url,
  } = useRouteMatch<{ categorySlug: string }>();

  const { replace } = useHistory();
  const { hash, pathname, search, state } = useLocation();
  const { selectedPortfolio } = useSelectedClient();

  const { getNameBySlug } = useAllocationGroups({
    groupBy: 'asset-breakdown',
    subselection: undefined,
    portfolio: selectedPortfolio?.id,
  });

  const grouping = useCurrentGrouping({ search });

  const { backUrl, breadcrumbs, navigationLinks, pageTitle, selectedUrl } =
    useNavigationLinks({
      categorySlug,
      getNameBySlug,
      grouping,
      search,
      url,
    });

  const { compare, currentSorting, onChangeSorting, sortOptions } =
    useSorting();

  const {
    currentFiltering,
    multipleOptions,
    filterOptions,
    onChangeFiltering,
  } = useFilteringSubtypes({
    categorySlug,
    portfolio: selectedPortfolio?.id,
  });

  const { allocation, allocationChartData, assets, balance } = useAssets({
    categorySlug,
    compare,
    multipleSubtypesOptions: multipleOptions,
    grouping,
    portfolio: selectedPortfolio?.id,
    url,
  });

  const headerColumns: KeySortOfColumn[] = [
    {
      titleColumn: `${assets.length} Assets`,
      keyOrderAsc: 'alphabeticalR',
      keyOrderDesc: 'alphabetical',
    },
    {
      titleColumn: 'Total ($)',
      keyOrderAsc: 'totalASC',
      keyOrderDesc: 'default',
    },
    {
      titleColumn: 'Total (%)',
      keyOrderAsc: 'totalPorcASC',
      keyOrderDesc: 'totalPorcDESC',
    },
  ];

  const { closeModal, modalOpen, openModal } = useModal();

  const initialValues: SortAndFilterFormModel = useMemo(
    () => ({
      filterValue: currentFiltering,
      sortValue: currentSorting,
    }),
    [currentFiltering, currentSorting],
  );

  const onSubmitForm = useCallback(
    ({ filterValue, sortValue }: SortAndFilterFormModel) => {
      replace({
        hash,
        pathname,
        search: stringify({
          ...parse(search),
          subtypes:
            filterValue === 'default' ? undefined : filterValue ?? undefined,
          sortBy: sortValue === 'default' ? undefined : sortValue ?? undefined,
        }),
        state,
      });
      closeModal();
    },
    [closeModal, hash, pathname, replace, search, state],
  );

  const { data } = useNotionalAssets({
    filterValue: currentFiltering,
    portfolio: selectedPortfolio?.id,
    grouping,
    categorySlug,
  });

  return {
    allocation,
    allocationChartData,
    assets,
    backUrl,
    balance,
    breadcrumbs,
    currentFiltering,
    currentSorting,
    filterOptions,
    handle: {
      changeFiltering: onChangeFiltering,
      changeSorting: onChangeSorting,
      closeModal,
      openModal,
      submitForm: onSubmitForm,
    },
    initialValues,
    modalOpen,
    navigationLinks,
    pageTitle,
    selectedUrl,
    sortOptions,
    headerColumns,
    url,
    notionalValues:
      categorySlug === 'equity-market' || categorySlug === 'equity-individual'
        ? data
        : undefined,
    search,
    categorySlug,
  };
}
