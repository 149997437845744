import { FC, memo } from 'react';

import ChangePercentage from 'components/ChangePercentage';

import {
  ColTitle,
  Content,
  FirstCol,
  Header,
  HeaderCell,
  Row,
  Table,
} from './styles';
import type { Props } from './types';

const StatisticalAnalysis: FC<Props> = ({ data }) => (
  <Table>
    <Header>
      <HeaderCell>Statistical Analysis</HeaderCell>
      <ColTitle>Last 30 Days</ColTitle>
      <ColTitle>Since Inception</ColTitle>
    </Header>
    <Content>
      {data.map((row) => (
        <Row key={row.id}>
          <FirstCol>{row.name}</FirstCol>
          <ChangePercentage value={row.last30Days} withArrow={false} />
          <ChangePercentage value={row.sinceInception} withArrow={false} />
        </Row>
      ))}
    </Content>
  </Table>
);

export default memo(StatisticalAnalysis);
