/* eslint-disable import/no-cycle */
import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import useLoginMutation from 'api/mutations/auth/login';
import type { LoginMutationVariables } from 'api/mutations/auth/login/types';
import {
  ACCESS_TOKEN_QUERY_KEY,
  AccessTokenQuery,
} from 'api/queries/auth/accessToken';
import { useVault } from 'containers/Services/VaultService';

export default function useLogin() {
  const client = useQueryClient();
  const { setupVault } = useVault();

  const { isLoading, mutateAsync } = useMutation(useLoginMutation(), {
    onSettled: async (data, _, { email }) => {
      if (data) {
        if (data.termsAgreed) {
          if (localStorage.getItem('biometrics_prompted') !== 'true') {
            await setupVault();
          }

          localStorage.setItem('biometrics_prompted', 'true');
        }

        client.setQueryData<AccessTokenQuery>(ACCESS_TOKEN_QUERY_KEY, {
          ...data,
          email,
        });
      }
    },
  });

  const login = useCallback(
    async (variables: LoginMutationVariables) => mutateAsync(variables),
    [mutateAsync],
  );

  return { loading: isLoading, login };
}
