import { useRouteMatch } from 'react-router';
import { useLocation } from 'react-router-dom';

import useCVBondSummary from 'api/hooks/clients/useCvBondSummary';
import useSelectedClient from 'api/hooks/clients/useSelectedClient';
import up from 'utils/paths';

import { useRows } from './logic';

export default function useConnect() {
  const { url } = useRouteMatch();
  const { search } = useLocation();

  const backUrl = `${up(url)}${search}`;

  const { selectedPortfolio } = useSelectedClient();

  const { data: cvBondPerformanceSummaries } = useCVBondSummary(
    selectedPortfolio?.id,
  );

  const { rows, subtypes } = useRows({
    cvBondPerformanceSummaries,
    search,
  });

  return { backUrl, rows, subtypes };
}
