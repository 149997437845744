import { FC, useMemo } from 'react';

import Amount from 'components/Amount';
import BaseTotalAllocationTable from 'components/BaseTotalAllocationTable';
import {
  Col,
  FirstCol,
  LastFirstCol,
  LastRow,
  SubItem100,
  Title,
} from 'components/BaseTotalAllocationTable/styles';
import { formatPercentage } from 'utils/percentages';

import type { Props } from './types';

const MaturityProfileDetailTable: FC<Props> = ({ data }) => {
  const rows = data.map((item) => ({
    id: item.id,
    firstCol: (
      <FirstCol key={`${item.id}-${item.name}`}>
        <Title>{item.name}</Title>
      </FirstCol>
    ),
    secondCol: (
      <Col key={`${item.id}-portfolio-allocation-${item.portfolioAllocation}`}>
        <Amount value={item.value} />
        <SubItem100>{formatPercentage(item.portfolioAllocation)}</SubItem100>
      </Col>
    ),
    thirdCol: (
      <Col
        key={`${item.id}-fixed-income-allocation-${item.fixedIncomeAllocation}`}
      >
        {formatPercentage(item.fixedIncomeAllocation)}
      </Col>
    ),
  }));

  const totalAllocation = useMemo(
    () => data.reduce((acc, item) => item.value + acc, 0),
    [data],
  );

  const totalFixedIncome = useMemo(
    () => data.reduce((acc, item) => item.fixedIncomeAllocation + acc, 0),
    [data],
  );

  return (
    <BaseTotalAllocationTable
      headers={['Name', 'Allocation', 'Fixed Income (%)']}
      rows={rows}
      lastRow={
        <LastRow>
          <LastFirstCol>Total</LastFirstCol>
          <Col>
            <Amount value={totalAllocation} />
          </Col>
          <Col>{formatPercentage(totalFixedIncome)}</Col>
        </LastRow>
      }
    />
  );
};

export default MaturityProfileDetailTable;
