import { Device } from '@capacitor/device';
import { useEffect, useState } from 'react';

import usePromise from 'utils/usePromise';

const getDeviceInfo = async () => Device.getInfo();

const getDeviceId = async () => Device.getId();

export default function useDevice() {
  const deviceInfo = usePromise(getDeviceInfo);
  const deviceID = usePromise(getDeviceId);

  const [isIOS, setIsIOS] = useState(false);
  const [isAndroid, setIsAndroid] = useState(false);

  useEffect(() => {
    if (deviceInfo?.platform === 'ios') {
      setIsIOS(true);
    } else if (deviceInfo?.platform === 'android') {
      setIsAndroid(true);
    }
  }, [deviceInfo]);

  return {
    deviceID: deviceID?.identifier ?? '',
    deviceInfo,
    isIOS,
    isAndroid,
  };
}
