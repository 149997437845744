import useGetClientTransactionsQuery, {
  GET_CLIENT_TRANSACTIONS_QUERY_KEY,
} from 'api/queries/transactions/getClientTransactions';
import { useQuery } from 'api/storage';

export default function useClientTransactions(
  portfolio: string | undefined,
  beginDate: Date,
  endDate: Date,
) {
  const formattedBeginDate = beginDate
    .toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .replace(/\//g, '-');

  const formattedEndDate = endDate
    .toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .replace(/\//g, '-');

  const { data } = useQuery(
    GET_CLIENT_TRANSACTIONS_QUERY_KEY(
      portfolio ?? '',
      formattedBeginDate,
      formattedEndDate,
    ),
    {
      enabled: !!portfolio,
      queryFn: useGetClientTransactionsQuery(),
    },
  );

  return {
    data: data ?? undefined,
  };
}
