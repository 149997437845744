import { AxisBottom } from '@visx/axis';
import { localPoint } from '@visx/event';
import { Group } from '@visx/group';
import { BarStackHorizontal } from '@visx/shape';
import { withTooltip } from '@visx/tooltip';
import type { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';
import { useEffect, useReducer, useRef } from 'react';

import from from 'styles/responsive';
import { formatPercentage } from 'utils/percentages';
import useMedia from 'utils/useMedia';

import { getWordMargin, useScales, useValues } from './logic';
import Tooltip from './Tooltip';
import type { BarStackHorizontalProps, TooltipData } from './types';

const defaultMargin = { top: 0, left: 0, right: 0, bottom: 20 };

export default withTooltip<BarStackHorizontalProps, TooltipData>(
  ({
    height,
    margin = defaultMargin,
    data,
    valueScale,
    period = 'year',
    isMobile,
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  }: BarStackHorizontalProps & WithTooltipProvidedProps<TooltipData>) => {
    const [, forceUpdate] = useReducer((x: number) => x + 1, 0);
    const { keys, values, valuesFormmated } = useValues(data, period);
    const isLaptop = useMedia(from.laptop.query);
    const { colorScale, nameScale, getName } = useScales(keys, data);
    const ref = useRef<HTMLHeadingElement>(null);
    const xMax = ref.current
      ? ref.current.offsetWidth
      : 0 - margin.left - margin.right;
    const yMax = height - margin.top - margin.bottom;

    valueScale.rangeRound([0, xMax]);
    nameScale.rangeRound([yMax, 0]);

    useEffect(() => {
      setTimeout(() => {
        forceUpdate();
      }, 0);
    }, [data]);

    return (
      <div ref={ref}>
        <svg width="100%" height={height} style={{ overflow: 'visible' }}>
          <Group top={margin.top} left={margin.left}>
            <BarStackHorizontal<{ [key: string]: string | number }, string>
              data={valuesFormmated}
              keys={keys}
              height={yMax}
              y={getName}
              xScale={valueScale}
              yScale={nameScale}
              color={colorScale}
              offset="diverging"
            >
              {(barStacks) =>
                barStacks.map((barStack) =>
                  barStack.bars.map((bar) => (
                    <svg
                      key={`barstack-horizontal-${barStack.index}-${bar.index}`}
                      style={{ overflow: 'visible' }}
                    >
                      <rect
                        x={bar.x}
                        y={bar.y}
                        width={
                          // eslint-disable-next-line no-nested-ternary
                          bar.width > 0
                            ? bar.width
                            : bar.bar[0] !== 0 || bar.bar[1] !== 0
                            ? 1
                            : 0
                        }
                        height={bar.height}
                        fill={bar.color}
                        rx="4"
                        onMouseLeave={() => {
                          hideTooltip();
                        }}
                        onMouseMove={(e) => {
                          const { x: xBar, y: yBar } = localPoint(e) ?? {
                            x: 0,
                            y: 0,
                          };

                          showTooltip({
                            tooltipData: bar,
                            tooltipTop: yBar,
                            tooltipLeft: xBar,
                          });
                        }}
                      />
                      {bar.bar.data.Client === 0 &&
                        bar.bar.data['Equity-Market'] === 0 &&
                        bar.bar.data['Fixed Income'] === 0 &&
                        barStack.bars.every((b) => b.width === 0) && (
                          <text
                            fontSize="12px"
                            fontWeight={bar.key === 'Client' ? '500' : '400'}
                            opacity={bar.key === 'Client' ? '1' : '0.75'}
                            fill="white"
                            x={
                              Math.max(...values) > 0 || Math.min(...values) > 0
                                ? bar.x
                                : bar.x -
                                  (isMobile
                                    ? getWordMargin(
                                        String(bar.bar.data.shortName) || '',
                                      )
                                    : 35)
                            }
                            y={bar.y + bar.height / 2 + 4}
                          >
                            {!isMobile
                              ? formatPercentage(
                                  bar.bar[1] > 0
                                    ? bar.bar[1] / 100
                                    : bar.bar[0] / 100,
                                )
                              : bar.bar.data.shortName}
                          </text>
                        )}
                      {(bar.width > 0 ||
                        bar.bar[0] !== 0 ||
                        bar.bar[1] !== 0) &&
                        barStack.bars.some(
                          (b) =>
                            b.width !== 0 ||
                            bar.bar[0] !== 0 ||
                            bar.bar[1] !== 0,
                        ) && (
                          <text
                            fontSize="12px"
                            fontWeight={bar.key === 'Client' ? '500' : '400'}
                            opacity={bar.key === 'Client' ? '1' : '0.75'}
                            fill="white"
                            x={
                              bar.bar[1] > 0
                                ? bar.x + bar.width + (isMobile ? 5 : 10)
                                : bar.x -
                                  (isMobile
                                    ? getWordMargin(
                                        String(bar.bar.data.shortName) || '',
                                      )
                                    : 55)
                            }
                            y={bar.y + bar.height / 2 + 4}
                          >
                            {!isMobile
                              ? formatPercentage(
                                  bar.bar[1] > 0
                                    ? bar.bar[1] / 100
                                    : bar.bar[0] / 100,
                                )
                              : bar.bar.data.shortName}
                          </text>
                        )}
                    </svg>
                  )),
                )
              }
            </BarStackHorizontal>
            <AxisBottom
              top={yMax}
              scale={valueScale}
              hideTicks
              hideAxisLine
              tickLabelProps={() => ({
                fill: 'white',
                opacity: '0.5',
                fontSize: 12,
                textAnchor: 'middle',
              })}
              tickFormat={(value) => `${value}${value === 0 ? '' : '%'}`}
              numTicks={isLaptop ? undefined : 5}
            />
          </Group>
        </svg>
        {tooltipOpen && tooltipData && tooltipLeft && tooltipTop && (
          <Tooltip
            data={data}
            tooltipLeft={tooltipLeft}
            tooltipTop={tooltipTop}
            tooltipData={tooltipData}
            period={period}
          />
        )}
      </div>
    );
  },
);
