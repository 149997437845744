import type { GetPerformanceBreakdownResponse } from 'api/queries/home/getPerformanceBreakdown/types';
import { parseBackendDate } from 'utils/dates';
import kebabCaseCustom from 'utils/kebabCaseCustom';
import type { WithoutNulls } from 'utils/withoutNulls';

type Input = NonNullable<
  NonNullable<
    NonNullable<
      GetPerformanceBreakdownResponse['data']
    >[number]['subPortfolios']
  >[number]['performanceBreakDown']
>[number];

export const normalizePerformanceBreakdownReport = (
  input: WithoutNulls<Input>,
) =>
  ({
    id: `${input.id}`,
    date: input.reportDate ? parseBackendDate(input.reportDate).getTime() : 0,
    name: input.reportName ?? '',
    profitAndLoss: input.profit_Loss ?? 0,
    roc: input.roc ?? 0,
    roe: input.roe ?? 0,
    slug: kebabCaseCustom(input.reportName ?? ''),
  } as const);

export type PerformanceBreakdownReport = ReturnType<
  typeof normalizePerformanceBreakdownReport
>;
