import type { FC } from 'react';
import { useCallback } from 'react';

import { Container, LaptopTabs } from './styles';
import type { Props, SummaryType } from './types';

const laptopOptions = [
  {
    label: 'Fixed Income Characteristics',
    value: 'FIStats' as const,
  },
  {
    label: 'Bond Performance Analysis',
    value: 'BPAStats' as const,
  },
];

const Controls: FC<Props> = ({ onChange, selectedSummaryType }) => {
  const handleSummaryTypeChange = useCallback(
    (summaryType: SummaryType) => {
      onChange((current) => ({
        ...current,
        summaryType,
      }));
    },
    [onChange],
  );

  return (
    <Container>
      <LaptopTabs<SummaryType>
        onChange={handleSummaryTypeChange}
        value={selectedSummaryType}
        tabs={laptopOptions}
      />
    </Container>
  );
};

export default Controls;
