import type { FC } from 'react';

import MarketOverviewTable from 'components/MarketOverviewTable';

import type { Props } from '../types';
import useConnect from './connect';
import { Container, EmptyState, Navigation, Title } from './styles';

const MarketsDesktopTablet: FC<Props> = ({ watchlistData }) => {
  const {
    activeLink,
    data,
    handle,
    links,
    selectedCategory,
    currentSorting,
    headerColumns,
  } = useConnect({
    watchlistData,
  });

  return (
    <Container>
      <Title>Market Overview</Title>
      <Navigation activeLink={activeLink} links={links} />
      {data.length > 0 ? (
        <MarketOverviewTable
          data={data}
          onPin={handle.addToWatchlist}
          onUnpin={handle.removeFromWatchlist}
          headerColumns={headerColumns}
          currentSorting={currentSorting}
          onChangeSorting={handle.changeSorting}
        />
      ) : (
        <>
          {!selectedCategory && (
            <EmptyState>
              You haven&apos;t added any assets to the Watchlist yet!
            </EmptyState>
          )}
        </>
      )}
    </Container>
  );
};

export default MarketsDesktopTablet;
