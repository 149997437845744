import type { FC } from 'react';

import type { Props } from '../types';
import {
  Amount,
  Container,
  Info,
  InfoValue,
  Label,
  TotalValue,
} from './styles';

const MobileVersion: FC<Props> = ({ equity, leverage, total }) => (
  <Container>
    <TotalValue>
      <Amount value={total} />
      <Label>Portfolio Balance</Label>
    </TotalValue>
    <Info>
      {(equity > 0 || leverage > 0) && (
        <>
          <InfoValue>
            <Amount value={leverage} />
            <Label>Leverage</Label>
          </InfoValue>
          <InfoValue>
            <Amount value={equity} />
            <Label>Portfolio Equity</Label>
          </InfoValue>
        </>
      )}
    </Info>
  </Container>
);

export default MobileVersion;
