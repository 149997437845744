import styled from 'styled-components';

import DefaultYieldHistoryChart from 'components/YieldHistoryChart';
import DefaultYieldSpreadChart from 'components/YieldSpreadChart';

export const Container = styled.div``;

export const Title = styled.h2`
  margin: 0 0 1.5rem;
  font-size: 1rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.weights.bold};
`;

export const YieldHistoryChart = styled(DefaultYieldHistoryChart)`
  margin-bottom: 1.5rem;
`;

export const YieldSpreadChart = styled(DefaultYieldSpreadChart)``;
