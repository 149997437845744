import { useCallback } from 'react';
import type { QueryFunction } from 'react-query';

import useRequest from 'api/request';
import type { DailyVolatilityDatum } from 'model/DailyVolatilityDatum';
import { normalizeDailyVolatilityDatum } from 'model/DailyVolatilityDatum';
import getPortfolioId from 'utils/getPorfolioId';
import withoutNulls from 'utils/withoutNulls';

import type {
  GetDailyVolatilityChartDataResponse,
  GetGetDailyVolatilityChartDataRequest,
} from './types';

export const GET_DAILY_VOLATILITY_CHART_DATA_QUERY_KEY = (
  portfolio: string,
) => ['riskAnalysis/getDailyVolatilityChartData', portfolio];

export default function useGetDailyVolatilityChartDataQuery(): QueryFunction<
  DailyVolatilityDatum[]
> {
  const request = useRequest<
    GetGetDailyVolatilityChartDataRequest,
    GetDailyVolatilityChartDataResponse
  >();

  return useCallback(
    async ({ queryKey }) => {
      const [, portfolio] = queryKey as [string, string];

      const response = await request(
        '/api/CVData/getDailyVolatilitySinceInception_HistogramReport/{portfolio}',
        {
          urlParams: {
            portfolio: getPortfolioId(portfolio) ?? '',
          },
        },
      );

      const data = await response.json();

      if (!data.isSuccess) {
        throw new Error(data.message ?? '');
      }

      return withoutNulls(data.data ?? []).map(normalizeDailyVolatilityDatum);
    },
    [request],
  );
}
