import { IonRadioGroup } from '@ionic/react';
import styled from 'styled-components';

import OptionItem from 'components/OptionItem';
import DefaultSearchBar from 'components/SearchBar';
import { RadioGroup as DefaultRadioGroup } from 'components/SearchBar/styles';

export const Container = styled.div``;

export const Title = styled.h2`
  margin: 0 0 0.5rem;
  padding-top: 4rem;
  font-size: 1.375rem;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.weights.regular};
`;

export const SubTitle = styled.h2`
  margin: 0 0 1rem;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.white75};
  font-weight: ${({ theme }) => theme.weights.regular};
`;

export const TitleSearchList = styled(Title)`
  padding: 4rem 0 1rem 0;
`;

export const PortfolioTitle = styled(Title)`
  margin: 3.5rem 0 1rem;
`;

export const RadioGroup = styled(IonRadioGroup)`
  > * {
    --background: ${({ theme }) => theme.colors.dark2};
    --background-hover: ${({ theme }) => theme.colors.dark2};
    --padding-start: 1rem;
    --padding-end: 1rem;
    --min-height: 3.25rem;
  }
  > :last-child {
    --border-color: transparent;
  }
`;

export const RadioGroupContainer = styled.div`
  border-radius: 8px;
  overflow: hidden;
`;

export const RadioItem = styled(OptionItem)``;

export const SearchBar = styled(DefaultSearchBar)`
  ${DefaultRadioGroup} {
    > :last-child {
      --border-color: transparent;
    }
  }
`;

export const SearchBarList = styled(DefaultSearchBar)``;
