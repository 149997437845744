import { FC, memo, useEffect } from 'react';

import { useNews } from 'containers/Services/NewsService';
import from from 'styles/responsive';
import useMedia from 'utils/useMedia';

import NewsCardDesktopTablet from './NewsCardDesktopTablet';
import NewsCardMobile from './NewsCardMobile';
import type { Props } from './types';

const NewsCard: FC<Props> = ({
  messageID,
  url,
  message,
  date,
  source,
  image,
  isRead,
  onUpdateNewsReadStatus,
}) => {
  const isTablet = useMedia(from.tablet.query);
  const newsServices = useNews();

  useEffect(
    () => () => {
      newsServices?.updateNotificationToNews(false);
      localStorage.setItem('navigate_to_news_from_notification', 'false');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return isTablet ? (
    <NewsCardDesktopTablet
      messageID={messageID}
      url={url}
      message={message}
      date={date}
      source={source}
      image={image}
      className={isRead ? 'mark-as-read-button' : 'mark-as-unread-button'}
      isRead={isRead}
      onUpdateNewsReadStatus={onUpdateNewsReadStatus}
    />
  ) : (
    <NewsCardMobile
      messageID={messageID}
      url={url}
      message={message}
      date={date}
      source={source}
      image={image}
      className={isRead ? 'mark-as-read-button' : 'mark-as-unread-button'}
      isRead={isRead}
      onUpdateNewsReadStatus={onUpdateNewsReadStatus}
    />
  );
};

export default memo(NewsCard);
