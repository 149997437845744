import uniq from 'lodash/uniq';
import { useMemo, useState } from 'react';

import type { IncomeProfileMonth } from 'model/IncomeProfileMonth';
import { formatMonthYear } from 'utils/dates';

type Option = {
  label: string;
  value: string;
};

export function getEntitiesOptions(data: IncomeProfileMonth[]) {
  const entities = uniq(
    data
      .map((dataMonth) =>
        dataMonth.assets
          .filter((item) => item.entity !== '')
          .map((item) => item.entity),
      )
      .flat(),
  );
  return [
    { value: 'default', label: 'All' },
    ...entities.map((item) => ({ value: item, label: item })),
  ];
}

export function getAccountsOptions(data: IncomeProfileMonth[]) {
  const accounts = uniq(
    data
      .map((dataMonth) =>
        dataMonth.assets
          .filter((item) => item.account !== '')
          .map((item) => item.account),
      )
      .flat(),
  );
  return [
    { value: 'default', label: 'All' },
    ...accounts.map((item) => ({ value: item, label: item })),
  ];
}

export function applyEntityFilter(
  data: IncomeProfileMonth[],
  filterEntity: string[] | undefined,
) {
  if (filterEntity !== undefined && filterEntity.length !== 0) {
    return data.map((dataMonth) => {
      const assets = dataMonth.assets.filter((item) =>
        filterEntity.includes(item.entity),
      );
      const assetsAfterTax = dataMonth.assetsAfterTax.filter((item) =>
        filterEntity.includes(item.entity),
      );
      return {
        id: dataMonth.id,
        date: dataMonth.date,
        assets,
        assetsAfterTax,
        income: assets.reduce(
          (sum, asset) => Number(sum) + Number(asset.income ?? 0),
          0,
        ),
        incomeAfterTax: assetsAfterTax.reduce(
          (sum, asset) => Number(sum) + Number(asset.income ?? 0),
          0,
        ),
      };
    });
  }

  return data;
}

export function applyAccountFilter(
  data: IncomeProfileMonth[],
  filterAccount: string[] | undefined,
) {
  if (filterAccount !== undefined && filterAccount.length !== 0) {
    return data.map((dataMonth) => {
      const assets = dataMonth.assets.filter((item) =>
        filterAccount.includes(item.account),
      );
      const assetsAfterTax = dataMonth.assetsAfterTax.filter((item) =>
        filterAccount.includes(item.account),
      );
      return {
        id: dataMonth.id,
        date: dataMonth.date,
        assets,
        assetsAfterTax,
        income: assets.reduce(
          (sum, asset) => Number(sum) + Number(asset.income ?? 0),
          0,
        ),
        incomeAfterTax: assetsAfterTax.reduce(
          (sum, asset) => Number(sum) + Number(asset.income ?? 0),
          0,
        ),
      };
    });
  }

  return data;
}

export function useDateSelection({
  incomeProfileR12DataFiltered,
}: {
  incomeProfileR12DataFiltered: readonly IncomeProfileMonth[];
}) {
  const dateOptions = useMemo(
    (): readonly [Option, ...Option[]] => [
      {
        label: 'Summary',
        value: 'summary',
      },
      ...incomeProfileR12DataFiltered.map(({ date }) => ({
        value: `${date}`,
        label: `${formatMonthYear(date)}`,
      })),
    ],
    [incomeProfileR12DataFiltered],
  );

  const [selectedDate, setSelectedDate] = useState(dateOptions[0].value);

  const incomeProfileR12Detail = useMemo(
    () =>
      selectedDate === 'summary'
        ? undefined
        : incomeProfileR12DataFiltered
            .find((month) => `${month.date}` === selectedDate)
            ?.assets?.sort((a, b) => a.name.localeCompare(b.name)),
    [incomeProfileR12DataFiltered, selectedDate],
  );

  const incomeProfileR12DetailAfterTax = useMemo(
    () =>
      selectedDate === 'summary'
        ? undefined
        : incomeProfileR12DataFiltered
            .find((month) => `${month.date}` === selectedDate)
            ?.assetsAfterTax?.sort((a, b) => a.name.localeCompare(b.name)),
    [incomeProfileR12DataFiltered, selectedDate],
  );

  return {
    dateOptions,
    incomeProfileR12Detail,
    incomeProfileR12DetailAfterTax,
    selectedDate,
    setSelectedDate,
  };
}
