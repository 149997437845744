import { TooltipWithBounds as DefaultTooltipWithBounds } from '@visx/tooltip';
import styled, { css } from 'styled-components';

import { tooltipStyles } from 'components/Tooltip/styles';

export const TooltipWithBounds = styled(DefaultTooltipWithBounds).attrs({
  offsetLeft: 16,
  offsetTop: -10,
  unstyled: true,
})`
  pointer-events: none;
  position: absolute;

  // Custom styles
  ${tooltipStyles} {
    display: flex;
    flex-direction: column;
  }

  > *:not(:last-child) {
    margin-bottom: 0.25rem;
  }
`;

export const Date = styled.span`
  color: ${({ theme }) => theme.colors.white75};
  font-size: 0.75rem;
  line-height: 0.875rem;
`;

export const Percentage = styled.span`
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  line-height: 1.0625rem;
`;

export const ColorPercentage = styled(Percentage)<{ $positive: boolean }>`
  ${({ $positive, theme }) =>
    $positive
      ? css`
          color: ${theme.colors.statusIncrease};
        `
      : css`
          color: ${theme.colors.statusDecrease};
        `}
`;
