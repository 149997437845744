import { localPoint } from '@visx/event';
import { scaleBand, scaleLinear } from '@visx/scale';
import { useTooltip as useVisxTooltip } from '@visx/tooltip';
import { MouseEvent, TouchEvent, useCallback, useMemo } from 'react';

import type { Average, Datum } from './types';

export const getTotalAmount = (d: Datum) => d.allocation;

export function useHeight(): number {
  return 424;
}

export function useEdgeValues(data: readonly Datum[]) {
  const start = useMemo(
    () =>
      data.reduce(
        (lowestPercentage, datum) =>
          Math.min(lowestPercentage, datum.allocation),
        Infinity,
      ),
    [data],
  );

  const end = useMemo(
    () =>
      data.reduce(
        (highestPercentage, datum) =>
          Math.max(highestPercentage, datum.allocation),
        0,
      ),
    [data],
  );

  const totals = useMemo(() => data.map(getTotalAmount), [data]);

  const maxValue = useMemo(() => Math.max(...totals), [totals]);
  const minValue = useMemo(() => Math.min(...totals), [totals]);

  return {
    end,
    maxValue,
    minValue,
    start,
    totals,
  };
}

export function useMargins() {
  // This builds on top of the padding of the Container element. Instead of
  // putting all on the padding, we put 1rem here so the chart line, which has
  // a thick stroke, doesn't get cut. We also put the rest on the Container
  // element so it is easier for other developers to change the styles.
  const topMargin = 32;

  // This can't simply be padding on the Container element because it wouldn't
  // be noticed by the resize observer.
  const rightMargin = 32;

  const bottomAxisHeight = 36;
  const bottomAxisMargin = 16;

  const leftAxisWidth = 84;
  const leftAxisMargin = 16;

  return {
    bottomAxisHeight,
    bottomAxisMargin,
    leftAxisMargin,
    leftAxisWidth,
    rightMargin,
    topMargin,
  };
}

export function useScales(props: {
  bottomAxisHeight: number;
  bottomAxisMargin: number;
  data: Datum[];
  end: number;
  height: number;
  leftAxisMargin: number;
  leftAxisWidth: number;
  maxValue: number;
  minValue: number;
  rightMargin: number;
  start: number;
  topMargin: number;
  width: number;
}) {
  // Destructured here rather than on the "props" parameter itself so that the
  // parameter hint in IntelliJ based IDEs isn't gigantic.
  const {
    bottomAxisHeight,
    bottomAxisMargin,
    data,
    height,
    leftAxisMargin,
    leftAxisWidth,
    maxValue,
    rightMargin,
    topMargin,
    width,
  } = props;

  const xScale = useMemo(
    () =>
      scaleBand({
        domain: data.map((datum) => datum.rating),
        padding: 0.4,
        range: [leftAxisWidth + leftAxisMargin, width - rightMargin],
        paddingOuter: 0,
      }),
    [leftAxisMargin, leftAxisWidth, rightMargin, width, data],
  );

  const yScale = useMemo(
    () =>
      scaleLinear({
        domain: [0, maxValue],
        range: [height - bottomAxisHeight - bottomAxisMargin, topMargin],
        nice: true,
        clamp: true,
      }),
    [bottomAxisHeight, bottomAxisMargin, height, maxValue, topMargin],
  );

  return { xScale, yScale };
}

export function useTooltip({
  xScale,
  yScale,
  height,
  leftAxisMargin,
  leftAxisWidth,
}: {
  xScale: ReturnType<typeof useScales>['xScale'];
  yScale: ReturnType<typeof useScales>['yScale'];
  height: number;
  leftAxisMargin: number;
  leftAxisWidth: number;
}) {
  const { hideTooltip, showTooltip, tooltipData, tooltipLeft, tooltipTop } =
    useVisxTooltip<Datum>();

  const handleTooltipUpdated = useCallback(
    (datum: Datum) => () => {
      const x = xScale(datum.rating) ?? 0;
      const width = xScale.bandwidth();
      const y = yScale(datum.allocation) ?? 0;
      const top = Math.max(y, height);
      const left = x + width + leftAxisMargin - leftAxisWidth;
      showTooltip({
        tooltipData: datum,
        tooltipTop: top,
        tooltipLeft: left,
      });
    },
    [xScale, yScale, height, leftAxisMargin, leftAxisWidth, showTooltip],
  );

  return {
    handleTooltipUpdated,
    handleTooltipClosed: hideTooltip,
    tooltipData,
    tooltipLeft,
    tooltipTop,
  };
}

export function useMedianTooltip({ y }: { y: number }) {
  const { hideTooltip, showTooltip, tooltipData, tooltipLeft, tooltipTop } =
    useVisxTooltip<Average>();

  const handleTooltipUpdated = useCallback(
    (average: Average) =>
      (event: MouseEvent<SVGRectElement> | TouchEvent<SVGRectElement>) => {
        const eventSvgCoords = localPoint(event);
        showTooltip({
          tooltipData: average,
          tooltipTop: y,
          tooltipLeft: eventSvgCoords?.x,
        });
      },
    [showTooltip, y],
  );

  return {
    handleMedianTooltipUpdated: handleTooltipUpdated,
    handleMedianTooltipClosed: hideTooltip,
    tooltipMedianData: tooltipData,
    tooltipMedianLeft: tooltipLeft,
    tooltipMedianTop: tooltipTop,
  };
}
