import { useMemo } from 'react';

import { useDataManipulation } from '.';

export type SortOption<T> = {
  compare?: (a: T, b: T) => number;
  label: string;
  value: string;
};

export default function useEntitySorting<T>({
  sortOptions,
}: {
  sortOptions: readonly [SortOption<T>, ...SortOption<T>[]];
}) {
  const dataManipulation = useDataManipulation({
    key: 'sortBy',
    options: useMemo(() => {
      const [first, ...rest] = sortOptions;

      return [
        {
          ...first,
          fn: first.compare,
        },
        ...rest.map((option) => ({
          ...option,
          fn: option.compare,
        })),
      ];
    }, [sortOptions]),
  });

  return {
    compare: dataManipulation.fn,
    currentSorting: dataManipulation.currentManipulation,
    onChangeSorting: dataManipulation.onChangeManipulation,
    sortOptions: dataManipulation.options,
  };
}
