import { useField } from 'formik';
import { FC, memo, useCallback } from 'react';

import { Container, Item } from '../styles';
import type { Props } from '../types';

const SortItemsGroup: FC<Props> = ({ className, options, name }) => {
  const [{ value = options[0]?.value }, , { setValue }] =
    useField<string>(name);
  const onChange = useCallback(
    (filterValue: string) => {
      setValue(filterValue);
    },
    [setValue],
  );
  return (
    <Container className={className}>
      {options.map((item) => (
        <Item
          key={item.value}
          $checked={item.value === value}
          onClick={() => onChange(item.value)}
          variant="link"
          name="value"
          value={value}
        >
          {item.label}
        </Item>
      ))}
    </Container>
  );
};
export default memo(SortItemsGroup);
