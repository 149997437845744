import { css } from 'styled-components';

// We might have more mixins in the future
// eslint-disable-next-line import/prefer-default-export
export const prettyScrollbar = ({
  color,
  vertical = true,
  size = '6px',
}: {
  color?: string;
  vertical?: boolean;
  size?: string;
}) => css`
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => color ?? theme.colors.white25} transparent;

  &::-webkit-scrollbar {
    ${vertical
      ? css`
          width: ${size};
        `
      : css`
          height: ${size};
        `};
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => color ?? theme.colors.white25};
    border-radius: 4px;
  }
`;
