import { useCallback } from 'react';
import { useMutation } from 'react-query';

import useChangePasswordMutation from 'api/mutations/auth/changePassword';
import type { ChangePasswordVariables } from 'api/mutations/auth/changePassword/types';

export default function useResetPassword() {
  const { isLoading, isSuccess, mutateAsync } = useMutation(
    useChangePasswordMutation(),
  );

  const changePassword = useCallback(
    (variables: ChangePasswordVariables) => mutateAsync(variables),
    [mutateAsync],
  );

  return { loading: isLoading, changePassword, success: isSuccess };
}
