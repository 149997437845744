import uniq from 'lodash/uniq';
import { useMemo } from 'react';

import type { AssetGrouping } from 'model/AssetGrouping';
import type { NotionalValuesSummary } from 'model/NotionalValues';
import kebabCaseCustom from 'utils/kebabCaseCustom';
import getNotionalSummary from 'utils/notionals/getNotionalSummary';

import useNotionalValues from './useNotionalValues';

function getKeyByGrouping(groupBy: AssetGrouping) {
  switch (groupBy) {
    case 'custodian':
      return 'custodian';
    case 'account':
      return 'account';
    case 'entity':
      return 'entity';
    case 'sector':
      return 'sector';
    case 'country':
      return 'country';
    default:
      return 'account';
  }
}

export default function useNotionalAssets({
  filterValue,
  grouping,
  portfolio,
  categorySlug,
}: {
  filterValue: string | undefined;
  grouping: { groupBy: AssetGrouping; subselection: string } | undefined;
  portfolio: string | undefined;
  categorySlug: string;
}): { data: NotionalValuesSummary; subtypes: string[] } {
  const { data } = useNotionalValues(portfolio);

  const filtered = useMemo(() => {
    let dataFiltered = data?.filter(
      (v) =>
        kebabCaseCustom(v.instrumentType) === kebabCaseCustom(categorySlug),
    );
    if (dataFiltered && grouping?.groupBy) {
      const selectingKey = getKeyByGrouping(grouping.groupBy);
      dataFiltered =
        dataFiltered?.filter(
          (asset) =>
            kebabCaseCustom(asset[selectingKey] ?? '') ===
            grouping?.subselection,
        ) ?? [];
    }
    return dataFiltered;
  }, [data, grouping, categorySlug]);

  const dataResult =
    filterValue && filterValue.length > 0
      ? filtered?.filter((v) =>
          filterValue
            ?.split(',')
            .includes(v.subType ? kebabCaseCustom(v.subType) ?? '' : ''),
        )
      : filtered;

  const subtypes = useMemo(
    () => uniq(dataResult && dataResult.map((item) => item.subType)),
    [dataResult],
  );

  return {
    data: dataResult
      ? getNotionalSummary(dataResult)
      : {
          balance: 0,
          balancePct: 0,
          notional: 0,
          notionalPct: 0,
          totalNotional: 0,
          totalNotionalPct: 0,
        },
    subtypes,
  };
}
