import { useCallback } from 'react';
import type { QueryFunction } from 'react-query';

import useRequest from 'api/request';
import { CoverageTeam, normalizeCoverageTeam } from 'model/CoverageTeam';
import getPortfolioId from 'utils/getPorfolioId';
import withoutNulls from 'utils/withoutNulls';

import type { GetCoverageTeamRequest, GetCoverageTeamResponse } from './types';

export const GET_COVERAGE_TEAM_QUERY_KEY = (portfolio: string) => [
  'clients/getCoverageTeam',
  portfolio,
];

export default function useGetCoverageTeam(): QueryFunction<CoverageTeam[]> {
  const request = useRequest<GetCoverageTeamRequest, GetCoverageTeamResponse>();

  return useCallback(
    async ({ queryKey }) => {
      const [, portfolio] = queryKey as [string, string];

      const response = await request(
        '/api/CVData/getCVCoverageTeam/{portfolio}',
        {
          urlParams: {
            portfolio: getPortfolioId(portfolio) ?? '',
          },
        },
      );

      const data = await response.json();
      if (!data.isSuccess) {
        throw new Error(data.message ?? '');
      }

      return withoutNulls(data.data ?? []).map(normalizeCoverageTeam);
    },
    [request],
  );
}
