import styled, { css } from 'styled-components';

import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import Button from 'components/Button';
import from from 'styles/responsive';

import type { ItemProps } from './types';

export const StatsTable = styled.div<{
  $columns: ItemProps['$columns'];
  $rows: ItemProps['$rows'];
}>`
  display: grid;
  ${({ $rows }) =>
    $rows === 1 &&
    css`
      grid-auto-flow: column;
    `}
  border-radius: 0.5rem;
  overflow: hidden;
  > :not(:last-child) {
    border-right: 0.5px solid ${({ theme }) => theme.colors.white10};
  }
  ${({ $columns, $rows }) =>
    $rows > 1 &&
    css`
      > :nth-child(${$columns}) {
        border-right: 0;
      }
      > :nth-child(n + ${$columns + 1}) {
        border-top: 0.5px solid ${({ theme }) => theme.colors.white10};
      }
    `}
  ${({ $columns }) =>
    $columns &&
    css`
      grid-template-columns: repeat(${$columns}, 1fr);
    `}
`;

export const Cell = styled.div`
  padding: 1rem 0.625rem;
  background-color: ${({ theme }) => theme.colors.dark1};
  text-align: center;
`;

export const CellAmount = styled.div`
  margin: 0 0 0.4063rem;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.weights.bold};
  span {
    margin-left: 8px;
    font-weight: ${({ theme }) => theme.weights.regular};
    opacity: 0.75;
  }
  ${from.laptop`
    font-size: 1rem;
    line-height: 1.0625rem;
  `}
`;

export const CellTitle = styled.span`
  font-size: 0.6875rem;
  line-height: 0.8438rem;
  color: ${({ theme }) => theme.colors.white75};
  ${from.laptop`
    font-size: 0.75rem;
    line-height: 0.9375rem;
  `}
`;

export const TableButton = styled(Button)`
  min-width: auto;
`;

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ButtonText = styled.span`
  width: 100%;
  text-align: left;
  font-size: 1rem !important;
`;

export const Chevron = styled(ChevronRight)``;
