import type { GetAllocationsResponse } from 'api/queries/allocations/getAllocations/types';
import kebabCaseCustom from 'utils/kebabCaseCustom';
import type { WithoutNulls } from 'utils/withoutNulls';

type Input = NonNullable<GetAllocationsResponse['data']>[number];

export const normalizeAssetAllocation = (input: WithoutNulls<Input>) =>
  ({
    accruedInterest: input.accruedInterest ?? 0,
    allocation: input.pctPortfolio ?? 0,
    annualIncome: input.annualIncome ?? 0,
    annualIncomeAfterTax: input.annualIncomeAfterTax ?? 0,
    capitalCalled: input.grossCapitalCall ?? 0,
    capitalCommitment: input.capitalCommitment ?? 0,
    country: input.countryName ?? 'No Country',
    coupon: input.coupon ?? 0,
    custodian: input.custodianName ?? 'No Custodian',
    custodianReporting: input.custodianReporting ?? 'No custodian Reporting',
    distributions: input.distributions ?? 0,
    dividendsReceived: input.dividendsReceived ?? 0,
    couponsReceived: input.couponsReceived ?? 0,
    duration: input.duration ?? 0,
    entity: input.entity ?? 'No Entity',
    fullName: input.fullAssetName ?? '',
    id: `${kebabCaseCustom(input.fullAssetName ?? '')}_${kebabCaseCustom(
      input.countryName ?? '',
    )}_${kebabCaseCustom(input.instrumentTypeName ?? '')}`,
    instrumentType: input.instrumentTypeName ?? 'No Instrument',
    marketPrice: input.price ?? 0,
    marketValue: input.marketValue ?? 0,
    maturity: input.cvMaturity ?? '',
    maturityDate: input.maturityDate,
    moi: input.moi ?? 0,
    name: input.assetName ?? '',
    pemv: input?.pemv ?? 0,
    rating: input.rating ?? '',
    rtgMOODY: input.rtG_MOODY ?? '',
    rtgSP: input.rtG_SP ?? '',
    rtgFITCH: input.rtG_FITCH ?? '',
    sector: input.sectorName ?? 'No Sector',
    shares: input.shares ?? 0,
    slug: kebabCaseCustom(input.fullAssetName ?? '') ?? '',
    subtype: input.subtypeName ?? '',
    sponsor: input.sponsor ?? '',
    toBeCalled: input.toBeCalled ?? 0,
    totalPortfolioPercent: input.pctPortfolio ?? 0,
    totalProfitAndLoss: input.totalPnL ?? 0,
    ttc: input.timeToMaturity ?? 0,
    value: input.total ?? 0,
    ytw: input.yieldtoWorst ?? 0,
    tey: input.tey ?? 0,
    contDollars: input.contDollars ?? 0,
    cost: input.cost ?? 0,
    totalPortfolioAtCostPercent: input.percentPortfolioAtCost ?? 0,
    valueDate: input.valueDate,
    closingDate: input.closingDate,
    endOfInvPeriodDate: input.endOfInvPeriod,
    maximumLiquidityDate: input.maximumLiquidityDate,
    pnLExclCpn: input.pnLExclCpn ?? 0,
    aIatPurchase: input.aIatPurchase ?? 0,
    closedPosition: input.closedPosition ?? false,
    isinTicker: input.isin_Ticker ?? '',
    priceAsOfDate: input.priceAsOfDate,
    fiMultiplier: input.fiMultiplier ?? null,
  } as const);

export type AssetAllocation = ReturnType<typeof normalizeAssetAllocation>;
