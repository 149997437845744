import type { PickByValue } from 'utility-types';

import type { Asset } from 'api/hooks/allocations/useAllocationAssets';

type Input = Pick<
  Asset,
  | 'allocation'
  | 'totalPortfolioAtCostPercent'
  | 'capitalCalled'
  | 'capitalCommitment'
  | 'distributions'
  | 'moi'
  | 'toBeCalled'
  | 'value'
  | 'contDollars'
>;

export default function getPrivateInvestmentsSummary(assets: readonly Input[]) {
  function sum(key: keyof PickByValue<Input, number>) {
    return assets.reduce((total, asset) => asset[key] + total, 0);
  }

  return {
    allocation: sum('allocation'),
    totalPortfolioAtCostPercent: sum('totalPortfolioAtCostPercent'),
    capitalCommitment: sum('capitalCommitment'),
    distributions: sum('distributions'),
    grossCapitalCall: sum('capitalCalled'),
    toBeCalled: sum('toBeCalled'),
    value: sum('value'),
    contDollars: sum('contDollars'),
  };
}
