import { FC, memo } from 'react';

import { Legend, Table, TableContainer } from '../styles';
import Row from './MobileRow';
import type { Props } from './types';

const MobileVersion: FC<Props> = ({ data, onPin, onUnpin, selectedData }) => (
  <TableContainer>
    <Table>
      {data.map((row) => (
        <Row
          key={row.id}
          onPin={onPin}
          onUnpin={onUnpin}
          row={row}
          selectedData={selectedData}
        />
      ))}
    </Table>
    <Legend>
      * Return calculations are relative to US$ rather than market convention.
    </Legend>
  </TableContainer>
);

export default memo(MobileVersion);
