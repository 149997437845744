import styled from 'styled-components';

import { ReactComponent as DefaultPercentageIcon } from 'assets/icons/percentage-icon.svg';
import from from 'styles/responsive';

export const Container = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.0544rem;
  ${from.tablet`
      font-size: 1rem;
      line-height: 1.205rem;  
  `}
`;

export const PercentageIcon = styled(DefaultPercentageIcon)`
  margin-right: 0.6931rem;
`;

export const Value = styled.span``;
