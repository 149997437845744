import type { FC } from 'react';

import PasswordResetForm from 'components/PasswordResetForm';
import PasswordResetRequestForm from 'components/PasswordResetRequestForm';
import PasswordResetRequestSuccess from 'components/PasswordResetRequestSuccess';
import UpdateAppOverlay from 'components/UpdateAppOverlay';
import { useUpdateApp } from 'containers/Services/UpdateAppService';

import useConnect from './connect';
import { IonContent, IonPage } from './styles';

const Guest: FC = () => {
  const updateApp = useUpdateApp();
  const { firstTime, emailSent, handle, isReset, loading } = useConnect();

  return (
    <IonPage>
      <IonContent>
        {updateApp?.isUpdateAppAvailable && <UpdateAppOverlay />}
        {(() => {
          if (isReset) {
            return (
              <PasswordResetForm
                firstTime={firstTime}
                onSubmit={handle.resetSubmit}
              />
            );
          }

          if (emailSent) {
            return <PasswordResetRequestSuccess />;
          }

          return (
            <PasswordResetRequestForm
              loading={loading}
              onSubmit={handle.requestSubmit}
            />
          );
        })()}
      </IonContent>
    </IonPage>
  );
};

export default Guest;
