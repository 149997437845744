import styled from 'styled-components';

import hexToRgb from 'utils/hexToRgb';

import type { $BarProps } from './types';

export const Bar = styled.rect.attrs<$BarProps>(
  ({ $isInstrumentTypeHover, $color, height, width }) => ({
    fill: $isInstrumentTypeHover ? `rgba(${hexToRgb($color)}, 0.5)` : $color,
    stroke: $isInstrumentTypeHover ? $color : 0,
    strokeWidth: $isInstrumentTypeHover ? 4 : 0,
    strokeDasharray: `${width} ${height}`,
  }),
)<$BarProps>``;
