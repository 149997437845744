import type { FC } from 'react';

import { Container, Content } from './styles';
import type { Props } from './types';

const AvatarButton: FC<Props> = ({ children, className, onClick }) => (
  <Container className={className} onClick={onClick}>
    <Content>{children}</Content>
  </Container>
);

export default AvatarButton;
