import { FC, memo } from 'react';

import {
  Button,
  Category,
  Chevron,
  Container,
  Info,
  Name,
  Subtitle,
  Title,
} from './styles';
import type { Props } from './types';

const PageDetailLayoutHeader: FC<Props> = ({
  goBackPathname,
  name,
  title,
  secondaryTitle,
  modalOpen = false,
}) => (
  <Container $withSubtitle={!!name} $modalOpen={modalOpen}>
    <Button
      color="secondary"
      variant="only-icon"
      size="small"
      withIcon={<Chevron />}
      href={goBackPathname}
    />
    <Info>
      <Category>
        <Title>{title}</Title>
        <Subtitle>{secondaryTitle}</Subtitle>
      </Category>
      {name && <Name>{name}</Name>}
    </Info>
  </Container>
);

export default memo(PageDetailLayoutHeader);
