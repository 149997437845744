/* eslint-disable @typescript-eslint/no-explicit-any */
import type { SelectChangeEventDetail } from '@ionic/core';
import { useCallback, useRef } from 'react';

/*
 * Ionic's IonSelect component has a flaw: whenever the value is updated, the
 * one or many onChange events are fired in consequence of that, which poses
 * some problems when persisting the state to the url.
 *
 * This workaround ensures that the events are separated by a reasonable humane
 * delay, so if a subsequent onChange event fires too quickly, we can dismiss
 * it as an unauthentic event.
 *
 * I really really hate Ionic :(
 */
export function useIsAuthenticEventHackyWorkaround() {
  const lastEventTimestampRef = useRef(0);

  return useCallback(
    (e: CustomEvent<SelectChangeEventDetail>) => {
      const isAuthentic = e.timeStamp - lastEventTimestampRef.current > 1000;

      if (isAuthentic) {
        lastEventTimestampRef.current = e.timeStamp;
      }

      return isAuthentic;
    },
    [lastEventTimestampRef],
  );
}

export function getSelectInterface(multiple: boolean, isMobile: boolean) {
  if (multiple) {
    return 'alert';
  }

  if (isMobile) {
    return 'action-sheet';
  }

  return 'popover';
}

export function getSelectedText(
  options: readonly Readonly<{ value: string; label: string }>[],
  selectedOptions: string[] | undefined,
  placeholder: string,
) {
  if (!selectedOptions || selectedOptions[0] === '') {
    return placeholder;
  }

  const allSelected = options.every((opt) =>
    selectedOptions?.includes(opt.value),
  );

  if (allSelected) {
    return placeholder;
  }

  const arrayNames = options
    .filter((i) => selectedOptions?.includes(i.value))
    .map((i) => i.label);
  const text = arrayNames.join(', ');

  return text !== '' ? text : placeholder;
}

export function toggleState(state: string | undefined | null) {
  return state !== 'true';
}

export function setCheckedValue(element: any, value: boolean) {
  setTimeout(() => {
    element.setAttribute('aria-checked', value ? 'true' : 'false');
  }, 100);
}
