import type { TickFormatter } from '@visx/axis';
import { FC, memo, useMemo } from 'react';
import useResizeObserver from 'use-resize-observer';

import from from 'styles/responsive';
import useMedia from 'utils/useMedia';

import Chart from './Chart';
import CursorBar from './CursorBar';
import {
  formatPercentage,
  useAccessors,
  useEdgeValues,
  useHeight,
  useMargins,
  useScales,
  useTooltip,
} from './logic';
import {
  AreaGradient,
  AxisBottom,
  AxisLeft,
  Container,
  GraphVector,
  Grid,
  LegendColor,
  LegendItem,
  LegendLabel,
  LegendText,
} from './styles';
import Tooltip from './Tooltip';
import type { Props } from './types';

const YieldSpreadChart: FC<Props> = ({
  className,
  data: readonlyData,
  isInteractive = true,
}) => {
  // visx wants mutable arrays even though it will never mutate them
  const data = useMemo(() => [...readonlyData], [readonlyData]);

  const isTablet = useMedia(from.tablet.query);
  const isLaptop = useMedia(from.laptop.query);

  const { ref, width = 0 } = useResizeObserver();
  const height = useHeight({ isLaptop, isTablet });

  const {
    bottomAxisHeight,
    bottomAxisMargin,
    leftAxisMargin,
    leftAxisWidth,
    rightMargin,
    topMargin,
  } = useMargins({ isTablet });
  const { end, maxValue, minValue, start } = useEdgeValues(data);
  const { xScale, yScale } = useScales({
    bottomAxisHeight,
    bottomAxisMargin,
    end,
    height,
    leftAxisMargin,
    leftAxisWidth,
    maxValue,
    minValue,
    rightMargin,
    start,
    topMargin,
    width,
  });
  const { x, y } = useAccessors({ xScale, yScale });
  const {
    anchorData,
    handleTooltipAnchored,
    handleTooltipClosed,
    handleTooltipUnanchored,
    handleTooltipUpdated,
    tooltipData,
    tooltipLeft,
    tooltipTop,
  } = useTooltip({
    data,
    leftAxisMargin,
    leftAxisWidth,
    xScale,
    yScale,
  });

  return (
    <Container ref={ref} className={className}>
      <GraphVector width="100%" viewBox={`0 0 ${width} ${height}`}>
        <AreaGradient />
        <Grid
          scale={yScale}
          width={width - leftAxisWidth - leftAxisMargin - rightMargin}
          left={leftAxisWidth + leftAxisMargin}
        />
        <AxisLeft
          left={leftAxisWidth}
          numTicks={isTablet ? 7 : 3}
          scale={yScale}
          tickFormat={formatPercentage as TickFormatter<unknown>}
        />
        <AxisBottom
          left={leftAxisWidth + leftAxisMargin}
          numTicks={8}
          scale={xScale}
          top={height - bottomAxisHeight}
        />
        <LegendText
          $x={25}
          $y={height / 2}
          dominantBaseline="central"
          textAnchor="middle"
        >
          Yield Historical (%)
        </LegendText>
        <Chart
          bottom={height - bottomAxisHeight - bottomAxisMargin}
          data={data}
          handleTooltipAnchored={handleTooltipAnchored}
          handleTooltipClosed={handleTooltipClosed}
          handleTooltipUnanchored={handleTooltipUnanchored}
          handleTooltipUpdated={handleTooltipUpdated}
          left={leftAxisWidth + leftAxisMargin}
          range={
            anchorData && tooltipData ? [anchorData, tooltipData] : undefined
          }
          right={width - rightMargin}
          top={topMargin}
          x={x}
          y={y}
          yScale={yScale}
        />
        {typeof tooltipLeft === 'number' &&
          typeof tooltipTop === 'number' &&
          isInteractive && (
            <CursorBar
              bottom={height - bottomAxisHeight - bottomAxisMargin}
              tooltipLeft={tooltipLeft}
              tooltipTop={tooltipTop}
              top={topMargin}
            />
          )}
      </GraphVector>
      {typeof tooltipData !== 'undefined' &&
        typeof tooltipLeft === 'number' &&
        typeof tooltipTop === 'number' &&
        isInteractive && (
          <Tooltip
            anchorData={anchorData}
            data={tooltipData}
            tooltipLeft={tooltipLeft}
            tooltipTop={tooltipTop}
          />
        )}
      <LegendItem>
        <LegendColor />
        <LegendLabel>
          Portfolio Yield Over The Adjusted Treasury (Spread)
        </LegendLabel>
      </LegendItem>
    </Container>
  );
};

export default memo(YieldSpreadChart);
