import styled, { css } from 'styled-components';

import { getChartKeyColor } from '../logic';
import type { ChartKey } from '../types';

export const Bar = styled.rect.attrs({
  fill: 'currentColor',
})<{ $key: ChartKey }>`
  ${({ $key, theme }) => css`
    color: ${getChartKeyColor($key, theme)};
  `}};
`;
