import { FC, memo } from 'react';

import Checkbox from 'components/Checkbox';
import RadioButton from 'components/RadioButton';
import Toggle from 'components/Toggle';

import { DefaultOptionItem, Label, LabelRadio } from './styles';
import type { Props } from './types';

const OptionItem: FC<Props> = ({
  checked = false,
  className,
  disabled,
  id,
  label,
  onChange,
  optionType,
  value,
}) => (
  <DefaultOptionItem className={className} onClick={onChange}>
    {optionType === 'radiobutton' && (
      <>
        <RadioButton disabled={disabled} id={id} value={value} />
        <LabelRadio>{label}</LabelRadio>
      </>
    )}
    {optionType === 'checkbox' && (
      <>
        <Label>{label}</Label>
        <Checkbox id={id} checked={checked} value={value} />
      </>
    )}
    {optionType === 'toggle' && (
      <>
        <Label>{label}</Label>
        <Toggle id={id} checked={checked} value={value} disabled={disabled} />
      </>
    )}
  </DefaultOptionItem>
);

export default memo(OptionItem);
