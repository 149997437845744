import { FC, memo, useMemo } from 'react';

import Amount from 'components/Amount';
import BaseFixedIncomeProfileTable from 'components/BaseFixedIncomeProfileTable';
import {
  Col,
  LastFirstCol,
  LastRow,
} from 'components/BaseFixedIncomeProfileTable/styles';

import type { Props } from './types';

const DetailFixedIncomeProfileTable: FC<Props> = ({
  data,
  period = 'monthly',
}) => {
  const rows = useMemo(
    () =>
      data.map((item) => ({
        id: item.id,
        name: item.name,
        value: item.income,
      })),
    [data],
  );

  const total = useMemo(
    () => data.reduce((acc, item) => item.income + acc, 0),
    [data],
  );

  return (
    <BaseFixedIncomeProfileTable
      headers={
        period === 'monthly'
          ? ['Month', 'Monthly Estimate']
          : ['Year', 'Yearly Estimate']
      }
      items={rows}
      lastRow={
        <LastRow>
          <LastFirstCol>Total</LastFirstCol>
          <Col>
            <Amount value={total} />
          </Col>
        </LastRow>
      }
    />
  );
};

export default memo(DetailFixedIncomeProfileTable);
