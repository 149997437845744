import useGetAssetClassBreakdownSummaryQuery, {
  GET_ASSET_CLASS_BREAKDOWN_SUMMARY_QUERY_KEY,
} from 'api/queries/home/getAssetClassBreakdownSummary';
import { useQuery } from 'api/storage';

export default function useAssetClassBreakdownSummary({
  portfolio,
  reportSlug,
  timeFrame,
}: {
  portfolio: string | undefined;
  reportSlug: string;
  timeFrame: string | undefined;
}) {
  const { data } = useQuery(
    GET_ASSET_CLASS_BREAKDOWN_SUMMARY_QUERY_KEY({
      portfolio: portfolio ?? '',
      reportSlug,
      timeFrame: timeFrame ?? '',
    }),
    {
      enabled: !!portfolio,
      queryFn: useGetAssetClassBreakdownSummaryQuery(),
    },
  );

  return {
    data,
  };
}
