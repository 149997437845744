import type { FC } from 'react';
import { QueryClientProvider } from 'react-query';

import MaintenanceModeProvider from 'containers/Services/MaintenanceModeService';
import NewsProvider from 'containers/Services/NewsService';
import RefreshTokenProvider from 'containers/Services/RefreshTokenService';
import ScreenOrientationProvider from 'containers/Services/ScreenOrientationService';
import UpdateAppProvider from 'containers/Services/UpdateAppService';
import VaultGate from 'containers/Services/VaultService';
import usePromise from 'utils/usePromise';

import createClient from './client';
import StorageGate from './storage';

const ApiProvider: FC = ({ children }) => {
  const client = usePromise(createClient);

  if (!client) {
    return null;
  }

  return (
    <QueryClientProvider client={client}>
      <ScreenOrientationProvider>
        <MaintenanceModeProvider>
          <UpdateAppProvider>
            <VaultGate>
              <RefreshTokenProvider>
                <NewsProvider>
                  <StorageGate>{children}</StorageGate>
                </NewsProvider>
              </RefreshTokenProvider>
            </VaultGate>
          </UpdateAppProvider>
        </MaintenanceModeProvider>
      </ScreenOrientationProvider>
    </QueryClientProvider>
  );
};

export default ApiProvider;
