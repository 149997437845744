import React, { createContext, FC, useContext, useState } from 'react';

import type { Props, State } from './types';

const initialState: State = {
  asset: '',
  setAsset: () => {},
};

const AssetContext = createContext<State>(initialState);

const AssetProvider: React.FC<Props> = (props) => {
  const [asset, setAsset] = useState('');

  return (
    <AssetContext.Provider
      value={{
        asset,
        setAsset,
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export const useAssetContext = () => {
  const context = useContext(AssetContext);
  if (context === undefined) {
    throw new Error('useAssetContext must be used within a Provider');
  }
  return context;
};

export const ManagedAssetContext: FC<Props> = ({ children }) => (
  <AssetProvider>{children}</AssetProvider>
);
