import useGetTermsAndConditionsQuery, {
  GET_TERMS_AND_CONDITIONS_QUERY_KEY,
} from 'api/queries/auth/getTermsAndConditions';
import { useQuery } from 'api/storage';

export default function useTermsAndConditions() {
  const { data } = useQuery(GET_TERMS_AND_CONDITIONS_QUERY_KEY(), {
    queryFn: useGetTermsAndConditionsQuery(),
  });

  return {
    data,
  };
}
