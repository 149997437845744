import styled from 'styled-components';

import from from 'styles/responsive';

export const Container = styled.div`
  display: grid;
  grid-template-rows: repeat(4, auto);
  ${from.tablet`
    grid-template-rows: repeat(3, auto);
    grid-template-columns: 10.4375rem 1fr;
    grid-column-gap: 1.5rem;
    padding: 3.375rem 2.5rem 0;
  `}
`;

export const DesktopContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: 10.4375rem 1fr;
  grid-column-gap: 1.5rem;
`;
