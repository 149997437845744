import { MarkerCircle as DefaultMarkerCircle } from '@visx/marker';
import { LinePath as DefaultLinePath } from '@visx/shape';
import styled from 'styled-components';

export const LinePath = styled(DefaultLinePath)<{
  $showBalanceArrow: boolean;
}>`
  pointer-events: all;
  stroke: ${({ theme, $showBalanceArrow }) =>
    $showBalanceArrow ? theme.colors.primary : 'transparent'};
`;

export const MarkerCircle = styled(DefaultMarkerCircle)`
  stroke: ${({ theme }) => theme.colors.primary};
`;

export const Bar = styled.rect``;
