import type { FC } from 'react';
import { memo } from 'react';

import { formatChartKey } from '../logic';
import type { ChartKey } from '../types';
import { Box, Circle, Container, Cross, Item, Label } from './styles';
import type { Props } from './types';

const Legend: FC<Props> = ({ keys }) => {
  const pictureToKey = (key: ChartKey) => {
    switch (key) {
      case 'current':
        return <Cross $key={key}>X</Cross>;
      case 'target':
        return <Circle $key={key} />;
      default:
      case 'range':
        return <Box $key={key} />;
    }
  };

  return (
    <Container>
      {keys.map((key) => (
        <Item key={key}>
          {pictureToKey(key)}
          <Label>{formatChartKey(key)}</Label>
        </Item>
      ))}
    </Container>
  );
};

export default memo(Legend);
