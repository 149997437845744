import styled from 'styled-components';

import from from 'styles/responsive';

import {
  Col as DefaultCol,
  Content as DefaultContent,
  FirstCol as DefaultFirstCol,
  Header as DefaultHeader,
  HeaderCell as DefaultHeaderCell,
  HeaderContainer as DefaultHeaderContainer,
  Row as DefaultRow,
  Table as DefaultTable,
} from '../styles';

export const FirstCol = styled(DefaultFirstCol)``;

export const Table = styled(DefaultTable)``;

export const Content = styled(DefaultContent)``;

export const HeaderContainer = styled(DefaultHeaderContainer)`
  display: none;
  ${from.tablet`
    padding: 1rem 2rem;
    display: grid;
    grid-template-columns: 1.5fr 0.1fr;
  `}
`;

export const Header = styled(DefaultHeader)`
  display: none;
  ${from.tablet`
    display: grid;
    grid-template-columns: 1.5fr 1fr;
  `}
`;

export const HeaderCell = styled(DefaultHeaderCell)``;

export const Row = styled(DefaultRow)`
  grid-template-columns: 1.5fr 0.7fr;
  ${from.tablet`
    grid-template-columns: 1.5fr 1fr;
  `}
`;

export const Col = styled(DefaultCol)`
  & > span:not(:first-child) {
    margin-top: 0.25rem;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    opacity: 0.75;
  }
`;
