import useGetAssetAllocationHistoryChartDataQuery, {
  GET_ASSET_ALLOCATION_HISTORY_CHART_DATA_QUERY_KEY,
} from 'api/queries/charts/getAssetAllocationHistoryChartData';
import { useQuery } from 'api/storage';

export default function useAssetAllocationHistoryChartData(
  portfolio: string | undefined,
) {
  const { data } = useQuery(
    GET_ASSET_ALLOCATION_HISTORY_CHART_DATA_QUERY_KEY(portfolio ?? ''),
    {
      enabled: !!portfolio,
      queryFn: useGetAssetAllocationHistoryChartDataQuery(),
    },
  );

  return {
    data,
  };
}
