/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import useResizeObserver from 'use-resize-observer';

import AssetAllocationTargetAndRangesChart from 'components/AssetAllocationTargetAndRangesChart';
import AssetAllocationTargetAndRangesTable from 'components/AssetAllocationTargetAndRangesTable';
import DailyVolatilityChart from 'components/DailyVolatilityChart';
import RollingAllocationTargetsChart from 'components/RollingAllocationTargetsChart';
import RollingVolatilityChart from 'components/RollingVolatilityChart';
import StatisticalAnalysis from 'components/StatisticalAnalysis';
import { useNews } from 'containers/Services/NewsService';
import from from 'styles/responsive';
import kebabCaseCustom from 'utils/kebabCaseCustom';
import useMedia from 'utils/useMedia';

import useConnect from './connect';
import {
  getLastMonth,
  useFiltering,
  useRollingAllocationTargets,
} from './logic';
import {
  ActionZone,
  AssetAllocationTargetAndRangesContainer,
  ChartTitle,
  Container,
  ContainerTabs,
  ContentGrid,
  FilterButton,
  LoaderContainer,
  Spinner,
  Tabs,
  Toolbar,
} from './styles';

const tabs = [
  {
    value: '30-day-rolling-vol',
    label: '30 Day Rolling Vol.',
  },
  {
    value: 'dayly-returns',
    label: 'Daily Returns',
  },
];

const tabsTargetAndRange = [
  {
    value: 'target-and-range-chart',
    label: 'Chart',
  },
  {
    value: 'target-and-range-table',
    label: 'Table',
  },
];

const RiskAnalysis: FC = () => {
  const [activeTab, setActiveTab] = useState('30-day-rolling-vol');
  const [activeTabTargetAndRange, setActiveTabTargetAndRange] = useState(
    'target-and-range-chart',
  );
  const isTablet = useMedia(from.tablet.query);
  const { replace } = useHistory();
  const newsServices = useNews();

  const { ref, width = 0 } = useResizeObserver();

  const {
    dailyVolatilityChartData,
    rollingVolatilityChartData,
    assetAllocationTargetAndRangesChartData,
    statisticalAnalysis,
  } = useConnect();

  const {
    rollingAllocationTargets,
    handleInstrumentTypeChange,
    handleBackToTargetAndRanges,
    handleInstrumentTypeFilter,
  } = useRollingAllocationTargets(assetAllocationTargetAndRangesChartData);

  const { filterOptions, currentFiltering, onChangeFiltering } = useFiltering(
    assetAllocationTargetAndRangesChartData,
  );

  useEffect(() => {
    if (newsServices?.notificationToNews) {
      replace('/news');
    }
  }, [newsServices?.notificationToNews]);

  useEffect(() => {
    if (rollingAllocationTargets === undefined) {
      onChangeFiltering('default');
    } else {
      onChangeFiltering(
        kebabCaseCustom(
          rollingAllocationTargets[0]?.instrumentType ?? 'default',
        ),
      );
    }
  }, [rollingAllocationTargets]);

  useEffect(() => {
    handleInstrumentTypeFilter(currentFiltering);
  }, [currentFiltering]);

  const assetAllocationTargetAndRanges = () => {
    if (assetAllocationTargetAndRangesChartData !== undefined) {
      if (activeTabTargetAndRange === 'target-and-range-chart') {
        return (
          <AssetAllocationTargetAndRangesChart
            data={getLastMonth(assetAllocationTargetAndRangesChartData)}
            handleInstrumentTypeChange={handleInstrumentTypeChange}
            width={width}
          />
        );
      }

      return (
        <AssetAllocationTargetAndRangesTable
          data={getLastMonth(assetAllocationTargetAndRangesChartData)}
        />
      );
    }

    return <></>;
  };

  const rollingAllocationTargetsChart = () => {
    if (rollingAllocationTargets !== undefined) {
      return (
        <RollingAllocationTargetsChart
          data={rollingAllocationTargets}
          handleBackToTargetAndRanges={handleBackToTargetAndRanges}
          width={width}
        />
      );
    }

    return <></>;
  };

  return (
    <>
      {(!dailyVolatilityChartData ||
        !rollingVolatilityChartData ||
        !assetAllocationTargetAndRangesChartData) && (
        <LoaderContainer>
          <Spinner />
        </LoaderContainer>
      )}
      <Container
        $loading={
          !dailyVolatilityChartData ||
          !rollingVolatilityChartData ||
          !assetAllocationTargetAndRangesChartData
        }
        $mobile={!isTablet}
      >
        <ContentGrid ref={ref}>
          <StatisticalAnalysis data={statisticalAnalysis} />
          {assetAllocationTargetAndRangesChartData !== undefined && (
            <AssetAllocationTargetAndRangesContainer>
              <Toolbar>
                <ChartTitle>
                  {rollingAllocationTargets === undefined
                    ? 'Asset Allocation: Targets & Ranges'
                    : 'Rolling Allocation Targets'}
                </ChartTitle>
                {isTablet && (
                  <ActionZone>
                    <FilterButton
                      onChange={onChangeFiltering}
                      options={filterOptions}
                      placeholder={
                        currentFiltering === undefined
                          ? 'Targets & Ranges'
                          : currentFiltering
                      }
                      value={currentFiltering}
                    />
                    {rollingAllocationTargets === undefined && (
                      <Tabs
                        value={activeTabTargetAndRange}
                        tabs={tabsTargetAndRange}
                        onChange={setActiveTabTargetAndRange}
                        variant="bg-tabs"
                      />
                    )}
                  </ActionZone>
                )}
              </Toolbar>
              {rollingAllocationTargets === undefined
                ? assetAllocationTargetAndRanges()
                : rollingAllocationTargetsChart()}
            </AssetAllocationTargetAndRangesContainer>
          )}
          <ContainerTabs>
            <Toolbar>
              {(() => {
                switch (activeTab) {
                  case '30-day-rolling-vol':
                  default:
                    return (
                      <ChartTitle>
                        30 Day Rolling Volatility
                        <span>Since Inception (annualized)</span>
                      </ChartTitle>
                    );
                  case 'dayly-returns':
                    return (
                      <ChartTitle>
                        Daily Returns <span>Since Inception (histogram)</span>
                      </ChartTitle>
                    );
                }
              })()}
              <Tabs
                value={activeTab}
                tabs={tabs}
                onChange={setActiveTab}
                variant="bg-tabs"
              />
            </Toolbar>
            {(() => {
              switch (activeTab) {
                case '30-day-rolling-vol':
                default:
                  return (
                    <RollingVolatilityChart
                      data={rollingVolatilityChartData ?? []}
                      width={width}
                    />
                  );
                case 'dayly-returns':
                  return (
                    <DailyVolatilityChart
                      data={dailyVolatilityChartData ?? []}
                      width={width}
                    />
                  );
              }
            })()}
          </ContainerTabs>
        </ContentGrid>
      </Container>
    </>
  );
};

export default RiskAnalysis;
