import { Form as DefaultForm } from 'formik';
import styled from 'styled-components';

import DefaultButton from 'components/Button';
import HeaderProfile from 'containers/Common/HeaderProfile';
import PageDetailLayout from 'containers/Common/PageDetailLayout';
import { DisclosuresLink } from 'containers/Common/PageDetailLayout/styles';
import from from 'styles/responsive';

export const Container = styled(PageDetailLayout)`
  ${from.tablet`
    padding: 56px 40px;
  `}
  ${from.laptop`
    padding: 54px 64px;
  `}
  ${DisclosuresLink} {
    display: none;
  }
`;

export const Grid = styled.div`
  padding: 2rem 1.25rem;
  max-width: 61.25rem;
  ${from.tablet`
    padding: 0;
    display: grid;
    grid-template-columns: 15.9375rem auto;
    grid-column-gap: 5.75rem;
    grid-row-gap: 3.5rem;
  `}
  ${from.laptop`
    grid-template-columns: 21rem auto;
  `}
`;

export const Header = styled(HeaderProfile)`
  margin-bottom: 32px;
  ${from.tablet`
    grid-column: span 2;
    margin-bottom: 0;
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  ${from.tablet`
    display: block;
  `}
`;

export const Title = styled.h3`
  margin: 0 0 0.5rem;
  font-size: 1.125rem;
  line-height: 1.5625rem;
`;

export const Description = styled.p`
  margin: 0 0 2rem;
  font-size: 0.875rem;
  line-height: 1.4063rem;
  ${from.tablet`
    margin-bottom: 3rem;
    font-size: 1rem;
    line-height: 1.1875rem;
  `}
`;

export const Form = styled(DefaultForm)`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Inputs = styled.div`
  > :not(:last-child) {
    margin-bottom: 1rem;
  }
  ${from.tablet`
    margin-bottom: 2.5rem;
    > :not(:last-child) {
      margin-bottom: 1.5rem;
    }
  `}
`;

export const Button = styled(DefaultButton)`
  width: 100%;
  min-width: 0;
  margin: auto 0 1rem;
`;
