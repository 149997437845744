import {
  IonContent,
  IonIcon,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
} from '@ionic/react';
import { FC, useState } from 'react';
import { Switch } from 'react-router';

import allocations from 'assets/icons/allocations-menu-icon.svg';
import home from 'assets/icons/home-menu-icon.svg';
import markets from 'assets/icons/markets-menu-icon.svg';
import news from 'assets/icons/news-menu-icon.svg';
import riskAnalysis from 'assets/icons/risk-analysis-menu-icon.svg';
import NoticeActivateNotifications from 'components/NoticeActivateNotifications';
import RefreshTokenOverlay from 'components/RefreshTokenOverlay';
import Spinner from 'components/Spinner';
import { useNews } from 'containers/Services/NewsService';
import { useRefreshToken } from 'containers/Services/RefreshTokenService';
import { useUpdateApp } from 'containers/Services/UpdateAppService';
import from from 'styles/responsive';
import { getFullPortfolioNameReadable } from 'utils/getFullPortfolioName';
import useMedia from 'utils/useMedia';
import usePlatform from 'utils/usePlatform/usePlatform';

import { useNotificationsControls } from '../Profile/ProfileNotifications/logic';
import useConnect from './connect';
import {
  DesktopContent,
  Dot,
  IconContainer,
  Label,
  LoaderContainer,
  Sidebar,
  Tabs,
} from './styles';

const maxDate = '32503593600000';

const AppNavigation: FC = ({ children }) => {
  const [
    showNoticeToActivateNotifications,
    setShowNoticeToActivateNotifications,
  ] = useState<boolean>(
    new Date(
      +(
        localStorage.getItem('notice_to_activate_notifications_prod') || maxDate
      ),
    ).getTime() < new Date().getTime(),
  );
  const updateApp = useUpdateApp();
  const newsServices = useNews();

  const { hasActivateAnyNotifications } = useNotificationsControls();

  const { isWeb } = usePlatform();
  const { openPortfolioSwitcher, selectedClient, selectedPortfolio, pathname } =
    useConnect();

  const closeNotice = () => {
    localStorage.setItem('notice_to_activate_notifications_prod', `${maxDate}`);
    setShowNoticeToActivateNotifications(false);
  };

  const isMobile = !useMedia(from.tablet.query);
  const refreshTokenContext = useRefreshToken();

  if (
    refreshTokenContext?.isLoadingRequestToken &&
    !refreshTokenContext?.isRefreshingToken &&
    isWeb
  ) {
    return (
      <IonContent id="main_content" forceOverscroll={false}>
        <LoaderContainer>
          <Spinner />
        </LoaderContainer>
      </IonContent>
    );
  }

  return (
    <IonContent id="main_content" forceOverscroll={false}>
      {refreshTokenContext?.isRefreshingToken && (
        <RefreshTokenOverlay
          isMobile={isMobile}
          couldRefreshToken={refreshTokenContext?.couldResfreshToken}
        />
      )}
      {!isWeb &&
        showNoticeToActivateNotifications &&
        hasActivateAnyNotifications !== undefined &&
        !hasActivateAnyNotifications && (
          <NoticeActivateNotifications closeNotice={closeNotice} />
        )}
      {isMobile ? (
        <>
          <Tabs
            $expiredSession={refreshTokenContext?.isRefreshingToken ?? false}
          >
            <IonRouterOutlet animated={false}>
              <Switch>{children}</Switch>
            </IonRouterOutlet>
            <IonTabBar onClick={updateApp?.availableUpdate} slot="bottom">
              <IonTabButton tab="home" href="/home">
                <IonIcon icon={home} />
                <Label>Home</Label>
              </IonTabButton>
              <IonTabButton tab="allocations" href="/allocations">
                <IonIcon icon={allocations} />
                <Label>Allocations</Label>
              </IonTabButton>
              <IonTabButton tab="risk-analysis" href="/risk">
                <IonIcon icon={riskAnalysis} />
                <Label>Risk</Label>
              </IonTabButton>
              <IonTabButton tab="markets" href="/markets">
                <IonIcon icon={markets} />
                <Label>Markets</Label>
              </IonTabButton>
              <IonTabButton tab="news" href="/news">
                <IconContainer>
                  {newsServices?.hasNewsUnread && <Dot />}
                  <IonIcon icon={news} />
                </IconContainer>
                <Label>News</Label>
              </IonTabButton>
            </IonTabBar>
          </Tabs>
        </>
      ) : (
        <>
          {selectedClient && selectedPortfolio && (
            <Sidebar
              $expiredSession={refreshTokenContext?.isRefreshingToken ?? false}
              initials={selectedClient.initials}
              lastUpdated={selectedPortfolio.lastUpdated}
              location={pathname}
              name={getFullPortfolioNameReadable({
                clientName: selectedClient.name,
                portfolioName: selectedPortfolio.name,
              })}
              onAvatarClick={openPortfolioSwitcher}
            />
          )}
          <DesktopContent
            $expiredSession={refreshTokenContext?.isRefreshingToken ?? false}
          >
            <Switch>{children}</Switch>
          </DesktopContent>
        </>
      )}
    </IonContent>
  );
};

export default AppNavigation;
