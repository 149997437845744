import { useCallback } from 'react';
import type { QueryFunction } from 'react-query';

import useRequest from 'api/request';
import {
  MarketWatchlistAsset,
  normalizeMarketWatchlistAsset,
} from 'model/MarketWatchlistAsset';
import withoutNulls from 'utils/withoutNulls';

import type {
  GetMarketWatchlistRequest,
  GetMarketWatchlistResponse,
} from './types';

export const GET_MARKET_WATCHLIST_QUERY_KEY = () => [
  'markets/getMarketWatchlist',
];

export default function useGetMarketWatchlistQuery(): QueryFunction<
  MarketWatchlistAsset[]
> {
  const request = useRequest<
    GetMarketWatchlistRequest,
    GetMarketWatchlistResponse
  >();

  return useCallback(async () => {
    const response = await request(
      '/api/CVData/getClientMarketSubscriptionData',
      {},
    );

    const data = await response.json();

    if (!data.isSuccess) {
      throw new Error(data.message ?? '');
    }

    return withoutNulls(data.data ?? []).map(normalizeMarketWatchlistAsset);
  }, [request]);
}
