import useAssetAllocationTargetAndRangesChartData from 'api/hooks/charts/useAssetAllocationTargetAndRanges';
import useSelectedClient from 'api/hooks/clients/useSelectedClient';
import useDailyVolatilityChartData from 'api/hooks/riskAnalysis/useDailyVolatilityChartData';
import useRollingVolatilityChartData from 'api/hooks/riskAnalysis/useRollingVolatilityChartData';
import useStatisticalAnalysis from 'api/hooks/riskAnalysis/useStatisticalAnalysis';

export default function useConnect() {
  const { selectedClient, selectedPortfolio } = useSelectedClient();

  const { data: statisticalAnalysis } = useStatisticalAnalysis({
    client: selectedClient?.id,
    portfolioName: selectedPortfolio?.name,
  });

  const { data: dailyVolatilityChartData } = useDailyVolatilityChartData(
    selectedPortfolio?.id,
  );

  const { data: rollingVolatilityChartData } = useRollingVolatilityChartData({
    client: selectedClient?.id,
    portfolioName: selectedPortfolio?.name,
  });

  const { data: assetAllocationTargetAndRangesChartData } =
    useAssetAllocationTargetAndRangesChartData(selectedPortfolio?.id);

  return {
    dailyVolatilityChartData,
    rollingVolatilityChartData,
    assetAllocationTargetAndRangesChartData,
    statisticalAnalysis,
  };
}
