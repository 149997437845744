import { useField } from 'formik';
import { FC, memo, useCallback } from 'react';

import { Container, Item } from '../styles';
import type { Props } from '../types';

const SortMultipleItemsGroup: FC<Props> = ({ className, options, name }) => {
  const [
    { value = options.map((item) => item.value).join(',') },
    ,
    { setValue },
  ] = useField<string>(name);

  const onChange = useCallback(
    (filterValue: string) => {
      let filterArray = value?.split(',');

      if (!filterArray?.includes(filterValue)) {
        filterArray?.push(filterValue);
      } else {
        filterArray = filterArray.filter((item) => item !== filterValue);
      }

      if (filterValue === 'default') {
        if (filterArray?.includes(filterValue)) {
          filterArray = options.map((item) => item.value);
        } else {
          filterArray = [];
        }
      } else {
        filterArray = filterArray?.filter((item) => item !== 'default');
      }

      setValue(filterArray?.join(',') ?? '');
    },
    [options, setValue, value],
  );
  return (
    <Container className={className}>
      {options.map((item) => (
        <Item
          key={item.value}
          $checked={value?.split(',').includes(item.value) ?? false}
          onClick={() => onChange(item.value)}
          variant="link"
          name="value"
        >
          {item.label}
        </Item>
      ))}
    </Container>
  );
};
export default memo(SortMultipleItemsGroup);
