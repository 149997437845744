import { groupBy } from 'lodash';
import uniq from 'lodash/uniq';
import { useMemo } from 'react';
import { useLocation } from 'react-router';

import type { Asset } from 'api/hooks/allocations/useAllocationAssets';
import useAllocationAssets from 'api/hooks/allocations/useAllocationAssets';
import { ALL_RATINGS } from 'utils/fixedIncome';
import useEntityFiltering from 'utils/useDataManipulation/useEntityFiltering';
import type { FilterOption } from 'utils/useDataManipulation/useEntityFiltering';
import useEntitySorting, {
  SortOption,
} from 'utils/useDataManipulation/useEntitySorting';

import type { useCurrentGrouping } from '../CategoryDetail/logic';
import type { Position } from '../PrivateInvestmentsSummary/Controls/types';

type AssetFilterOption = FilterOption<Asset>;
type AssetSortOption = SortOption<Asset>;

export function useAssets({
  categorySlug,
  compare,
  multipleSubtypesOptions,
  multipleSponsorsOptions,
  grouping,
  portfolio,
  url,
}: {
  categorySlug: string;
  compare: AssetSortOption['compare'];
  multipleSubtypesOptions?: AssetFilterOption[];
  multipleSponsorsOptions?: AssetFilterOption[];
  grouping: ReturnType<typeof useCurrentGrouping>;
  portfolio: string | undefined;
  url: string;
}) {
  const searchParams = useLocation().search;
  const { data } = useAllocationAssets({
    categorySlug,
    grouping,
    portfolio,
  });

  const sorted = useMemo(
    () => (compare ? [...data].sort(compare) : data),
    [compare, data],
  );

  const valueSubtypesOptions =
    multipleSubtypesOptions && multipleSubtypesOptions.map((f) => f.label);

  const filteredBySubtypes = useMemo(
    () =>
      valueSubtypesOptions &&
      valueSubtypesOptions?.length > 0 &&
      !valueSubtypesOptions.includes('All')
        ? [...sorted].filter((d) => valueSubtypesOptions.includes(d.subtype))
        : sorted,
    [valueSubtypesOptions, sorted],
  );

  const valueSponsorsOptions =
    multipleSponsorsOptions && multipleSponsorsOptions.map((f) => f.label);

  const filteredBySponsors = useMemo(
    () =>
      valueSponsorsOptions &&
      valueSponsorsOptions?.length > 0 &&
      !valueSponsorsOptions.includes('All')
        ? [...filteredBySubtypes].filter((d) =>
            valueSponsorsOptions.includes(d.sponsor),
          )
        : filteredBySubtypes,
    [valueSponsorsOptions, filteredBySubtypes],
  );

  const assets = useMemo(
    () =>
      filteredBySponsors.map((asset) => ({
        ...asset,
        pathname: `${url}/assets/${asset.slug}${searchParams}`,
      })),
    [filteredBySponsors, url, searchParams],
  );

  const allocation = useMemo(
    () => assets.reduce((acc, asset) => acc + asset.allocation, 0),
    [assets],
  );

  const balance = useMemo(
    () => assets.reduce((acc, asset) => acc + asset.value, 0),
    [assets],
  );

  const totalPandL = useMemo(
    () => assets.reduce((acc, asset) => acc + asset.totalProfitAndLoss, 0),
    [assets],
  );

  const allocationChartData = useMemo(() => {
    const grouped = groupBy(data, (asset) => asset.subtype);
    const subTypes = Object.entries(grouped).map(([subType, assetsAux]) => {
      const { value } = assetsAux.reduce(
        ({ value: currentValue }, asset) => ({
          value: currentValue + asset.value,
        }),
        {
          value: 0,
        },
      );

      return {
        id: subType,
        name: subType,
        value,
      };
    });

    const sortedSubTypes = [...subTypes]
      .sort((a, b) => b.value - a.value)
      .map(({ value, ...asset }) => ({
        ...asset,
        totalAllocation: value / balance,
        totalValue: value,
      }));

    const relevant = sortedSubTypes.slice(0, 14);
    const others = sortedSubTypes.slice(14);

    return [
      ...relevant,
      ...(others.length >= 2
        ? [
            others.reduce(
              (acc, group) => ({
                ...acc,
                totalAllocation: acc.totalAllocation + group.totalAllocation,
                totalValue: acc.totalValue + group.totalValue,
              }),
              {
                id: 'other',
                name: 'Other',
                totalAllocation: 0,
                totalValue: 0,
              },
            ),
          ]
        : others),
    ];
  }, [balance, data]);

  return {
    allocation,
    allocationChartData,
    assets,
    balance,
    totalPandL,
  };
}

export function useFilteringSubtypes({
  categorySlug,
  portfolio,
  position = 'open',
}: {
  categorySlug: string;
  portfolio: string | undefined;
  position?: Position;
}) {
  const { data } = useAllocationAssets({
    categorySlug,
    grouping: undefined,
    portfolio,
  });

  const assets = useMemo(
    () =>
      data.filter(
        (a) =>
          position === 'all' ||
          (position === 'open' && !a.closedPosition) ||
          (position === 'closed' && a.closedPosition),
      ),
    [data, position],
  );

  const subtypes = useMemo(
    () =>
      uniq(
        assets?.map((asset) => asset.subtype).filter((it) => !!it) ?? [],
      ).sort((a, b) => a.localeCompare(b)),
    [assets],
  );

  const filterSubtypesOptions: readonly [
    AssetFilterOption,
    ...AssetFilterOption[]
  ] = useMemo(
    () => [
      {
        filter: () => true,
        label: 'All',
        value: 'default',
      },
      ...subtypes.map(
        (subtype): AssetFilterOption => ({
          filter: (asset) => asset.subtype === subtype,
          label: subtype,
          value: subtype,
        }),
      ),
    ],
    [subtypes],
  );

  return useEntityFiltering({
    filterKey: 'subtypes',
    filterOptions: filterSubtypesOptions,
  });
}

export function useFilteringSponsors({
  categorySlug,
  portfolio,
  position = 'open',
}: {
  categorySlug: string;
  portfolio: string | undefined;
  position?: Position;
}) {
  const { data } = useAllocationAssets({
    categorySlug,
    grouping: undefined,
    portfolio,
  });

  const assets = useMemo(
    () =>
      data.filter(
        (a) =>
          position === 'all' ||
          (position === 'open' && !a.closedPosition) ||
          (position === 'closed' && a.closedPosition),
      ),
    [data, position],
  );

  const sponsors = useMemo(
    () =>
      uniq(
        assets?.map((asset) => asset.sponsor).filter((it) => !!it) ?? [],
      ).sort((a, b) => a.localeCompare(b)),
    [assets],
  );

  const filterSponsorsOptions: readonly [
    AssetFilterOption,
    ...AssetFilterOption[]
  ] = useMemo(
    () => [
      {
        filter: () => true,
        label: 'All',
        value: 'default',
      },
      ...sponsors.map(
        (sponsor): AssetFilterOption => ({
          filter: (asset) => asset.sponsor === sponsor,
          label: sponsor,
          value: sponsor,
        }),
      ),
    ],
    [sponsors],
  );

  return useEntityFiltering({
    filterKey: 'sponsors',
    filterOptions: filterSponsorsOptions,
  });
}

export function useSorting() {
  const sortOptions: readonly [AssetSortOption, ...AssetSortOption[]] = useMemo(
    () => [
      {
        compare: (a, b) => b.value - a.value,
        label: 'Total: High to Low',
        value: 'default',
      },
      {
        compare: (a, b) => a.value - b.value,
        label: 'Total: Low to High',
        value: 'totalASC',
      },
      {
        compare: (a, b) => b.ytw - a.ytw,
        label: 'YTW: High to Low',
        value: 'ytwDESC',
      },
      {
        compare: (a, b) => a.ytw - b.ytw,
        label: 'YTW: Low to High',
        value: 'ytwASC',
      },
      {
        compare: (a, b) => b.ttc - a.ttc,
        label: 'TTC: High to Low',
        value: 'ttcDESC',
      },
      {
        compare: (a, b) => a.ttc - b.ttc,
        label: 'TTC: Low to High',
        value: 'ttcASC',
      },
      {
        compare: (a, b) => b.duration - a.duration,
        label: 'Duration: High to Low',
        value: 'durationDESC',
      },
      {
        compare: (a, b) => a.duration - b.duration,
        label: 'Duration: Low to High',
        value: 'durationASC',
      },
      {
        compare: (a, b) => a.name.localeCompare(b.name),
        label: 'Alphabetical: A to Z',
        value: 'alphabetical',
      },
      {
        compare: (a, b) => b.name.localeCompare(a.name),
        label: 'Alphabetical: Z to A',
        value: 'alphabeticalR',
      },
      {
        compare: (a, b) =>
          ALL_RATINGS.indexOf(a.rating) - ALL_RATINGS.indexOf(b.rating),
        label: 'Rating: High to Low',
        value: 'ratingDESC',
      },
      {
        compare: (a, b) =>
          ALL_RATINGS.indexOf(b.rating) - ALL_RATINGS.indexOf(a.rating),
        label: 'Rating: Low to High',
        value: 'ratingASC',
      },
    ],
    [],
  );
  return useEntitySorting({ sortOptions });
}
