import styled from 'styled-components';

export const ContainerOverlay = styled.div`
  position: fixed;
  height: 100%;
  width: 125%;
  top: 0;
  left: -15rem;
  right: 0px;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.32);
`;
