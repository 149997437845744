import type { FC } from 'react';

import BaseTotalAllocationTable from 'components/BaseTotalAllocationTable';
import { Col, FirstCol } from 'components/BaseTotalAllocationTable/styles';
import { formatYear } from 'utils/dates';
import { formatPercentage } from 'utils/percentages';

import type { Props } from './types';

const MaturityProfileSummaryTable: FC<Props> = ({ data }) => {
  const rows = data.map((item) => ({
    id: item.id,
    firstCol: <FirstCol key={item.id}>{formatYear(item.date)}</FirstCol>,
    secondCol: (
      <Col key={`${item.id}-allocation-${item.allocation}`}>
        {formatPercentage(item.allocation)}
      </Col>
    ),
    thirdCol: (
      <Col key={`${item.id}-monthly-cumulative-${item.cumulativeAllocation}`}>
        {formatPercentage(item.cumulativeAllocation)}
      </Col>
    ),
  }));
  return (
    <BaseTotalAllocationTable
      headers={['Year', 'Allocation', 'Monthly Cumulative']}
      rows={rows}
    />
  );
};

export default MaturityProfileSummaryTable;
