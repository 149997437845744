import type { GetProfitLossChartDataResponse } from 'api/queries/charts/getProfitLossChartData/types';
import { parseBackendDate } from 'utils/dates';
import type { WithoutNulls } from 'utils/withoutNulls';

type Input = NonNullable<
  NonNullable<
    NonNullable<GetProfitLossChartDataResponse['data']>[number]['subPnlCharts']
  >[number]['pnlChartData']
>[number];

export const normalizeProfitLossDatum = (input: WithoutNulls<Input>) =>
  ({
    id: `${input.id}`,
    date: input.dDate ? parseBackendDate(input.dDate).getTime() : 0,
    value: input.clientPnL ?? 0,
  } as const);

export type ProfitLossDatum = ReturnType<typeof normalizeProfitLossDatum>;
