import type { Dispatch, SetStateAction } from 'react';

export interface MaintenanceModeContextModel {
  isMaintenanceMode: boolean;
  setMaintenanceMode: Dispatch<SetStateAction<boolean>>;
}

export enum MaintenanceModeStatus {
  RUNNING = 'Server is running',
  DOWN = 'Server is down',
}
