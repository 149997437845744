import styled from 'styled-components';

export const ButtonTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  span {
    display: inline-block;
    margin: 0 0.375rem 0 auto;
  }
`;
