import { curveMonotoneX } from '@visx/curve';
import { Group } from '@visx/group';
import { Bar } from '@visx/shape';
import compose from 'lodash/fp/compose';
import { memo, useMemo } from 'react';
import type { FC } from 'react';

import { AreaClosed, Path } from './styles';
import type { Props } from './types';

const Chart: FC<Props> = ({
  bottom,
  data,
  handleTooltipAnchored,
  handleTooltipClosed,
  handleTooltipUnanchored,
  handleTooltipUpdated,
  left,
  range,
  right,
  top,
  x,
  y,
  yScale,
}) => {
  const [rangeStart, rangeEnd] = useMemo(
    () => (range ? range.sort((a, b) => a.date - b.date) : []),
    [range],
  );

  const filteredData = useMemo(() => {
    if (typeof rangeStart === 'undefined' || typeof rangeEnd === 'undefined') {
      return data;
    }

    return data.filter(
      (datum) => !(datum.date < rangeStart.date || datum.date > rangeEnd.date),
    );
  }, [data, rangeEnd, rangeStart]);

  const handleBothTooltipsClosed = useMemo(
    () => compose(handleTooltipClosed, handleTooltipUnanchored),
    [handleTooltipClosed, handleTooltipUnanchored],
  );

  return (
    <Group left={left}>
      <AreaClosed
        $isRange={
          typeof rangeStart !== 'undefined' && typeof rangeEnd !== 'undefined'
        }
        curve={curveMonotoneX}
        data={filteredData}
        x={x}
        y={y}
        yScale={yScale}
      />
      <Path curve={curveMonotoneX} data={data} x={x} y={y} />
      <Bar
        fill="transparent"
        height={bottom - top}
        onMouseDown={handleTooltipAnchored}
        onMouseLeave={handleBothTooltipsClosed}
        onMouseMove={handleTooltipUpdated}
        onMouseUp={handleTooltipUnanchored}
        onTouchMove={handleTooltipUpdated}
        onTouchStart={handleTooltipUpdated}
        width={Math.max(right - left, 0)}
        x={0}
        y={top}
      />
    </Group>
  );
};

export default memo(Chart);
