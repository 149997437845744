import styled, { css } from 'styled-components';

import Tooltip from 'components/Tooltip';
import type { Theme } from 'styles/themes';

import type { ItemProps } from './types';

export const BarTooltip = styled(Tooltip)`
  opacity: 0;
  transition: opacity 200ms ease;
  top: -840%;
  pointer-events: none;
  right: 50%;
  transform: translateX(50%);
  min-width: 176px;
`;

export const Container = styled.div<{
  $width: ItemProps['$width'];
  $color: keyof Theme['colors'];
}>`
  margin: 0 1px;
  position: relative;
  height: 0.75rem;
  width: 100%;
  cursor: pointer;

  &:hover {
    ${BarTooltip} {
      opacity: 1;
    }
  }

  &:first-child {
    margin-left: 0;
    border-radius: 0.25rem 0rem 0rem 0.25rem;
  }
  &:last-child {
    margin-right: 0;
    border-radius: 0rem 0.25rem 0.25rem 0rem;
  }
  ${({ $width }) =>
    $width &&
    css`
      width: ${$width};
    `}
  ${({ theme, $color }) =>
    $color &&
    css`
      background-color: ${theme.colors[$color]};
    `}
`;

export const ToolTipTitle = styled.p`
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.0544rem;
  color: ${({ theme }) => theme.colors.white};
`;

export const ToolTipAssets = styled.p`
  margin: 0;
  margin-bottom: 0.75rem;
  font-size: 0.75rem;
  line-height: 0.9038rem;
  color: ${({ theme }) => theme.colors.white};
`;

export const Table = styled.div`
  display: grid;
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.75rem;
  line-height: 0.875rem;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0.5rem;
  align-items: center;
  margin-bottom: 0.25rem;
`;

export const ColTitle = styled.div``;

export const Col = styled.div`
  text-align: right;
  font-weight: ${({ theme }) => theme.weights.bold};
`;
