import { FC, memo } from 'react';

import Amount from 'components/Amount';

import { Col, FirstCol, Header, HeaderCell, Row, Table } from './styles';
import type { Props } from './types';

const BaseFixedIncomeProfileTable: FC<Props> = ({
  headers,
  items,
  lastRow,
}) => (
  <Table>
    <Header>
      {headers.map((header) => (
        <HeaderCell key={header}>{header}</HeaderCell>
      ))}
    </Header>
    {items.map((row) => (
      <Row key={row.id}>
        <FirstCol>{row.name}</FirstCol>
        <Col>
          <Amount value={row.value} />
        </Col>
      </Row>
    ))}
    {lastRow || null}
  </Table>
);

export default memo(BaseFixedIncomeProfileTable);
