import { FC, memo } from 'react';

import { formatAmount } from 'utils/amounts';
import { formatPercentage } from 'utils/percentages';

import {
  ChangePercentage,
  Col,
  ColouredAmount,
  FirstCol,
  Header,
  HeaderCell,
  Row,
  Table,
} from '../styles';
import type { Props } from './types';

const DesktopVersion: FC<Props> = ({ data, headers }) => (
  <Table>
    <Header>
      {headers.map((header) => (
        <HeaderCell key={header.id}>{header.label}</HeaderCell>
      ))}
    </Header>
    {data.map((row) => (
      <Row key={row.id}>
        <FirstCol>{row.name}</FirstCol>
        <Col>{formatAmount(row.activity)}</Col>
        <Col>{formatAmount(row.startBalance)}</Col>
        <Col>{formatPercentage(row.startPortfolioPercentage)}</Col>
        <ColouredAmount value={row.changedAmount} />
        <ChangePercentage value={row.changedPercentage} />
        <Col>{formatAmount(row.endBalance)} </Col>
        <Col>{formatPercentage(row.endPortfolioPercentage)}</Col>
      </Row>
    ))}
  </Table>
);

export default memo(DesktopVersion);
