import styled, { css } from 'styled-components';

import { PROFILES_COLUMN_WIDTH } from '../styles';

export const Container = styled.button`
  appearance: none;
  background: none;
  color: ${({ theme }) => theme.colors.white};
  padding: 0;
  width: 100%;
`;

export const Box = styled.div<{ $isActive: boolean }>`
  align-items: center;
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.white10};
  display: flex;
  height: 3.125rem;
  justify-content: center;
  margin: 0 auto;
  width: 3.125rem;

  ${({ $isActive }) =>
    $isActive &&
    css`
      background-color: ${({ theme }) => theme.colors.primary};
      border-color: ${({ theme }) => theme.colors.primary};
    `};
`;

export const Initials = styled.span`
  font-size: 1rem;
  line-height: 1rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  text-transform: uppercase;
`;

export const Label = styled.span`
  display: block;
  font-size: 12px;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: 0.9rem;
  margin: 4px auto 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  // Leave a bit of margin
  width: calc(${PROFILES_COLUMN_WIDTH} - 0.7rem);
`;
