import { FC, memo } from 'react';

import BaseMobileBreakdownTable from 'components/BaseMobileBreakdownTable';

import type { Props } from './types';

const MobileVersion: FC<Props> = ({ data }) => {
  const rows = data.map((item) => ({
    id: item.id,
    name: item.name,
    profitAndLoss: item.changedAmount,
    percentChange: item.changedPercentage,
  }));
  return <BaseMobileBreakdownTable data={rows} />;
};

export default memo(MobileVersion);
