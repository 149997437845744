import type { FC } from 'react';
import { memo } from 'react';

import keysOf from 'utils/keysOf';

import { Circle, Group, Line } from './styles';
import type { Props } from './types';

const CursorBar: FC<Props> = ({ bottom, tooltipLeft, top, chartKeysY }) => (
  <Group>
    <Line
      from={{
        x: tooltipLeft,
        y: top,
      }}
      to={{
        x: tooltipLeft,
        y: bottom,
      }}
      pointerEvents="none"
    />
    {keysOf(chartKeysY).map((key) => (
      <Circle
        key={key}
        $chartKey={key}
        cx={tooltipLeft}
        cy={chartKeysY[key]}
        r={4}
        pointerEvents="none"
      />
    ))}
  </Group>
);

export default memo(CursorBar);
