import useGetCoverageTeam from 'api/hooks/clients/useGetCoverageTeam';
import useSelectedClient from 'api/hooks/clients/useSelectedClient';

const useCoverageTeam = () => {
  const { selectedPortfolio } = useSelectedClient();
  const { data: coverageTeam } = useGetCoverageTeam(selectedPortfolio?.id);

  return { coverageTeam };
};

export default useCoverageTeam;
