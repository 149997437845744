import { FC, memo } from 'react';

import SortMultipleItemsGroup from './SortMultipleItemsGroup';
import SortSimpleItemsGroup from './SortSimpleItemsGroup';
import type { Props } from './types';

const SortItemsGroup: FC<Props> = ({ options, name, multiple }) => {
  if (multiple) {
    return <SortMultipleItemsGroup options={options} name={name} />;
  }

  return <SortSimpleItemsGroup options={options} name={name} />;
};
export default memo(SortItemsGroup);
