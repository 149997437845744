import styled, { css } from 'styled-components';

import type { Theme } from 'styles/themes';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const Item = styled.div`
  :not(:last-child) {
    margin-right: 2rem;
  }
`;

export const Box = styled.span<{ $color: keyof Theme['colors'] }>`
  border-radius: 0.125rem;
  display: inline-block;
  height: 0.625rem;
  margin-right: 0.375rem;
  width: 0.625rem;

  ${({ theme, $color }) => css`
    background-color: ${theme.colors[$color]};
  `};
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.white50};
`;
