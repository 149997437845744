import { IonLabel, IonTabs } from '@ionic/react';
import styled from 'styled-components';

import DefaultSidebar from 'components/Sidebar';
import DefaultSpinner from 'components/Spinner';

const SIDEBAR_WIDTH = '15rem';

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const Sidebar = styled(DefaultSidebar)<{
  $expiredSession: boolean;
}>`
  height: 100vh;
  position: fixed;
  width: ${SIDEBAR_WIDTH};
  filter: blur(${({ $expiredSession }) => ($expiredSession ? '8px' : 0)});
`;

export const DesktopContent = styled.div<{
  $expiredSession: boolean;
}>`
  margin-left: ${SIDEBAR_WIDTH};
  filter: blur(${({ $expiredSession }) => ($expiredSession ? '8px' : 0)});
`;

export const Tabs = styled(IonTabs)<{
  $expiredSession: boolean;
}>`
  --ion-background-color: ${({ theme }) => theme.colors.dark0};
  background: ${({ theme }) => theme.colors.dark2};
  & ion-tab-bar {
    height: 4.8125rem;
    border-top: none;
    border-radius: 1.5rem 1.5rem 0 0;
    padding-inline-start: 20px;
    padding-inline-end: 20px;
  }
  & ion-tab-button {
    color: ${({ theme }) => theme.colors.white};
    opacity: 0.5;
  }
  & .tab-selected {
    color: ${({ theme }) => theme.colors.white};
    opacity: 1;
  }
  ion-icon {
    height: 1.25rem;
    font-size: 0.75rem;
    width: 1.25rem;
  }
  ion-icon svg {
    height: 1.25rem;
    font-size: 0.75rem;
    width: 1.25rem;
  }
  filter: blur(${({ $expiredSession }) => ($expiredSession ? '8px' : 0)});
`;

export const Label = styled(IonLabel)`
  margin-top: 0.25rem;
  font-size: 0.6875rem;
  line-height: 0.9625rem;
`;

export const IconContainer = styled.div`
  position: relative;
  margin-top: 6px;
  margin-botton: 2px;
`;

export const Dot = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  position: absolute;
  left: 0.85rem;
  top: -0.125rem;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.statusDecrease};
  z-index: 1;
`;

export const Spinner = styled(DefaultSpinner)``;
