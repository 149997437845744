import type { FC } from 'react';

import useTermsAndConditions from 'api/hooks/auth/useTermsAndConditions';
import ProfileMenu from 'components/ProfileMenu';
import from from 'styles/responsive';
import useMedia from 'utils/useMedia';

import useConnect from '../connect';
import {
  pathnameConditions,
  pathnameLogOut,
  pathnameNotifications,
  pathnamePassword,
  pathnameRoot,
} from '../constants';
import {
  Container,
  Content,
  Grid,
  Header,
  TermsAndConditionsContent,
} from './styles';
import type { Props } from './types';

const TermsAndConditions: FC<Props> = ({ path }) => {
  const { data: termsAndConditions } = useTermsAndConditions();
  const { biometricsEnabled, biometricType, email, onToggleBiometrics } =
    useConnect();

  const isTablet = useMedia(from.tablet.query);

  return (
    <Container
      title="Terms, Conditions, and Important Disclosures"
      parentUrl={`${path}${pathnameRoot}`}
      withSubtitle={false}
    >
      <Grid>
        {isTablet && (
          <>
            <Header email={email} />
            <ProfileMenu
              biometricsEnabled={biometricsEnabled}
              biometricType={biometricType}
              onToggleBiometrics={onToggleBiometrics}
              currentPathname={`${path}${pathnameConditions}`}
              pathnameConditions={`${path}${pathnameConditions}`}
              pathnameLogOut={`${path}${pathnameLogOut}`}
              pathnameNotifications={`${path}${pathnameNotifications}`}
              pathnamePassword={`${path}${pathnamePassword}`}
            />
          </>
        )}
        <Content>
          {termsAndConditions && (
            <TermsAndConditionsContent
              content={termsAndConditions}
              withLogo={false}
            />
          )}
        </Content>
      </Grid>
    </Container>
  );
};

export default TermsAndConditions;
