import type { SegmentChangeEventDetail } from '@ionic/core';
import { memo, useCallback } from 'react';

import { DefaultTabs, TabButton, TabLabel } from './styles';
import type { Props } from './types';

function Tabs<T extends string = string>({
  className,
  onChange,
  tabs,
  value,
  variant = 'folder-tabs',
}: Props<T>) {
  const handleChange = useCallback(
    (e: CustomEvent<SegmentChangeEventDetail>) => {
      if (onChange && e.detail.value) {
        onChange(e.detail.value as T);
      }
    },
    [onChange],
  );

  return (
    <DefaultTabs
      onIonChange={handleChange}
      value={value}
      $variant={variant}
      className={className}
    >
      {tabs.map((tab) => (
        <TabButton
          value={tab.value}
          key={tab.value}
          $variant={variant}
          mode="md"
        >
          <TabLabel text-wrap>{tab.label}</TabLabel>
        </TabButton>
      ))}
    </DefaultTabs>
  );
}

export default memo(Tabs) as typeof Tabs;
