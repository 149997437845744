import type { FC } from 'react';

import UpdateAppOverlay from 'components/UpdateAppOverlay';
import { useUpdateApp } from 'containers/Services/UpdateAppService';

import useConnect from './connect';
import { IonContent, IonPage, TermsAndConditionsContent } from './styles';

const TermsAndConditions: FC = () => {
  const updateApp = useUpdateApp();
  const { handle, termsAndConditions } = useConnect();

  return (
    <IonPage>
      <IonContent>
        {updateApp?.isUpdateAppAvailable && <UpdateAppOverlay />}
        {termsAndConditions && (
          <TermsAndConditionsContent
            content={termsAndConditions}
            onAccept={handle.acceptTermsConditions}
            onReject={handle.logout}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default TermsAndConditions;
