import { parse, stringify } from 'query-string';
import { useCallback, useMemo } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import useAllocationGroups from 'api/hooks/allocations/useAllocationGroups';
import useCVBondSummary from 'api/hooks/clients/useCvBondSummary';
import useSelectedClient from 'api/hooks/clients/useSelectedClient';
import kebabCaseCustom from 'utils/kebabCaseCustom';
import useModal from 'utils/useModal';

import {
  useCurrentGrouping,
  useNavigationLinks,
} from '../CategoryDetail/logic';
import { useAssets, useFilteringSubtypes, useSorting } from './logic';
import type { SortAndFilterFormModel } from './types';

export default function useConnect() {
  const categorySlug = 'fixed-income';
  const pageTitle = 'Fixed Income';

  const { url } = useRouteMatch();
  const { replace } = useHistory();
  const { hash, pathname, search, state } = useLocation();

  const fixedIncomeCharacteristicsUrl = `${url}/fixed-income-characteristics${search}`;
  const bondPerformanceAnalysisUrl = `${url}/bond-performance-analysis${search}`;

  const { selectedPortfolio } = useSelectedClient();

  const { getNameBySlug } = useAllocationGroups({
    groupBy: 'asset-breakdown',
    subselection: undefined,
    portfolio: selectedPortfolio?.id,
  });
  const grouping = useCurrentGrouping({ search });
  const { backUrl, breadcrumbs, navigationLinks, selectedUrl } =
    useNavigationLinks({
      categorySlug,
      getNameBySlug,
      grouping,
      search,
      url,
    });

  const { compare, currentSorting, onChangeSorting, sortOptions } =
    useSorting();

  const {
    currentFiltering,
    filterOptions,
    multipleOptions,
    onChangeFiltering,
  } = useFilteringSubtypes({
    categorySlug,
    portfolio: selectedPortfolio?.id,
  });

  const { allocation, allocationChartData, assets, balance } = useAssets({
    categorySlug,
    compare,
    multipleSubtypesOptions: multipleOptions,
    grouping,
    portfolio: selectedPortfolio?.id,
    url,
  });

  const { closeModal, modalOpen, openModal } = useModal();

  const initialValues: SortAndFilterFormModel = useMemo(
    () => ({
      filterValue: currentFiltering,
      sortValue: currentSorting,
    }),
    [currentFiltering, currentSorting],
  );

  const onSubmitForm = useCallback(
    ({ filterValue, sortValue }: SortAndFilterFormModel) => {
      replace({
        hash,
        pathname,
        search: stringify({
          ...parse(search),
          subtypes:
            filterValue === 'default' ? undefined : filterValue ?? undefined,
          sortBy: sortValue === 'default' ? undefined : sortValue ?? undefined,
        }),
        state,
      });
      closeModal();
    },
    [closeModal, hash, pathname, replace, search, state],
  );

  const { data } = useCVBondSummary(selectedPortfolio?.id);

  const cvBondPerformanceSummaries =
    currentFiltering && currentFiltering.length > 0 && Array.isArray(data)
      ? data.filter((v) =>
          currentFiltering
            ?.split(',')
            .includes(v.subtype ? kebabCaseCustom(v.subtype) ?? '' : ''),
        )
      : data;

  return {
    allocation,
    allocationChartData,
    assets,
    cvBondPerformanceSummaries,
    backUrl,
    balance,
    fixedIncomeCharacteristicsUrl,
    bondPerformanceAnalysisUrl,
    breadcrumbs,
    currentFiltering,
    currentSorting,
    filterOptions,
    handle: {
      changeFiltering: onChangeFiltering,
      changeSorting: onChangeSorting,
      closeModal,
      openModal,
      submitForm: onSubmitForm,
    },
    initialValues,
    modalOpen,
    navigationLinks,
    pageTitle,
    selectedUrl,
    sortOptions,
  };
}
