import type { FC } from 'react';

import from from 'styles/responsive';
import useMedia from 'utils/useMedia';

import {
  ButtonContent,
  ButtonText,
  Cell,
  CellAmount,
  CellTitle,
  Chevron,
  StatsTable,
  TableButton,
} from './styles';
import type { Props } from './types';

const BaseStats: FC<Props> = ({ className, cols, pathname, buttontext }) => {
  const isTabletOrDesktop = useMedia(from.tablet.query);

  if (isTabletOrDesktop) {
    return (
      <StatsTable
        $columns={cols.length < 8 ? cols.length : cols.length / 2}
        $rows={cols.length < 8 ? 1 : 2}
        className={className}
      >
        {cols.map((col) => (
          <Cell key={col.id}>
            <CellAmount>
              {col.value}
              {col.subValue && <span>({col.subValue})</span>}
            </CellAmount>
            <CellTitle>{col.title}</CellTitle>
          </Cell>
        ))}
      </StatsTable>
    );
  }

  return (
    <>
      {buttontext && (
        <TableButton
          color="secondary"
          expand="block"
          href={pathname}
          className={className}
        >
          <ButtonContent>
            <ButtonText>{buttontext}</ButtonText> <Chevron />
          </ButtonContent>
        </TableButton>
      )}
    </>
  );
};

export default BaseStats;
