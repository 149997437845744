/* eslint-disable react/no-unescaped-entities */
import type { FC } from 'react';
import { memo } from 'react';

import {
  Container,
  MaintenanceImg,
  PrimaryText,
  SecondaryText,
  TertiaryText,
} from './styles';

const Maintenance: FC = () => (
  <Container>
    <MaintenanceImg />
    <SecondaryText>Our CV App is undergoing maintenance</SecondaryText>
    <PrimaryText>
      We're working diligently to bring it back online as soon as possible
    </PrimaryText>
    <TertiaryText>
      Thank you for your understanding and patience. We apologize for any
      inconvenience
    </TertiaryText>
  </Container>
);

export default memo(Maintenance);
