import { FC, memo } from 'react';

import { DefaultCheckbox } from './styles';
import type { Props } from './types';

const Checkbox: FC<Props> = ({ checked, disabled, onChange, id, value }) => (
  <DefaultCheckbox
    checked={checked}
    disabled={disabled}
    id={id}
    onIonChange={onChange}
    value={value}
  />
);

export default memo(Checkbox);
