import styled, { css } from 'styled-components';

import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import DefaultButton from 'components/Button';
import DefaultProfitLossChart from 'components/ProfitLossChart';
import DefaultSpinner from 'components/Spinner';
import PageLayout from 'containers/Common/PageLayout';
import from from 'styles/responsive';

export const LoaderContainer = styled.div`
  position: sticky;
  top: 50%;
  padding-left: 50%;
  z-index: 1000;
`;

export const Container = styled(PageLayout)<{
  $loading: boolean;
  $mobile: boolean;
}>`
  ${({ $loading }) =>
    $loading &&
    css`
      pointer-events: none;
      filter: blur(1px);
    `};

  ${({ $loading, $mobile }) =>
    $loading &&
    !$mobile &&
    css`
      margin-top: -4.5rem;
    `};

  ${from.tablet`
    padding: 3.875rem 2.5rem 1.25rem;
  `}
`;

export const Content = styled.div`
  padding: 2rem 1.25rem;

  ${from.tablet`
    padding: 2rem 0;
	  display: grid;
	  grid-row-gap: 2.5rem;
    grid-template-columns: 1fr;
  `};

  ${from.laptop`
    grid-column-gap: 1.5rem;
    grid-template-columns: 22.3rem auto;
  `};
`;

export const ProfitLossSection = styled.div`
  display: none;

  ${from.tablet`
		display: block;
	`};
`;

export const ProfitLossTitle = styled.h2`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.375rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  line-height: 1.4;
  margin: 0;
`;

export const ProfitLossChart = styled(DefaultProfitLossChart)``;

export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  ${from.laptop`
		align-items: flex-start;
    margin-bottom: 0.75rem;
	`};
`;

export const Button = styled(DefaultButton)`
  min-width: 8.625rem;
`;

export const TableButton = styled(Button)`
  min-width: auto;
  margin: 2rem 1.25rem 0;
`;

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ButtonText = styled.span`
  width: 100%;
  text-align: left;
  font-size: 1rem !important;
`;

export const Chevron = styled(ChevronRight)``;

export const Spinner = styled(DefaultSpinner)``;
