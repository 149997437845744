import { Capacitor } from '@capacitor/core';

export default function usePlatform() {
  const platform = Capacitor.getPlatform();
  const isWeb = Capacitor.getPlatform() === 'web';
  const isIOS = Capacitor.getPlatform() === 'ios';
  const isAndroid = Capacitor.getPlatform() === 'android';

  return {
    platform,
    isWeb,
    isIOS,
    isAndroid,
  };
}
