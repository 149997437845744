import type { GetVolatilityReportResponse } from 'api/queries/riskAnalysis/getVolatilityReport/types';
import { parseBackendDate } from 'utils/dates';
import type { WithoutNulls } from 'utils/withoutNulls';

type Input = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<
        GetVolatilityReportResponse['data']
      >[number]['subVolatilityReport']
    >[number]['volatilityReportData']
  >['r1Data']
>[number];

export const normalizeRollingVolatilityDatum = (input: WithoutNulls<Input>) =>
  ({
    id: `${input.dataType}_${input.dateProcessed}`,
    date: input.dateProcessed
      ? parseBackendDate(input.dateProcessed).getTime()
      : 0,
    value: input.value ?? 0,
  } as const);

export type RollingVolatilityDatum = ReturnType<
  typeof normalizeRollingVolatilityDatum
>;
