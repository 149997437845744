import { LinePath } from '@visx/shape';
import type { LinePathProps } from '@visx/shape/lib/shapes/LinePath';
import styled from 'styled-components';

import type { Datum } from '../types';

function DatumLinePath(props: LinePathProps<Datum>) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <LinePath<Datum> {...props} />;
}

export const Path = styled(DatumLinePath)`
  stroke: currentColor;
  stroke-width: 0.1875rem;
  color: ${({ theme }) => theme.colors.dataCarrot};
  pointer-events: none;
`;

export const Point = styled.circle.attrs(() => ({
  r: 8,
}))`
  color: ${({ theme }) => theme.colors.dataCarrot};
  fill: currentColor;
  pointer-events: none;
  transition: clip-path 200ms ease;
  clip-path: circle(25%);
`;

export const BasePoint = styled.circle.attrs(() => ({
  r: 24,
}))`
  fill: transparent;
  &:hover + ${Point} {
    clip-path: circle(50%);
  }
`;
