import type { GetVolatilityReportResponse } from 'api/queries/riskAnalysis/getVolatilityReport/types';
import type { WithoutNulls } from 'utils/withoutNulls';

type Input = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<
        GetVolatilityReportResponse['data']
      >[number]['subVolatilityReport']
    >[number]['volatilityReportData']
  >['r2Data']
>;

export const normalizeStatisticalAnalysis = (input: WithoutNulls<Input>) => {
  const last30Days = input.find((it) => !it.sinceInception);
  const sinceInception = input.find((it) => !!it.sinceInception);

  return [
    {
      id: 'avg-daily-movement',
      last30Days: last30Days?.avgDailyMovement ?? 0,
      name: 'Average Daily Movement',
      sinceInception: sinceInception?.avgDailyMovement ?? 0,
    } as const,
    {
      id: 'max-daily-loss',
      last30Days: last30Days?.maxDailyLoss ?? 0,
      name: 'Maximum Daily Loss',
      sinceInception: sinceInception?.maxDailyLoss ?? 0,
    } as const,
    {
      id: 'max-daily-gain',
      last30Days: last30Days?.maxDailyGain ?? 0,
      name: 'Maximum Daily Gain',
      sinceInception: sinceInception?.maxDailyGain ?? 0,
    } as const,
    {
      id: 'annual-std-dev',
      last30Days: last30Days?.annualStdDev ?? 0,
      name: 'Annualized Standard Deviation',
      sinceInception: sinceInception?.annualStdDev ?? 0,
    } as const,
    {
      id: 'profitable-percentage',
      last30Days: last30Days?.profitablePercentage ?? 0,
      name: 'Profitable Percentage',
      sinceInception: sinceInception?.profitablePercentage ?? 0,
    } as const,
  ] as const;
};

export type StatisticalAnalysis = ReturnType<
  typeof normalizeStatisticalAnalysis
>;

export type StatisticalAnalysisRow = StatisticalAnalysis[number];
