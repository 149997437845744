import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import useSnsTokenRegistration from 'api/mutations/clients/notifications';
import { GET_NOTIFICATION_SETTINGS_QUERY_KEY } from 'api/queries/clients/notifications';
import type { GetNotificationSettingsRequestParams } from 'api/queries/clients/notifications/types';

type Body = {
  snsToken: string;
  deviceID: string;
  platform: string;
  dailyClose: boolean;
  newsUpdate: boolean;
};

export default function useSetNotificationSettings({
  deviceID,
}: GetNotificationSettingsRequestParams) {
  const client = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(useSnsTokenRegistration(), {
    onSuccess: () => {
      void client.invalidateQueries(
        GET_NOTIFICATION_SETTINGS_QUERY_KEY({ deviceID }),
      );
    },
  });

  return {
    isLoading,
    setNotificationSettings: useCallback(
      async (body: Body) => mutateAsync(body),
      [mutateAsync],
    ),
  };
}
