import styled from 'styled-components';

export const Container = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 0.75rem;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  height: 2.25rem;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 2.25rem;
`;

export const Content = styled.span`
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  text-transform: uppercase;
`;
