import { FC, memo } from 'react';

import from from 'styles/responsive';
import useMedia from 'utils/useMedia';

import {
  Breadcrumb,
  BreadcrumbLink,
  Breadcrumbs,
  ButtonLink,
  ButtonLinks,
  CaretLeft,
  Container,
  Link,
  Links,
  MobileContainer,
  Title,
} from './styles';
import type { Props } from './types';

const SectionNavigation: FC<Props> = ({
  className,
  links,
  breadcrumbs,
  activeLink,
  withTitle = true,
}) => {
  const isMobile = !useMedia(from.tablet.query);

  if (isMobile) {
    return (
      <MobileContainer className={className}>
        {withTitle && <Title>Browse Assets by</Title>}
        <ButtonLinks>
          {links.map((link) => (
            <ButtonLink key={link.value} pathname={link.value}>
              {link.name}
            </ButtonLink>
          ))}
        </ButtonLinks>
      </MobileContainer>
    );
  }
  return (
    <Container className={className}>
      {withTitle && <Title>Browse by</Title>}
      {breadcrumbs && (
        <Breadcrumbs>
          {breadcrumbs.map((breadcrumb) => (
            <Breadcrumb key={breadcrumb.name}>
              <CaretLeft $isActive={activeLink === breadcrumb.value} />
              <BreadcrumbLink
                $isActive={activeLink === breadcrumb.value}
                href={breadcrumb.value}
                variant="link"
              >
                {breadcrumb.name}
              </BreadcrumbLink>
            </Breadcrumb>
          ))}
        </Breadcrumbs>
      )}
      <Links>
        {links.map((link) => (
          <Link
            $isActive={activeLink === link.value}
            href={link.value}
            key={link.name}
            variant="link"
          >
            {link.name}
          </Link>
        ))}
      </Links>
    </Container>
  );
};

export default memo(SectionNavigation);
