import { IonLabel, IonSegment, IonSegmentButton } from '@ionic/react';
import styled, { css } from 'styled-components';

import from from 'styles/responsive';

import type { TabsProps } from './types';

export const DefaultTabs = styled(IonSegment)<{
  $variant: TabsProps['$variant'];
}>`
  border-radius: 0;
  ${({ $variant }) =>
    ($variant === 'bg-tabs' || $variant === 'bg-tabs-without-width') &&
    css`
      --background: ${({ theme }) => theme.colors.white5};
      padding: 0.25rem;
      border-radius: 0.625rem;
    `}
`;

const tabsVariants = css<{
  $variant: TabsProps['$variant'];
}>`
  text-transform: initial;
  font-size: 0.875rem;
  line-height: 1.0625rem;

  ${({ $variant }) =>
    ($variant === 'folder-tabs' &&
      css`
        ${from.tablet`
          min-width: 10.5rem;
          max-width: 12.9375rem;
        `}
        --indicator-color: ${({ theme }) => theme.colors.primary};
        --background-hover: transparent;
        background: ${({ theme }) => theme.colors.dark2};
        border-bottom: 0.0625rem solid ${({ theme }) => theme.colors.white10};
        border-radius: 0;
        & ion-label {
          margin-top: 0.875rem;
          margin-bottom: 0.875rem;
        }
        &.segment-button-checked {
          margin-bottom: -0.0625rem;
          & ion-label {
            margin-bottom: 0.9375rem;
          }
        }
        --color: ${({ theme }) => theme.colors.white50};
        --color-checked: ${({ theme }) => theme.colors.white};
        --color-hover: ${({ theme }) => theme.colors.white};
      `) ||
    (($variant === 'bg-tabs' || $variant === 'no-bg-tabs') &&
      css`
        ${from.tablet`
          min-width: 10.5rem;
          max-width: 12.9375rem;
        `}
        --indicator-color: transparent;
        --color: ${({ theme }) => theme.colors.white};
        --color-checked: ${({ theme }) => theme.colors.white};
        --background-checked: ${({ theme }) => theme.colors.primary};
        --border-radius: 0.5rem;
        overflow: hidden;
        min-height: 2.0625rem;
        transition: all 200ms ease;
        & ion-label {
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
          line-height: 1.05rem;
        }
      `) ||
    ($variant === 'bg-tabs-without-width' &&
      css`
        --indicator-color: transparent;
        --color: ${({ theme }) => theme.colors.white};
        --color-checked: ${({ theme }) => theme.colors.white};
        --background-checked: ${({ theme }) => theme.colors.primary};
        --border-radius: 0.5rem;
        overflow: hidden;
        min-height: 2.0625rem;
        transition: all 200ms ease;
        & ion-label {
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
          line-height: 1.05rem;
        }
      `)}
`;

export const TabButton = styled(IonSegmentButton)`
  ${tabsVariants}
`;

export const TabLabel = styled(IonLabel)`
  line-height: 1.1875rem !important;
`;
