import { FC, memo, useMemo } from 'react';

import BaseStats from 'components/BaseStats';
import { formatAmount } from 'utils/amounts';
import { formatPercentage } from 'utils/percentages';

import type { Props } from './types';

const PrivateInvestmentsStats: FC<Props> = ({
  className,
  capitalCalled,
  commitment,
  distributions,
  marketValue,
  contDollars,
  MOI,
  pathname,
  toBeCalled,
  totalPortfolioPercent,
}) => {
  const cols = useMemo(
    () => [
      {
        id: 1,
        title: 'Market Value',
        value: formatAmount(marketValue),
        subValue: formatPercentage(totalPortfolioPercent),
      },
      {
        id: 3,
        title: 'Commitment',
        value: formatAmount(commitment),
      },
      {
        id: 4,
        title: 'Capital Called',
        value: formatAmount(capitalCalled),
      },
      {
        id: 5,
        title: 'To Be Called',
        value: formatAmount(toBeCalled),
      },
      {
        id: 6,
        title: 'Distributions',
        value: formatAmount(distributions),
      },
      {
        id: 7,
        title: 'P&L ($)',
        value: formatAmount(contDollars),
      },
      {
        id: 8,
        title: 'MOI',
        value: MOI,
      },
    ],
    [
      MOI,
      capitalCalled,
      commitment,
      distributions,
      marketValue,
      toBeCalled,
      totalPortfolioPercent,
      contDollars,
    ],
  );

  return (
    <BaseStats
      className={className}
      cols={cols}
      pathname={pathname}
      buttontext="Private Investments Summary"
    />
  );
};

export default memo(PrivateInvestmentsStats);
