import { FC, memo } from 'react';

import BaseListButton from 'components/BaseListButton';
import from from 'styles/responsive';
import { formatPercentageAsBondCoupon } from 'utils/percentages';
import useMedia from 'utils/useMedia';

import { HeaderTitle, VectorDownIcon, VectorUpIcon } from '../styles';
import InfoColumn from './InfoColumn';
import {
  Content,
  FirstCol,
  Header,
  HeaderCell,
  HeaderContainer,
  Row,
  Table,
} from './styles';
import type { Props } from './types';

const TabletAndMobileVersion: FC<Props> = ({
  data,
  selectedData,
  handleSortByColumn,
  sortApplied,
  headerColumns,
}) => {
  const handlerClick = (id: string) => {
    sessionStorage.setItem('itemPosition', id);
  };

  const isTablet = useMedia(from.tablet.query);

  const firstCol = headerColumns[0];
  const secondCol = headerColumns.find(
    (col) => col.titleColumn === selectedData,
  );

  return (
    <Table>
      <HeaderContainer>
        {isTablet && (
          <Header>
            <HeaderCell key={firstCol?.titleColumn}>
              <HeaderTitle
                onClick={() =>
                  handleSortByColumn && firstCol
                    ? handleSortByColumn(firstCol)
                    : undefined
                }
              >
                {firstCol?.titleColumn}
                {sortApplied?.titleColumn === firstCol?.titleColumn &&
                  (sortApplied?.orderOrientation === 'DESC' ? (
                    <VectorDownIcon />
                  ) : (
                    <VectorUpIcon />
                  ))}
              </HeaderTitle>
            </HeaderCell>
            <HeaderCell key={secondCol?.titleColumn}>
              <HeaderTitle
                onClick={() =>
                  handleSortByColumn && secondCol
                    ? handleSortByColumn(secondCol)
                    : undefined
                }
              >
                {secondCol?.titleColumn}
                {sortApplied?.titleColumn === secondCol?.titleColumn &&
                  (sortApplied?.orderOrientation === 'DESC' ? (
                    <VectorDownIcon />
                  ) : (
                    <VectorUpIcon />
                  ))}
              </HeaderTitle>
            </HeaderCell>
          </Header>
        )}
        {!isTablet && (
          <Header>
            <HeaderCell>{firstCol?.titleColumn}</HeaderCell>
            <HeaderCell>{secondCol?.titleColumn}</HeaderCell>
          </Header>
        )}
      </HeaderContainer>
      <Content>
        {data.map((row) => (
          <BaseListButton
            key={`${row.id}-${row.value}`}
            pathname={row.pathname}
            onClick={() => handlerClick(row.id)}
          >
            <Row id={row.id}>
              <FirstCol>
                {row.name}
                <span>
                  {formatPercentageAsBondCoupon(row.coupon)} {row.maturity}
                </span>
              </FirstCol>
              <InfoColumn row={row} selectedData={selectedData} />
            </Row>
          </BaseListButton>
        ))}
      </Content>
    </Table>
  );
};

export default memo(TabletAndMobileVersion);
