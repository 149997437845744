import { Form as DefaultForm } from 'formik';
import styled, { css } from 'styled-components';

import AllocationsHeader from 'components/AllocationsHeader';
import Button from 'components/Button';
import CashStats from 'components/CashStats';
import PrivateInvestmentsAssetList from 'components/PrivateInvestmentsAssetList';
import SectionNavigation from 'components/SectionNavigation';
import MainContentGrid from 'containers/Common/MainContentGrid';
import PageDetailLayout from 'containers/Common/PageDetailLayout';
import { MobileContent } from 'containers/Common/PageDetailLayout/styles';
import from from 'styles/responsive';
import ZIndex from 'styles/ZIndex';

export const Container = styled(PageDetailLayout)<{
  $enableCSSPropAsWorkaround: boolean;
}>`
  ${({ $enableCSSPropAsWorkaround }) =>
    $enableCSSPropAsWorkaround &&
    css`
      ${MobileContent} {
        z-index: ${ZIndex.MOBILE_CONTENT};
      }
    `};
`;

export const Grid = styled(MainContentGrid)`
  padding: 0;
  grid-template-columns: 1fr;
  ${from.tablet`
    display: grid;
    grid-template-rows: repeat(4, auto);
    padding: 0;
    grid-template-columns: 10.4375rem 1fr;
    grid-column-gap: 1.5rem;
  `}
`;

export const Header = styled(AllocationsHeader)`
  ${from.laptop`
    margin-bottom: 3.5rem;
  `}
`;

export const Content = styled.div`
  padding: 0 1.25rem 3.3125rem;
  ${from.tablet`
    padding: 0;
  `}
`;

export const Navigation = styled(SectionNavigation)`
  padding-top: 1rem;
`;

export const Toolbar = styled.div`
  display: none;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  ${from.tablet`
    display: flex;
  `}
`;

export const Title = styled.h2`
  margin: 0;
  font-size: 1rem;
  line-height: 1.4;
`;

export const ActionZone = styled.div`
  display: flex;
  align-items: center;
`;

export const ExportToExcelButton = styled(Button)`
  min-width: 8.625rem;
`;

export const Stats = styled(CashStats)`
  margin-bottom: 2rem;
  ${from.tablet`
    margin: 1.5rem 0 3.125rem;
  `}
`;

export const TableContainer = styled.div`
  position: relative;
`;

export const CashContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.white10};
  padding-top: 0.5rem;
  ${from.tablet`
    padding-top: 0;
    border: none;
  `}
`;

export const CashList = styled(PrivateInvestmentsAssetList)`
  padding-top: 0.5rem;
`;

export const ModalTitle = styled.h2`
  margin: 0 0 1rem;
  font-size: 1.375rem;
  line-height: 1.4;
  font-weight: ${({ theme }) => theme.weights.medium};
`;

export const Form = styled(DefaultForm)``;

export const TableButton = styled(Button)`
  svg {
    transform: rotate(-90deg);
  }
`;
