import { Route } from 'react-router-dom';

import ClientPerformance from './ClientPerformance';
import HomeRoot from './HomeRoot';
import ReportCategoryDetail from './ReportCategoryDetail';
import ReportDetail from './ReportDetail';
import type { Props } from './types';

function Home({ path }: Props) {
  return [
    <Route
      component={ReportCategoryDetail}
      key="report-category-detail"
      exact
      path={`${path}/reports/:reportSlug/detail/:categorySlug/:timeFrame`}
    />,
    <Route
      component={ReportCategoryDetail}
      key="report-category-detail"
      exact
      path={`${path}/reports/:reportSlug/detail/:categorySlug`}
    />,
    <Route
      component={ReportDetail}
      key="report-detail"
      exact
      path={`${path}/reports/:reportSlug/:timeFrame`}
    />,
    <Route
      component={ReportDetail}
      key="report-detail"
      exact
      path={`${path}/reports/:reportSlug`}
    />,
    <Route
      component={ClientPerformance}
      key="client-performance"
      exact
      path={`${path}/client-performance`}
    />,
    <Route component={HomeRoot} key="home-root" exact path={path} />,
  ];
}

export default Home;
