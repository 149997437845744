import type { FC } from 'react';
import { Redirect } from 'react-router';
import { Route } from 'react-router-dom';

import Menu from 'components/Menu';
import ProfileSwitcherAccount from 'components/ProfileSwitcherAccount';
import ProfileSwitcherLayout from 'components/ProfileSwitcherLayout';
import Settings from 'components/Settings';
import UpdateAppOverlay from 'components/UpdateAppOverlay';
import { useUpdateApp } from 'containers/Services/UpdateAppService';
import { ManagedAssetContext } from 'context/Asset';

import PortfolioSwitcherContext from '../Services/PortfolioSwitcherContext';
import Allocations from './Allocations';
import AppNavigation from './AppNavigation';
import useConnect from './connect';
import Home from './Home';
import Markets from './Markets';
import News from './News';
import Profile from './Profile';
import RiskAnalysis from './RiskAnalysis';
import TermsOfUseAndDisclosures from './TermsOfUseAndDisclosures';
import Transactions from './Transactions';

const Protected: FC = () => {
  const updateApp = useUpdateApp();
  const {
    handleAccountRadioGroupChange,
    handleDisplaySearch,
    handleDisplaySettings,
    handleSearchChange,
    clients,
    clientList,
    handle,
    menuRef,
    openSearch,
    openSettings,
    searchValue,
    selectedClient,
    selectedPortfolio,
    selectedDefaultAccount,
    selectedDefaultPortfolio,
  } = useConnect();

  return (
    <ManagedAssetContext>
      {updateApp?.isUpdateAppAvailable && <UpdateAppOverlay />}
      <PortfolioSwitcherContext menuRef={menuRef}>
        <Menu contentId="main_content" ref={menuRef}>
          <ProfileSwitcherLayout
            activeProfileId={selectedClient?.id}
            onChangeSelectedProfile={handle.changeSelectedClient}
            profiles={clients}
            settingsActive={openSettings}
            searchActive={openSearch}
            onClickSettings={handleDisplaySettings}
            onClickSearch={handleDisplaySearch}
          >
            {selectedClient && selectedPortfolio && clientList && (
              <>
                {openSettings || openSearch ? (
                  <Settings
                    accounts={clientList}
                    onChangeAccount={handle.changeSelectedClient}
                    onChangeDefaultAccount={handleAccountRadioGroupChange}
                    onChangeSearch={handleSearchChange}
                    searchValue={searchValue}
                    selectedAccount={selectedDefaultAccount}
                    selectedPortfolio={selectedDefaultPortfolio}
                    settingsActive={openSettings}
                  />
                ) : (
                  <ProfileSwitcherAccount
                    name={selectedClient.name}
                    portfolios={selectedClient.subPortfolios}
                    selectedPortfolioId={selectedPortfolio.id}
                    onChangeSelectedPortfolio={handle.changeSelectedPortfolio}
                  />
                )}
              </>
            )}
          </ProfileSwitcherLayout>
        </Menu>
        <AppNavigation>
          {[
            ...Home({
              // Ionic Framework is seriously a piece of shit, and this
              // route will not re-render even if the props change.
              // So the only props that we can pass are props that never
              // change.
              path: '/home',
            }),
            ...Allocations({
              path: '/allocations',
            }),
            <Route key="risk" exact path="/risk" component={RiskAnalysis} />,
            ...Markets({
              path: '/markets',
            }),
            <Route
              key="transactions"
              exact
              path="/transactions"
              component={Transactions}
            />,
            <Route key="news" exact path="/news" component={News} />,
            ...Profile({
              path: '/profile',
            }),
            <Route
              key="terms-of-use-and-disclosures"
              exact
              path="/terms-of-use-and-disclosures"
              component={TermsOfUseAndDisclosures}
            />,
            <Redirect key="404" to="/home" />,
          ]}
        </AppNavigation>
      </PortfolioSwitcherContext>
    </ManagedAssetContext>
  );
};

export default Protected;
