import { FC, memo } from 'react';
import { useHistory } from 'react-router';

import {
  Button,
  ButtonClose,
  Container,
  MessageActivateNotification,
  SubText,
  Text,
  TextContainer,
} from './style';
import type { Props } from './types';

const NoticeActivateNotifications: FC<Props> = ({ closeNotice }) => {
  const { replace } = useHistory();

  const goToNotifications = () => {
    closeNotice();
    replace('/profile/notifications');
  };

  return (
    <Container>
      <MessageActivateNotification>
        <TextContainer>
          <Text>Activate Notifications</Text>
          <SubText>
            To be notified when relevant news is posted or data is updated,
            please activate your notification settings on the Profile page in
            the CV App.
          </SubText>
          <Button onClick={goToNotifications}>Notification Settings</Button>
          <ButtonClose onClick={closeNotice}>Close</ButtonClose>
        </TextContainer>
      </MessageActivateNotification>
    </Container>
  );
};

export default memo(NoticeActivateNotifications);
