import { FC, memo, useCallback } from 'react';

import { Circle } from './styles';
import type { Event, Props } from './types';

const Point: FC<Props> = ({
  x,
  y,
  radius,
  datum,
  meanYieldToWorst,
  meanDuration,
  handleTooltipUpdated,
  handleTooltipClosed,
}) => {
  const handleTooltip = useCallback(
    (event: Event) => {
      if (meanDuration !== undefined && meanYieldToWorst !== undefined) {
        handleTooltipUpdated(event, {
          isAverage: true,
          radius,
          ytw: meanYieldToWorst,
          duration: meanDuration,
        });
      } else if (datum) {
        handleTooltipUpdated(event, datum);
      }
    },
    [radius, datum, meanYieldToWorst, meanDuration, handleTooltipUpdated],
  );

  return (
    <Circle
      $isMedian={meanDuration !== undefined && meanYieldToWorst !== undefined}
      r={radius}
      cx={x}
      cy={y}
      onMouseLeave={handleTooltipClosed}
      onMouseEnter={handleTooltip}
    />
  );
};

export default memo(Point);
