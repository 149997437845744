import { useMemo } from 'react';

import useGetPortfolioHistoryChartDataQuery, {
  GET_PORTFOLIO_HISTORY_CHART_DATA_QUERY_KEY,
} from 'api/queries/charts/getPortfolioHistoryChartData';
import { useQuery } from 'api/storage';

export default function usePortfolioHistoryChartData({
  client,
  portfolioName,
}: {
  client: string | undefined;
  portfolioName: string | undefined;
}) {
  const { data: raw } = useQuery(
    GET_PORTFOLIO_HISTORY_CHART_DATA_QUERY_KEY(client ?? ''),
    {
      enabled: !!client,
      queryFn: useGetPortfolioHistoryChartDataQuery(),
    },
  );

  const data = useMemo(() => {
    const selectedData = portfolioName ? raw?.[portfolioName] : [];
    return selectedData
      ? [...selectedData].sort((a, b) => a.date - b.date)
      : undefined;
  }, [portfolioName, raw]);

  return {
    data,
  };
}
