import { curveLinear } from '@visx/curve';
import { Group } from '@visx/group';
import { FC, memo } from 'react';

import type { Datum } from '../types';
import {
  LinePathCurrent,
  LinePathTarget,
  MarkerCircle,
  MarkerX,
} from './styles';
import type { Props } from './types';

const LineChart: FC<Props> = ({ data, xScale, yScale, widthToCenterBand }) => (
  <svg>
    <MarkerX id="marker-x" size={12} />
    <MarkerCircle id="marker-circle" size={3} refX={4} />
    <Group>
      <LinePathCurrent
        curve={curveLinear}
        data={data}
        x={(d) => (xScale((d as Datum).date) ?? 0) + widthToCenterBand}
        y={(d) => yScale((d as Datum).current) ?? 0}
        markerStart="url(#marker-x)"
        markerMid="url(#marker-x)"
        markerEnd="url(#marker-x)"
      />
      <LinePathTarget
        curve={curveLinear}
        data={data}
        x={(d) => (xScale((d as Datum).date) ?? 0) + widthToCenterBand}
        y={(d) => yScale((d as Datum).target) ?? 0}
        markerStart="url(#marker-circle)"
        markerMid="url(#marker-circle)"
        markerEnd="url(#marker-circle)"
      />
    </Group>
  </svg>
);

export default memo(LineChart);
