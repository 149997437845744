import { scaleOrdinal } from '@visx/scale';
import { BarStack } from '@visx/shape';
import compose from 'lodash/fp/compose';
import { memo, useMemo } from 'react';
import type { FC } from 'react';

import { getBarKeyColor, getDate } from '../logic';
import type { BarKey, Datum } from '../types';
import { Bar } from './styles';
import type { Props } from './types';

const noOpScale = scaleOrdinal<string, string>({
  domain: [],
  range: [],
});

const BarChart: FC<Props> = ({
  data,
  handleTooltipClosed,
  handleTooltipUpdated,
  handleBarClick,
  keys,
  xScale,
  yScale,
  instrumentTypeHover,
}) => {
  const handleBothTooltipsClosed = useMemo(
    () => compose(handleTooltipClosed),
    [handleTooltipClosed],
  );

  return (
    <g>
      <BarStack<Datum, BarKey>
        // We handle colors ourselves with our style system so we can access
        // the current theme. Since BarStack requires a color scale, we feed it
        // a no-op scale.
        color={noOpScale}
        data={data}
        keys={[...keys]}
        // This is what takes care of bar positioning: positive and negative
        // bars diverge; positive to top, negative to bottom.
        // https://github.com/d3/d3-shape/blob/54f0f111a0fc356d8a71205686cd59033a931631/README.md#stackOffsetDiverging
        offset="diverging"
        x={getDate}
        xScale={xScale}
        yScale={yScale}
      >
        {(barStacks) =>
          barStacks.flatMap((barStack) =>
            barStack.bars.map((bar) => (
              <Bar
                $isInstrumentTypeHover={bar.key === instrumentTypeHover}
                $color={getBarKeyColor(keys.indexOf(bar.key))}
                data-datum-id={bar.bar.data.id}
                data-key={bar.key}
                height={bar.height}
                key={`bar-stack-${barStack.key}-${bar.bar.data.date}`}
                onMouseLeave={handleBothTooltipsClosed}
                onMouseMove={handleTooltipUpdated}
                onClick={handleBarClick}
                width={bar.width}
                x={bar.x}
                y={bar.key === instrumentTypeHover ? bar.y + 2 : bar.y}
              />
            )),
          )
        }
      </BarStack>
    </g>
  );
};

export default memo(BarChart);
