import { useLocation, useRouteMatch } from 'react-router';

import useMarketOverview from 'api/hooks/markets/useMarketOverview';
import useMarketWatchlistActions from 'api/hooks/markets/useMarketWatchlistActions';
import type { KeySortOfColumn } from 'utils/sort/types';

import type { Datum } from '../types';
import {
  useCategorySlugs,
  useLinks,
  useSelectedCategory,
  useSorting,
} from './logic';

export default function useConnect({
  watchlistData,
}: {
  watchlistData: Datum[];
}) {
  const { url } = useRouteMatch();
  const { search } = useLocation();

  const { categories } = useMarketOverview({
    categoryName: '',
    watchlistAssets: [],
  });

  const { getCategoryNameBySlug, getSlugByCategoryName } = useCategorySlugs({
    categories,
  });
  const selectedCategory = useSelectedCategory({
    getCategoryNameBySlug,
    search,
  });
  const { activeLink, links } = useLinks({
    categories,
    getSlugByCategoryName,
    search,
    url,
  });

  const { currentSorting, onChangeSorting, compare } = useSorting();

  const { data: categoryData } = useMarketOverview({
    categoryName: selectedCategory,
    watchlistAssets: watchlistData,
  });

  const dataSorted =
    selectedCategory === ''
      ? watchlistData.sort(compare)
      : categoryData.sort(compare);

  const headerColumns: KeySortOfColumn[] = [
    {
      titleColumn: `${dataSorted.length} Assets`,
      keyOrderAsc: 'alphabeticalR',
      keyOrderDesc: 'default',
    },
    {
      titleColumn: 'Daily Change',
      keyOrderAsc: 'dailyChangeASC',
      keyOrderDesc: 'dailyChangeDESC',
    },
    {
      titleColumn: 'MTD',
      keyOrderAsc: 'mtdASC',
      keyOrderDesc: 'mtdDESC',
    },
    {
      titleColumn: 'YTD',
      keyOrderAsc: 'ytdASC',
      keyOrderDesc: 'ytdDESC',
    },
  ];

  const { addToWatchlist, removeFromWatchlist } = useMarketWatchlistActions();

  return {
    activeLink,
    data: dataSorted,
    handle: {
      addToWatchlist,
      removeFromWatchlist,
      changeSorting: onChangeSorting,
    },
    links,
    selectedCategory,
    currentSorting,
    headerColumns,
  };
}
