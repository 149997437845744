import type { FC } from 'react';
import { memo } from 'react';

import { formatPercentageWithDecimalsIfNecessary } from 'utils/percentages';

import { Data, DataLabel, DataValue, Key, TooltipWithBounds } from './styles';
import type { Props } from './types';

const Tooltip: FC<Props> = ({ data, tooltipLeft, tooltipTop }) => (
  <TooltipWithBounds top={tooltipTop} left={tooltipLeft}>
    <Key>{data.instrumentType}</Key>
    <Data>
      <DataLabel>Target</DataLabel>
      <DataValue>
        {formatPercentageWithDecimalsIfNecessary(data.target)}
      </DataValue>
    </Data>
    <Data>
      <DataLabel>Current</DataLabel>
      <DataValue>
        {formatPercentageWithDecimalsIfNecessary(data.current)}
      </DataValue>
    </Data>
    <Data>
      <DataLabel>Range</DataLabel>
      <DataValue>{`${formatPercentageWithDecimalsIfNecessary(
        data.range.lowerBound,
      )} - ${formatPercentageWithDecimalsIfNecessary(
        data.range.upperBound,
      )}`}</DataValue>
    </Data>
  </TooltipWithBounds>
);

export default memo(Tooltip);
