import { useRouteMatch } from 'react-router';
import { useLocation } from 'react-router-dom';

import useSelectedClient from 'api/hooks/clients/useSelectedClient';
import up from 'utils/paths';

import { useRows } from './logic';

export default function useConnect() {
  const { url } = useRouteMatch();
  const { search } = useLocation();

  const backUrl = `${up(url)}${search}`;

  const { selectedPortfolio } = useSelectedClient();

  const { filterText, rows, subtypes } = useRows({
    portfolio: selectedPortfolio?.id,
    search,
    url,
  });

  return { backUrl, filterText, rows, subtypes };
}
