import type { FC } from 'react';

import useConnect from './connect';
import { Container, Content, FilterLabelContainer, Table } from './styles';

const FixedIncomeCharacteristics: FC = () => {
  const { backUrl, filterText, rows, subtypes } = useConnect();

  return (
    <Container
      parentUrl={backUrl}
      title="Fixed Income Characteristics"
      subtitle={filterText}
    >
      {subtypes && (
        <FilterLabelContainer>
          <div>
            Filtered by <strong>{subtypes.join(', ')}</strong>
          </div>
        </FilterLabelContainer>
      )}
      <Content>
        <Table rows={rows} />
      </Content>
    </Container>
  );
};

export default FixedIncomeCharacteristics;
