import { Form as DefaultForm } from 'formik';
import styled from 'styled-components';

import BondPerformanceAnalysisStats from 'components/BondPerformanceAnalysisStats';
import Button from 'components/Button';
import FixedIncomeAssetList from 'components/FixedIncomeAssetList';
import FixedIncomeStats from 'components/FixedIncomeStats';
import Select from 'components/Select';
import DefaultSortInfo from 'components/SortInfo';
import SortModal from 'components/SortModal';
import from from 'styles/responsive';
import ZIndex from 'styles/ZIndex';

export const Container = styled.div`
  padding: 0 1.25rem 3.3125rem;
  ${from.tablet`
    padding: 0;
  `}
`;

export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.4375rem;
`;

export const Title = styled.h2`
  margin: 0;
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.white75};
  font-weight: ${({ theme }) => theme.weights.regular};
  ${from.tablet`
    font-size: 1rem;
    line-height: 1.4;
    color: ${({ theme }) => theme.colors.white};
    font-weight: ${({ theme }) => theme.weights.bold};
  `}
`;

export const FIStats = styled(FixedIncomeStats)`
  margin-bottom: 2rem;
  ${from.tablet`
    margin: 1.5rem 0 3.125rem;
  `}
`;

export const BPAStats = styled(BondPerformanceAnalysisStats)`
  margin-top: -1.5rem;
  margin-bottom: 2rem;
`;

export const ActionZone = styled.div`
  display: flex;
  align-items: center;
`;

export const SortInfo = styled(DefaultSortInfo)`
  margin-bottom: 0.5rem;
`;

export const TableContainer = styled.div`
  position: relative;
`;

export const SortButton = styled(Select)`
  width: fit-content;
  margin-left: 0.75rem;
  display: none;
  ${from.tablet`
    display: flex;
  `}
`;

export const FilterButton = styled(SortButton)`
  display: none;
  ${from.tablet`
    display: flex;
  `}
`;

export const DataDisplayButton = styled(Select)`
  --min-height: 2.4375rem;
  margin-left: 1rem;
  position: relative;
  z-index: ${ZIndex.MAIN};
  ${from.laptop`
    display: none;
  `}
`;

export const FixedIncomeAssetTable = styled(FixedIncomeAssetList)`
  border-top: 1px solid ${({ theme }) => theme.colors.white10};
  padding-top: 0.5rem;
  ${from.tablet`
    padding-top: 0;
    border: none;
  `}
`;

export const ModalTitle = styled.h2`
  margin: 0 0 1rem;
  font-size: 1.375rem;
  line-height: 1.4;
  font-weight: ${({ theme }) => theme.weights.medium};
`;

export const FilterModalTitle = styled(ModalTitle)`
  margin-top: 2rem;
`;

export const Form = styled(DefaultForm)``;

export const SortMobileButton = styled(Button)`
  width: fit-content;
  margin-left: 1rem;
  ${from.tablet`
    display: none;
  `}
`;

export const Modal = styled(SortModal)`
  left: 0;
`;
