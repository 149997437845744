import type { GetClientTransactionsResponse } from 'api/queries/transactions/getClientTransactions/types';
import type { WithoutNulls } from 'utils/withoutNulls';

type Input = NonNullable<
  NonNullable<GetClientTransactionsResponse['data']>
>[number];

export const normalizeClientTransactionDatum = (input: WithoutNulls<Input>) =>
  ({
    id: input.transactionId || 0,
    date: new Date(input.date || ''),
    asset: input.assetName || '',
    entity: input.entity || '',
    account: input.account || '',
    description: input.description || '',
    assetClass: input.instrumentType || '',
    category: input.category || '',
    amount: input.activity || 0,
    type: input.type === 'A' ? 'activity' : 'transaction',
    ticker: input.identifier || '',
    price: input.price || 0,
    shares: input.shares || 0,
  } as const);

export type ClientTransactionDatum = ReturnType<
  typeof normalizeClientTransactionDatum
>;
