import { FC, memo } from 'react';

import BaseStats from 'components/BaseStats';

import { useFormattedData } from './logic';
import type { Props } from './types';

const BondPerformanceAnalysisStats: FC<Props> = ({
  className,
  pathname,
  totalCost,
  couponPL,
  bondPL,
  couponsToMaturity,
  recoveryToPar,
  totalFinalPL,
  weightedAverage,
  currBondValue,
}) => {
  const cols = useFormattedData({
    totalCost,
    couponPL,
    bondPL,
    couponsToMaturity,
    recoveryToPar,
    totalFinalPL,
    weightedAverage,
    currBondValue,
  });

  return (
    <BaseStats
      className={className}
      cols={cols}
      pathname={pathname}
      buttontext="Bond Performance Analysis"
    />
  );
};

export default memo(BondPerformanceAnalysisStats);
