import { useMemo } from 'react';

import useAccessTokenQuery, {
  ACCESS_TOKEN_QUERY_KEY,
} from 'api/queries/auth/accessToken';
import { useQuery } from 'api/storage';

export default function useLoginStatus() {
  const { data } = useQuery(ACCESS_TOKEN_QUERY_KEY, {
    queryFn: useAccessTokenQuery(),
    staleTime: Infinity,
  });

  return useMemo(() => {
    if (!data) {
      return {
        type: 'loading',
      } as const;
    }

    if (!data.accessToken || !data.refreshToken) {
      return {
        type: 'not-logged-in',
      } as const;
    }

    if (!data.termsAgreed) {
      return {
        type: 'terms-not-agreed',
      } as const;
    }

    if (data.passwordResetData) {
      return {
        email: data.passwordResetData.email,
        token: data.passwordResetData.token,
        type: 'password-reset-required',
      } as const;
    }

    return {
      type: 'logged-in',
    } as const;
  }, [data]);
}
