import { IonContent, IonPage } from '@ionic/react';
import styled from 'styled-components';

import Button from 'components/Button';
import from from 'styles/responsive';

export const DesktopContainer = styled.main`
  padding: 3.375rem 2.5rem 1.25rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const MobileContainer = styled(IonPage)`
  width: 100vw;
`;

export const MobileContent = styled(IonContent).attrs({
  fullScreen: true,
  forceOverscroll: false,
})`
  --background: ${({ theme }) => theme.colors.dark2};
`;

export const DisclosuresLink = styled(Button)`
  display: block;
  margin: 0 1.25rem 1.25rem auto;
  text-align: right;
  font-size: 0.75rem;
  ${from.tablet`
    margin: 5rem 0 0 auto;
  `}
`;
