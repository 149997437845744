import { FC, memo } from 'react';

import from from 'styles/responsive';
import useMedia from 'utils/useMedia';

import DesktopVersion from './DesktopVersion';
import TabletAndMobileVersion from './TabletAndMobileVersion';
import type { Props } from './types';

const FixedIncomeAssetList: FC<Props> = ({
  data,
  selectedData,
  enabledTaxEquivalentYield = false,
  handleSortByColumn,
  sortApplied,
  headerColumns,
}) => {
  const isDesktop = useMedia(from.laptop.query);

  if (isDesktop) {
    return (
      <DesktopVersion
        data={data}
        enabledTaxEquivalent={enabledTaxEquivalentYield}
        handleSortByColumn={handleSortByColumn}
        sortApplied={sortApplied}
        headerColumns={headerColumns}
      />
    );
  }

  return (
    <TabletAndMobileVersion
      data={data}
      selectedData={selectedData}
      handleSortByColumn={handleSortByColumn}
      sortApplied={sortApplied}
      headerColumns={headerColumns}
    />
  );
};

export default memo(FixedIncomeAssetList);
