import useEmail from 'api/hooks/auth/useEmail';

import { useBiometricsControls } from '../logic';
import { useSubmitHandler } from './logic';

export default function useConnect() {
  const { data: email } = useEmail();

  const { loading, onSubmit, success } = useSubmitHandler();

  const {
    enabled: biometricsEnabled,
    onToggle: onToggleBiometrics,
    type: biometricType,
  } = useBiometricsControls();

  return {
    biometricsEnabled,
    biometricType,
    email,
    handle: {
      submit: onSubmit,
    },
    loading,
    onToggleBiometrics,
    success,
  };
}
