import type { GetMaturityProfileResponse } from 'api/queries/allocations/getMaturityProfile/types';
import kebabCaseCustom from 'utils/kebabCaseCustom';
import type { WithoutNulls } from 'utils/withoutNulls';

type Input = NonNullable<
  NonNullable<GetMaturityProfileResponse['data']>[number]['detail']
>[number];

export const normalizeMaturityProfileAsset = (
  input: WithoutNulls<Input>,
  parentId: string,
) =>
  ({
    fixedIncomeAllocation: input.fixedIncomePercent ?? 0,
    id: `${parentId}_${kebabCaseCustom(input.assetName ?? '')}`,
    name: input.assetName ?? '',
    portfolioAllocation: input.portfolioPercent ?? 0,
    value: input.total ?? 0,
  } as const);

export type MaturityProfileAsset = ReturnType<
  typeof normalizeMaturityProfileAsset
>;
