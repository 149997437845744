import { useMutation, useQueryClient } from 'react-query';

import useAcceptTermsConditionsMutation from 'api/mutations/acceptTermsConditions';
import {
  ACCESS_TOKEN_QUERY_KEY,
  AccessTokenQuery,
} from 'api/queries/auth/accessToken';

export default function useAcceptTermsConditions() {
  const client = useQueryClient();

  const { mutate } = useMutation(useAcceptTermsConditionsMutation(), {
    onSettled: () => {
      client.setQueryData<AccessTokenQuery>(
        ACCESS_TOKEN_QUERY_KEY,
        (state) => ({
          accessToken: state?.accessToken,
          email: state?.email,
          passwordResetData: undefined,
          refreshToken: state?.refreshToken,
          termsAgreed: true,
        }),
      );
    },
  });

  return { acceptTermsConditions: mutate };
}
