import styled from 'styled-components';

import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import Button from 'components/Button';
import from from 'styles/responsive';

export const RowBase = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 0.2fr;
  column-gap: 0.5rem;
  align-items: center;
  background: ${({ theme }) => theme.colors.white5};
  padding: 1.5rem 1.25rem;
  ${from.tablet`
    padding: 1rem 2rem;
  `}
`;

export const RowLink = styled(Button)``;

export const Col = styled.div`
  text-align: right;
`;

export const Chevron = styled(ChevronRight)``;
