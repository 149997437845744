import styled, { css } from 'styled-components';

import DefaultBaseListButton from 'components/BaseListButton';
import { Col, RowBase, RowLink } from 'components/BaseListButton/styles';
import DefaultOptionItem from 'components/OptionItem';
import { DefaultOptionItem as DefaultOptionItemContainer } from 'components/OptionItem/styles';
import from from 'styles/responsive';

import type { ButtonProps } from './types';

export const Container = styled.div``;

export const Settings = styled.div`
  display: grid;
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 1rem;
  overflow: hidden;
  ${RowBase} {
    padding: 1.25rem;
    margin-bottom: 0.125rem;
  }
  ${DefaultOptionItemContainer} {
    --border-style: none;
    --padding-top: 4px;
    --padding-bottom: 4px;
  }
  ${from.tablet`
    ${Col} {
      display: none;
    }
    ${RowBase} {
      grid-template-columns: 1fr;
      padding: 1.25rem;
      margin-bottom: 0.125rem;
    }
    ${RowLink} {
      :active {
        background-color: ${({ theme }) => theme.colors.primary25};
      }
  `}
`;

export const ListButton = styled(DefaultBaseListButton)<{
  $isActive?: ButtonProps['$isActive'];
}>`
  ${({ $isActive, theme }) =>
    $isActive &&
    css`
      ${from.tablet`
        ${RowBase} {
          background: ${theme.colors.primary25};
        }   
      `}
    `}
`;

export const SettingItem = styled.div<{
  $isActive?: ButtonProps['$isActive'];
}>`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 0.5rem;
  align-items: center;
  margin: 0;
  font-size: 1rem;
  line-height: 1.4rem;
`;

export const LogOutButton = styled(DefaultBaseListButton)`
  color: ${({ theme }) => theme.colors.statusDecrease};
`;

export const OptionItem = styled(DefaultOptionItem)`
  position: relative;
  z-index: 0;
  border-radius: 1rem;
  overflow: hidden;
`;

export const VersionItem = styled(SettingItem)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.white75};
`;
