import { FC, memo } from 'react';

import { DefaultToggle } from './styles';
import type { Props } from './types';

const Toggle: FC<Props> = ({
  checked,
  disabled,
  onChange,
  id,
  size = 'medium',
}) => (
  <DefaultToggle
    checked={checked}
    disabled={disabled}
    id={id}
    onIonChange={onChange}
    $size={size}
  />
);

export default memo(Toggle);
