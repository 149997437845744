/* eslint-disable react/no-array-index-key */
import type { FC } from 'react';

import { ReactComponent as Call } from 'assets/icons/call.svg';
import type { CoverageTeam as CoverageTeamModel } from 'model/CoverageTeam';

import useCoverageTeam from './logic';
import {
  Container,
  Email,
  H1,
  Info,
  Item,
  MembersTeam,
  Name,
  Phone,
  PhoneContainer,
  Title,
} from './styles';

const CoverageTeam: FC = () => {
  const { coverageTeam } = useCoverageTeam();

  const groupedCoverageTeam = coverageTeam
    ? coverageTeam.reduce(
        (grouped: { [key: string]: CoverageTeamModel[] }, item) => {
          const key = item.roleName || '';
          if (!grouped[key]) {
            // eslint-disable-next-line no-param-reassign
            grouped[key] = [];
          }
          grouped[key]?.push(item);
          return grouped;
        },
        {},
      )
    : undefined;

  return (
    <Container>
      <H1>Your CV Team</H1>
      {groupedCoverageTeam &&
        Object.entries(groupedCoverageTeam).map(
          ([roleName, teamMembers], roleIndex) =>
            teamMembers.filter(
              ({ email, fullName }) => email !== '' || fullName !== '',
            ).length > 0 && (
              <Item key={roleIndex}>
                <Title>{roleName}</Title>
                <MembersTeam>
                  {teamMembers.map(
                    ({ email, fullName, teamEmail }, memberIndex) => (
                      <Info key={memberIndex}>
                        <Name>{fullName}</Name>
                        <Email
                          href={`mailto:${email}${
                            teamEmail && `,${teamEmail}`
                          }`}
                        >
                          {email}
                        </Email>
                        {teamEmail && (
                          <Email href={`mailto:${email},${teamEmail}`}>
                            {teamEmail}
                          </Email>
                        )}
                      </Info>
                    ),
                  )}
                </MembersTeam>
              </Item>
            ),
        )}
      <PhoneContainer>
        <Phone href="tel:+1-305-358-5990">
          <Call /> +1-305-358-5990
        </Phone>
      </PhoneContainer>
    </Container>
  );
};

export default CoverageTeam;
