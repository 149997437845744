import { FC, memo, useCallback } from 'react';

import {
  Col,
  ColorAmount,
  ColorPercentage,
  Column,
  PinIcon,
} from '../../styles';
import { PinButton } from './styles';
import type { Props } from './types';

const InfoColumns: FC<Props> = ({ onPin, onUnpin, row, selectedData }) => {
  const handleTogglePinned = useCallback(() => {
    if (row.pinned) {
      onUnpin(row.name);
    } else {
      onPin(row.name);
    }
  }, [onPin, onUnpin, row]);

  switch (selectedData) {
    default:
    case 'Daily Change':
      return (
        <>
          <Column>
            <ColorPercentage value={row.dailyPercentChange} />
            <ColorAmount value={row.dailyProfitAndLoss} />
          </Column>
          <Col>
            <PinButton $isPinned={row.pinned} onClick={handleTogglePinned}>
              <PinIcon $isPinned={row.pinned} />
            </PinButton>
          </Col>
        </>
      );
    case 'MTD':
      return (
        <>
          <Column>
            <ColorPercentage value={row.mtdPercentChange} />
            <ColorAmount value={row.mtdProfitAndLoss} />
          </Column>
          <Col>
            <PinButton $isPinned={row.pinned} onClick={handleTogglePinned}>
              <PinIcon $isPinned={row.pinned} />
            </PinButton>
          </Col>
        </>
      );
    case 'YTD':
      return (
        <>
          <Column>
            <ColorPercentage value={row.ytdPercentChange} />
            <ColorAmount value={row.ytdProfitAndLoss} />
          </Column>
          <Col>
            <PinButton $isPinned={row.pinned} onClick={handleTogglePinned}>
              <PinIcon $isPinned={row.pinned} />
            </PinButton>
          </Col>
        </>
      );
  }
};

export default memo(InfoColumns);
