import { useRouteMatch } from 'react-router';

import useMarketOverview from 'api/hooks/markets/useMarketOverview';
import useMarketWatchlist from 'api/hooks/markets/useMarketWatchlist';
import { useCategorySlugs } from 'containers/Protected/Markets/MarketsRoot/MarketsDesktopTablet/logic';
import up from 'utils/paths';

import useMarketWatchlistActions from '../../../../api/hooks/markets/useMarketWatchlistActions';

export default function useConnect() {
  const {
    params: { categorySlug },
    url,
  } = useRouteMatch<{ categorySlug: string }>();
  const parentUrl = up(url, 2);

  const { categories } = useMarketOverview({
    categoryName: '',
    watchlistAssets: [],
  });

  const { getCategoryNameBySlug } = useCategorySlugs({
    categories,
  });

  const categoryName = getCategoryNameBySlug(categorySlug);

  const { data: watchlistData } = useMarketWatchlist();
  const { addToWatchlist, removeFromWatchlist } = useMarketWatchlistActions();
  const { data } = useMarketOverview({
    categoryName,
    watchlistAssets: watchlistData ?? [],
  });

  return {
    categoryName,
    data,
    handle: { addToWatchlist, removeFromWatchlist },
    parentUrl,
  };
}
