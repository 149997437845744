import styled from 'styled-components';

import DefaultAmount from 'components/Amount';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.dark1};
  border-bottom: 1px solid ${({ theme }) => theme.colors.white10};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
`;

export const TotalValue = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 1.75rem;
  margin: auto;

  > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

export const Amount = styled(DefaultAmount)`
  display: block;
  font-weight: ${({ theme }) => theme.weights.bold};
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.white75};
  font-size: 0.875rem;
`;

export const Info = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.white10};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 1.5rem;

  > *:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.colors.white10};
  }
`;

export const InfoValue = styled(TotalValue)`
  align-items: center;
  font-size: 1rem;
  padding: 0.75rem 1.25rem;
  width: 100%;

  > *:not(:last-child) {
    margin-bottom: 0;
  }
`;
