import { useCallback } from 'react';
import type { QueryFunction } from 'react-query';

import useRequest from 'api/request';
import {
  MonthlyAllocationDatum,
  normalizeMonthlyAllocationDatum,
} from 'model/MonthlyAllocationDatum';
import getPortfolioId from 'utils/getPorfolioId';
import withoutNulls from 'utils/withoutNulls';

import type {
  GetClientMonthlyAllocationByAssetNameChartDataRequest,
  GetClientMonthlyAllocationByAssetNameChartDataResponse,
} from '../types';

export const GET_CLIENT_MONTHLY_ALLOCATION_BYASSETNAME_CHART_DATA_QUERY_KEY = (
  portfolio: string,
) => ['charts/getClientMonthlyAllocationByAssetNameChartData', portfolio];

export default function useGetClientMonthlyAllocationByAssetNameChartDataQuery(): QueryFunction<
  Record<string, MonthlyAllocationDatum[]>
> {
  const request = useRequest<
    GetClientMonthlyAllocationByAssetNameChartDataRequest,
    GetClientMonthlyAllocationByAssetNameChartDataResponse
  >();

  return useCallback(
    async ({ queryKey }) => {
      const [, portfolio] = queryKey as [string, string];

      const response = await request(
        '/api/CVData/getClientMonthlyAllocation_EquitiesByAssetNameComplete/{portfolio}',
        {
          urlParams: {
            portfolio: getPortfolioId(portfolio) ?? '',
          },
        },
      );

      const data = await response.json();

      if (!data.isSuccess) {
        throw new Error(data.message ?? '');
      }

      const raw = withoutNulls(data.data ?? []);

      return (
        raw[0]?.subClientMonthlyAllocations?.reduce(
          (
            acc,
            { clientName: portfolioName = '', clientMonthlyAllocations = [] },
          ) => ({
            ...acc,
            [portfolioName]: clientMonthlyAllocations.map(
              normalizeMonthlyAllocationDatum,
            ),
          }),
          {} as Record<string, MonthlyAllocationDatum[]>,
        ) ?? {}
      );
    },
    [request],
  );
}
