import useGetAssetClassBreakdownQuery, {
  GET_ASSET_CLASS_BREAKDOWN_QUERY_KEY,
} from 'api/queries/home/getAssetClassBreakdown';
import { useQuery } from 'api/storage';
import { parseRequestDate } from 'utils/dates';

export default function useAssetClassBreakdown({
  period,
  portfolio,
  beginDate,
  endDate,
  lastUpdated,
}: {
  period: 'MTD' | 'YTD';
  portfolio: string | undefined;
  beginDate: Date | undefined;
  endDate: Date | undefined;
  lastUpdated: Date;
}) {
  let timeframeParseBegin = '';
  const auxTimeframeBegin = beginDate || new Date(lastUpdated);
  let timeframeParseEnd = '';
  const auxTimeframeEnd = endDate || new Date(lastUpdated);

  if (period === 'MTD') {
    timeframeParseBegin = parseRequestDate(auxTimeframeBegin);
    timeframeParseEnd = parseRequestDate(auxTimeframeEnd);
  } else if (period === 'YTD') {
    timeframeParseBegin = parseRequestDate(
      new Date(auxTimeframeBegin.getFullYear(), 0, 1),
    );
    timeframeParseEnd = parseRequestDate(
      auxTimeframeEnd.getFullYear() === new Date(lastUpdated).getFullYear()
        ? new Date(lastUpdated)
        : new Date(auxTimeframeEnd.getFullYear(), 11, 31),
    );
  }

  const { data } = useQuery(
    GET_ASSET_CLASS_BREAKDOWN_QUERY_KEY({
      portfolio: portfolio ?? '',
      beginDate: timeframeParseBegin,
      endDate: timeframeParseEnd,
    }),
    {
      enabled: !!portfolio,
      queryFn: useGetAssetClassBreakdownQuery(),
      keepPreviousData: true,
    },
  );

  return {
    data,
  };
}
