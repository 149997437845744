import type { FC } from 'react';
import { useCallback } from 'react';

import { Container, Content, Default, Link, Text } from './styles';
import type { Props } from './types';

const Portfolio: FC<Props> = ({
  id,
  isDefault,
  name,
  onChangeSelectedPortfolio,
  selectedPortfolioId,
}) => {
  const onClick = useCallback(() => {
    onChangeSelectedPortfolio(id);
  }, [id, onChangeSelectedPortfolio]);

  return (
    <Container>
      <Link key={id} onClick={onClick} variant="link">
        <Content>
          <Text $isSelected={id === selectedPortfolioId}>{name}</Text>
          {isDefault && <Default>Default</Default>}
        </Content>
      </Link>
    </Container>
  );
};

export default Portfolio;
