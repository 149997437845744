import { useCallback } from 'react';

import useRequest from 'api/request';
import withoutNulls from 'utils/withoutNulls';

import type {
  PostUserDefaultPortfolioRequest,
  PostUserDefaultPortfolioResponse,
} from './types';

export default function useSetUserDefaultPortfolio() {
  const request = useRequest<
    PostUserDefaultPortfolioRequest,
    PostUserDefaultPortfolioResponse
  >();

  return useCallback(
    async ({ portfolioName }: { portfolioName: string }) => {
      const response = await request(
        '/api/ClientData/setUserDefaultPortfolio',
        { method: 'POST', body: portfolioName },
      );
      const data = await response.json();
      if (!data.isSuccess) {
        throw new Error(data.message ?? 'Could not set the default portfolio');
      }
      return withoutNulls(data.data);
    },
    [request],
  );
}
