import { nanoid } from 'nanoid/non-secure';

import type { GetWinnersLosersResponse } from 'api/queries/home/getWinnersLosers/types';
import type { WithoutNulls } from 'utils/withoutNulls';

type Input = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<GetWinnersLosersResponse['data']>[number]
    >['winnerLosers']
  >[number]['winnerLosersList']
>[number];

export const normalizeWinnerLoser = (input: WithoutNulls<Input>) =>
  ({
    // The back-end doesn't send an ID. This is a first for me.
    // I believe generating our own IDs should be fine because the back-end data
    // doesn't change very often and the normalization will only run once per
    // request. These items have no detail page anyway so we should only need
    // the generated IDs for react keys, etc.
    id: nanoid(),
    name: input.assetName ?? '',
    percentChange: input.clientPnlChange ?? 0,
    profitAndLoss: input.clientPnL ?? 0,
    value: input.marketValue ?? 0,
  } as const);

export type WinnerLoser = ReturnType<typeof normalizeWinnerLoser>;
