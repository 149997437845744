import { useDataSelection, useRatings } from './logic';
import type { Props } from './types';

export default function useConnect({ assets }: { assets: Props['assets'] }) {
  const ratings = useRatings({ assets });
  const { dataOptions, ratingDetail, selectedData, setSelectedData } =
    useDataSelection({ ratings });

  return { dataOptions, ratings, ratingDetail, selectedData, setSelectedData };
}
