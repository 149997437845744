import { FC, memo } from 'react';

import { Container, Email, Title } from './styles';
import type { Props } from './types';

const HeaderProfile: FC<Props> = ({ className, email }) => (
  <Container className={className}>
    <Title>Profile</Title>
    <Email>{email}</Email>
  </Container>
);

export default memo(HeaderProfile);
