import styled from 'styled-components';

import DefaultCheckbox from 'components/Checkbox';

export const Container = styled.div`
  display: flex;
  cursor: pointer;
`;

export const Label = styled.span`
  text-size: 1rem;
  margin-left: 0.5rem;
`;

export const Checkbox = styled(DefaultCheckbox)`
  position: relative;
  z-index: 0;
  border-radius: 0 0 1rem 1rem;
  overflow: hidden;
`;
