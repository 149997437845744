import { FC, memo } from 'react';

import { formatPercentage } from 'utils/percentages';

import { Container, PercentageIcon, Value } from './styles';
import type { Props } from './types';

const Allocation: FC<Props> = ({ value }) => (
  <Container>
    <PercentageIcon />
    <Value>{formatPercentage(value)}</Value>
  </Container>
);

export default memo(Allocation);
