import { FC, memo } from 'react';

import {
  CaretDownIcon,
  DefaultButton,
  DefaultLink,
  IconContainer,
} from './styles';
import type { Props } from './types';

const Button: FC<Props> = ({
  children,
  className,
  color = 'primary',
  disabled,
  expand,
  href,
  onClick,
  size,
  target,
  type,
  variant = 'label',
  withCaretDown,
  withIcon,
}) => (
  <>
    {variant === 'link' ? (
      <DefaultLink
        className={className}
        $disabled={disabled}
        routerLink={href}
        onClick={onClick}
        target={target}
      >
        {children}
        {withCaretDown && <CaretDownIcon />}
      </DefaultLink>
    ) : (
      <DefaultButton
        $color={color}
        $size={size}
        $variant={variant}
        className={className}
        disabled={disabled}
        expand={expand}
        routerLink={href}
        onClick={onClick}
        target={target}
        type={type}
      >
        {withIcon && (
          <IconContainer $variant={variant} $size={size}>
            {withIcon}
          </IconContainer>
        )}
        {variant !== 'only-icon' && variant !== 'no-border' && (
          <>
            {children}
            {withCaretDown && <CaretDownIcon />}
          </>
        )}
      </DefaultButton>
    )}
  </>
);

export default memo(Button);
