import usePerformanceBreakdownQuery, {
  GET_PERFORMANCE_BREAKDOWN_QUERY_KEY,
} from 'api/queries/home/getPerformanceBreakdown';
import { useQuery } from 'api/storage';

export default function usePerformanceBreakdown({
  client,
  portfolioName,
}: {
  client: string | undefined;
  portfolioName: string | undefined;
}) {
  const { data } = useQuery(GET_PERFORMANCE_BREAKDOWN_QUERY_KEY(client ?? ''), {
    enabled: !!client,
    queryFn: usePerformanceBreakdownQuery(),
  });

  return {
    data: portfolioName ? data?.[portfolioName] : undefined,
  };
}
