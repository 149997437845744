import { FC, memo } from 'react';

import BaseStats from 'components/BaseStats';

import { useFormattedData } from './logic';
import type { Props } from './types';

const FixedIncomeStats: FC<Props> = ({
  annualIncome,
  className,
  coupon,
  duration,
  rating,
  timeToMaturity,
  pathname,
  yieldToWorst,
  enabledTaxEquvalentYield = false,
}) => {
  const cols = useFormattedData({
    annualIncome,
    coupon,
    duration,
    rating,
    timeToMaturity,
    yieldToWorst,
    enabledTaxEquvalentYield,
  });

  return (
    <BaseStats
      className={className}
      cols={cols}
      pathname={pathname}
      buttontext="Fixed Income Characteristics"
    />
  );
};

export default memo(FixedIncomeStats);
