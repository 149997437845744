import useGetIncomeProfileQuery, {
  GET_INCOME_PROFILE_QUERY_KEY,
} from 'api/queries/allocations/getIncomeProfile';
import { useQuery } from 'api/storage';

export default function useIncomeProfile(portfolio: string | undefined) {
  const { data } = useQuery(GET_INCOME_PROFILE_QUERY_KEY(portfolio ?? ''), {
    enabled: !!portfolio,
    queryFn: useGetIncomeProfileQuery(),
  });

  return {
    data,
  };
}
