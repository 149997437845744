import { useMemo } from 'react';

import useAssetClassBreakdownDetail from 'api/hooks/home/useAssetClassBreakdownDetail';
import useAssetClassBreakdownSummary from 'api/hooks/home/useAssetClassBreakdownSummary';
import usePerformanceBreakdown from 'api/hooks/home/usePerformanceBreakdown';
import type { AssetClassBreakdownAsset } from 'model/AssetClassBreakdownAsset';
import up from 'utils/paths';
import unreachable from 'utils/unreachable';
import type { SortOption } from 'utils/useDataManipulation/useEntitySorting';
import useEntitySorting from 'utils/useDataManipulation/useEntitySorting';

type AssetSortOption = SortOption<AssetClassBreakdownAsset>;

export function useCategory({
  categorySlug,
  compare,
  portfolio,
  reportSlug,
  timeFrame,
}: {
  categorySlug: string;
  compare: AssetSortOption['compare'];
  portfolio: string | undefined;
  reportSlug: string;
  timeFrame: string;
}) {
  const { data: summary } = useAssetClassBreakdownSummary({
    portfolio,
    reportSlug,
    timeFrame,
  });

  const { data: assets } = useAssetClassBreakdownDetail({
    portfolio,
    reportSlug,
    timeFrame,
  });

  return useMemo(() => {
    const category = summary?.find((it) => it.slug === categorySlug);

    if (!assets || !category) {
      return undefined;
    }

    const filteredAssets = assets.filter(
      (asset) => asset.instrumentType === category.name,
    );

    return {
      ...category,
      assets: compare ? [...filteredAssets].sort(compare) : filteredAssets,
    };
  }, [assets, categorySlug, compare, summary]);
}

export function useNavigation({
  categoryName,
  categorySlug,
  reportSlug,
  url,
}: {
  categoryName: string | undefined;
  categorySlug: string;
  reportSlug: string;
  url: string;
}) {
  const allReports = useMemo(
    () => [
      { label: 'Daily Estimate', slug: 'daily-estimate' },
      { label: 'Monthly Estimate', slug: 'month-to-date' },
      { label: 'Yearly Estimate', slug: 'year-to-date' },
      { label: 'Since Inception', slug: 'since-inception' },
    ],
    [],
  );

  const currentReport = useMemo(
    () =>
      allReports.find(({ slug }) => slug === reportSlug) ??
      unreachable({
        label: '',
        slug: '',
      }),
    [allReports, reportSlug],
  );

  const otherReports = useMemo(
    () => allReports.filter(({ slug }) => slug !== reportSlug),
    [allReports, reportSlug],
  );

  const hasTimeFrame = url.split('/').length === 7;

  const breadcrumbs = useMemo(
    () => [
      {
        name: currentReport.label,
        value: `${up(url, hasTimeFrame ? 3 : 2)}${hasTimeFrame ? '/' : ''}${
          hasTimeFrame ? url.split('/').pop() : ''
        }`,
      },
      {
        name: categoryName ?? '',
        value: url,
      },
    ],
    [categoryName, currentReport, hasTimeFrame, url],
  );

  const navLinks = useMemo(() => {
    const baseUrl = up(url, hasTimeFrame ? 4 : 3);

    return [
      ...otherReports.map((report) => ({
        name: report.label,
        value: `${baseUrl}/${report.slug}/detail/${categorySlug}`,
      })),
    ];
  }, [categorySlug, hasTimeFrame, otherReports, url]);

  return {
    breadcrumbs,
    navLinks,
  };
}

export function useReport({
  client,
  portfolioName,
  slug,
}: {
  client: string | undefined;
  portfolioName: string | undefined;
  slug: string;
}) {
  const { data: reports } = usePerformanceBreakdown({
    client,
    portfolioName,
  });

  return useMemo(
    () => reports?.find((it) => it.slug === slug),
    [reports, slug],
  );
}

// noinspection DuplicatedCode - This is abstract enough
export function useSorting() {
  const sortOptions: readonly [AssetSortOption, ...AssetSortOption[]] = useMemo(
    () => [
      {
        compare: (a, b) => b.profitAndLoss - a.profitAndLoss,
        label: 'Total: High to Low',
        value: 'default',
      },
      {
        compare: (a, b) => a.profitAndLoss - b.profitAndLoss,
        label: 'Total: Low to High',
        value: 'totalASC',
      },
      {
        compare: (a, b) => b.percentChange - a.percentChange,
        label: 'Total %: High to Low',
        value: 'totalPorcDESC',
      },
      {
        compare: (a, b) => a.percentChange - b.percentChange,
        label: 'Total %: Low to High',
        value: 'totalPorcASC',
      },
      {
        compare: (a, b) => a.name.localeCompare(b.name),
        label: 'Alphabetical: A to Z',
        value: 'alphabetical',
      },
      {
        compare: (a, b) => b.name.localeCompare(a.name),
        label: 'Alphabetical: Z to A',
        value: 'alphabeticalR',
      },
    ],
    [],
  );

  return useEntitySorting({ sortOptions });
}
