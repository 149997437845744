import type { FC } from 'react';

import ProfileMenu from 'components/ProfileMenu';
import from from 'styles/responsive';
import useMedia from 'utils/useMedia';

import {
  pathnameConditions,
  pathnameLogOut,
  pathnameNotifications,
  pathnamePassword,
  pathnameRoot,
} from '../constants';
import useConnect from './connect';
import {
  Container,
  Content,
  Description,
  Grid,
  Header,
  Note,
  NotificationItem,
  Option,
  Title,
} from './styles';
import type { Props } from './types';

const ProfileNotifications: FC<Props> = ({ path }) => {
  const {
    biometricsEnabled,
    biometricType,
    email,
    disabledSettings,
    dailyCloseEnabled,
    newsUpdateEnabled,
    notificationSettingsIsLoading,
    setNotificationSettingsIsLoading,
    onToggleBiometrics,
    handleDailyCloseChange,
    handleNewsUpdateChange,
  } = useConnect();
  const isTablet = useMedia(from.tablet.query);

  return (
    <Container
      title="Notifications"
      parentUrl={`${path}${pathnameRoot}`}
      withSubtitle={false}
    >
      <Grid>
        {isTablet && (
          <>
            <Header email={email} />
            <ProfileMenu
              biometricsEnabled={biometricsEnabled}
              biometricType={biometricType}
              onToggleBiometrics={onToggleBiometrics}
              currentPathname={`${path}${pathnameNotifications}`}
              pathnameConditions={`${path}${pathnameConditions}`}
              pathnameLogOut={`${path}${pathnameLogOut}`}
              pathnameNotifications={`${path}${pathnameNotifications}`}
              pathnamePassword={`${path}${pathnamePassword}`}
            />
          </>
        )}
        <Content>
          <NotificationItem>
            <Title>Daily close</Title>
            <Description>
              We will send you a push notification once a day when your
              portfolio data is updated.
            </Description>
            <Option
              optionType="toggle"
              id="daily-close-notification"
              value="daily-close-notification"
              checked={dailyCloseEnabled}
              label="Daily Close Notification"
              onChange={handleDailyCloseChange}
              disabled={disabledSettings}
            />
          </NotificationItem>
          <NotificationItem>
            <Title>News Update</Title>
            <Description>
              We will notify you of relevant news related to investments in your
              portfolio.
            </Description>
            <Option
              optionType="toggle"
              id="news-update-notification"
              value="news-update-notification"
              checked={newsUpdateEnabled}
              label="News Update Notification"
              onChange={handleNewsUpdateChange}
              disabled={disabledSettings}
            />
          </NotificationItem>
          {!notificationSettingsIsLoading &&
            !setNotificationSettingsIsLoading &&
            disabledSettings && (
              <Note>
                In order to activate notifications, it is necessary to give
                permissions to the CV Advisors application from your device
                settings.
              </Note>
            )}
        </Content>
      </Grid>
    </Container>
  );
};

export default ProfileNotifications;
