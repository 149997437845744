import styled, { css } from 'styled-components';

import { ReactComponent as CaretLeftIcon } from 'assets/icons/caret-left-blue.svg';
import BaseListButton from 'components/BaseListButton';
import { RowBase } from 'components/BaseListButton/styles';
import DefaultLink from 'components/Button';
import from from 'styles/responsive';

import type { FilterProps } from './types';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MobileContainer = styled(Container)``;

export const ButtonLinks = styled(Container)`
  border-radius: 1rem;
  overflow: hidden;
  > :not(:last-child) {
    margin-bottom: 2px;
  }
`;

export const Title = styled.h2`
  margin: 0 0 1.75rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  font-size: 1.375rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.colors.white};
  ${from.tablet`
    margin-bottom: 1.375rem;
    font-size: 0.625rem;
    line-height: 0.75rem;
    letter-spacing: 0.16em;
    font-weight: ${({ theme }) => theme.weights.regular};
    color: ${({ theme }) => theme.colors.white75};
    text-transform: uppercase;
  `}
`;

export const ButtonLink = styled(BaseListButton)`
  ${RowBase} {
    padding: 1.3125rem 1.25rem;
  }
  font-size: 1rem;
`;

export const Breadcrumbs = styled(Container)`
  margin-bottom: 1.625rem;
`;

export const Links = styled(Container)``;

export const Link = styled(DefaultLink)<{
  $isActive: FilterProps['$isActive'];
}>`
  margin-bottom: 1rem;
  font-size: 1rem;
  line-height: 1.1875rem;
  color: ${({ theme }) => theme.colors.white};
  opacity: 0.5;
  font-weight: ${({ theme }) => theme.weights.regular};
  transition: all 200ms ease;
  ${({ $isActive }) =>
    $isActive &&
    css`
      opacity: 1;
      font-weight: ${({ theme }) => theme.weights.medium};
      pointer-events: none;
      order: -1;
    `}
`;

export const Breadcrumb = styled.div`
  :not(:last-child) {
    margin-bottom: 0.75rem;
  }
`;

export const BreadcrumbLink = styled(Link)`
  ${({ $isActive }) =>
    $isActive &&
    css`
      pointer-events: none;
    `}
`;

export const CaretLeft = styled(CaretLeftIcon)<{
  $isActive: FilterProps['$isActive'];
}>`
  margin-right: 0.5625rem;
  ${({ $isActive }) =>
    $isActive &&
    css`
      display: none;
    `}
`;
