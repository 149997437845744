import { parse, stringify } from 'query-string';
import { useCallback, useMemo } from 'react';

import useAllocationGroups, {
  AllocationGroup,
} from 'api/hooks/allocations/useAllocationGroups';
import type { AssetGrouping } from 'model/AssetGrouping';
import { isValidGrouping } from 'model/AssetGrouping';
import useEntityFiltering from 'utils/useDataManipulation/useEntityFiltering';
import useEntitySorting, {
  SortOption,
} from 'utils/useDataManipulation/useEntitySorting';

import { getGroupUrl } from './pureLogic';

type GroupSortOption = SortOption<AllocationGroup>;

export function useCurrentGrouping({ search }: { search: string }) {
  return useMemo((): { groupBy: AssetGrouping; subselection?: string } => {
    const { browseBy, subselection } = parse(search);

    if (typeof browseBy === 'string' && isValidGrouping(browseBy)) {
      if (typeof subselection === 'string') {
        return { groupBy: browseBy, subselection };
      }

      return { groupBy: browseBy };
    }

    return {
      groupBy: 'asset-breakdown',
    };
  }, [search]);
}

export function useGroups({
  compare,
  groupBy,
  portfolio,
  search,
  subselection,
  url,
}: {
  compare: GroupSortOption['compare'];
  groupBy: AssetGrouping;
  portfolio: string | undefined;
  search: string;
  subselection: string | undefined;
  url: string;
}) {
  const { data } = useAllocationGroups({
    groupBy,
    portfolio,
    subselection,
  });

  const allocationChartData = useMemo(() => {
    const sorted = [...data].sort(
      (a, b) => b.totalAllocation - a.totalAllocation,
    );

    const [
      first,
      second,
      third,
      fourth,
      fifth,
      sixth,
      seventh,
      eighth,
      ninth,
      tenth,
      eleventh,
      twelfth,
      thirteenth,
      fourteenth,
      ...others
    ] = sorted;
    const firstFourteen = [
      first,
      second,
      third,
      fourth,
      fifth,
      sixth,
      seventh,
      eighth,
      ninth,
      tenth,
      eleventh,
      twelfth,
      thirteenth,
      fourteenth,
    ].filter((it): it is NonNullable<typeof it> => !!it);

    return [
      ...firstFourteen,
      ...(others.length >= 2
        ? [
            others.reduce(
              (acc, group) => ({
                ...acc,
                totalAllocation: acc.totalAllocation + group.totalAllocation,
                totalAssets: acc.totalAssets + group.totalAssets,
                totalValue: acc.totalValue + group.totalValue,
              }),
              {
                id: 'other',
                name: 'Other',
                slug: 'other',
                totalAllocation: 0,
                totalAssets: 0,
                totalValue: 0,
              },
            ),
          ]
        : others),
    ];
  }, [data]);

  const allocation = useMemo(
    () => data.reduce((acc, group) => acc + group.totalAllocation, 0),
    [data],
  );

  const balance = useMemo(
    () => data.reduce((acc, group) => acc + group.totalValue, 0),
    [data],
  );

  const sorted = useMemo(
    () => (compare ? [...data].sort(compare) : data),
    [compare, data],
  );

  const groups = useMemo(
    () =>
      sorted.map((group) => ({
        ...group,
        pathname: getGroupUrl({
          groupBy,
          groupSlug: group.slug,
          search,
          subselection,
          url,
        }),
      })),
    [groupBy, search, sorted, subselection, url],
  );

  return { allocationChartData, balance, groups, allocation };
}

export const groupingLabels: Record<AssetGrouping, string> = {
  'asset-breakdown': 'Asset Breakdown',
  country: 'Country',
  account: 'Account',
  custodian: 'Custodian',
  entity: 'Entity',
  sector: 'Sector',
};

export function useGroupLinks({
  getNameBySlug,
  groupBy,
  search,
  subselection,
  url,
}: {
  getNameBySlug: (grouping: AssetGrouping, slug: string) => string;
  groupBy: AssetGrouping;
  search: string;
  subselection: string | undefined;
  url: string;
}) {
  const getGrouping = useCallback(
    (grouping: AssetGrouping) => ({
      name: groupingLabels[grouping],
      value: `${url}?${stringify({
        ...parse(search),
        browseBy: grouping,
        subselection: undefined,
      })}`,
    }),
    [search, url],
  );

  // The current search without the grouping information
  const cleanSearch = useMemo(() => {
    const result = stringify({
      ...parse(search),
      browseBy: undefined,
      subselection: undefined,
    });

    return result.length > 0 ? `?${result}` : '';
  }, [search]);

  const breadcrumbs = useMemo(
    () =>
      subselection
        ? [
            getGrouping(groupBy),
            {
              name: getNameBySlug(groupBy, subselection),
              value: `${url}?${stringify({
                ...parse(search),
                browseBy: groupBy,
                subselection,
              })}`,
            },
          ]
        : [],
    [getGrouping, getNameBySlug, groupBy, search, subselection, url],
  );

  const groupLinks = useMemo(
    () =>
      [
        { name: 'Asset Breakdown', value: `${url}${cleanSearch}` },
        getGrouping('custodian'),
        getGrouping('account'),
        getGrouping('entity'),
        getGrouping('country'),
        getGrouping('sector'),
      ].filter((link) => link.value !== breadcrumbs[0]?.value),
    [breadcrumbs, cleanSearch, getGrouping, url],
  );

  const selectedGroupUrl =
    groupBy === 'asset-breakdown'
      ? `${url}${cleanSearch}`
      : `${url}?${stringify({
          ...parse(search),
          browseBy: groupBy,
          subselection,
        })}`;

  const pageTitle = subselection
    ? getNameBySlug(groupBy, subselection)
    : `Allocations by ${groupingLabels[groupBy]}`;

  const backUrl = subselection
    ? `${url}?${stringify({
        ...parse(search),
        browseBy: groupBy,
        subselection: undefined,
      })}`
    : `${url}${cleanSearch}`;

  return { backUrl, breadcrumbs, groupLinks, pageTitle, selectedGroupUrl };
}

// noinspection DuplicatedCode - This is abstract enough
export function useSorting() {
  const sortOptions: readonly [GroupSortOption, ...GroupSortOption[]] = useMemo(
    () => [
      {
        compare: (a, b) => b.totalValue - a.totalValue,
        label: 'Total: High to Low',
        value: 'default',
      },
      {
        compare: (a, b) => a.totalValue - b.totalValue,
        label: 'Total: Low to High',
        value: 'totalASC',
      },
      {
        compare: (a, b) => b.totalAllocation - a.totalAllocation,
        label: 'Total %: High to Low',
        value: 'totalPorcDESC',
      },
      {
        compare: (a, b) => a.totalAllocation - b.totalAllocation,
        label: 'Total %: Low to High',
        value: 'totalPorcASC',
      },
      {
        compare: (a, b) => a.name.localeCompare(b.name),
        label: 'Alphabetical: A to Z',
        value: 'alphabetical',
      },
      {
        compare: (a, b) => b.name.localeCompare(a.name),
        label: 'Alphabetical: Z to A',
        value: 'alphabeticalR',
      },
    ],
    [],
  );

  return useEntitySorting({ sortOptions });
}

export function useFiltering() {
  return useEntityFiltering({
    filterOptions: [
      {
        filter: () => true,
        label: 'All',
        value: 'default',
      },
    ],
  });
}
