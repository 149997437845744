import { curveMonotoneX } from '@visx/curve';
import { Group } from '@visx/group';
import { Bar } from '@visx/shape';
import type { FC } from 'react';
import { memo } from 'react';

import { AreaClosed, Path } from './styles';
import type { Props } from './types';

const Chart: FC<Props> = ({
  bottom,
  data,
  handleTooltipClosed,
  handleTooltipUpdated,
  left,
  right,
  top,
  x,
  y,
  yScale,
}) => (
  <Group left={left}>
    <AreaClosed
      curve={curveMonotoneX}
      data={data}
      x={x}
      y={y}
      yScale={yScale}
    />
    <Path curve={curveMonotoneX} data={data} x={x} y={y} />
    <Bar
      fill="transparent"
      height={bottom - top}
      onMouseLeave={handleTooltipClosed}
      onMouseMove={handleTooltipUpdated}
      onTouchMove={handleTooltipUpdated}
      onTouchStart={handleTooltipUpdated}
      width={Math.max(right - left, 0)}
      x={0}
      y={top}
    />
  </Group>
);

export default memo(Chart);
