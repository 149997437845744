import { FC, memo } from 'react';

import { Radio } from './styles';
import type { Props } from './types';

const RadioButton: FC<Props> = ({ disabled, id, value }) => (
  <Radio disabled={disabled} id={id} value={value} mode="md" />
);

export default memo(RadioButton);
