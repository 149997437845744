import type { FC } from 'react';
import { memo, useMemo } from 'react';

import { formatAmount } from 'utils/amounts';
import { formatMonth } from 'utils/dates';
import { formatPercentage } from 'utils/percentages';

import { calculateDifference } from './logic';
import { Date, Percentage, Price, TooltipWithBounds } from './styles';
import type { Props } from './types';

const Tooltip: FC<Props> = ({ anchorData, data, tooltipLeft, tooltipTop }) => {
  const difference = useMemo(() => {
    if (!anchorData) {
      return undefined;
    }

    if (anchorData.date > data.date) {
      return calculateDifference(data.value, anchorData.value);
    }

    return calculateDifference(anchorData.value, data.value);
  }, [anchorData, data]);

  const formattedDate = useMemo(() => {
    if (!anchorData) {
      return formatMonth(data.date);
    }

    if (anchorData.date > data.date) {
      return `${formatMonth(data.date)} to ${formatMonth(anchorData.date)}`;
    }

    return `${formatMonth(anchorData.date)} to ${formatMonth(data.date)}`;
  }, [anchorData, data]);

  return (
    <TooltipWithBounds top={tooltipTop} left={tooltipLeft}>
      <Date>{formattedDate}</Date>
      {typeof difference === 'undefined' ? (
        <Price>{formatAmount(data.value)}</Price>
      ) : (
        <Percentage $positive={difference >= 0}>
          {(difference === Infinity || difference === -Infinity) &&
          anchorData &&
          data
            ? // Handle the case of Infinity (i.e $0 -> $50) by simply outputting
              // the portfolio value
              formatAmount(
                anchorData.value > data.value
                  ? anchorData.value - data.value
                  : data.value - anchorData.value,
              )
            : formatPercentage(difference / 100)}
        </Percentage>
      )}
    </TooltipWithBounds>
  );
};

export default memo(Tooltip);
