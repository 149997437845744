import styled, { css } from 'styled-components';

import { ReactComponent as DefaultVectorDownIcon } from 'assets/icons/vector-down.svg';
import { ReactComponent as DefaultVectorUpIcon } from 'assets/icons/vector-up.svg';
import Amount from 'components/Amount';
import ChangePercentage from 'components/ChangePercentage';
import from from 'styles/responsive';

export const Table = styled.div`
  display: grid;
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.875rem;
  line-height: 1.0625rem;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  ${from.tablet`
    position: sticky;
    top: 0;
    background: ${({ theme }) => theme.colors.dark2};
    z-index: 1;
  `}
`;

export const Header = styled.div<{ $isSinceInception: boolean }>`
  flex: 1;
  display: grid;
  grid-template-columns: 1.5fr repeat(2, 0.4fr);
  column-gap: 0.5rem;
  padding: 1rem 1.5rem;
  color: ${({ theme }) => theme.colors.white75};
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  text-align: right;
  > :first-child {
    text-align: left;
  }
  ${from.laptop`
    grid-template-columns: 1.5fr repeat(2, 0.2fr);
  `}
  ${({ $isSinceInception }) =>
    $isSinceInception &&
    css`
      grid-template-columns: 1.5fr 0.4fr;
      ${from.laptop`
        grid-template-columns: 1.5fr 0.4fr;
     `}
    `};
`;

export const HeaderCell = styled.div``;

export const HeaderTitle = styled.span`
  cursor: pointer;
  display: inline-flex;
`;

export const Content = styled.div`
  ${from.tablet`
    > :nth-child(odd) {
      background: ${({ theme }) => theme.colors.dark1};
    }
  `}
`;

export const Row = styled.div<{ $isSinceInception: boolean }>`
  display: grid;
  grid-template-columns: 1.5fr 0.4fr;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 4px;
  column-gap: 0.5rem;
  align-items: center;
  padding: 1rem 0;
  margin: 0;
  font-size: 1rem;
  line-height: 1.1875rem;
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.white10};
  ${from.tablet`
    grid-template-columns: 1.5fr repeat(2, 0.4fr);
    grid-template-rows: 1fr;
    padding: 1rem 1.5rem;
  `}
  ${from.laptop`
    grid-template-columns: 1.5fr repeat(2, 0.2fr);
  `}
  ${({ $isSinceInception }) =>
    $isSinceInception &&
    css`
      ${from.tablet`
        grid-template-columns: 1.5fr 0.4fr;
     `}
    `};
`;

export const FirstCol = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 0 0.25rem;
  font-size: 1rem;
  line-height: 1.205rem;
  span {
    margin-top: 0.25rem;
    font-size: 0.875rem;
    line-height: 1.0625rem;
  }
  grid-row: span 2;
  ${from.tablet`
    grid-row: auto;
  `}
`;

export const AmountCol = styled(Amount).attrs({ coloured: true })`
  justify-self: end;
  grid-column: 2;
  grid-row: 2;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  ${from.tablet`
    grid-column: auto;
    grid-row: auto;
    font-size: 1rem;
    line-height: 1.205rem;
  `}
`;

export const ChangePercentageCol = styled(ChangePercentage)`
  justify-self: end;
`;

export const VectorDownIcon = styled(DefaultVectorDownIcon)``;

export const VectorUpIcon = styled(DefaultVectorUpIcon)``;
