import type { TickFormatter } from '@visx/axis';
import { Group } from '@visx/group';
import { FC, memo, useMemo } from 'react';
import useResizeObserver from 'use-resize-observer';

import { formatYear } from 'utils/dates';
import { formatPercentageWithDecimalsIfNecessary } from 'utils/percentages';
import useScaleBandBottomAxisTickRotation from 'utils/useScaleBandBottomAxisTickRotation';

import BarChart from './BarChart';
import Legend from './Legend';
import LineChart from './LineChart';
import {
  getDate,
  useEdgeValues,
  useHeight,
  useMargins,
  useScales,
  useTooltip,
} from './logic';
import {
  AxisBottom,
  AxisLeft,
  Container,
  GraphVector,
  Grid,
  Title,
} from './styles';
import Tooltip from './Tooltip';
import type { Props } from './types';

const MaturityProfileChart: FC<Props> = ({
  className,
  data: readonlyData,
  selectDate,
}) => {
  // visx wants mutable arrays even though it will never mutate them
  const data = useMemo(() => [...readonlyData], [readonlyData]);
  const { ref, width = 0 } = useResizeObserver();
  const height = useHeight();

  const {
    bottomAxisHeight,
    bottomAxisMargin,
    leftAxisMargin,
    leftAxisWidth,
    rightMargin,
    topMargin,
  } = useMargins();

  const { end, maxValue, minValue, start } = useEdgeValues(data);

  const { xScale, yScale, cumulativeXScale } = useScales({
    bottomAxisHeight,
    bottomAxisMargin,
    data,
    end,
    height,
    leftAxisMargin,
    leftAxisWidth,
    maxValue,
    minValue,
    rightMargin,
    start,
    topMargin,
    width,
  });

  const {
    handleTooltipClosed,
    handleTooltipUpdated,
    tooltipData,
    tooltipLeft,
    tooltipTop,
  } = useTooltip({
    xScale,
    yScale,
    height: topMargin + bottomAxisHeight,
    width,
    leftAxisMargin,
    leftAxisWidth,
  });

  const { bottomGroupRef, vertical } = useScaleBandBottomAxisTickRotation(
    width,
    xScale,
  );

  return (
    <Container ref={ref} className={className}>
      <GraphVector width="100%" viewBox={`0 0 ${width} ${height}`}>
        <Grid
          scale={yScale}
          width={width - leftAxisWidth - leftAxisMargin - rightMargin}
          left={leftAxisWidth + leftAxisMargin}
        />
        <AxisLeft
          left={leftAxisWidth}
          scale={yScale}
          tickFormat={
            formatPercentageWithDecimalsIfNecessary as TickFormatter<unknown>
          }
        />
        <Group innerRef={bottomGroupRef}>
          <AxisBottom
            $vertical={vertical}
            left={0}
            tickValues={data.map(getDate)}
            tickFormat={formatYear}
            scale={xScale}
            top={height - bottomAxisHeight}
          />
        </Group>
        <Title
          $x={34}
          $y={height / 2}
          dominantBaseline="central"
          textAnchor="middle"
        >
          Total Allocation (%)
        </Title>
        <BarChart
          data={data}
          xScale={xScale}
          yScale={yScale}
          bottomHeight={height - bottomAxisHeight - bottomAxisMargin}
          handleTooltipClosed={handleTooltipClosed}
          handleTooltipUpdated={handleTooltipUpdated}
          selectDate={selectDate}
        />
        <LineChart
          data={data}
          xScale={cumulativeXScale}
          yScale={yScale}
          handleTooltipClosed={handleTooltipClosed}
          handleTooltipUpdated={handleTooltipUpdated}
        />
      </GraphVector>
      <Legend keys={['allocation', 'cumulative']} />
      {typeof tooltipData !== 'undefined' &&
        typeof tooltipLeft === 'number' &&
        typeof tooltipTop === 'number' && (
          <Tooltip
            data={tooltipData}
            tooltipLeft={tooltipLeft}
            tooltipTop={tooltipTop}
          />
        )}
    </Container>
  );
};

export default memo(MaturityProfileChart);
