/* eslint-disable import/prefer-default-export */
/*
 * This "pure logic" file exists as a workaround for Jest not being able to
 * parse the import of Identity Vault. Enabling babel so the IV code can be
 * transpiled would be quite hard as the jest config is abstracted away by
 * react-scripts.
 *
 * It would also be pointless as Jest shouldn't have to import IV in the first
 * place, but if the function inside this file lived in the "logic.ts" file, it
 * would accidentally end up importing it due to the chain of imports:
 *
 * useAllocationGroups > useAllocations > useQuery > useVault > IV
 *
 * The easy workaround is to isolate the "pure" functions (as in, they don't
 * have any runtime dependency in the application code) so that the chain of
 * imports is broken.
 */
import { parse, stringify } from 'query-string';

import type { AssetGrouping } from 'model/AssetGrouping';
import searchWithoutSort from 'utils/searchWithoutSort';

export function getGroupUrl({
  groupBy,
  groupSlug,
  search,
  subselection,
  url,
}: {
  groupBy: AssetGrouping;
  groupSlug: string;
  search: string;
  subselection: string | undefined;
  url: string;
}) {
  const searchAux = searchWithoutSort(search);

  if (groupBy === 'asset-breakdown') {
    return `${url}/categories/${groupSlug}${searchAux}`;
  }

  if (!subselection) {
    return `${url}?${stringify({
      ...parse(searchAux),
      browseBy: groupBy,
      subselection: groupSlug,
    })}`;
  }

  return `${url}/categories/${groupSlug}?${stringify({
    ...parse(searchAux),
    browseBy: groupBy,
    subselection,
  })}`;
}
