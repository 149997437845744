import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQueryClient } from 'react-query';

import {
  ACCESS_TOKEN_QUERY_KEY,
  AccessTokenQuery,
} from 'api/queries/auth/accessToken';
import env from 'utils/env';
import usePlatform from 'utils/usePlatform/usePlatform';

import { MaintenanceModeContextModel, MaintenanceModeStatus } from './types';

const Context = createContext<MaintenanceModeContextModel | undefined>(
  undefined,
);

const MaintenanceModeProvider: FC = ({ children }) => {
  const queryClient = useQueryClient();
  const [isMaintenanceMode, setMaintenanceMode] = useState<boolean>(false);
  const { isWeb } = usePlatform();

  useEffect(() => {
    const fetchMaintenanceMode = async () => {
      const healthcheck = await fetch(
        `${env('REACT_APP_HEALTHCHECK_PATH')}`,
      ).then(async (res) => res.json());

      let exitMaintenanceMode = false;

      if (
        isMaintenanceMode &&
        healthcheck.status === MaintenanceModeStatus.RUNNING
      ) {
        exitMaintenanceMode = true;
      }

      setMaintenanceMode(healthcheck.status !== MaintenanceModeStatus.RUNNING);

      if (exitMaintenanceMode && isWeb) {
        queryClient.setQueryData<AccessTokenQuery>(ACCESS_TOKEN_QUERY_KEY, {
          accessToken: undefined,
          email: undefined,
          passwordResetData: undefined,
          refreshToken: undefined,
          termsAgreed: false,
        });

        setTimeout(() => window.location.reload(), 100);
      }
    };

    void fetchMaintenanceMode();

    const intervalId = setInterval(() => {
      void fetchMaintenanceMode();
    }, 60000);

    return () => clearInterval(intervalId);
  }, [isMaintenanceMode, isWeb, queryClient]);

  const value = useMemo(
    () => ({
      isMaintenanceMode,
      setMaintenanceMode,
    }),
    [isMaintenanceMode],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export function useMaintenanceMode() {
  return useContext(Context);
}

export default MaintenanceModeProvider;
