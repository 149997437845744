import { FC, memo } from 'react';

import Amount from 'components/Amount';
import ChangePercentage from 'components/ChangePercentage';
import from from 'styles/responsive';
import useMedia from 'utils/useMedia';

import {
  BaseListButton,
  Col,
  Content,
  FirstCol,
  Header,
  HeaderCell,
  HeaderContainer,
  HeaderTitle,
  Row,
  Table,
  VectorDownIcon,
  VectorUpIcon,
} from './styles';
import type { Props } from './types';

const AssetClassBreakdownList: FC<Props> = ({
  className,
  data,
  timeFrame,
  isSinceInception,
  handleSortByColumn,
  sortApplied,
  headerColumns,
}) => {
  const isTablet = useMedia(from.tablet.query);

  return (
    <Table className={className}>
      {isTablet && (
        <HeaderContainer>
          <Header $isSinceInception={isSinceInception}>
            {headerColumns.map((col) => (
              <HeaderCell key={col.titleColumn}>
                <HeaderTitle
                  onClick={() =>
                    handleSortByColumn ? handleSortByColumn(col) : undefined
                  }
                >
                  {col.titleColumn}
                  {sortApplied?.titleColumn === col.titleColumn &&
                    (sortApplied?.orderOrientation === 'DESC' ? (
                      <VectorDownIcon />
                    ) : (
                      <VectorUpIcon />
                    ))}
                </HeaderTitle>
              </HeaderCell>
            ))}
          </Header>
        </HeaderContainer>
      )}
      <Content>
        {data.map((row) => (
          <BaseListButton
            key={row.id}
            pathname={`${row.pathname}/${timeFrame}`}
          >
            <Row $isSinceInception={isSinceInception}>
              <FirstCol>{row.name}</FirstCol>
              <Col>
                <Amount value={row.profitAndLoss} />
              </Col>
              {!isSinceInception && (
                <Col>
                  <ChangePercentage value={row.percentChange} />
                </Col>
              )}
            </Row>
          </BaseListButton>
        ))}
      </Content>
    </Table>
  );
};

export default memo(AssetClassBreakdownList);
