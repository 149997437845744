import { useCallback } from 'react';

import useRequest from 'api/request';
import withoutNulls from 'utils/withoutNulls';

import type {
  PostNewsReadStatusRequest,
  PostNewsReadStatusResponse,
} from './types';

export default function useUpdateNewsReadStatusForEmail() {
  const request = useRequest<
    PostNewsReadStatusRequest,
    PostNewsReadStatusResponse
  >();

  return useCallback(
    async ({ email, messageID }: { email: string; messageID: number }) => {
      const response = await request('/api/SNSData/updateNewsReadStatus', {
        method: 'POST',
        body: { email, messageID },
      });
      const data = await response.json();
      if (!data.isSuccess) {
        throw new Error(
          data.message ??
            `Could not upadte read status for message ${messageID}`,
        );
      }
      return withoutNulls(data.data);
    },
    [request],
  );
}
