import styled, { css } from 'styled-components';

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 1.5rem 0;
`;

export const PageItem = styled.div<{ $active: boolean }>`
  width: 1.5rem;
  height: 1.5rem;
  font-size: 0.75rem;
  margin: 0 0.25rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.primary};
  border: 1px solid ${({ theme }) => theme.colors.primary};

  ${({ $active, theme }) =>
    $active
      ? css`
          cursor: default;
          background: ${theme.colors.primary};
          color: ${theme.colors.white};
        `
      : css`
          &:hover {
            background: ${theme.colors.primary50};
            color: ${theme.colors.white50};
            border: 1px solid ${theme.colors.primary50};
          }
        `}
`;

export const PointsPage = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.primary};
  margin: 0 0.35rem;
`;

export const NavigationLink = styled.div<{ $disabled: boolean }>`
  display: flex;
  font-size: 0.875rem;
  align-items: center;
  margin: 0 1rem;
  line-height: 1.1;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.weights.regular};

  ${({ $disabled }) =>
    $disabled &&
    css`
      visibility: hidden;
    `}

  &:hover {
    color: ${({ theme }) => theme.colors.primary75};

    & path {
      fill: ${({ theme }) => theme.colors.primary75};
    }
  }
`;
