import { nanoid } from 'nanoid/non-secure';

import type { WithoutNulls } from 'utils/withoutNulls';

import type { GetAssetClassBreakdownDetailResponse } from '../api/queries/home/getAssetClassBreakdownDetail/types';

type Input = NonNullable<
  NonNullable<GetAssetClassBreakdownDetailResponse['data']>[number]
>;

export const normalizeAssetClassBreakdownAsset = (input: WithoutNulls<Input>) =>
  ({
    currentBalance: input.currentBalance ?? 0,
    // The back-end doesn't send an ID. This is a first for me.
    // I believe generating our own IDs should be fine because the back-end data
    // doesn't change very often and the normalization will only run once per
    // request. These items have no detail page anyway so we should only need
    // the generated IDs for react keys, etc.
    id: nanoid(),
    instrumentType: input.instrumentType ?? '',
    name: input.assetName ?? '',
    percentChange: input.pctChange ?? 0,
    profitAndLoss: input.pnL ?? 0,
  } as const);

export type AssetClassBreakdownAsset = ReturnType<
  typeof normalizeAssetClassBreakdownAsset
>;
