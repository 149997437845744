import { FC, memo } from 'react';

import type { Props } from '../types';
import {
  BlueTickIcon,
  Container,
  ContainerFooter,
  ContainerMain,
  DateItem,
  DateSourceItem,
  DescriptionItem,
  ImagenContainer,
  MarkAsReadButton,
  MarkAsUnreadButton,
  SourceItem,
} from './styles';

const NewsCardMobile: FC<Props> = ({
  messageID,
  url,
  message,
  date,
  source,
  image,
  className,
  isRead,
  onUpdateNewsReadStatus,
}) => {
  const openInNewTab = () => {
    window.open(url, '_blank', 'noreferrer');

    if (!isRead) {
      onUpdateNewsReadStatus(messageID);
    }
  };

  return (
    <Container $isRead={isRead}>
      <ContainerMain onClick={openInNewTab}>
        <DescriptionItem>{message}</DescriptionItem>
        {image && <ImagenContainer $url={image} onClick={openInNewTab} />}
      </ContainerMain>
      <ContainerFooter>
        <DateSourceItem>
          <DateItem>{date}</DateItem>
          <SourceItem>{source}</SourceItem>
        </DateSourceItem>
        {!isRead ? (
          <MarkAsReadButton
            className={className}
            onClick={() => onUpdateNewsReadStatus(messageID)}
          >
            Unread
          </MarkAsReadButton>
        ) : (
          <MarkAsUnreadButton
            className={className}
            onClick={() => onUpdateNewsReadStatus(messageID)}
          >
            <BlueTickIcon />
            Read
          </MarkAsUnreadButton>
        )}
      </ContainerFooter>
    </Container>
  );
};

export default memo(NewsCardMobile);
