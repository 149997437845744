import { TooltipWithBounds as DefaultTooltipWithBounds } from '@visx/tooltip';
import styled from 'styled-components';

import { tooltipStyles } from 'components/Tooltip/styles';

export const TooltipWithBounds = styled(DefaultTooltipWithBounds).attrs({
  offsetLeft: 16,
  offsetTop: -10,
  // We disable the default @visx/tooltip styles as they are applied at the
  // element level and can't be overridden.
  unstyled: true,
})`
  // We rescue some styles defined by @visx/tooltip, which we disabled above.
  pointer-events: none;
  position: absolute;

  // Custom styles
  ${tooltipStyles};
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: 0.25rem;
  }
`;

export const Days = styled.span`
  color: ${({ theme }) => theme.colors.white75};
  font-size: 0.75rem;
  line-height: 0.875rem;
`;

export const Change = styled.span`
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  line-height: 1.0625rem;
`;
