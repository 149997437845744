import styled, { css } from 'styled-components';

import Amount from 'components/Amount';
import Select from 'components/Select';
import DefaultSpinner from 'components/Spinner';
import Tabs from 'components/Tabs';
import MainContentGrid from 'containers/Common/MainContentGrid';
import PageDetailLayout from 'containers/Common/PageDetailLayout';
import from from 'styles/responsive';

export const LoaderContainer = styled.div`
  position: sticky;
  top: 50%;
  padding-left: 50%;
  z-index: 1000;
`;

export const Container = styled(PageDetailLayout)<{
  $loading: boolean;
  $mobile: boolean;
}>`
  ${({ $loading }) =>
    $loading &&
    css`
      pointer-events: none;
      filter: blur(1px);
    `};

  ${({ $loading, $mobile }) =>
    $loading &&
    !$mobile &&
    css`
      margin-top: -4.5rem;
    `};

  ${from.tablet`
    padding: 3.875rem 2.5rem 1.25rem;
  `}
`;

export const MobileContent = styled.div`
  padding: 0 1.25rem 1.25rem;
  ${from.tablet`
    display: contents;
`}
`;

export const MainGrid = styled(MainContentGrid)``;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.0313rem 0;
  background-color: ${({ theme }) => theme.colors.dark1};
  border-bottom: 1px solid ${({ theme }) => theme.colors.white10};
  ${from.tablet`
    display: block;
    padding: 0;
    grid-column: 1/3;
    margin-bottom: 2rem;
    background-color: transparent;
    border-bottom: none;
  `}
`;

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderTitleContainer = styled(RowContainer)`
  display: none;
  ${from.tablet`
    display: flex;
  `}
`;

export const Title = styled.h2`
  margin: 0 0.5rem 0.25rem 0;
  font-size: 1.375rem;
  line-height: 1.4;
  font-weight: ${({ theme }) => theme.weights.regular};
`;

export const HeaderAmount = styled(Amount)`
  margin: 0 0 0.5rem;
  font-size: 1.75rem;
  line-height: 2.4375rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  ${from.tablet`
    margin: 0 0 0.25rem;
  `}
`;

export const Subtitle = styled.h3`
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: ${({ theme }) => theme.colors.white75};
  font-weight: ${({ theme }) => theme.weights.regular};
`;

export const ROCTitle = styled.h4`
  margin: 0 0.5rem 0 0;
  font-size: 1rem;
  line-height: 1.1875rem;
  color: ${({ theme }) => theme.colors.white75};
  font-weight: ${({ theme }) => theme.weights.regular};
`;

export const TableTabs = styled(Tabs)`
  ${from.tablet`
    flex: 0;
    width: fit-content;
  `}
`;

export const AssetClassListContainer = styled.div``;

export const TableTitle = styled.h3`
  margin: 2rem 0;
  font-size: 1.125rem;
  line-height: 1.4;
  font-weight: ${({ theme }) => theme.weights.regular};
  ${from.tablet`
    margin: 0;
  `}
`;

export const Toolbar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.5rem;
  ${from.laptop`
    flex-direction: row;
    align-items: center;
  `}
`;

export const ToolbarTitle = styled.h3`
  margin: 0 0 1.5rem;
  font-size: 1.125rem;
  line-height: 1.4;
  font-weight: ${({ theme }) => theme.weights.regular};
  ${from.laptop`
    margin: 0;
    font-size: 1.375rem;
  `}
`;

export const WinnersLosersTableContainer = styled.div`
  padding: 2rem 0 2rem;
  ${from.tablet`
    grid-column: 2;
    padding: 2.5rem 0 2rem;
  `}
`;

export const ActionZone = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${from.tablet`
    justify-content: flex-end;
  `}
`;

export const FilterButton = styled(Select)`
  width: fit-content;
  margin-left: 0.75rem;
`;

export const Spinner = styled(DefaultSpinner)``;
