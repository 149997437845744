import styled from 'styled-components';

import { Content as DefaultContent } from 'components/PasswordResetRequestForm/styles';

export const Content = styled(DefaultContent)`
  align-items: flex-start;
  padding: 0 1.25rem;
`;

export {
  BackButton,
  Chevron,
  Container,
  Paragraph,
  Title,
} from 'components/PasswordResetRequestForm/styles';
