import type { FC } from 'react';

import from from 'styles/responsive';
import useMedia from 'utils/useMedia';

import {
  Amount,
  Container,
  DistributionChart,
  Title,
  TitleContainer,
} from './styles';
import type { Props } from './types';

const AllocationsHeader: FC<Props> = ({
  className,
  chartData,
  title,
  amount,
  allocation,
  modalOpen = false,
}) => {
  const isTablet = useMedia(from.tablet.query);

  return (
    <Container className={className} $modalOpen={modalOpen}>
      <TitleContainer>
        {!isTablet && allocation ? allocation : <Title>{title}</Title>}
        <Amount value={amount} />
      </TitleContainer>
      {isTablet && chartData && <DistributionChart data={chartData} />}
    </Container>
  );
};

export default AllocationsHeader;
