import type { FC } from 'react';

import type { Props } from '../types';
import { Amount, Card, Container, Label } from './styles';

const DesktopAndTabletVersion: FC<Props> = ({
  className,
  equity,
  leverage,
  total,
}) => (
  <Container className={className}>
    <Card>
      <Amount value={total} />
      <Label>Portfolio Balance</Label>
    </Card>
    <Card $disabled={leverage === 0}>
      <Amount value={leverage} />
      <Label>Leverage</Label>
    </Card>
    <Card $disabled={equity === 0}>
      <Amount value={equity} />
      <Label>Portfolio Equity</Label>
    </Card>
  </Container>
);

export default DesktopAndTabletVersion;
