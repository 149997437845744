import type { FC } from 'react';
import { useCallback } from 'react';

import { Container, LaptopTabs } from './styles';
import type { Props, TypeTransactions } from './types';

const typeTransactionsOptions = [
  {
    label: 'All',
    value: 'all' as TypeTransactions,
  },
  {
    label: 'Activity',
    value: 'activity' as TypeTransactions,
  },
  {
    label: 'Transactions',
    value: 'transaction' as TypeTransactions,
  },
];

const ControlTabTypeTransactions: FC<Props> = ({
  onChange,
  selectedTypeTransactions,
}) => {
  const handleTypeChange = useCallback(
    (position: TypeTransactions) => {
      onChange(position);
    },
    [onChange],
  );

  return (
    <Container>
      <LaptopTabs<TypeTransactions>
        onChange={handleTypeChange}
        value={selectedTypeTransactions}
        tabs={typeTransactionsOptions}
      />
    </Container>
  );
};

export default ControlTabTypeTransactions;
