import type { FC } from 'react';
import { memo } from 'react';

import from from 'styles/responsive';
import useMedia from 'utils/useMedia';

import DesktopAndTabletVersion from './DesktopAndTabletVersion';
import MobileVersion from './MobileVersion';
import type { Props } from './types';

const PortfolioBalance: FC<Props> = ({
  className,
  equity,
  leverage,
  total,
}) => {
  const isMobile = !useMedia(from.tablet.query);

  if (isMobile) {
    return <MobileVersion equity={equity} leverage={leverage} total={total} />;
  }

  return (
    <DesktopAndTabletVersion
      className={className}
      equity={equity}
      leverage={leverage}
      total={total}
    />
  );
};

export default memo(PortfolioBalance);
