import { Group } from '@visx/group';
import { Bar } from '@visx/shape';
import { memo } from 'react';
import type { FC } from 'react';

import { CustonBar } from './styles';
import type { Props } from './types';

const BarChart: FC<Props> = ({
  data,
  height,
  width,
  top,
  left,
  xScale,
  yScale,
  handleTooltipClosed,
  handleTooltipUpdated,
}) => (
  <Group>
    {data.map((datum) => (
      <CustonBar
        key={`bar-${datum.date}`}
        id={`bar-${datum.date}`}
        x={xScale(datum.date) ?? 0}
        width={width / data.length}
        y={yScale(datum.range.upperBound)}
        height={
          height - yScale(datum.range.upperBound - datum.range.lowerBound)
        }
      />
    ))}
    <Bar
      fill="transparent"
      height={height - top}
      onMouseLeave={handleTooltipClosed}
      onMouseMove={handleTooltipUpdated}
      onTouchMove={handleTooltipUpdated}
      onTouchStart={handleTooltipUpdated}
      width={width}
      x={left}
      y={top}
    />
  </Group>
);

export default memo(BarChart);
