import type { FC } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import type { Props } from './types';

interface PortfolioSwitcherContextType {
  onOpenPortfolioSwitcher: () => void;
  onClosePortfolioSwitcher: () => void;
  isOpen: boolean;
}

const Context = createContext<PortfolioSwitcherContextType>({
  onOpenPortfolioSwitcher: () => {},
  onClosePortfolioSwitcher: () => {},
  isOpen: false,
});

export function usePortfolioSwitcher() {
  return useContext(Context);
}

const PortfolioSwitcherContext: FC<Props> = ({ children, menuRef }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpenPortfolioSwitcher = useCallback(() => {
    void menuRef.current?.open();
    setIsOpen(true);
  }, [menuRef]);

  const onClosePortfolioSwitcher = useCallback(() => {
    void menuRef.current?.close();
    setIsOpen(false);
  }, [menuRef]);

  const value = useMemo(
    () => ({
      onOpenPortfolioSwitcher,
      onClosePortfolioSwitcher,
      isOpen,
    }),
    [onOpenPortfolioSwitcher, onClosePortfolioSwitcher, isOpen],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default PortfolioSwitcherContext;
