import { Form as DefaultForm } from 'formik';
import styled from 'styled-components';

import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
import DefaultButton from 'components/Button';
import { Button as LoginFormButton } from 'components/LoginForm/styles';
import from from 'styles/responsive';

export { Inputs } from 'components/LoginForm/styles';

export const Container = styled.div`
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding-top: 3rem;

  ${from.tablet`
    max-width: 21.5rem;
    margin: auto;
    padding-top: 0;
  `}
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;

  ${from.tablet`
    justify-content: space-around;
    height: fit-content;
  `};
`;

export const Form = styled(DefaultForm)`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 1.25rem 4rem;
  width: 100%;

  ${from.tablet`
    padding: 0;
  `}
`;

export const BackButton = styled(DefaultButton).attrs({
  color: 'secondary',
  size: 'small',
  variant: 'only-icon',
})`
  align-self: self-start;
`;

export const Chevron = styled(ChevronLeft)`
  margin-top: 0.125rem;
`;

export const Title = styled.h1`
  font-size: 1.125rem;
  font-style: normal;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: 140%;
`;

export const Paragraph = styled.p`
  line-height: 160%;
`;

export const SubmitButton = styled(LoginFormButton)`
  margin-top: auto;
`;
