import type { TickFormatter } from '@visx/axis';
import { FC, memo, useEffect, useMemo } from 'react';
import useResizeObserver from 'use-resize-observer';

import { useScreenOrientation } from 'containers/Services/ScreenOrientationService';
import from from 'styles/responsive';
import { formatAmountShort } from 'utils/amounts';
import { formatMonth } from 'utils/dates';
import useMedia from 'utils/useMedia';

import BarChart from './BarChart';
import Legend from './Legend';
import {
  getDate,
  useEdgeValues,
  useHeight,
  useMargins,
  useScales,
  useTooltip,
} from './logic';
import {
  AreaGradient,
  AxisBottom,
  AxisLeft,
  BackLink,
  Chevron,
  Container,
  GraphVector,
  Grid,
  Title,
} from './styles';
import Tooltip from './Tooltip';
import type { Props } from './types';

const ClientMonthlyAllocationBySubtypeChart: FC<Props> = ({
  className,
  data: readonlyData,
  filter,
  handleBackToAllocationHistory,
  isVisible = true,
  onChangeTooltip,
}) => {
  // visx wants mutable arrays even though it will never mutate them
  const data = useMemo(
    () => (Array.isArray(readonlyData) ? [...readonlyData] : []),
    [readonlyData],
  );

  const keySet = new Set<string>();
  data.forEach((item) => {
    Object.keys(item).forEach((key) => {
      if (key !== 'id' && key !== 'date') {
        keySet.add(key);
      }
    });
  });

  const barKeys: string[] = Array.from(keySet);

  const isTablet = useMedia(from.tablet.query);
  const screenOrientation = useScreenOrientation();

  const { ref, width = 0 } = useResizeObserver();
  const height = useHeight({
    isTablet,
    isLandscape: screenOrientation?.isLandscape ?? false,
  });

  const {
    bottomAxisHeight,
    bottomAxisMargin,
    leftAxisMargin,
    leftAxisWidth,
    rightMargin,
    topMargin,
  } = useMargins({ isTablet });
  const { end, maxValue, minValue, start } = useEdgeValues({ barKeys, data });
  const { xScaleBars, yScale } = useScales({
    bottomAxisHeight,
    bottomAxisMargin,
    data,
    end,
    height,
    leftAxisMargin,
    leftAxisWidth,
    maxValue,
    minValue,
    rightMargin,
    start,
    topMargin,
    width,
    barKeys,
  });
  const {
    handleTooltipClosed,
    handleTooltipUpdated,
    tooltipData,
    tooltipLeft,
    tooltipTop,
  } = useTooltip({
    xScale: xScaleBars,
    yScale,
    height: topMargin + bottomAxisHeight,
    width,
    leftAxisMargin,
    leftAxisWidth,
    barKeys,
    data,
  });

  useEffect(() => {
    if (onChangeTooltip) {
      onChangeTooltip(!!tooltipData);
    }
  }, [tooltipData, onChangeTooltip]);

  useEffect(() => {
    handleTooltipClosed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenOrientation]);

  const xAxisTickValues = useMemo(() => {
    const factorTick = !isTablet ? Math.floor(data.length / 18) : 2;
    return data
      .map(getDate)
      .filter((_, i) => i % (factorTick === 0 ? 1 : factorTick) === 0);
  }, [data, isTablet]);

  const handleBackLink = () => {
    handleBackToAllocationHistory();
  };

  if (!isVisible) {
    return <></>;
  }

  return (
    <Container ref={ref} className={className}>
      <BackLink $isTablet={isTablet} onClick={handleBackLink}>
        <Chevron />
        Back to Asset Allocation History
      </BackLink>
      <GraphVector width="100%" viewBox={`0 0 ${width} ${height}`}>
        <AreaGradient />
        <Grid
          scale={yScale}
          width={width - leftAxisWidth - leftAxisMargin - rightMargin}
          left={leftAxisWidth + leftAxisMargin}
        />
        <AxisLeft
          $isTablet={isTablet}
          left={leftAxisWidth}
          numTicks={7}
          scale={yScale}
          tickFormat={formatAmountShort as TickFormatter<unknown>}
        />
        <AxisBottom
          $isTablet={isTablet}
          left={0}
          tickValues={xAxisTickValues}
          scale={xScaleBars}
          top={height - bottomAxisHeight}
          tickFormat={formatMonth}
        />
        <Title
          $x={25}
          $y={height / 2}
          dominantBaseline="central"
          textAnchor="middle"
        >
          {filter !== 'Equity-Individual' && filter !== 'Equity-Market'
            ? 'Subtypes (%)'
            : 'Asset Name (%)'}
        </Title>
        <BarChart
          data={data}
          handleTooltipClosed={handleTooltipClosed}
          handleTooltipUpdated={handleTooltipUpdated}
          keys={barKeys}
          xScale={xScaleBars}
          yScale={yScale}
          instrumentTypeHover={tooltipData?.key}
        />
      </GraphVector>
      {filter !== 'Equity-Individual' && filter !== 'Equity-Market' && (
        <Legend keys={barKeys} />
      )}
      {typeof tooltipData !== 'undefined' &&
        typeof tooltipLeft === 'number' &&
        typeof tooltipTop === 'number' && (
          <Tooltip
            data={tooltipData}
            tooltipLeft={tooltipLeft}
            tooltipTop={tooltipTop}
            keys={barKeys}
          />
        )}
    </Container>
  );
};

export default memo(ClientMonthlyAllocationBySubtypeChart);
