import useGetClientsQuery, {
  GET_CLIENTS_QUERY_KEY,
} from 'api/queries/clients/getClients';
import { useQuery } from 'api/storage';

export default function useClients() {
  const { data } = useQuery(GET_CLIENTS_QUERY_KEY, {
    queryFn: useGetClientsQuery(),
    refetchInterval: 5 * 60 * 1000,
  });

  return {
    data,
  };
}
