import { parseISO } from 'date-fns';

import type { GetIncomeProfileResponse } from 'api/queries/allocations/getIncomeProfile/types';
import unreachable from 'utils/unreachable';
import type { WithoutNulls } from 'utils/withoutNulls';

type Input = NonNullable<GetIncomeProfileResponse['data']>[number];

export const normalizeIncomeProfileYear = (input: WithoutNulls<Input>) => {
  const id = input.year ?? '';

  return {
    date: parseISO(input.year ?? unreachable('1970-01-01')).getTime(),
    id,
    income: input.income ?? 0,
    incomeAfterTax: input.incomeAfterTax ?? 0,
  } as const;
};

export type IncomeProfileYear = ReturnType<typeof normalizeIncomeProfileYear>;
