import { useState } from 'react';

import useSelectedClient from 'api/hooks/clients/useSelectedClient';
import useAssetClassBreakdown from 'api/hooks/home/useAssetClassBreakdown';

import { getBeginDateAndEndDate, isDefaultPeriod } from '../logic';
import type { Data, Period } from './Controls/types';

export default function useConnect() {
  const { selectedPortfolio } = useSelectedClient();
  const [controls, setControls] = useState<{
    data: Data;
    period: Period;
  }>({
    data: 'activity',
    period: 'MTD',
  });

  const [rangeDate, setRangeDate] = useState<
    [Date | undefined, Date | undefined]
  >([undefined, undefined]);

  const { beginDate, endDate } = getBeginDateAndEndDate(
    rangeDate,
    new Date(selectedPortfolio?.lastUpdated || new Date()),
  );

  const { data: assetClassBreakdown } = useAssetClassBreakdown({
    period: controls.period,
    portfolio: selectedPortfolio?.id,
    beginDate,
    endDate,
    lastUpdated: new Date(selectedPortfolio?.lastUpdated || new Date()),
  });

  return {
    assetClassBreakdown,
    controls,
    rangeDate,
    isDefaultPeriod: isDefaultPeriod(beginDate, endDate, controls.period),
    handle: {
      changeControls: setControls,
      changeRangeDate: setRangeDate,
    },
  };
}
