import { RefObject, useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import useSelectedClient from 'api/hooks/clients/useSelectedClient';
import type { MenuController } from 'components/Menu';
import type { Client } from 'model/Client';

export function useLogic({
  clients = [],
  menuRef,
}: {
  clients: Client[] | undefined;
  menuRef: RefObject<MenuController | null>;
}) {
  const { replace } = useHistory();
  const { hash, pathname, state } = useLocation();
  const { initialize, selectedClient, selectedPortfolio, setSelectedClient } =
    useSelectedClient();

  const [openSettings, setOpenSettings] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const handleDisplaySettings = useCallback(() => {
    setOpenSearch(false);
    setOpenSettings(!openSettings);
    setSearchValue('');
  }, [openSettings]);

  const handleDisplaySearch = useCallback(() => {
    setOpenSettings(false);
    setOpenSearch(!openSearch);
    setSearchValue('');
  }, [openSearch]);

  const handleSearchChange = useCallback(
    (e) => {
      setSearchValue(e.target.value);
    },
    [setSearchValue],
  );

  const onChangeSelectedClient = useCallback(
    (clientId: string, portfolioIdValue?: string) => {
      const client = clients?.find((it) => it.id === clientId);

      if (!client) {
        return;
      }

      let portfolio = portfolioIdValue;
      if (!portfolio) {
        portfolio =
          client && client?.subPortfolios[0]
            ? client?.subPortfolios[0].id
            : undefined;
      }

      if (!portfolio) {
        return;
      }

      if (!openSearch && !openSettings) {
        void menuRef.current?.close();
      }

      setOpenSearch(false);
      setOpenSettings(false);

      setSelectedClient({
        clientId: client.id,
        portfolioId: portfolio,
      });

      replace({
        hash,
        pathname,
        state,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clients, menuRef, openSearch, openSettings, setSelectedClient],
  );

  const onChangeSelectedPortfolio = useCallback(
    (portfolioId: string) => {
      if (!selectedClient) {
        return;
      }
      void menuRef.current?.close();

      setSelectedClient({
        clientId: selectedClient.id,
        portfolioId,
      });

      replace({
        hash,
        pathname,
        state,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [menuRef, selectedClient, setSelectedClient],
  );

  useEffect(() => {
    initialize();
  }, [initialize]);

  return {
    handleDisplaySearch,
    handleDisplaySettings,
    handleSearchChange,
    onChangeSelectedClient,
    onChangeSelectedPortfolio,
    searchValue,
    selectedClient,
    selectedPortfolio,
    openSearch,
    openSettings,
  };
}
