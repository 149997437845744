import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 0.75rem;
  line-height: 1.5rem;
  padding: 0 1rem;
`;

export const Item = styled.div`
  :not(:last-child) {
    margin: 0 1rem;
  }
`;

export const Box = styled.span<{ $color: string }>`
  border-radius: 0.125rem;
  display: inline-block;
  height: 0.625rem;
  margin-right: 0.375rem;
  width: 0.625rem;

  ${({ $color }) => css`
    background-color: ${$color};
  `};
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.white50};
`;
