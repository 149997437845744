import { useCallback } from 'react';
import type { QueryFunction } from 'react-query';

import useRequest from 'api/request';
import {
  AssetAllocationTargetAndRangesDatum,
  normalizeAssetAllocationTargetAndRangesDatum,
} from 'model/AssetAllocationTargetAndRangesDatum';
import getPortfolioId from 'utils/getPorfolioId';
import withoutNulls from 'utils/withoutNulls';

import type {
  GetAssetAllocationTargetAndRangesChartDataRequest,
  GetAssetAllocationTargetAndRangesChartDataResponse,
} from './types';

export const GET_ASSET_ALLOCATION_TARGET_AND_RANGES_CHART_DATA_QUERY_KEY = (
  portfolio: string,
) => ['charts/getAllocationsTargetAndRangesChartData', portfolio];

export default function useGetAssetAllocationTargetAndRangesChartDataQuery(): QueryFunction<
  AssetAllocationTargetAndRangesDatum[]
> {
  const request = useRequest<
    GetAssetAllocationTargetAndRangesChartDataRequest,
    GetAssetAllocationTargetAndRangesChartDataResponse
  >();

  return useCallback(
    async ({ queryKey }) => {
      const [, portfolio] = queryKey as [string, string];

      const response = await request(
        '/api/CVData/getHistoricalAllocationsAndTargetsByInstrument/{portfolio}',
        {
          urlParams: {
            portfolio: getPortfolioId(portfolio) ?? '',
          },
        },
      );

      const data = await response.json();

      if (!data.isSuccess) {
        throw new Error(data.message ?? '');
      }

      return withoutNulls(data.data ?? []).map(
        normalizeAssetAllocationTargetAndRangesDatum,
      );
    },
    [request],
  );
}
