import type { GetCVBondSummaryResponse } from 'api/queries/clients/getCVBondSummary/types';
import type { WithoutNulls } from 'utils/withoutNulls';

type Input = NonNullable<GetCVBondSummaryResponse['data']>[number];

export const normalizeCVBondSummary = (input: WithoutNulls<Input>) =>
  ({
    subtype: input.subType ?? '',
    totalCost: input.totalCost ?? 0,
    couponPL: input.couponPL ?? 0,
    bondPL: input.bondPL ?? 0,
    couponsToMaturity: input.couponsToMaturity ?? 0,
    recoveryToPar: input.recoveryToPar ?? 0,
    totalFinalPL: input.totalFinalPL ?? 0,
    weightedAverage: input.weightedAverage ?? 0,
    currBondValue: input.currBondValue ?? 0,
  } as const);

export type CVBondSummary = ReturnType<typeof normalizeCVBondSummary>;
