import { useCallback } from 'react';

import useRequest from 'api/request';
import withoutNulls from 'utils/withoutNulls';

import type {
  GetUserDefaultAccountRequest,
  GetUserDefaultAccountResponse,
} from './types';

export const GET_USER_DEFAULT_ACCOUNT_QUERY_KEY =
  'clients/getUserDefaultAccount';

export default function useGetUserDefaultAccount() {
  const request = useRequest<
    GetUserDefaultAccountRequest,
    GetUserDefaultAccountResponse
  >();

  return useCallback(async () => {
    const response = await request('/api/ClientData/getUserDefaultAccount');
    const data = await response.json();
    if (!data.isSuccess) {
      throw new Error(data?.message ?? '');
    }
    return withoutNulls(data.data);
  }, [request]);
}
