import { useCallback } from 'react';
import type { QueryFunction } from 'react-query';

import useRequest from 'api/request';
import {
  normalizeRollingVolatilityDatum,
  RollingVolatilityDatum,
} from 'model/RollingVolatilityDatum';
import type { StatisticalAnalysis } from 'model/StatisticalAnalysis';
import { normalizeStatisticalAnalysis } from 'model/StatisticalAnalysis';
import getPortfolioId from 'utils/getPorfolioId';
import withoutNulls from 'utils/withoutNulls';

import type {
  GetVolatilityReportRequest,
  GetVolatilityReportResponse,
} from './types';

export const GET_VOLATILITY_REPORT_QUERY_KEY = (portfolio: string) => [
  'riskAnalysis/getVolatilityReport',
  portfolio,
];

type Report = {
  rollingVolatilityChartData: readonly RollingVolatilityDatum[];
  statisticalAnalysis: StatisticalAnalysis;
};

export default function useGetVolatilityReportQuery(): QueryFunction<
  Record<string, Report>
> {
  const request = useRequest<
    GetVolatilityReportRequest,
    GetVolatilityReportResponse
  >();

  return useCallback(
    async ({ queryKey }) => {
      const [, portfolio] = queryKey as [string, string];

      const response = await request(
        '/api/CVData/getVolatilityReportComplete/{portfolio}',
        {
          urlParams: {
            portfolio: getPortfolioId(portfolio) ?? '',
          },
        },
      );

      const data = await response.json();

      if (!data.isSuccess) {
        throw new Error(data.message ?? '');
      }

      const raw = withoutNulls(data.data ?? []);

      return (
        raw[0]?.subVolatilityReport?.reduce(
          (acc, { clientName: portfolioName = '', volatilityReportData }) => ({
            ...acc,
            [portfolioName]: {
              rollingVolatilityChartData:
                volatilityReportData?.r1Data
                  ?.filter((it) => it.dataType === 'AnnualizedStdDev')
                  ?.map(normalizeRollingVolatilityDatum) ?? [],
              statisticalAnalysis: normalizeStatisticalAnalysis(
                volatilityReportData?.r2Data ?? [],
              ),
            },
          }),
          {} as Record<string, Report>,
        ) ?? {}
      );
    },
    [request],
  );
}
