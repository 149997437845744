import { useCallback, useMemo } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

import useAllocationGroups from 'api/hooks/allocations/useAllocationGroups';
import useLiquidityAssets from 'api/hooks/allocations/useLiquidityAssets';
import useSelectedClient from 'api/hooks/clients/useSelectedClient';
import type { KeySortOfColumn } from 'utils/sort/types';
import useModal from 'utils/useModal';

import {
  useAssets,
  useCurrentGrouping,
  useNavigationLinks,
  useSorting,
} from '../CategoryDetail/logic';
import { useSummary } from './logic';
import type { SortFormModel } from './types';

export default function useConnect() {
  const categorySlug = 'cash';
  const pageTitle = 'Cash';

  const { url } = useRouteMatch();
  const { search } = useLocation();

  const { selectedPortfolio } = useSelectedClient();

  const { getNameBySlug } = useAllocationGroups({
    groupBy: 'asset-breakdown',
    subselection: undefined,
    portfolio: selectedPortfolio?.id,
  });
  const grouping = useCurrentGrouping({ search });
  const { backUrl, breadcrumbs, navigationLinks, selectedUrl } =
    useNavigationLinks({
      categorySlug,
      getNameBySlug,
      grouping,
      search,
      url,
    });

  const { compare, currentSorting, onChangeSorting, sortOptions } =
    useSorting();

  const { allocation, allocationChartData, assets, balance } = useAssets({
    categorySlug,
    compare,
    grouping,
    portfolio: selectedPortfolio?.id,
    url,
  });

  const headerColumns: KeySortOfColumn[] = [
    {
      titleColumn: `${assets.length} Assets`,
      keyOrderAsc: 'alphabeticalR',
      keyOrderDesc: 'alphabetical',
    },
    {
      titleColumn: 'Total ($)',
      keyOrderAsc: 'totalASC',
      keyOrderDesc: 'default',
    },
    {
      titleColumn: 'Total (%)',
      keyOrderAsc: 'totalPorcASC',
      keyOrderDesc: 'totalPorcDESC',
    },
  ];

  const { closeModal, modalOpen, openModal } = useModal();

  const initialValues: SortFormModel = useMemo(
    () => ({
      value: currentSorting,
    }),
    [currentSorting],
  );

  const onSubmitSortForm = useCallback(
    ({ value }: { value?: string }) => {
      onChangeSorting(value ?? '');
      closeModal();
    },
    [closeModal, onChangeSorting],
  );

  const { data: liquidity } = useLiquidityAssets({
    portfolio: selectedPortfolio?.id,
    grouping,
  });

  const summary = useSummary({
    accounts: liquidity,
  });

  return {
    allocation,
    allocationChartData,
    assets,
    backUrl,
    balance,
    breadcrumbs,
    currentSorting,
    handle: {
      changeSorting: onChangeSorting,
      closeModal,
      openModal,
      submitSortForm: onSubmitSortForm,
    },
    initialValues,
    summary,
    modalOpen,
    navigationLinks,
    pageTitle,
    selectedUrl,
    sortOptions,
    headerColumns,
    url,
    search,
  };
}
