import { Formik } from 'formik';
import type { FC } from 'react';
import { useLocation } from 'react-router-dom';

import Allocation from 'components/Allocation';
import AllocationsList from 'components/AllocationsList';
import SortInfo from 'components/SortInfo';
import SortItemsGroup from 'components/SortItemsGroup';
import SortModal from 'components/SortModal';

import useConnect from './connect';
import {
  ButtonContent,
  ButtonText,
  Chevron,
  Container,
  Content,
  Form,
  Header,
  MainTabs,
  ModalTitle,
  Navigation,
  TableButton,
  TableContainer,
} from './styles';
import type { Props } from './types';

const AllocationsMobile: FC<Props> = ({
  balance,
  allocation,
  closeModal,
  currentSorting,
  groups,
  headerColumns,
  isBreakdown,
  onChangeSorting,
  modalOpen,
  groupLinks,
  openModal,
  sortOptions,
}) => {
  const { search } = useLocation();
  const subselection = new URLSearchParams(search).get('subselection');
  const {
    activeMainTab,
    handle,
    initialValues,
    mainTabs,
    mobileGroupLinks,
    url,
  } = useConnect({
    closeModal,
    currentSorting,
    groupLinks,
    onChangeSorting,
  });

  return (
    <Container>
      {isBreakdown && (
        <MainTabs
          value={activeMainTab}
          tabs={mainTabs}
          onChange={handle.changeMainTab}
        />
      )}
      {activeMainTab === 'asset-breakdown' ? (
        <Content>
          {subselection ? (
            <Header
              amount={balance}
              allocation={<Allocation value={allocation} />}
              modalOpen={modalOpen}
            />
          ) : (
            <Header
              amount={balance}
              title="Portfolio Balance"
              modalOpen={modalOpen}
            />
          )}
          <TableButton
            color="secondary"
            expand="block"
            href={`${url}/asset-allocation-history`}
            className={undefined}
          >
            <ButtonContent>
              <ButtonText>Asset Allocation History</ButtonText> <Chevron />
            </ButtonContent>
          </TableButton>
          <TableContainer>
            <SortInfo
              currentSorting={currentSorting}
              sortOptions={sortOptions}
            />
            <AllocationsList
              data={groups}
              openModal={openModal}
              headerColumns={headerColumns}
            />
          </TableContainer>
        </Content>
      ) : (
        <Navigation activeLink="" links={mobileGroupLinks} />
      )}
      {modalOpen && (
        <Formik
          initialValues={initialValues}
          onSubmit={handle.submitSortForm}
          enableReinitialize
        >
          <Form>
            <SortModal onClose={closeModal}>
              <>
                <ModalTitle>Sort by</ModalTitle>
                <SortItemsGroup options={sortOptions} name="value" />
              </>
            </SortModal>
          </Form>
        </Formik>
      )}
    </Container>
  );
};

export default AllocationsMobile;
