import { useCallback } from 'react';
import type { MutationFunction } from 'react-query';

import env from 'utils/env';
import useDevice from 'utils/useDevice/useDevice';
import withoutNulls from 'utils/withoutNulls';

import type { SaveDeviceInfoRequest, SaveDeviceInfoVariables } from './types';

export default function useDeviceInfoMutation(): MutationFunction<
  void,
  SaveDeviceInfoVariables
> {
  const { deviceInfo, deviceID } = useDevice();

  return useCallback(
    async ({ refreshToken }: SaveDeviceInfoVariables) => {
      const requestBody: SaveDeviceInfoRequest = {
        refreshToken,
        deviceId: deviceID,
        deviceName: deviceInfo?.name,
        isVirtual: String(deviceInfo?.isVirtual),
        manufacturer: deviceInfo?.manufacturer,
        osVersion: deviceInfo?.osVersion,
        model: deviceInfo?.model,
        operatingSystem: deviceInfo?.operatingSystem,
        devicePlatform: deviceInfo?.platform,
        webViewVersion: deviceInfo?.webViewVersion,
      };

      const response = await fetch(
        `${env('REACT_APP_API_ENDPOINT')}/api/Authentication/saveDeviceInfo`,
        {
          body: JSON.stringify(requestBody),
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'post',
        },
      );

      const data = withoutNulls(await response.json());

      if (!data) {
        throw new Error();
      }
    },
    [deviceID, deviceInfo],
  );
}
