import { FC, memo, useState } from 'react';
import { useHistory } from 'react-router';

import LogoutConfirmModal from 'components/LogoutConfirmModal';
import usePlatform from 'utils/usePlatform/usePlatform';

import { version } from '../../../package.json';
import {
  Container,
  ListButton,
  LogOutButton,
  OptionItem,
  SettingItem,
  Settings,
  VersionItem,
} from './styles';
import type { Props } from './types';

const ProfileMenu: FC<Props> = ({
  biometricsEnabled = false,
  biometricType,
  currentPathname,
  onToggleBiometrics,
  pathnameConditions,
  pathnameLogOut,
  pathnameNotifications,
  pathnamePassword,
}) => {
  const [showLogoutConfirmModal, setShowLogoutConfirmModal] = useState(false);
  const { isWeb } = usePlatform();
  const { replace } = useHistory();

  const handleLogoutButtom = () => {
    if (isWeb) {
      replace(pathnameLogOut);
    } else {
      setShowLogoutConfirmModal(true);
    }
  };

  const onConfirmLogout = () => {
    replace(pathnameLogOut);
  };

  const onCancelLogout = () => {
    setShowLogoutConfirmModal(false);
  };

  return (
    <Container>
      <Settings>
        <ListButton
          pathname={pathnamePassword}
          $isActive={currentPathname === pathnamePassword}
        >
          <SettingItem>Change Password</SettingItem>
        </ListButton>
      </Settings>
      {!isWeb && (
        <Settings>
          <ListButton
            pathname={pathnameNotifications}
            $isActive={currentPathname === pathnameNotifications}
          >
            <SettingItem>Notifications</SettingItem>
          </ListButton>
        </Settings>
      )}
      {typeof biometricType !== 'undefined' && (
        <Settings>
          <OptionItem
            checked={biometricsEnabled}
            id="1"
            label={biometricType}
            onChange={onToggleBiometrics}
            optionType="toggle"
            value="faceid"
          />
        </Settings>
      )}
      <Settings>
        <ListButton
          pathname={pathnameConditions}
          $isActive={currentPathname === pathnameConditions}
        >
          <SettingItem>
            Terms, Conditions, and Important Disclosures
          </SettingItem>
        </ListButton>
      </Settings>
      <Settings>
        <LogOutButton onClick={handleLogoutButtom}>
          <SettingItem>Log Out</SettingItem>
        </LogOutButton>
      </Settings>
      <VersionItem>Version: {version}</VersionItem>
      {showLogoutConfirmModal && (
        <LogoutConfirmModal
          onConfirmLogout={onConfirmLogout}
          onCancelLogout={onCancelLogout}
        />
      )}
    </Container>
  );
};

export default memo(ProfileMenu);
