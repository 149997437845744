import styled from 'styled-components';

import Select from 'components/Select';
import Tabs from 'components/Tabs';
import from from 'styles/responsive';

export const Container = styled.div`
  margin-bottom: 24px;
  ${from.laptop`
    width: 25rem;
  `};
`;

export const LaptopTabs = styled(Tabs).attrs({
  variant: 'bg-tabs',
})`` as typeof Tabs;

export const TabletSelect = styled(Select)``;
