import useAcceptTermsConditions from 'api/hooks/auth/useAcceptTermsConditions';
import useLogout from 'api/hooks/auth/useLogout';
import useTermsAndConditions from 'api/hooks/auth/useTermsAndConditions';

export default function useConnect() {
  const { data: termsAndConditions } = useTermsAndConditions();

  const { acceptTermsConditions } = useAcceptTermsConditions();
  const logout = useLogout();

  return { handle: { acceptTermsConditions, logout }, termsAndConditions };
}
