import { Group } from '@visx/group';
import { FC, memo, MouseEvent, TouchEvent } from 'react';

import { Bar, LinePath, MarkerCircle } from './styles';
import type { Line, Props } from './types';

const LineBalanceChart: FC<Props> = ({
  data,
  xScale,
  yScale,
  widthToCenterBand,
  barWidth,
  showBalanceArrow,
  handleTooltipClosed,
  handleTooltipUpdated,
  handleBalanceArrowClosed,
  handleBalanceArrowUpdated,
}) => {
  const mapping = data.map(
    (d) =>
      ({
        id: d.id,
        date: d.date,
        points: [d.balance, yScale.domain()[yScale.domain().length - 1]],
      } as Line),
  );

  const onMouseLeave = () => {
    handleBalanceArrowClosed();
    handleTooltipClosed();
  };

  const onMouseMove = (
    event: MouseEvent<SVGRectElement> | TouchEvent<SVGRectElement>,
  ) => {
    handleBalanceArrowUpdated(event);
    handleTooltipUpdated(event);
  };

  return (
    <svg>
      <MarkerCircle id="marker-circle" size={1} strokeWidth={4} refX={3} />
      {mapping.map((lineData) => (
        <Group key={`line-${lineData.date}`}>
          <LinePath
            key={`path-${lineData.id}`}
            id={`path-${lineData.id}`}
            $showBalanceArrow={lineData.date === showBalanceArrow}
            strokeWidth={2}
            data={lineData.points}
            x={(xScale(lineData.date) || 0) + (widthToCenterBand ?? 0)}
            y={(d) => yScale(d as number) - 10 ?? 0}
            markerStart={
              lineData.date === showBalanceArrow
                ? 'url(#marker-circle)'
                : undefined
            }
          />
          <Bar
            key={`bar-path-${lineData.id}`}
            id={`bar-path-${lineData.id}`}
            data-datum-id={lineData.id}
            fill="transparent"
            height={
              (yScale(lineData.points[0] as number) - 10 ?? 0) -
              (yScale(lineData.points[1] as number) - 10 ?? 0)
            }
            width={barWidth}
            onMouseEnter={onMouseMove}
            onMouseLeave={onMouseLeave}
            x={xScale(lineData.date) || 0}
            y={10}
          />
        </Group>
      ))}
    </svg>
  );
};

export default memo(LineBalanceChart);
