import { useCallback } from 'react';
import { useMutation } from 'react-query';

import useResetPasswordMutation from 'api/mutations/auth/resetPassword';
import type { ResetPasswordVariables } from 'api/mutations/auth/resetPassword/types';

export default function useResetPassword() {
  const { isLoading, isSuccess, mutateAsync } = useMutation(
    useResetPasswordMutation(),
  );

  const resetPassword = useCallback(
    (variables: ResetPasswordVariables) => mutateAsync(variables),
    [mutateAsync],
  );

  return { loading: isLoading, resetPassword, success: isSuccess };
}
