import styled from 'styled-components';

import OptionItem from 'components/OptionItem';
import HeaderProfile from 'containers/Common/HeaderProfile';
import PageDetailLayout from 'containers/Common/PageDetailLayout';
import { DisclosuresLink } from 'containers/Common/PageDetailLayout/styles';
import from from 'styles/responsive';

export const Container = styled(PageDetailLayout)`
  ${from.tablet`
    padding: 56px 40px;
  `}
  ${from.laptop`
    padding: 54px 64px;
  `}
  ${DisclosuresLink} {
    display: none;
  }
`;

export const Grid = styled.div`
  padding: 2rem 1.25rem;
  max-width: 61.25rem;
  ${from.tablet`
    padding: 0;
    display: grid;
    grid-template-columns: 15.9375rem auto;
    grid-column-gap: 5.75rem;
    grid-row-gap: 3.5rem;
  `}
  ${from.laptop`
    grid-template-columns: 21rem auto;
  `}
`;

export const Header = styled(HeaderProfile)`
  margin-bottom: 32px;
  ${from.tablet`
    grid-column: span 2;
    margin-bottom: 0;
  `}
`;

export const Content = styled.div`
  > :first-child {
    border-bottom: 1px solid ${({ theme }) => theme.colors.white10};
  }
  ${from.tablet`
    > :first-child {
      border-bottom: none;
    }
  `}
`;

export const NotificationItem = styled.div`
  margin-bottom: 2rem;
  ${from.tablet`
    margin-bottom: 3rem;
  `}
`;

export const Title = styled.h3`
  margin: 0 0 0.5rem;
  font-size: 1.125rem;
  line-height: 1.5625rem;
`;

export const Description = styled.p`
  margin: 0 0 1.5rem;
  font-size: 0.875rem;
  line-height: 1.4063rem;
  ${from.tablet`
    margin-bottom: 2rem;
    font-size: 1rem;
    line-height: 1.1875rem;
  `}
`;

export const Option = styled(OptionItem)`
  margin-bottom: 2rem;
  border-radius: 1rem;
  --border-radius: 1rem;
  --border-color: transparent;
  ${from.tablet`
    margin-bottom: 0;
  `}
`;

export const Note = styled.p`
  font-style: italic;
  font-size: 0.725rem;
  line-height: 1.4063rem;
  margin-bottom: 2rem;
  ${from.tablet`
    font-size: 1rem;
    line-height: 1.1875rem;
    margin-bottom: 3rem;
  `}
`;
