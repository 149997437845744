import styled, { css } from 'styled-components';

export const Container = styled.span<{
  $coloured: boolean;
  $isNegative: boolean;
}>`
  opacity: 1;

  ${({ $coloured, $isNegative, theme }) =>
    $coloured &&
    css`
      color: ${$isNegative
        ? theme.colors.statusDecrease
        : theme.colors.statusIncrease};
    `};
`;

export const Signs = styled.span<{ $coloured: boolean }>`
  ${({ $coloured }) =>
    !$coloured &&
    css`
      opacity: 0.75;
    `};
`;

export const Value = styled.span``;
