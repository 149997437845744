import { FC, Fragment, memo, useMemo } from 'react';

import { formatMonth } from 'utils/dates';
import { formatPercentage } from 'utils/percentages';

import {
  Box,
  Date,
  Percentage,
  PercentageTTM,
  TooltipWithBounds,
} from './styles';
import type { Props } from './types';

const Tooltip: FC<Props> = ({ data, tooltipLeft, tooltipTop, chartKeys }) => {
  const formattedDate = useMemo(() => formatMonth(data.date), [data]);
  const sortedKeys = useMemo(
    () => [...chartKeys].sort((a, b) => data[b] - data[a]),
    [data, chartKeys],
  );
  return (
    <TooltipWithBounds top={tooltipTop} left={tooltipLeft}>
      <Date>{formattedDate}</Date>
      <PercentageTTM>TTM: {data.portfolioTTM}</PercentageTTM>
      {sortedKeys.map((chartKey) => (
        <Fragment key={chartKey}>
          <Percentage>
            {sortedKeys.length > 1 ? <Box $key={chartKey} /> : null}
            {formatPercentage(data[chartKey])}
          </Percentage>
        </Fragment>
      ))}
    </TooltipWithBounds>
  );
};

export default memo(Tooltip);
