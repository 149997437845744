import { Circle as DefaultCircle } from '@visx/shape';
import styled from 'styled-components';

export const Circle = styled(DefaultCircle)<{ $isMedian?: boolean }>`
  fill: currentColor;
  color: ${({ theme, $isMedian }) =>
    $isMedian ? theme.colors.dataCarrot : theme.colors.primary};
  transition: opacity 200ms ease;
  &:hover {
    opacity: 0.5;
  }
`;
