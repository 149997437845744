import type { FC } from 'react';
import { useCallback } from 'react';

import { Box, Container, Initials, Label } from './styles';
import type { Props } from './types';

const Profile: FC<Props> = ({
  id,
  isActive,
  initials,
  name,
  onChangeSelectedProfile,
}) => {
  const onClick = useCallback(() => {
    onChangeSelectedProfile(id);
  }, [id, onChangeSelectedProfile]);

  return (
    <Container onClick={onClick}>
      <Box $isActive={isActive}>
        <Initials>{initials}</Initials>
      </Box>
      <Label>{name}</Label>
    </Container>
  );
};

export default Profile;
