import styled from 'styled-components';

import DefaultTermsAndConditionsContent from 'components/TermsAndConditionsContent';
import HeaderProfile from 'containers/Common/HeaderProfile';
import PageDetailLayout from 'containers/Common/PageDetailLayout';
import {
  DisclosuresLink,
  MobileContent,
} from 'containers/Common/PageDetailLayout/styles';
import from from 'styles/responsive';
import ZIndex from 'styles/ZIndex';

export const Container = styled(PageDetailLayout)`
  ${from.tablet`
    padding: 3.5rem 2.5rem;
  `}
  ${from.laptop`
    padding: 3.375rem 4rem;
  `}
  ${MobileContent} {
    z-index: ${ZIndex.MOBILE_CONTENT};
  }
  ${DisclosuresLink} {
    display: none;
  }
`;

export const Grid = styled.div`
  padding: 2rem 1.25rem;
  max-width: 85rem;
  ${from.tablet`
    padding: 0;
    display: grid;
    grid-template-columns: 15.9375rem auto;
    grid-column-gap: 5.75rem;
    grid-row-gap: 3.5rem;
  `}
  ${from.laptop`
    grid-template-columns: 21rem auto;
  `}
`;

export const Header = styled(HeaderProfile)`
  margin-bottom: 32px;
  ${from.tablet`
    grid-column: span 2;
    margin-bottom: 0;
  `}
`;

export const Content = styled.div`
  > :first-child {
    margin-bottom: 1.5rem;
  }
`;

export const TermsAndConditionsContent = styled(
  DefaultTermsAndConditionsContent,
)`
  padding: 2.5rem 1.25rem;
  ${from.tablet`
    padding: 0;
  `}
`;
