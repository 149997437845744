import useGetProfitLossChartDataQuery, {
  GET_PROFIT_LOSS_CHART_DATA_QUERY_KEY,
} from 'api/queries/charts/getProfitLossChartData';
import { useQuery } from 'api/storage';

export default function useProfitLossChartData({
  client,
  portfolioName,
}: {
  client: string | undefined;
  portfolioName: string | undefined;
}) {
  const { data } = useQuery(
    GET_PROFIT_LOSS_CHART_DATA_QUERY_KEY(client ?? ''),
    {
      enabled: !!client,
      queryFn: useGetProfitLossChartDataQuery(),
    },
  );

  return {
    data: portfolioName ? data?.[portfolioName] : undefined,
  };
}
