import { FC, memo } from 'react';

import usePlatform from 'utils/usePlatform/usePlatform';

import {
  Button,
  Container,
  FooterContainer,
  MessageUpdateApp,
  TextContainer,
  TextDescription,
  Title,
} from './style';
import type { Props } from './types';

const LogoutConfirmModal: FC<Props> = ({ onConfirmLogout, onCancelLogout }) => {
  const { isIOS, isAndroid } = usePlatform();

  if (!isIOS && !isAndroid) {
    return <></>;
  }

  return (
    <Container>
      <MessageUpdateApp $isIOS={isIOS}>
        <TextContainer $isIOS={isIOS}>
          <Title>Log out?</Title>
          <TextDescription $isIOS={isIOS}>
            Are you sure you want to log out?
          </TextDescription>
        </TextContainer>
        <FooterContainer $isIOS={isIOS}>
          <Button $isIOS={isIOS} $red={false} onClick={onCancelLogout}>
            NO
          </Button>
          <Button $isIOS={isIOS} $red onClick={onConfirmLogout}>
            YES
          </Button>
        </FooterContainer>
      </MessageUpdateApp>
    </Container>
  );
};

export default memo(LogoutConfirmModal);
