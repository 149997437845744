import useGetNotionalValues, {
  GET_NOTIONAL_VALUES_QUERY_KEY,
} from 'api/queries/allocations/getNotionalValues';
import { useQuery } from 'api/storage';

export default function useNotionalValues(portfolio: string | undefined) {
  const { data } = useQuery(GET_NOTIONAL_VALUES_QUERY_KEY(portfolio ?? ''), {
    enabled: !!portfolio,
    queryFn: useGetNotionalValues(),
  });

  return {
    data,
  };
}
