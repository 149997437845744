const dark = {
  weights: {
    regular: 400,
    medium: 500,
    bold: 700,
  },
  colors: {
    primary: '#4D84FF',
    primary75: '#4D84FFC0',
    primary50: '#4D84FF80',
    primary25: '#4D84FF40',
    primary15: '#4D84FF26',
    primary10: '#4D84FF19',
    primary5: '#4D84FF0C',
    chartLightBlue: '#419DC0',
    chartBlue: '#3E77B9',
    chartLightGreen: '#64CB72',
    chartGreen: '#267230',
    chartLightYellow: '#F6D459',
    chartYellow: '#F3AE1F',
    chartLightOrange: '#D97726',
    chartOrange: '#DB4D33',
    chartLightPink: '#E04F94',
    chartPink: '#B11F65',
    chartLightPurple: '#C388EA',
    chartPurple: '#9329D9',
    chartLightMint: '#54E1B3',
    chartMint: '#51AEA0',
    dataBlue: '#009CC0',
    dataMantis: '#8DBF5E',
    dataSupernova: '#F1CC00',
    dataCarrot: '#F39A1E',
    statusDecrease: '#FF6B6B',
    statusDecrease75: '#FF6B6BC0',
    statusIncrease: '#8FDABB',
    statusIncrease75: '#8FDABBC0',
    statusWarning: '#F9CB3F',
    dark0: '#0A1233',
    dark05: '#0B153B',
    dark1: '#0C1640',
    dark2: '#0E1A48',
    dark3: '#11205A',
    white: '#FFFFFF',
    white75: '#FFFFFFC0',
    white50: '#FFFFFF80',
    white35: '#FFFFFF59',
    white25: '#FFFFFF40',
    white10: '#FFFFFF19',
    white5: '#FFFFFF0C',
  },
  fonts: {
    GTAmerica: 'GT America',
  },
};

export default dark;
