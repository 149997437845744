import {
  AxisBottom as DefaultAxisBottom,
  AxisLeft as DefaultAxisLeft,
} from '@visx/axis';
import { GridRows } from '@visx/grid';
import type { TextProps } from '@visx/text/lib/Text';
import styled, { css } from 'styled-components';

import from from 'styles/responsive';
import type { Theme } from 'styles/themes';

export const GraphVector = styled.svg``;

export const Container = styled.div`
  position: relative;

  ${from.tablet`
    background-color: ${({ theme }) => theme.colors.dark1};
    padding: 1rem 0 2rem;
    border-radius: 1rem;
  `}
`;

export const Grid = styled(GridRows).attrs({ stroke: 'currentColor' })`
  color: ${({ theme }) => theme.colors.white}1a;
  display: none;

  ${from.tablet`
    display: initial;
  `};
`;

const commonAxisProps = ({ theme }: { theme: Theme }) => ({
  hideTicks: true,
  stroke: 'none',
  tickLabelProps: (): Partial<TextProps> => ({
    fill: theme.colors.white50,
    fontSize: '0.875rem',
    textAnchor: 'middle',
  }),
});

export const AxisLeft = styled(DefaultAxisLeft).attrs(({ theme }) => ({
  ...commonAxisProps({ theme }),
  tickLabelProps: (): Partial<TextProps> => ({
    ...commonAxisProps({ theme }).tickLabelProps(),
    textAnchor: 'end',
    verticalAnchor: 'middle',
    x: 0,
  }),
}))``;

export const AxisBottom = styled(DefaultAxisBottom).attrs(({ theme }) => ({
  ...commonAxisProps({ theme }),
  tickLabelProps: (): Partial<TextProps> => ({
    ...commonAxisProps({ theme }).tickLabelProps(),
    y: 8,
    textAnchor: 'middle',
    verticalAnchor: 'middle',
  }),
}))``;

export const AxisBottomTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

const Title = css`
  color: ${({ theme }) => theme.colors.white50};
  display: none;
  font-size: 0.8125rem;

  ${from.tablet`
    display: initial;
  `};
`;

export const LeftTitle = styled.text.attrs({ fill: 'currentColor' })<{
  $x: number;
  $y: number;
}>`
  ${Title}
  transform: translateX(${({ $x }) => $x}px) translateY(${({ $y }) => $y}px)
rotate(-90deg);
`;

export const BottomTitle = styled.span.attrs({ fill: 'currentColor' })`
  ${Title}
`;

export const MedianBar = styled.rect`
  fill: currentColor;
  color: ${({ theme }) => theme.colors.dataCarrot};
`;
