import type { GetMarketWatchlistResponse } from 'api/queries/markets/getMarketWatchlist/types';
import type { WithoutNulls } from 'utils/withoutNulls';

type Input = NonNullable<
  NonNullable<GetMarketWatchlistResponse['data']>[number]
>;

export const normalizeMarketWatchlistAsset = (input: WithoutNulls<Input>) =>
  ({
    dailyPercentChange: input.dayCh ?? 0,
    dailyProfitAndLoss: input.changeInDollars_Daily ?? 0,
    // Actually this type assertion is necessary because it casts `${number | undefined}` to string. Go home eslint, you're drunk.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    id: `${input.id}` as string,
    mtdPercentChange: input.mtd ?? 0,
    mtdProfitAndLoss: input.changeInDollars_MTD ?? 0,
    name: input.instrumentName ?? '',
    // If an item is in the watchlist, it is pinned by default. It might be
    // unpinned at the user's request at which point it will remain in the
    // watchlist in cache but it will be removed from the back-end.
    pinned: true as boolean,
    price: input.price ?? 0,
    ytdPercentChange: input.ytd ?? 0,
    ytdProfitAndLoss: input.changeInDollars_YTD ?? 0,
  } as const);

export type MarketWatchlistAsset = ReturnType<
  typeof normalizeMarketWatchlistAsset
>;
