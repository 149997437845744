import {
  AxisBottom as DefaultAxisBottom,
  AxisLeft as DefaultAxisLeft,
} from '@visx/axis';
import { LinearGradient } from '@visx/gradient';
import { GridRows } from '@visx/grid';
import type { TextProps } from '@visx/text/lib/Text';
import styled from 'styled-components';

import type { Theme } from 'styles/themes';

import type { $GradientProps, ChartKey } from './types';

export const GraphVector = styled.svg``;

export function getChartKeyColor(key: ChartKey, theme: Theme) {
  switch (key) {
    case 'adjustedTreasuryYield':
      return theme.colors.dataCarrot;
    default:
    case 'portfolioYield':
      return theme.colors.primary;
  }
}

export function getChartKeyColorGradientOpacity(key: ChartKey) {
  switch (key) {
    case 'adjustedTreasuryYield':
      return 99;
    default:
    case 'portfolioYield':
      return 29;
  }
}

export function getChartKeyGradientId($chartId: string, $chartKey: ChartKey) {
  return `area_gradient_${$chartId}_${$chartKey}`;
}

export const Container = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.dark1};
  padding: 1rem 0 2rem;
  border-radius: 1rem;
`;

export const AreaGradient = styled(LinearGradient).attrs<$GradientProps>(
  ({ theme, $chartKey, $chartId }) => ({
    from: `${getChartKeyColor(
      $chartKey,
      theme,
    )}${getChartKeyColorGradientOpacity($chartKey)}`,
    fromOffset: '87.75%',
    id: getChartKeyGradientId($chartId, $chartKey),
    to: `${getChartKeyColor($chartKey, theme)}00`,
    vertical: false,
  }),
)<$GradientProps>``;

export const Grid = styled(GridRows).attrs({ stroke: 'currentColor' })`
  color: ${({ theme }) => theme.colors.white}1a;
`;

const commonAxisProps = ({ theme }: { theme: Theme }) => ({
  hideTicks: true,
  stroke: 'none',
  tickLabelProps: (): Partial<TextProps> => ({
    fill: theme.colors.white50,
    fontSize: '12px',
    textAnchor: 'middle',
  }),
});

export const AxisLeft = styled(DefaultAxisLeft).attrs(({ theme }) => ({
  ...commonAxisProps({ theme }),
  tickLabelProps: (): Partial<TextProps> => ({
    ...commonAxisProps({ theme }).tickLabelProps(),
    textAnchor: 'end',
    verticalAnchor: 'middle',
    x: 0,
  }),
}))``;

export const AxisBottom = styled(DefaultAxisBottom).attrs(({ theme }) => ({
  ...commonAxisProps({ theme }),
  tickLabelProps: (): Partial<TextProps> => ({
    ...commonAxisProps({ theme }).tickLabelProps(),
    y: 8,
    textAnchor: 'middle',
    verticalAnchor: 'middle',
  }),
}))``;

export const LegendText = styled.text.attrs({ fill: 'currentColor' })<{
  $x: number;
  $y: number;
}>`
  color: ${({ theme }) => theme.colors.white50};
  font-size: 0.75rem;
  transform: translateX(${({ $x }) => $x}px) translateY(${({ $y }) => $y}px)
    rotate(-90deg);
`;

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
`;

export const LegendLabel = styled.p`
  color: ${({ theme }) => theme.colors.white50};
  font-size: 0.75rem;
  line-height: 0.9038rem;
`;
