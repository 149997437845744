import type { FC } from 'react';
import { memo } from 'react';

import { Box, Container, Item, Label } from './styles';
import type { Props } from './types';

const Legend: FC<Props> = ({ keys, data }) => {
  const formatKey = (key: string) => {
    switch (key) {
      case 'Client':
        return data.find((dt) => dt.instrumentType === 'Client')?.name || key;
      case 'Equity-Market':
        return 'Equity Indices';
      case 'Fixed Income':
        return 'Fixed Income Indices';
      default:
        return key;
    }
  };

  return (
    <Container>
      {keys.map((key: string, index) => (
        <Item key={key}>
          <Box $index={index} $length={keys.length} />
          <Label>{formatKey(key)}</Label>
        </Item>
      ))}
    </Container>
  );
};

export default memo(Legend);
