import { FC, memo } from 'react';

import {
  Header,
  HeaderCell,
  HeaderTitle,
  Legend,
  Table,
  TableContainer,
  Title,
  VectorDownIcon,
  VectorUpIcon,
} from '../styles';
import Row from './Row';
import type { Props } from './types';

const DesktopAndTabletVersion: FC<Props> = ({
  data,
  onPin,
  onUnpin,
  handleSortByColumn,
  sortApplied,
  headerColumns,
}) => (
  <TableContainer>
    <Table>
      <Header>
        {headerColumns.map((col) => (
          <HeaderCell key={col.titleColumn}>
            <HeaderTitle
              onClick={() =>
                handleSortByColumn ? handleSortByColumn(col) : undefined
              }
            >
              <Title>{col.titleColumn}</Title>
              {sortApplied?.titleColumn === col.titleColumn &&
                (sortApplied?.orderOrientation === 'DESC' ? (
                  <VectorDownIcon />
                ) : (
                  <VectorUpIcon />
                ))}
            </HeaderTitle>
          </HeaderCell>
        ))}
      </Header>
      {data.map((row) => (
        <Row onPin={onPin} onUnpin={onUnpin} row={row} key={row.id} />
      ))}
    </Table>
    <Legend>
      * Return calculations are relative to US$ rather than market convention.
    </Legend>
  </TableContainer>
);

export default memo(DesktopAndTabletVersion);
