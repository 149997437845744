import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 30px;
`;

export const H1 = styled.h1`
  font-size: 18px;
  line-height: 25.2px;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.weights.regular};
  margin-bottom: 32px;
`;

export const Item = styled.div`
  display: flex;
  margin: 20px 0;
`;

export const MembersTeam = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`;

export const Title = styled.h2`
  min-width: 3.5rem;
  max-width: 3.6rem;
  font-size: 11px;
  line-height: 15.4px;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.weights.regular};
  margin: 0;
  opacity: 75%;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Name = styled.p`
  font-size: 12px;
  line-height: 16.8px;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.weights.regular};
  margin: 0;
`;

export const Email = styled.a`
  font-size: 12px;
  line-height: 16.8px;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.weights.regular};
  margin: 0;
  text-decoration: none;
  width: fit-content;
`;

export const PhoneContainer = styled.div`
  display: flex;
  gap: 7.33px;
  margin-top: 33.5px;
`;

export const Phone = styled.a`
  font-size: 11px;
  line-height: 13.26px;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.weights.regular};
  opacity: 75%;
  text-decoration: none;
  display: flex;
  gap: 7.33px;
`;
