import { FC, memo, useCallback } from 'react';

import {
  Amount,
  Col,
  ColorAmount,
  ColorPercentage,
  Column,
  Container,
  FirstCol,
  PinButton,
  PinIcon,
} from '../../styles';
import type { Props } from './types';

const Row: FC<Props> = ({ onPin, onUnpin, row }) => {
  const handleTogglePinned = useCallback(() => {
    if (row.pinned) {
      onUnpin(row.name);
    } else {
      onPin(row.name);
    }
  }, [onPin, onUnpin, row]);

  return (
    <Container>
      <FirstCol>
        {row.name}
        <Amount value={row.price} />
      </FirstCol>
      <Column>
        <ColorPercentage value={row.dailyPercentChange} />
        <ColorAmount value={row.dailyProfitAndLoss} />
      </Column>
      <Column>
        <ColorPercentage value={row.mtdPercentChange} />
        <ColorAmount value={row.mtdProfitAndLoss} />
      </Column>
      <Column>
        <ColorPercentage value={row.ytdPercentChange} />
        <ColorAmount value={row.ytdProfitAndLoss} />
      </Column>
      <Col>
        <PinButton $isPinned={row.pinned} onClick={handleTogglePinned}>
          <PinIcon $isPinned={row.pinned} />
        </PinButton>
      </Col>
    </Container>
  );
};

export default memo(Row);
