import { useEffect, useState } from 'react';

export default function useMedia(query: string) {
  const formattedQuery = query.slice(7);
  const [matches, setMatches] = useState(
    window.matchMedia(formattedQuery).matches,
  );

  useEffect(() => {
    const media = window.matchMedia(formattedQuery);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const listener = () => setMatches(media.matches);

    /*
     * We subscribe to window's resize instead of media's change because the
     * latter was giving us some trouble on desktop Safari. This means that we
     * can only use window size media queries, which are the only ones we use
     * in the app.
     */
    window.addEventListener('resize', listener);

    return () => window.removeEventListener('resize', listener);
  }, [formattedQuery, matches]);
  return matches;
}
