import { BarRounded as DefaultBarRounded } from '@visx/shape';
import styled from 'styled-components';

export const BarRounded = styled(DefaultBarRounded)`
  cursor: pointer;
  fill: currentColor;
  color: ${({ theme }) => theme.colors.primary};
  transition: opacity 200ms ease;
  &:hover {
    opacity: 0.5;
  }
`;
