import { useCallback, useMemo, useState } from 'react';

type DataDisplay = 'Total' | 'YTW' | 'TE YTW' | 'TTC' | 'Duration' | 'Rating';

type DataDisplayOption = {
  label: string;
  value: DataDisplay;
};

export function useDataDisplay(enabledTaxEquivalentYield = false) {
  const dataDisplayOptions: [DataDisplayOption, ...DataDisplayOption[]] =
    useMemo(
      () => [
        { value: 'Total', label: 'Total ($)' },
        {
          value: enabledTaxEquivalentYield ? 'TE YTW' : 'YTW',
          label: enabledTaxEquivalentYield
            ? 'TE Yield to Worst'
            : 'Yield to Worst',
        },
        { value: 'Duration', label: 'Duration' },
      ],
      [enabledTaxEquivalentYield],
    );

  const [dataDisplay, setDataDisplay] = useState<DataDisplay>(
    dataDisplayOptions[0].value,
  );

  const onChangeDataDisplay = useCallback(
    (value: string) => {
      if (
        value === 'Total' ||
        value === 'YTW' ||
        value === 'TE YTW' ||
        value === 'Duration'
      ) {
        setDataDisplay(value);
      }
    },
    [setDataDisplay],
  );

  return {
    dataDisplay,
    dataDisplayOptions,
    onChangeDataDisplay,
  };
}
