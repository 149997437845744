import { FC, memo } from 'react';

import from from 'styles/responsive';
import useMedia from 'utils/useMedia';

import PerformanceBreakdownCard from './PerformanceBreakdownCard';
import { Container, Content, Title } from './styles';
import type { Props } from './types';

const PerformanceBreakdown: FC<Props> = ({ profitLossChartData, reports }) => {
  const isMobile = !useMedia(from.tablet.query);
  return (
    <Container>
      <Title>Performance Breakdown</Title>
      <Content>
        {reports.map((report) => (
          <PerformanceBreakdownCard
            id={report.id}
            key={report.id}
            name={report.name}
            slug={report.slug}
            pathname={report.pathname}
            profitAndLoss={report.profitAndLoss}
            profitLossChartData={
              report.name === 'Since Inception' && isMobile
                ? profitLossChartData
                : undefined
            }
            roc={report.roc}
            roe={report.roe}
          />
        ))}
      </Content>
    </Container>
  );
};
export default memo(PerformanceBreakdown);
