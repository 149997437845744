import type { FC } from 'react';
import { memo, useMemo } from 'react';

import {
  CloseButton,
  CloseIcon,
  Container,
  EnableButton,
  FaceIDIcon,
  IconContainer,
  Subtitle,
  Title,
  TouchIDIcon,
} from './styles';
import type { Props } from './types';

const EnableBiometricsPrompt: FC<Props> = ({
  biometricType,
  onEnable,
  onReject,
}) => {
  /* eslint-disable react/jsx-key */ // Doesn't apply here
  const [titleText, enableText, icon] = useMemo(() => {
    switch (biometricType) {
      case 'face-id':
        return ['Face ID', 'Enable Face ID', <FaceIDIcon />];
      case 'touch-id':
        return ['Touch ID', 'Enable Touch ID', <TouchIDIcon />];
      case 'generic':
      default:
        return ['Biometric Log In', 'Enable Biometrics', <TouchIDIcon />];
    }
  }, [biometricType]);
  /* eslint-enable react/jsx-key */

  return (
    <Container>
      <CloseButton onClick={onReject} withIcon={<CloseIcon />} />
      <IconContainer>{icon}</IconContainer>
      <Title>{titleText}</Title>
      <Subtitle>
        {enableText} to log into your account safely in the future
      </Subtitle>
      <EnableButton onClick={onEnable}>{enableText}</EnableButton>
    </Container>
  );
};

export default memo(EnableBiometricsPrompt);
