import styled, { css } from 'styled-components';

import { ReactComponent as DefaultBlueTick } from 'assets/icons/blue-tick.svg';
import { MarkAllAsReadButton } from 'containers/Protected/News/styles';
import from from 'styles/responsive';

export const Container = styled.div<{ $isRead: boolean }>`
  position: relative;
  display: flex;
  border-radius: 1rem;
  margin-bottom: 1rem;
  height: 7.5rem;
  background: ${({ theme }) => theme.colors.white5};
  padding: 1rem 2rem;

  ${({ $isRead }) =>
    $isRead
      ? css`
          opacity: 0.6;
        `
      : css`
          box-shadow: 0.5rem 0.5rem 0.5rem 0px rgba(0, 0, 0, 0.2);
        `};

  ${from.laptop`
    &:hover {
      cursor: pointer;
      background: ${({ theme }) => theme.colors.white10};
    }
  `}
`;

export const ImagenContainer = styled.div<{ $url: string }>`
  min-width: 5.5rem;
  min-height: 5.5rem;
  border-radius: 5px;
  background-image: url(${({ $url }) => $url});
  background-size: cover;
  background-position: center;
`;

export const ContainerDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 1rem;
  padding-right: 8rem;
`;

export const DescriptionItem = styled.div`
  font-size: 1rem;
  line-height: 1.4rem;
  color: ${({ theme }) => theme.colors.white};
`;

export const DateSourceItem = styled.div`
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: ${({ theme }) => theme.colors.white75};
`;

export const MarkAsReadButton = styled(MarkAllAsReadButton)`
  position: absolute;
  right: 2rem;
  height: fit-content;
`;

export const MarkAsUnreadButton = styled(MarkAsReadButton)`
  ${from.laptop`
    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  `}
`;

export const BlueTickIcon = styled(DefaultBlueTick)`
  margin-right: 0.5rem;
  path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;
