import styled from 'styled-components';

import DefaultButton from 'components/Button';
import hexToRgb from 'utils/hexToRgb';

export const Container = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100000;
  background-color: rgba(${({ theme }) => hexToRgb(theme.colors.dark0)}, 0.625);
`;

export const MessageActivateNotification = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  background: ${({ theme }) => theme.colors.dark3};
  padding: 2rem 1.25rem;
  top: 50%;
  left: 50%;
  box-shadow: 8px 10px 20px -6px #000000;
  border-radius: 5%;
  transform: translate(-50%, -50%);
  width: 21.825rem;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  align-items: center;
`;

export const Text = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.weights.bold};
  font-size: 1.5rem;
  line-height: 1.25rem;
`;

export const SubText = styled.span`
  font-weight: ${({ theme }) => theme.weights.medium};
  line-height: 1.25rem;
  text-align: center;
  font-size: 0.825rem;
  color: ${({ theme }) => theme.colors.white};
  margin: 1.35rem 0;
`;

export const ButtonClose = styled.div`
  font-weight: ${({ theme }) => theme.weights.medium};
  color: ${({ theme }) => theme.colors.white75};
  font-size: 0.75rem;
  line-height: 1.25rem;
  text-align: center;
  margin-top: 0.825rem;
  cursor: pointer;
`;

export const Button = styled(DefaultButton)`
  background: ${({ theme }) => theme.colors.primary};
  min-width: 10rem;
  width: 18rem;
`;
