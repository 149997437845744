import { Redirect, Route } from 'react-router-dom';

import ProfileChangePassword from './ProfileChangePassword';
import ProfileLogout from './ProfileLogout';
import ProfileNotifications from './ProfileNotifications';
import ProfileRoot from './ProfileRoot';
import TermsAndConditions from './TermsAndConditions';
import type { Props } from './types';

function Profile({ path }: Props) {
  return [
    // Change Password
    <Route key="profile-change-password" exact path={`${path}/change-password`}>
      <ProfileChangePassword path={path} />
    </Route>,
    // Notifications
    <Route key="profile-notifications" exact path={`${path}/notifications`}>
      <ProfileNotifications path={path} />
    </Route>,
    // Terms and Conditions
    <Route
      exact
      key="terms-and-conditions"
      path={`${path}/terms-and-conditions`}
    >
      <TermsAndConditions path={path} />
    </Route>,
    // Logout
    <Route key="profile-logout" exact path={`${path}/logout`}>
      <ProfileLogout />
    </Route>,
    // Root
    <Route exact key="profile-root" path={`${path}`}>
      <ProfileRoot path={path} />
    </Route>,
    <Route exact key="profile-root-redirect" path={path}>
      <Redirect key="profile-root-redirect" to={`${path}/profile-root`} />
    </Route>,
  ];
}

export default Profile;
