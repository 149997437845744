import type { AssetAllocation } from 'model/AssetAllocation';
import { weightedAvg } from 'utils/math';

export const ALL_RATINGS = [
  'AAA',
  'AA+',
  'AA',
  'AA-',
  'A+',
  'A',
  'A-',
  'BBB+',
  'BBB',
  'BBB-',
  'BB+',
  'BB',
  'BB-',
  'B+',
  'B',
  'B-',
  'CCC+',
  'CCC',
  'CCC-',
  'CC',
  'C',
  'R',
  'SD',
  'D',
  'NR',
];

type Input = Pick<AssetAllocation, 'rating' | 'value'>;

export function getRatingValue(rating: string) {
  return ALL_RATINGS.indexOf(rating);
}

const avgIndex = weightedAvg<Input>(
  (asset) => getRatingValue(asset.rating),
  (asset) => asset.value,
);

export function getAverageRating(assets: readonly Input[]): string {
  const assetsExcludeNr = assets.filter((asset) => asset.rating !== 'NR');
  return ALL_RATINGS[Math.round(avgIndex(assetsExcludeNr))] ?? 'NR';
}
