import styled from 'styled-components';

import { ReactComponent as DefaultLogo } from 'assets/brand/cvlogo.svg';
import { ReactComponent as DefaultLogoWithOneFamily } from 'assets/brand/cvlogo_onefamily.svg';

export const Container = styled.div`
  max-width: 16.25rem;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
`;

export const CVLogo = styled(DefaultLogo)`
  width: 100%;
`;

export const CVLogoWithOneFamily = styled(DefaultLogoWithOneFamily)`
  width: 100%;
`;
