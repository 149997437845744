import { useMemo } from 'react';

export function useLinks({
  categories,
  getSlugByCategoryName,
  url,
}: {
  categories: readonly string[];
  getSlugByCategoryName: (categoryName: string) => string;
  url: string;
}) {
  return useMemo(
    () =>
      categories.map((category) => ({
        name: category,
        value: `${url}/categories/${getSlugByCategoryName(category)}`,
      })),
    [categories, getSlugByCategoryName, url],
  );
}
