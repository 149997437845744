import type { FormikHelpers } from 'formik';
import { useCallback } from 'react';

import useLogin from 'api/hooks/auth/useLogin';
import useRequestResetPassword from 'api/hooks/auth/useRequestResetPassword';
import useResetPassword from 'api/hooks/auth/useResetPassword';
import type { PasswordResetFormModel } from 'components/PasswordResetForm';
import type { PasswordResetRequestFormModel } from 'components/PasswordResetRequestForm';

export const useRequestSubmitHandler = () => {
  const { loading, requestResetPassword, success } = useRequestResetPassword();

  const handleRequestSubmit = useCallback(
    async (
      values: PasswordResetRequestFormModel,
      { setFieldError }: FormikHelpers<PasswordResetRequestFormModel>,
    ) => {
      try {
        await requestResetPassword({ email: values.email });
      } catch (e) {
        const messageError = (e as Error).message;
        if (messageError === 'Account Locked') {
          setFieldError('email', messageError);
        } else {
          setFieldError(
            'email',
            'There was an error. Please check that the e-mail is valid.',
          );
        }
      }
    },
    [requestResetPassword],
  );

  return { loading, handleRequestSubmit, success };
};

export const useResetSubmitHandler = ({
  email,
  token,
}: {
  email: string | undefined;
  token: string | undefined;
}) => {
  const { loading: resetLoading, resetPassword } = useResetPassword();
  const { loading: loginLoading, login } = useLogin();

  const handleResetSubmit = useCallback(
    async (
      values: PasswordResetFormModel,
      { setFieldError }: FormikHelpers<PasswordResetFormModel>,
    ) => {
      if (!email || !token) {
        return;
      }

      try {
        await resetPassword({
          email,
          password: values.newPassword,
          token,
        });
        await login({
          email,
          password: values.newPassword,
        });
      } catch (e) {
        const messageError = (e as Error).message;
        setFieldError('confirmPassword', messageError ?? 'There was an error.');
      }
    },
    [email, login, resetPassword, token],
  );

  return { loading: resetLoading || loginLoading, handleResetSubmit };
};
