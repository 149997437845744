import { FC, memo } from 'react';
import { matchPath } from 'react-router';

import Brand from 'components/Brand';
import { useNews } from 'containers/Services/NewsService';
import { formatDate } from 'utils/dates';

import {
  AllocationsIcon,
  AnalysisIcon,
  AvatarButton,
  Container,
  Content,
  Dot,
  Heading,
  HomeIcon,
  Info,
  Label,
  LastUpdated,
  Link,
  LinkContainer,
  LinkContent,
  MarketIcon,
  Name,
  Navigation,
  NewsIcon,
  ProfileIcon,
  ProfileLinkContainer,
  TransactionsIcon,
} from './styles';
import type { Props } from './types';

const ROUTES = [
  {
    Icon: HomeIcon,
    label: 'Home',
    url: '/home',
  },
  {
    Icon: AllocationsIcon,
    label: 'Allocations',
    url: '/allocations',
  },
  {
    Icon: AnalysisIcon,
    label: 'Risk',
    url: '/risk',
  },
  {
    Icon: MarketIcon,
    label: 'Markets',
    url: '/markets',
  },
  {
    Icon: TransactionsIcon,
    label: 'Transactions',
    url: '/transactions',
  },
  {
    Icon: NewsIcon,
    label: 'News',
    url: '/news',
  },
];

const Sidebar: FC<Props> = ({
  className,
  initials,
  name,
  lastUpdated,
  location,
  onAvatarClick,
}) => {
  const newsServices = useNews();

  return (
    <Container className={className}>
      <Heading>
        <Brand />
        <AvatarButton onClick={onAvatarClick}>{initials}</AvatarButton>
        <Info>
          <Name>{name}</Name>
          <LastUpdated>Last updated: {formatDate(lastUpdated)}</LastUpdated>
        </Info>
      </Heading>
      <Content>
        <Navigation>
          {ROUTES.map((route) => (
            <LinkContainer key={route.url}>
              <Link
                $active={!!matchPath(location, route.url)}
                routerLink={route.url}
              >
                <LinkContent id={`btn-menu-${route.label.toLowerCase()}`}>
                  {route.label === 'News' && newsServices?.hasNewsUnread && (
                    <Dot />
                  )}
                  <route.Icon />
                  <Label>{route.label}</Label>
                </LinkContent>
              </Link>
            </LinkContainer>
          ))}
        </Navigation>
        <ProfileLinkContainer>
          <Link
            $active={!!matchPath(location, '/profile')}
            routerLink="/profile"
          >
            <LinkContent id="btn-menu-profile">
              <ProfileIcon />
              <Label>Profile</Label>
            </LinkContent>
          </Link>
        </ProfileLinkContainer>
      </Content>
    </Container>
  );
};

export default memo(Sidebar);
