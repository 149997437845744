import useVolatilityReport from './useVolatilityReport';

export default function useRollingVolatilityChartData({
  client,
  portfolioName,
}: {
  client: string | undefined;
  portfolioName: string | undefined;
}) {
  const { data } = useVolatilityReport({
    client,
    portfolioName,
  });

  return {
    data: data?.rollingVolatilityChartData,
  };
}
