import styled, { css } from 'styled-components';

import { ReactComponent as EyeOff } from 'assets/icons/eye-off.svg';
import { ReactComponent as Eye } from 'assets/icons/eye.svg';

import type { IconProps } from './types';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white5};
  border: 1px solid transparent;
  border-radius: 0.5rem;
  position: relative;

  :focus-within {
    border-color: ${({ theme }) => theme.colors.white10};
  }
`;

export const HTMLInput = styled.input`
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.white};
  padding: 1.1875rem 3.125rem 1.125rem 1rem;
  outline: none;

  ::placeholder {
    color: ${({ theme }) => theme.colors.white50};
  }

  width: 100%;
`;

const iconStyles = css<{ $disabled: IconProps['$disabled'] }>`
  position: absolute;
  z-index: 2;
  cursor: pointer;
  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}
`;

export const ShowIcon = styled(Eye)`
  ${iconStyles};
  width: 1.25rem;
  height: 1rem;
  top: 1.2188rem;
  right: 1.2188rem;
`;

export const HideIcon = styled(EyeOff)`
  ${iconStyles};
  width: 1.5rem;
  height: 1.5rem;
  top: 1rem;
  right: 1.075rem;
`;
