import styled from 'styled-components';

import Tabs from 'components/Tabs';
import { TabButton } from 'components/Tabs/styles';
import PageDetailLayout from 'containers/Common/PageDetailLayout';

export const Container = styled(PageDetailLayout)``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 1.25rem;
`;

export const TableTabs = styled(Tabs)`
  margin-bottom: 1.75rem;
  ${TabButton} {
    min-width: 4.5rem;
  }
` as typeof Tabs;
