import { FC, memo } from 'react';

import {
  formatAmount,
  formatAmountWithDecimals,
  formatNumberDecimals,
} from 'utils/amounts';
import { formatPercentage } from 'utils/percentages';

import {
  Col,
  ColouredAmount,
  FirstCol,
  Header,
  HeaderCell,
  HeaderTitle,
  Row,
  Table,
  Title,
  VectorDownIcon,
  VectorUpIcon,
} from './styles';
import type { Props } from './types';

const CategoryDetailTable: FC<Props> = ({
  data,
  handleSortByColumn,
  sortApplied,
  headerColumns,
}) => (
  <Table>
    <Header>
      {headerColumns.map((col) => (
        <HeaderCell key={col.titleColumn}>
          <HeaderTitle
            onClick={() =>
              handleSortByColumn ? handleSortByColumn(col) : undefined
            }
          >
            <Title>{col.titleColumn}</Title>
            {sortApplied?.titleColumn === col.titleColumn &&
              (sortApplied?.orderOrientation === 'DESC' ? (
                <VectorDownIcon />
              ) : (
                <VectorUpIcon />
              ))}
          </HeaderTitle>
        </HeaderCell>
      ))}
    </Header>
    {data.map((row) => (
      <Row key={row.id}>
        <FirstCol>{row.name}</FirstCol>
        <Col>{formatAmount(row.marketValue)}</Col>
        <Col>{formatNumberDecimals(row.shares)}</Col>
        <Col>{formatAmountWithDecimals(row.marketPrice)}</Col>
        <Col>{formatPercentage(row.totalPorc)}</Col>
        <Col>{formatAmount(row.cost)}</Col>
        <Col>{formatAmount(row.dividendsReceived)}</Col>
        <ColouredAmount value={row.totalProfitLoss} />
      </Row>
    ))}
  </Table>
);
export default memo(CategoryDetailTable);
