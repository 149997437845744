import styled from 'styled-components';

import Amount from 'components/Amount';
import ChangePercentage from 'components/ChangePercentage';

export const TableMobile = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1rem;
  line-height: 1.0813rem;
`;

export const RowMobile = styled.div`
  padding: 1rem 0;
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.white10};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  row-gap: 0.5rem;

  > :first-child {
    color: ${({ theme }) => theme.colors.white75};
    grid-column: span 2;
    font-size: 0.875rem;
    line-height: 1.0625rem;
  }
  > :not(:last-child) {
    text-align: left;
  }
`;

export const Col = styled.div`
  text-align: right;
`;

export const AmountCol = styled(Amount)`
  text-align: right;
`;

export const ChangeCol = styled(ChangePercentage)`
  text-align: right;
`;
