import type { FC } from 'react';
import { useCallback, useMemo } from 'react';

import EnableBiometricsPrompt from 'components/EnableBiometricsPrompt';

import type { Props } from './types';

const EnableBiometrics: FC<Props> = ({
  onEnable,
  onReject: parentOnReject,
}) => {
  const biometricType = 'generic' as 'face-id' | 'touch-id' | 'generic';

  const loginMethod = useMemo(() => {
    switch (biometricType) {
      case 'face-id':
        return 'Face ID';
      case 'touch-id':
        return 'Touch ID';
      case 'generic':
      default:
        return 'Biometric Log In';
    }
  }, [biometricType]);

  const onReject = useCallback(() => {
    if (localStorage.getItem('biometrics_prompted') !== 'true') {
      // The native dialog is fine here because this is only executed in
      // mobile and never in desktop, so the mobile OS' UI is shown.
      // eslint-disable-next-line no-alert
      alert(
        `In order to access the app offline, you will need to enable ${loginMethod}. You can do this later in the app's settings.`,
      );
    }

    parentOnReject();
  }, [loginMethod, parentOnReject]);

  return (
    <EnableBiometricsPrompt
      biometricType="generic"
      onEnable={onEnable}
      onReject={onReject}
    />
  );
};

export default EnableBiometrics;
