import groupBy from 'lodash/groupBy';
import { useMemo, useState } from 'react';

import { getRatingValue } from 'utils/fixedIncome';

import type { Props } from './types';

export function useRatings({ assets }: { assets: Props['assets'] }) {
  // The absoluteRatings hold the absolute allocation, or the allocation
  // relative to the whole portfolio.
  const absoluteRatings = useMemo(
    () =>
      Object.entries(groupBy(assets, (asset) => asset.rating))
        .map(([rating, filteredAssets]) => ({
          allocation: filteredAssets.reduce(
            (acc, asset) => acc + asset.allocation,
            0,
          ),
          assets: filteredAssets,
          id: rating,
          rating,
        }))
        .sort((a, b) => getRatingValue(a.rating) - getRatingValue(b.rating)),
    [assets],
  );

  const sumOfAllocations = useMemo(
    () => absoluteRatings.reduce((acc, { allocation }) => acc + allocation, 0),
    [absoluteRatings],
  );

  // These relativeRatings hold the allocation relative to only to the sum of
  // current assets
  return useMemo(
    () =>
      absoluteRatings.map(
        ({ allocation, assets: filteredAssets, ...rating }) => ({
          allocation: allocation / sumOfAllocations,
          assets: filteredAssets.map((asset) => ({
            ...asset,
            fixedIncomeAllocation: asset.allocation / sumOfAllocations,
          })),
          ...rating,
        }),
      ),
    [absoluteRatings, sumOfAllocations],
  );
}

type Option = {
  label: string;
  value: string;
};

export function useDataSelection({
  ratings,
}: {
  ratings: ReturnType<typeof useRatings>;
}) {
  const dataOptions = useMemo(
    (): readonly [Option, ...Option[]] => [
      {
        label: 'Summary',
        value: 'summary',
      },
      ...ratings.map(({ rating }) => ({
        value: `${rating}`,
        label: `${rating}`,
      })),
    ],
    [ratings],
  );

  const [selectedData, setSelectedData] = useState(dataOptions[0].value);

  const ratingDetail = useMemo(
    () =>
      selectedData === 'summary'
        ? undefined
        : ratings.find(({ rating }) => selectedData === rating)?.assets,
    [ratings, selectedData],
  );

  return { dataOptions, ratingDetail, selectedData, setSelectedData };
}
