import { StatusCodes } from 'http-status-codes';
import { useCallback } from 'react';
import type { MutationFunction } from 'react-query';

import useDeviceInfo from 'api/hooks/auth/useDeviceInfo';
import useRequest from 'api/request';
import { useRefreshToken } from 'containers/Services/RefreshTokenService';
import { normalizeTokens, Tokens } from 'model/Tokens';
import usePlatform from 'utils/usePlatform/usePlatform';
import withoutNulls from 'utils/withoutNulls';

import type {
  LoginMutationVariables,
  LoginRequest,
  LoginResponse,
} from './types';

export default function useLoginMutation(): MutationFunction<
  Tokens & {
    passwordResetData:
      | {
          email: string;
          token: string;
        }
      | undefined;
    termsAgreed: boolean;
  },
  LoginMutationVariables
> {
  const request = useRequest<LoginRequest, LoginResponse>();
  const refreshTokenContext = useRefreshToken();
  const { isWeb } = usePlatform();
  const { deviceInfo } = useDeviceInfo();

  return useCallback(
    async ({ email, password, rememberUsername }: LoginMutationVariables) => {
      const response = await request('/api/Authentication/login2', {
        body: {
          email,
          password,
          secureStorage: !isWeb,
        },
        method: 'post',
      });

      if (response.status === StatusCodes.UNAUTHORIZED) {
        return {
          accessToken: undefined,
          passwordResetData: undefined,
          refreshToken: undefined,
          termsAgreed: false,
        };
      }

      const data = withoutNulls(await response.json());

      if (response.status === StatusCodes.LOCKED) {
        throw new Error(data.message);
      }

      if (
        response.status === StatusCodes.PRECONDITION_FAILED &&
        data.message === 'Client agreement failed'
      ) {
        return {
          ...normalizeTokens(data),
          passwordResetData: undefined,
          termsAgreed: false,
        };
      }

      if (data.data?.token && data.message === 'Password Reset') {
        // When the back-end forces a password reset, they send in the "token"
        // field a password reset url with the query params we need.
        const url = new URL(data.data.token);

        return {
          ...normalizeTokens(data),
          passwordResetData: {
            email: url.searchParams.get('email') ?? '',
            token: url.searchParams.get('token') ?? '',
          },
          termsAgreed: true,
        };
      }

      if (!data.isSuccess) {
        throw new Error(data.message);
      }

      localStorage.setItem('unlock_automatic_enabled', 'true');

      if (localStorage.getItem('login_remember_username') || rememberUsername) {
        localStorage.setItem(
          'login_remember_username',
          JSON.stringify({ email, rememberUsername }),
        );
      }

      refreshTokenContext?.setRefreshingToken(false);
      refreshTokenContext?.setCouldResfreshToken(true);

      await deviceInfo({
        refreshToken: data.data?.refreshToken,
      });

      return {
        ...normalizeTokens(data),
        passwordResetData: undefined,
        termsAgreed: true,
      };
    },
    [deviceInfo, isWeb, refreshTokenContext, request],
  );
}
