import styled, { css } from 'styled-components';

import Button from 'components/Button';
import { prettyScrollbar } from 'styles/mixins';

import type { $SettingsButton } from './types';

export const PROFILES_COLUMN_WIDTH = '5.625rem';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.dark2};
  display: grid;
  grid-template-columns: ${PROFILES_COLUMN_WIDTH} auto;
  height: 100vh;
`;

const scrollable = css`
  ${prettyScrollbar({
    vertical: true,
  })};

  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Profiles = styled.div`
  background-color: ${({ theme }) => theme.colors.dark1};
  max-height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
`;

export const ProfileList = styled.div`
  ${scrollable};
  padding-top: 4rem;
  padding-bottom: 2rem;

  > *:not(:last-child) {
    margin-bottom: 1.7rem;
  }
`;

export const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.625rem 0;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.white10};
  > :first-child {
    margin-bottom: 1.625rem;
  }
`;

export const SettingsButton = styled(Button).attrs<$SettingsButton>(
  ({ $isActive }) => ({
    color: $isActive ? 'primary' : 'secondary',
    variant: 'only-icon',
  }),
)<$SettingsButton>``;

export const Content = styled.div`
  ${scrollable};
  padding: 0 1.25rem 3.0938rem 1.25rem;
`;
