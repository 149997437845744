import styled, { css } from 'styled-components';

import AllocationsHeader from 'components/AllocationsHeader';
import SectionNavigation from 'components/SectionNavigation';
import MainContentGrid from 'containers/Common/MainContentGrid';
import PageDetailLayout from 'containers/Common/PageDetailLayout';
import { MobileContent } from 'containers/Common/PageDetailLayout/styles';
import from from 'styles/responsive';
import ZIndex from 'styles/ZIndex';

export const Container = styled(PageDetailLayout)<{
  $enableCSSPropAsWorkaround: boolean;
}>`
  ${({ $enableCSSPropAsWorkaround }) =>
    $enableCSSPropAsWorkaround &&
    css`
      ${MobileContent} {
        z-index: ${ZIndex.MOBILE_CONTENT};
      }
    `};
`;
export const Grid = styled(MainContentGrid)`
  padding: 0;
  grid-template-columns: 1fr;
  ${from.tablet`
    display: grid;
    grid-template-rows: repeat(4, auto);
    padding: 0;
    grid-template-columns: 10.4375rem 1fr;
    grid-column-gap: 1.5rem;
  `}
`;

export const Header = styled(AllocationsHeader)`
  ${from.laptop`
    margin-bottom: 3.5rem;
  `}
`;

export const Navigation = styled(SectionNavigation)`
  padding-top: 1rem;
`;
