import { FC, memo } from 'react';

import { formatPercentage } from 'utils/percentages';

import { ColTitle, ColValue, Header, HeaderCell, Row, Table } from './styles';
import type { Props } from './types';

const CreditRiskFixedIncomeTable: FC<Props> = ({ data }) => {
  const headers = ['Ratings', 'Allocation'];

  return (
    <Table>
      <Header>
        {headers.map((header) => (
          <HeaderCell key={header}>{header}</HeaderCell>
        ))}
      </Header>
      {data.map((row) => (
        <Row key={row.id}>
          <ColTitle>{row.rating}</ColTitle>
          <ColValue>{formatPercentage(row.allocation)}</ColValue>
        </Row>
      ))}
    </Table>
  );
};

export default memo(CreditRiskFixedIncomeTable);
