import { useMemo } from 'react';

import type { AssetGrouping } from 'model/AssetGrouping';
import kebabCaseCustom from 'utils/kebabCaseCustom';

import useAllocationAssets from './useAllocationAssets';

export default function useAllocationAsset({
  assetSlug,
  categorySlug,
  grouping,
  portfolio,
}: {
  assetSlug: string;
  categorySlug: string;
  grouping: { groupBy: AssetGrouping; subselection: string } | undefined;
  portfolio: string | undefined;
}) {
  const { data: assets } = useAllocationAssets({
    portfolio,
    categorySlug,
    grouping,
  });

  return useMemo(
    () =>
      assets?.find(
        (localAsset) => kebabCaseCustom(localAsset.fullName) === assetSlug,
      ),
    [assets, assetSlug],
  );
}
