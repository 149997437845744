const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  currencySign: 'accounting',
});

const formatterWithDecimals = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  currencySign: 'accounting',
});

const formatterWithoutSign = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  currencySign: 'accounting',
});

export function roundNumberInteger(number: number): number {
  return Math.sign(number) * Math.floor(Math.abs(number) + 0.5);
}

export function formatAmount(amount: number): string {
  const roundAmount = roundNumberInteger(amount);
  return formatter.format(roundAmount);
}

export function formatAmountWithDecimals(amount: number): string {
  return formatterWithDecimals.format(amount);
}

export function formatNumberDecimals(amount: number): string {
  const formattedAmount = formatterWithoutSign.format(Number(amount));
  // With Intl.NumberFormat it's impossible to use the accounting mode without the currency sign, so we have to remove it manually.
  return formattedAmount.replace(/[^0123456789.,()]/, '');
}

const shortFormatter = Intl.NumberFormat('en-US', {
  maximumFractionDigits: 1,
});

export function formatAmountShort(dollars: number): string {
  const absoluteDollars = Math.abs(dollars);
  const sign = dollars < 0 ? '-' : '';

  const formatted = (() => {
    if (absoluteDollars < 1000) {
      return `${absoluteDollars}`;
    }

    const thousands = absoluteDollars / 1000;

    if (thousands < 1000) {
      return `${shortFormatter.format(thousands)}K`;
    }

    const millions = thousands / 1000;

    if (millions < 1000) {
      return `${shortFormatter.format(millions)}M`;
    }

    const billions = millions / 1000;
    return `${shortFormatter.format(billions)}B`;
  })();

  return `${sign}${formatted}`;
}
