import { Group } from '@visx/group';
import { Fragment, memo } from 'react';
import type { FC } from 'react';

import { BasePoint, Point } from './styles';
import type { Props } from './types';

const PointChart: FC<Props> = ({
  data,
  isTablet,
  isLaptop,
  xScale,
  yScale,
  widthToCenterBand,
  showInstrumentArrow,
}) => (
  <Group>
    {data.map((datum) => (
      <Fragment key={`point_${datum.instrumentType}_${datum.target}`}>
        <BasePoint
          $showInstrumentArrow={
            datum.instrumentType === showInstrumentArrow ||
            (isTablet && !isLaptop)
          }
          cx={(xScale(datum.instrumentType) || 0) + widthToCenterBand ?? 0}
          cy={yScale(datum.target)}
        />
        <Point
          cx={(xScale(datum.instrumentType) || 0) + widthToCenterBand ?? 0}
          cy={yScale(datum.target)}
        />
      </Fragment>
    ))}
  </Group>
);

export default memo(PointChart);
