import styled from 'styled-components';

import AllocationsHeader from 'components/AllocationsHeader';
import BaseAssetDetailTable from 'components/BaseAssetDetailTable';
import { ColTitle, ColValue } from 'components/BaseAssetDetailTable/styles';
import PageDetailLayout from 'containers/Common/PageDetailLayout';

export const Container = styled(PageDetailLayout)``;

export const Content = styled.div`
  padding: 0 1.25rem 2rem;
`;

export const Header = styled(AllocationsHeader)``;

export const Title = styled.h2`
  margin: 0 0 1rem;
  font-size: 1.125rem;
  line-height: 1.575rem;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.weights.regular};
`;

export const CashTable = styled(BaseAssetDetailTable)`
  margin-bottom: 2.875rem;
  > :last-child {
    border-bottom: none;
    ${ColTitle} {
      font-size: 1rem;
      line-height: 1.2063rem;
    }
    ${ColValue} {
      font-weight: ${({ theme }) => theme.weights.bold};
    }
  }
  > :nth-child(3) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.white50};
  }
`;

export const LastRow = styled.div`
  padding: 2rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.white10};
`;

export const RowAmount = styled.p`
  margin: 0;
`;

export const RowTitle = styled(Title)``;
