import type { PickByValue } from 'utility-types';

import type { AssetAllocation } from 'model/AssetAllocation';
import { weightedAvg } from 'utils/math';

import { getAverageRating } from './getAverageRating';

type Input = Pick<
  AssetAllocation,
  | 'annualIncome'
  | 'annualIncomeAfterTax'
  | 'coupon'
  | 'duration'
  | 'rating'
  | 'value'
  | 'ttc'
  | 'ytw'
  | 'tey'
>;

export default function getFixedIncomeCharacteristics(
  assets: readonly Input[],
) {
  function sum(key: keyof PickByValue<Input, number>) {
    return assets.reduce((total, asset) => asset[key] + total, 0);
  }

  function avg(key: keyof PickByValue<Input, number>) {
    return weightedAvg<Input>(
      (asset) => asset[key],
      (asset) => asset.value,
    )(assets);
  }

  return {
    annualIncome: sum('annualIncome'),
    annualIncomeAfterTax: sum('annualIncomeAfterTax'),
    coupon: avg('coupon'),
    duration: avg('duration'),
    rating: getAverageRating(assets),
    timeToMaturity: avg('ttc'),
    yieldToWorst: avg('ytw'),
    taxEquivalentYield: avg('tey'),
  };
}
