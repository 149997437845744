import styled, { css } from 'styled-components';

import DefaultPortfolioHistoryChart from 'components/PortfolioHistoryChart';
import DefaultSpinner from 'components/Spinner';
import PageDetailLayout from 'containers/Common/PageDetailLayout';

export const LoaderContainer = styled.div`
  position: sticky;
  top: 50%;
  padding-left: 50%;
  z-index: 1000;
`;

export const Container = styled(PageDetailLayout)<{
  $loading: boolean;
  $mobile: boolean;
}>`
  ${({ $loading }) =>
    $loading &&
    css`
      pointer-events: none;
      filter: blur(1px);
    `};

  ${({ $loading, $mobile }) =>
    $loading &&
    !$mobile &&
    css`
      margin-top: -4.5rem;
    `};
`;

export const Title = styled.h2`
  margin: 0 0 1.5rem 0.25rem;
  font-size: 1.375rem;
  line-height: 1.4;
  font-weight: ${({ theme }) => theme.weights.regular};
`;

export const Spinner = styled(DefaultSpinner)``;

export const PortfolioHistoryChart = styled(DefaultPortfolioHistoryChart)`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;
