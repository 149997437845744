import type { FC } from 'react';

import { AmountCol, ChangeCol, Col, RowMobile, TableMobile } from './styles';
import type { Props } from './types';

const BaseMobileBreakdownTable: FC<Props> = ({ className, data }) => (
  <TableMobile className={className}>
    {data.map((row) => (
      <RowMobile key={row.id}>
        <Col>{row.name}</Col>
        <AmountCol value={row.profitAndLoss} />
        <ChangeCol value={row.percentChange} />
      </RowMobile>
    ))}
  </TableMobile>
);

export default BaseMobileBreakdownTable;
