import { Bar as DefaultBar } from '@visx/shape';
import styled from 'styled-components';

import hexToRgb from 'utils/hexToRgb';

export const CustonBar = styled(DefaultBar)<{
  width: number;
  height: number;
}>`
  fill: currentColor;
  stroke: ${({ theme }) => theme.colors.primary};
  stroke-width: 2;
  stroke-dasharray: ${({ width }) => width} ${({ height }) => height};
  color: rgba(${({ theme }) => hexToRgb(theme.colors.primary)}, 0.2);
`;
