import styled from 'styled-components';

import from from 'styles/responsive';

import { Grid } from './PerformanceBreakdownCard/styles';

export const Container = styled.div``;

export const Content = styled.div`
  > :not(:last-child) ${Grid} {
    margin-bottom: 1rem;
  }
  ${from.tablet`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1.5rem;
    row-gap: 1rem;
    margin-bottom: 0;
    > :not(:last-child) ${Grid} {
      margin-bottom: 0;
    }
  `}
  ${from.laptop`
    display: block;
    > :not(:last-child) ${Grid} {
      margin-bottom: 1rem;
    }
  `}
`;

export const Title = styled.h2`
  font-size: 1.375rem;
  line-height: 1.4;
  margin: 0 0 1.5rem 0;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.weights.regular};
  ${from.tablet`
    margin: 0 0 1.875rem 0;
  `}
`;
