import { stringify } from 'querystring';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';

import useLoginStatus from 'api/hooks/auth/useLoginStatus';
import {
  ACCESS_TOKEN_QUERY_KEY,
  AccessTokenQuery,
} from 'api/queries/auth/accessToken';

import {
  useTrackAppOpened,
  useTrackLocationChange,
  useTrackUserID,
} from './logic';

export default function useConnect() {
  const loginStatus = useLoginStatus();
  const { push } = useHistory();
  const client = useQueryClient();

  useEffect(() => {
    if (loginStatus.type === 'password-reset-required') {
      client.setQueryData<AccessTokenQuery>(ACCESS_TOKEN_QUERY_KEY, {
        accessToken: undefined,
        email: undefined,
        passwordResetData: undefined,
        refreshToken: undefined,
        termsAgreed: false,
      });

      void push({
        pathname: '/reset-password',
        search: stringify({
          email: loginStatus.email,
          firstTime: true,
          token: loginStatus.token,
        }),
      });
    }
  }, [client, loginStatus, push]);

  useTrackAppOpened();
  useTrackLocationChange();
  useTrackUserID();

  return {
    loginStatus,
  };
}
