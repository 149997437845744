import { localPoint } from '@visx/event';
import { scaleBand, scaleLinear } from '@visx/scale';
import { useTooltip as useVisxTooltip } from '@visx/tooltip';
import { useMemo } from 'react';
import type { MouseEvent, TouchEvent } from 'react';

import type { Theme } from 'styles/themes';

import type { ChartKey, Datum, TooltipDatum } from './types';

export function formatChartKey(key: ChartKey) {
  switch (key) {
    case 'current':
      return 'Allocation';
    case 'target':
      return 'Target';
    default:
    case 'range':
      return 'Range';
  }
}

export function getChartKeyColor(key: ChartKey, theme: Theme) {
  switch (key) {
    case 'current':
      return theme.colors.primary;
    case 'target':
      return theme.colors.statusDecrease;
    default:
    case 'range':
      return theme.colors.primary;
  }
}

export const getDate = (d: Datum) => d.date;

export function useHeight({ isTablet }: { isTablet: boolean }): number {
  if (isTablet) {
    return 425;
  }

  return 250;
}

export function useMargins({ isTablet }: { isTablet: boolean }) {
  // This builds on top of the padding of the Container element. Instead of
  // putting all on the padding, we put 1rem here so the chart line, which has
  // a thick stroke, doesn't get cut. We also put the rest on the Container
  // element so it is easier for other developers to change the styles.
  const topMargin = 16;

  // This can't simply be padding on the Container element because it wouldn't
  // be noticed by the resize observer.
  const rightMargin = isTablet ? 32 : 16;

  const bottomAxisHeight = 48;
  const bottomAxisMargin = 12;

  const leftAxisWidth = isTablet ? 60 : 30;
  const leftAxisMargin = isTablet ? 24 : 16;
  const leftAxisPadding = 24;

  return {
    bottomAxisHeight,
    bottomAxisMargin,
    leftAxisMargin,
    leftAxisPadding,
    leftAxisWidth,
    rightMargin,
    topMargin,
  };
}

export const getTotalUpperBound = (d: Datum) => d.range.upperBound;
export const getTotalCurrent = (d: Datum) => d.current;
export const getTotalTarget = (d: Datum) => d.target;

export function useEdgeValues(data: readonly Datum[]) {
  const start = useMemo(
    () =>
      data.reduce(
        (minPercentage, datum) =>
          Math.min(
            minPercentage,
            datum.range.lowerBound,
            datum.current,
            datum.target,
          ),
        Infinity,
      ),
    [data],
  );

  const end = useMemo(
    () =>
      data.reduce(
        (maxPercentage, datum) =>
          Math.max(
            maxPercentage,
            datum.range.lowerBound,
            datum.current,
            datum.target,
          ),
        0,
      ),
    [data],
  );

  const totalsUpperBound = useMemo(() => data.map(getTotalUpperBound), [data]);
  const totalsCurrent = useMemo(() => data.map(getTotalCurrent), [data]);
  const totalsTarget = useMemo(() => data.map(getTotalTarget), [data]);

  const totals = totalsUpperBound.concat(totalsCurrent).concat(totalsTarget);

  const maxValue = useMemo(() => Math.max(...totals), [totals]);
  const minValue = useMemo(() => Math.min(...totals), [totals]);

  return {
    end,
    maxValue,
    minValue,
    start,
  };
}

export function useScales(props: {
  bottomAxisHeight: number;
  bottomAxisMargin: number;
  data: Datum[];
  end: number;
  height: number;
  leftAxisMargin: number;
  leftAxisPadding: number;
  leftAxisWidth: number;
  maxValue: number;
  minValue: number;
  rightMargin: number;
  start: number;
  topMargin: number;
  width: number;
}) {
  // Destructured here rather than on the "props" parameter itself so that the
  // parameter hint in IntelliJ based IDEs isn't gigantic.
  const {
    bottomAxisHeight,
    bottomAxisMargin,
    data,
    height,
    leftAxisMargin,
    leftAxisWidth,
    maxValue,
    rightMargin,
    topMargin,
    width,
  } = props;

  const xScale = useMemo(
    () =>
      scaleBand({
        domain: data.map(getDate),
        range: [leftAxisWidth + leftAxisMargin, width - rightMargin],
      }),
    [leftAxisMargin, leftAxisWidth, rightMargin, width, data],
  );

  const yScale = useMemo(
    () =>
      scaleLinear({
        domain: [0, maxValue],
        range: [height - bottomAxisHeight - bottomAxisMargin, topMargin],
        nice: true,
        clamp: true,
      }),
    [bottomAxisHeight, bottomAxisMargin, height, maxValue, topMargin],
  );

  return { xScale, yScale };
}

export function useTooltip({
  data,
  leftAxisMargin,
  leftAxisWidth,
  xScale,
}: {
  xScale: ReturnType<typeof useScales>['xScale'];
  yScale: ReturnType<typeof useScales>['yScale'];
  data: readonly Datum[];
  leftAxisMargin: number;
  leftAxisWidth: number;
  height: number;
  width: number;
}) {
  const { hideTooltip, showTooltip, tooltipData, tooltipLeft, tooltipTop } =
    useVisxTooltip<TooltipDatum>();

  const handleTooltipUpdated = useMemo(
    () => (event: MouseEvent<SVGRectElement> | TouchEvent<SVGRectElement>) => {
      if (event.target instanceof SVGRectElement) {
        // bar chart tooltip
        const { x: xBar, y: yBar } = localPoint(event) ?? { x: 0, y: 0 };

        const adjustedX = xBar - leftAxisMargin - leftAxisWidth;

        const eachBand = xScale.step();
        const index = Math.round(adjustedX / eachBand);
        const selectedDate = xScale.domain()[index];

        const datumBar = data.find((d) => d.date === selectedDate);

        if (!datumBar) {
          return undefined;
        }

        showTooltip({
          tooltipData: {
            instrumentType: datumBar.instrumentType,
            target: datumBar.target,
            current: datumBar.current,
            range: {
              lowerBound: datumBar.range.lowerBound,
              upperBound: datumBar.range.upperBound,
            },
            date: datumBar.date,
          },
          tooltipLeft: xBar,
          tooltipTop: yBar,
        });
      }

      return undefined;
    },
    [data, leftAxisMargin, leftAxisWidth, showTooltip, xScale],
  );

  return {
    handleTooltipClosed: () => hideTooltip(),
    handleTooltipUpdated,
    tooltipData,
    tooltipLeft,
    tooltipTop,
  };
}
