import type { FC } from 'react';
import { memo } from 'react';

import { formatNumberDecimals } from 'utils/amounts';

import { Container, Signs, Value } from './styles';
import type { Props } from './types';

const NumberAmount: FC<Props> = ({
  as,
  className,
  coloured = false,
  value,
}) => {
  const formattedChars = Array.from(formatNumberDecimals(value));
  const signs = formattedChars.splice(0, value >= 0 ? 0 : 1);
  const lastSign = value < 0 && formattedChars.splice(-1);

  return (
    <Container
      $coloured={coloured}
      $isNegative={value < 0}
      as={as}
      className={className}
    >
      <Signs $coloured={coloured}>{signs}</Signs>
      <Value>{formattedChars.join('')}</Value>
      <Signs $coloured={coloured}>{lastSign}</Signs>
    </Container>
  );
};

export default memo(NumberAmount);
