import { useCallback } from 'react';
import type { QueryFunction } from 'react-query';

import useRequest from 'api/request';

import type {
  GetTermsAndConditionsRequest,
  GetTermsAndConditionsResponse,
} from './types';

export const GET_TERMS_AND_CONDITIONS_QUERY_KEY = () => [
  'auth/getTermsAndConditions',
];

export default function useGetTermsAndConditionsQuery(): QueryFunction<string> {
  const request = useRequest<
    GetTermsAndConditionsRequest,
    GetTermsAndConditionsResponse
  >();

  return useCallback(async () => {
    const response = await request('/api/ClientData/getTermsConditions');

    const data = await response.json();

    if (!data.isSuccess) {
      throw new Error(data.message ?? '');
    }

    return data.data ?? '';
  }, [request]);
}
