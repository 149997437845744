import { LinePath } from '@visx/shape';
import type { LinePathProps } from '@visx/shape/lib/shapes/LinePath';
import styled from 'styled-components';

import type { Datum } from '../types';

function DatumLinePath(props: LinePathProps<Datum>) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <LinePath<Datum> {...props} />;
}

export const Path = styled(DatumLinePath)`
  stroke: currentColor;
  stroke-width: 0.25rem;
  color: ${({ theme }) => theme.colors.primary};
`;
