import { parse, stringify } from 'query-string';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyFunction = (...args: any[]) => any;

export type Option<T extends AnyFunction> = {
  fn?: T;
  label: string;
  value: string;
};

export function useDataManipulation<T extends AnyFunction>({
  key,
  options,
}: {
  key: string;
  options: readonly [Option<T>, ...Option<T>[]];
}) {
  const { replace } = useHistory();
  const { hash, pathname, search, state } = useLocation();

  const parsedSearch = parse(search);
  const rawValue = parsedSearch[key];

  const value: string | undefined =
    typeof rawValue === 'string' ? rawValue : undefined;

  const setValue = useCallback(
    (newValue: string | undefined) => {
      replace({
        hash,
        pathname,
        search: stringify({
          ...parse(search),
          [key]: newValue,
        }),
        state,
      });
    },
    [hash, key, pathname, replace, search, state],
  );

  const currentOption = useMemo(
    () => options.find((option) => option.value === value) ?? options[0],
    [options, value],
  );

  const currentOptions = useMemo(() => {
    const result = options.filter((option) =>
      value?.split(',').includes(option.value),
    );

    return result ?? options[0];
  }, [options, value]);

  const onChange = useCallback(
    (newValue: string) => {
      if (newValue === 'default') {
        // If the value is the default, we save undefined instead so the sort
        // option dropdown shows the placeholder. Since currentFiltering falls
        // back to the first option on undefined keys, this is fine.
        setValue(undefined);
      } else {
        setValue(newValue);
      }
    },
    [setValue],
  );

  return {
    currentManipulation: value,
    fn: currentOption.fn,
    currentOptions,
    onChangeManipulation: onChange,
    options,
  };
}
