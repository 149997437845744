import type { BaseThemedCssFunction, DefaultTheme } from 'styled-components';
import { css } from 'styled-components';

import keysOf from 'utils/keysOf';

const size = {
  tablet: '1000px',
  laptop: '1200px',
  desktop: '1800px',
} as const;

const maxWidthQuery = (width: string) => `@media (max-width: ${width})`;
const minWidthQuery = (width: string) => `@media (min-width: ${width})`;
export const isNotTouchable = '@media (hover: hover) and (pointer: fine)';

const mediaQueryCss =
  (queryFunction: (width: string) => string) =>
  (width: string): BaseThemedCssFunction<DefaultTheme> =>
  (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    first: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...args: any
  ) =>
    css`
      ${queryFunction(width)} {
        ${css(first, ...args)};
      }
    `;

const maxWidth = mediaQueryCss(maxWidthQuery);
const minWidth = mediaQueryCss(minWidthQuery);

interface Breakpoint extends ReturnType<typeof minWidth> {
  query: string;
}

const from = keysOf(size).reduce(
  (acc, key) => ({
    ...acc,
    [key]: (() => {
      const mixin = minWidth(size[key]) as Breakpoint;
      mixin.query = minWidthQuery(size[key]);
      return mixin;
    })(),
  }),
  {} as { [key in keyof typeof size]: Breakpoint },
);

export const upTo = keysOf(size).reduce(
  (acc, key) => ({
    ...acc,
    [key]: (() => {
      const mixin = maxWidth(size[key]) as Breakpoint;
      mixin.query = maxWidthQuery(size[key]);
      return mixin;
    })(),
  }),
  {} as { [key in keyof typeof size]: Breakpoint },
);

export default from;
