import type { GetClientsResponse } from 'api/queries/clients/getClients/types';
import type { WithoutNulls } from 'utils/withoutNulls';

import { normalizePortfolio } from './Portfolio';

type Input = NonNullable<GetClientsResponse['data']>[number];

export const normalizeClient = (input: WithoutNulls<Input>) =>
  ({
    id: `client_${input.id}`,
    initials: input.name?.slice(0, 2) ?? '',
    name: input.name ?? '',
    subPortfolios: input.subPortfolios?.map(normalizePortfolio) ?? [],
    countryState_domicile: input.countryState_domicile ?? 'Non-US',
  } as const);

export type Client = ReturnType<typeof normalizeClient>;
