import type { FC } from 'react';

import SummaryFixedIncomeProfileTable from 'components/SummaryFixedIncomeProfileTable';

import useConnect from './connect';
import { Container, IncomeProfileChart, Title, Toolbar } from './styles';
import type { Props } from './types';

const IncomeProfile: FC<Props> = ({ enabledAfterTax = false }) => {
  const { incomeProfile } = useConnect();

  return (
    <Container>
      <IncomeProfileChart
        data={incomeProfile.map((d) => ({
          date: d.date,
          income: enabledAfterTax ? d.incomeAfterTax : d.income,
        }))}
        withAverage={false}
      />
      <Toolbar>
        <Title>Yearly Fixed Income Profile</Title>
      </Toolbar>
      <SummaryFixedIncomeProfileTable
        data={incomeProfile.map((d) => ({
          id: d.id,
          date: d.date,
          income: enabledAfterTax ? d.incomeAfterTax : d.income,
        }))}
        period="yearly"
      />
    </Container>
  );
};
export default IncomeProfile;
