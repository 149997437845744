import type { ScaleBand } from 'd3-scale';
import { useEffect, useRef, useState } from 'react';

const WIDTH_SAFE_OFFSET = 4;

/**
 * Provides an easy way to rotate bottom axis ticks
 * from horizontal to vertical so they can be read
 * better when there are tons of ticks
 */
export default function useScaleBandBottomAxisTickRotation(
  width: number,
  xScale: ScaleBand<number>,
) {
  const [vertical, setVertical] = useState(false);
  const [tickTextWidth, setTickTextWidth] = useState<number>(width);
  const bottomGroupRef = useRef<SVGGElement>(null);

  useEffect(() => {
    if (!bottomGroupRef.current) return;
    const tick =
      bottomGroupRef.current.querySelector<SVGGElement>('.visx-axis-tick');
    if (!tick) return;
    const tickWidth = tick.getBBox().width;
    setTickTextWidth(tickWidth);
  }, [bottomGroupRef]);

  useEffect(() => {
    const step = xScale.step();
    setVertical(step < tickTextWidth + WIDTH_SAFE_OFFSET);
  }, [width, tickTextWidth, xScale]);

  return { bottomGroupRef, vertical };
}
