import { FC, memo, useCallback, useState } from 'react';

import { Container, HideIcon, HTMLInput, ShowIcon } from './styles';
import type { Props } from './types';

const Input: FC<Props> = ({
  disabled,
  id,
  name,
  onChange,
  onKeyDown,
  placeholder,
  required,
  type,
  value,
}) => {
  const [isRevealPwd, setIsRevealPwd] = useState<boolean>(false);

  const showIconEye = useCallback(() => {
    setIsRevealPwd(true);
  }, []);

  const showIconEyeOff = useCallback(() => {
    setIsRevealPwd(false);
  }, []);

  return (
    <Container>
      <HTMLInput
        disabled={disabled}
        id={id}
        onChange={onChange}
        name={name}
        placeholder={placeholder}
        required={required}
        type={type === 'password' && isRevealPwd ? 'text' : type}
        value={value}
        onKeyDown={onKeyDown}
      />
      {type === 'password' &&
        (isRevealPwd ? (
          <HideIcon
            onClick={showIconEyeOff}
            $disabled={value === '' || disabled}
          />
        ) : (
          <ShowIcon
            onClick={showIconEye}
            $disabled={value === '' || disabled}
          />
        ))}
    </Container>
  );
};

export default memo(Input);
