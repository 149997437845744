import { useLocation } from 'react-router';

import useSelectedClient from 'api/hooks/clients/useSelectedClient';
import { usePortfolioSwitcher } from 'containers/Services/PortfolioSwitcherContext';

export default function useConnect() {
  const { pathname } = useLocation();
  const { selectedClient, selectedPortfolio } = useSelectedClient();

  const portfolioSwitcherContext = usePortfolioSwitcher();

  return {
    openPortfolioSwitcher: portfolioSwitcherContext.onOpenPortfolioSwitcher,
    selectedClient,
    selectedPortfolio,
    pathname,
  };
}
