import { subMonths } from 'date-fns';
import uniq from 'lodash/uniq';
import { useMemo } from 'react';

import type { ClientTransactionDatum } from 'model/ClientTransaction';
import { formatAmountWithDecimals } from 'utils/amounts';
import useEntitySorting, {
  SortOption,
} from 'utils/useDataManipulation/useEntitySorting';

import type { FilterDate } from './ControlTabFilterDate/types';
import type { TypeTransactions } from './ControlTabType/types';

export function getEntitiesOptions(data: ClientTransactionDatum[]) {
  const entities = uniq(
    data.filter((item) => item.entity !== '').map((item) => item.entity),
  );
  return [
    { value: 'default', label: 'All' },
    ...entities.map((item) => ({ value: item, label: item })),
  ];
}

export function getAccountsOptions(data: ClientTransactionDatum[]) {
  const accounts = uniq(
    data.filter((item) => item.account !== '').map((item) => item.account),
  );
  return [
    { value: 'default', label: 'All' },
    ...accounts.map((item) => ({ value: item, label: item })),
  ];
}

export function getAssetsClassOptions(data: ClientTransactionDatum[]) {
  const assetsClass = uniq(
    data
      .filter((item) => item.assetClass !== '')
      .map((item) => item.assetClass),
  );
  return [
    { value: 'default', label: 'All' },
    ...assetsClass.map((item) => ({ value: item, label: item })),
  ];
}

export function getCategoriesOptions(data: ClientTransactionDatum[]) {
  const categories = uniq(
    data.filter((item) => item.category !== '').map((item) => item.category),
  );
  return [
    { value: 'default', label: 'All' },
    ...categories.map((item) => ({ value: item, label: item })),
  ];
}

export function applyTypeFilter(
  data: ClientTransactionDatum[],
  filter: TypeTransactions,
) {
  return filter !== 'all' ? data.filter((item) => item.type === filter) : data;
}

export function applySearchFilter(
  data: ClientTransactionDatum[],
  filter: string | undefined,
) {
  return filter !== undefined
    ? data.filter(
        (item) =>
          item.asset.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
          item.account
            .toLocaleLowerCase()
            .includes(filter.toLocaleLowerCase()) ||
          item.entity
            .toLocaleLowerCase()
            .includes(filter.toLocaleLowerCase()) ||
          item.description
            .toLocaleLowerCase()
            .includes(filter.toLocaleLowerCase()) ||
          item.assetClass
            .toLocaleLowerCase()
            .includes(filter.toLocaleLowerCase()) ||
          formatAmountWithDecimals(item.amount)
            .toString()
            .toLocaleLowerCase()
            .includes(filter.toLocaleLowerCase()),
      )
    : data;
}

export function applyEntityFilter(
  data: ClientTransactionDatum[],
  filterEntity: string[] | undefined,
) {
  return filterEntity !== undefined && filterEntity.length !== 0
    ? data.filter((item) => filterEntity.includes(item.entity))
    : data;
}

export function applyAccountFilter(
  data: ClientTransactionDatum[],
  filterAccount: string[] | undefined,
) {
  return filterAccount !== undefined && filterAccount.length !== 0
    ? data.filter((item) => filterAccount.includes(item.account))
    : data;
}

export function applyAssetClassFilter(
  data: ClientTransactionDatum[],
  filterAssetClass: string[] | undefined,
) {
  return filterAssetClass !== undefined && filterAssetClass.length !== 0
    ? data.filter((item) => filterAssetClass.includes(item.assetClass))
    : data;
}

export function applyCategoryFilter(
  data: ClientTransactionDatum[],
  filterCategory: string[] | undefined,
) {
  return filterCategory !== undefined && filterCategory.length !== 0
    ? data.filter((item) => filterCategory.includes(item.category))
    : data;
}

type AssetSortOption = SortOption<ClientTransactionDatum>;

export function useSorting() {
  const sortOptions: readonly [AssetSortOption, ...AssetSortOption[]] = useMemo(
    () => [
      {
        compare: (a, b) =>
          new Date(b.date).getTime() - new Date(a.date).getTime(),
        label: 'Date: High to Low',
        value: 'default',
      },
      {
        compare: (a, b) =>
          new Date(a.date).getTime() - new Date(b.date).getTime(),
        label: 'Date: Low to High',
        value: 'dateASC',
      },
      {
        compare: (a, b) => a.asset.localeCompare(b.asset),
        label: 'Asset: A to Z',
        value: 'assetDESC',
      },
      {
        compare: (a, b) => b.asset.localeCompare(a.asset),
        label: 'Asset: Z to A',
        value: 'assetASC',
      },
      {
        compare: (a, b) => a.entity.localeCompare(b.entity),
        label: 'Entity: A to Z',
        value: 'entityDESC',
      },
      {
        compare: (a, b) => b.entity.localeCompare(a.entity),
        label: 'Entity: Z to A',
        value: 'entityASC',
      },
      {
        compare: (a, b) => a.account.localeCompare(b.account),
        label: 'Account: A to Z',
        value: 'accountDESC',
      },
      {
        compare: (a, b) => b.account.localeCompare(a.account),
        label: 'Account: Z to A',
        value: 'accountASC',
      },
      {
        compare: (a, b) => a.description.localeCompare(b.description),
        label: 'Description: A to Z',
        value: 'descriptionDESC',
      },
      {
        compare: (a, b) => b.description.localeCompare(a.description),
        label: 'Description: Z to A',
        value: 'descriptionASC',
      },
      {
        compare: (a, b) => b.amount - a.amount,
        label: 'Amount: High to Low',
        value: 'amountDESC',
      },
      {
        compare: (a, b) => a.amount - b.amount,
        label: 'Amount: Low to High',
        value: 'amountASC',
      },
    ],
    [],
  );

  return useEntitySorting({ sortOptions });
}

export function getBeginDateAndEndDate(
  filterDate: FilterDate,
  rangeDate: [Date | undefined, Date | undefined],
) {
  const date = new Date();

  switch (filterDate) {
    case 'current-month':
      return {
        beginDate: new Date(date.getFullYear(), date.getMonth(), 1),
        endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
      };
    case 'prior-month': {
      const priorMonth = subMonths(date, 1);
      return {
        beginDate: new Date(priorMonth.getFullYear(), priorMonth.getMonth(), 1),
        endDate: new Date(
          priorMonth.getFullYear(),
          priorMonth.getMonth() + 1,
          0,
        ),
      };
    }
    case 'current-year': {
      const currentYear = date.getFullYear();
      return {
        beginDate: new Date(currentYear, 0, 1),
        endDate: new Date(currentYear, 11, 31),
      };
    }
    case 'prior-year': {
      const priorYear = date.getFullYear() - 1;
      return {
        beginDate: new Date(priorYear, 0, 1),
        endDate: new Date(priorYear, 11, 31),
      };
    }
    case 'custom-date': {
      const [beginDate, endDate] = rangeDate;
      if (beginDate && endDate) {
        return {
          beginDate,
          endDate,
        };
      }
      break;
    }
  }

  return {
    beginDate: new Date(date.getFullYear(), date.getMonth(), 1),
    endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
  };
}
