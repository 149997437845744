import type { FC } from 'react';
import { memo } from 'react';

import { formatPercentage } from 'utils/percentages';

import { Rating, TooltipWithBounds, Value } from './styles';
import type { Props } from './types';

const Tooltip: FC<Props> = ({ data, tooltipLeft, tooltipTop }) => (
  <TooltipWithBounds top={tooltipTop} left={tooltipLeft}>
    <Rating>{'average' in data ? data.average : data.rating}</Rating>
    <Value>{formatPercentage(data.allocation)}</Value>
  </TooltipWithBounds>
);

export default memo(Tooltip);
