import type { GetAssetClassBreakdownResponse } from 'api/queries/home/getAssetClassBreakdown/types';
import type { WithoutNulls } from 'utils/withoutNulls';

type Input = NonNullable<
  NonNullable<GetAssetClassBreakdownResponse['data']>[number]
>;

export const normalizeAssetClassBreakdownReport = (
  input: WithoutNulls<Input>,
) =>
  ({
    activity: input.activity ?? 0,
    changedAmount: input.changedAmount ?? 0,
    changedPercentage: input.changedPercentage ?? 0,
    endBalance: input.eoM_Balance ?? 0,
    endPortfolioPercentage: input.eoM_PortfolioPercentage ?? 0,
    id: `${input.id}`,
    name: input.instrumentName ?? '',
    startBalance: input.boM_Balance ?? 0,
    startPortfolioPercentage: input.boM_PortfolioPercentage ?? 0,
  } as const);

export type AssetClassBreakdownReport = ReturnType<
  typeof normalizeAssetClassBreakdownReport
>;
