import { FC, useMemo } from 'react';

import { formatAmount } from 'utils/amounts';

import useConnect from './connect';
import {
  CashTable,
  Container,
  Content,
  Header,
  LastRow,
  RowAmount,
  RowTitle,
  Title,
} from './styles';

const CashLiquidity: FC = () => {
  const { parentUrl, summary } = useConnect();

  const mobileRows = useMemo(
    () => [
      {
        id: 1,
        key: 'Cash (USD)',
        value: formatAmount(summary?.cashUSD ?? 0),
      },
      {
        id: 2,
        key: 'Cash (Other Currencies)',
        value: formatAmount(summary?.cashOther ?? 0),
      },
      {
        id: 3,
        key: 'Cash Equivalent',
        value: formatAmount(summary?.cashEquivalent ?? 0),
      },
      {
        id: 4,
        key: 'Total Cash Available',
        value: formatAmount(summary?.totalCashAvailable ?? 0),
      },
    ],
    [summary],
  );

  return (
    <Container parentUrl={parentUrl} title="Liquidity Available">
      <Header
        title="Total Liquidity Available"
        amount={summary?.totalLiquidityAvailable ?? 0}
      />
      <Content>
        <Title>Cash</Title>
        <CashTable rows={mobileRows} />
        <LastRow>
          <RowTitle>US Treasuries</RowTitle>
          <RowAmount>{formatAmount(summary?.treasuries ?? 0)}</RowAmount>
        </LastRow>
      </Content>
    </Container>
  );
};

export default CashLiquidity;
