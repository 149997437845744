import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { useNews } from 'containers/Services/NewsService';
import from from 'styles/responsive';
import type { OrderOrientation } from 'utils/sort/types';
import useMedia from 'utils/useMedia';

import AllocationsDesktopTablet from './AllocationsDesktopTablet';
import AllocationsMobile from './AllocationsMobile';
import useConnect from './connect';
import {
  Container,
  FilteredContainer,
  LoaderContainer,
  Spinner,
} from './styles';

const Allocations: FC = () => {
  const [needResetFilter, setNeedResetFilter] = useState<boolean>(true);
  const [sortColumn, setSortColumn] = useState<string>('Total ($)');
  const [sortOrientation, setSortOrientation] = useState<string>('DESC');

  const { replace } = useHistory();
  const newsServices = useNews();

  const {
    allocationChartData,
    backUrl,
    balance,
    allocation,
    breadcrumbs,
    currentSorting,
    groupLinks,
    groups,
    headerColumns,
    handle,
    isBreakdown,
    modalOpen,
    pageTitle,
    selectedGroupUrl,
    sortOptions,
    url,
  } = useConnect();

  useEffect(() => {
    if (newsServices?.notificationToNews) {
      replace('/news');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsServices?.notificationToNews]);

  useEffect(() => {
    if (needResetFilter) {
      handle.changeFiltering('default');
      setNeedResetFilter(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needResetFilter]);

  useEffect(() => {
    const currentSortingAux = currentSorting ?? 'default';

    const headerColumn = headerColumns.find(
      (col) =>
        col.keyOrderAsc === currentSortingAux ||
        col.keyOrderDesc === currentSortingAux,
    );

    if (headerColumn) {
      setSortColumn(headerColumn.titleColumn);
      setSortOrientation(
        headerColumn.keyOrderAsc === currentSortingAux ? 'ASC' : 'DESC',
      );
    }
  }, [headerColumns, currentSorting]);

  const isTablet = useMedia(from.tablet.query);

  if (isTablet) {
    return (
      <>
        {(groups.length === 0 || allocationChartData.length === 0) && (
          <LoaderContainer>
            <Spinner />
          </LoaderContainer>
        )}
        <Container
          $loading={groups.length === 0 || allocationChartData.length === 0}
          $mobile={!isTablet}
        >
          <AllocationsDesktopTablet
            allocationChartData={allocationChartData}
            breadcrumbs={breadcrumbs}
            balance={balance}
            groupLinks={groupLinks}
            groups={groups}
            onChangeSorting={handle.changeSorting}
            sortApplied={{
              titleColumn: sortColumn,
              orderOrientation: sortOrientation as OrderOrientation,
            }}
            headerColumns={headerColumns}
            selectedGroupUrl={selectedGroupUrl}
            url={url}
          />
        </Container>
      </>
    );
  }

  const mobileContent = (
    <AllocationsMobile
      balance={balance}
      allocation={allocation}
      closeModal={handle.closeModal}
      currentSorting={currentSorting}
      groupLinks={groupLinks}
      groups={groups}
      headerColumns={headerColumns}
      isBreakdown={isBreakdown}
      onChangeSorting={handle.changeSorting}
      openModal={handle.openModal}
      sortOptions={sortOptions}
      modalOpen={modalOpen}
    />
  );

  return isBreakdown ? (
    <>
      {groups.length === 0 && (
        <LoaderContainer>
          <Spinner />
        </LoaderContainer>
      )}
      <Container
        $loading={groups.length === 0}
        $mobile={!isTablet}
        showSelectedPortfolio={!modalOpen}
      >
        {mobileContent}
      </Container>
    </>
  ) : (
    <>
      {groups.length === 0 && (
        <LoaderContainer>
          <Spinner />
        </LoaderContainer>
      )}
      <FilteredContainer
        $loading={groups.length === 0}
        $mobile={!isTablet}
        parentUrl={backUrl}
        title={pageTitle}
        showLayoutHeader={!modalOpen}
      >
        {mobileContent}
      </FilteredContainer>
    </>
  );
};

export default Allocations;
