import { FC, useState } from 'react';

import MarketOverviewTable from 'components/MarketOverviewTable';

import useConnect from './connect';
import { Container, Content, TableTabs } from './styles';

const tableTabs = [
  { value: 'Daily Change' as const, label: 'Daily Change' },
  { value: 'MTD' as const, label: 'MTD' },
  { value: 'YTD' as const, label: 'YTD' },
];

const MarketsCategoryDetail: FC = () => {
  const { categoryName, data, handle, parentUrl } = useConnect();

  const [activeTableTab, setActiveTableTab] = useState<
    'Daily Change' | 'YTD' | 'MTD'
  >('Daily Change');

  return (
    <Container parentUrl={parentUrl} title={categoryName} withSubtitle={false}>
      <Content>
        <TableTabs
          value={activeTableTab}
          tabs={tableTabs}
          onChange={setActiveTableTab}
          variant="bg-tabs"
        />
        <MarketOverviewTable
          data={data}
          onPin={handle.addToWatchlist}
          onUnpin={handle.removeFromWatchlist}
          selectedData={activeTableTab}
        />
      </Content>
    </Container>
  );
};

export default MarketsCategoryDetail;
