import { FC, memo } from 'react';

import { formatPercentage } from 'utils/percentages';

import { Arrow, Container } from './styles';
import type { Props } from './types';

const DesktopVersion: FC<Props> = ({ className, value, withArrow = true }) => (
  <Container className={className} $isNegative={value < 0}>
    {formatPercentage(value)}
    {withArrow && <Arrow $isNegative={value < 0} />}
  </Container>
);

export default memo(DesktopVersion);
