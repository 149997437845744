import { FC, useMemo } from 'react';

import BaseStats from 'components/BaseStats';
import { formatAmount } from 'utils/amounts';
import { formatPercentage } from 'utils/percentages';

import type { Props } from './types';

const AssetStats: FC<Props> = ({
  category,
  buttonText,
  className,
  balance,
  balancePct,
  notional,
  notionalPct,
  totalNotional,
  totalNotionalPct,
  pathname,
}) => {
  const cols = useMemo(
    () => [
      {
        id: 1,
        title: `${category} (Physical exposure)`,
        value: formatAmount(balance ?? 0),
        subValue: formatPercentage(balancePct ?? 0),
      },
      {
        id: 2,
        title: `${category} Options (Notional)`,
        value: formatAmount(notional ?? 0),
        subValue: formatPercentage(notionalPct ?? 0),
      },
      {
        id: 3,
        title: `Total Notional ${category}`,
        value: formatAmount(totalNotional ?? 0),
        subValue: formatPercentage(totalNotionalPct ?? 0),
      },
    ],
    [
      category,
      balancePct,
      balance,
      notionalPct,
      notional,
      totalNotionalPct,
      totalNotional,
    ],
  );

  return (
    <BaseStats
      className={className}
      cols={cols}
      pathname={pathname}
      buttontext={buttonText}
    />
  );
};

export default AssetStats;
