import {
  AxisBottom as DefaultAxisBottom,
  AxisLeft as DefaultAxisLeft,
} from '@visx/axis';
import { GridRows } from '@visx/grid';
import type { TextProps } from '@visx/text/lib/Text';
import styled, { css } from 'styled-components';

import from from 'styles/responsive';
import type { Theme } from 'styles/themes';

import type { $AxisProps } from './types';

export const GraphVector = styled.svg``;

export const ChartContainer = styled.div`
  position: relative;

  ${from.tablet`
    background-color: ${({ theme }) => theme.colors.dark1};
    padding: 1.2rem 0;
    border-radius: 1rem;
  `}
`;

export const Grid = styled(GridRows).attrs({ stroke: 'currentColor' })`
  color: ${({ theme }) => theme.colors.white10};
`;

const commonAxisProps = ({
  $isTablet,
  theme,
}: {
  $isTablet: boolean;
  theme: Theme;
}) => ({
  hideTicks: true,
  stroke: 'none',
  tickLabelProps: (): Partial<TextProps> => ({
    fill: $isTablet ? theme.colors.white50 : theme.colors.white75,
    fontSize: $isTablet ? '0.875rem' : '0.750rem',
    textAnchor: 'middle',
  }),
});

export const AxisLeft = styled(DefaultAxisLeft).attrs<$AxisProps>(
  ({ $isTablet, theme }) => ({
    ...commonAxisProps({ $isTablet, theme }),
    tickLabelProps: (): Partial<TextProps> => ({
      ...commonAxisProps({ $isTablet, theme }).tickLabelProps(),
      textAnchor: 'end',
      verticalAnchor: 'middle',
      x: 0,
    }),
  }),
)<$AxisProps>``;

export const AxisBottom = styled(DefaultAxisBottom).attrs<$AxisProps>(
  ({ $isTablet, theme }) => ({
    ...commonAxisProps({ $isTablet, theme }),
    tickLabelProps: (): Partial<TextProps> => ({
      ...commonAxisProps({ $isTablet, theme }).tickLabelProps(),
      y: -4,
      angle: -90,
      textAnchor: 'end',
      verticalAnchor: 'middle',
    }),
  }),
)<$AxisProps>``;

export const AxisBottomTitleWrapper = styled.div`
  display: flex;
  justify-content: center;

  ${from.tablet`
    margin-top: 0.75rem;
  `}
`;

const Title = css`
  color: ${({ theme }) => theme.colors.white50};
  font-size: 0.8125rem;

  ${from.tablet`
    display: initial;
  `};
`;

export const LeftTitle = styled.text.attrs({ fill: 'currentColor' })<{
  $x: number;
  $y: number;
}>`
  ${Title}
  transform: translateX(${({ $x }) => $x}px) translateY(${({ $y }) => $y}px)
rotate(-90deg);
`;

export const BottomTitle = styled.span.attrs({ fill: 'currentColor' })`
  ${Title}
`;
