/* eslint-disable import/no-cycle */
import { useSubmitHandler } from './logic';

export default function useConnect() {
  const { loading, onSubmit } = useSubmitHandler();

  return {
    handle: {
      submit: onSubmit,
    },
    loading,
  };
}
