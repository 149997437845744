import {
  MarkerCircle as DefaultMarkerCircle,
  MarkerX as DefaultMarkerX,
} from '@visx/marker';
import { LinePath as DefaultLinePath } from '@visx/shape';
import styled from 'styled-components';

export const LinePathCurrent = styled(DefaultLinePath)`
  stroke: ${({ theme }) => theme.colors.primary};
  stroke-width: 1;
`;

export const LinePathTarget = styled(DefaultLinePath)`
  stroke: transparent;
`;

export const MarkerX = styled(DefaultMarkerX)`
  stroke: ${({ theme }) => theme.colors.primary};
`;

export const MarkerCircle = styled(DefaultMarkerCircle)`
  fill: ${({ theme }) => theme.colors.statusDecrease};
`;
