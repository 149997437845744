const formatterAsBondCoupon = new Intl.NumberFormat('en-Us', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 3,
});

const percentageFormatter = new Intl.NumberFormat('en-Us', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const percentageFormatterDecimalsIfNecessary = new Intl.NumberFormat('en-Us', {
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

const formatterAsInteger = new Intl.NumberFormat('en-Us', {
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export function formatPercentageAsBondCoupon(coupon: number): string {
  return formatterAsBondCoupon.format(coupon);
}

export function formatPercentage(percentage: number): string {
  return percentageFormatter.format(percentage);
}

export function formatPercentageWithDecimalsIfNecessary(
  percentage: number,
): string {
  return percentageFormatterDecimalsIfNecessary.format(percentage);
}

export function formatPercentageAsInteger(percentage: number): string {
  return formatterAsInteger.format(percentage);
}

function getNumberOfDecimals(numberOfIntegers: number): number {
  if (numberOfIntegers >= 5) {
    return 0;
  }

  if (numberOfIntegers === 4) {
    return 1;
  }

  return 2;
}

export function formatPercentage5Dig(number: number): string {
  const isNegative = number < 0;
  const numberToPercent = number * 100;
  const integers = Math.trunc(numberToPercent);
  const numberOfIntegers = integers.toString().length;
  const decimalNum = isNegative ? numberOfIntegers - 1 : numberOfIntegers;
  const finalValue = numberToPercent.toFixed(getNumberOfDecimals(decimalNum));
  return `${finalValue.toString()}%`;
}
