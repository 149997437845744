import type { GetClientsResponse } from 'api/queries/clients/getClients/types';
import type { WithoutNulls } from 'utils/withoutNulls';

import { parseBackendDate } from '../utils/dates';

type Input = NonNullable<
  NonNullable<GetClientsResponse['data']>[number]['subPortfolios']
>[number];

export const normalizePortfolio = (input: WithoutNulls<Input>) =>
  ({
    id: `portfolio_${input.id}`,
    isDefault: false,
    lastUpdated: input.lastProcessedTime
      ? parseBackendDate(input.lastProcessedTime).getTime()
      : 0,
    name: input.clientName ?? '',
    inceptionDate: input.inceptionDate
      ? new Date(new Date(input.inceptionDate).toDateString()).getTime()
      : 0,
  } as const);

export type Portfolio = ReturnType<typeof normalizePortfolio>;
