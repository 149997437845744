import { FC, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

import useSelectedClient from 'api/hooks/clients/useSelectedClient';

import { ContainerOverlay } from './styles';
import type { Props } from './types';

const CustomDate: FC<Props> = ({
  selectedDate,
  selectedPeriod,
  onChange,
  onClear,
}) => {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [startDate, endDate] = dateRange;
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const { selectedPortfolio } = useSelectedClient();

  useEffect(() => {
    if (startDate && endDate) {
      onChange([startDate, endDate]);
    }

    if (!startDate && !endDate) {
      onClear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    setDateRange([
      selectedDate[0] ? selectedDate[0] : null,
      selectedDate[1] ? selectedDate[1] : null,
    ]);
  }, [selectedPeriod, selectedDate]);

  useEffect(() => {
    if (isCalendarOpen) {
      onClear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCalendarOpen]);

  if (selectedPeriod === 'MTD') {
    return (
      <div>
        {isCalendarOpen && <ContainerOverlay />}
        <DatePicker
          wrapperClassName="react-datepicker-custom"
          placeholderText="Select Month"
          selectsRange
          startDate={startDate}
          endDate={endDate}
          minDate={new Date(selectedPortfolio?.inceptionDate ?? 0)}
          maxDate={new Date(selectedPortfolio?.lastUpdated ?? new Date())}
          showMonthYearPicker
          dateFormat="MMM/yyyy"
          isClearable
          onChange={(update) => {
            setDateRange(update);
          }}
          onCalendarOpen={() => {
            setIsCalendarOpen(true);
          }}
          onCalendarClose={() => {
            setIsCalendarOpen(false);
          }}
        />
      </div>
    );
  }

  if (selectedPeriod === 'YTD') {
    return (
      <div>
        {isCalendarOpen && <ContainerOverlay />}
        <DatePicker
          wrapperClassName="react-datepicker-custom"
          placeholderText="Select Year"
          selectsRange
          startDate={startDate}
          endDate={endDate}
          minDate={new Date(selectedPortfolio?.inceptionDate ?? 0)}
          maxDate={new Date(selectedPortfolio?.lastUpdated ?? new Date())}
          showYearPicker
          dateFormat="yyyy"
          yearItemNumber={8}
          isClearable
          onChange={(update) => {
            setDateRange(update);
          }}
          onCalendarOpen={() => {
            setIsCalendarOpen(true);
          }}
          onCalendarClose={() => {
            setIsCalendarOpen(false);
          }}
        />
      </div>
    );
  }

  return <></>;
};

export default CustomDate;
