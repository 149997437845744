export function useTitleTextAlign(data: number[]) {
  const allMoreZero = data.every((d) => d >= 0);
  const allMinusZero = data.every((d) => d <= 0);

  let textAlign;

  if (allMoreZero) {
    textAlign = 'left';
  } else if (allMinusZero) {
    textAlign = 'right';
  } else {
    textAlign = 'left';
  }

  return textAlign;
}

export function useTitlePaddingLeft(
  data: number[],
  ticks: number[],
  isLaptop: boolean,
) {
  const allMoreZero = data.every((d) => d >= 0);
  const allMinusZero = data.every((d) => d <= 0);

  let paddingLeft = 0;

  if (!allMoreZero && !allMinusZero) {
    const chartWidth =
      document.getElementById('market-summary-chart')?.clientWidth ?? 0;

    // Factor to adjust padding depending on screen resolution.
    /** 1px is added to the padding for every 20px that the width
     *  of the graph is greater than the width for the minimum
     * resolution (427px for Desktop) */
    const responsiveCorrectionFactor = isLaptop
      ? ((chartWidth - 427) / 20) * 1 - 10
      : -12;

    paddingLeft =
      (chartWidth / ticks.length) * ticks.findIndex((d) => d === 0) +
      responsiveCorrectionFactor;
  }

  return paddingLeft;
}
