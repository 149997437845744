import type { ChartKey } from '../types';

export function formatChartKey(key: ChartKey) {
  switch (key) {
    case 'adjustedTreasuryYield':
      return 'Adjusted Treasury Yield (Portfolio)';
    default:
    case 'portfolioYield':
      return 'Portfolio Yield';
  }
}
