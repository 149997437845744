import { useRouteMatch } from 'react-router';

import useMarketOverview from 'api/hooks/markets/useMarketOverview';
import useMarketWatchlistActions from 'api/hooks/markets/useMarketWatchlistActions';
import { useCategorySlugs } from 'containers/Protected/Markets/MarketsRoot/MarketsDesktopTablet/logic';

import { useLinks } from './logic';

export default function useConnect() {
  const { url } = useRouteMatch();
  const { addToWatchlist, removeFromWatchlist } = useMarketWatchlistActions();

  const { categories } = useMarketOverview({
    categoryName: '',
    watchlistAssets: [],
  });

  const { getSlugByCategoryName } = useCategorySlugs({
    categories,
  });
  const links = useLinks({
    categories,
    getSlugByCategoryName,
    url,
  });

  return {
    handle: { addToWatchlist, removeFromWatchlist },
    links,
  };
}
