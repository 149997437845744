import { useCallback } from 'react';
import type { QueryFunction } from 'react-query';

import useRequest from 'api/request';
import {
  MarketOverviewCategory,
  normalizeMarketOverviewCategory,
} from 'model/MarketOverviewCategory';
import withoutNulls from 'utils/withoutNulls';

import type {
  GetMarketOverviewRequest,
  GetMarketOverviewResponse,
} from './types';

export const GET_MARKET_OVERVIEW_QUERY_KEY = () => [
  'markets/getMarketOverview',
];

export default function useGetMarketOverviewQuery(): QueryFunction<
  MarketOverviewCategory[]
> {
  const request = useRequest<
    GetMarketOverviewRequest,
    GetMarketOverviewResponse
  >();

  return useCallback(async () => {
    const response = await request('/api/CVData/getMarketOverviewComplete', {});

    const data = await response.json();

    if (!data.isSuccess) {
      throw new Error(data.message ?? '');
    }

    return withoutNulls(
      data.data?.flatMap((it) => it.instrumentClassDetail ?? []) ?? [],
    ).map(normalizeMarketOverviewCategory);
  }, [request]);
}
