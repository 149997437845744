import { GoogleAnalytics } from '@ionic-native/google-analytics';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

import isDoNotTrack from 'utils/isDoNotTrack';
import { uint32ArrayToString } from 'utils/typedArray';

import type { Props } from './types';

const gaId: string | undefined = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

const Context = createContext<
  | {
      setUserId: (userId: string) => Promise<void>;
      trackEvent: (
        category: string,
        action: string,
        label?: string,
      ) => Promise<void>;
      trackPageview: (path: string) => Promise<void>;
    }
  | undefined
>(undefined);

/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
  interface Window {
    dataLayer?: any[];
    gtag?: (...args: any[]) => void;
  }
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export default function GoogleAnalyticsService({
  children,
}: Props): JSX.Element {
  const [gaInfo, setGaInfo] = useState<
    | { status: 'loading' | 'failed' }
    | { ga: typeof GoogleAnalytics; status: 'mobile-ga' }
    | { ga: typeof ReactGA; status: 'desktop-ga' }
  >({
    status: 'loading',
  });

  const initialize = useCallback(async () => {
    if (isDoNotTrack() || !gaId) {
      setGaInfo({ status: 'failed' });
      return;
    }

    const tagManagerArgs = {
      gtmId: gaId,
    };

    TagManager.initialize(tagManagerArgs);

    try {
      await GoogleAnalytics.startTrackerWithId(gaId);
      setGaInfo({
        ga: GoogleAnalytics,
        status: 'mobile-ga',
      });
    } catch (e) {
      ReactGA.initialize(gaId);

      setGaInfo({
        ga: ReactGA,
        status: 'desktop-ga',
      });
    }
  }, [setGaInfo]);

  useEffect(() => {
    initialize().catch(() => {
      setGaInfo({ status: 'failed' });
    });
  }, [initialize, setGaInfo]);

  const setUserId = useMemo(() => {
    if (gaInfo.status === 'failed' || gaInfo.status === 'loading') {
      return undefined;
    }

    return async (userId: string) => {
      const digest = await crypto.subtle.digest(
        'SHA-256',
        new TextEncoder().encode(userId),
      );
      // The ga user id should not contain identifiable information, so we hash the email.
      const hashedId = `cv_user:${btoa(
        uint32ArrayToString(new Uint32Array(digest)),
      )}`;

      if (gaInfo.status === 'mobile-ga') {
        await gaInfo.ga.setUserId(hashedId);
        await gaInfo.ga.trackEvent('authentication', 'user-id available');
      }

      if (gaInfo.status === 'desktop-ga') {
        gaInfo.ga.ga('set', 'userId', hashedId);
        gaInfo.ga.ga('send', 'event', 'authentication', 'user-id available');
      }
    };
  }, [gaInfo]);

  const trackEvent = useMemo(() => {
    if (gaInfo.status === 'failed' || gaInfo.status === 'loading') {
      return undefined;
    }

    return async (category: string, action: string, label?: string) => {
      if (gaInfo.status === 'mobile-ga') {
        await gaInfo.ga.trackEvent(category, action, label);
      }

      if (gaInfo.status === 'desktop-ga') {
        gaInfo.ga.ga('send', 'event', category, action, label);
      }
    };
  }, [gaInfo]);

  const trackPageview = useMemo(() => {
    if (gaInfo.status === 'failed' || gaInfo.status === 'loading') {
      return undefined;
    }

    return async (path: string) => {
      if (gaInfo.status === 'mobile-ga') {
        await gaInfo.ga.trackView(path);
      }

      if (gaInfo.status === 'desktop-ga') {
        gaInfo.ga.ga('send', 'pageview', path);
      }
    };
  }, [gaInfo]);

  const value = useMemo(
    () =>
      setUserId && trackEvent && trackPageview
        ? { setUserId, trackEvent, trackPageview }
        : undefined,
    [setUserId, trackEvent, trackPageview],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export function useGoogleAnalytics() {
  return useContext(Context);
}
