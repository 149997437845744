/* eslint-disable no-await-in-loop */
import {
  OrientationType,
  ScreenOrientation,
} from '@capawesome/capacitor-screen-orientation';
import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import type { ScreenOrientationContextModel } from './types';

const Context = createContext<ScreenOrientationContextModel | undefined>(
  undefined,
);

const ScreenOrientationProvider: FC = ({ children }) => {
  const [isLandscape, setIsLandscape] = useState(false);

  useEffect(() => {
    const checkOrientation = async () => {
      const info = await ScreenOrientation.getCurrentOrientation();
      setIsLandscape(
        info.type === OrientationType.LANDSCAPE ||
          info.type === OrientationType.LANDSCAPE_PRIMARY ||
          info.type === OrientationType.LANDSCAPE_SECONDARY,
      );
    };

    const handleOrientationChange = () => {
      void checkOrientation();
    };

    void ScreenOrientation.addListener(
      'screenOrientationChange',
      handleOrientationChange,
    );

    void checkOrientation();

    return () => {
      void ScreenOrientation.removeAllListeners();
    };
  }, []);

  const value = useMemo(
    () => ({
      isLandscape,
    }),
    [isLandscape],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export function useScreenOrientation() {
  return useContext(Context);
}

export default ScreenOrientationProvider;
