import useGetCVBondSummary, {
  GET_CV_BOND_SUMMARY_QUERY_KEY,
} from 'api/queries/clients/getCVBondSummary';
import { useQuery } from 'api/storage';

export default function useCVBondSummary(portfolio: string | undefined) {
  const { data } = useQuery(GET_CV_BOND_SUMMARY_QUERY_KEY(portfolio ?? ''), {
    enabled: !!portfolio,
    queryFn: useGetCVBondSummary(),
  });

  return {
    data: data ?? [],
  };
}
