import type { BrowserVault, Vault } from '@ionic-enterprise/identity-vault';

import {
  uint32ArrayFromString,
  uint32ArrayToString,
  uint8ArrayFromString,
  uint8ArrayToString,
} from 'utils/typedArray';

export async function generateKey() {
  const key = await crypto.subtle.generateKey(
    {
      name: 'AES-CBC',
      length: 256,
    },
    true,
    ['encrypt', 'decrypt'],
  );

  return JSON.stringify(await crypto.subtle.exportKey('jwk', key));
}

export async function importKeyFromVault(
  vault: Vault | BrowserVault,
): Promise<CryptoKey | undefined> {
  const key = await vault.getValue<string>('key');

  if (!key) {
    return undefined;
  }

  return crypto.subtle.importKey(
    'jwk',
    JSON.parse(key),
    { name: 'AES-CBC', length: 256 },
    false,
    ['encrypt', 'decrypt'],
  );
}

export async function unlockVault(
  vault: Vault | BrowserVault,
): Promise<boolean> {
  try {
    await vault.unlock();
    return true;
  } catch (e) {
    return false;
  }
}

export async function encryptText(key: CryptoKey, input: string) {
  const iv = crypto.getRandomValues(new Uint8Array(16));

  const ciphertext = await crypto.subtle.encrypt(
    { name: 'AES-CBC', iv },
    key,
    new TextEncoder().encode(input),
  );

  const base64ciphertext = btoa(
    uint32ArrayToString(new Uint32Array(ciphertext)),
  );
  const base64iv = btoa(uint8ArrayToString(iv));

  return JSON.stringify(['AESCBC', base64ciphertext, base64iv]);
}

export async function decryptText(
  key: CryptoKey,
  [, base64ciphertext, base64iv]: ['AESCBC', string, string],
) {
  const ciphertext = uint32ArrayFromString(atob(base64ciphertext)).buffer;
  const iv = uint8ArrayFromString(atob(base64iv)).buffer;

  const plaintext = await crypto.subtle.decrypt(
    { name: 'AES-CBC', iv },
    key,
    ciphertext,
  );

  return new TextDecoder().decode(plaintext);
}
