import type { TickFormatter } from '@visx/axis';
import { FC, memo } from 'react';
import useResizeObserver from 'use-resize-observer';

import { formatAmountShort } from 'utils/amounts';
import { formatYear } from 'utils/dates';

import BarChart from './BarChart';
import Legend from './Legend';
import {
  useEdgeValues,
  useHeight,
  useMargins,
  useMedianTooltip,
  useScales,
  useTooltip,
} from './logic';
import MedianBar from './MedianBar';
import {
  AxisBottom,
  AxisLeft,
  Container,
  GraphVector,
  Grid,
  Title,
} from './styles';
import Tooltip from './Tooltip';
import type { ChartKey, Props } from './types';

const IncomeProfileChart: FC<Props> = ({
  className,
  data,
  withAverage = true,
}) => {
  const { ref, width = 0 } = useResizeObserver();
  const height = useHeight();

  const {
    bottomAxisHeight,
    bottomAxisMargin,
    leftAxisMargin,
    leftAxisWidth,
    rightMargin,
    topMargin,
  } = useMargins();

  const { totals, end, maxValue, minValue, start } = useEdgeValues(data);

  const { xScale, yScale } = useScales({
    bottomAxisHeight,
    bottomAxisMargin,
    data,
    end,
    height,
    leftAxisMargin,
    leftAxisWidth,
    maxValue,
    minValue,
    rightMargin,
    start,
    topMargin,
    width,
  });

  const medianValue = totals.reduce((acc, d) => acc + d, 0) / totals.length;
  const medianY = yScale(medianValue);

  const {
    handleTooltipClosed,
    handleTooltipUpdated,
    tooltipData,
    tooltipLeft,
    tooltipTop,
  } = useTooltip({
    xScale,
    yScale,
    height: topMargin + bottomAxisHeight,
    leftAxisMargin,
    leftAxisWidth,
  });

  const {
    handleMedianTooltipClosed,
    handleMedianTooltipUpdated,
    tooltipMedianData,
    tooltipMedianLeft,
    tooltipMedianTop,
  } = useMedianTooltip({
    y: medianY,
  });

  return (
    <Container ref={ref} className={className}>
      <GraphVector width="100%" viewBox={`0 0 ${width} ${height}`}>
        <Grid
          scale={yScale}
          width={width - leftAxisWidth - leftAxisMargin - rightMargin}
          left={leftAxisWidth + leftAxisMargin}
        />
        <AxisLeft
          left={leftAxisWidth}
          scale={yScale}
          tickFormat={formatAmountShort as TickFormatter<unknown>}
        />
        <AxisBottom
          left={0}
          tickValues={data.map((datum) => datum.date)}
          tickFormat={formatYear}
          scale={xScale}
          top={height - bottomAxisHeight}
        />
        <Title
          $x={34}
          $y={height / 2}
          dominantBaseline="central"
          textAnchor="middle"
        >
          Yearly Fixed Income Profile (US$)
        </Title>
        <BarChart
          data={data}
          xScale={xScale}
          yScale={yScale}
          bottomHeight={height - bottomAxisHeight - bottomAxisMargin}
          handleTooltipClosed={handleTooltipClosed}
          handleTooltipUpdated={handleTooltipUpdated}
        />
        {withAverage && (
          <MedianBar
            key="median"
            median={medianValue}
            x={leftAxisWidth + leftAxisMargin}
            y={medianY}
            width={Math.max(
              width - leftAxisWidth - leftAxisMargin - rightMargin,
              0,
            )}
            handleTooltipUpdated={handleMedianTooltipUpdated}
            handleTooltipClosed={handleMedianTooltipClosed}
          />
        )}
      </GraphVector>
      <Legend
        keys={
          ['rating', withAverage ? 'average' : null].filter(
            Boolean,
          ) as ChartKey[]
        }
      />
      {typeof tooltipData !== 'undefined' &&
        typeof tooltipLeft === 'number' &&
        typeof tooltipTop === 'number' && (
          <Tooltip
            data={tooltipData}
            tooltipLeft={tooltipLeft}
            tooltipTop={tooltipTop}
          />
        )}
      {withAverage &&
        typeof tooltipMedianData !== 'undefined' &&
        typeof tooltipMedianLeft === 'number' &&
        typeof tooltipMedianTop === 'number' && (
          <Tooltip
            data={tooltipMedianData}
            tooltipLeft={tooltipMedianLeft}
            tooltipTop={tooltipMedianTop}
          />
        )}
    </Container>
  );
};

export default memo(IncomeProfileChart);
