import DOMPurify from 'dompurify';
import { FC, memo, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';

import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';

import {
  Brand,
  Button,
  Buttons,
  Container,
  GoBackButton,
  RichText,
} from './styles';
import type { Props } from './types';

const TermsAndConditionsContent: FC<Props> = ({
  className,
  content,
  onAccept,
  onReject,
  withLogo = true,
  withGoBack = false,
}) => {
  const sanitized = useMemo(() => {
    const cleanContent = DOMPurify.sanitize(content);
    const updatedContent = cleanContent.replace(/<a\s/g, '<a target="_blank" ');
    return updatedContent;
  }, [content]);

  const { goBack } = useHistory();
  const handleGoBack = useCallback(() => {
    goBack();
  }, [goBack]);

  return (
    <Container className={className}>
      {withLogo && <Brand withOneFamily />}
      {withGoBack && (
        <GoBackButton
          color="secondary"
          variant="only-icon"
          size="small"
          withIcon={<ChevronLeft />}
          onClick={handleGoBack}
        />
      )}
      <RichText dangerouslySetInnerHTML={{ __html: sanitized }} />
      {(onAccept || onReject) && (
        <Buttons>
          {onAccept && <Button onClick={onAccept}>Accept</Button>}
          {onReject && <Button onClick={onReject}>Reject</Button>}
        </Buttons>
      )}
    </Container>
  );
};

export default memo(TermsAndConditionsContent);
