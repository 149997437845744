import styled from 'styled-components';

import { ReactComponent as DefaultVectorDownIcon } from 'assets/icons/vector-down.svg';
import { ReactComponent as DefaultVectorUpIcon } from 'assets/icons/vector-up.svg';
import Button from 'components/Button';
import from from 'styles/responsive';

export const FirstCol = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  grid-row: span 2;
  span {
    margin-top: 0.25rem;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    opacity: 0.75;
  }
  ${from.tablet`
    grid-row: span 1;
  `}
`;

export const Table = styled.div`
  display: grid;
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.875rem;
  line-height: 1.0625rem;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 0 0.5rem 1.25rem;
  ${from.tablet`
    position: sticky;
    top: 0;
    background: ${({ theme }) => theme.colors.dark2};
    z-index: 1;
    border-bottom: 1px solid ${({ theme }) => theme.colors.white};
    padding: 1rem 2rem;
    display: grid;
    grid-template-columns: 1.5fr 0.2fr;
    column-gap: 0.5rem;
  `}
`;

export const Header = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1.5fr repeat(2, 0.6fr);
  column-gap: 0.5rem;
  color: ${({ theme }) => theme.colors.white75};
  text-align: right;
  > :first-child {
    text-align: left;
  }
  > :not(:first-child) {
    display: none;
  }
  ${from.tablet`
    grid-template-columns: 1.5fr repeat(2, 0.6fr);
    > :not(:first-child) {
      display: block
    }
  `}
  ${from.desktop`
    grid-template-columns: 1.5fr repeat(2, 1fr);
  `}
`;

export const Content = styled.div`
  border-radius: 1rem;
  overflow: hidden;
  > :not(:last-child) div {
    margin-bottom: 0.125rem;
  }

  ${from.tablet`
    margin-top: 1rem;
  `}
`;

export const HeaderCell = styled.div``;

export const HeaderTitle = styled.span`
  cursor: pointer;
  display: inline-flex;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  column-gap: 0.5rem;
  align-items: center;
  margin: 0;
  font-size: 1rem;
  line-height: 1.1875rem;
    svg {
      display: none;
    }
  }
  ${from.tablet`
    grid-template-columns: 1.5fr repeat(2, 0.6fr);
    > :nth-child(3) {
      grid-column: auto;
      grid-row: auto;
      font-size: 1rem;
      line-height: 1.1875rem;
      svg {
        display: inline;
      }
    }
    > :last-child {
      grid-row: span 1;
    }
  `}
  ${from.desktop`
    grid-template-columns: 1.5fr repeat(2, 1fr);
  `}
`;

export const Col = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const SortMobileButton = styled(Button)`
  width: fit-content;
  margin-left: 1rem;
`;

export const VectorDownIcon = styled(DefaultVectorDownIcon)``;

export const VectorUpIcon = styled(DefaultVectorUpIcon)``;
