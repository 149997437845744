import styled, { css } from 'styled-components';

import { getChartKeyColor } from '../logic';
import type { ChartKey } from '../types';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const Item = styled.div`
  :not(:last-child) {
    margin-right: 2rem;
  }
`;

export const Box = styled.span<{ $key: ChartKey }>`
  border-radius: 0.125rem;
  display: inline-block;
  height: 0.625rem;
  margin-right: 0.375rem;
  width: 0.625rem;

  ${({ $key, theme }) => css`
    background-color: ${getChartKeyColor($key, theme)};
  `};
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.white50};
`;
