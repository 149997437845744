import type { FC } from 'react';
import { memo } from 'react';

import { formatAmount } from 'utils/amounts';
import { formatMonth } from 'utils/dates';

import { formatChartKey } from '../logic';
import { Key, KeyLabel, KeySquare, TooltipWithBounds, Value } from './styles';
import type { Props } from './types';

const Tooltip: FC<Props> = ({
  data: { date, value, key },
  tooltipLeft,
  tooltipTop,
}) => (
  <TooltipWithBounds top={tooltipTop} left={tooltipLeft}>
    <Key>{formatMonth(date)}</Key>
    <Key>
      <KeySquare $key={key} />
      <KeyLabel>{formatChartKey(key)}</KeyLabel>
    </Key>
    <Value>{formatAmount(value)}</Value>
  </TooltipWithBounds>
);

export default memo(Tooltip);
