import { kebabCase } from 'lodash';

const ID_DASH = ' 1845324562354 ';

export default function kebabCaseCustom(value: string) {
  if (!value) {
    return value;
  }

  return kebabCase(value.split('-').join(ID_DASH))
    .split(ID_DASH.split(' ').join(''))
    .join('+')
    .toLocaleLowerCase();
}
