import { FC, useEffect, useState } from 'react';

import BaseAssetDetailTable from 'components/BaseAssetDetailTable';
import CashTable from 'components/CashTable';
import from from 'styles/responsive';
import exportToExcel from 'utils/exportToExcel';
import searchWithoutSort from 'utils/searchWithoutSort';
import type { KeySortOfColumn, OrderOrientation } from 'utils/sort/types';
import useMedia from 'utils/useMedia';
import usePlatform from 'utils/usePlatform/usePlatform';

import useConnect from './connect';
import {
  ActionZone,
  Amount,
  Button,
  Chevron,
  Container,
  Content,
  ExportToExcelButton,
  GoBackContainer,
  Header,
  Stats,
  StatsTitle,
  Title,
  Toolbar,
} from './styles';

const DetailCashSummary: FC = () => {
  const [sortColumn, setSortColumn] = useState<string>('Cash (USD)');
  const [sortOrientation, setSortOrientation] = useState<string>('DESC');
  const {
    summary,
    balance,
    parentUrl,
    search,
    accounts,
    handle,
    totalCashAllocation,
    mobileRows,
    currentSorting,
    headerColumns,
  } = useConnect();

  const isTablet = useMedia(from.tablet.query);
  const { isWeb } = usePlatform();

  useEffect(() => {
    const currentSortingAux = currentSorting ?? 'default';

    const headerColumn = headerColumns.find(
      (col) =>
        col.keyOrderAsc === currentSortingAux ||
        col.keyOrderDesc === currentSortingAux,
    );

    if (headerColumn) {
      setSortColumn(headerColumn.titleColumn);
      setSortOrientation(
        headerColumn.keyOrderAsc === currentSortingAux ? 'ASC' : 'DESC',
      );
    }
  }, [headerColumns, currentSorting]);

  const handleSortByColumn = (sortByColumnSelected: KeySortOfColumn) => {
    if (sortByColumnSelected.titleColumn === sortColumn) {
      handle.changeSorting(
        sortOrientation === 'DESC'
          ? sortByColumnSelected.keyOrderAsc
          : sortByColumnSelected.keyOrderDesc,
      );
    } else if (sortByColumnSelected.titleColumn !== sortColumn) {
      handle.changeSorting(sortByColumnSelected.keyOrderDesc);
    }
  };

  const handleExportToExcel = () => {
    const data = accounts.map((item) => ({
      Name: `${item.entity} ${item.account}`,
      Cash_USD: item.cashUSD,
      Cash_Other: item.cashOther,
      Cash_Equivalent: item.cashEquivalent,
      US_Treasuries: item.treasuries,
      Liquidity_Available: item.totalLiquidityAvailable,
    }));

    void exportToExcel(data, 'Liquidity Table List', 'LTlist', [2, 3, 4, 5, 6]);
  };

  return (
    <Container
      parentUrl={`${parentUrl}${search}`}
      title="Liquidity Available Summary"
      withSubtitle={false}
    >
      {isTablet ? (
        <>
          <Header>
            <GoBackContainer>
              <Button
                color="secondary"
                variant="only-icon"
                size="small"
                withIcon={<Chevron />}
                href={`${parentUrl}${searchWithoutSort(search)}`}
              />
              <Title>Liquidity Table</Title>
            </GoBackContainer>
            <Amount value={balance} />
          </Header>
          <Content>
            <Toolbar>
              <StatsTitle>Liquidity Summary</StatsTitle>
              <ActionZone>
                {isWeb && accounts && accounts.length > 0 && (
                  <ExportToExcelButton
                    size="small"
                    color="secondary"
                    onClick={handleExportToExcel}
                  >
                    Export to Excel
                  </ExportToExcelButton>
                )}
              </ActionZone>
            </Toolbar>
            <Stats
              cashUSD={summary?.cashUSD}
              cashOther={summary?.cashOther}
              cashEquivalent={summary?.cashEquivalent}
              totalCashAvailable={summary?.totalCashAvailable}
              USTreasuries={summary?.treasuries}
              totalLiquidityAvailable={summary?.totalLiquidityAvailable}
              totalCashAllocation={totalCashAllocation}
              pathname="#"
            />
            <CashTable
              data={accounts}
              handleSortByColumn={handleSortByColumn}
              sortApplied={{
                titleColumn: sortColumn,
                orderOrientation: sortOrientation as OrderOrientation,
              }}
              headerColumns={headerColumns}
            />
          </Content>
        </>
      ) : (
        <Content>
          <BaseAssetDetailTable rows={mobileRows} />
        </Content>
      )}
    </Container>
  );
};

export default DetailCashSummary;
