import { TooltipWithBounds as DefaultTooltipWithBounds } from '@visx/tooltip';
import styled, { css } from 'styled-components';

import { tooltipStyles } from 'components/Tooltip/styles';

import { getChartKeyColor } from '../styles';
import type { ChartKey } from '../types';

export const TooltipWithBounds = styled(DefaultTooltipWithBounds).attrs({
  offsetLeft: 16,
  offsetTop: -10,
  unstyled: true,
})`
  pointer-events: none;
  position: absolute;

  // Custom styles
  ${tooltipStyles} {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
`;

export const Date = styled.span`
  color: ${({ theme }) => theme.colors.white75};
  font-size: 0.75rem;
  line-height: 0.875rem;
  margin-block-end: 0.25rem;
`;

export const Percentage = styled.span`
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  line-height: 1.0625rem;
  font-variant-numeric: tabular-nums;
`;

export const PercentageTTM = styled.span`
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  line-height: 1.0625rem;
  font-variant-numeric: tabular-nums;
`;

export const Box = styled.span<{ $key: ChartKey }>`
  border-radius: 0.125rem;
  display: inline-block;
  height: 0.625rem;
  margin-right: 0.375rem;
  width: 0.625rem;
  border: 1px solid ${({ theme }) => theme.colors.white75};
  ${({ $key, theme }) => css`
    background-color: ${getChartKeyColor($key, theme)};
  `};
`;
