import styled from 'styled-components';

export const Container = styled.div``;

export const TitleContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
`;

export const Title = styled.h1`
  margin: 4rem 0 3.5rem;
  font-size: 2rem;
  white-space: nowrap;
`;

export const Portfolios = styled.ul`
  padding: 0;
  margin: 0;
`;
