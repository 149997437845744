import { useCallback } from 'react';
import type { QueryFunction } from 'react-query';

import useRequest from 'api/request';
import {
  NewsForAnEmail,
  normalizeNewsForAnEmail,
} from 'model/NewsForEmailDatum';
import withoutNulls from 'utils/withoutNulls';

import type {
  GetNewsForAnEmailRequest,
  GetNewsForAnEmailRequestParams,
  GetNewsForAnEmailResponse,
} from './types';

export const GET_NEWS_FOR_EMAIL_QUERY_KEY = ({
  email,
}: GetNewsForAnEmailRequestParams) => ['clients/getNewsForEmail', email];

export default function useGetNewsForEmail(): QueryFunction<NewsForAnEmail[]> {
  const request = useRequest<
    GetNewsForAnEmailRequest,
    GetNewsForAnEmailResponse
  >();

  return useCallback(
    async ({ queryKey }) => {
      const [, email] = queryKey as [string, string];

      const response = await request(
        '/api/SNSData/getNewsMessagesForAnEmail/{email}',
        {
          urlParams: {
            email,
          },
        },
      );

      const data = await response.json();
      if (!data.isSuccess) {
        throw new Error(data?.message ?? '');
      }

      const today = new Date();
      const priorDate = new Date(new Date().setDate(today.getDate() - 31));

      const dataFiltered =
        data.data &&
        data.data.filter((d) => new Date(d.dateCreated ?? 0) > priorDate);

      const dataOrdered =
        dataFiltered &&
        dataFiltered
          .sort((d) => new Date(d.dateCreated ?? 0).getTime())
          .reverse();

      const raw = data.data && withoutNulls(dataOrdered);
      return raw?.map(normalizeNewsForAnEmail) ?? [];
    },
    [request],
  );
}
