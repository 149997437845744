import { css } from 'styled-components';

const DatepickerCustomStyles = css`
  .react-datepicker-custom input{
    cursor: pointer;
    height: 35px;
    border-radius: 5px;
    border-style: none;
    padding: 6px 20px 6px 12px;
    color: ${({ theme }) => theme.colors.primary};
    font-style: italic;
    background-color: ${({ theme }) => theme.colors.primary15};
    font-size: 0.825rem;
    width: 12rem;

    &:focus,
    &:focus-visible,
    &:hover {
      outline: 1px solid ${({ theme }) => theme.colors.primary};
    }

    ::placeholder { 
      color: #acacac;
      opacity: 0.6;
    }
  }

  .react-datepicker-custom.small input{
    width: 10rem;
  }

  .react-datepicker-custom .react-datepicker__close-icon {
    &:after {
      cursor: pointer;
      background-color: ${({ theme }) => theme.colors.primary};
      height: 14px;
      width: 14px;
      padding: 3px;
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      content: "x";
    }

    &:hover {
      &:after {
        background-color: ${({ theme }) => theme.colors.primary50};
      }
    }
  }

  .react-datepicker {
    z-index: 2000;
    background-color: ${({ theme }) => theme.colors.dark2};
    border: 1px solid ${({ theme }) => theme.colors.dark2};
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }

  .react-datepicker-popper {
    z-index: 2000;
    inset: auto !important;
    transform: none !important;
    width: 140%;
  }

  .react-datepicker__tab-loop {
    position: relative;
  }
  
  .react-datepicker__header {
    border-bottom: 1px solid ${({ theme }) => theme.colors.white25};
  }

  .react-datepicker__current-month,
  .react-datepicker__header {
    background-color: ${({ theme }) => theme.colors.dark2};
    color: ${({ theme }) => theme.colors.white75};
    padding-bottom: 0.5rem;
  }

  .react-datepicker__day-names {
    font-weight: ${({ theme }) => theme.weights.bold};
  }

  .react-datepicker__year-wrapper {
    justify-content: space-around;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: ${({ theme }) => theme.colors.primary};

    &:hover {
      background-color: ${({ theme }) => theme.colors.primary50};
    }
  }
  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__year-text--disabled {
    color: ${({ theme }) => theme.colors.primary50} !important;

    &:hover {
      background-color: transparent !important;
    }
  }

  .react-datepicker__day,
  .react-datepicker__day-name,
  .react-datepicker__month-text,
  .react-datepicker__year-text {
    color: ${({ theme }) => theme.colors.white75};

    &:hover {
      background-color: ${({ theme }) => theme.colors.primary50};
    }
  }

  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range),
  .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range),
  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--selected,
  .react-datepicker__year-text--selected,
  .react-datepicker__month-text--selected {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  .react-datepicker-popper[data-placement^=bottom],
  .react-datepicker-popper[data-placement^=top] {
    padding-top: 10px;
    padding-bottom: 0;
  }

  .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
    transform: none !important;
    left: 50% !important;
    top: 0;
    margin-top: -8px;

    &:before {
      border-bottom-color: ${({ theme }) => theme.colors.dark2} !important;
    }
  
    &:after {
      border-bottom-color: ${({ theme }) => theme.colors.dark2} !important;
    }
  }

  .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
    transform: rotate(180deg) !important;
    left: 50% !important;
    top: 0;
    margin-top: -8px;

    &:before {
      border-top-color: ${({ theme }) => theme.colors.dark2} !important;
    }
  
    &:after {
      border-top-color: ${({ theme }) => theme.colors.dark2} !important;
    }
  }
}`;

export default DatepickerCustomStyles;
