import type { FC } from 'react';

import { Chevron, Col, RowBase, RowLink } from './styles';
import type { Props } from './types';

const BaseListButton: FC<Props> = ({
  children,
  className,
  onClick,
  pathname,
  id = '',
}) => (
  <RowLink
    className={className}
    href={pathname}
    variant="link"
    onClick={onClick}
  >
    <RowBase id={id}>
      {children}
      <Col>
        <Chevron />
      </Col>
    </RowBase>
  </RowLink>
);

export default BaseListButton;
