import { normalizeStatisticalAnalysis } from 'model/StatisticalAnalysis';

import useVolatilityReport from './useVolatilityReport';

const emptyData = normalizeStatisticalAnalysis([]);

export default function useStatisticalAnalysis({
  client,
  portfolioName,
}: {
  client: string | undefined;
  portfolioName: string | undefined;
}) {
  const { data } = useVolatilityReport({
    client,
    portfolioName,
  });

  return {
    data: data?.statisticalAnalysis ?? emptyData,
  };
}
