import type { FC } from 'react';
import { useCallback } from 'react';

import { Container, LaptopTabs } from './styles';
import type { FilterDate, Props } from './types';

const filterDateOptions = [
  {
    label: 'Prior Month',
    value: 'prior-month' as FilterDate,
  },
  {
    label: 'Current Month',
    value: 'current-month' as FilterDate,
  },
  {
    label: 'Prior Year',
    value: 'prior-year' as FilterDate,
  },
  {
    label: 'Current Year',
    value: 'current-year' as FilterDate,
  },
];

const ControlTabFilterDate: FC<Props> = ({ onChange, selectedFilterDate }) => {
  const handleFilterDateChange = useCallback(
    (filterDate: FilterDate) => {
      onChange(filterDate);
    },
    [onChange],
  );

  return (
    <Container>
      <LaptopTabs<FilterDate>
        onChange={handleFilterDateChange}
        value={selectedFilterDate}
        tabs={filterDateOptions}
      />
    </Container>
  );
};

export default ControlTabFilterDate;
