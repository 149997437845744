import { forwardRef } from 'react';

import { Container } from './styles';
import type { Props } from './types';

const Tooltip = forwardRef<HTMLDivElement, Props>(
  ({ children, className, left, top }, ref) => (
    <Container $top={top} $left={left} className={className} ref={ref}>
      {children}
    </Container>
  ),
);

export default Tooltip;
