import styled, { css } from 'styled-components';

import hexToRgb from 'utils/hexToRgb';

export const Container = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(${({ theme }) => hexToRgb(theme.colors.dark0)}, 0.5);
`;

export const MessageUpdateApp = styled.div<{ $isIOS: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  background: ${({ theme }) => theme.colors.white};
  top: 50%;
  left: 50%;
  border-radius: ${({ $isIOS }) => ($isIOS ? '5%' : '0%')};
  transform: translate(-50%, -50%);
  width: 20rem;
`;

export const TextContainer = styled.div<{ $isIOS: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  padding: 1.5rem;
  align-items: ${({ $isIOS }) => ($isIOS ? 'center' : 'start')};
`;

export const Title = styled.span`
  color: #000000c0;
  font-weight: ${({ theme }) => theme.weights.bold};
  font-size: 1.5rem;
  line-height: 1.25rem;
`;

export const TextDescription = styled.span<{ $isIOS: boolean }>`
  color: #000000c0;
  padding-top: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  font-size: 1rem;
  line-height: 1.25rem;
  text-align: ${({ $isIOS }) => ($isIOS ? 'center' : 'left')};
`;

export const Button = styled.div<{ $isIOS: boolean }>`
  color: ${({ $isIOS }) => ($isIOS ? '#0D81FE' : '#62c7b8')};
  padding: 0.75rem 1.5rem;
  background: transparent;
  text-align: ${({ $isIOS }) => ($isIOS ? 'center' : 'right')};
  font-weight: ${({ theme }) => theme.weights.bold};
  font-size: 1.25rem;
  text-transform: ${({ $isIOS }) => ($isIOS ? 'capitalize' : 'uppercase')};
  ${({ $isIOS }) =>
    $isIOS &&
    css`
      border-top: 1px solid #00000019;
    `}
`;
