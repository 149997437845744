import { IonCheckbox } from '@ionic/react';
import styled from 'styled-components';

import checkbg from 'assets/icons/blue-check.svg';

export const DefaultCheckbox = styled(IonCheckbox)`
  position: relative;
  width: 1.25rem;
  height: 1.25rem;
  margin: 0;
  --background: transparent;
  --background-checked: ${({ theme }) => theme.colors.primary};
  --border-color: ${({ theme }) => theme.colors.white};
  --border-width: 0.0938rem;
  --border-radius: 0.375rem;

  &.checkbox-checked {
    :after {
      content: '';
      top: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url(${checkbg});
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }
  &.checkbox-disabled {
    opacity: 0.1;
  }
`;
