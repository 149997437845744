import { FC, useEffect } from 'react';
import { useHistory } from 'react-router';

import PerformanceBreakdown from 'components/PerformanceBreakdown';
import PortfolioBalance from 'components/PortfolioBalance';
import { useNews } from 'containers/Services/NewsService';
import from from 'styles/responsive';
import useMedia from 'utils/useMedia';

import AssetClassBreakdown from './AssetClassBreakdown';
import useConnect from './connect';
import MarketSummaryDesktop from './MarketSummaryDesktop';
import MarketSummaryMobile from './MarketSummaryMobile';
import {
  Button,
  ButtonContent,
  ButtonText,
  Chevron,
  Container,
  Content,
  LoaderContainer,
  ProfitLossChart,
  ProfitLossSection,
  ProfitLossTitle,
  Spinner,
  TableButton,
  Toolbar,
} from './styles';

const HomeRoot: FC = () => {
  const { replace } = useHistory();
  const {
    leverage,
    performanceBreakdownReports,
    portfolioBalance,
    portfolioEquity,
    profitLossChartData,
    url,
    marketSummaryData,
    handle,
    isDefaultPeriod,
  } = useConnect();
  const newsServices = useNews();

  const isTabletPortrait = useMedia(from.tablet.query);

  useEffect(() => {
    if (newsServices?.notificationToNews) {
      replace('/news');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsServices?.notificationToNews]);

  return (
    <>
      {(!performanceBreakdownReports ||
        !marketSummaryData ||
        !profitLossChartData) && (
        <LoaderContainer>
          <Spinner />
        </LoaderContainer>
      )}
      <Container
        $loading={
          !performanceBreakdownReports ||
          !marketSummaryData ||
          !profitLossChartData
        }
        $mobile={!isTabletPortrait}
      >
        <PortfolioBalance
          equity={portfolioEquity}
          leverage={leverage}
          total={portfolioBalance}
        />
        {!isTabletPortrait && (
          <TableButton
            color="secondary"
            expand="block"
            href={`${url}/client-performance`}
            className={undefined}
          >
            <ButtonContent>
              <ButtonText>Portfolio History</ButtonText> <Chevron />
            </ButtonContent>
          </TableButton>
        )}
        <Content>
          <PerformanceBreakdown
            reports={performanceBreakdownReports ?? []}
            profitLossChartData={profitLossChartData}
          />
          <ProfitLossSection>
            <Toolbar>
              <ProfitLossTitle>Profit & Loss Since Inception</ProfitLossTitle>
              <Button
                size="small"
                color="secondary"
                href={`${url}/client-performance`}
              >
                Portfolio History
              </Button>
            </Toolbar>
            {/* The parent div is already hidden by css on tablet portrait down.
                This is an optimization to avoid an expensive render. */}
            {isTabletPortrait && (
              <ProfitLossChart data={profitLossChartData ?? []} />
            )}
          </ProfitLossSection>
          {isTabletPortrait && (
            <>
              <AssetClassBreakdown
                changeRangeDate={handle.changeRangeDate}
                changeControls={handle.changeControls}
              />
            </>
          )}
          {isTabletPortrait ? (
            <MarketSummaryDesktop
              marketSummaryData={marketSummaryData ?? []}
              isDefaultPeriod={isDefaultPeriod}
            />
          ) : (
            <MarketSummaryMobile
              marketSummaryData={marketSummaryData ?? []}
              isDefaultPeriod={isDefaultPeriod}
            />
          )}
        </Content>
      </Container>
    </>
  );
};

export default HomeRoot;
