import type { FC } from 'react';
import { memo } from 'react';

import { Circle, Group, Line } from './styles';
import type { Props } from './types';

const CursorBar: FC<Props> = ({ bottom, tooltipLeft, tooltipTop, top }) => (
  <Group>
    <Line
      from={{
        x: tooltipLeft,
        y: top,
      }}
      to={{
        x: tooltipLeft,
        y: bottom,
      }}
      pointerEvents="none"
    />
    <Circle cx={tooltipLeft} cy={tooltipTop} r={4} pointerEvents="none" />
  </Group>
);

export default memo(CursorBar);
