import type { FC } from 'react';

import useConnect from './connect';
import { Container, Content, FilterLabelContainer, Table } from './styles';

const BondPerformanceAnalysis: FC = () => {
  const { backUrl, rows, subtypes } = useConnect();

  return (
    <Container parentUrl={backUrl} title="Bond Performance Analysis">
      {subtypes && (
        <FilterLabelContainer>
          <div>
            Filtered by <strong>{subtypes.join(', ')}</strong>
          </div>
        </FilterLabelContainer>
      )}
      <Content>
        <Table rows={rows} />
      </Content>
    </Container>
  );
};

export default BondPerformanceAnalysis;
