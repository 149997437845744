import type { Mutex } from 'async-mutex';

/**
 * An extension to the Mutex interface that works similar to "runExclusive" in
 * that only one worker will run at a time, except "runExclusive" queues
 * subsequent calls and runs them serially, and "runExclusiveDiscarding"
 * discards subsequent calls, but still waits until the original worker is
 * finished.
 */
export default async function runExclusiveDiscarding(
  mutex: Mutex,
  worker: () => Promise<unknown>,
): Promise<void> {
  if (mutex.isLocked()) {
    await mutex.waitForUnlock();
  } else {
    await mutex.runExclusive(worker);
  }
}
