import type { GetMarketSummaryResponse } from 'api/queries/markets/getMarketSummary/types';
import type { WithoutNulls } from 'utils/withoutNulls';

type Input = NonNullable<GetMarketSummaryResponse['data']>[number];

export const normalizeMarketSummary = (input: WithoutNulls<Input>) => {
  const id = input.id ?? 0;
  const name = input.name ?? '';
  const shortName = input.shortName ?? '';
  const mtd = input.mtd ?? 0;
  const ytd = input.ytd ?? 0;
  const instrumentType = input.instrumentType ?? '';

  return {
    id,
    name,
    shortName,
    mtd,
    ytd,
    instrumentType,
  } as const;
};

export type MarketSummary = ReturnType<typeof normalizeMarketSummary>;
