import { FC, memo } from 'react';

import type { Props } from '../types';
import {
  BlueTickIcon,
  Container,
  ContainerDetails,
  DateSourceItem,
  DescriptionItem,
  ImagenContainer,
  MarkAsReadButton,
  MarkAsUnreadButton,
} from './styles';

const NewsCardDesktopTablet: FC<Props> = ({
  messageID,
  url,
  message,
  date,
  source,
  image,
  className,
  isRead,
  onUpdateNewsReadStatus,
}) => {
  const openInNewTab = () => {
    window.open(url, '_blank', 'noreferrer');

    if (!isRead) {
      onUpdateNewsReadStatus(messageID);
    }
  };

  return (
    <Container $isRead={isRead}>
      {image && <ImagenContainer $url={image} onClick={openInNewTab} />}
      <ContainerDetails onClick={openInNewTab}>
        <DescriptionItem>{message}</DescriptionItem>
        <DateSourceItem>{`${date} · ${source}`}</DateSourceItem>
      </ContainerDetails>
      {!isRead ? (
        <MarkAsReadButton
          className={className}
          onClick={() => onUpdateNewsReadStatus(messageID)}
        >
          Unread
        </MarkAsReadButton>
      ) : (
        <MarkAsUnreadButton
          className={className}
          onClick={() => onUpdateNewsReadStatus(messageID)}
        >
          <BlueTickIcon />
          Read
        </MarkAsUnreadButton>
      )}
    </Container>
  );
};

export default memo(NewsCardDesktopTablet);
