import styled, { css } from 'styled-components';

import DefaultChangePercentage from 'components/ChangePercentage';
import from from 'styles/responsive';

export const FirstCol = styled.div<{ $isResume?: boolean }>`
  text-align: left;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  ${from.laptop`
    font-size: 1rem;
  `}
  ${({ $isResume = false, theme }) =>
    $isResume &&
    `
      font-weight: ${theme.weights.bold};
      color: ${theme.colors.white};
  `}
`;

export const Table = styled.div`
  display: grid;
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.875rem;
  line-height: 1.1875rem;
  color: ${({ theme }) => theme.colors.white};
  > :nth-child(even) {
    background: ${({ theme }) => theme.colors.dark1};
  }
  > :nth-child(2n + 9) {
    > ${FirstCol} {
      font-weight: ${({ theme }) => theme.weights.bold};
      color: ${({ theme }) => theme.colors.white};
    }
  }
  ${from.laptop`
    font-size: 1rem;
  `}
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: 30% repeat(3, 15%) 22%;
  column-gap: 0.5rem;
  padding: 1rem 1.5rem;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.weights.bold};
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  text-align: right;
  > :first-child {
    text-align: left;
  }
`;

export const HeaderCell = styled.div`
  text-align: center;
  font-size: 0.75rem;
  line-height: 1.0625rem;
  ${from.laptop`
    font-size: 0.875rem;
  `}
`;

export const Row = styled.div<{ $isResume?: boolean }>`
  display: grid;
  grid-template-columns: 30% repeat(3, 15%) 22%;
  column-gap: 0.5rem;
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.white10};
  padding: 1rem 1.5rem;

  ${({ $isResume = false, theme }) =>
    $isResume &&
    `
      border-bottom: 1px solid ${theme.colors.white};
      border-top: 1px solid ${theme.colors.white};
  `}
`;

const columnStyles = css`
  text-align: center;
`;

export const Col = styled.div<{ $isResume?: boolean }>`
  ${columnStyles};

  ${({ $isResume = false, theme }) =>
    $isResume &&
    `
      font-weight: ${theme.weights.bold};
      color: ${theme.colors.white};
  `}
`;

export const ChangePercentage = styled(DefaultChangePercentage)`
  ${columnStyles};
`;
