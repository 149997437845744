import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

import useAllocationGroups from 'api/hooks/allocations/useAllocationGroups';
import useNotionalAssets from 'api/hooks/allocations/useNotionalAssets';
import { formatAmount } from 'utils/amounts';
import { formatPercentage } from 'utils/percentages';

import {
  useCurrentGrouping,
  useCurrentSubtypes,
  useNavigationLinks,
} from '../CategoryDetail/logic';

export function useData({
  portfolio,
  url,
  search,
}: {
  portfolio: string | undefined;
  url: string;
  search: string;
}) {
  const currentSubtypes = useCurrentSubtypes({ search });
  const {
    params: { categorySlug },
  } = useRouteMatch<{ categorySlug: string }>();

  const { getNameBySlug } = useAllocationGroups({
    portfolio,
    groupBy: 'asset-breakdown',
    subselection: undefined,
  });
  const grouping = useCurrentGrouping({ search });
  const { pageTitle } = useNavigationLinks({
    categorySlug,
    getNameBySlug,
    grouping,
    search,
    url,
  });

  const { data: notionalValues, subtypes } = useNotionalAssets({
    filterValue: currentSubtypes?.join(','),
    portfolio,
    grouping,
    categorySlug,
  });

  const mobileRows = useMemo(
    () => [
      {
        id: 1,
        key: `${pageTitle} (Physical exposure)`,
        value: formatAmount(notionalValues?.balance ?? 0),
        subValue: formatPercentage(notionalValues?.balancePct ?? 0),
      },
      {
        id: 2,
        key: `${pageTitle} Options (Notional)`,
        value: formatAmount(notionalValues?.notional ?? 0),
        subValue: formatPercentage(notionalValues?.notionalPct ?? 0),
      },
      {
        id: 3,
        key: `Total Notional ${pageTitle}`,
        value: formatAmount(notionalValues?.totalNotional ?? 0),
        subValue: formatPercentage(notionalValues?.totalNotionalPct ?? 0),
      },
    ],
    [pageTitle, notionalValues],
  );

  return { mobileRows, pageTitle, notionalValues, subtypes };
}
