export type Datum = Readonly<{
  id: string;
  instrumentType: string;
  target: number;
  current: number;
  range: { lowerBound: number; upperBound: number };
}>;

export const instrumentTypeOrdered: string[] = [
  'Equity-Individual',
  'Equity-Market',
  'Fixed Income',
  'Alternatives',
  'Commodity',
  'Private Investments',
  'Cash',
];

export type Props = {
  className?: string;
  data: readonly Datum[];
};
