import { useCallback } from 'react';
import { useMutation } from 'react-query';

import useRequestResetPasswordMutation from 'api/mutations/auth/requestResetPassword';
import type { RequestResetPasswordVariables } from 'api/mutations/auth/requestResetPassword/types';

export default function useRequestResetPassword() {
  const { isLoading, isSuccess, mutateAsync } = useMutation(
    useRequestResetPasswordMutation(),
  );

  const requestResetPassword = useCallback(
    (variables: RequestResetPasswordVariables) => mutateAsync(variables),
    [mutateAsync],
  );

  return { loading: isLoading, requestResetPassword, success: isSuccess };
}
