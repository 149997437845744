import { useCallback } from 'react';
import type { QueryFunction } from 'react-query';

import useRequest from 'api/request';
import type { AssetClassBreakdownAsset } from 'model/AssetClassBreakdownAsset';
import { normalizeAssetClassBreakdownAsset } from 'model/AssetClassBreakdownAsset';
import getPortfolioId from 'utils/getPorfolioId';
import last12Month from 'utils/last12Month';
import withoutNulls from 'utils/withoutNulls';

import type {
  GetAssetClassBreakdownDetailRequest,
  GetAssetClassBreakdownDetailResponse,
} from './types';

export const GET_ASSET_CLASS_BREAKDOWN_DETAIL_QUERY_KEY = ({
  portfolio,
  reportSlug,
  timeFrame,
}: {
  portfolio: string;
  reportSlug: string;
  timeFrame: string;
}) => ['home/getAssetClassBreakdownDetail', reportSlug, portfolio, timeFrame];

function denormalizePeriod(input: string) {
  switch (input) {
    case 'daily-estimate':
      return 'Daily' as const;
    case 'month-to-date':
      return 'MTD' as const;
    case 'year-to-date':
      return 'YTD' as const;
    case 'since-inception':
    default:
      return 'SI' as const;
  }
}

export default function useGetAssetClassBreakdownDetailQuery(): QueryFunction<
  AssetClassBreakdownAsset[]
> {
  const request = useRequest<
    GetAssetClassBreakdownDetailRequest,
    GetAssetClassBreakdownDetailResponse
  >();

  return useCallback(
    async ({ queryKey }) => {
      const [, period, portfolio, timeFrame] = queryKey as [
        string,
        string,
        string,
        string,
      ];

      const denormalizeP = denormalizePeriod(period);

      let response;
      if (denormalizeP === 'Daily' || denormalizeP === 'SI') {
        response = await request(
          `/api/CVData/getAssetClassBreakDown_${denormalizeP}_Detail/{portfolio}` as const,
          {
            urlParams: {
              portfolio: getPortfolioId(portfolio) ?? '',
            },
          },
        );
      } else {
        const defaultTimeFrame =
          denormalizeP === 'YTD'
            ? new Date().getFullYear().toString()
            : last12Month(undefined)[0] ?? '';

        response = await request(
          `/api/CVData/getAssetClassBreakDown_TimeFrame_Detail/{portfolio}/{timeFrame}` as const,
          {
            urlParams: {
              portfolio: getPortfolioId(portfolio) ?? '',
              timeFrame: timeFrame !== '' ? timeFrame : defaultTimeFrame,
            },
          },
        );
      }

      const data = await response.json();

      if (!data.isSuccess) {
        throw new Error(data.message ?? '');
      }

      return withoutNulls(data.data ?? []).map(
        normalizeAssetClassBreakdownAsset,
      );
    },
    [request],
  );
}
