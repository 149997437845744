/* eslint-disable @typescript-eslint/no-explicit-any */
import uniq from 'lodash/uniq';

export function instrumentTypes(data: any | undefined) {
  return uniq(
    data
      ?.map((d: { [x: string]: number }) => {
        const keysWithNonZeroValues = Object.keys(d).filter(
          (key) => d[key] !== 0 && key !== 'date' && key !== 'id',
        );
        return keysWithNonZeroValues;
      })
      .flat(),
  ).sort((a, b) => (a as string).localeCompare(b as string)) as string[];
}

export function getFilterOption(instrumentType: string) {
  switch (instrumentType) {
    case 'Commodity':
    case 'commodity':
      return {
        label: 'Commodity',
        value: 'commodity',
      };
    case 'Cash':
    case 'cash':
      return {
        label: 'Cash',
        value: 'cash',
      };
    case 'Alternatives':
    case 'alternatives':
      return {
        label: 'Alternatives',
        value: 'alternatives',
      };
    case 'Fixed Income':
    case 'fixedIncome':
      return {
        label: 'Fixed Income',
        value: 'fixedIncome',
      };
    case 'Equity-Individual':
    case 'equityIndividual':
      return {
        label: 'Equity-Individual',
        value: 'equityIndividual',
      };
    case 'Equity-Market':
    case 'equityMarket':
      return {
        label: 'Equity-Market',
        value: 'equityMarket',
      };
    case 'Private Investments':
    case 'privateInvestments':
      return {
        label: 'Private Investments',
        value: 'privateInvestments',
      };
    default:
      return {
        label: 'Filter',
        value: 'default',
      };
  }
}
