import { Group } from '@visx/group';
import { Bar } from '@visx/shape';
import { FC, memo, MouseEvent, TouchEvent } from 'react';

import { LinePath, MarkerArrow, MarkerX } from './styles';
import type { Line, Props } from './types';

const LineChart: FC<Props> = ({
  data,
  isTablet,
  isLaptop,
  xScale,
  yScale,
  widthToCenterBand,
  showInstrumentArrow,
  handleTooltipClosed,
  handleTooltipUpdated,
  handleInstrumentArrowClosed,
  handleInstrumentArrowUpdated,
  handleInstrumentTypeChange,
}) => {
  const onMouseLeave = () => {
    handleInstrumentArrowClosed();
    handleTooltipClosed();
  };

  const onMouseMove = (
    event: MouseEvent<SVGRectElement> | TouchEvent<SVGRectElement>,
  ) => {
    handleInstrumentArrowUpdated(event);
    handleTooltipUpdated(event);
  };

  const mapping = data.map(
    (d) =>
      ({
        id: d.id,
        instrumentType: d.instrumentType,
        points: [d.current, d.target],
        withoutRanges: d.range.lowerBound === d.range.upperBound,
      } as Line),
  );

  return (
    <svg>
      <MarkerX
        id="marker-x"
        size={18}
        strokeWidth={2}
        markerUnits="userSpaceOnUse"
      />
      <MarkerArrow id="marker-arrow" refX={8} size={4} strokeWidth={1} />
      {mapping.map((lineData) => (
        <Group key={`line-${lineData.instrumentType}`}>
          {lineData.withoutRanges && (
            <Bar
              fill="transparent"
              key={`bar-${lineData.id}`}
              id={`bar-${lineData.id}`}
              x={xScale(lineData.instrumentType) ?? 0}
              width={xScale.bandwidth()}
              y={yScale(lineData.points[0] ?? 0) - 20}
              height={40}
              onMouseLeave={onMouseLeave}
              onMouseMove={onMouseMove}
              onClick={isTablet ? handleInstrumentTypeChange : undefined}
            />
          )}
          <LinePath
            $showInstrumentArrow={
              lineData.instrumentType === showInstrumentArrow ||
              (isTablet && !isLaptop)
            }
            strokeWidth={2}
            $strokeTransparent={false}
            data={lineData.points}
            x={(xScale(lineData.instrumentType) || 0) + widthToCenterBand ?? 0}
            y={(d) => yScale(d as number) ?? 0}
          />
          <LinePath
            $showInstrumentArrow={
              lineData.instrumentType === showInstrumentArrow ||
              (isTablet && !isLaptop)
            }
            strokeWidth={2}
            $strokeTransparent
            data={lineData.points}
            x={(xScale(lineData.instrumentType) || 0) + widthToCenterBand ?? 0}
            y={(d) => yScale(d as number) ?? 0}
            markerStart="url(#marker-x)"
            markerEnd={
              lineData.instrumentType === showInstrumentArrow ||
              (isTablet && !isLaptop)
                ? 'url(#marker-arrow)'
                : ''
            }
          />
        </Group>
      ))}
    </svg>
  );
};

export default memo(LineChart);
