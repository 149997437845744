import { Form as DefaultForm } from 'formik';
import styled from 'styled-components';

import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import AllocationsHeader from 'components/AllocationsHeader';
import Button from 'components/Button';
import SectionNavigation from 'components/SectionNavigation';
import Tabs from 'components/Tabs';
import { TabButton } from 'components/Tabs/styles';

export const Container = styled.div`
  display: grid;
  grid-template-rows: repeat(4, auto);
`;

export const MainTabs = styled(Tabs)`
  ${TabButton} {
    flex-basis: 50%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled(AllocationsHeader)`
  margin-bottom: 0;
`;

export const TableContainer = styled.div`
  position: relative;
  padding: 2rem 1.25rem 2rem;
`;

export const SortButton = styled(Button)`
  width: fit-content;
  position: absolute;
  right: 1.25rem;
`;

export const SortInfo = styled.span``;

export const Navigation = styled(SectionNavigation)`
  padding: 2rem 1.25rem;
`;

export const ModalTitle = styled.h2`
  margin: 0 0 1rem;
  font-size: 1.375rem;
  line-height: 1.4;
  font-weight: ${({ theme }) => theme.weights.medium};
`;

export const Form = styled(DefaultForm)``;

export const TableButton = styled(Button)`
  min-width: auto;
  margin: 2rem 1.25rem 0;
`;

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ButtonText = styled.span`
  width: 100%;
  text-align: left;
  font-size: 1rem !important;
`;

export const Chevron = styled(ChevronRight)``;
