import useAccessTokenQuery, {
  ACCESS_TOKEN_QUERY_KEY,
} from 'api/queries/auth/accessToken';
import { useQuery } from 'api/storage';

export default function useEmail() {
  const { data } = useQuery(ACCESS_TOKEN_QUERY_KEY, {
    queryFn: useAccessTokenQuery(),
    staleTime: Infinity,
  });

  return {
    data: data?.email ?? '',
  };
}
