import { useMemo } from 'react';

import { formatAmount } from 'utils/amounts';

import type { Props } from './types';

const decimals = new Intl.NumberFormat('en-Us', {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export function useFormattedData({
  totalCost,
  couponPL,
  bondPL,
  couponsToMaturity,
  recoveryToPar,
  totalFinalPL,
  weightedAverage,
  currBondValue,
}: {
  totalCost: Props['totalCost'];
  couponPL: Props['couponPL'];
  bondPL: Props['bondPL'];
  couponsToMaturity: Props['couponsToMaturity'];
  recoveryToPar: Props['recoveryToPar'];
  totalFinalPL: Props['totalFinalPL'];
  weightedAverage: Props['weightedAverage'];
  currBondValue: Props['currBondValue'];
}) {
  return useMemo(
    () => [
      {
        id: 1,
        title: 'Total Cost of Bond Book',
        value: formatAmount(totalCost),
      },
      {
        id: 2,
        title: 'Bond P&L',
        value: formatAmount(bondPL),
      },
      {
        id: 3,
        title: 'Coupons Received',
        value: formatAmount(couponPL),
      },
      {
        id: 4,
        title: 'Current Bond Portfolio Value',
        value: formatAmount(currBondValue),
      },
      {
        id: 5,
        title: 'Avg. Bond Price',
        value: decimals.format(weightedAverage),
      },
      {
        id: 6,
        title: 'Recovery to Par',
        value: formatAmount(recoveryToPar),
      },
      {
        id: 7,
        title: 'Coupons to Maturity',
        value: formatAmount(couponsToMaturity),
      },
      {
        id: 8,
        title: 'Total P&L to Maturity',
        value: formatAmount(totalFinalPL),
      },
    ],
    [
      totalCost,
      couponPL,
      bondPL,
      couponsToMaturity,
      recoveryToPar,
      totalFinalPL,
      weightedAverage,
      currBondValue,
    ],
  );
}
