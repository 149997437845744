import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { ACCESS_TOKEN_QUERY_KEY } from 'api/queries/auth/accessToken';
import type { Tokens } from 'model/Tokens';

export const INITIALIZED_KEY = 'com.cv_advisors.app_client_initialized';
export default function useLocalDataCleanup() {
  const client = useQueryClient();

  return useCallback(() => {
    // Even though we execute client.clear() below, that does not broadcast
    // to hooks, so we explicitly remove the access tokens to force a
    // redirect to the login screen.
    client.setQueryData<Tokens>(ACCESS_TOKEN_QUERY_KEY, {
      accessToken: undefined,
      refreshToken: undefined,
    });
    client.clear();
    sessionStorage.removeItem(INITIALIZED_KEY);
  }, [client]);
}
