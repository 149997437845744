import type { RefObject } from 'react';

const updateTooltipPosition = ({
  barItemRef,
  tooltipRef,
}: {
  barItemRef: RefObject<HTMLDivElement | null>;
  tooltipRef: RefObject<HTMLDivElement | null>;
}) => {
  const screenPadding = 16;
  const barItem = barItemRef.current;
  const tooltip = tooltipRef.current;
  if (!barItem || !tooltip) {
    return;
  }

  const placeholderRect = barItem.getBoundingClientRect();
  const dropdownRect = tooltip.getBoundingClientRect();

  const dropdownRightX = dropdownRect.x + dropdownRect.width;
  const placeholderRightX = placeholderRect.x + placeholderRect.width;

  if (dropdownRect.x < 0) {
    tooltip.style.left = '6px';
    tooltip.style.right = 'auto';
    tooltip.style.transform = `translateX(${
      -placeholderRect.x + screenPadding
    }px)`;
  } else if (dropdownRightX > window.outerWidth) {
    tooltip.style.left = 'auto';
    tooltip.style.right = '6px';
    tooltip.style.transform = `translateX(${
      window.outerWidth - placeholderRightX - screenPadding
    }px)`;
  }
};

export default updateTooltipPosition;
