import type { TickFormatter } from '@visx/axis';
import { nanoid } from 'nanoid/non-secure';
import { FC, memo, useMemo } from 'react';
import useResizeObserver from 'use-resize-observer';

import from from 'styles/responsive';
import { formatYear } from 'utils/dates';
import { formatPercentageWithDecimalsIfNecessary } from 'utils/percentages';
import useMedia from 'utils/useMedia';

import Chart from './Chart';
import CursorBar from './CursorBar';
import Legend from './Legend';
import {
  useEdgeValues,
  useHeight,
  useMargins,
  useScales,
  useTooltip,
} from './logic';
import {
  AreaGradient,
  AxisBottom,
  AxisLeft,
  Container,
  GraphVector,
  Grid,
  LegendText,
} from './styles';
import Tooltip from './Tooltip';
import type { Props } from './types';

const YieldHistoryChart: FC<Props> = ({
  className,
  data: readonlyData,
  isInteractive = true,
  chartKeys = ['portfolioYield', 'adjustedTreasuryYield'],
}) => {
  // visx wants mutable arrays even though it will never mutate them
  const data = useMemo(() => [...readonlyData], [readonlyData]);

  const isTablet = useMedia(from.tablet.query);
  const isLaptop = useMedia(from.laptop.query);

  const { ref, width = 0 } = useResizeObserver();
  const height = useHeight({ isLaptop, isTablet });

  const {
    bottomAxisHeight,
    bottomAxisMargin,
    leftAxisMargin,
    leftAxisWidth,
    rightMargin,
    topMargin,
  } = useMargins({ isTablet });
  const { end, maxValue, minValue, start } = useEdgeValues(data, chartKeys);
  const { xScale, yScale } = useScales({
    bottomAxisHeight,
    bottomAxisMargin,
    end,
    height,
    leftAxisMargin,
    leftAxisWidth,
    maxValue,
    minValue,
    rightMargin,
    start,
    topMargin,
    width,
  });

  const {
    handleTooltipClosed,
    handleTooltipUpdated,
    tooltipData,
    tooltipLeft,
    tooltipTop,
    chartKeysY,
  } = useTooltip({
    data,
    leftAxisMargin,
    leftAxisWidth,
    xScale,
    yScale,
    chartKeys,
  });

  const chartId = useMemo(nanoid, []);

  return (
    <Container ref={ref} className={className}>
      <GraphVector width="100%" viewBox={`0 0 ${width} ${height}`}>
        {chartKeys.map((key) => (
          <AreaGradient id={key} key={key} $chartKey={key} $chartId={chartId} />
        ))}
        <Grid
          scale={yScale}
          width={width - leftAxisWidth - leftAxisMargin - rightMargin}
          left={leftAxisWidth + leftAxisMargin}
        />
        <AxisLeft
          left={leftAxisWidth}
          scale={yScale}
          tickFormat={
            formatPercentageWithDecimalsIfNecessary as TickFormatter<unknown>
          }
        />
        <AxisBottom
          tickFormat={formatYear}
          left={leftAxisWidth + leftAxisMargin}
          scale={xScale}
          top={height - bottomAxisHeight}
        />
        <LegendText
          $x={25}
          $y={height / 2}
          dominantBaseline="central"
          textAnchor="middle"
        >
          Yield Historical (%)
        </LegendText>
        {chartKeys.map((chartKey) => (
          <Chart
            key={chartKey}
            bottom={height - bottomAxisHeight - bottomAxisMargin}
            data={data}
            handleTooltipClosed={handleTooltipClosed}
            handleTooltipUpdated={handleTooltipUpdated}
            left={leftAxisWidth + leftAxisMargin}
            right={width - rightMargin}
            top={topMargin}
            yScale={yScale}
            xScale={xScale}
            chartKey={chartKey}
            chartId={chartId}
          />
        ))}
        {typeof tooltipLeft === 'number' &&
          typeof tooltipTop === 'number' &&
          chartKeysY &&
          isInteractive && (
            <CursorBar
              bottom={height - bottomAxisHeight - bottomAxisMargin}
              tooltipLeft={tooltipLeft}
              top={topMargin}
              chartKeysY={chartKeysY}
            />
          )}
      </GraphVector>
      {typeof tooltipData !== 'undefined' &&
        typeof tooltipLeft === 'number' &&
        typeof tooltipTop === 'number' &&
        isInteractive && (
          <Tooltip
            data={tooltipData}
            tooltipLeft={tooltipLeft}
            tooltipTop={tooltipTop}
            chartKeys={chartKeys}
          />
        )}
      <Legend keys={chartKeys} />
    </Container>
  );
};

export default memo(YieldHistoryChart);
