/* eslint-disable no-await-in-loop */
import { AppUpdate } from '@capawesome/capacitor-app-update';
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import env from 'utils/env';
import usePlatform from 'utils/usePlatform/usePlatform';
import wait from 'utils/wait';

import type { UpdateAppContextModel } from './types';

const Context = createContext<UpdateAppContextModel | undefined>(undefined);

const UpdateAppProvider: FC = ({ children }) => {
  const { isWeb, isAndroid, isIOS } = usePlatform();
  const [isUpdateAppAvailable, setUpdateAppAvailable] =
    useState<boolean>(false);
  const [updateInProcess, setUpdateInProcess] = useState<boolean>(false);

  function compareVersions(
    availableVersion: number | string,
    currentVersion: number | string,
  ) {
    if (isAndroid) {
      return +availableVersion > +currentVersion;
    }

    if (isIOS) {
      const availableVersionSplit =
        availableVersion.toString().split('.') ?? [];
      const currentVersionSplit = currentVersion.toString().split('.') ?? [];

      let isAvailable = false;

      for (let i = 0; i < availableVersionSplit.length; i += 1) {
        if (!isAvailable) {
          if (
            +(availableVersionSplit[i] ?? 0) !== +(currentVersionSplit[i] ?? 0)
          ) {
            isAvailable =
              +(availableVersionSplit[i] ?? 0) > +(currentVersionSplit[i] ?? 0);

            break;
          }
        }
      }

      return isAvailable;
    }

    return false;
  }

  const availableUpdate = useCallback(async () => {
    if (!isWeb && env('REACT_APP_FRONTEND_HOST') === 'app.cv-advisors.com') {
      const updateInfo = await AppUpdate?.getAppUpdateInfo();

      let isAvailable = false;

      if (isIOS) {
        isAvailable = compareVersions(
          updateInfo.availableVersionName ?? updateInfo.currentVersionName,
          updateInfo.currentVersionName,
        );
      } else if (isAndroid) {
        isAvailable = compareVersions(
          updateInfo.availableVersionCode ?? updateInfo.currentVersionCode,
          updateInfo.currentVersionCode,
        );
      }

      if (isAvailable) {
        setUpdateAppAvailable(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWeb]);

  const openAppStore = useCallback(async () => {
    await AppUpdate?.openAppStore();

    if (!updateInProcess) {
      setUpdateInProcess(true);
      let updateCompleted = false;

      do {
        await availableUpdate();

        if (isUpdateAppAvailable) {
          await wait(1500);
        } else {
          setUpdateAppAvailable(false);
          updateCompleted = true;
        }
      } while (!updateCompleted);

      setUpdateInProcess(false);
    }
  }, [availableUpdate, isUpdateAppAvailable, updateInProcess]);

  useEffect(() => {
    void availableUpdate();
  }, [availableUpdate]);

  const value = useMemo(
    () => ({
      isUpdateAppAvailable,
      openAppStore,
      availableUpdate,
    }),
    [isUpdateAppAvailable, openAppStore, availableUpdate],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export function useUpdateApp() {
  return useContext(Context);
}

export default UpdateAppProvider;
