import { useRouteMatch } from 'react-router';

import useLiquidity from 'api/hooks/allocations/useLiquidity';
import useSelectedClient from 'api/hooks/clients/useSelectedClient';
import up from 'utils/paths';

import { useSummary } from '../CategoryDetailCash/logic';

export default function useConnect() {
  const { url } = useRouteMatch();
  const parentUrl = up(url);

  const { selectedPortfolio } = useSelectedClient();

  const { data: liquidity } = useLiquidity(selectedPortfolio?.id);

  const summary = useSummary({
    accounts: liquidity,
  });

  return { summary, parentUrl };
}
