import { useCallback } from 'react';
import type { QueryFunction } from 'react-query';

import useRequest from 'api/request';
import type { WinnerLoser } from 'model/WinnerLoser';
import { normalizeWinnerLoser } from 'model/WinnerLoser';
import getFullPortfolioName from 'utils/getFullPortfolioName';
import getPortfolioId from 'utils/getPorfolioId';
import withoutNulls from 'utils/withoutNulls';

import type {
  GetWinnersLosersRequest,
  GetWinnersLosersResponse,
} from './types';

export const GET_WINNERS_LOSERS_QUERY_KEY = ({
  clientName,
  portfolioName,
  portfolio,
}: {
  clientName: string;
  portfolioName: string;
  portfolio: string;
}) => ['home/getWinnersLosers', clientName, portfolioName, portfolio];

export default function useWinnersLosers(): QueryFunction<{
  losers: readonly WinnerLoser[];
  winners: readonly WinnerLoser[];
}> {
  const request = useRequest<
    GetWinnersLosersRequest,
    GetWinnersLosersResponse
  >();

  return useCallback(
    async ({ queryKey }) => {
      const [, clientName, portfolioName, portfolio] = queryKey as [
        string,
        string,
        string,
        string,
      ];

      const response = await request(
        '/api/CVData/getWinnersLosers/{portfolio}',
        {
          urlParams: {
            portfolio: getPortfolioId(portfolio) ?? '',
          },
        },
      );

      const data = await response.json();

      if (!data.isSuccess) {
        throw new Error(data.message ?? '');
      }

      const raw = withoutNulls(data.data)?.find(
        (it) =>
          it.name ===
          getFullPortfolioName({
            clientName,
            portfolioName,
          }),
      )?.winnerLosers;

      const find = (type: string) =>
        raw
          ?.find((it) => it.typeWinnerLosers === type)
          ?.winnerLosersList?.map(normalizeWinnerLoser) ?? [];

      return { losers: find('Losers'), winners: find('Winners') };
    },
    [request],
  );
}
