import { FC, memo } from 'react';

import { useUpdateApp } from 'containers/Services/UpdateAppService';
import usePlatform from 'utils/usePlatform/usePlatform';

import {
  Button,
  Container,
  MessageUpdateApp,
  TextContainer,
  TextDescription,
  Title,
} from './style';

const UpdateAppOverlay: FC = () => {
  const updateApp = useUpdateApp();
  const { isIOS, isAndroid } = usePlatform();

  const goToUpdate = () => {
    updateApp?.openAppStore();
  };

  if (!isIOS && !isAndroid) {
    return <></>;
  }

  return (
    <Container>
      <MessageUpdateApp $isIOS={isIOS}>
        <TextContainer $isIOS={isIOS}>
          <Title>New Version Available</Title>
          <TextDescription $isIOS={isIOS}>
            There is a newer version available for download! Please update the
            app by visiting the {isIOS ? 'Apple Store' : 'Play Store'}
          </TextDescription>
        </TextContainer>
        <Button $isIOS={isIOS} onClick={goToUpdate}>
          UPDATE
        </Button>
      </MessageUpdateApp>
    </Container>
  );
};

export default memo(UpdateAppOverlay);
