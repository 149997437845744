import { memo, MouseEvent, TouchEvent } from 'react';
import type { FC } from 'react';

import { Bar } from './styles';
import type { Props } from './types';

const BarChart: FC<Props> = ({
  data,
  isTablet,
  xScale,
  yScale,
  bottomHeight,
  handleTooltipClosed,
  handleTooltipUpdated,
  handleInstrumentArrowClosed,
  handleInstrumentArrowUpdated,
  handleInstrumentTypeChange,
}) => {
  const onMouseLeave = () => {
    handleInstrumentArrowClosed();
    handleTooltipClosed();
  };

  const onMouseMove = (
    event: MouseEvent<SVGRectElement> | TouchEvent<SVGRectElement>,
  ) => {
    handleInstrumentArrowUpdated(event);
    handleTooltipUpdated(event);
  };

  return (
    <g>
      {data.map((datum) => (
        <Bar
          key={`bar-${datum.id}`}
          id={`bar-${datum.id}`}
          x={xScale(datum.instrumentType) ?? 0}
          width={xScale.bandwidth()}
          y={yScale(datum.range.upperBound)}
          height={
            bottomHeight -
            yScale(datum.range.upperBound - datum.range.lowerBound)
          }
          onMouseLeave={onMouseLeave}
          onMouseMove={onMouseMove}
          onClick={isTablet ? handleInstrumentTypeChange : undefined}
        />
      ))}
    </g>
  );
};

export default memo(BarChart);
