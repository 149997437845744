import { FC, memo, useEffect, useState } from 'react';

import getClientJoinPortfolio from 'utils/getClientJoinPortfolio';

import {
  Container,
  RadioGroup,
  RadioItem,
  SearchBar,
  SearchBarList,
  SubTitle,
  Title,
  TitleSearchList,
} from './styles';
import type { Props, SearchItem } from './types';

const Settings: FC<Props> = ({
  accounts,
  onChangeAccount,
  onChangeDefaultAccount,
  onChangeSearch,
  searchValue,
  selectedAccount = '',
  selectedPortfolio = '',
  settingsActive,
}) => {
  const [accountsOrdered, setAccountsOrdered] = useState<SearchItem[]>([]);

  useEffect(() => {
    const elem = accounts.find((acc) => acc.label === selectedAccount);

    if (elem) {
      const accountsAux = accounts.filter(
        (acc) => acc.label !== selectedAccount,
      );

      accountsAux.unshift(elem);

      setAccountsOrdered(accountsAux);
    } else {
      setAccountsOrdered(accounts);
    }
  }, [accounts, selectedAccount]);

  if (settingsActive) {
    return (
      <Container>
        {accountsOrdered.length >= 7 ? (
          <SearchBar
            id="search-bar-settings"
            placeholder="Search"
            searchItemsList={accountsOrdered}
            variant="radio"
            onChange={onChangeSearch}
            onChangeAccount={onChangeAccount}
            onChangeDefaultAccount={onChangeDefaultAccount}
            selectedAccount={getClientJoinPortfolio(
              selectedAccount,
              selectedPortfolio,
            )}
            value={searchValue}
            title="Default Portfolio"
            subtitle="Select a portfolio to load by default when opening the App"
          />
        ) : (
          <Container>
            <Title>Default Portfolio</Title>
            <SubTitle>
              Select a portfolio to load by default when opening the App
            </SubTitle>
            <RadioGroup
              value={selectedAccount}
              onIonChange={onChangeDefaultAccount}
            >
              {accounts.map((item) =>
                item.subPortfolios.map((portfolio) => (
                  <RadioItem
                    key={getClientJoinPortfolio(item.value, portfolio.value)}
                    optionType="radiobutton"
                    id={`sidebar-default-account-option-${getClientJoinPortfolio(
                      item.value,
                      portfolio.value,
                    )}`}
                    value={getClientJoinPortfolio(item.value, portfolio.value)}
                    label={getClientJoinPortfolio(
                      item.value,
                      portfolio.value,
                      ' ',
                    )}
                  />
                )),
              )}
            </RadioGroup>
          </Container>
        )}
      </Container>
    );
  }
  return (
    <Container>
      <TitleSearchList>Directory</TitleSearchList>
      <SearchBarList
        id="search-bar-settings"
        placeholder="Search"
        searchItemsList={accounts}
        onChange={onChangeSearch}
        onChangeAccount={onChangeAccount}
        selectedAccount={selectedAccount}
        value={searchValue}
        variant="list"
      />
    </Container>
  );
};
export default memo(Settings);
