import groupBy from 'lodash/groupBy';
import uniq from 'lodash/uniq';
import { nanoid } from 'nanoid/non-secure';
import { useMemo } from 'react';

import useGetMarketOverviewQuery, {
  GET_MARKET_OVERVIEW_QUERY_KEY,
} from 'api/queries/markets/getMarketOverview';
import { useQuery } from 'api/storage';
import type { MarketWatchlistAsset } from 'model/MarketWatchlistAsset';

export type NormalizedAsset = {
  dailyPercentChange: number;
  dailyProfitAndLoss: number;
  id: string;
  mtdPercentChange: number;
  mtdProfitAndLoss: number;
  name: string;
  pinned: boolean;
  price: number;
  ytdPercentChange: number;
  ytdProfitAndLoss: number;
};

export default function useMarketOverview({
  categoryName,
  watchlistAssets,
}: {
  categoryName: string;
  watchlistAssets: readonly MarketWatchlistAsset[];
}) {
  const { data: raw } = useQuery(GET_MARKET_OVERVIEW_QUERY_KEY(), {
    queryFn: useGetMarketOverviewQuery(),
  });

  const categories = useMemo(
    () => uniq(raw?.map((it) => it.name) ?? []),
    [raw],
  );

  const watchlistAssetNames = useMemo(
    () =>
      watchlistAssets
        .filter((asset) => asset.pinned)
        .map((asset) => asset.name),
    [watchlistAssets],
  );

  const groupedAssets = useMemo(() => {
    const allAssets =
      raw?.flatMap((category) =>
        category.assets.map((asset) => ({
          ...asset,
          categoryName: category.name,
          timePeriod: category.timePeriod,
        })),
      ) ?? [];

    const groupedByCategory = groupBy(allAssets, (asset) => asset.categoryName);

    return Object.entries(groupedByCategory).reduce(
      (acc, [currentCategoryName, assets]) => {
        const groupedByName = groupBy(assets, (asset) => asset.name);

        const normalizedAssets = Object.values(groupedByName).map(
          (partialAssets) => {
            const dailyChangeAsset = partialAssets.find(
              (asset) => asset.timePeriod === 'DayCh',
            );
            const mtdAsset = partialAssets.find(
              (asset) => asset.timePeriod === 'MTD',
            );
            const ytdAsset = partialAssets.find(
              (asset) => asset.timePeriod === 'YTD',
            );

            const name =
              dailyChangeAsset?.name ?? mtdAsset?.name ?? ytdAsset?.name ?? '';

            return {
              dailyPercentChange: dailyChangeAsset?.percentChange ?? 0,
              dailyProfitAndLoss: dailyChangeAsset?.profitAndLoss ?? 0,
              id: nanoid(),
              mtdPercentChange: mtdAsset?.percentChange ?? 0,
              mtdProfitAndLoss: mtdAsset?.profitAndLoss ?? 0,
              name,
              pinned: watchlistAssetNames.includes(name),
              price:
                dailyChangeAsset?.price ??
                mtdAsset?.price ??
                ytdAsset?.price ??
                0,
              ytdPercentChange: ytdAsset?.percentChange ?? 0,
              ytdProfitAndLoss: ytdAsset?.profitAndLoss ?? 0,
            };
          },
        );

        return {
          ...acc,
          [currentCategoryName]: normalizedAssets,
        };
      },
      {} as Record<string, NormalizedAsset[]>,
    );
  }, [raw, watchlistAssetNames]);

  const selectedData = groupedAssets[categoryName] ?? [];

  return {
    categories,
    data: selectedData,
  };
}
