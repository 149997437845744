export function getFullPortfolioNameReadable({
  clientName,
  portfolioName,
}: {
  clientName: string;
  portfolioName: string;
}) {
  if (portfolioName === 'Default') {
    return clientName;
  }

  return `${clientName} ${portfolioName}`;
}

export default function getFullPortfolioName({
  clientName,
  portfolioName,
}: {
  clientName: string;
  portfolioName: string;
}) {
  if (portfolioName === 'Default') {
    return clientName;
  }

  return `${clientName}-${portfolioName}`;
}
