import { parse, stringify } from 'query-string';
import { useCallback, useMemo } from 'react';

import type { NormalizedAsset } from 'api/hooks/markets/useMarketOverview';
import kebabCaseCustom from 'utils/kebabCaseCustom';
import reverseMap from 'utils/reverseMap';
import useEntitySorting, {
  SortOption,
} from 'utils/useDataManipulation/useEntitySorting';

import type { Datum } from '../types';

type AssetSortOption = SortOption<Datum | NormalizedAsset>;

export function useCategorySlugs({
  categories,
}: {
  categories: readonly string[];
}) {
  const slugsByCategory = useMemo(
    () =>
      categories.reduce(
        (acc, categoryName) => ({
          ...acc,
          [categoryName]: kebabCaseCustom(categoryName),
        }),
        {} as Record<string, string>,
      ),
    [categories],
  );

  const categoriesBySlug = useMemo(
    () => reverseMap(slugsByCategory),
    [slugsByCategory],
  );

  const getSlugByCategoryName = useCallback(
    (categoryName: string) => slugsByCategory[categoryName] ?? '',
    [slugsByCategory],
  );

  const getCategoryNameBySlug = useCallback(
    (slug: string) => categoriesBySlug[slug] ?? '',
    [categoriesBySlug],
  );

  return {
    getCategoryNameBySlug,
    getSlugByCategoryName,
  };
}

export function useLinks({
  categories,
  getSlugByCategoryName,
  search,
  url,
}: {
  categories: readonly string[];
  getSlugByCategoryName: (categoryName: string) => string;
  search: string;
  url: string;
}) {
  const activeLink = useMemo(() => {
    const { browseBy } = parse(search);
    return browseBy ? `${url}?${stringify({ browseBy })}` : url;
  }, [search, url]);

  const links = useMemo(
    () => [
      {
        name: 'Watchlist',
        value: url,
      },
      ...categories.map((category) => ({
        name: category,
        value: `${url}?${stringify({
          browseBy: getSlugByCategoryName(category),
        })}`,
      })),
    ],
    [categories, getSlugByCategoryName, url],
  );

  return { activeLink, links };
}

export function useSelectedCategory({
  getCategoryNameBySlug,
  search,
}: {
  getCategoryNameBySlug: (slug: string) => string;
  search: string;
}) {
  const { browseBy } = parse(search);

  return typeof browseBy === 'string' ? getCategoryNameBySlug(browseBy) : '';
}

// noinspection DuplicatedCode - This is abstract enough
export function useSorting() {
  const sortOptions: readonly [AssetSortOption, ...AssetSortOption[]] = useMemo(
    () => [
      {
        compare: (a, b) => a.name.localeCompare(b.name),
        label: 'Alphabetical: A to Z',
        value: 'default',
      },
      {
        compare: (a, b) => b.name.localeCompare(a.name),
        label: 'Alphabetical: Z to A',
        value: 'alphabeticalR',
      },
      {
        compare: (a, b) => b.dailyPercentChange - a.dailyPercentChange,
        label: 'Daily Change: High to Low',
        value: 'dailyChangeDESC',
      },
      {
        compare: (a, b) => a.dailyPercentChange - b.dailyPercentChange,
        label: 'Daily Change: Low to High',
        value: 'dailyChangeASC',
      },
      {
        compare: (a, b) => b.mtdPercentChange - a.mtdPercentChange,
        label: 'MTD: High to Low',
        value: 'mtdDESC',
      },
      {
        compare: (a, b) => a.mtdPercentChange - b.mtdPercentChange,
        label: 'MTD: Low to High',
        value: 'mtdASC',
      },
      {
        compare: (a, b) => b.ytdPercentChange - a.ytdPercentChange,
        label: 'YTD: High to Low',
        value: 'ytdDESC',
      },
      {
        compare: (a, b) => a.ytdPercentChange - b.ytdPercentChange,
        label: 'YTD: Low to High',
        value: 'ytdASC',
      },
    ],
    [],
  );

  return useEntitySorting({ sortOptions });
}
