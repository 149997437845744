import { useCallback } from 'react';
import type { QueryFunction } from 'react-query';

import useRequest from 'api/request';
import {
  ClientTransactionDatum,
  normalizeClientTransactionDatum,
} from 'model/ClientTransaction';
import getPortfolioId from 'utils/getPorfolioId';
import withoutNulls from 'utils/withoutNulls';

import type {
  GetClientTransactionsRequest,
  GetClientTransactionsResponse,
} from './types';

export const GET_CLIENT_TRANSACTIONS_QUERY_KEY = (
  portfolio: string,
  beginDate: string,
  endDate: string,
) => ['transactions/getClientTransactions', portfolio, beginDate, endDate];

export default function useGetClientTransactionsQuery(): QueryFunction<
  ClientTransactionDatum[]
> {
  const request = useRequest<
    GetClientTransactionsRequest,
    GetClientTransactionsResponse
  >();

  return useCallback(
    async ({ queryKey }) => {
      const [, portfolio, beginDate, endDate] = queryKey as [
        string,
        string,
        string,
        string,
      ];

      const response = await request(
        '/api/CVData/getClientTransactionsAndActivityByDate/{portfolio}/{begindate}/{enddate}',
        {
          urlParams: {
            portfolio: getPortfolioId(portfolio) ?? '',
            begindate: beginDate,
            enddate: endDate,
          },
        },
      );

      const data = await response.json();

      if (!data.isSuccess) {
        throw new Error(data.message ?? '');
      }

      return withoutNulls(data.data ?? [])
        .map(normalizeClientTransactionDatum)
        .sort((a, b) => b.date.getTime() - a.date.getTime());
    },
    [request],
  );
}
