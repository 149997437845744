import styled, { css } from 'styled-components';

import DefaultSpinner from 'components/Spinner';
import PageDetailLayout from 'containers/Common/PageDetailLayout';
import PageLayout from 'containers/Common/PageLayout';
import { MobileContent } from 'containers/Common/PageLayout/styles';
import ZIndex from 'styles/ZIndex';

export const LoaderContainer = styled.div`
  position: sticky;
  top: 50%;
  padding-left: 50%;
  z-index: 1000;
`;

export const Container = styled(PageLayout)<{
  $loading: boolean;
  $mobile: boolean;
}>`
  ${({ $loading }) =>
    $loading &&
    css`
      pointer-events: none;
      filter: blur(1px);
    `};

  ${({ $loading, $mobile }) =>
    $loading &&
    !$mobile &&
    css`
      margin-top: -4.5rem;
    `};

  ${MobileContent} {
    z-index: ${ZIndex.MOBILE_CONTENT};
  }
`;

export const FilteredContainer = styled(PageDetailLayout)<{
  $loading: boolean;
  $mobile: boolean;
}>`
  ${({ $loading }) =>
    $loading &&
    css`
      pointer-events: none;
      filter: blur(1px);
    `};

  ${({ $loading, $mobile }) =>
    $loading &&
    !$mobile &&
    css`
      margin-top: -4.5rem;
    `};

  ${MobileContent} {
    z-index: ${ZIndex.MOBILE_CONTENT};
  }
`;

export const Spinner = styled(DefaultSpinner)``;
