import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';

import useGetMarketWatchlistQuery, {
  GET_MARKET_WATCHLIST_QUERY_KEY,
} from 'api/queries/markets/getMarketWatchlist';
import { useQuery } from 'api/storage';

export default function useMarketWatchlist(invalidateOnLocation = true) {
  const { listen: listenToHistory } = useHistory();
  const client = useQueryClient();

  const { data, refetch } = useQuery(GET_MARKET_WATCHLIST_QUERY_KEY(), {
    queryFn: useGetMarketWatchlistQuery(),
  });

  /*
   * Invalidate on location change rather than on component mount, because on
   * Desktop switching from the watchlist to a category overview does not
   * re-mount this hook and therefore "refetchOnMount" is not useful.
   *
   * We want to invalidate queries because we keep unpinned assets in the state
   */
  useEffect(() => {
    if (invalidateOnLocation) {
      const unsubscribe = listenToHistory(() => {
        void client.invalidateQueries(GET_MARKET_WATCHLIST_QUERY_KEY());
      });
      return () => {
        unsubscribe();
      };
    }
    return undefined;
  }, [client, invalidateOnLocation, listenToHistory]);

  return {
    data,
    refetch,
  };
}
