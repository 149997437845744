import { scaleBand, scaleLinear } from '@visx/scale';
import { useTooltip as useVisxTooltip } from '@visx/tooltip';
import { useCallback, useMemo } from 'react';

import type { Datum, TooltipDatum } from './types';

export const getDate = (datum: Datum) => datum.date;

export function useHeight(): number {
  return 424;
}

export function useEdgeValues(data: readonly Datum[]) {
  const flattenedData = useMemo(
    () =>
      data.flatMap((datum) => [datum.allocation, datum.cumulativeAllocation]),
    [data],
  );

  const start = useMemo(
    () =>
      flattenedData.reduce(
        (lowestPercentage, value) => Math.min(lowestPercentage, value),
        Infinity,
      ),
    [flattenedData],
  );

  const end = useMemo(
    () =>
      flattenedData.reduce(
        (highestPercentage, value) => Math.max(highestPercentage, value),
        0,
      ),
    [flattenedData],
  );

  const maxValue = useMemo(() => Math.max(...flattenedData), [flattenedData]);
  const minValue = useMemo(() => Math.min(...flattenedData), [flattenedData]);

  return {
    end,
    maxValue,
    minValue,
    start,
  };
}

export function useMargins() {
  // This builds on top of the padding of the Container element. Instead of
  // putting all on the padding, we put 1rem here so the chart line, which has
  // a thick stroke, doesn't get cut. We also put the rest on the Container
  // element so it is easier for other developers to change the styles.
  const topMargin = 32;

  // This can't simply be padding on the Container element because it wouldn't
  // be noticed by the resize observer.
  const rightMargin = 32;

  const bottomAxisHeight = 36;
  const bottomAxisMargin = 16;

  const leftAxisWidth = 84;
  const leftAxisMargin = 16;

  return {
    bottomAxisHeight,
    bottomAxisMargin,
    leftAxisMargin,
    leftAxisWidth,
    rightMargin,
    topMargin,
  };
}

export function useScales(props: {
  bottomAxisHeight: number;
  bottomAxisMargin: number;
  data: Datum[];
  end: number;
  height: number;
  leftAxisMargin: number;
  leftAxisWidth: number;
  maxValue: number;
  minValue: number;
  rightMargin: number;
  start: number;
  topMargin: number;
  width: number;
}) {
  // Destructured here rather than on the "props" parameter itself so that the
  // parameter hint in IntelliJ based IDEs isn't gigantic.
  const {
    bottomAxisHeight,
    bottomAxisMargin,
    data,
    height,
    leftAxisMargin,
    leftAxisWidth,
    minValue,
    maxValue,
    rightMargin,
    topMargin,
    width,
  } = props;

  const xScale = useMemo(
    () =>
      scaleBand({
        domain: data.map(getDate),
        padding: 0.4,
        range: [leftAxisWidth + leftAxisMargin, width - rightMargin],
        paddingOuter: 0,
      }),
    [leftAxisMargin, leftAxisWidth, rightMargin, width, data],
  );

  const yScale = useMemo(
    () =>
      scaleLinear({
        domain: [minValue, maxValue],
        range: [height - bottomAxisHeight - bottomAxisMargin, topMargin],
        nice: true,
      }),
    [bottomAxisHeight, bottomAxisMargin, height, maxValue, minValue, topMargin],
  );

  const cumulativeXScale = useMemo(
    () =>
      scaleBand({
        domain: data.map(getDate),
        range: [leftAxisWidth + leftAxisMargin, width - rightMargin],
        paddingInner: 1,
        paddingOuter: 0.35,
      }),
    [leftAxisMargin, leftAxisWidth, rightMargin, width, data],
  );

  return { xScale, yScale, cumulativeXScale };
}

export function useTooltip({
  xScale,
  yScale,
  height,
  width,
  leftAxisMargin,
  leftAxisWidth,
}: {
  xScale: ReturnType<typeof useScales>['xScale'];
  yScale: ReturnType<typeof useScales>['yScale'];
  height: number;
  width: number;
  leftAxisMargin: number;
  leftAxisWidth: number;
}) {
  const { hideTooltip, showTooltip, tooltipData, tooltipLeft, tooltipTop } =
    useVisxTooltip<TooltipDatum>();

  const handleTooltipUpdated = useCallback(
    (params: { datum: Datum; isCumulative?: boolean }) => {
      const { datum, isCumulative = false } = params;
      const x = xScale(datum.date) ?? 0;
      const bandwidth = xScale.bandwidth();
      const xAlignedRight = width;
      const chartEndThreshold = 50;
      let y: number;
      let left: number;
      let offsetTop: number;
      if (isCumulative) {
        // cumulative line chart point tooltip
        const minTooltipWidth = 140;
        y = yScale(datum.cumulativeAllocation) ?? 0;
        const isYHigherThanChart = y < height;
        offsetTop = isYHigherThanChart ? -100 : 0;
        const xAlignedCenter =
          x + bandwidth / 2 - leftAxisMargin - leftAxisWidth;
        const isNearTheEnd =
          xAlignedCenter + minTooltipWidth + chartEndThreshold > width;
        left = isNearTheEnd ? xAlignedRight : xAlignedCenter;
      } else {
        // bar chart tooltip
        const minTooltipWidth = 200;
        y = yScale(datum.allocation) ?? 0;
        offsetTop = 0;
        const xAlignedCenter = x - bandwidth - minTooltipWidth / 2;
        const isNearTheEnd =
          xAlignedCenter + minTooltipWidth + chartEndThreshold > width;
        left = isNearTheEnd ? xAlignedRight : xAlignedCenter;
      }
      const top = Math.max(y - offsetTop, height);
      showTooltip({
        tooltipData: { ...datum, isCumulative },
        tooltipTop: top,
        tooltipLeft: left,
      });
    },
    [xScale, yScale, height, width, leftAxisMargin, leftAxisWidth, showTooltip],
  );

  return {
    handleTooltipUpdated,
    handleTooltipClosed: hideTooltip,
    tooltipData,
    tooltipLeft,
    tooltipTop,
  };
}
