import type { FC } from 'react';

import { Header, HeaderCell, Row, Table } from './styles';
import type { Props } from './types';

const BaseAssetDetailTable: FC<Props> = ({ headers, rows, lastRow }) => (
  <Table>
    <Header>
      {headers.map((header) => (
        <HeaderCell key={header}>{header}</HeaderCell>
      ))}
    </Header>
    {rows.map((row) => (
      <Row key={row.id}>
        {row.firstCol}
        {row.secondCol}
        {row.thirdCol}
      </Row>
    ))}
    {lastRow || null}
  </Table>
);

export default BaseAssetDetailTable;
