import groupBy from 'lodash/groupBy';
import uniq from 'lodash/uniq';
import { useMemo, useState } from 'react';
import type { MouseEvent, TouchEvent } from 'react';

import type { Datum } from 'components/AssetAllocationTargetAndRangesChart/types';
import type { AssetAllocationTargetAndRangesDatum } from 'model/AssetAllocationTargetAndRangesDatum';
import kebabCaseCustom from 'utils/kebabCaseCustom';
import type { FilterOption } from 'utils/useDataManipulation/useEntityFiltering';
import useEntityFiltering from 'utils/useDataManipulation/useEntityFiltering';

type AssetFilterOption = FilterOption<Datum>;

function getRollingAllocationTargetsDatum(
  data: AssetAllocationTargetAndRangesDatum[],
  instrumentType: string,
) {
  return data
    .filter((d) => kebabCaseCustom(d.instrumentType) === instrumentType)
    .map((d) => ({
      instrumentType: d.instrumentType,
      date: d.date,
      current: d.current,
      target: d.target,
      range: { lowerBound: d.range.lowerBound, upperBound: d.range.upperBound },
    }));
}

export function getLastMonth(data: AssetAllocationTargetAndRangesDatum[]) {
  const groupedByMonthAndYear = groupBy(data, (item) => item.date);

  const lastMonth = Object.keys(groupedByMonthAndYear).sort(
    (a, b) => +b - +a,
  )[0];

  const dataLastMonth =
    lastMonth !== undefined
      ? (groupedByMonthAndYear[
          lastMonth
        ] as AssetAllocationTargetAndRangesDatum[])
      : [];

  return dataLastMonth;
}

export function useRollingAllocationTargets(
  data: AssetAllocationTargetAndRangesDatum[] | undefined,
) {
  const [rollingAllocationTargets, setRollingAllocationTargets] = useState<
    | {
        instrumentType: string;
        date: number;
        current: number;
        target: number;
        range: { lowerBound: number; upperBound: number };
      }[]
    | undefined
  >();

  function handleInstrumentTypeChange(
    event: MouseEvent<SVGRectElement> | TouchEvent<SVGRectElement>,
  ) {
    if (event.target instanceof SVGRectElement && data !== undefined) {
      const datumId = event.target.id;
      const datumBar = data.find((d) => `bar-${d.id}` === datumId);
      if (datumBar !== undefined) {
        setRollingAllocationTargets(
          getRollingAllocationTargetsDatum(
            data,
            kebabCaseCustom(datumBar?.instrumentType),
          ),
        );
      }
    }

    if (rollingAllocationTargets) {
      setRollingAllocationTargets(undefined);
    }
  }

  function handleBackToTargetAndRanges() {
    setRollingAllocationTargets(undefined);
  }

  function handleInstrumentTypeFilter(instrumentType: string | undefined) {
    if (instrumentType !== undefined && data !== undefined) {
      setRollingAllocationTargets(
        getRollingAllocationTargetsDatum(data, instrumentType),
      );
    } else {
      setRollingAllocationTargets(undefined);
    }
  }

  return {
    rollingAllocationTargets,
    handleInstrumentTypeChange,
    handleBackToTargetAndRanges,
    handleInstrumentTypeFilter,
  };
}

export function useFiltering(
  data: AssetAllocationTargetAndRangesDatum[] | undefined,
) {
  const instrumentTypes = useMemo(
    () =>
      uniq(
        data?.map((d) => d.instrumentType).sort((a, b) => a.localeCompare(b)),
      ),
    [data],
  );

  const filterOptions: readonly [AssetFilterOption, ...AssetFilterOption[]] =
    useMemo(
      () => [
        {
          filter: () => true,
          label: 'Targets & Ranges',
          value: 'default',
        },
        ...instrumentTypes.map(
          (instrumentType): AssetFilterOption => ({
            label: instrumentType,
            value: instrumentType,
          }),
        ),
      ],
      [instrumentTypes],
    );

  return useEntityFiltering({
    filterKey: 'rollingAllocationTargets',
    filterOptions,
  });
}
