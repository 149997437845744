import { IonItem, IonLabel } from '@ionic/react';
import styled from 'styled-components';

export const DefaultOptionItem = styled(IonItem)`
  --padding-start: 1.375rem;
  --padding-end: 0.25rem;
  --min-height: 4rem;
  --background: ${({ theme }) => theme.colors.white5};
  --border-color: ${({ theme }) => theme.colors.white5};
  --background-hover: ${({ theme }) => theme.colors.white5};
  --color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
`;

export const Label = styled(IonLabel)`
  margin: 0;
  font-weight: ${({ theme }) => theme.weights.regular};
`;

export const LabelRadio = styled(IonLabel)`
  margin-left: 16px;

  font-size: 16px;
  line-height: 19px;
  font-weight: ${({ theme }) => theme.weights.regular};
`;
