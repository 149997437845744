import type { FC } from 'react';

import MarketSummaryChart from 'components/MarketSummaryChart';

import { useScales, useValues } from '../logic';
import type { Props } from '../types';
import useConnect from './connect';
import Controls from './Controls';
import Legend from './Legend';
import { ChartContainer, ChartInfo, Container, Header, Title } from './styles';

const MarketSummaryMobile: FC<Props> = ({ marketSummaryData }) => {
  const { controls, handle } = useConnect();
  const { values } = useValues(marketSummaryData, controls.period);
  const { valueScale } = useScales(values);

  if (!marketSummaryData.length) {
    return <></>;
  }

  let keys = marketSummaryData.map((dt) => dt.instrumentType);
  keys = keys.filter((v, i) => !keys.includes(v, i + 1));

  return (
    <Container>
      <Header>
        <Title>Market Summary</Title>
      </Header>
      <Controls
        onChange={handle.changeControls}
        selectedData={controls.data}
        selectedPeriod={controls.period}
      />
      <ChartContainer>
        <ChartInfo>
          <MarketSummaryChart
            data={[...marketSummaryData].reverse()}
            height={318}
            valueScale={valueScale}
            period={controls.period}
            isMobile
          />
        </ChartInfo>
        <Legend keys={keys} data={marketSummaryData} />
      </ChartContainer>
    </Container>
  );
};

export default MarketSummaryMobile;
