import styled, { css } from 'styled-components';

import Select from 'components/Select';
import DefaultSpinner from 'components/Spinner';
import DefaultTabs from 'components/Tabs';
import { TabButton } from 'components/Tabs/styles';
import PageLayout from 'containers/Common/PageLayout';
import from from 'styles/responsive';

export const LoaderContainer = styled.div`
  position: sticky;
  top: 50%;
  padding-left: 50%;
  z-index: 1000;
`;

export const Container = styled(PageLayout)<{
  $loading: boolean;
  $mobile: boolean;
}>`
  ${({ $loading }) =>
    $loading &&
    css`
      pointer-events: none;
      filter: blur(1px);
    `};

  ${({ $loading, $mobile }) =>
    $loading &&
    !$mobile &&
    css`
      margin-top: -4.5rem;
    `};
`;

export const ContainerTabs = styled.div``;

export const ContentGrid = styled.div`
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-row-gap: 35px;
  padding: 36px 20px;

  ${from.tablet`
    grid-row-gap: 40px;
    padding: 0;
  `}
  ${from.laptop`
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 32px;
  `}
`;

export const Tabs = styled(DefaultTabs)`
  margin-left: 2rem;

  ${from.tablet`
    width: fit-content;
  `}

  ${TabButton} {
    padding: 0 1rem;
    max-width: none;
    max-height: none;
    min-width: 0;
    --padding-start: 0.625rem;
    --padding-end: 0.625rem;
  }
`;

export const AssetAllocationTargetAndRangesContainer = styled.div``;

export const ChartTitle = styled.h2`
  margin: 0 0 0.5rem 0;
  font-size: 1.125rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.weights.regular};
  min-width: 30%;
  flex: 1;
  span {
    display: block;
    font-size: 0.875rem;
    line-height: 1.4;
    color: ${({ theme }) => theme.colors.white75};
  }
  ${from.laptop`
    margin: 0;
    span {
      display: inline;
      margin: 0 0 2rem 0.25rem;
      font-size: 0.75rem;
      line-height: 1.15;
    }
  `}
`;

export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  ${from.tablet`
    margin-bottom: 1.5rem;
    flex-wrap: nowrap;
  `}
`;

export const ActionZone = styled.div`
  display: flex;
`;

export const FilterButton = styled(Select)`
  width: fit-content;
  margin-left: 0.75rem;
`;

export const Spinner = styled(DefaultSpinner)``;
