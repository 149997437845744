import { IonContent } from '@ionic/react';
import { nanoid } from 'nanoid/non-secure';
import { forwardRef, useImperativeHandle, useMemo } from 'react';

import { useController } from './logic';
import { IonMenu } from './styles';
import type { MenuController, Props } from './types';

const Menu = forwardRef<MenuController, Props>(
  ({ children, contentId, onAfterClose }, ref) => {
    const menuId = useMemo(nanoid, []);

    const controller = useController(menuId);
    useImperativeHandle(ref, () => controller, [controller]);

    return (
      <IonMenu
        contentId={contentId}
        onIonDidClose={onAfterClose}
        side="start"
        menuId={menuId}
      >
        <IonContent forceOverscroll={false}>{children}</IonContent>
      </IonMenu>
    );
  },
);

export default Menu;

export type { MenuController };
