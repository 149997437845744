import type { FC } from 'react';

import { ColorCol, ColTitle, ColValue, Container, Row } from './styles';
import type { Props } from './types';

const BaseAssetDetailTable: FC<Props> = ({ className, rows }) => (
  <Container className={className}>
    {rows.map((row) => (
      <Row key={row.id}>
        <ColTitle>{row.key}</ColTitle>
        {row.isColored ? (
          <ColorCol $isNegative={row.isNegative}>{row.value}</ColorCol>
        ) : (
          <ColValue>
            {row.value} {row.subValue && <p>{row.subValue}</p>}
          </ColValue>
        )}
      </Row>
    ))}
  </Container>
);

export default BaseAssetDetailTable;
