import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import useSetUserDefaultPortfolio from 'api/mutations/clients/portfolio';
import { GET_USER_DEFAULT_PORTFOLIO_QUERY_KEY } from 'api/queries/clients/portfolio';

export default function useSetDefaultPortfolio() {
  const client = useQueryClient();
  const { mutateAsync } = useMutation(useSetUserDefaultPortfolio(), {
    onSuccess: () => {
      void client.invalidateQueries([GET_USER_DEFAULT_PORTFOLIO_QUERY_KEY]);
    },
  });

  return useCallback(
    async ({ portfolioName }: { portfolioName: string }) =>
      mutateAsync({ portfolioName }),
    [mutateAsync],
  );
}
