import styled, { css } from 'styled-components';

import type { ContainerProps } from './types';

export const tooltipStyles = css`
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.colors.white};
  padding: 0.5rem 0.75rem;
`;

export const Container = styled.div<{
  $top: ContainerProps['$top'];
  $left: ContainerProps['$left'];
}>`
  ${tooltipStyles};

  position: absolute;
  max-width: 11rem;
  ${({ $top }) =>
    $top &&
    css`
      top: ${$top}px;
    `}
  ${({ $left }) =>
    $left &&
    css`
      left: ${$left}px;
    `}
`;
