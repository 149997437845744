import styled, { css } from 'styled-components';

import Button from '../../Button';

export const Container = styled.li`
  list-style-type: none;
`;

export const Link = styled(Button).attrs({ variant: 'link' })``;

// This container is necessary because Ionic is a piece of shit and won't let
// us style the link.
export const Content = styled.div`
  align-items: center;
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.white10};
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 0;
`;

export const Text = styled.p<{ $isSelected: boolean }>`
  font-size: 1rem;
  line-height: 1.1875rem;
  margin: 0;

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      font-weight: ${({ theme }) => theme.weights.bold};
    `};
`;

export const Default = styled.span`
  color: ${({ theme }) => theme.colors.white50};
  font-size: 0.75rem;
  line-height: 0.9rem;
`;
