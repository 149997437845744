import { useCallback, useEffect, useState } from 'react';

import useIncomeProfileR12 from 'api/hooks/allocations/useIncomeProfileR12';
import useSelectedClient from 'api/hooks/clients/useSelectedClient';
import type { IncomeProfileMonth } from 'model/IncomeProfileMonth';

import {
  applyAccountFilter,
  applyEntityFilter,
  getAccountsOptions,
  getEntitiesOptions,
  useDateSelection,
} from './logic';

export default function useConnect() {
  const [filterEntity, setFilterEntity] = useState<string[] | undefined>(
    undefined,
  );
  const [filterAccount, setFilterAccount] = useState<string[] | undefined>(
    undefined,
  );

  const [incomeProfileR12DataFiltered, setIncomeProfileR12DataFiltered] =
    useState<IncomeProfileMonth[]>([]);

  const { selectedPortfolio } = useSelectedClient();

  const { data: incomeProfileR12 = [] } = useIncomeProfileR12(
    selectedPortfolio?.id,
  );

  useEffect(() => {
    let dataFiltered = incomeProfileR12;

    dataFiltered = applyEntityFilter(dataFiltered, filterEntity);
    dataFiltered = applyAccountFilter(dataFiltered, filterAccount);

    setIncomeProfileR12DataFiltered([...dataFiltered]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterEntity, filterAccount]);

  const {
    dateOptions,
    incomeProfileR12Detail,
    incomeProfileR12DetailAfterTax,
    selectedDate,
    setSelectedDate,
  } = useDateSelection({
    incomeProfileR12DataFiltered,
  });

  const onChangeFilteringEntity = useCallback(
    (newValue: string[] | undefined) => {
      if (newValue?.length === 0 || newValue === undefined) {
        setFilterEntity(undefined);
      } else {
        setFilterEntity(newValue);
      }
    },
    [setFilterEntity],
  );

  const onChangeFilteringAccount = useCallback(
    (newValue: string[] | undefined) => {
      if (newValue?.length === 0 || newValue === undefined) {
        setFilterAccount(undefined);
      } else {
        setFilterAccount(newValue);
      }
    },
    [setFilterAccount],
  );

  return {
    dateOptions,
    filterEntity,
    filterAccount,
    entitiesOptions: getEntitiesOptions(incomeProfileR12),
    accountsOptions: getAccountsOptions(incomeProfileR12),
    handle: {
      changeSelectedDate: setSelectedDate,
      onChangeFilteringEntity,
      onChangeFilteringAccount,
    },
    incomeProfileR12: incomeProfileR12DataFiltered,
    incomeProfileR12Detail,
    incomeProfileR12DetailAfterTax,
    selectedDate,
  };
}
