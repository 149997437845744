import useWinnersLosersQuery, {
  GET_WINNERS_LOSERS_QUERY_KEY,
} from 'api/queries/home/getWinnersLosers';
import { useQuery } from 'api/storage';

export default function useWinnersLosers({
  clientName,
  enabled = true,
  portfolioName,
  portfolio,
}: {
  clientName: string | undefined;
  enabled?: boolean;
  portfolioName: string | undefined;
  portfolio: string | undefined;
}) {
  const { data } = useQuery(
    GET_WINNERS_LOSERS_QUERY_KEY({
      clientName: clientName ?? '',
      portfolioName: portfolioName ?? '',
      portfolio: portfolio ?? '',
    }),
    {
      enabled: enabled && !!clientName && !!portfolioName && !!portfolio,
      queryFn: useWinnersLosersQuery(),
    },
  );

  return {
    data,
  };
}
