export default function getClientJoinPortfolio(
  client: string,
  portfolio: string,
  separator = '-',
) {
  // Label case with Default Portfolio, only show client name
  if (separator === ' ' && portfolio.toLowerCase() === 'default') {
    return client;
  }

  // Normal case
  return [client, portfolio].join(separator);
}
