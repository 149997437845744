import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import styled, { css } from 'styled-components';

import blueCaretDown from 'assets/icons/blue-caret-down.svg';
import blueTick from 'assets/icons/blue-tick.svg';
import whiteCaretDown from 'assets/icons/white-caret-down.svg';
import from, { isNotTouchable } from 'styles/responsive';

import type { OptionProps } from './types';

export const AlertStyles = css`
  ion-select {
    ${from.tablet`
      max-width: 250px;
    `}
    ${from.laptop`
      max-width: 400px;
    `}
    ${from.desktop`
      max-width: 750px;
    `}
  }

  .select-action-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${({ theme }) => theme.colors.white75};
    font-size: 14px;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  .select-action-button {
    &:hover {
      cursor: pointer;
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  ion-select-option.disable-events {
    pointer-events: none;
  }

  ion-ripple-effect {
    pointer-events: none;
  }

  .alert-wrapper.sc-ion-alert-md {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    border-radius: 0.5rem;
  }

  .alert-message.sc-ion-alert-md {
    padding-top: 0;
    padding-bottom: 0;
  }

  .alert-head.sc-ion-alert-md {
    display: none;
  }

  .alert-checkbox-group.sc-ion-alert-md {
    overflow: auto;
    max-height: 200px;
    border-top: 0.5px solid ${({ theme }) => theme.colors.white10};
    border-bottom: 0.5px solid ${({ theme }) => theme.colors.white10};
    &::-webkit-scrollbar {
      display: block;
      width: 0.375rem;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.primary75};
      border-radius: 4px;
    }
  }

  .alert-checkbox-label.sc-ion-alert-md {
    font-size: 14px;
  }

  .alert-checkbox-icon.sc-ion-alert-md {
    width: 14px;
    height: 14px;
  }

  .alert-tappable.sc-ion-alert-md {
    height: 40px;
  }
`;

export const PopoverStyles = css`
  .popover-content {
    --max-height: 16.25rem;
    border-radius: 0.5rem !important;
    padding: 0rem 0rem 0.5rem 1rem;
    overflow: auto;
    box-sizing: border-box;
    margin-top: 0.35rem;
    &::-webkit-scrollbar {
      width: 0.375rem;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.primary75};
      border-radius: 4px;
    }
  }
  .popover-viewport {
    display: contents;
  }
`;

export const Container = styled(IonItem)<{
  $active: boolean;
  $textSmall: boolean;
}>`
  ${({ $textSmall }) =>
    $textSmall &&
    css`
      ion-select {
        ${from.tablet`
          max-width: 150px;
        `}
        ${from.laptop`
          max-width: 200px;
        `}
        ${from.desktop`
          max-width: 600px;
        `}
      }
    `}

  --min-height: 2.5625rem;
  --background: ${({ theme }) => theme.colors.primary15};
  border: 1px solid ${({ theme }) => theme.colors.primary15};
  border-radius: 0.75rem;
  transition: all 200ms ease;
  --border-color: transparent;
  --padding-start: 0;
  --inner-padding-end: 0.75rem;
  --placeholder-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primary};
  --color: ${({ theme }) => theme.colors.primary};
  font-size: 0.875rem;
  ${isNotTouchable} {
    :hover {
      --background: ${({ theme }) => theme.colors.primary25};
      border: 1px solid ${({ theme }) => theme.colors.primary};
    }
  }

  ${({ $active, theme }) =>
    $active &&
    css`
      --background: ${theme.colors.primary};
      border: 1px solid ${theme.colors.primary};
      --placeholder-color: ${theme.colors.white};
      color: ${theme.colors.white};
      --color: ${theme.colors.white};
      ${isNotTouchable} {
        :hover {
          --background: ${theme.colors.primary75};
          border: 1px solid ${theme.colors.primary75};
        }
      }
    `}
`;

export const Select = styled(IonSelect)<{ $active: boolean }>`
  --padding-start: 0.75rem;
  width: 100%;
  ::part(icon) {
    position: relative;
    color: transparent;
    opacity: 1;
    :after {
      content: '';
      width: 0.5rem;
      height: 0.3125rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      background-image: url(${blueCaretDown});
      background-repeat: no-repeat;
    }
  }
  ::part(placeholder) {
    --color: ${({ theme }) => theme.colors.primary};
    opacity: 1;
  }

  ${({ $active }) =>
    $active &&
    css`
      ::part(icon) {
        :after {
          background-image: url(${whiteCaretDown});
        }
      }
    `}
`;

export const SelectMultiple = styled(Select)``;

export const Placeholder = styled(IonLabel)``;

export const Option = styled(IonSelectOption)<{
  $isMobile: OptionProps['$isMobile'];
}>`
  --padding-start: 0;
  transition: all 200ms ease;
  position: relative;
  --border-color: ${({ theme }) => theme.colors.white10};
  span {
    border-bottom: 0.5px solid ${({ theme }) => theme.colors.white10};
  }
  &.action-sheet-button {
    --button-color: ${({ theme }) => theme.colors.white75};
    --button-color-hover: ${({ theme }) => theme.colors.white};
    --background: ${({ theme }) => theme.colors.dark2};
    --min-height: 2.625rem;
    --inner-border-width: 0;
    &.action-sheet-selected {
      position: relative;
      ${isNotTouchable} {
        :hover {
          :after {
            background-image: url(${blueTick});
            background-color: transparent;
            opacity: 1;
          }
        }
      }
      :after {
        content: '';
        width: 0.875rem;
        height: 0.625rem;
        top: 50%;
        left: 94%;
        transform: translate(-50%, -50%);
        position: absolute;
        background-image: url(${blueTick});
        background-repeat: no-repeat;
        opacity: 1;
        background-color: transparent;
      }
    }
  }
  ${({ $isMobile }) =>
    !$isMobile &&
    css`
      font-size: 0.875rem;
      --color: ${({ theme }) => theme.colors.white75};
      ${isNotTouchable} {
        :hover {
          --color: ${({ theme }) => theme.colors.white};
        }
      }
      &.select-interface-option :last-child {
        opacity: 0;
      }
      &.item-radio-checked {
        --color: ${({ theme }) => theme.colors.white};
        font-weight: ${({ theme }) => theme.weights.bold};
      }
      &.item-radio-checked :after {
        content: '';
        width: 0.875rem;
        height: 0.625rem;
        top: 50%;
        left: 90%;
        transform: translate(-50%, -50%);
        position: absolute;
        background-image: url(${blueTick});
        background-repeat: no-repeat;
        opacity: 1;
        background-color: transparent;
      }
    `}
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      --button-background: ${({ theme }) => theme.colors.dark2};
    `}
`;

export const OptionMultiple = styled(IonSelectOption)<{
  $isMobile: OptionProps['$isMobile'];
}>``;
