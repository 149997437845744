import { FC, useEffect } from 'react';

import { usePortfolioSwitcher } from 'containers/Services/PortfolioSwitcherContext';

import CoverageTeam from './CoverageTeam';
import Portfolio from './Portfolio';
import { Container, Portfolios, Title, TitleContainer } from './styles';
import type { Props } from './types';

const ProfileSwitcherAccount: FC<Props> = ({
  name,
  portfolios,
  onChangeSelectedPortfolio,
  selectedPortfolioId,
}) => {
  const portfolioSwitcherContext = usePortfolioSwitcher();

  function adjustFontSize(
    textElement: HTMLElement,
    parentContainer: HTMLElement,
  ) {
    let fontSize = parseFloat(window.getComputedStyle(textElement).fontSize);

    while (
      textElement.scrollWidth > parentContainer.clientWidth &&
      fontSize > 1
    ) {
      fontSize -= 1;
      // eslint-disable-next-line no-param-reassign
      textElement.style.fontSize = `${+fontSize}px`;
    }
  }

  useEffect(() => {
    const textElement = document.getElementById(
      'profile-switcher-account-title',
    );
    const parentContainer = document.getElementById(
      'profile-switcher-account-container',
    );

    if (textElement && parentContainer) {
      textElement.style.fontSize = '2rem';
      adjustFontSize(textElement, parentContainer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPortfolioId, portfolioSwitcherContext.isOpen]);

  return (
    <>
      <Container>
        <TitleContainer id="profile-switcher-account-container">
          <Title id="profile-switcher-account-title">{name}</Title>
        </TitleContainer>
        <Portfolios>
          {portfolios.map((portfolio) => (
            <Portfolio
              id={portfolio.id}
              isDefault={portfolio.isDefault}
              key={portfolio.id}
              name={portfolio.name}
              onChangeSelectedPortfolio={onChangeSelectedPortfolio}
              selectedPortfolioId={selectedPortfolioId}
            />
          ))}
        </Portfolios>
      </Container>
      <CoverageTeam />
    </>
  );
};

export default ProfileSwitcherAccount;
