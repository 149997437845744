import { getYear } from 'date-fns';
import type { FC } from 'react';
import { memo } from 'react';

import { formatPercentage } from 'utils/percentages';

import { Date, Label, TooltipWithBounds, Value } from './styles';
import type { Props } from './types';

const Tooltip: FC<Props> = ({ data, tooltipLeft, tooltipTop }) => {
  if (data.isCumulative) {
    return (
      <TooltipWithBounds top={tooltipTop} left={tooltipLeft} $isCumulative>
        <Date>{getYear(data.date)}</Date>
        <Label>Cumulative</Label>
        <Value>{formatPercentage(data.cumulativeAllocation)}</Value>
      </TooltipWithBounds>
    );
  }

  return (
    <TooltipWithBounds top={tooltipTop} left={tooltipLeft}>
      <Date>{getYear(data.date)}</Date>
      <Label>Allocation</Label>
      <Value>{formatPercentage(data.allocation)}</Value>
    </TooltipWithBounds>
  );
};

export default memo(Tooltip);
