import { Formik } from 'formik';
import { FC, memo } from 'react';

import FormikIonInput from 'components/FormikIonInput';

import {
  BackButton,
  Chevron,
  Container,
  Content,
  Form,
  Inputs,
  Paragraph,
  SubmitButton,
  Title,
} from './styles';
import type { PasswordResetRequestFormModel, Props } from './types';

const initialValues: PasswordResetRequestFormModel = {
  email: '',
};

const PasswordResetRequestForm: FC<Props> = ({ loading = false, onSubmit }) => (
  <Container>
    <Content>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        <Form>
          <BackButton href="/" withIcon={<Chevron />} />
          <Title>Reset Password</Title>
          <Paragraph>
            Enter your email and we&apos;ll send you instructions on how to
            reset your password.
          </Paragraph>
          <Inputs>
            <FormikIonInput
              placeholder="Email"
              name="email"
              inputMode="email"
            />
          </Inputs>
          <SubmitButton disabled={loading} type="submit">
            Submit
          </SubmitButton>
        </Form>
      </Formik>
    </Content>
  </Container>
);

export default memo(PasswordResetRequestForm);

export type { PasswordResetRequestFormModel };
