import {
  AxisBottom as DefaultAxisBottom,
  AxisLeft as DefaultAxisLeft,
} from '@visx/axis';
import { LinearGradient } from '@visx/gradient';
import { GridRows } from '@visx/grid';
import type { TextProps } from '@visx/text/lib/Text';
import { nanoid } from 'nanoid/non-secure';
import styled from 'styled-components';

import type { Theme } from 'styles/themes';

export const GraphVector = styled.svg``;

export const areaGradientId = `area_gradient_${nanoid()}`;

export const Container = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.dark1};
  padding: 1rem 0 2rem;
  border-radius: 1rem;
`;

export const AreaGradient = styled(LinearGradient).attrs(({ theme }) => ({
  from: `${theme.colors.primary}29`,
  fromOffset: '87.75%',
  id: areaGradientId,
  to: `${theme.colors.primary}00`,
  vertical: false,
}))``;

export const Grid = styled(GridRows).attrs({ stroke: 'currentColor' })`
  color: ${({ theme }) => theme.colors.white}1a;
`;

const commonAxisProps = ({ theme }: { theme: Theme }) => ({
  hideTicks: true,
  stroke: 'none',
  tickLabelProps: (): Partial<TextProps> => ({
    fill: theme.colors.white50,
    fontSize: '12px',
    textAnchor: 'middle',
  }),
});

export const AxisLeft = styled(DefaultAxisLeft).attrs(({ theme }) => ({
  ...commonAxisProps({ theme }),
  tickLabelProps: (): Partial<TextProps> => ({
    ...commonAxisProps({ theme }).tickLabelProps(),
    textAnchor: 'end',
    verticalAnchor: 'middle',
    x: 0,
  }),
}))``;

export const AxisBottom = styled(DefaultAxisBottom).attrs(({ theme }) => ({
  ...commonAxisProps({ theme }),
  tickLabelProps: (): Partial<TextProps> => ({
    ...commonAxisProps({ theme }).tickLabelProps(),
    y: 8,
    textAnchor: 'middle',
    verticalAnchor: 'middle',
  }),
}))``;

export const LegendText = styled.text.attrs({ fill: 'currentColor' })<{
  $x: number;
  $y: number;
}>`
  color: ${({ theme }) => theme.colors.white50};
  font-size: 0.75rem;
  transform: translateX(${({ $x }) => $x}px) translateY(${({ $y }) => $y}px)
    rotate(-90deg);
`;

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
`;

export const LegendColor = styled.div`
  width: 0.625rem;
  height: 0.625rem;
  margin-right: 0.375rem;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 0.125rem;
`;

export const LegendLabel = styled.p`
  color: ${({ theme }) => theme.colors.white50};
  font-size: 0.75rem;
  line-height: 0.9038rem;
`;
