import { IonRadio } from '@ionic/react';
import styled from 'styled-components';

export const Radio = styled(IonRadio)`
  --border-width: 0.0938rem;
  --color: ${({ theme }) => theme.colors.white};
  --color-checked: ${({ theme }) => theme.colors.primary};
  transition: all 200ms ease;

  &.radio-checked {
    position: relative;
    --border-width: 0.3438rem;
    :after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 0.625rem;
      height: 0.625rem;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.white};
      cursor: pointer;
    }
    &.radio-disabled {
      --border-width: 0.0938rem;
      --color-checked: ${({ theme }) => theme.colors.white};
    }
  }

  &.radio-disabled {
    opacity: 0.1;
  }
`;
