import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Spin = styled.div`
  margin: 1rem 0;
  border: 0.375rem solid ${({ theme }) => theme.colors.white10};
  border-radius: 50%;
  border-top: 0.375rem solid ${({ theme }) => theme.colors.primary};
  width: 2.5rem;
  height: 2.5rem;
  animation: ${spin} 2s linear infinite;
`;
