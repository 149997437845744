import { useCallback } from 'react';
import type { QueryFunction } from 'react-query';

import useRequest from 'api/request';
import {
  normalizePortfolioHistoryDatum,
  PortfolioHistoryDatum,
} from 'model/PortfolioHistoryDatum';
import getPortfolioId from 'utils/getPorfolioId';
import withoutNulls from 'utils/withoutNulls';

import type {
  GetPortfolioHistoryChartDataRequest,
  GetPortfolioHistoryChartDataResponse,
} from './types';

export const GET_PORTFOLIO_HISTORY_CHART_DATA_QUERY_KEY = (
  portfolio: string,
) => ['charts/getPortfolioHistoryChartData', portfolio];

export default function useGetPortfolioHistoryChartDataQuery(): QueryFunction<
  Record<string, PortfolioHistoryDatum[]>
> {
  const request = useRequest<
    GetPortfolioHistoryChartDataRequest,
    GetPortfolioHistoryChartDataResponse
  >();

  return useCallback(
    async ({ queryKey }) => {
      const [, portfolio] = queryKey as [string, string];

      const response = await request(
        '/api/CVData/getClientMonthlyPerformanceComplete/{portfolio}',
        {
          urlParams: {
            portfolio: getPortfolioId(portfolio) ?? '',
          },
        },
      );

      const data = await response.json();

      if (!data.isSuccess) {
        throw new Error(data.message ?? '');
      }

      const raw = withoutNulls(data.data ?? []);

      return (
        raw[0]?.subClientMonthlyPerformance?.reduce(
          (
            acc,
            { clientName: portfolioName = '', monthlyPerformanceReport = [] },
          ) => ({
            ...acc,
            [portfolioName]: monthlyPerformanceReport.map(
              normalizePortfolioHistoryDatum,
            ),
          }),
          {} as Record<string, PortfolioHistoryDatum[]>,
        ) ?? {}
      );
    },
    [request],
  );
}
