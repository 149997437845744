import useIncomeProfile from 'api/hooks/allocations/useIncomeProfile';
import useSelectedClient from 'api/hooks/clients/useSelectedClient';

export default function useConnect() {
  const { selectedPortfolio } = useSelectedClient();

  const { data: incomeProfile = [] } = useIncomeProfile(selectedPortfolio?.id);

  return {
    incomeProfile,
  };
}
