import type { FC } from 'react';

import { Container } from './styles';
import type { Props } from './types';

const BreakpointDebugger: FC<Props> = () => <Container />;

const EMPTY_COMPONENT: FC = () => null;

export const breakpointDebuggerEnabled =
  !!process.env.REACT_APP_BREAKPOINT_DEBUGGER;
export const isDev = process.env.NODE_ENV === 'development';

export default isDev && breakpointDebuggerEnabled
  ? BreakpointDebugger
  : EMPTY_COMPONENT;
