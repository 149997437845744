import styled from 'styled-components';

import BondPerformanceAnalysisStats from 'components/BondPerformanceAnalysisStats';
import Button from 'components/Button';
import FixedIncomeAssetList from 'components/FixedIncomeAssetList';
import FixedIncomeStats from 'components/FixedIncomeStats';
import Select from 'components/Select';
import YieldDuration from 'components/YieldDurationChart';
import from from 'styles/responsive';
import ZIndex from 'styles/ZIndex';

export const Container = styled.div``;

export const YieldDurationChart = styled(YieldDuration)`
  margin-bottom: 3.0625rem;
`;

export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.4375rem;
`;

export const Title = styled.h2`
  margin: 0;
  font-size: 1rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.weights.bold};
`;

export const ContainerControl = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const FIStats = styled(FixedIncomeStats)`
  margin: 1.5rem 0 3.125rem;
`;

export const BPAStats = styled(BondPerformanceAnalysisStats)`
  margin: 1.5rem 0 3.125rem;
`;

export const ActionZone = styled.div`
  display: flex;
  align-items: center;
`;

export const ExportToExcelButton = styled(Button)`
  min-width: 8.625rem;
`;

export const SortButton = styled(Select)`
  width: fit-content;
  margin-left: 0.75rem;
`;

export const FilterButton = styled(SortButton)``;

export const DataDisplayButton = styled(Select)`
  --min-height: 2.4375rem;
  margin-left: 1rem;
  z-index: ${ZIndex.MAIN};
  ${from.laptop`
    display: none;
  `}
`;

export const FixedIncomeAssetTable = styled(FixedIncomeAssetList)``;
