import useEmail from 'api/hooks/auth/useEmail';

import { useBiometricsControls } from '../logic';
import { useNotificationsControls } from './logic';

export default function useConnect() {
  const { data: email } = useEmail();
  const {
    disabledSettings,
    dailyCloseEnabled,
    newsUpdateEnabled,
    notificationSettingsIsLoading,
    setNotificationSettingsIsLoading,
    handleDailyCloseChange,
    handleNewsUpdateChange,
  } = useNotificationsControls(true);

  const {
    enabled: biometricsEnabled,
    onToggle: onToggleBiometrics,
    type: biometricType,
  } = useBiometricsControls();

  return {
    biometricsEnabled,
    biometricType,
    email,
    disabledSettings,
    dailyCloseEnabled,
    newsUpdateEnabled,
    notificationSettingsIsLoading,
    setNotificationSettingsIsLoading,
    onToggleBiometrics,
    handleDailyCloseChange,
    handleNewsUpdateChange,
  };
}
