import styled, { css } from 'styled-components';

import { isNotTouchable } from 'styles/responsive';

import { PinButton as DefaultPinButton } from '../../styles';
import type { PinProps } from '../../types';

export const PinButton = styled(DefaultPinButton)<{
  $isPinned?: PinProps['$isPinned'];
}>`
  ${isNotTouchable} {
    :hover {
      border: 1px solid ${({ theme }) => theme.colors.white10};
    }
  }
  ${({ $isPinned }) =>
    $isPinned &&
    css`
      ${isNotTouchable} {
        :hover {
          background-color: ${({ theme }) => theme.colors.primary};
        }
      }
    `};
`;
