// "object" is necessary for this to work
// eslint-disable-next-line @typescript-eslint/ban-types
export type WithoutNulls<T> = T extends object
  ? {
      [TKey in keyof T]: WithoutNulls<T[TKey]>;
    }
  : T extends null
  ? undefined
  : T;

export default function withoutNulls<T>(input: T): WithoutNulls<T> {
  if (input === null) {
    return undefined as unknown as WithoutNulls<T>;
  }

  if (typeof input === 'object') {
    if (Array.isArray(input)) {
      return input.map(withoutNulls) as unknown as WithoutNulls<T>;
    }

    return Object.entries(input).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: withoutNulls(value),
      }),
      {} as WithoutNulls<T>,
    );
  }

  return input as unknown as WithoutNulls<T>;
}
