import { FC, memo, useState } from 'react';

import { formatAmount } from 'utils/amounts';
import type { KeySortOfColumn } from 'utils/sort/types';

import {
  Col,
  ColMoreInfo,
  FirstCol,
  Header,
  HeaderCell,
  HeaderTitle,
  MinusIcon,
  PlusIcon,
  Row,
  Table,
  Title,
  VectorDownIcon,
  VectorUpIcon,
} from './styles';
import type { Props } from './types';

const CashTable: FC<Props> = ({
  data,
  handleSortByColumn,
  sortApplied,
  headerColumns,
}) => {
  const [selectedRow, setSelectedRow] = useState<string | undefined>(undefined);

  const clickSort = (col: KeySortOfColumn) => {
    setSelectedRow(undefined);

    if (handleSortByColumn) {
      handleSortByColumn(col);
    }
  };

  const groupedData = Array.from(
    data
      .reduce((acc: Map<string, typeof data[0][]>, item, index) => {
        const normalizedAccount = (item.groupID ?? index).toString();

        if (!acc.has(normalizedAccount)) {
          acc.set(normalizedAccount, []);
        }
        acc.get(normalizedAccount)?.push(item);
        return acc;
      }, new Map<string, typeof data[0][]>())
      .values(),
  );

  return (
    <Table>
      <Header>
        <HeaderCell $marginRight />
        {headerColumns.map((col, index) => (
          <HeaderCell key={col.titleColumn} $alignLeft={index === 0}>
            <HeaderTitle onClick={() => clickSort(col)}>
              <Title>{col.titleColumn}</Title>
              {sortApplied?.titleColumn === col.titleColumn &&
                (sortApplied?.orderOrientation === 'DESC' ? (
                  <VectorDownIcon />
                ) : (
                  <VectorUpIcon />
                ))}
            </HeaderTitle>
          </HeaderCell>
        ))}
      </Header>
      {Object.entries(groupedData).map(([key, items]) => (
        <div key={key}>
          <Row key={key}>
            {items.length > 1 && (
              <ColMoreInfo
                onClick={() =>
                  setSelectedRow(selectedRow !== key ? key : undefined)
                }
              >
                {selectedRow === key ? <MinusIcon /> : <PlusIcon />}
              </ColMoreInfo>
            )}
            {items.length === 1 && <Col />}
            <FirstCol>
              {items[0]?.entity} {items[0]?.account.replace(' Loan', '')}
            </FirstCol>
            <Col>
              {formatAmount(
                items.reduce((total, item) => item.cashUSD + total, 0),
              )}
            </Col>
            <Col>
              {formatAmount(
                items.reduce((total, item) => item.cashOther + total, 0),
              )}
            </Col>
            <Col>
              {formatAmount(
                items.reduce((total, item) => item.cashEquivalent + total, 0),
              )}
            </Col>
            <Col>
              {formatAmount(
                items.reduce((total, item) => item.treasuries + total, 0),
              )}
            </Col>
            <Col>
              {formatAmount(
                items.reduce(
                  (total, item) => item.totalLiquidityAvailable + total,
                  0,
                ),
              )}
            </Col>
          </Row>
          {selectedRow === key &&
            items.map((row, index) => (
              <Row
                key={`${row.entity} ${row.account}`}
                $withoutBorder={index !== items.length - 1}
              >
                <Col />
                <FirstCol>
                  {row.entity} {row.account}
                </FirstCol>
                <Col>{formatAmount(row.cashUSD)}</Col>
                <Col>{formatAmount(row.cashOther)}</Col>
                <Col>{formatAmount(row.cashEquivalent)}</Col>
                <Col>{formatAmount(row.treasuries)}</Col>
                <Col>{formatAmount(row.totalLiquidityAvailable)}</Col>
              </Row>
            ))}
        </div>
      ))}
    </Table>
  );
};

export default memo(CashTable);
