/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useState } from 'react';

import {
  Button,
  Chevron,
  GoBackContainer,
} from 'components/PrivateInvestmentsAssetDetailTable/styles';
import from from 'styles/responsive';
import useMedia from 'utils/useMedia';
import usePlatform from 'utils/usePlatform/usePlatform';

import useConnect from './connect';
import { getFilterOption, instrumentTypes } from './logic';
import {
  AssetAllocationHistoryChart,
  ClientMonthlyAllocationBySubtypeChart,
  Container,
  FilterButton,
  LoaderContainer,
  Spinner,
  Title,
  Toolbar,
} from './styles';

const AssetAllocationHistory: FC = () => {
  const isTablet = useMedia(from.tablet.query);
  const [fullScreen, setFullScreen] = useState(false);
  const { isWeb } = usePlatform();
  const {
    parentUrl,
    assetAllocationHistoryData,
    clientMonthlyAllocationBySubtypeData,
    clientMonthlyAllocationByAssetNameData,
  } = useConnect();
  const [filter, setFilter] = useState<string | undefined>(undefined);
  const [allocationHistory, setAllocationHistory] = useState<any | undefined>(
    undefined,
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (
      assetAllocationHistoryData &&
      clientMonthlyAllocationBySubtypeData &&
      clientMonthlyAllocationByAssetNameData
    ) {
      setLoading(false);
    }
  }, [
    assetAllocationHistoryData,
    clientMonthlyAllocationBySubtypeData,
    clientMonthlyAllocationByAssetNameData,
  ]);

  useEffect(() => {
    if (!filter) return;

    const clientMonthlyAllocation =
      filter !== 'Equity-Individual' && filter !== 'Equity-Market'
        ? clientMonthlyAllocationBySubtypeData
        : clientMonthlyAllocationByAssetNameData;

    if (!clientMonthlyAllocation) return;

    const transformedData = clientMonthlyAllocation.map((data) => {
      const subtypeDataObject = data.instrumentTypes
        .filter((instType) => instType.instrumentType === filter)[0]
        ?.items?.reduce((obj, item) => {
          const subtypeKey = item.name as string;
          const newObj = { ...obj };
          newObj[subtypeKey] = item.balance ?? 0;
          return newObj;
        }, {} as { [key: string]: number });
      return {
        id: data.id,
        date: data.date,
        ...subtypeDataObject,
      };
    });

    setAllocationHistory(transformedData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const onChangeFiltering = (value: string) => {
    setFilter(getFilterOption(value).label);
  };

  const onHandleBackToAllocationHistory = () => {
    setFilter(undefined);
  };

  const onHandleClickInstrumentType = (instrumentType: string | undefined) => {
    if (instrumentType && isWeb) {
      onChangeFiltering(instrumentType);
    }
  };

  return (
    <>
      {loading && (
        <LoaderContainer>
          <Spinner />
        </LoaderContainer>
      )}
      <Container
        $loading={loading}
        $mobile={!isTablet}
        fullScreen={fullScreen}
        parentUrl={parentUrl}
        title="Asset Allocation History"
      >
        {isTablet && (
          <GoBackContainer>
            <Button
              color="secondary"
              variant="only-icon"
              size="small"
              withIcon={<Chevron />}
              href={parentUrl}
            />
          </GoBackContainer>
        )}
        <Toolbar>
          {isTablet && filter && (
            <Title>
              {filter !== 'Equity-Individual' && filter !== 'Equity-Market'
                ? `${filter} by Subtype History`
                : `${filter} by Asset Name History`}
            </Title>
          )}
          {isTablet && !filter && <Title>Asset Allocation History </Title>}
          {(isTablet || !filter) && (
            <FilterButton
              $isTablet={isTablet}
              onChange={onChangeFiltering}
              options={instrumentTypes(assetAllocationHistoryData ?? []).map(
                (type) => getFilterOption(type),
              )}
              placeholder={filter === undefined ? 'Filter' : filter}
              value={filter}
            />
          )}
        </Toolbar>
        <ClientMonthlyAllocationBySubtypeChart
          data={allocationHistory}
          filter={filter}
          handleBackToAllocationHistory={onHandleBackToAllocationHistory}
          isVisible={!!filter}
          onChangeTooltip={setFullScreen}
        />
        <AssetAllocationHistoryChart
          handleClickInstrumentType={onHandleClickInstrumentType}
          data={assetAllocationHistoryData ?? []}
          isVisible={!filter}
          onChangeTooltip={setFullScreen}
        />
      </Container>
    </>
  );
};

export default AssetAllocationHistory;
