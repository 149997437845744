import styled from 'styled-components';

import from from 'styles/responsive';

export const Container = styled.div`
  font-size: 0.75rem;
  line-height: 0.875rem;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;

  ${from.tablet`
    display: none;
  `};
`;

export const SortLabelContainer = styled.div``;

export const FilterLabelContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
