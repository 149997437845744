import { parseISO } from 'date-fns';

import type { GetYieldHistoryResponse } from 'api/queries/allocations/getYieldHistory/types';
import unreachable from 'utils/unreachable';
import type { WithoutNulls } from 'utils/withoutNulls';

type Input = NonNullable<GetYieldHistoryResponse['data']>[number];

export const normalizeYieldHistoryDatum = (input: WithoutNulls<Input>) =>
  ({
    // This percentage is expressed over 1 (1 = 100%)
    adjustedTreasuryYield: input.adjustedTreasuryYield ?? 0,
    date: parseISO(input.date ?? unreachable('1970-01-01')).getTime(),
    id: input.date ?? '',
    portfolioTTM: input.portfolioTTM ?? 0,
    // This percentage is expressed over 1 (1 = 100%)
    portfolioYield: input.portfolioYield ?? 0,
    // This percentage is expressed over 100 (100 = 100%), but we want it over 1
    yieldSpread: (input.yieldSpread ?? 0) / 100,
  } as const);

export type YieldHistoryDatum = ReturnType<typeof normalizeYieldHistoryDatum>;
