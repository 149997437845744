import { FC, memo } from 'react';

import Button from 'components/Button';

import { ApplyButton, CloseIcon, Container, Content, Header } from './styles';
import type { Props } from './types';

const SortModal: FC<Props> = ({ children, className, onClose }) => (
  <Container className={className}>
    <Header>
      <Button
        color="secondary"
        variant="only-icon"
        size="small"
        withIcon={<CloseIcon />}
        onClick={onClose}
      />
      <ApplyButton variant="label" type="submit">
        Apply
      </ApplyButton>
    </Header>
    <Content>{children}</Content>
  </Container>
);

export default memo(SortModal);
