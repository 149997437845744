import { useCallback } from 'react';
import type { QueryFunction } from 'react-query';

import useRequest from 'api/request';
import { Liquidity, normalizeLiquidity } from 'model/Liquidity';
import getPortfolioId from 'utils/getPorfolioId';
import withoutNulls from 'utils/withoutNulls';

import type { GetLiquidityRequest, GetLiquidityResponse } from './types';

export const GET_LIQUIDITY_QUERY_KEY = (portfolio: string) => [
  'allocations/getLiquidity',
  portfolio,
];

export default function useGetLiquidityQuery(): QueryFunction<Liquidity[]> {
  const request = useRequest<GetLiquidityRequest, GetLiquidityResponse>();

  return useCallback(
    async ({ queryKey }) => {
      const [, portfolio] = queryKey as [string, string];
      const response = await request(
        '/api/CVData/getClient_Liquidity/{portfolio}',
        {
          urlParams: {
            portfolio: getPortfolioId(portfolio) ?? '',
          },
        },
      );
      const data = await response.json();

      if (!data.isSuccess) {
        throw new Error(data.message ?? '');
      }
      return withoutNulls(data.data ?? []).map(normalizeLiquidity);
    },
    [request],
  );
}
