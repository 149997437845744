import type { Theme } from 'styles/themes';

import type { ChartKey } from '../types';

export function formatChartKey(key: ChartKey) {
  switch (key) {
    case 'allocation':
      return 'Total Allocation';
    default:
    case 'cumulative':
      return 'Cumulative (%)';
  }
}

export function getChartKeyColor(key: ChartKey, theme: Theme) {
  switch (key) {
    case 'cumulative':
      return theme.colors.dataCarrot;
    default:
    case 'allocation':
      return theme.colors.primary;
  }
}
