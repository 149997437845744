import type { FC } from 'react';
import { memo } from 'react';

import { useUpdateApp } from 'containers/Services/UpdateAppService';
import { formatDate } from 'utils/dates';

import {
  AvatarButton,
  Container,
  Info,
  LastUpdated,
  Name,
  ProfileButton,
  ProfileIcon,
} from './styles';
import type { Props } from './types';

const SelectedPortfolio: FC<Props> = ({
  initials,
  lastUpdated,
  name,
  onAvatarClick,
  onProfileClick,
}) => {
  const updateApp = useUpdateApp();
  const handleAvatarButtom = () => {
    onAvatarClick();
    updateApp?.availableUpdate();
  };

  const handleProfileButtom = () => {
    onProfileClick();
    updateApp?.availableUpdate();
  };

  return (
    <Container>
      <AvatarButton onClick={handleAvatarButtom}>{initials}</AvatarButton>
      <Info>
        <Name>{name}</Name>
        <LastUpdated>Last updated: {formatDate(lastUpdated)}</LastUpdated>
      </Info>
      <ProfileButton>
        <ProfileIcon onClick={handleProfileButtom} />
      </ProfileButton>
    </Container>
  );
};

export default memo(SelectedPortfolio);
