import styled from 'styled-components';

import DefaultTabs from 'components/Tabs';
import { TabButton } from 'components/Tabs/styles';
import from from 'styles/responsive';

export const Separator = styled.div`
  width: 2px;
  margin: 0 1rem;
  background-color: ${({ theme }) => theme.colors.white5};
`;

export const Container = styled.div`
  padding: 0 1.25rem 3.3125rem;
  ${from.tablet`
    padding: 0;
  `}
`;

export const Tabs = styled(DefaultTabs)`
  margin-bottom: 1.5rem;
  ${TabButton} {
    max-width: none;
    max-height: none;
    min-width: 0;
    --padding-start: 0.625rem;
    --padding-end: 0.625rem;
  }
`;

export const SettingsTax = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: -2.6rem;
  margin-bottom: 1rem;
`;

export const ContainerToggle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Label = styled.span`
  margin-left: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
`;

export const Content = styled.div``;
