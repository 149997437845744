import { FC, useCallback, useEffect, useState } from 'react';

import AssetClassBreakdownList from 'components/AssetClassBreakdownList';
import ChangePercentage from 'components/ChangePercentage';
import ProfitLossChart from 'components/ProfitLossChart';
import WinnersLosersTable from 'components/WinnersLosersTable';
import from from 'styles/responsive';
import up from 'utils/paths';
import type { KeySortOfColumn, OrderOrientation } from 'utils/sort/types';
import useMedia from 'utils/useMedia';

import useConnect from './connect';
import { optionsForSelect } from './logic';
import Navigation from './style';
import {
  ActionZone,
  AssetClassListContainer,
  Container,
  FilterButton,
  Header,
  HeaderAmount,
  HeaderTitleContainer,
  LoaderContainer,
  MainGrid,
  MobileContent,
  ROCTitle,
  RowContainer,
  Spinner,
  Subtitle,
  TableTabs,
  TableTitle,
  Title,
  Toolbar,
  ToolbarTitle,
  WinnersLosersTableContainer,
} from './styles';

const tabs = [
  { value: 'winners', label: 'Winners' },
  { value: 'losers', label: 'Losers' },
];

const ReportDetail: FC = () => {
  const [sortColumn, setSortColumn] = useState<string>('Total ($)');
  const [sortOrientation, setSortOrientation] = useState<string>('DESC');
  const {
    isWinnersContent,
    navLinks,
    profitLossChartData,
    reportSlug,
    summary,
    balance,
    formattedDate,
    formattedName,
    selectedPortfolio,
    selectTimeFrame,
    toggleWinnersContent,
    url,
    urlTimeFrame,
    winnersLosers,
    currentSorting,
    headerColumns,
    onChangeSorting,
  } = useConnect();

  const [options, setOptions] = useState<
    { value: string; label: string }[] | undefined
  >(
    optionsForSelect(
      reportSlug,
      selectedPortfolio?.inceptionDate ?? 0,
      selectedPortfolio?.lastUpdated ?? 0,
    ),
  );

  const initOptionValue = useCallback(() => {
    if (options) {
      return urlTimeFrame
        ? options.find((opt) => opt.value === urlTimeFrame)?.value ?? ''
        : options[0]?.value ?? '';
    }
    return '';
  }, [options, urlTimeFrame]);

  const [optionValue, setOptionValue] = useState<string | undefined>(
    initOptionValue(),
  );

  useEffect(() => {
    const currentSortingAux = currentSorting ?? 'default';

    const headerColumn = headerColumns.find(
      (col) =>
        col.keyOrderAsc === currentSortingAux ||
        col.keyOrderDesc === currentSortingAux,
    );

    if (headerColumn) {
      setSortColumn(headerColumn.titleColumn);
      setSortOrientation(
        headerColumn.keyOrderAsc === currentSortingAux ? 'ASC' : 'DESC',
      );
    }
  }, [headerColumns, currentSorting]);

  useEffect(() => {
    setOptions(
      optionsForSelect(
        reportSlug,
        selectedPortfolio?.inceptionDate ?? 0,
        selectedPortfolio?.lastUpdated ?? 0,
      ),
    );
  }, [reportSlug, selectedPortfolio]);

  useEffect(() => {
    setOptionValue(initOptionValue());
    selectTimeFrame(initOptionValue());
  }, [initOptionValue, options, selectTimeFrame]);

  const handleSortByColumn = (sortByColumnSelected: KeySortOfColumn) => {
    if (sortByColumnSelected.titleColumn === sortColumn) {
      onChangeSorting(
        sortOrientation === 'DESC'
          ? sortByColumnSelected.keyOrderAsc
          : sortByColumnSelected.keyOrderDesc,
      );
    } else if (sortByColumnSelected.titleColumn !== sortColumn) {
      onChangeSorting(sortByColumnSelected.keyOrderDesc);
    }
  };

  const onChangeFiltering = (option: string) => {
    setOptionValue(option);
    selectTimeFrame(option);
  };

  const isTablet = useMedia(from.tablet.query);

  return (
    <>
      {(!profitLossChartData || summary.length === 0 || !balance) && (
        <LoaderContainer>
          <Spinner />
        </LoaderContainer>
      )}
      <Container
        $loading={!profitLossChartData || summary.length === 0 || !balance}
        $mobile={!isTablet}
        parentUrl={up(url, 2)}
        title={formattedName ?? ''}
        secondaryTitle={formattedDate ? `For ${formattedDate}` : ''}
      >
        <MainGrid>
          <>
            <Header>
              <HeaderTitleContainer>
                <Title>{formattedName}</Title>
                {formattedDate && <Subtitle>For {formattedDate}</Subtitle>}
              </HeaderTitleContainer>
              <HeaderAmount value={balance?.profitAndLoss ?? 0} />
              <RowContainer>
                <ROCTitle>ROC</ROCTitle>
                <ChangePercentage value={balance?.percentChange ?? 0} />
              </RowContainer>
            </Header>
            <MobileContent>
              {isTablet && (
                <Navigation
                  activeLink={up(url, urlTimeFrame ? 1 : 0)}
                  links={navLinks}
                />
              )}
              {!isTablet && reportSlug === 'since-inception' && (
                <>
                  <TableTitle>Total Profits Since Inception</TableTitle>
                  <ProfitLossChart data={profitLossChartData ?? []} />
                </>
              )}
              <AssetClassListContainer>
                <ActionZone>
                  {!isTablet && <TableTitle>Asset Class Breakdown</TableTitle>}
                  {(reportSlug === 'month-to-date' ||
                    reportSlug === 'year-to-date') && (
                    <FilterButton
                      onChange={onChangeFiltering}
                      options={options ?? []}
                      value={optionValue}
                    />
                  )}
                </ActionZone>
                {summary.length !== 0 && (
                  <AssetClassBreakdownList
                    data={summary}
                    timeFrame={optionValue ?? ''}
                    isSinceInception={reportSlug === 'since-inception'}
                    handleSortByColumn={handleSortByColumn}
                    sortApplied={{
                      titleColumn: sortColumn,
                      orderOrientation: sortOrientation as OrderOrientation,
                    }}
                    headerColumns={headerColumns}
                  />
                )}
              </AssetClassListContainer>
              {reportSlug === 'daily-estimate' && (
                <WinnersLosersTableContainer>
                  <Toolbar>
                    <ToolbarTitle>
                      Top Daily Winners and Losers by Dollar Value
                    </ToolbarTitle>
                    <TableTabs
                      tabs={tabs}
                      onChange={toggleWinnersContent}
                      value={isWinnersContent ? 'winners' : 'losers'}
                      variant="bg-tabs"
                    />
                  </Toolbar>
                  <WinnersLosersTable data={winnersLosers} />
                </WinnersLosersTableContainer>
              )}
            </MobileContent>
          </>
        </MainGrid>
      </Container>
    </>
  );
};

export default ReportDetail;
