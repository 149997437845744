export default function weightedAvg<T>(
  getValue: (item: T) => number,
  getWeight: (item: T) => number,
) {
  return function performWeightedAvg(items: readonly T[]) {
    const { sumOfWeightedValues, sumOfWeights } = items.reduce(
      (
        {
          sumOfWeightedValues: currentSumOfWeightedValues,
          sumOfWeights: currentSumOfWeights,
        },
        asset,
      ) => {
        const value = getValue(asset);
        const weight = getWeight(asset);

        return {
          sumOfWeightedValues: currentSumOfWeightedValues + value * weight,
          sumOfWeights: currentSumOfWeights + weight,
        };
      },
      {
        sumOfWeightedValues: 0,
        sumOfWeights: 0,
      },
    );

    return sumOfWeightedValues / sumOfWeights;
  };
}
