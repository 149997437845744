import styled, { css } from 'styled-components';

import { ReactComponent as DefaultCloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as DefaultFaceIDIcon } from 'assets/icons/face-id.svg';
import { ReactComponent as DefaultTouchIDIcon } from 'assets/icons/touch-id.svg';
import DefaultButton from 'components/Button';

export const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: relative;
`;

export const IconContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white10};
  border-radius: 2.25rem;
  display: flex;
  height: 8rem;
  justify-content: center;
  width: 8rem;
  margin-top: 20vh;
`;

const icon = css`
  color: ${({ theme }) => theme.colors.dark3};
  height: 4rem;
  width: 4rem;
`;

export const FaceIDIcon = styled(DefaultFaceIDIcon)`
  ${icon};
`;

export const TouchIDIcon = styled(DefaultTouchIDIcon)`
  ${icon};
`;

export const CloseIcon = styled(DefaultCloseIcon)`
  color: ${({ theme }) => theme.colors.white};
`;

export const Title = styled.h1`
  font-size: 1.75rem;
  font-style: normal;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: 140%;
  margin: 2rem 0 0.5rem;
`;

export const Subtitle = styled.span`
  color: ${({ theme }) => theme.colors.white75};
  max-width: 15rem;
  text-align: center;
`;

export const EnableButton = styled(DefaultButton).attrs({
  color: 'primary-dark',
})`
  margin: auto 0 4rem;
`;

export const CloseButton = styled(DefaultButton).attrs({
  color: 'secondary',
  variant: 'only-icon',
})`
  left: 1.25rem;
  position: absolute;
  top: 2rem;
`;
