export default function reverseMap<A extends string, B extends string>(
  map: Record<A, B>,
): Record<B, A> {
  return Object.entries(map).reduce((acc, entry) => {
    const [key, value] = entry as [A, B];

    return {
      ...acc,
      [value]: key,
    };
  }, {} as Record<B, A>);
}
