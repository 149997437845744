import { FC, memo } from 'react';

import {
  BackButton,
  Chevron,
  Container,
  Content,
  Paragraph,
  Title,
} from './styles';

const PasswordResetRequestForm: FC = () => (
  <Container>
    <Content>
      <BackButton href="/" withIcon={<Chevron />} />
      <Title>Check your Inbox</Title>
      <Paragraph>
        We&apos;ll send you instructions on how to reset your password
      </Paragraph>
    </Content>
  </Container>
);

export default memo(PasswordResetRequestForm);
