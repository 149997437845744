export type PositionAlert = {
  left: number;
  top: number;
  width: number;
};

export default function getPositionSelectAlert(
  numOptions: number,
  multiple = false,
  placeholder = 'Filter',
  showLeft = false,
): PositionAlert {
  let selectAlert = {
    left: 0,
    top: 0,
    width: 260,
  };

  const heightAlertGroup = 43 * numOptions > 200 ? 200 : 43 * numOptions;
  const widthWrapperAlert = 55 + heightAlertGroup;
  const posBottomScreen = window.scrollY + window.innerHeight;

  document
    .querySelectorAll(
      `ion-select.select-multiple-${placeholder
        .toLowerCase()
        .split(' ')
        .join('-')}`,
    )
    .forEach((a) => {
      if ((a as HTMLIonSelectElement).interface === 'alert') {
        const posSelect = a.getBoundingClientRect();
        const width = posSelect.width > 260 ? posSelect.width + 13 : 260;

        let left: number;
        if (showLeft) {
          left = posSelect.x;
        } else {
          left =
            posSelect.width < 200
              ? posSelect.x + posSelect.width - width + 13
              : posSelect.x;
        }

        selectAlert = {
          left,
          top: posSelect.y + posSelect.height + 4,
          width,
        };

        const isPlaceOnTop =
          selectAlert.top + widthWrapperAlert + 20 > posBottomScreen;

        if (isPlaceOnTop) {
          selectAlert.top =
            posSelect.y - 4 - widthWrapperAlert + 20 - (multiple ? 84 : 0);
        }
      }
    });

  return selectAlert;
}
