import useGetUserDefaultPortfolio, {
  GET_USER_DEFAULT_PORTFOLIO_QUERY_KEY,
} from 'api/queries/clients/portfolio';
import { useQuery } from 'api/storage';

export default function useDefaultPortfolio() {
  const { data: portfolio } = useQuery(GET_USER_DEFAULT_PORTFOLIO_QUERY_KEY, {
    queryFn: useGetUserDefaultPortfolio(),
  });
  return { portfolio };
}
