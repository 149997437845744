import {
  MarkerArrow as DefaultMarkerArrow,
  MarkerX as DefaultMarkerX,
} from '@visx/marker';
import { LinePath as DefaultLinePath } from '@visx/shape';
import styled from 'styled-components';

export const LinePath = styled(DefaultLinePath)<{
  $showInstrumentArrow: boolean;
  $strokeTransparent: boolean;
}>`
  stroke: ${({ theme, $showInstrumentArrow, $strokeTransparent }) =>
    $showInstrumentArrow && !$strokeTransparent
      ? theme.colors.primary
      : 'transparent'};
  stroke-dasharray: ${({ $strokeTransparent }) =>
    !$strokeTransparent ? 5 : 0};
`;

export const MarkerX = styled(DefaultMarkerX)`
  stroke: ${({ theme }) => theme.colors.primary};
`;

export const MarkerArrow = styled(DefaultMarkerArrow)`
  stroke: ${({ theme }) => theme.colors.primary};
`;
