import { TooltipWithBounds as DefaultTooltipWithBounds } from '@visx/tooltip';
import styled from 'styled-components';

import { tooltipStyles } from 'components/Tooltip/styles';

export const TooltipWithBounds = styled(DefaultTooltipWithBounds).attrs({
  offsetLeft: 16,
  offsetTop: -46,
  // We disable the default @visx/tooltip styles as they are applied at the
  // element level and can't be overridden.
  unstyled: true,
})`
  // We rescue some styles defined by @visx/tooltip, which we disabled above.
  pointer-events: none;
  position: absolute;

  // Custom styles
  ${tooltipStyles}
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  /**
    The background of the tooltip is the same as the bar
    so they can't be distinguished properly, the properties
    below make the tooltip distinguishable, though we have
    to keep the design consistent
   */
  /* background: ${({ theme }) => `${theme.colors.dark3}FA`};
  border: 0.125rem solid ${({ theme }) => `${theme.colors.primary}40`}; */
  box-shadow: 0 0 1.375rem -0.5rem ${({ theme }) => `${theme.colors.dark3}FA`};
  > *:not(:last-child) {
    margin-bottom: 0.25rem;
  }
`;

export const Rating = styled.span`
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  line-height: 0.9063rem;
  color: ${({ theme }) => theme.colors.white75};
`;

export const Value = styled.span`
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  line-height: 1.0625rem;
`;
