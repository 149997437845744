import { FC, useEffect } from 'react';
import { useHistory } from 'react-router';

import { useNews } from 'containers/Services/NewsService';
import from from 'styles/responsive';
import useMedia from 'utils/useMedia';

import useConnect from './connect';
import MarketsDesktopTablet from './MarketsDesktopTablet';
import MarketsMobile from './MarketsMobile';
import { Container, LoaderContainer, Spinner } from './styles';

const Markets: FC = () => {
  const isTablet = useMedia(from.tablet.query);
  const { replace } = useHistory();
  const newsServices = useNews();
  const { watchlistData, handleChangeMainTab, activeMainTab } = useConnect();

  useEffect(() => {
    if (newsServices?.notificationToNews) {
      replace('/news');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsServices?.notificationToNews]);

  return (
    <>
      {!watchlistData && (
        <LoaderContainer>
          <Spinner />
        </LoaderContainer>
      )}
      <Container $loading={!watchlistData} $mobile={!isTablet}>
        {isTablet ? (
          <MarketsDesktopTablet watchlistData={watchlistData ?? []} />
        ) : (
          <MarketsMobile
            watchlistData={watchlistData ?? []}
            handleChangeMainTab={handleChangeMainTab}
            activeMainTab={activeMainTab}
          />
        )}
      </Container>
    </>
  );
};
export default Markets;
