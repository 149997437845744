import { PushNotifications, Token } from '@capacitor/push-notifications';
import { useCallback, useEffect, useState } from 'react';

import useNotificationSettings from 'api/hooks/clients/notifications/useNotificationSettings';
import useSetNotificationSettings from 'api/hooks/clients/notifications/useSetNotificationSettings';
import useDevice from 'utils/useDevice/useDevice';
import usePlatform from 'utils/usePlatform/usePlatform';

export function useNotificationsControls(fromProfile = false) {
  const { platform, isWeb } = usePlatform();
  const { deviceID } = useDevice();
  const {
    isLoading: notificationSettingsIsLoading,
    data: notificationSettings,
  } = useNotificationSettings({ deviceID });
  const [snsToken, setSnsToken] = useState<string>();
  const [loadingSnsToken, setLoadingSnsToken] = useState(true);
  const [dailyCloseEnabled, setDailyCloseEnabled] = useState(false);
  const [newsUpdateEnabled, setNewsUpdateEnabled] = useState(false);
  const [hasActivateAnyNotifications, sethasActivateAnyNotifications] =
    useState<boolean | undefined>(undefined);
  const {
    isLoading: setNotificationSettingsIsLoading,
    setNotificationSettings,
  } = useSetNotificationSettings({ deviceID });

  useEffect(() => {
    if (!isWeb) {
      void PushNotifications.checkPermissions().then((permission) => {
        if (
          permission.receive === 'prompt' ||
          permission.receive === 'denied'
        ) {
          if (fromProfile) {
            void PushNotifications.requestPermissions().then((permission1) => {
              if (permission1.receive === 'granted') {
                // Register with Apple / Google to receive push via APNS/FCM
                void PushNotifications.register();
              } else {
                setSnsToken(undefined);
                setLoadingSnsToken(false);
              }
            });
          } else {
            setSnsToken(undefined);
            setLoadingSnsToken(false);
          }
        } else if (permission.receive === 'granted') {
          void PushNotifications.register();
        } else {
          setSnsToken(undefined);
          setLoadingSnsToken(false);
        }
      });

      void PushNotifications.addListener('registration', (token: Token) => {
        if (token.value) {
          setSnsToken(token.value);
        }
        setLoadingSnsToken(false);
      });
    }
  }, [fromProfile, isWeb]);

  useEffect(() => {
    if (
      !notificationSettingsIsLoading &&
      !loadingSnsToken &&
      !setNotificationSettingsIsLoading &&
      notificationSettings
    ) {
      const settings = notificationSettings?.find(
        (ns) => ns.token === snsToken,
      );

      if (settings && (settings.dailyClose || settings.newsUpdate)) {
        sethasActivateAnyNotifications(true);
      } else {
        sethasActivateAnyNotifications(false);
      }
    }
  }, [
    notificationSettings,
    notificationSettingsIsLoading,
    setNotificationSettingsIsLoading,
    snsToken,
    loadingSnsToken,
  ]);

  useEffect(() => {
    const settings = notificationSettings?.find((ns) => ns.token === snsToken);
    setDailyCloseEnabled(settings?.dailyClose ?? false);
    setNewsUpdateEnabled(settings?.newsUpdate ?? false);
  }, [notificationSettings, snsToken]);

  const handleSetNotificationSettings = useCallback(
    (dailyClose: boolean, newsUpdate: boolean) => {
      if (deviceID && snsToken && platform) {
        void setNotificationSettings({
          snsToken,
          deviceID,
          platform,
          dailyClose,
          newsUpdate,
        });
      }
    },
    [snsToken, setNotificationSettings, deviceID, platform],
  );

  const handleDailyCloseChange = useCallback(() => {
    const newDailyClose = !dailyCloseEnabled;
    handleSetNotificationSettings(newDailyClose, newsUpdateEnabled);
  }, [dailyCloseEnabled, handleSetNotificationSettings, newsUpdateEnabled]);

  const handleNewsUpdateChange = useCallback(() => {
    const newNewsUpdate = !newsUpdateEnabled;
    handleSetNotificationSettings(dailyCloseEnabled, newNewsUpdate);
  }, [newsUpdateEnabled, handleSetNotificationSettings, dailyCloseEnabled]);

  return {
    disabledSettings:
      !snsToken ||
      notificationSettingsIsLoading ||
      setNotificationSettingsIsLoading,
    dailyCloseEnabled,
    newsUpdateEnabled,
    hasActivateAnyNotifications,
    notificationSettingsIsLoading,
    setNotificationSettingsIsLoading,
    handleDailyCloseChange,
    handleNewsUpdateChange,
  };
}
