import { useCallback } from 'react';
import type { MutationFunction } from 'react-query';

import useRequest from 'api/request';
import withoutNulls from 'utils/withoutNulls';

import type {
  RequestResetPasswordRequest,
  RequestResetPasswordResponse,
  RequestResetPasswordVariables,
} from './types';

export default function useRequestResetPasswordMutation(): MutationFunction<
  void,
  RequestResetPasswordVariables
> {
  const request = useRequest<
    RequestResetPasswordRequest,
    RequestResetPasswordResponse
  >();

  return useCallback(
    async ({ email }: RequestResetPasswordVariables) => {
      const response = await request(
        '/api/Authentication/passwordResetRequest',
        {
          body: {
            domainName: process.env.REACT_APP_FRONTEND_HOST ?? '',
            email,
          },
          method: 'post',
        },
      );

      const data = withoutNulls(await response.json());

      if (!data.isSuccess) {
        throw new Error(data.message);
      }
    },
    [request],
  );
}
