import { TooltipWithBounds as DefaultTooltipWithBounds } from '@visx/tooltip';
import styled, { css } from 'styled-components';

import { tooltipStyles } from 'components/Tooltip/styles';

import { getChartKeyColor } from '../logic';
import type { ChartKey } from '../types';

export const TooltipWithBounds = styled(DefaultTooltipWithBounds).attrs({
  offsetLeft: 16,
  offsetTop: -10,
  // We disable the default @visx/tooltip styles as they are applied at the
  // element level and can't be overridden.
  unstyled: true,
})`
  // We rescue some styles defined by @visx/tooltip, which we disabled above.
  pointer-events: none;
  position: absolute;

  // Custom styles
  ${tooltipStyles};
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: 0.25rem;
  }
`;

export const Key = styled.span`
  display: flex;
  align-items: center;
`;

export const KeySquare = styled.span<{ $key: ChartKey }>`
  border: 1px solid white;
  border-radius: 0.125rem;
  display: inline-block;
  height: 0.625rem;
  margin-right: 0.625rem;
  width: 0.625rem;

  ${({ $key, theme }) => css`
    background-color: ${getChartKeyColor($key, theme)};
  `}};
`;

export const KeyLabel = styled.span`
  color: ${({ theme }) => theme.colors.white75};
  font-size: 0.875rem;
  line-height: 1.0625rem;
`;

export const Value = styled.span`
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  line-height: 1.0625rem;
`;
