import { useCallback, useState } from 'react';

import useMarketWatchlist from 'api/hooks/markets/useMarketWatchlist';
import from from 'styles/responsive';
import useMedia from 'utils/useMedia';

export default function useConnect() {
  const [activeMainTab, setActiveMainTab] = useState('watchlist');
  const isTablet = useMedia(from.tablet.query);
  const { data: watchlistData, refetch } = useMarketWatchlist(isTablet);

  const handleChangeMainTab = useCallback(
    (value: string) => {
      if (value === 'watchlist') {
        void refetch();
      }
      setActiveMainTab(value);
    },
    [refetch, setActiveMainTab],
  );

  return {
    watchlistData,
    handleChangeMainTab,
    activeMainTab,
  };
}
