import styled, { css } from 'styled-components';

import { ReactComponent as Pin } from 'assets/icons/pin.svg';
import { ReactComponent as DefaultVectorDownIcon } from 'assets/icons/vector-down.svg';
import { ReactComponent as DefaultVectorUpIcon } from 'assets/icons/vector-up.svg';
import ChangePercentage from 'components/ChangePercentage';
import NumberAmount from 'components/NumberAmount';
import from, { isNotTouchable } from 'styles/responsive';

import type { PinProps } from './types';

export const FirstCol = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 0 0.25rem;
  font-size: 1rem;
  line-height: 1.205rem;
`;

export const TableContainer = styled.div``;

export const Table = styled.div`
  display: grid;
  color: ${({ theme }) => theme.colors.white};
  font-size: 1rem;
  line-height: 1.205rem;
  > :first-child {
    border-top: 0.5px solid ${({ theme }) => theme.colors.white10};
  }
  ${from.tablet`
  align-self: start;
    > :nth-child(even) {
      background: ${({ theme }) => theme.colors.dark1};
    }
    > :first-child {
      border-top: none;
    }
  `}
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: 1.5fr repeat(4, 0.8fr);
  column-gap: 0.5rem;
  padding: 1rem 1.5rem;
  color: ${({ theme }) => theme.colors.white75};
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  text-align: right;
  position: sticky;
  top: 0;
  background: ${({ theme }) => theme.colors.dark2};
  z-index: 1;

  > :first-child {
    text-align: left;
  }
  ${from.laptop`
    grid-template-columns: 1.5fr repeat(4, 0.45fr);
  `}
  ${from.desktop`
    grid-template-columns: 1.5fr repeat(4, 0.22fr);
  `}
`;

export const HeaderCell = styled.div`
  font-size: 0.875rem;
  line-height: 1.0544rem;
`;

export const HeaderTitle = styled.span`
  cursor: pointer;
  display: inline-flex;
`;

export const Title = styled.span`
  width: max-content;
`;

export const Container = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1.5fr repeat(2, 0.4fr);
  column-gap: 0.5rem;
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.white10};
  padding: 1rem 0;
  ${from.tablet`
    padding: 1rem 1.5rem;
    grid-template-columns: 1.5fr repeat(4, 0.8fr);
  `}
  ${from.laptop`
    grid-template-columns: 1.5fr repeat(4, 0.45fr);
  `}
  ${from.desktop`
    grid-template-columns: 1.5fr repeat(4, 0.22fr);
  `}
`;

export const Col = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const Column = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-self: end;
  width: min-content;
  span {
    margin-top: 0.25rem;
  }
`;

export const ColorPercentage = styled(ChangePercentage)`
  display: flex;
  align-items: center;
`;

export const Amount = styled(NumberAmount)`
  margin-top: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.0625rem;
`;

export const ColorAmount = styled(NumberAmount).attrs({ coloured: true })`
  font-size: 0.875rem;
  line-height: 1.0625rem;
  margin-right: 1.0625rem;
`;

export const PinButton = styled.button<{
  $isPinned?: PinProps['$isPinned'];
}>`
  padding: 0.5625rem 0.75rem;
  border-radius: 0.75rem;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.white10};
  cursor: pointer;
  transition: 200ms ease;
  ${isNotTouchable} {
    :hover {
      border: 1px solid ${({ theme }) => theme.colors.white25};
    }
  }
  ${({ $isPinned }) =>
    $isPinned &&
    css`
      border: 1px solid transparent;
      background-color: ${({ theme }) => theme.colors.primary};
      ${isNotTouchable} {
        :hover {
          background-color: ${({ theme }) => theme.colors.primary75};
          border: 1px solid transparent;
        }
      }
    `};
`;

export const PinIcon = styled(Pin)<{
  $isPinned?: PinProps['$isPinned'];
}>`
  color: ${({ theme }) => theme.colors.white50};
  transform: rotate(25deg);
  ${({ $isPinned }) =>
    $isPinned &&
    css`
      color: ${({ theme }) => theme.colors.white};
      transform: rotate(0deg);
    `};
`;

export const Legend = styled.p`
  margin: 12px 0 0;
  color: ${({ theme }) => theme.colors.white75};
  font-size: 0.875rem;
  line-height: 1.0625rem;
  ${from.tablet`
    margin: 12px 0 0 24px;
  `}
`;

export const VectorDownIcon = styled(DefaultVectorDownIcon)``;

export const VectorUpIcon = styled(DefaultVectorUpIcon)``;
