import type { GetPortfolioHistoryChartDataResponse } from 'api/queries/charts/getPortfolioHistoryChartData/types';
import { parseBackendDateTime } from 'utils/dates';
import type { WithoutNulls } from 'utils/withoutNulls';

type Input = NonNullable<
  NonNullable<
    NonNullable<
      GetPortfolioHistoryChartDataResponse['data']
    >[number]['subClientMonthlyPerformance']
  >[number]['monthlyPerformanceReport']
>[number];

export const normalizePortfolioHistoryDatum = (input: WithoutNulls<Input>) =>
  ({
    activity: input.activity ?? 0,
    balance: input.balance ?? 0,
    date: input.monthDate ? parseBackendDateTime(input.monthDate).getTime() : 0,
    id: `${input.id}`,
    initialBalance: input.initialBalance ?? 0,
    loan: input.loanAmount ?? 0,
    profitAndLoss: input.pnL ?? 0,
  } as const);

export type PortfolioHistoryDatum = ReturnType<
  typeof normalizePortfolioHistoryDatum
>;
