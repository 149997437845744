import { Capacitor } from '@capacitor/core';
import { FC, memo, useEffect } from 'react';
import { useLocation } from 'react-router';

import Allocation from 'components/Allocation';
import Amount from 'components/Amount';
import BaseListButton from 'components/BaseListButton';
import { useAssetContext } from 'context/Asset';
import from from 'styles/responsive';
import useMedia from 'utils/useMedia';

import {
  Col,
  Content,
  FirstCol,
  Header,
  HeaderCell,
  HeaderContainer,
  HeaderTitle,
  Row,
  SortMobileButton,
  Table,
  VectorDownIcon,
  VectorUpIcon,
} from './styles';
import type { Props } from './types';

const AllocationsList: FC<Props> = ({
  data,
  textButton,
  openModal,
  handleSortByColumn,
  sortApplied,
  headerColumns,
}) => {
  const { asset, setAsset } = useAssetContext();
  const { search } = useLocation();

  const isMobile = !useMedia(from.tablet.query);

  useEffect(() => {
    const subselection = new URLSearchParams(search).get('subselection');
    const browseBy = new URLSearchParams(search).get('browseBy');

    if (Capacitor.getPlatform() !== 'web') {
      if (asset && data && !subselection) {
        setTimeout(() => {
          document.getElementById(asset)?.scrollIntoView({ block: 'center' });
          setAsset('');
        }, 20);
      } else if (browseBy && subselection) {
        setAsset(`by_${browseBy}_${subselection}`);
      }
    }
  }, [data, asset, setAsset, search]);

  return (
    <Table>
      <HeaderContainer>
        <Header>
          {headerColumns.map((col) => (
            <HeaderCell key={col.titleColumn}>
              <HeaderTitle
                onClick={() =>
                  handleSortByColumn ? handleSortByColumn(col) : undefined
                }
              >
                {col.titleColumn}
                {!isMobile &&
                  sortApplied?.titleColumn === col.titleColumn &&
                  (sortApplied?.orderOrientation === 'DESC' ? (
                    <VectorDownIcon />
                  ) : (
                    <VectorUpIcon />
                  ))}
              </HeaderTitle>
            </HeaderCell>
          ))}
        </Header>
        {isMobile && (
          <SortMobileButton color="secondary" size="small" onClick={openModal}>
            {textButton || 'Sort'}
          </SortMobileButton>
        )}
      </HeaderContainer>
      <Content>
        {data.map((row) => (
          <BaseListButton
            id={`btn-allocations-${row.slug}`}
            key={row.id}
            pathname={row.pathname}
          >
            <Row id={row.id}>
              <FirstCol>
                {row.name}{' '}
                <span>
                  {row.totalAssets} {row.totalAssets === 1 ? 'asset' : 'assets'}
                </span>
              </FirstCol>
              <Col>
                <Amount value={row.totalValue} />
              </Col>
              <Col>
                <Allocation value={row.totalAllocation} />
              </Col>
            </Row>
          </BaseListButton>
        ))}
      </Content>
    </Table>
  );
};

export default memo(AllocationsList);
