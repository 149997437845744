import { FC, useEffect } from 'react';
import { Redirect } from 'react-router';

import RegularAssetDetailTable from 'components/RegularAssetDetailTable';
import { useAssetContext } from 'context/Asset';

import useConnect from './connect';
import { Container } from './styles';

const AssetDetail: FC = () => {
  const { setAsset } = useAssetContext();
  const {
    asset,
    backUrl,
    dividendsReceived,
    marketPrice,
    marketValue,
    cost,
    parentPath,
    pageTitle,
    shares,
    title,
    totalProfitLoss,
    url,
  } = useConnect();

  useEffect(() => {
    setAsset(asset?.id ?? '');
    setTimeout(() => {
      document.getElementById('header')?.scrollIntoView({ block: 'end' });
    }, 0);
  }, [asset?.id, setAsset]);

  if (!asset) {
    return <Redirect to={parentPath} />;
  }

  return (
    <Container parentUrl={backUrl} title={pageTitle}>
      <RegularAssetDetailTable
        categoryName={pageTitle}
        dividendsReceived={dividendsReceived}
        marketPrice={marketPrice}
        marketValue={marketValue}
        cost={cost}
        parentUrl={backUrl}
        shares={shares}
        title={title}
        totalProfitLoss={totalProfitLoss}
        url={url}
      />
    </Container>
  );
};
export default AssetDetail;
