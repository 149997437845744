import styled from 'styled-components';

import from from 'styles/responsive';

export const Container = styled.div`
  ${from.laptop`
    grid-column: span 2;
  `};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.375rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  line-height: 1.4;
  margin: 0 0 1.5rem 0;

  ${from.tablet`
    margin: 0 0 1.875rem 0;
  `}
`;

export const ContainerControls = styled.div`
  display: flex;
  height: 100%;
`;

export const ContainerCustomDate = styled.div`
  margin-right: 1rem;
  display: flex;
  align-items: center;
`;
