import { useCallback } from 'react';

import useRequest from 'api/request';
import withoutNulls from 'utils/withoutNulls';

import type {
  GetUserDefaultPortfolioRequest,
  GetUserDefaultPortfolioResponse,
} from './types';

export const GET_USER_DEFAULT_PORTFOLIO_QUERY_KEY =
  'clients/getUserDefaultPortfolio';

export default function useGetUserDefaultPortfolio() {
  const request = useRequest<
    GetUserDefaultPortfolioRequest,
    GetUserDefaultPortfolioResponse
  >();

  return useCallback(async () => {
    const response = await request('/api/ClientData/getUserDefaultPortfolio');
    const data = await response.json();
    if (!data.isSuccess) {
      throw new Error(data.message ?? 'Could not get default portfolio');
    }
    return withoutNulls(data.data);
  }, [request]);
}
