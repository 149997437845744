function padMonth(month: number) {
  if (month < 10) {
    return `0${month}`;
  }

  return month;
}

function lastDay(y: number, m: number) {
  return new Date(y, m, 0).getDate();
}

export default function last12Month(lastUpdate: number | undefined) {
  const dates = [];
  const lastUpdateDate = lastUpdate ? new Date(lastUpdate) : new Date();
  const year = lastUpdateDate.getFullYear();
  const month = lastUpdateDate.getMonth();

  if (month === 11) {
    for (let i = 1; i < 13; i += 1) {
      dates.push(`${padMonth(i)}-${lastDay(year, i)}-${year}`);
    }
  } else {
    for (let i = month + 1; i < month + 13; i += 1) {
      if (i % 12 > month) {
        dates.push(
          `${padMonth(i + 1)}-${lastDay(year - 1, i + 1)}-${year - 1}`,
        );
      } else {
        dates.push(
          `${padMonth((i % 12) + 1)}-${lastDay(year, (i % 12) + 1)}-${year}`,
        );
      }
    }
  }

  return dates.reverse();
}
