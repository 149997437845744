import styled from 'styled-components';

import DefaultIncomeProfileChart from 'components/IncomeProfileChart';

export const Container = styled.div``;

export const IncomeProfileChart = styled(DefaultIncomeProfileChart)`
  margin-bottom: 2.125rem;
`;

export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.4375rem;
`;

export const Title = styled.h2`
  margin: 0;
  font-size: 1rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.weights.bold};
`;
