import { FC, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import PrivateInvestmentsAssetDetailTable from 'components/PrivateInvestmentsAssetDetailTable';
import { useAssetContext } from 'context/Asset';

import useConnect from './connect';
import { Container } from './styles';

const AssetDetailPrivateInvestments: FC = () => {
  const { setAsset } = useAssetContext();
  const {
    asset,
    backUrl,
    capitalCalled,
    commitment,
    distributions,
    marketValue,
    moi,
    parentPath,
    title,
    toBeCalled,
    totalPortfolioPercent,
    totalPortfolioAtCostPercent,
    contDollars,
    valueDate,
    closingDate,
    endOfInvPeriodDate,
    maximumLiquidityDate,
    url,
  } = useConnect();

  useEffect(() => {
    setAsset(asset?.id ?? '');
    setTimeout(() => {
      document.getElementById('header')?.scrollIntoView({ block: 'end' });
    }, 0);
  }, [asset?.id, setAsset]);

  if (!asset) {
    return <Redirect to={parentPath} />;
  }

  return (
    <Container parentUrl={backUrl} title="Private Investments">
      <PrivateInvestmentsAssetDetailTable
        capitalCalled={capitalCalled}
        commitment={commitment}
        distributions={distributions}
        marketValue={marketValue}
        MOI={moi}
        parentUrl={backUrl}
        title={title}
        toBeCalled={toBeCalled}
        totalPortfolioPercent={totalPortfolioPercent}
        totalPortfolioAtCostPercent={totalPortfolioAtCostPercent}
        contDollars={contDollars}
        valueDate={valueDate}
        closingDate={closingDate}
        endOfInvPeriodDate={endOfInvPeriodDate}
        maximumLiquidityDate={maximumLiquidityDate}
        url={url}
      />
    </Container>
  );
};
export default AssetDetailPrivateInvestments;
