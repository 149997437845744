import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import useEmail from 'api/hooks/auth/useEmail';
import useNewsForEmail from 'api/hooks/clients/news/useNewsForEmail';
import type { NewsForAnEmail } from 'model/NewsForEmailDatum';

import type { NewsContextModel } from './types';

const Context = createContext<NewsContextModel | undefined>(undefined);

const NewsProvider: FC = ({ children }) => {
  const [notificationToNews, setNotificationToNews] = useState<boolean>(
    localStorage.getItem('navigate_to_news_from_notification') === 'true',
  );
  const [hasNewsUnread, setHasNewsUnread] = useState<boolean>(false);
  const [numNewsUnread, setNumNewsUnread] = useState<number>(0);
  const [newsList, setNewsList] = useState<NewsForAnEmail[] | undefined>(
    undefined,
  );
  const [newsUnread, setNewsUnread] = useState<number[]>([]);
  const [newsRead, setNewsRead] = useState<number[]>([]);

  const { data: email } = useEmail();
  const { data } = useNewsForEmail(email);

  const updateNewsList = (news: NewsForAnEmail[] | undefined) => {
    if (news) {
      setNewsList(news);

      setNewsRead(news.filter((n) => n.messageRead).map((n) => n.id ?? 0));
      setNewsUnread(news.filter((n) => !n.messageRead).map((n) => n.id ?? 0));

      const numNewsU = news.filter((n) => !n.messageRead).length;
      setNumNewsUnread(numNewsU);
      setHasNewsUnread(numNewsU > 0);
    }
  };

  const updateNumNewsUnread = (value: number) => {
    setNumNewsUnread(value);
    setHasNewsUnread(value > 0);
  };

  const updateUnreadAndReadList = useCallback(
    (messageID: number) => {
      const iMUnread = newsUnread.findIndex((id) => id === messageID);
      const iMRead = newsRead.findIndex((id) => id === messageID);

      if (iMUnread !== undefined && iMUnread !== -1) {
        newsUnread?.splice(iMUnread, 1);
        setNewsUnread(newsUnread);
        newsRead?.push(messageID);
        setNewsRead(newsRead);
      } else if (iMRead !== undefined && iMRead !== -1) {
        newsRead?.splice(iMRead, 1);
        setNewsRead(newsRead);
        newsUnread?.push(messageID);
        setNewsUnread(newsUnread);
      } else if (messageID === 0) {
        setNewsRead(newsRead?.concat(newsUnread ?? []));
        newsUnread?.splice(0, newsUnread.length);
        setNewsUnread(newsUnread);
      }

      updateNumNewsUnread(newsUnread.length ?? 0);
    },
    [newsRead, newsUnread],
  );

  const updateNotificationToNews = (value: boolean) => {
    setNotificationToNews(value);
  };

  useEffect(() => {
    updateNewsList(data);
  }, [data]);

  const value = useMemo(
    () => ({
      hasNewsUnread,
      numNewsUnread,
      newsList,
      newsUnread,
      newsRead,
      notificationToNews,
      updateNewsList,
      updateUnreadAndReadList,
      updateNumNewsUnread,
      updateNotificationToNews,
    }),
    [
      hasNewsUnread,
      numNewsUnread,
      newsList,
      newsUnread,
      newsRead,
      notificationToNews,
      updateUnreadAndReadList,
    ],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export function useNews() {
  return useContext(Context);
}

export default NewsProvider;
