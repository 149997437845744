import { parse } from 'query-string';
import { useMemo } from 'react';
import { useLocation } from 'react-router';

import { useRequestSubmitHandler, useResetSubmitHandler } from './logic';

export default function useConnect() {
  const { search } = useLocation();

  const { firstTime, email, token } = useMemo(() => {
    const parsed = parse(search);

    return {
      firstTime: parsed.firstTime === 'true',
      email: typeof parsed.email === 'string' ? parsed.email : undefined,
      token: typeof parsed.token === 'string' ? parsed.token : undefined,
    };
  }, [search]);

  const {
    handleRequestSubmit,
    loading: requestLoading,
    success: emailSent,
  } = useRequestSubmitHandler();

  const { handleResetSubmit, loading: resetLoading } = useResetSubmitHandler({
    email,
    token,
  });

  return {
    firstTime,
    emailSent,
    handle: {
      requestSubmit: handleRequestSubmit,
      resetSubmit: handleResetSubmit,
    },
    isReset: !!email && !!token,
    loading: requestLoading || resetLoading,
  };
}
