import { useCallback } from 'react';
import type { QueryFunction } from 'react-query';

import useRequest from 'api/request';
import { MarketSummary, normalizeMarketSummary } from 'model/MarketSummary';
import getPortfolioId from 'utils/getPorfolioId';
import withoutNulls from 'utils/withoutNulls';

import type {
  GetMarketSummaryRequest,
  GetMarketSummaryResponse,
} from './types';

export const GET_MARKET_SUMMARY_QUERY_KEY = ({
  portfolio,
  beginDate,
  endDate,
}: {
  portfolio: string;
  beginDate: string;
  endDate: string | undefined;
}) => ['market/getMarketSummary', portfolio, beginDate, endDate];

export default function useGetMarketSummaryQuery(): QueryFunction<
  MarketSummary[]
> {
  const request = useRequest<
    GetMarketSummaryRequest,
    GetMarketSummaryResponse
  >();

  return useCallback(
    async ({ queryKey }) => {
      const [, portfolio, beginDate, endDate] = queryKey as [
        string,
        string,
        string,
        string,
      ];

      let response;
      if (endDate === undefined) {
        response = await request(
          '/api/CVData/getMarketSummary/{portfolio}/{dateOrYear}',
          {
            urlParams: {
              portfolio: getPortfolioId(portfolio) ?? '',
              dateOrYear: beginDate,
            },
          },
        );
      } else {
        response = await request(
          '/api/CVData/getMarketSummary/{portfolio}/{dateOrYear}/{endDate}',
          {
            urlParams: {
              portfolio: getPortfolioId(portfolio) ?? '',
              dateOrYear: beginDate,
              endDate,
            },
          },
        );
      }

      const data = await response.json();

      if (!data.isSuccess) {
        throw new Error(data.message ?? '');
      }
      return withoutNulls(data.data ?? []).map(normalizeMarketSummary);
    },
    [request],
  );
}
