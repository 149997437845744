import { IonToggle } from '@ionic/react';
import styled, { css } from 'styled-components';

import type { Size } from './types';

export const DefaultToggle = styled(IonToggle)<{
  $size: Size;
}>`
  padding: 0;
  --background: ${({ theme }) => theme.colors.white10};
  --background-checked: ${({ theme }) => theme.colors.primary};
  --handle-background-checked	${({ theme }) => theme.colors.white};
  &.toggle-checked {
    --background-checked: ${({ theme }) => theme.colors.primary};
  }
  --border-radius: 32px;
  
  ${({ $size }) =>
    $size === 'medium' &&
    css`
      width: 56px;
      height: 32px;
      --border-radius: 32px;
      --handle-spacing: 4px;
      --handle-width: 24px;
      --handle-height: 24px;
    `};
  ${({ $size }) =>
    $size === 'small' &&
    css`
      width: 46px;
      height: 26px;
      --handle-spacing: 3px;
      --handle-width: 20px;
      --handle-height: 20px;
    `};
`;
