import styled from 'styled-components';

import Select from 'components/Select';
import Tabs from 'components/Tabs';
import from from 'styles/responsive';

export const Container = styled.div`
  ${from.laptop`
    width: 18rem;
  `};
`;

export const LaptopTabs = styled(Tabs).attrs({
  variant: 'bg-tabs',
})`
  ion-segment-button {
    min-width: 5rem;
    font-size: 0.75rem;
  }
` as typeof Tabs;

export const TabletSelect = styled(Select)``;
