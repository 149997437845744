import useGetUserDefaultAccount, {
  GET_USER_DEFAULT_ACCOUNT_QUERY_KEY,
} from 'api/queries/clients/account';
import { useQuery } from 'api/storage';

export default function useDefaultAccount() {
  const { data: account } = useQuery(GET_USER_DEFAULT_ACCOUNT_QUERY_KEY, {
    queryFn: useGetUserDefaultAccount(),
  });
  return { account };
}
