import styled from 'styled-components';

import { ReactComponent as DefaultVectorDownIcon } from 'assets/icons/vector-down.svg';
import { ReactComponent as DefaultVectorUpIcon } from 'assets/icons/vector-up.svg';
import { RowBase } from 'components/BaseListButton/styles';

export const FirstCol = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  span {
    margin-top: 0.25rem;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    opacity: 0.75;
  }
`;

export const Table = styled.div`
  display: grid;
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.875rem;
  line-height: 1.0625rem;
`;

export const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 0.1fr;
  column-gap: 0.5rem;
  padding: 1rem 2rem;
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.colors.dark2};
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  z-index: 1;
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: 1.5fr repeat(5, 1fr);
  column-gap: 0.5rem;
  color: ${({ theme }) => theme.colors.white75};
  text-align: right;
  text-transform: capitalize;
  > :first-child {
    text-align: left;
  }
`;

export const Content = styled.div`
  border-radius: 1rem;
  overflow: hidden;
  margin-top: 1rem;
  > :not(:last-child) div {
    margin-bottom: 0.125rem;
  }
  ${RowBase} {
    grid-template-columns: 1.5fr 0.1fr;
  }
`;

export const HeaderCell = styled.div``;

export const HeaderTitle = styled.span`
  cursor: pointer;
  display: inline-flex;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1.5fr repeat(5, 1fr);
  column-gap: 0.5rem;
  align-items: center;
  margin: 0;
  font-size: 1rem;
  line-height: 1.1875rem;
`;

export const Col = styled.div`
  text-align: right;
  display: flex;
  flex-direction: column;
  & > span:not(:first-child) {
    margin-top: 0.25rem;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    opacity: 0.75;
  }
`;

export const VectorDownIcon = styled(DefaultVectorDownIcon)``;

export const VectorUpIcon = styled(DefaultVectorUpIcon)``;
