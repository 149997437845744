import { useMemo } from 'react';

import { formatAmount } from 'utils/amounts';
import { formatPercentage } from 'utils/percentages';

import type { Props } from './types';

const decimals = new Intl.NumberFormat('en-Us', {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export function useFormattedData({
  annualIncome,
  coupon,
  duration,
  rating,
  timeToMaturity,
  yieldToWorst,
  enabledTaxEquvalentYield,
}: {
  annualIncome: Props['annualIncome'];
  coupon: Props['coupon'];
  duration: Props['duration'];
  rating: Props['rating'];
  timeToMaturity: Props['timeToMaturity'];
  yieldToWorst: Props['yieldToWorst'];
  enabledTaxEquvalentYield: boolean;
}) {
  return useMemo(
    () => [
      {
        id: 1,
        title: enabledTaxEquvalentYield
          ? 'TE Yield to Worst'
          : 'Yield to Worst',
        value: formatPercentage(yieldToWorst),
      },
      {
        id: 2,
        title: 'Time to Maturity',
        value: decimals.format(timeToMaturity),
      },
      {
        id: 3,
        title: 'Duration',
        value: decimals.format(duration),
      },
      {
        id: 4,
        title: 'Rating',
        value: rating,
      },
      {
        id: 5,
        title: 'Coupon',
        value: formatPercentage(coupon),
      },
      {
        id: 6,
        title: 'Annual Income',
        value: formatAmount(annualIncome),
      },
    ],
    [
      annualIncome,
      coupon,
      duration,
      enabledTaxEquvalentYield,
      rating,
      timeToMaturity,
      yieldToWorst,
    ],
  );
}
