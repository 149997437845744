import { IonHeader } from '@ionic/react';
import styled from 'styled-components';

import { ReactComponent as DefaultProfileIcon } from 'assets/icons/profile.svg';
import DefaultAvatarButton from 'components/AvatarButton';

export const Container = styled(IonHeader)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.dark2};
  border-bottom: 1px solid ${({ theme }) => theme.colors.white10};
  color: ${({ theme }) => theme.colors.white};
  padding: 3rem 1.25rem 1rem;
  display: flex;
  justify-content: space-between;
`;

export const AvatarButton = styled(DefaultAvatarButton)``;

export const Info = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const Name = styled.span`
  font-weight: ${({ theme }) => theme.weights.medium};
`;

export const LastUpdated = styled.span`
  color: ${({ theme }) => theme.colors.white75};
  font-size: 0.875rem;
`;

export const ProfileButton = styled(AvatarButton)`
  background-color: ${({ theme }) => theme.colors.white10};
`;

export const ProfileIcon = styled(DefaultProfileIcon)``;
