import useAllocationsQuery, {
  GET_ALLOCATIONS_QUERY_KEY,
} from 'api/queries/allocations/getAllocations';
import { useQuery } from 'api/storage';

export default function useAllocations(portfolio: string | undefined) {
  const { data } = useQuery(GET_ALLOCATIONS_QUERY_KEY(portfolio ?? ''), {
    enabled: !!portfolio,
    queryFn: useAllocationsQuery(),
  });

  return {
    data,
  };
}
