import { ErrorMessage, useField, useFormikContext } from 'formik';
import { ChangeEvent, FC, KeyboardEventHandler, useCallback } from 'react';

import { ErrorContainer, Input } from './styles';
import type { Props } from './types';

const FormikIonInput: FC<Props> = ({ name, ...props }) => {
  const { submitForm } = useFormikContext();
  const [{ value = '' }, , { setValue }] = useField<string>(name);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    },
    [setValue],
  );

  const handleKeyboardSubmission: KeyboardEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        if (e.key === 'Enter') {
          void submitForm();
        }
      },
      [submitForm],
    );

  return (
    <>
      <Input
        onChange={onChange}
        value={value}
        onKeyDown={handleKeyboardSubmission}
        /* We don't know which props Formik will pass, so we make an exception here */
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      {name && (
        <ErrorContainer>
          <ErrorMessage name={name} />
        </ErrorContainer>
      )}
    </>
  );
};

export default FormikIonInput;
