const profileMenuPathnames = {
  pathnameRoot: '/',
  pathnameNotifications: '/notifications',
  pathnamePassword: '/change-password',
  pathnamePasscode: '/change-passcode',
  pathnameConditions: '/terms-and-conditions',
  pathnameLogOut: '/logout',
};

export const {
  pathnameRoot,
  pathnameNotifications,
  pathnamePassword,
  pathnamePasscode,
  pathnameConditions,
  pathnameLogOut,
} = profileMenuPathnames;
