import { FC, useEffect, useState } from 'react';

import Toggle from 'components/Toggle';

import useConnect from './connect';
import CreditRiskProfile from './CreditRiskProfile';
import IncomeProfile from './IncomeProfile';
import IncomeProfileR12 from './IncomeProfileR12';
import MaturityProfile from './MaturityProfile';
import {
  Container,
  ContainerToggle,
  Content,
  Label,
  Separator,
  SettingsTax,
  Tabs,
} from './styles';
import type { Props } from './types';
import YieldHistory from './YieldHistory';
import YieldVsDuration from './YieldVsDuration';

const tabs = [
  { value: 'yield-vs-duration', label: 'Yield Vs Duration' },
  { value: 'income-profile-r12', label: 'Income Profile R12' },
  { value: 'income-profile', label: 'Income Profile' },
  { value: 'maturity-profile', label: 'Maturity Profile' },
  { value: 'credit-risk-profile', label: 'Credit Risk Profile' },
  { value: 'yield-history', label: 'Yield History' },
];

const FullContent: FC<Props> = ({
  assets,
  cvBondPerformanceSummaries,
  fixedIncomeCharacteristicsUrl,
  bondPerformanceAnalysisUrl,
  currentFiltering,
  currentSorting,
  filterOptions,
  onChangeMultiple,
  onChangeSorting,
  onChangeTab,
}) => {
  const [activeTab, setActiveTab] = useState('yield-vs-duration');
  const [enabledAfterTax, setEnabledAfterTax] = useState(false);
  const [enabledTaxEquvalentYield, setEnabledTaxEquvalentYield] =
    useState(false);

  const { countryState } = useConnect();

  useEffect(() => {
    onChangeTab(activeTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useEffect(() => {
    setEnabledTaxEquvalentYield(false);
    setEnabledAfterTax(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryState]);

  const onToggleAfterTax = () => {
    setEnabledAfterTax(!enabledAfterTax);
  };

  const onToggleTaxEquvalentYield = () => {
    setEnabledTaxEquvalentYield(!enabledTaxEquvalentYield);
  };

  return (
    <Container>
      {countryState === 'US' && (
        <SettingsTax>
          <ContainerToggle onClick={onToggleTaxEquvalentYield}>
            <Toggle
              id="tax-equivalent-yield-toggle"
              checked={enabledTaxEquvalentYield}
              value="taxEquivalentYield"
              size="small"
            />
            <Label>Tax Equivalent Yield</Label>
          </ContainerToggle>
          <Separator />
          <ContainerToggle onClick={onToggleAfterTax}>
            <Toggle
              id="after-tax-toggle"
              checked={enabledAfterTax}
              value="afterTax"
              size="small"
            />
            <Label>Estimated After Tax Income</Label>
          </ContainerToggle>
        </SettingsTax>
      )}
      <Tabs
        value={activeTab}
        tabs={tabs}
        onChange={setActiveTab}
        variant="bg-tabs"
      />
      <Content>
        {(() => {
          switch (activeTab) {
            case 'yield-vs-duration':
            default:
              return (
                <YieldVsDuration
                  assets={assets}
                  cvBondPerformanceSummaries={cvBondPerformanceSummaries}
                  fixedIncomeCharacteristicsUrl={fixedIncomeCharacteristicsUrl}
                  bondPerformanceAnalysisUrl={bondPerformanceAnalysisUrl}
                  currentFiltering={currentFiltering}
                  currentSorting={currentSorting}
                  filterOptions={filterOptions}
                  onChangeMultiple={onChangeMultiple}
                  onChangeSorting={onChangeSorting}
                  enabledAfterTax={enabledAfterTax}
                  enabledTaxEquvalentYield={enabledTaxEquvalentYield}
                />
              );
            case 'income-profile-r12':
              return <IncomeProfileR12 enabledAfterTax={enabledAfterTax} />;
            case 'income-profile':
              return <IncomeProfile enabledAfterTax={enabledAfterTax} />;
            case 'maturity-profile':
              return <MaturityProfile />;
            case 'credit-risk-profile':
              return <CreditRiskProfile assets={assets} />;
            case 'yield-history':
              return <YieldHistory />;
          }
        })()}
      </Content>
    </Container>
  );
};

export default FullContent;
