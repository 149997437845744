import type { GetAssetAllocationHistoryChartDataResponse } from 'api/queries/charts/getAssetAllocationHistoryChartData/types';
import type { WithoutNulls } from 'utils/withoutNulls';

type Input = NonNullable<
  GetAssetAllocationHistoryChartDataResponse['data']
>[number];

export const normalizeAssetAllocationHistoryDatum = (
  input: WithoutNulls<Input>,
) =>
  ({
    instrumentType: input.instrumentType ?? null,
    date: input.date ? new Date(input.date).getTime() : 0,
    percentage: input.percentage ?? 0,
    balance: input.balance ?? 0,
  } as const);

export type AssetAllocationHistoryDatum = ReturnType<
  typeof normalizeAssetAllocationHistoryDatum
>;
