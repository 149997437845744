import { useCallback } from 'react';
import type { QueryFunction } from 'react-query';

import useRequest from 'api/request';
import getPortfolioId from 'utils/getPorfolioId';

import type {
  GetMinimumTransactionDateRequest,
  GetMinimumTransactionDateResponse,
} from './types';

export const GET_MINIMUM_TRANSACTION_DATE_QUERY_KEY = (portfolio: string) => [
  'transactions/getMinimumTransactionDate',
  portfolio,
];

export default function useGetMinimumTransactionDateQuery(): QueryFunction<Date> {
  const request = useRequest<
    GetMinimumTransactionDateRequest,
    GetMinimumTransactionDateResponse
  >();

  return useCallback(
    async ({ queryKey }) => {
      const [, portfolio] = queryKey as [string, string];

      const response = await request(
        // eslint-disable-next-line no-nested-ternary
        '/api/CVData/getMinimumTransactionDate/{portfolio}',
        {
          urlParams: {
            portfolio: getPortfolioId(portfolio) ?? '',
          },
        },
      );

      const data = await response.json();

      if (!data.isSuccess) {
        throw new Error(data.message ?? '');
      }

      return new Date(data.data ?? 0);
    },
    [request],
  );
}
