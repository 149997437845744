import type { TickFormatter } from '@visx/axis';
import { range } from 'lodash';
import { FC, memo, useMemo } from 'react';
import useResizeObserver from 'use-resize-observer';

import { formatPercentageWithDecimalsIfNecessary } from 'utils/percentages';

import GridColumns from './GridColumns';
import Legend from './Legend';
import {
  durationToYearsForAxisBottom,
  useEdgeValues,
  useHeight,
  useMargins,
  useMedians,
  useScales,
  useTooltip,
} from './logic';
import Point from './Point';
import PointChart from './PointChart';
import {
  AxisBottom,
  AxisBottomTitleWrapper,
  AxisLeft,
  BottomTitle,
  Container,
  GraphVector,
  Grid,
  LeftTitle,
} from './styles';
import Tooltip from './Tooltip';
import type { ChartKey, Props } from './types';

const YieldDuration: FC<Props> = ({
  className,
  data: readonlyData,
  durationAverage,
  yieldToWorstAverage,
  enabledTaxEquvalentYield = false,
}) => {
  // visx wants mutable arrays even though it will never mutate them
  const data = useMemo(() => [...readonlyData], [readonlyData]);
  const bands = useMemo(() => {
    const years = Array.from(
      new Set([...data].map((datum) => Math.floor(datum.duration))),
    );
    const maxYear = years.length ? Math.max(...years) + 1 : 0;
    const bandsArray = range(maxYear);
    return bandsArray;
  }, [data]);
  const { ref, width = 0 } = useResizeObserver();
  const height = useHeight();

  const {
    bottomAxisHeight,
    bottomAxisMargin,
    leftAxisMargin,
    leftAxisWidth,
    rightMargin,
    topMargin,
  } = useMargins();

  const { end, start, yieldToWorstEdges, valueEdges } = useEdgeValues(
    data,
    enabledTaxEquvalentYield,
  );

  const { xScale, xScaleAxis, yScale, radiusScale } = useScales({
    data,
    start,
    end,
    yieldToWorstMaxValue: yieldToWorstEdges.max,
    valueMaxValue: valueEdges.max,
    valueMinValue: valueEdges.min,
    width,
    height,
    topMargin,
    rightMargin,
    bottomAxisHeight,
    bottomAxisMargin,
    leftAxisMargin,
    leftAxisWidth,
  });

  const { averageRadius, averageX, averageY } = useMedians({
    durationAverage,
    radiusScale,
    valueTotalValues: valueEdges.total,
    xScale,
    yieldToWorstAverage,
    yScale,
  });

  const {
    handleTooltipClosed,
    handleTooltipUpdated,
    tooltipData,
    tooltipLeft,
    tooltipTop,
  } = useTooltip({
    xScale,
    yScale,
    height: topMargin + bottomAxisHeight,
    leftAxisMargin,
    leftAxisWidth,
  });

  const withAverage =
    typeof yieldToWorstAverage === 'number' &&
    typeof durationAverage === 'number';

  return (
    <Container ref={ref} className={className}>
      <GraphVector width="100%" viewBox={`0 0 ${width} ${height}`}>
        <GridColumns
          bands={bands}
          scale={xScaleAxis}
          height={height - bottomAxisHeight - bottomAxisMargin - topMargin}
          top={topMargin}
        />
        <Grid
          scale={yScale}
          width={width - leftAxisWidth - leftAxisMargin - rightMargin}
          left={leftAxisWidth + leftAxisMargin}
        />
        <AxisLeft
          left={leftAxisWidth}
          scale={yScale}
          tickFormat={
            formatPercentageWithDecimalsIfNecessary as TickFormatter<unknown>
          }
        />
        <AxisBottom
          left={0}
          tickValues={bands}
          tickFormat={durationToYearsForAxisBottom}
          scale={xScaleAxis}
          top={height - bottomAxisHeight}
        />
        <LeftTitle
          $x={34}
          $y={height / 2}
          dominantBaseline="central"
          textAnchor="middle"
        >
          {enabledTaxEquvalentYield
            ? 'TE Yield To Worst (%)'
            : 'Yield To Worst (%)'}
        </LeftTitle>
        <PointChart
          data={data}
          xScale={xScale}
          yScale={yScale}
          radiusScale={radiusScale}
          bottomHeight={height - bottomAxisHeight - bottomAxisMargin}
          handleTooltipClosed={handleTooltipClosed}
          handleTooltipUpdated={handleTooltipUpdated}
          enabledTaxEquvalentYield={enabledTaxEquvalentYield}
        />
        {withAverage && (
          <Point
            key="median"
            meanYieldToWorst={yieldToWorstAverage}
            meanDuration={durationAverage}
            radius={averageRadius}
            x={averageX}
            y={averageY}
            handleTooltipUpdated={handleTooltipUpdated}
            handleTooltipClosed={handleTooltipClosed}
          />
        )}
      </GraphVector>
      <AxisBottomTitleWrapper>
        <BottomTitle>Duration (Years)</BottomTitle>
      </AxisBottomTitleWrapper>
      <Legend
        keys={[
          'individualAsset',
          withAverage ? 'portfolioAverage' : null,
        ].filter((item): item is ChartKey => Boolean(item))}
      />
      {typeof tooltipData !== 'undefined' &&
        typeof tooltipLeft === 'number' &&
        typeof tooltipTop === 'number' && (
          <Tooltip
            data={tooltipData}
            tooltipLeft={tooltipLeft}
            tooltipTop={tooltipTop}
            enabledTaxEquvalentYield={enabledTaxEquvalentYield}
          />
        )}
    </Container>
  );
};

export default memo(YieldDuration);
