import { Route } from 'react-router-dom';

import MarketsCategoryDetail from './MarketsCategoryDetail';
import MarketsRoot from './MarketsRoot';
import type { Props } from './types';

function Markets({ path }: Props) {
  return [
    // Markets category detail
    <Route
      key="markets-category-detail"
      exact
      path={`${path}/categories/:categorySlug`}
      component={MarketsCategoryDetail}
    />,
    // Root
    <Route key="markets-root" exact path={path} component={MarketsRoot} />,
  ];
}

export default Markets;
