import type { FC } from 'react';
import { memo } from 'react';

import { formatMonth } from 'utils/dates';
import { formatPercentage } from 'utils/percentages';

import { Date, TooltipWithBounds, Value } from './styles';
import type { Props } from './types';

const Tooltip: FC<Props> = ({ data, tooltipLeft, tooltipTop }) => (
  <TooltipWithBounds top={tooltipTop} left={tooltipLeft}>
    <Date>{formatMonth(data.date)}</Date>
    <Value>{formatPercentage(data.value)}</Value>
  </TooltipWithBounds>
);

export default memo(Tooltip);
