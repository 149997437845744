import styled from 'styled-components';

import { ReactComponent as MaintenanceIcon } from 'assets/icons/icon-maintenance.svg';
import from from 'styles/responsive';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  margin: 2rem;
  color: ${({ theme }) => theme.colors.white};
`;

export const PrimaryText = styled.div`
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  margin: 2rem 0;

  ${from.tablet`
    font-size: 2.5rem;
  `}
`;

export const SecondaryText = styled.div`
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.weights.medium};

  ${from.tablet`
    font-size: 1.5rem;
  `}
`;

export const TertiaryText = styled.div`
  font-size: 1rem;

  ${from.tablet`
    font-size: 1.25rem;
  `}
`;

export const MaintenanceImg = styled(MaintenanceIcon)`
  width: 75%;
  margin-bottom: 2rem;

  ${from.tablet`
      width: 50%;
  `}

  ${from.laptop`
    width: 30%;
`}
`;
