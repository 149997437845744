import styled, { css } from 'styled-components';

import type { CellProps } from './types';

export const Container = styled.div`
  margin-bottom: 3.75rem;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.5313rem 0 1.3438rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.white10};
`;

export const ColTitle = styled.h2`
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: ${({ theme }) => theme.colors.white75};
  font-weight: ${({ theme }) => theme.weights.regular};
`;

export const ColValue = styled.span`
  font-size: 1rem;
  line-height: 1.1875rem;
  color: ${({ theme }) => theme.colors.white};
  text-align: right;
  p {
    margin: 4px 0 0 0;
    opacity: 0.75;
    font-size: 0.875rem;
  }
`;

export const ColorCol = styled(ColValue)<{
  $isNegative?: CellProps['$isNegative'];
}>`
  color: ${({ theme }) => theme.colors.statusIncrease};
  ${({ $isNegative }) =>
    $isNegative &&
    css`
      color: ${({ theme }) => theme.colors.statusDecrease};
    `}
`;
