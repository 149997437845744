import { useMemo } from 'react';

import useLiquidityAssets from 'api/hooks/allocations/useLiquidityAssets';
import type { AssetGrouping } from 'model/AssetGrouping';
import type { Liquidity } from 'model/Liquidity';
import { formatAmount } from 'utils/amounts';
import getCashSummary from 'utils/cash/getCashSummary';
import { formatPercentage } from 'utils/percentages';
import type { SortOption } from 'utils/useDataManipulation/useEntitySorting';
import useEntitySorting from 'utils/useDataManipulation/useEntitySorting';

type LiquiditySortOption = SortOption<Liquidity>;

export function useData({
  portfolio,
  grouping,
  totalCashAllocation,
  compare,
}: {
  portfolio: string | undefined;
  grouping: { groupBy: AssetGrouping; subselection: string } | undefined;
  totalCashAllocation: number;
  compare: LiquiditySortOption['compare'];
}) {
  const { data: accounts } = useLiquidityAssets({
    portfolio,
    grouping,
  });

  const summary = useMemo(() => getCashSummary(accounts), [accounts]);

  const relativeTotalLiquidityAvailable =
    (summary.totalLiquidityAvailable * (totalCashAllocation * 100)) /
    (summary.totalCashAvailable * 100);

  const accountsSorted = accounts.sort(compare);

  const mobileRows = useMemo(
    () => [
      {
        id: 1,
        key: 'Cash (USD)',
        value: formatAmount(summary.cashUSD),
      },
      {
        id: 2,
        key: 'Cash (Other Currencies)',
        value: formatAmount(summary.cashOther),
      },
      {
        id: 3,
        key: 'Cash Equivalent',
        value: formatAmount(summary.cashEquivalent),
      },
      {
        id: 4,
        key: 'Total Cash Available',
        value: formatAmount(summary.totalCashAvailable),
      },
      {
        id: 5,
        key: 'US Treasuries',
        value: formatAmount(summary.treasuries),
      },
      {
        id: 6,
        key: 'Total Liquidity Available',
        value: formatAmount(summary.totalLiquidityAvailable),
        subValue: formatPercentage(relativeTotalLiquidityAvailable),
      },
    ],
    [summary, relativeTotalLiquidityAvailable],
  );

  return {
    accounts: accountsSorted,
    relativeTotalLiquidityAvailable,
    mobileRows,
  };
}

// noinspection DuplicatedCode - This is abstract enough
export function useSortingLiquidityTable() {
  const sortOptions: readonly [LiquiditySortOption, ...LiquiditySortOption[]] =
    useMemo(
      () => [
        {
          compare: (a, b) => b.cashUSD - a.cashUSD,
          label: 'Cash (USD): High to Low',
          value: 'default',
        },
        {
          compare: (a, b) => a.cashUSD - b.cashUSD,
          label: 'Cash (USD): Low to High',
          value: 'cashUsdASC',
        },
        {
          compare: (a, b) => b.cashOther - a.cashOther,
          label: 'Cash (Other Currencies): High to Low',
          value: 'cashOtherDESC',
        },
        {
          compare: (a, b) => a.cashOther - b.cashOther,
          label: 'Cash (Other Currencies): Low to High',
          value: 'cashOtherASC',
        },
        {
          compare: (a, b) => b.cashEquivalent - a.cashEquivalent,
          label: 'Cash Equivalent: High to Low',
          value: 'cashEquivDESC',
        },
        {
          compare: (a, b) => a.cashEquivalent - b.cashEquivalent,
          label: 'Cash Equivalent: Low to High',
          value: 'cashEquivASC',
        },
        {
          compare: (a, b) => b.treasuries - a.treasuries,
          label: 'US Treasuries: High to Low',
          value: 'usTreasuriesDESC',
        },
        {
          compare: (a, b) => a.treasuries - b.treasuries,
          label: 'US Treasuries: Low to High',
          value: 'usTreasuriesASC',
        },
        {
          compare: (a, b) =>
            b.totalLiquidityAvailable - a.totalLiquidityAvailable,
          label: 'Total Liquidity Available: High to Low',
          value: 'totalLiquidityDESC',
        },
        {
          compare: (a, b) =>
            a.totalLiquidityAvailable - b.totalLiquidityAvailable,
          label: 'Total Liquidity Available: Low to High',
          value: 'totalLiquidityASC',
        },
        {
          compare: (a, b) =>
            `${a.entity}${a.account}`.localeCompare(`${b.entity}${b.account}`),
          label: 'Alphabetical: A to Z',
          value: 'alphabetical',
        },
        {
          compare: (a, b) =>
            `${b.entity}${b.account}`.localeCompare(`${a.entity}${a.account}`),
          label: 'Alphabetical: Z to A',
          value: 'alphabeticalR',
        },
      ],
      [],
    );

  return useEntitySorting({ sortOptions });
}
