import type { FC } from 'react';
import { useCallback } from 'react';

import { Container, LaptopTabs } from './styles';
import type { Period, Props } from './types';

const laptopOptions = [
  {
    label: 'Month',
    value: 'MTD' as const,
  },
  {
    label: 'Year',
    value: 'YTD' as const,
  },
];

const Controls: FC<Props> = ({ onChange, selectedPeriod }) => {
  const handlePeriodChange = useCallback(
    (period: Period) => {
      onChange((current) => ({
        ...current,
        period,
      }));
    },
    [onChange],
  );

  return (
    <Container>
      <LaptopTabs<Period>
        onChange={handlePeriodChange}
        value={selectedPeriod}
        tabs={laptopOptions}
      />
    </Container>
  );
};

export default Controls;
