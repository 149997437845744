import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.h1`
  margin: 0 0 0.25rem;
  font-size: 1.75rem;
  line-height: 2.4375rem;
  color: ${({ theme }) => theme.colors.white};
`;

export const Email = styled.p`
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: ${({ theme }) => theme.colors.white75};
`;
