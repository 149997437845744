import type { GetDailyVolatilityChartDataResponse } from 'api/queries/riskAnalysis/getDailyVolatilityChartData/types';
import type { WithoutNulls } from 'utils/withoutNulls';

type Input = NonNullable<GetDailyVolatilityChartDataResponse['data']>[number];

export const normalizeDailyVolatilityDatum = (input: WithoutNulls<Input>) =>
  ({
    id: `${input.change}`,
    change: input.change ?? 0,
    days: input.dataValue ?? 0,
  } as const);

export type DailyVolatilityDatum = ReturnType<
  typeof normalizeDailyVolatilityDatum
>;
