import { useCallback } from 'react';

import useRequest from 'api/request';
import { useVault } from 'containers/Services/VaultService';

import type { LogoutRequest, LogoutResponse } from './types';

export default function useLogoutMutation() {
  const request = useRequest<LogoutRequest, LogoutResponse>();
  const { isCreated, isSupported } = useVault();

  return useCallback(async () => {
    if (!isCreated || !isSupported) {
      const response = await request(`/api/Authentication/logOut`);
      const success = await response.json();
      if (!success) {
        throw new Error(`Couldn't log out`);
      }
    }
  }, [isCreated, isSupported, request]);
}
