import { curveLinear } from '@visx/curve';
import { Group } from '@visx/group';
import { memo } from 'react';
import type { FC } from 'react';

import { Path } from './styles';
import type { Props } from './types';

const LineChart: FC<Props> = ({ data, xScale, yScale, widthToCenterBand }) => (
  <Group>
    <Path
      curve={curveLinear}
      data={data}
      x={(d) => (xScale(d.date) || 0) + widthToCenterBand ?? 0}
      y={(d) => yScale(d.balance || 0) - 10 ?? 0}
    />
  </Group>
);

export default memo(LineChart);
