import useSelectedClient from 'api/hooks/clients/useSelectedClient';

import { useDataSelection, useMaturityData } from './logic';

export default function useConnect() {
  const { selectedClient, selectedPortfolio } = useSelectedClient();

  const maturityData = useMaturityData({
    clientName: selectedClient?.name,
    portfolio: selectedPortfolio?.id,
  });

  const { dataOptions, maturityDataDetail, selectedData, setSelectedData } =
    useDataSelection({ maturityData });

  return {
    dataOptions,
    handle: { changeSelectedData: setSelectedData },
    maturityData,
    maturityDataDetail,
    selectedData,
  };
}
