import styled from 'styled-components';

import { ReactComponent as DefaultMinusIcon } from 'assets/icons/minus.svg';
import { ReactComponent as DefaultPlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as DefaultVectorDownIcon } from 'assets/icons/vector-down.svg';
import { ReactComponent as DefaultVectorUpIcon } from 'assets/icons/vector-up.svg';

export const FirstCol = styled.div`
  text-align: left;
  color: ${({ theme }) => theme.colors.white};
  font-size: 1rem;
  line-height: 1.1875rem;
  span {
    display: block;
    margin-top: 0.25rem;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: ${({ theme }) => theme.colors.white75};
  }
`;

export const Table = styled.div`
  display: grid;
  color: ${({ theme }) => theme.colors.white};
  font-size: 1rem;
  line-height: 1.1875rem;
  color: ${({ theme }) => theme.colors.white};
  > :nth-child(even) {
    background: ${({ theme }) => theme.colors.dark1};
  }
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: 3rem 18% 14% repeat(3, 15%) 16.8%;
  column-gap: 0.5rem;
  padding: 1rem;
  color: ${({ theme }) => theme.colors.white75};
  font-size: 0.875rem;
  line-height: 1.0625rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  position: sticky;
  top: 0;
  background: ${({ theme }) => theme.colors.dark2};
  text-align: center;
  > :first-child {
    text-align: left;
  }
`;

export const HeaderCell = styled.div<{
  $alignLeft?: boolean;
  $marginRight?: boolean;
}>`
  text-align: ${({ $alignLeft }) => ($alignLeft ? 'left' : 'center')};
  margin-right: ${({ $marginRight }) => ($marginRight ? '2rem' : '0')};
`;

export const HeaderTitle = styled.span`
  cursor: pointer;
  display: inline-flex;
`;

export const Title = styled.span`
  font-size: 0.75rem;
  width: max-content;
`;

export const Row = styled.div<{ $withoutBorder?: boolean }>`
  display: grid;
  grid-template-columns: 3rem 18% 14% repeat(3, 15%) 16.8%;
  column-gap: 0.5rem;
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.white10};
  padding: 1rem;

  ${({ $withoutBorder }) =>
    $withoutBorder &&
    `
      border-bottom: none;
    `}
`;

export const Col = styled.div`
  text-align: center;
  span {
    color: ${({ theme }) => theme.colors.white75};
    display: block;
    margin-top: 0.25rem;
    font-size: 0.875rem;
    line-height: 1.0625rem;
  }
`;

export const ColMoreInfo = styled.div`
  cursor: pointer;
  text-align: center;
  height: 1.5rem;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  margin-right: 1.5rem;

  &:hover {
    background: ${({ theme }) => theme.colors.primary50};
  }
`;

export const VectorDownIcon = styled(DefaultVectorDownIcon)``;

export const VectorUpIcon = styled(DefaultVectorUpIcon)``;

export const MinusIcon = styled(DefaultMinusIcon)``;

export const PlusIcon = styled(DefaultPlusIcon)``;
