import { useCallback, useState } from 'react';

export default function useModal() {
  const [modalOpen, setModalOpen] = useState(false);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  const openModal = useCallback(() => {
    setModalOpen(true);
  }, [setModalOpen]);

  return {
    closeModal,
    modalOpen,
    openModal,
  };
}
