import type { GetAssetClassBreakdownSummaryResponse } from 'api/queries/home/getAssetClassBreakdownSummary/types';
import kebabCaseCustom from 'utils/kebabCaseCustom';
import type { WithoutNulls } from 'utils/withoutNulls';

type Input = NonNullable<
  NonNullable<GetAssetClassBreakdownSummaryResponse['data']>[number]
>;

export const normalizeAssetClassBreakdownSummary = (
  input: WithoutNulls<Input>,
) =>
  ({
    id: `${input.id}`,
    name: input.instrumentName ?? '',
    numberOfAssets: input.numberOfAssets ?? 0,
    percentChange: input.percentChange ?? 0,
    profitAndLoss: input.pnL ?? 0,
    slug: kebabCaseCustom(input.instrumentName ?? ''),
  } as const);

export type AssetClassBreakdownSummary = ReturnType<
  typeof normalizeAssetClassBreakdownSummary
>;
