import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import useSetUserDefaultAccount from 'api/mutations/clients/account';
import { GET_USER_DEFAULT_ACCOUNT_QUERY_KEY } from 'api/queries/clients/account';

export default function useSetDefaultAccount() {
  const client = useQueryClient();
  const { mutateAsync } = useMutation(useSetUserDefaultAccount(), {
    onSuccess: () => {
      void client.invalidateQueries([GET_USER_DEFAULT_ACCOUNT_QUERY_KEY]);
    },
  });
  return useCallback(
    async ({ newDefaultAccount }: { newDefaultAccount: string }) =>
      mutateAsync({ newDefaultAccount }),
    [mutateAsync],
  );
}
