import { memo } from 'react';
import type { FC } from 'react';

import Point from '../Point';
import type { Props } from './types';

const PointChart: FC<Props> = ({
  data,
  xScale,
  yScale,
  radiusScale,
  handleTooltipClosed,
  handleTooltipUpdated,
  enabledTaxEquvalentYield,
}) => (
  <g>
    {data.map((datum) => (
      <Point
        key={`point-${datum.id}-${datum.value}`}
        datum={datum}
        x={xScale(datum.duration) ?? 0}
        y={yScale(enabledTaxEquvalentYield ? datum.tey : datum.ytw) ?? 0}
        radius={radiusScale(datum.value)}
        handleTooltipUpdated={handleTooltipUpdated}
        handleTooltipClosed={handleTooltipClosed}
      />
    ))}
  </g>
);

export default memo(PointChart);
