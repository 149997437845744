import { useCallback } from 'react';
import type { MutationFunction } from 'react-query';

import useRequest from 'api/request';

import type {
  SetMarketWatchlistMutationVariables,
  SetMarketWatchlistRequest,
  SetMarketWatchlistResponse,
} from './types';

export default function useSetMarketWatchlistMutation(): MutationFunction<
  void,
  SetMarketWatchlistMutationVariables
> {
  const request = useRequest<
    SetMarketWatchlistRequest,
    SetMarketWatchlistResponse
  >();

  return useCallback(
    async ({ assets }) => {
      await request('/api/CVData/ClientMarketSubscription_InsUpd', {
        body: assets.map((asset, i) => ({
          instrumentName: asset,
          order: i,
        })),
        method: 'post',
      });
    },
    [request],
  );
}
