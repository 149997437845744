import { useMemo } from 'react';

import useGetClientMonthlyAllocationByAssetNameChartDataQuery, {
  GET_CLIENT_MONTHLY_ALLOCATION_BYASSETNAME_CHART_DATA_QUERY_KEY,
} from 'api/queries/charts/getClientMonthlyAllocationChartData/ByAssetName';
import { useQuery } from 'api/storage';

export default function useClientMonthlyAllocationByAssetNameChartData({
  client,
  portfolioName,
}: {
  client: string | undefined;
  portfolioName: string | undefined;
}) {
  const { data: raw } = useQuery(
    GET_CLIENT_MONTHLY_ALLOCATION_BYASSETNAME_CHART_DATA_QUERY_KEY(
      client ?? '',
    ),
    {
      enabled: !!client,
      queryFn: useGetClientMonthlyAllocationByAssetNameChartDataQuery(),
    },
  );

  const data = useMemo(() => {
    const selectedData = portfolioName ? raw?.[portfolioName] : [];
    return selectedData
      ? [...selectedData].sort((a, b) => a.date - b.date)
      : undefined;
  }, [portfolioName, raw]);

  return {
    data,
  };
}
