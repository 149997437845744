import { useRouteMatch } from 'react-router-dom';

import useAssetAllocationHistoryChartData from 'api/hooks/charts/useAssetAllocationHistoryChartData';
import useClientMonthlyAllocationByAssetNameChartData from 'api/hooks/charts/useClientMonthlyAllocationByAssetNameChartData';
import useClientMonthlyAllocationBySubtypeChartData from 'api/hooks/charts/useClientMonthlyAllocationBySubtypeChartData';
import useSelectedClient from 'api/hooks/clients/useSelectedClient';
import up from 'utils/paths';

export default function useConnect() {
  const { url } = useRouteMatch();
  const { selectedClient, selectedPortfolio } = useSelectedClient();

  const { data: assetAllocationHistoryData } =
    useAssetAllocationHistoryChartData(selectedPortfolio?.id);

  const { data: clientMonthlyAllocationBySubtypeData } =
    useClientMonthlyAllocationBySubtypeChartData({
      client: selectedClient?.id,
      portfolioName: selectedPortfolio?.name,
    });

  const { data: clientMonthlyAllocationByAssetNameData } =
    useClientMonthlyAllocationByAssetNameChartData({
      client: selectedClient?.id,
      portfolioName: selectedPortfolio?.name,
    });

  const parentUrl = up(url);

  return {
    assetAllocationHistoryData,
    clientMonthlyAllocationBySubtypeData,
    clientMonthlyAllocationByAssetNameData,
    parentUrl,
  };
}
