/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import type { SelectChangeEventDetail } from '@ionic/core';
import { FC, memo, useCallback, useEffect, useState } from 'react';

import { ReactComponent as BlueTick } from 'assets/icons/blue-tick.svg';
import from from 'styles/responsive';
import getPositionSelectAlert, {
  PositionAlert,
} from 'utils/getPositionSelectAlert';
import useMedia from 'utils/useMedia';

import {
  getSelectedText,
  getSelectInterface,
  useIsAuthenticEventHackyWorkaround,
} from './logic';
import {
  Container,
  Option,
  OptionMultiple,
  Select,
  SelectMultiple,
} from './styles';
import type { Props } from './types';

const Dropdown: FC<Props> = ({
  className,
  multiple,
  onChange,
  onChangeMultiple,
  options,
  placeholder = 'Filter',
  value,
  showLeft = false,
  textSmall = false,
}) => {
  const [positionSelect, setPositionSelect] = useState<PositionAlert>({
    left: 0,
    top: 0,
    width: 260,
  });
  const isMobile = !useMedia(from.tablet.query);
  const isAuthenticEvent = useIsAuthenticEventHackyWorkaround();
  const [filter, setFilter] = useState<string[]>([]);
  const [selectedText, setSelectedText] = useState<string>(placeholder ?? '');

  const handleChange = useCallback(
    (e: CustomEvent<SelectChangeEventDetail>) => {
      if (onChange && e.detail.value && isAuthenticEvent(e) && !multiple) {
        onChange(e.detail.value);
      }
      if (
        onChangeMultiple &&
        e.detail.value &&
        isAuthenticEvent(e) &&
        multiple
      ) {
        onChangeMultiple(e.detail.value);
        setSelectedText(
          value
            ? getSelectedText(options, e.detail.value, placeholder)
            : placeholder,
        );
      }
    },
    [
      isAuthenticEvent,
      multiple,
      onChange,
      onChangeMultiple,
      options,
      placeholder,
      value,
    ],
  );

  useEffect(() => {
    const filterBase = value?.split(',');
    setFilter(filterBase ?? []);
    setSelectedText(
      value ? getSelectedText(options, filterBase, placeholder) : placeholder,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    const filterBase = options.map((opt) => opt.value);
    setFilter(filterBase);
    setSelectedText(
      value ? getSelectedText(options, filterBase, placeholder) : placeholder,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.map((opt) => opt.value).join(',')]);

  useEffect(() => {
    const element = document.getElementsByName(
      'global-dynamic-style',
    )[0] as HTMLStyleElement;

    if (element && element.sheet) {
      if (element.sheet.cssRules.length > 0) {
        element.sheet?.deleteRule(0);
      }

      element.sheet.insertRule(
        `.alert-wrapper.sc-ion-alert-md { min-width: ${positionSelect.width}px; max-width: ${positionSelect.width}px; position: absolute; top: ${positionSelect.top}px; left: ${positionSelect.left}px;}`,
        0,
      );
    }
  }, [positionSelect]);

  const customAlertOptions = {
    message: multiple
      ? `
    <div class="select-action-header">
      <div id="select-all-button" class="select-action-button">
        Select All
      </div>
      <div id="unselect-all-button" class="select-action-button">
        Unselect All
      </div>
    </div>
    `
      : '',
  };

  const openSelector = () => {
    if (multiple) {
      const handleSelectAllClick = () => {
        const checkBoxes = document.getElementsByClassName('alert-checkbox');
        const checkBoxesArray = Array.from(checkBoxes);

        checkBoxesArray.forEach((chx) => {
          if (chx.getAttribute('aria-checked') === 'false') {
            (chx as HTMLButtonElement).click();
          }
        });
      };

      const handleUnselectAllClick = () => {
        const checkBoxes = document.getElementsByClassName('alert-checkbox');
        const checkBoxesArray = Array.from(checkBoxes);

        checkBoxesArray.forEach((chx) => {
          if (chx.getAttribute('aria-checked') === 'true') {
            (chx as HTMLButtonElement).click();
          }
        });
      };

      setTimeout(() => {
        const clickUnselectAll = document.getElementById('unselect-all-button');
        const clickSelectAll = document.getElementById('select-all-button');

        if (clickUnselectAll) {
          clickUnselectAll.addEventListener('click', handleUnselectAllClick);
        }

        if (clickSelectAll) {
          clickSelectAll.addEventListener('click', handleSelectAllClick);
        }
      }, 500);
    }
  };

  return (
    <Container
      $active={selectedText !== placeholder}
      $textSmall={textSmall}
      className={className}
      onClick={openSelector}
    >
      {multiple ? (
        <SelectMultiple
          $active={selectedText !== placeholder}
          className={`select-multiple-${placeholder
            .toLowerCase()
            .split(' ')
            .join('-')}`}
          interface={getSelectInterface(multiple, isMobile)}
          interfaceOptions={customAlertOptions}
          onPointerDown={() =>
            setPositionSelect(
              getPositionSelectAlert(
                options.length,
                multiple,
                placeholder,
                showLeft,
              ),
            )
          }
          multiple={multiple}
          onIonChange={handleChange}
          placeholder={placeholder}
          mode="md"
          value={filter}
          selectedText={selectedText}
        >
          {options.map((option) => (
            <OptionMultiple
              value={option.value}
              key={option.value}
              $isMobile={isMobile}
            >
              {option.label}
            </OptionMultiple>
          ))}
        </SelectMultiple>
      ) : (
        <Select
          $active={selectedText !== placeholder}
          interface={getSelectInterface(multiple ?? false, isMobile)}
          multiple={multiple}
          onIonChange={handleChange}
          placeholder={placeholder}
          value={value}
          mode="md"
        >
          {options.map((option) => (
            <Option
              value={option.value}
              key={option.value}
              $isMobile={isMobile}
            >
              {option.label} <BlueTick />
            </Option>
          ))}
        </Select>
      )}
    </Container>
  );
};

export default memo(Dropdown);
