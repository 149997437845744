import styled, { css } from 'styled-components';

import DefaultButton from 'components/Button';
import DefaultSpinner from 'components/Spinner';
import hexToRgb from 'utils/hexToRgb';

export const Container = styled.div<{ $isMobile: boolean }>`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;

  ${({ $isMobile }) =>
    $isMobile
      ? css`
          background-color: rgba(
            ${({ theme }) => hexToRgb(theme.colors.dark0)},
            0.5
          );
        `
      : css`
          background-color: rgba(
            ${({ theme }) => hexToRgb(theme.colors.dark0)},
            0.75
          );
        `};
`;

export const MessageRefreshToken = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  background: ${({ theme }) => theme.colors.dark3};
  padding: 2rem 1.25rem;
  top: 50%;
  left: 50%;
  box-shadow: 8px 10px 20px -6px #000000;
  border-radius: 5%;
  transform: translate(-50%, -50%);

  ${({ $isMobile }) =>
    $isMobile
      ? css`
          width: 21.375rem;
          height: 14rem;
        `
      : css`
          width: 22.5rem;
          height: 15rem;
        `};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  align-items: center;
`;

export const Text = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.weights.bold};
  font-size: 1.75rem;
  line-height: 1.25rem;
`;

export const SubText = styled.span<{ $small: boolean }>`
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.weights.medium};
  line-height: 1.25rem;
  text-align: center;

  ${({ $small }) =>
    $small
      ? css`
          font-size: 0.75rem;
        `
      : css`
          font-size: 1rem;
        `};
`;

export const Spinner = styled(DefaultSpinner)``;

export const Button = styled(DefaultButton)`
  margin-right: 16px;
  background: ${({ theme }) => theme.colors.primary};
  min-width: 10rem;
  width: 18rem;
`;
