import { useCallback } from 'react';
import { useMutation } from 'react-query';

import useLogoutMutation from 'api/mutations/auth/logout';
import { useClearStorage } from 'api/storage';
import { useVault } from 'containers/Services/VaultService';

import useLocalDataCleanup from './useLocalDataCleanup';

export default function useLogout() {
  const clearStorage = useClearStorage();
  const { clearVault, lockedVault, isCreated, isSupported } = useVault();
  const cleanup = useLocalDataCleanup();

  const { mutateAsync } = useMutation(useLogoutMutation(), {
    onSettled: async () => {
      if (isCreated && isSupported) {
        localStorage.setItem('unlock_automatic_enabled', 'false');
        await Promise.all([lockedVault()]);
      } else {
        await Promise.all([clearStorage(), clearVault()]);
        cleanup();
      }
    },
  });

  return useCallback(async () => mutateAsync(), [mutateAsync]);
}
