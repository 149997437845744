import { useCallback } from 'react';
import type { MutationFunction } from 'react-query';

import useRequest from 'api/request';
import withoutNulls from 'utils/withoutNulls';

import type {
  ChangePasswordRequest,
  ChangePasswordResponse,
  ChangePasswordVariables,
} from './types';

export default function useChangePasswordMutation(): MutationFunction<
  void,
  ChangePasswordVariables
> {
  const request = useRequest<ChangePasswordRequest, ChangePasswordResponse>();

  return useCallback(
    async ({ oldPassword, newPassword }: ChangePasswordVariables) => {
      const response = await request('/api/Authentication/changePassword', {
        body: {
          confirmNewPassword: newPassword,
          currentPassword: oldPassword,
          newPassword,
        },
        method: 'post',
      });

      const data = withoutNulls(await response.json());

      if (!data.isSuccess) {
        throw new Error(data.message);
      }
    },
    [request],
  );
}
