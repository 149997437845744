import {
  AxisBottom as DefaultAxisBottom,
  AxisLeft as DefaultAxisLeft,
} from '@visx/axis';
import { LinearGradient } from '@visx/gradient';
import { GridRows } from '@visx/grid';
import type { TextProps } from '@visx/text/lib/Text';
import { nanoid } from 'nanoid/non-secure';
import styled from 'styled-components';

import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
import from from 'styles/responsive';
import type { Theme } from 'styles/themes';

import type { $AxisProps } from './types';

export const GraphVector = styled.svg``;

export const areaGradientId = `area_gradient_${nanoid()}`;

export const Container = styled.div`
  position: relative;

  ${from.tablet`
    background-color: ${({ theme }) => theme.colors.dark1};
    padding: 1rem 0 2rem;
    border-radius: 1rem;
  `}
`;

export const AreaGradient = styled(LinearGradient).attrs(({ theme }) => ({
  from: `${theme.colors.primary}29`,
  fromOffset: '87.75%',
  id: areaGradientId,
  to: `${theme.colors.primary}00`,
  vertical: false,
}))``;

export const Grid = styled(GridRows).attrs({ stroke: 'currentColor' })`
  color: ${({ theme }) => theme.colors.white}1a;
  display: none;

  ${from.tablet`
    display: initial;
  `};
`;

const commonAxisProps = ({
  $isTablet,
  theme,
}: {
  $isTablet: boolean;
  theme: Theme;
}) => ({
  hideTicks: true,
  stroke: 'none',
  tickLabelProps: (): Partial<TextProps> => ({
    fill: $isTablet ? theme.colors.white50 : theme.colors.white75,
    fontSize: '12px',
    textAnchor: 'middle',
  }),
});

export const AxisLeft = styled(DefaultAxisLeft).attrs<$AxisProps>(
  ({ $isTablet, theme }) => ({
    ...commonAxisProps({ $isTablet, theme }),
    tickLabelProps: (): Partial<TextProps> => ({
      ...commonAxisProps({ $isTablet, theme }).tickLabelProps(),
      textAnchor: 'end',
      verticalAnchor: 'middle',
      x: 0,
    }),
  }),
)<$AxisProps>``;

export const AxisBottom = styled(DefaultAxisBottom).attrs<$AxisProps>(
  ({ $isTablet, theme }) => ({
    ...commonAxisProps({ $isTablet, theme }),
    tickLabelProps: (): Partial<TextProps> => ({
      ...commonAxisProps({ $isTablet, theme }).tickLabelProps(),
      y: -12,
      angle: -90,
      textAnchor: 'end',
      verticalAnchor: 'middle',
    }),
  }),
)<$AxisProps>``;

export const Title = styled.text.attrs({ fill: 'currentColor' })<{
  $x: number;
  $y: number;
}>`
  color: ${({ theme }) => theme.colors.white50};
  display: none;
  font-size: 12px;
  transform: translateX(${({ $x }) => $x}px) translateY(${({ $y }) => $y}px)
    rotate(-90deg);

  ${from.tablet`
    display: initial;
  `};
`;

export const BackLink = styled.div<{
  $isTablet: boolean;
}>`
  cursor: pointer;
  margin: 0 0 1.5rem 3.5rem;
  margin-top: ${({ $isTablet }) => ($isTablet ? '0' : '1.5rem')};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.weights.regular};

  & path {
    fill: ${({ theme }) => theme.colors.primary};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary75};

    & path {
      fill: ${({ theme }) => theme.colors.primary75};
    }
  }
`;

export const Chevron = styled(ChevronLeft)`
  cursor: pointer;
  margin-right: 0.5rem;
`;
