/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useState } from 'react';

import {
  Button,
  Chevron,
  GoBackContainer,
} from 'components/PrivateInvestmentsAssetDetailTable/styles';
import from from 'styles/responsive';
import up from 'utils/paths';
import useMedia from 'utils/useMedia';

import useConnect from './connect';
import {
  Container,
  LoaderContainer,
  PortfolioHistoryChart,
  Spinner,
  Title,
} from './styles';

const ClientPerformance: FC = () => {
  const isTablet = useMedia(from.tablet.query);
  const [historyData, setHistoryData] = useState<any>(undefined);
  const [fullScreen, setFullScreen] = useState(false);
  const { parentUrl, portfolioHistoryData } = useConnect();

  useEffect(() => {
    setHistoryData(portfolioHistoryData);
  }, [portfolioHistoryData]);

  return (
    <>
      {!historyData && (
        <LoaderContainer>
          <Spinner />
        </LoaderContainer>
      )}
      <Container
        $loading={!historyData}
        $mobile={!isTablet}
        parentUrl={up(parentUrl)}
        fullScreen={fullScreen}
        title="Portfolio History"
      >
        {isTablet && (
          <GoBackContainer>
            <Button
              color="secondary"
              variant="only-icon"
              size="small"
              withIcon={<Chevron />}
              href={parentUrl}
            />
          </GoBackContainer>
        )}
        {isTablet && <Title>Portfolio History</Title>}
        <PortfolioHistoryChart
          data={historyData ?? []}
          onChangeTooltip={setFullScreen}
        />
      </Container>
    </>
  );
};

export default ClientPerformance;
