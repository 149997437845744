import { useCallback } from 'react';
import { useMutation } from 'react-query';

import useDeviceInfoMutation from 'api/mutations/auth/deviceInfo';
import type { SaveDeviceInfoVariables } from 'api/mutations/auth/deviceInfo/types';

export default function useDeviceInfo() {
  const { isLoading, isSuccess, mutateAsync } = useMutation(
    useDeviceInfoMutation(),
  );

  const deviceInfo = useCallback(
    (variables: SaveDeviceInfoVariables) => mutateAsync(variables),
    [mutateAsync],
  );

  return { loading: isLoading, deviceInfo, success: isSuccess };
}
