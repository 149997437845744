import { useCallback } from 'react';

import useRequest from 'api/request';
import { Client, normalizeClient } from 'model/Client';
import withoutNulls from 'utils/withoutNulls';

import type { GetClientsRequest, GetClientsResponse } from './types';

export const GET_CLIENTS_QUERY_KEY = 'clients/getClients';

export default function useGetClientsQuery() {
  const request = useRequest<GetClientsRequest, GetClientsResponse>();

  return useCallback(async (): Promise<Client[]> => {
    const response = await request('/api/ClientData/getClientList');

    const data = await response.json();

    if (!data.isSuccess) {
      throw new Error(data.message ?? '');
    }

    const raw = data.data && withoutNulls(data.data);
    return raw?.map(normalizeClient) ?? [];
  }, [request]);
}
