/* eslint-disable @typescript-eslint/no-explicit-any */
import { localPoint } from '@visx/event';
import { scaleBand, scaleLinear } from '@visx/scale';
import { useTooltip as useVisxTooltip } from '@visx/tooltip';
import { useMemo } from 'react';
import type { MouseEvent, TouchEvent } from 'react';

import unreachable from 'utils/unreachable';

import type { TooltipDatum } from './types';

function isBarKey(key: string | undefined, barKeys: readonly string[]) {
  return barKeys.includes(key as string);
}

export const getDate = (d: any) => d.date;

const colorList = [
  '#3E77B9',
  '#267230',
  '#F3AE1F',
  '#DB4D33',
  '#B11F65',
  '#9329D9',
  '#51AEA0',
  '#FCB587',
  '#BA9309',
  '#6F72AE',
  '#DE57A9',
  '#65693B',
  '#DFFFF3',
  '#927CB7',
  '#7C9843',
  '#E502A3',
  '#CFEE5E',
  '#17AAD1',
  '#62CB44',
  '#A0669F',
  '#D31C65',
  '#BBFDD8',
  '#630398',
  '#D299DA',
  '#9BA7F2',
  '#E23FDF',
  '#977727',
  '#566D76',
  '#B16D63',
  '#12DEB4',
  '#1194CC',
  '#7AA853',
  '#55FBA3',
  '#9D88FE',
  '#34A7E5',
  '#413824',
  '#3727EA',
  '#530547',
  '#322C95',
  '#34AD70',
  '#DA45BD',
  '#066A6B',
  '#16B9D0',
  '#47361E',
  '#64DD6F',
  '#47B10A',
  '#DC3C15',
  '#37DA31',
  '#2BA78B',
  '#4B6166',
  '#83FC03',
  '#7ECD31',
  '#AD4D8F',
  '#7635BC',
  '#B70DBC',
  '#C69FDD',
  '#2302E5',
  '#253680',
  '#034B12',
  '#AF6F08',
  '#4CCFD7',
  '#F44184',
  '#85114D',
  '#124EBE',
  '#E25B5C',
  '#9CA481',
  '#88625D',
  '#E6AF44',
  '#91D7E0',
  '#D63338',
  '#A7E0C0',
  '#4FDAC3',
  '#56BCCE',
  '#FB3415',
  '#5C08AB',
  '#178E37',
  '#8A294D',
  '#D98CC7',
  '#EC3172',
  '#5F27C0',
  '#4F11A6',
  '#523076',
  '#0F43E3',
  '#C5A28E',
  '#365B0F',
  '#495855',
  '#877878',
  '#43EA4D',
  '#D9ED38',
  '#FCAC24',
  '#E372C0',
  '#3DF87C',
  '#733796',
  '#1B944F',
  '#4DE658',
  '#66016E',
  '#286B9E',
  '#2C5555',
  '#6A7370',
  '#2109D7',
  '#F596AB',
  '#55849B',
  '#3A4B5A',
  '#C2550F',
  '#0B1092',
  '#EF5D8F',
  '#CF3EFF',
  '#49A922',
  '#B844BA',
  '#EF4C3B',
  '#AEA7AE',
  '#F81F35',
  '#EE7A3F',
];

export function getBarKeyColor(index: number) {
  return colorList[index];
}

export function useHeight({
  isTablet,
  isLandscape,
}: {
  isTablet: boolean;
  isLandscape: boolean;
}): number {
  if (isTablet) {
    return window.innerHeight - 328;
  }

  return isLandscape ? 320 : window.innerHeight - 420;
}

export function useEdgeValues({
  barKeys,
  data,
}: {
  barKeys: readonly string[];
  data: readonly any[];
}) {
  const start = useMemo(
    () =>
      data.reduce(
        (lowestTime, datum) => Math.min(lowestTime, datum.date),
        Infinity,
      ),
    [data],
  );

  const end = useMemo(
    () =>
      data.reduce((highestTime, datum) => Math.max(highestTime, datum.date), 0),
    [data],
  );

  const edges = useMemo(
    () =>
      data.map((datum) => {
        const values = [...barKeys].map((key) => datum[key]);

        const positiveValues = values.filter((value) => value >= 0);
        const negativeValues = values.filter((value) => value < 0);

        const startValue = negativeValues.reduce(
          (acc, value) => (acc as number) + (value as number),
          0,
        );

        const endValue = positiveValues.reduce(
          (acc, value) => (acc as number) + (value as number),
          0,
        );

        return { startValue, endValue };
      }),
    [barKeys, data],
  );

  const maxValue = useMemo(
    () => Math.max(...edges.map((edge) => edge.endValue)),
    [edges],
  );
  const minValue = useMemo(
    () => Math.min(...edges.map((edge) => edge.startValue)),
    [edges],
  );

  return {
    end,
    maxValue,
    minValue,
    start,
  };
}

export function useMargins({ isTablet }: { isTablet: boolean }) {
  // This builds on top of the padding of the Container element. Instead of
  // putting all on the padding, we put 1rem here so the chart line, which has
  // a thick stroke, doesn't get cut. We also put the rest on the Container
  // element so it is easier for other developers to change the styles.
  const topMargin = 16;

  // This can't simply be padding on the Container element because it wouldn't
  // be noticed by the resize observer.
  const rightMargin = 32;

  const bottomAxisHeight = 60;
  const bottomAxisMargin = 19;

  const leftAxisWidth = isTablet ? 70 : 40;
  const leftAxisMargin = 16;

  return {
    bottomAxisHeight,
    bottomAxisMargin,
    leftAxisMargin,
    leftAxisWidth,
    rightMargin,
    topMargin,
  };
}

export function useScales(props: {
  bottomAxisHeight: number;
  bottomAxisMargin: number;
  data: any[];
  end: number;
  height: number;
  leftAxisMargin: number;
  leftAxisWidth: number;
  maxValue: number;
  minValue: number;
  rightMargin: number;
  start: number;
  topMargin: number;
  width: number;
  barKeys: string[];
}) {
  // Destructured here rather than on the "props" parameter itself so that the
  // parameter hint in IntelliJ based IDEs isn't gigantic.
  const {
    bottomAxisHeight,
    bottomAxisMargin,
    data,
    height,
    leftAxisMargin,
    leftAxisWidth,
    maxValue,
    minValue,
    rightMargin,
    topMargin,
    width,
  } = props;

  const xScaleBars = useMemo(
    () =>
      scaleBand({
        domain: data.map(getDate),
        padding: 0.2,
        range: [leftAxisWidth + leftAxisMargin, width - rightMargin],
        paddingOuter: 0,
      }),
    [leftAxisMargin, leftAxisWidth, rightMargin, width, data],
  );

  const yScale = useMemo(
    () =>
      scaleLinear({
        domain: [minValue, maxValue],
        range: [height - bottomAxisHeight - bottomAxisMargin, topMargin],
      }),
    [bottomAxisHeight, bottomAxisMargin, height, minValue, maxValue, topMargin],
  );

  return { xScaleBars, yScale };
}

export function useTooltip({
  barKeys,
  data,
}: {
  xScale: ReturnType<typeof useScales>['xScaleBars'];
  yScale: ReturnType<typeof useScales>['yScale'];
  barKeys: readonly string[];
  data: readonly any[];
  leftAxisMargin: number;
  leftAxisWidth: number;
  height: number;
  width: number;
}) {
  const { hideTooltip, showTooltip, tooltipData, tooltipLeft, tooltipTop } =
    useVisxTooltip<TooltipDatum>();

  const handleTooltipUpdated = useMemo(
    () => (event: MouseEvent<SVGRectElement> | TouchEvent<SVGRectElement>) => {
      if (event.target instanceof SVGRectElement) {
        // bar chart tooltip
        const { x: xBar, y: yBar } = localPoint(event) ?? { x: 0, y: 0 };

        const { datumId, key } = event.target.dataset;
        const datumBar = data.find((it) => it.id === datumId);

        if (!datumBar || !isBarKey(key, barKeys)) {
          return unreachable(undefined);
        }
        showTooltip({
          tooltipData: {
            date: datumBar.date,
            key: key as string,
            value: datumBar[key as string] / 100,
          },
          tooltipLeft: xBar,
          tooltipTop: yBar,
        });
      }

      return undefined;
    },
    [barKeys, data, showTooltip],
  );

  return {
    handleTooltipClosed: hideTooltip,
    handleTooltipUpdated,
    tooltipData,
    tooltipLeft,
    tooltipTop,
  };
}
