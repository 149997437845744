import { parseISO } from 'date-fns';

import type { GetMaturityProfileResponse } from 'api/queries/allocations/getMaturityProfile/types';
import unreachable from 'utils/unreachable';
import type { WithoutNulls } from 'utils/withoutNulls';

import { normalizeMaturityProfileAsset } from './MaturityProfileAsset';

type Input = NonNullable<GetMaturityProfileResponse['data']>[number];

export const normalizeMaturityProfileYear = (input: WithoutNulls<Input>) => {
  const id = input.maturityYear ?? '';

  return {
    allocation: input.percentMaturity ?? 0,
    assets:
      input.detail?.map((asset) => normalizeMaturityProfileAsset(asset, id)) ??
      [],
    id,
    date: parseISO(input.maturityYear ?? unreachable('1970-01-01')).getTime(),
  } as const;
};

export type MaturityProfileYear = ReturnType<
  typeof normalizeMaturityProfileYear
>;
