export type AssetGrouping =
  | 'asset-breakdown'
  | 'custodian'
  | 'account'
  | 'entity'
  | 'country'
  | 'sector';

export function isValidGrouping(grouping: string): grouping is AssetGrouping {
  return [
    'asset-breakdown',
    'custodian',
    'account',
    'entity',
    'country',
    'sector',
  ].includes(grouping);
}
