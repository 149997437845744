import useGetVolatilityReportQuery, {
  GET_VOLATILITY_REPORT_QUERY_KEY,
} from 'api/queries/riskAnalysis/getVolatilityReport';
import { useQuery } from 'api/storage';

export default function useVolatilityReport({
  client,
  portfolioName,
}: {
  client: string | undefined;
  portfolioName: string | undefined;
}) {
  const { data } = useQuery(GET_VOLATILITY_REPORT_QUERY_KEY(client ?? ''), {
    enabled: !!client,
    queryFn: useGetVolatilityReportQuery(),
  });

  return {
    data: portfolioName ? data?.[portfolioName] : undefined,
  };
}
