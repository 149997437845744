import styled from 'styled-components';

import Select from 'components/Select';
import Tabs from 'components/Tabs';

export const Container = styled.div`
  margin: 0 2.5rem 0 0;
`;

export const LaptopTabs = styled(Tabs).attrs({
  variant: 'bg-tabs',
})`
  ion-segment-button {
    min-width: 8.93rem;
    font-size: 0.75rem;
  }
` as typeof Tabs;

export const TabletSelect = styled(Select)``;
