import { Form as DefaultForm } from 'formik';
import styled from 'styled-components';

import { ReactComponent as DefaultFaceIDIcon } from 'assets/icons/face-id-login.svg';
import { ReactComponent as DefaultGenericBiometricWhiteIcon } from 'assets/icons/generic-biometric-login-white.svg';
import { ReactComponent as DefaultGenericBiometricIcon } from 'assets/icons/generic-biometric-login.svg';
import { ReactComponent as DefaultTouchIDIcon } from 'assets/icons/touch-id-login.svg';
import DefaultBrand from 'components/Brand';
import DefaultButton from 'components/Button';
import from from 'styles/responsive';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  min-height: 30rem;

  ${from.tablet`
    max-width: 21.5rem;
    margin: auto;
    padding-top: 0;
  `}
`;

export const AlertBiometrics = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary};
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 2.25rem;
`;

export const AlertBiometricsIcon = styled.div`
  padding: 1rem;
  margin-left: 0.5rem;
`;

export const AlertBiometricsLabel = styled.div`
  padding: 1rem;
  display: flex;
  font-size: 1.125rem;
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  padding-top: 5rem;

  ${from.tablet`
    height: fit-content;
  `};
`;

export const Brand = styled(DefaultBrand).attrs({
  withOneFamily: true,
})`
  ${from.tablet`
    margin-bottom: 3.5rem;
  `};
`;

export const Form = styled(DefaultForm)`
  padding: 0 1.25rem;
  width: 100%;

  ${from.tablet`
    padding: 0;
  `}
`;

export const Inputs = styled.div`
  margin-bottom: 2rem;

  > :not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const Button = styled(DefaultButton)`
  width: 100%;
  min-width: 0;
  ${from.tablet`
    margin-bottom: 2rem;
    min-width: inherit;
  `};
`;

export const HelpSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > :not(:last-child) {
    margin-right: 0.5rem;
  }
  > :last-child {
    margin-top: 0.75rem;
  }
`;

export const HelpItem = styled.div``;

export const HelpLabel = styled.span`
  color: ${({ theme }) => theme.colors.white}80;
  margin-right: 0.5rem;
`;

export const HelpLink = styled(Button).attrs({ variant: 'link' })`
  font-size: 1rem;
`;

export const HorizontalGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BiometricsButton = styled.div`
  padding: 1rem;
`;

export const GenericBiometricIcon = styled(DefaultGenericBiometricIcon)``;

export const GenericBiometricWhiteIcon = styled(
  DefaultGenericBiometricWhiteIcon,
)``;

export const FaceIDIcon = styled(DefaultFaceIDIcon)``;

export const TouchIDIcon = styled(DefaultTouchIDIcon)``;
