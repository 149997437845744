import { useCallback, useMemo } from 'react';
import { useQueryClient } from 'react-query';

import { INITIALIZED_KEY } from 'api/hooks/auth/useLocalDataCleanup';
import useSelectedClientQuery, {
  SELECTED_CLIENT_QUERY_KEY,
} from 'api/queries/clients/selectedClient';
import type { SelectedClientQuery } from 'api/queries/clients/selectedClient/types';
import { useQuery } from 'api/storage';
import type { Client } from 'model/Client';
import useEntityFiltering from 'utils/useDataManipulation/useEntityFiltering';

import useDefaultAccount from './account/useDefaultAccount';
import useDefaultPortfolio from './portfolio/useDefaultPortfolio';
import useClients from './useClients';

function useInitialize({
  clients,
  setSelectedClient,
}: {
  clients: Client[] | undefined;
  setSelectedClient: (input: NonNullable<SelectedClientQuery>) => void;
}) {
  const { account: defaultAccountName } = useDefaultAccount();
  const { portfolio: defaultPortfolioName } = useDefaultPortfolio();

  return useCallback(() => {
    const initialized = !!window.sessionStorage.getItem(INITIALIZED_KEY);

    if (initialized) {
      return;
    }

    const defaultClient = clients?.find(
      (client) => client.name === defaultAccountName,
    );
    const defaultPortfolio = defaultClient?.subPortfolios.find(
      (portfolio) => portfolio.name === defaultPortfolioName,
    );

    if (defaultClient && defaultPortfolio) {
      setSelectedClient({
        clientId: defaultClient.id,
        portfolioId: defaultPortfolio.id,
      });

      sessionStorage.setItem(INITIALIZED_KEY, 'true');
    }
  }, [clients, setSelectedClient, defaultAccountName, defaultPortfolioName]);
}

export default function useSelectedClient() {
  const queryClient = useQueryClient();
  const { data: clients } = useClients();

  const { onChangeFiltering } = useEntityFiltering({
    filterOptions: [
      {
        filter: () => true,
        label: 'All',
        value: 'default',
      },
    ],
  });

  const { data } = useQuery<SelectedClientQuery>(SELECTED_CLIENT_QUERY_KEY, {
    queryFn: useSelectedClientQuery(),
    staleTime: Infinity,
    refetchOnMount: 'always',
  });

  const setSelectedClient = useCallback(
    (input: NonNullable<SelectedClientQuery>) => {
      onChangeFiltering('default');

      queryClient.setQueryData<SelectedClientQuery>(
        SELECTED_CLIENT_QUERY_KEY,
        input,
      );
    },
    [onChangeFiltering, queryClient],
  );

  const initialize = useInitialize({ clients, setSelectedClient });

  const selectedClient = useMemo(
    () =>
      data
        ? clients?.find((client) => client.id === data.clientId)
        : clients?.[0],
    [clients, data],
  );

  const selectedPortfolio = useMemo(
    () =>
      selectedClient?.subPortfolios.find(
        (portfolio) => portfolio.id === data?.portfolioId,
      ) ?? selectedClient?.subPortfolios[0],
    [data, selectedClient],
  );

  return {
    initialize,
    selectedClient,
    selectedPortfolio,
    setSelectedClient,
  };
}
