import { useCallback } from 'react';
import type { QueryFunction } from 'react-query';

import useRequest from 'api/request';
import type { AssetAllocation } from 'model/AssetAllocation';
import { normalizeAssetAllocation } from 'model/AssetAllocation';
import getPortfolioId from 'utils/getPorfolioId';
import withoutNulls from 'utils/withoutNulls';

import type { GetAllocationsRequest, GetAllocationsResponse } from './types';

export const GET_ALLOCATIONS_QUERY_KEY = (portfolio: string) => [
  'allocations/getAllocations',
  portfolio,
];

export default function useAllocationsQuery(): QueryFunction<
  AssetAllocation[]
> {
  const request = useRequest<GetAllocationsRequest, GetAllocationsResponse>();

  return useCallback(
    async ({ queryKey }) => {
      const [, portfolio] = queryKey as [string, string];

      const response = await request(
        '/api/CVData/getAllocationByAssetType_Raw/{portfolio}',
        {
          urlParams: {
            portfolio: getPortfolioId(portfolio) ?? '',
          },
        },
      );

      const data = await response.json();

      if (!data.isSuccess) {
        throw new Error(data.message ?? '');
      }

      return withoutNulls(data.data ?? []).map(normalizeAssetAllocation);
    },
    [request],
  );
}
