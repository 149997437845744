import type {
  GetClientMonthlyAllocationByAssetNameChartDataResponse,
  GetClientMonthlyAllocationBySubtypeChartDataResponse,
} from 'api/queries/charts/getClientMonthlyAllocationChartData/types';
import { parseBackendDate } from 'utils/dates';
import type { WithoutNulls } from 'utils/withoutNulls';

type InputAllocationBySubtype = NonNullable<
  NonNullable<
    NonNullable<
      GetClientMonthlyAllocationBySubtypeChartDataResponse['data']
    >[number]['subClientMonthlyAllocations']
  >[number]['clientMonthlyAllocations']
>[number];

type InputAllocationByAssetName = NonNullable<
  NonNullable<
    NonNullable<
      GetClientMonthlyAllocationByAssetNameChartDataResponse['data']
    >[number]['subClientMonthlyAllocations']
  >[number]['clientMonthlyAllocations']
>[number];

export const normalizeMonthlyAllocationDatum = (
  input: WithoutNulls<InputAllocationBySubtype | InputAllocationByAssetName>,
) =>
  ({
    id: `${input.monthDateID}`,
    date: input.monthDate ? parseBackendDate(input.monthDate).getTime() : 0,
    balance: input.balance ?? 0,
    instrumentTypes: mapInstrumentTypes(input),
  } as const);

export type MonthlyAllocationDatum = ReturnType<
  typeof normalizeMonthlyAllocationDatum
>;

function mapInstrumentTypes(
  input: InputAllocationBySubtype | InputAllocationByAssetName,
) {
  if (
    input &&
    input.instrumentTypes &&
    input.instrumentTypes[0] &&
    'subtypes' in input.instrumentTypes[0]
  ) {
    return (
      (input as InputAllocationBySubtype).instrumentTypes
        ?.filter(
          (d) =>
            d.instrumentType !== 'Equity-Individual' &&
            d.instrumentType !== 'Equity-Market',
        )
        .map((d) => ({
          instrumentType: d.instrumentType,
          items:
            d.subtypes?.map((s) => ({ balance: s.balance, name: s.subtype })) ??
            [],
        })) ?? []
    );
  }

  return (
    (input as InputAllocationByAssetName).instrumentTypes?.map((d) => ({
      instrumentType: d.instrumentType,
      items:
        d.assets?.map((s) => ({ balance: s.balance, name: s.assetName })) ?? [],
    })) ?? []
  );
}
