import { Capacitor } from '@capacitor/core';
import { FC, useEffect } from 'react';

import Allocation from 'components/Allocation';
import { useAssetContext } from 'context/Asset';
import from from 'styles/responsive';
import useMedia from 'utils/useMedia';

import useConnect from './connect';
import Content from './Content';
import FullContent from './FullContent';
import { Container, Grid, Header, Navigation } from './styles';

const CategoryDetailFixedIncome: FC = () => {
  const { asset, setAsset } = useAssetContext();
  const {
    allocation,
    allocationChartData,
    assets,
    cvBondPerformanceSummaries,
    backUrl,
    balance,
    fixedIncomeCharacteristicsUrl,
    bondPerformanceAnalysisUrl,
    breadcrumbs,
    currentFiltering,
    currentSorting,
    filterOptions,
    handle,
    initialValues,
    modalOpen,
    navigationLinks,
    pageTitle,
    selectedUrl,
    sortOptions,
  } = useConnect();

  const isMobile = !useMedia(from.tablet.query);
  const filtersApplied = breadcrumbs.length >= 3;

  useEffect(() => {
    if (asset && assets && Capacitor.getPlatform() !== 'web') {
      setTimeout(() => {
        document.getElementById(asset)?.scrollIntoView({ block: 'center' });
        setAsset('');
      }, 0);
    }
  }, [asset, setAsset, assets]);

  useEffect(() => {
    if (assets.length) {
      const itemPosition = sessionStorage.getItem('itemPosition');
      if (itemPosition) {
        setTimeout(() => {
          document
            .getElementById(itemPosition)
            ?.scrollIntoView({ block: 'center' });
          sessionStorage.removeItem('itemPosition');
        }, 0);
      }
    }
  }, [assets]);

  const applyFilter = (value: string[]) => {
    handle.changeFiltering(value.join());
  };

  const resetFilter = (tab: string) => {
    if (tab !== 'yield-vs-duration') {
      handle.changeFiltering('default');
    }
  };

  return (
    <Container
      parentUrl={backUrl}
      showLayoutHeader={!modalOpen}
      title={pageTitle}
      /*
       * This is a dirty hack that solves an issue on Safari on the mobile
       * viewport where after closing the sort/filter modal the app got stuck
       * on a blue screen.
       *
       * It seems that in that state, removing a css prop forces ionic/safari
       * to show the content correctly.
       *
       * Anyway, this line solves it, we discourage you from touching it or
       * things will break.
       */
      $enableCSSPropAsWorkaround={modalOpen}
    >
      <Grid>
        <Header
          allocation={<Allocation value={allocation} />}
          amount={balance}
          chartData={allocationChartData}
          title={pageTitle}
          modalOpen={modalOpen}
        />
        {!isMobile && (
          <Navigation
            activeLink={selectedUrl}
            breadcrumbs={breadcrumbs}
            links={navigationLinks}
          />
        )}
        {filtersApplied || isMobile ? (
          <Content
            assets={assets}
            fixedIncomeCharacteristicsUrl={fixedIncomeCharacteristicsUrl}
            bondPerformanceAnalysisUrl={bondPerformanceAnalysisUrl}
            cvBondPerformanceSummaries={cvBondPerformanceSummaries}
            currentFiltering={currentFiltering}
            currentSorting={currentSorting}
            filterOptions={filterOptions}
            initialValues={initialValues}
            modalOpen={modalOpen}
            onChangeMultiple={applyFilter}
            onChangeSorting={handle.changeSorting}
            onCloseModal={handle.closeModal}
            onOpenModal={handle.openModal}
            onSubmitForm={handle.submitForm}
            sortOptions={sortOptions}
            showBondPerformanceAnalysis={!filtersApplied}
          />
        ) : (
          <FullContent
            assets={assets}
            fixedIncomeCharacteristicsUrl={fixedIncomeCharacteristicsUrl}
            bondPerformanceAnalysisUrl={bondPerformanceAnalysisUrl}
            cvBondPerformanceSummaries={cvBondPerformanceSummaries}
            currentFiltering={currentFiltering}
            currentSorting={currentSorting}
            filterOptions={filterOptions}
            onChangeMultiple={applyFilter}
            onChangeSorting={handle.changeSorting}
            onChangeTab={resetFilter}
          />
        )}
      </Grid>
    </Container>
  );
};
export default CategoryDetailFixedIncome;
