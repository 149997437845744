import { nanoid } from 'nanoid/non-secure';

import type { GetMarketOverviewResponse } from 'api/queries/markets/getMarketOverview/types';
import type { WithoutNulls } from 'utils/withoutNulls';

import { normalizeMarketOverviewAsset } from './MarketOverviewAsset';

type Input = NonNullable<
  NonNullable<
    NonNullable<
      GetMarketOverviewResponse['data']
    >[number]['instrumentClassDetail']
  >[number]
>;

export const normalizeMarketOverviewCategory = (input: WithoutNulls<Input>) =>
  ({
    assets: input.marketOverviewDetail?.map(normalizeMarketOverviewAsset) ?? [],
    // The back-end doesn't send an ID. This is a first for me.
    // I believe generating our own IDs should be fine because the back-end data
    // doesn't change very often and the normalization will only run once per
    // request. These items have no detail page anyway so we should only need
    // the generated IDs for react keys, etc.
    id: nanoid(),
    name: input.instrumentName ?? '',
    timePeriod: input.timePeriod ?? '',
  } as const);

export type MarketOverviewCategory = ReturnType<
  typeof normalizeMarketOverviewCategory
>;
