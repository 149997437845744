import type { FC } from 'react';
import { memo } from 'react';

import { Container, FilterLabelContainer, SortLabelContainer } from './styles';
import type { Props } from './types';

const SortInfo: FC<Props> = ({
  className,
  currentFilteringSubtypes,
  currentFilteringSponsors,
  currentSorting,
  filterSubtypesOptions = [],
  filterSponsorsOptions = [],
  sortOptions = [],
}) => {
  const filterSubtypesArray = currentFilteringSubtypes?.split(',');
  const filterSponsorsArray = currentFilteringSponsors?.split(',');

  let subtypesLabel;
  if (filterSubtypesArray?.includes('default')) {
    subtypesLabel = 'All';
  } else {
    subtypesLabel = filterSubtypesOptions
      .filter((option) => filterSubtypesArray?.includes(option.value))
      ?.map((d) => d.label)
      .join(', ');
  }

  let sponsorsLabel;
  if (filterSponsorsArray?.includes('default')) {
    sponsorsLabel = 'All';
  } else {
    sponsorsLabel = filterSponsorsOptions
      .filter((option) => filterSponsorsArray?.includes(option.value))
      ?.map((d) => d.label)
      .join(', ');
  }

  const sortLabel = sortOptions.find(
    (option) => option.value === currentSorting,
  )?.label;

  if (!sortLabel && !subtypesLabel && !sponsorsLabel) {
    return null;
  }

  return (
    <Container className={className}>
      {sortLabel && (
        <SortLabelContainer>
          Sorted by <strong>{sortLabel}</strong>
        </SortLabelContainer>
      )}
      {subtypesLabel && (
        <FilterLabelContainer>
          Subtypes filtered by <strong>{subtypesLabel}</strong>
        </FilterLabelContainer>
      )}
      {sponsorsLabel && (
        <FilterLabelContainer>
          Sponsors filtered by <strong>{sponsorsLabel}</strong>
        </FilterLabelContainer>
      )}
    </Container>
  );
};

export default memo(SortInfo);
