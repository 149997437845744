import styled from 'styled-components';

import Select from 'components/Select';
import Tabs from 'components/Tabs';

export const Container = styled.div`
  width: 32rem;
`;

export const LaptopTabs = styled(Tabs).attrs({
  variant: 'bg-tabs-without-width',
})`` as typeof Tabs;

export const TabletSelect = styled(Select)``;
