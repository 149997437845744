import type { QueryFunction } from 'react-query';

export const ACCESS_TOKEN_QUERY_KEY = 'auth/accessToken';

const accessTokenQuery = () => ({
  accessToken: undefined as string | undefined,
  email: undefined as string | undefined,
  passwordResetData: undefined as
    | {
        email: string;
        token: string;
      }
    | undefined,
  refreshToken: undefined as string | undefined,
  termsAgreed: false,
});

export type AccessTokenQuery = ReturnType<typeof accessTokenQuery>;

export default function useAccessTokenQuery(): QueryFunction<AccessTokenQuery> {
  return accessTokenQuery;
}
