import { useCallback } from 'react';

import useRequest from 'api/request';

import type {
  AcceptTermsConditionsRequest,
  AcceptTermsConditionsResponse,
} from './types';

export default function useAcceptTermsConditionsMutation() {
  const request = useRequest<
    AcceptTermsConditionsRequest,
    AcceptTermsConditionsResponse
  >();

  return useCallback(async () => {
    const response = await request(`/api/ClientData/TermsConditions_Accepted`, {
      body: true,
      method: 'post',
    });
    const data = await response.json();
    if (!data?.isSuccess) {
      throw new Error(`Couldn't accept terms!`);
    }
  }, [request]);
}
