import { Bar as DefaultBar } from '@visx/shape';
import styled from 'styled-components';

export const Bar = styled(DefaultBar).attrs(({ theme }) => ({
  fill: theme.colors.primary,
}))`
  fill: ${({ theme }) => theme.colors.primary};
  transition: opacity 200ms ease;
  &:hover {
    opacity: 0.5;
  }
`;
