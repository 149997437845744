import styled from 'styled-components';

import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
import DefaultAmount from 'components/Amount';
import AssetStats from 'components/AssetStats';
import DefaultButton from 'components/Button';
import Select from 'components/Select';
import PageDetailLayout from 'containers/Common/PageDetailLayout';
import from from 'styles/responsive';

export const Container = styled(PageDetailLayout)``;

export const ContainerMain = styled.div``;

export const Content = styled.div`
  padding: 2rem 1.25rem;
  ${from.tablet`
    padding: 0;
  `}
`;

export const Header = styled.div``;

export const Title = styled.h2`
  margin: 0;
  font-size: 1.375rem;
  line-height: 1.925rem;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.weights.regular};
  ${from.laptop`
    margin-bottom: 0.25rem;
  `}
`;

export const Amount = styled(DefaultAmount).attrs({ forwardedAs: 'h1' })`
  margin: 0;
  font-size: 1.75rem;
  line-height: 2.45rem;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.weights.bold};
  ${from.tablet`
    margin-bottom: 0.625rem;
  `}
`;

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  ${from.tablet`
    margin-bottom: 2.125rem;
  `}
`;

export const PandLTitle = styled.h4`
  margin: 0 0.5rem 0 0;
  font-size: 1rem;
  line-height: 1.1875rem;
  color: ${({ theme }) => theme.colors.white75};
  font-weight: ${({ theme }) => theme.weights.regular};
`;

export const ColouredAmount = styled(DefaultAmount).attrs({ coloured: true })``;

export const GoBackContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
`;

export const Button = styled(DefaultButton)`
  margin-right: 1rem;
`;

export const Chevron = styled(ChevronLeft)`
  margin-top: 0.125rem;
`;

export const StatsTitle = styled.h2`
  font-size: 1rem;
  line-height: 1.4;
`;

export const Stats = styled(AssetStats)`
  margin-bottom: 2rem;
  ${from.tablet`
    margin-bottom: 2.5rem;
  `}
`;

export const FilterLabelContainer = styled.div`
  font-size: 0.75rem;
  line-height: 0.875rem;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1.25rem 0;
`;

export const Toolbar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1.5rem;
`;

export const ActionZone = styled.div`
  display: flex;
  align-items: center;
`;

export const ExportToExcelButton = styled(DefaultButton)`
  min-width: 8.625rem;
`;

export const SortButton = styled(Select)`
  width: fit-content;
  margin-left: 0.75rem;
`;

export const FilterButton = styled(SortButton)``;
