import { Capacitor } from '@capacitor/core';
import { Formik } from 'formik';
import { FC, useEffect, useState } from 'react';

import Allocation from 'components/Allocation';
import SortInfo from 'components/SortInfo';
import SortItemsGroup from 'components/SortItemsGroup';
import SortModal from 'components/SortModal';
import { useAssetContext } from 'context/Asset';
import from from 'styles/responsive';
import { formatDate } from 'utils/dates';
import exportToExcel from 'utils/exportToExcel';
import searchWithoutSort from 'utils/searchWithoutSort';
import type { KeySortOfColumn, OrderOrientation } from 'utils/sort/types';
import useMedia from 'utils/useMedia';
import usePlatform from 'utils/usePlatform/usePlatform';

import useConnect from './connect';
import {
  ActionZone,
  Container,
  Content,
  ExportToExcelButton,
  FilterButton,
  FilterModalTitle,
  Form,
  Grid,
  Header,
  ListContainer,
  ModalTitle,
  Navigation,
  PrivateInvestmentsList,
  Stats,
  TableButton,
  TableContainer,
  Title,
  Toolbar,
} from './styles';

const CategoryDetail: FC = () => {
  const { asset, setAsset } = useAssetContext();
  const [sortColumn, setSortColumn] = useState<string>('Total ($)');
  const [sortOrientation, setSortOrientation] = useState<string>('DESC');
  const {
    allocation,
    allocationChartData,
    assets,
    backUrl,
    balance,
    breadcrumbs,
    currentFiltering,
    currentSorting,
    filterOptions,
    handle,
    initialValues,
    modalOpen,
    navigationLinks,
    pageTitle,
    selectedUrl,
    sortOptions,
    headerColumns,
    notionalValues,
    url,
    search,
    categorySlug,
  } = useConnect();

  const isMobile = !useMedia(from.tablet.query);
  const { isWeb } = usePlatform();

  const showFilter = () =>
    categorySlug === 'equity-market' ||
    categorySlug === 'equity-individual' ||
    categorySlug === 'alternatives';

  useEffect(() => {
    const currentSortingAux = currentSorting ?? 'default';

    const headerColumn = headerColumns.find(
      (col) =>
        col.keyOrderAsc === currentSortingAux ||
        col.keyOrderDesc === currentSortingAux,
    );

    if (headerColumn) {
      setSortColumn(headerColumn.titleColumn);
      setSortOrientation(
        headerColumn.keyOrderAsc === currentSortingAux ? 'ASC' : 'DESC',
      );
    }
  }, [headerColumns, currentSorting]);

  useEffect(() => {
    if (asset && assets && Capacitor.getPlatform() !== 'web') {
      setTimeout(() => {
        document.getElementById(asset)?.scrollIntoView({ block: 'center' });
        setAsset('');
      }, 0);
    }
  }, [asset, setAsset, assets]);

  useEffect(() => {
    if (assets.length) {
      const itemPosition = sessionStorage.getItem('itemPosition');
      if (itemPosition) {
        setTimeout(() => {
          document
            .getElementById(itemPosition)
            ?.scrollIntoView({ block: 'center' });
          sessionStorage.removeItem('itemPosition');
        }, 0);
      }
    }
  }, [assets]);

  const handleSortByColumn = (sortByColumnSelected: KeySortOfColumn) => {
    if (sortByColumnSelected.titleColumn === sortColumn) {
      handle.changeSorting(
        sortOrientation === 'DESC'
          ? sortByColumnSelected.keyOrderAsc
          : sortByColumnSelected.keyOrderDesc,
      );
    } else if (sortByColumnSelected.titleColumn !== sortColumn) {
      handle.changeSorting(sortByColumnSelected.keyOrderDesc);
    }
  };

  const applyFilter = (value: string[]) => {
    handle.changeFiltering(value.join());
  };

  const handleExportToExcel = () => {
    if (
      categorySlug === 'equity-market' ||
      categorySlug === 'equity-individual' ||
      categorySlug === 'commodity'
    ) {
      const data = assets.map((item) => ({
        Isin_Ticker: item.isinTicker ? item.isinTicker : 'N/A',
        Name: item.name,
        Price_As_Of_Date:
          item.priceAsOfDate > 0 ? formatDate(item.priceAsOfDate) : 'N/A',
        Price: item.marketPrice,
        Market_Value: item.marketValue,
        Cost: item.cost,
        Dividends_Received: item.dividendsReceived,
        Total_Profit_and_Loss: item.totalProfitAndLoss,
      }));

      void exportToExcel(data, `${pageTitle} List`, 'CDlist', [4, 5, 6, 7, 8]);
    } else {
      const data = assets.map((item) => ({
        Name: item.name,
        Price_As_Of_Date:
          item.priceAsOfDate > 0 ? formatDate(item.priceAsOfDate) : 'N/A',
        Price: item.marketPrice,
        Market_Value: item.marketValue,
        Cost: item.cost,
        Dividends_Received: item.dividendsReceived,
        Total_Profit_and_Loss: item.totalProfitAndLoss,
      }));

      void exportToExcel(data, `${pageTitle} List`, 'CDlist', [3, 4, 5, 6, 7]);
    }
  };

  return (
    <Container
      parentUrl={backUrl}
      showLayoutHeader={!modalOpen}
      title={pageTitle}
      /*
       * This is a dirty hack that solves an issue on Safari on the mobile
       * viewport where after closing the sort/filter modal the app got stuck
       * on a blue screen.
       *
       * It seems that in that state, removing a css prop forces ionic/safari
       * to show the content correctly.
       *
       * Anyway, this line solves it, we discourage you from touching it or
       * things will break.
       */
      $enableCSSPropAsWorkaround={modalOpen}
    >
      <Grid>
        <Header
          allocation={<Allocation value={allocation} />}
          amount={balance}
          chartData={allocationChartData}
          title={pageTitle}
          modalOpen={modalOpen}
        />
        {!isMobile && (
          <Navigation
            activeLink={selectedUrl}
            breadcrumbs={breadcrumbs}
            links={navigationLinks}
          />
        )}
        <Content>
          {notionalValues && (
            <>
              {!isMobile && (
                <Toolbar>
                  <Title>{pageTitle} Summary</Title>
                  <TableButton
                    color="secondary"
                    size="small"
                    withCaretDown
                    href={`${url}/summary${searchWithoutSort(search)}`}
                  >
                    {`${pageTitle} Table`}
                  </TableButton>
                </Toolbar>
              )}
              <Stats
                category={pageTitle}
                balance={notionalValues?.balance}
                balancePct={notionalValues?.balancePct}
                notional={notionalValues?.notional}
                notionalPct={notionalValues?.notionalPct}
                totalNotional={notionalValues?.totalNotional}
                totalNotionalPct={notionalValues?.totalNotionalPct}
                pathname={`${url}/summary${search}`}
                buttonText={`${pageTitle} Summary`}
              />
            </>
          )}
          {!isMobile && (
            <Toolbar>
              <Title>{pageTitle} Assets</Title>
              <ActionZone>
                {isWeb && assets && assets.length > 0 && (
                  <ExportToExcelButton
                    size="small"
                    color="secondary"
                    onClick={handleExportToExcel}
                  >
                    Export to Excel
                  </ExportToExcelButton>
                )}
                {(!showFilter() || categorySlug === 'alternatives') && (
                  <TableButton
                    color="secondary"
                    size="small"
                    withCaretDown
                    href={`${url}/summary${searchWithoutSort(search)}`}
                  >
                    {`${pageTitle} Table`}
                  </TableButton>
                )}
                {showFilter() && (
                  <FilterButton
                    onChangeMultiple={applyFilter}
                    options={filterOptions.filter(
                      (opt) => opt.value !== 'default',
                    )}
                    placeholder="Filter"
                    value={currentFiltering}
                    multiple
                  />
                )}
              </ActionZone>
            </Toolbar>
          )}
          <TableContainer>
            <ListContainer>
              {showFilter() && (
                <SortInfo
                  currentFilteringSubtypes={currentFiltering}
                  currentSorting={currentSorting}
                  filterSubtypesOptions={filterOptions}
                  sortOptions={sortOptions}
                />
              )}
              {!showFilter() && (
                <SortInfo
                  currentSorting={currentSorting}
                  sortOptions={sortOptions}
                />
              )}
              <PrivateInvestmentsList
                data={assets}
                isMobile={isMobile}
                textButton={showFilter() ? 'Sort/Filter' : 'Sort'}
                openModal={handle.openModal}
                handleSortByColumn={handleSortByColumn}
                sortApplied={{
                  titleColumn: sortColumn,
                  orderOrientation: sortOrientation as OrderOrientation,
                }}
                headerColumns={headerColumns}
              />
            </ListContainer>
          </TableContainer>
        </Content>
      </Grid>
      {modalOpen && (
        <Formik
          initialValues={initialValues}
          onSubmit={handle.submitForm}
          enableReinitialize
        >
          <Form>
            <SortModal onClose={handle.closeModal}>
              <>
                <ModalTitle>Sort by</ModalTitle>
                <SortItemsGroup
                  options={sortOptions}
                  name={showFilter() ? 'sortValue' : 'value'}
                />
                {showFilter() && (
                  <>
                    <FilterModalTitle>Filter</FilterModalTitle>
                    <SortItemsGroup
                      options={filterOptions}
                      name="filterValue"
                      multiple
                    />
                  </>
                )}
              </>
            </SortModal>
          </Form>
        </Formik>
      )}
    </Container>
  );
};

export default CategoryDetail;
