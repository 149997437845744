import { useMemo } from 'react';
import { useRouteMatch } from 'react-router';

import useProfitLossChartData from 'api/hooks/charts/useProfitLossChartData';
import useSelectedClient from 'api/hooks/clients/useSelectedClient';
import up from 'utils/paths';
import type { KeySortOfColumn } from 'utils/sort/types';

import { useSorting, useSummary, useWinnersContent } from './logic';

export default function useConnect() {
  const {
    params: { reportSlug, timeFrame },
    url,
  } = useRouteMatch<{ reportSlug: string; timeFrame: string | undefined }>();

  const { selectedClient, selectedPortfolio } = useSelectedClient();
  const { data: profitLossChartData } = useProfitLossChartData({
    client: selectedClient?.id,
    portfolioName: selectedPortfolio?.name,
  });

  const { compare, currentSorting, onChangeSorting } = useSorting();

  const { summary, balance, formattedDate, formattedName, selectTimeFrame } =
    useSummary({
      portfolio: selectedPortfolio?.id,
      lastUpdate: selectedPortfolio?.lastUpdated,
      slug: reportSlug,
      compare,
      url,
    });

  const { isWinnersContent, toggleWinnersContent, winnersLosers } =
    useWinnersContent({
      clientName: selectedClient?.name,
      portfolioName: selectedPortfolio?.name,
      slug: reportSlug,
      portfolio: selectedPortfolio?.id,
    });

  const navLinks = useMemo(() => {
    const hasTimeFrame = url.split('/').length === 5;
    const baseUrl = up(url, hasTimeFrame ? 2 : 1);

    return [
      { name: 'Daily Estimate', value: `${baseUrl}/daily-estimate` },
      { name: 'Monthly Estimate', value: `${baseUrl}/month-to-date` },
      { name: 'Yearly Estimate', value: `${baseUrl}/year-to-date` },
      { name: 'Since Inception', value: `${baseUrl}/since-inception` },
    ];
  }, [url]);

  const totalAssets = useMemo(
    () => summary.reduce((acc, item) => item.numberOfAssets + acc, 0),
    [summary],
  );

  const headerColumns: KeySortOfColumn[] =
    reportSlug === 'since-inception'
      ? [
          {
            titleColumn: `${totalAssets} Assets`,
            keyOrderAsc: 'alphabeticalR',
            keyOrderDesc: 'alphabetical',
          },
          {
            titleColumn: 'Total ($)',
            keyOrderAsc: 'totalASC',
            keyOrderDesc: 'default',
          },
        ]
      : [
          {
            titleColumn: `${totalAssets} Assets`,
            keyOrderAsc: 'alphabeticalR',
            keyOrderDesc: 'alphabetical',
          },
          {
            titleColumn: 'Total ($)',
            keyOrderAsc: 'totalASC',
            keyOrderDesc: 'default',
          },
          {
            titleColumn: 'Total (%)',
            keyOrderAsc: 'totalPorcASC',
            keyOrderDesc: 'totalPorcDESC',
          },
        ];

  return {
    isWinnersContent,
    navLinks,
    profitLossChartData,
    reportSlug,
    summary,
    balance,
    formattedDate,
    formattedName,
    selectedPortfolio,
    urlTimeFrame: timeFrame,
    selectTimeFrame,
    toggleWinnersContent,
    url,
    winnersLosers,
    headerColumns,
    currentSorting,
    onChangeSorting,
  };
}
