import { localPoint } from '@visx/event';
import { scaleLinear, scaleTime } from '@visx/scale';
import { useTooltip as useVisxTooltip } from '@visx/tooltip';
import { bisector, extent } from 'd3-array';
import type { MouseEvent, TouchEvent } from 'react';
import { useCallback, useMemo } from 'react';

import type { Datum } from './types';

const bisectDate = bisector<Datum, Date>((d) => new Date(d.date)).left;

const getDate = (d: Datum) => d.date;
const getValue = (d: Datum) => d.value;

export function useAccessors({ xScale, yScale }: ReturnType<typeof useScales>) {
  const x = useCallback((d: Datum) => xScale(getDate(d)) ?? 0, [xScale]);
  const y = useCallback((d: Datum) => yScale(getValue(d)) ?? 0, [yScale]);

  return { x, y };
}

export function useHeight({ isTablet }: { isTablet: boolean }): number {
  if (isTablet) {
    return 424;
  }

  return 250;
}

export function useEdgeValues(data: readonly Datum[]) {
  const start = useMemo(
    () =>
      data.reduce(
        (lowestTime, datum) => Math.min(lowestTime, datum.date),
        Infinity,
      ),
    [data],
  );

  const end = useMemo(
    () =>
      data.reduce((highestTime, datum) => Math.max(highestTime, datum.date), 0),
    [data],
  );

  const [minValue = 0, maxValue = 0] = useMemo(
    () => extent(data, getValue),
    [data],
  );

  return {
    end,
    maxValue,
    minValue,
    start,
  };
}

export function useMargins({ isTablet }: { isTablet: boolean }) {
  // This builds on top of the padding of the Container element. Instead of
  // putting all on the padding, we put 1rem here so the chart line, which has
  // a thick stroke, doesn't get cut. We also put the rest on the Container
  // element so it is easier for other developers to change the styles.
  const topMargin = 16;

  // This can't simply be padding on the Container element because it wouldn't
  // be noticed by the resize observer.
  const rightMargin = isTablet ? 32 : 16;

  const bottomAxisHeight = 13;
  const bottomAxisMargin = 12;

  const leftAxisWidth = isTablet ? 60 : 30;
  const leftAxisMargin = isTablet ? 24 : 16;

  return {
    bottomAxisHeight,
    bottomAxisMargin,
    leftAxisMargin,
    leftAxisWidth,
    rightMargin,
    topMargin,
  };
}

export function useScales(props: {
  bottomAxisHeight: number;
  bottomAxisMargin: number;
  end: number;
  height: number;
  leftAxisMargin: number;
  leftAxisWidth: number;
  maxValue: number;
  minValue: number;
  rightMargin: number;
  start: number;
  topMargin: number;
  width: number;
}) {
  // Destructured here rather than on the "props" parameter itself so that the
  // parameter hint in IntelliJ based IDEs isn't gigantic.
  const {
    bottomAxisHeight,
    bottomAxisMargin,
    end,
    height,
    leftAxisMargin,
    leftAxisWidth,
    maxValue,
    minValue,
    rightMargin,
    start,
    topMargin,
    width,
  } = props;

  const xScale = useMemo(
    () =>
      scaleTime({
        range: [0, width - leftAxisWidth - leftAxisMargin - rightMargin],
        domain: [start, end],
      }),
    [end, leftAxisMargin, leftAxisWidth, start, rightMargin, width],
  );

  const yScale = useMemo(
    () =>
      scaleLinear({
        range: [height - bottomAxisHeight - bottomAxisMargin, topMargin],
        domain: [minValue, maxValue * 1.1],
      }),
    [bottomAxisHeight, bottomAxisMargin, height, maxValue, minValue, topMargin],
  );

  return { xScale, yScale };
}

export function useTooltip({
  data,
  leftAxisMargin,
  leftAxisWidth,
  xScale,
  yScale,
}: {
  data: readonly Datum[];
  leftAxisMargin: number;
  leftAxisWidth: number;
  xScale: ReturnType<typeof useScales>['xScale'];
  yScale: ReturnType<typeof useScales>['yScale'];
}) {
  const { hideTooltip, showTooltip, tooltipData, tooltipLeft, tooltipTop } =
    useVisxTooltip<Datum>();

  const handleTooltipUpdated = useMemo(
    () => (event: MouseEvent<SVGRectElement> | TouchEvent<SVGRectElement>) => {
      const { x } = localPoint(event) ?? { x: 0 };

      // The x from the event includes the left margin
      const adjustedX = x - leftAxisMargin - leftAxisWidth;

      const selectedDate = xScale.invert(adjustedX);
      const index = bisectDate(data, selectedDate, 1);

      const datumLeft = data[index - 1];
      const datumRight = data[index];

      const closestDatum = (() => {
        if (!datumLeft && !datumRight) {
          return undefined;
        }

        if (!datumLeft) {
          return datumRight;
        }

        if (!datumRight) {
          return datumLeft;
        }

        const differenceToLeft = selectedDate.getTime() - getDate(datumLeft);
        const differenceToRight = getDate(datumRight) - selectedDate.getTime();

        if (differenceToLeft > differenceToRight) {
          return datumRight;
        }

        return datumLeft;
      })();

      if (closestDatum) {
        showTooltip({
          tooltipData: closestDatum,
          tooltipLeft: x,
          tooltipTop: yScale(getValue(closestDatum)),
        });
      }
    },
    [data, leftAxisMargin, leftAxisWidth, showTooltip, xScale, yScale],
  );

  return {
    handleTooltipClosed: hideTooltip,
    handleTooltipUpdated,
    tooltipData,
    tooltipLeft,
    tooltipTop,
  };
}
