import { App, URLOpenListenerEvent } from '@capacitor/app';
import { LocalNotifications } from '@capacitor/local-notifications';
import { PushNotifications } from '@capacitor/push-notifications';
import { useIonRouter } from '@ionic/react';
import { addDays } from 'date-fns';
import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Guest from 'containers/Guest';
import Maintenance from 'containers/Maintenance';
import Protected from 'containers/Protected';
import { useMaintenanceMode } from 'containers/Services/MaintenanceModeService';
import { useNews } from 'containers/Services/NewsService';
import TermsAndConditions from 'containers/TermsAndConditions';
import usePlatform from 'utils/usePlatform/usePlatform';

import useConnect from './connect';
import type { CustomEvent } from './types';

const Main: FC = () => {
  const { loginStatus } = useConnect();
  const ionRouter = useIonRouter();
  const { isWeb, isAndroid, isIOS } = usePlatform();
  const newsServices = useNews();
  const maintenanceModeContext = useMaintenanceMode();
  const history = useHistory();

  useEffect(() => {
    if (
      localStorage.getItem('notice_to_activate_notifications_prod') === null
    ) {
      const dateForShowMessage = addDays(new Date(), 30).getTime();
      localStorage.setItem(
        'notice_to_activate_notifications_prod',
        `${dateForShowMessage}`,
      );
    }

    void App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      history.push(event.url);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener('ionBackButton', (ev: unknown) => {
      (ev as CustomEvent).detail.register(-1, () => {
        if (!ionRouter.canGoBack()) {
          App?.minimizeApp();
        }
      });
    });
  }, [ionRouter]);

  useEffect(() => {
    if (!isWeb && isAndroid) {
      void PushNotifications.removeAllListeners().then(() => {
        void PushNotifications.addListener(
          'pushNotificationReceived',
          (notification) => {
            const { title, body } = notification;

            if (title && body) {
              void LocalNotifications.schedule({
                notifications: [
                  {
                    id: Math.floor(Math.random() * 100 + 1),
                    title,
                    body,
                  },
                ],
              });
            }
          },
        );
      });
    }

    if (!isWeb && isIOS) {
      void PushNotifications.removeAllListeners().then(() => {
        void PushNotifications.addListener(
          'pushNotificationActionPerformed',
          (action) => {
            const { body } = action.notification;

            if (!body?.includes('Daily Performance Estimate')) {
              newsServices?.updateNotificationToNews(true);
              localStorage.setItem(
                'navigate_to_news_from_notification',
                'true',
              );
            } else {
              newsServices?.updateNotificationToNews(false);
              localStorage.setItem(
                'navigate_to_news_from_notification',
                'false',
              );
            }
          },
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAndroid, isIOS, isWeb]);

  if (maintenanceModeContext?.isMaintenanceMode) {
    return <Maintenance />;
  }

  if (loginStatus.type === 'loading') {
    return null;
  }

  if (loginStatus.type === 'not-logged-in') {
    return <Guest />;
  }

  if (loginStatus.type === 'terms-not-agreed') {
    return <TermsAndConditions />;
  }

  if (loginStatus.type === 'password-reset-required') {
    // The useEffect in the connect file will handle this case.
    return null;
  }

  return <Protected />;
};

export default Main;
