import { useCallback } from 'react';

import useRequest from 'api/request';
import withoutNulls from 'utils/withoutNulls';

import type {
  AppErrorReportMutationVariables,
  AppErrorReportRequest,
  AppErrorReportResponse,
} from './types';

export default function useAppError() {
  const request = useRequest<AppErrorReportRequest, AppErrorReportResponse>();

  return useCallback(
    async (body: AppErrorReportMutationVariables) => {
      const response = await request('/api/ClientData/appErrorReport', {
        method: 'POST',
        body,
      });

      const data = await response.json();

      if (!data.isSuccess) {
        throw new Error(data?.message ?? 'Could not register the error');
      }

      return withoutNulls(data.data);
    },
    [request],
  );
}
