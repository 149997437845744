import { FC, memo } from 'react';

import { Amount, Container, FirstCol } from '../../styles';
import InfoColumns from '../InfoColumns';
import type { Props } from './types';

const Row: FC<Props> = ({ row, selectedData, onPin, onUnpin }) => (
  <Container>
    <FirstCol>
      {row.name}
      <Amount value={row.price} />
    </FirstCol>
    <InfoColumns
      onPin={onPin}
      onUnpin={onUnpin}
      row={row}
      selectedData={selectedData}
    />
  </Container>
);

export default memo(Row);
