import {
  IonContent as DefaultIonContent,
  IonPage as DefaultIonPage,
} from '@ionic/react';
import styled from 'styled-components';

export const IonPage = styled(DefaultIonPage)``;

export const IonContent = styled(DefaultIonContent).attrs({
  forceOverscroll: false,
})``;
