import { FC, memo, useMemo } from 'react';

import { formatPercentage } from 'utils/percentages';

import { getDataOrdered } from './logic';
import {
  ChangePercentage,
  Col,
  FirstCol,
  Header,
  HeaderCell,
  Row,
  Table,
} from './styles';
import type { Props } from './types';

const AssetAllocationTargetAndRangesTable: FC<Props> = ({
  data: readonlyData,
}) => {
  const data = getDataOrdered(useMemo(() => [...readonlyData], [readonlyData]));

  const headers = useMemo(
    () => [
      {
        id: 'name',
        label: 'Asset Class',
      },
      {
        id: 'current',
        label: 'Current',
      },
      {
        id: 'change',
        label: 'Change',
      },
      {
        id: 'target',
        label: 'Target',
      },
      {
        id: 'range',
        label: 'Range',
      },
    ],
    [],
  );

  return (
    <Table>
      <Header>
        {headers.map((header) => (
          <HeaderCell key={header.id}>{header.label}</HeaderCell>
        ))}
      </Header>
      {data.map((row) => (
        <Row key={row.id}>
          <FirstCol>{row.instrumentType}</FirstCol>
          <Col>{formatPercentage(row.current)}</Col>
          <ChangePercentage value={row.target - row.current} />
          <Col>{formatPercentage(row.target)}</Col>
          <Col>{`${formatPercentage(row.range.lowerBound)} - ${formatPercentage(
            row.range.upperBound,
          )}`}</Col>
        </Row>
      ))}
      <Row key="allocation-target-and-range-resume" $isResume>
        <FirstCol $isResume>SUM</FirstCol>
        <Col $isResume>
          {formatPercentage(data.reduce((acc, row) => acc + row.current, 0))}
        </Col>
        <Col />
        <Col $isResume>
          {formatPercentage(data.reduce((acc, row) => acc + row.target, 0))}
        </Col>
        <Col />
      </Row>
    </Table>
  );
};

export default memo(AssetAllocationTargetAndRangesTable);
