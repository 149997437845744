import { useCallback } from 'react';

import useRequest from 'api/request';
import withoutNulls from 'utils/withoutNulls';

import type {
  SnsTokenRegistrationMutationVariables,
  SnsTokenRegistrationRequest,
  SnsTokenRegistrationResponse,
} from './types';

export default function useSnsTokenRegistration() {
  const request = useRequest<
    SnsTokenRegistrationRequest,
    SnsTokenRegistrationResponse
  >();

  return useCallback(
    async (body: SnsTokenRegistrationMutationVariables) => {
      const response = await request('/api/SNSData/snsTokenRegistration', {
        method: 'POST',
        body,
      });

      const data = await response.json();

      if (!data.isSuccess) {
        throw new Error(data?.message ?? 'Could not register the device token');
      }

      return withoutNulls(data.data);
    },
    [request],
  );
}
