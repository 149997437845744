import type { GetLiquidityResponse } from 'api/queries/allocations/getLiquidity/types';
import type { WithoutNulls } from 'utils/withoutNulls';

type Input = NonNullable<GetLiquidityResponse['data']>[number];

export const normalizeLiquidity = (input: WithoutNulls<Input>) => {
  const client = input.client ?? '';
  const entity = input.entity ?? '';
  const account = input.account ?? '';
  const custodian = input.custodian ?? '';
  const sector = input.sector ?? '';
  const country = input.country ?? '';
  const ranking = input.ranking ?? 0;
  const cashEquivalent = input.cashEquiv ?? 0;
  const cashOther = input.cashOther ?? 0;
  const cashUSD = input.cashUSD ?? 0;
  const treasuries = input.treasuries ?? 0;
  const groupID = input.groupID ?? null;

  const totalCashAvailable = cashEquivalent + cashUSD + cashOther;

  return {
    client,
    entity,
    account,
    custodian,
    sector,
    country,
    ranking,
    cashEquivalent,
    cashOther,
    cashUSD,
    treasuries,
    totalCashAvailable,
    totalLiquidityAvailable: totalCashAvailable + treasuries,
    groupID,
  } as const;
};

export type Liquidity = ReturnType<typeof normalizeLiquidity>;
